import React from 'react';
import LiveListItem from './LiveListItem';

function TagListItem({ item: tag, ...props }) {
  const taggedCount = Object.keys(tag.itemsByType || {}).reduce(
    (total, type) => total + Object.keys(tag.itemsByType[type]).length,
    0
  );
  tag.taggedCountString = `${taggedCount} item${taggedCount === 1 ? '' : 's'}`;

  return (
    <LiveListItem
      type="tags"
      primaryPath="id"
      secondaryPath="taggedCountString"
      item={tag}
      {...props}
      hideFollow={true}
      divider={tag.type === 'follow'}
    />
  );
}

export default TagListItem;
