import { Divider } from '@material-ui/core';
import React, { Fragment } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import _ from 'lodash';
import { FilterField } from '../fields';

export const sourceFilterMapping = {
  vehicleTrips: { trip: 'event', vehicle: true, person: 'driver' },
  vehicleTime: { vehicle: true, person: 'driver' },
  vehicleIdles: { stop: 'event', vehicle: true, person: 'driver' },
  vehicleIdleCount: { stop: 'event', vehicle: true, person: 'driver' },
  vehicleStops: { stop: 'event', vehicle: true, person: 'lastDriver' },
  vehicleStopCount: { stop: 'event', vehicle: true, person: 'lastDriver' },
  vehiclePolls: { vehicle: true },
  vehicleVisits: { location: true, vehicle: true, person: 'driver' },
  vehicleVisitCount: { location: true, vehicle: true, person: 'driver' },
  incidents: { incident: 'event' },
  incidentCount: { incident: 'event' },
  personTrails: { person: true },
  personTime: { person: true },
  personPolls: { person: true },
  personVisits: { location: true, person: true },
  personVisitCount: { location: true, person: true },
  locations: { location: 'event' },
};

export default function SourceFilters({ type, name, filters }) {
  const mapping = sourceFilterMapping[type];

  if (mapping) {
    return (
      <Fragment>
        {Object.entries(mapping).map(([key, id], index) => {
          id = typeof id === 'string' ? id : key;

          return (
            <Fragment key={key}>
              {index > 0 && (
                <Divider style={{ marginTop: 16, marginBottom: 8 }} />
              )}
              <FieldArray
                label={_.startCase(`${id} Filters`)}
                name={`${name}.${id}`}
                filters={filters[key]}
                component={FilterField}
              />
            </Fragment>
          );
        })}
      </Fragment>
    );
  } else {
    return <Fragment></Fragment>;
  }
}

// export default function SourceFilters({ type, name, filters }) {
export function OldSourceFilters({ type, name, filters }) {
  switch (type) {
    case 'vehicleTrips':
      return (
        <Fragment>
          <FieldArray
            label="Event Filters"
            name={`${name}.event`}
            filters={filters.trip}
            component={FilterField}
          />
          <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          <FieldArray
            label="Vehicle Filters"
            name={`${name}.vehicle`}
            filters={filters.vehicle}
            component={FilterField}
          />
          <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          <FieldArray
            label="Driver Filters"
            name={`${name}.driver`}
            filters={filters.person}
            component={FilterField}
          />
        </Fragment>
      );
    case 'vehicleTime':
      return (
        <Fragment>
          <FieldArray
            label="Vehicle Filters"
            name={`${name}.vehicle`}
            filters={filters.vehicle}
            component={FilterField}
          />
          <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          <FieldArray
            label="Driver Filters"
            name={`${name}.driver`}
            filters={filters.person}
            component={FilterField}
          />
        </Fragment>
      );
    case 'vehicleIdles':
    case 'vehicleIdleCount':
      return (
        <Fragment>
          <FieldArray
            label="Event Filters"
            name={`${name}.event`}
            filters={filters.stop}
            component={FilterField}
          />
          <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          <FieldArray
            label="Vehicle Filters"
            name={`${name}.vehicle`}
            filters={filters.vehicle}
            component={FilterField}
          />
          <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          <FieldArray
            label="Driver Filters"
            name={`${name}.driver`}
            filters={filters.person}
            component={FilterField}
          />
        </Fragment>
      );
    case 'vehicleStops':
    case 'vehicleStopCount':
      return (
        <Fragment>
          <FieldArray
            label="Event Filters"
            name={`${name}.event`}
            filters={filters.stop}
            component={FilterField}
          />
          <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          <FieldArray
            label="Vehicle Filters"
            name={`${name}.vehicle`}
            filters={filters.vehicle}
            component={FilterField}
          />
          <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          <FieldArray
            label="Last Driver Filters"
            name={`${name}.lastDriver`}
            filters={filters.person}
            component={FilterField}
          />
        </Fragment>
      );
    case 'vehiclePolls':
      return (
        <Fragment>
          <FieldArray
            label="Vehicle Filters"
            name={`${name}.vehicle`}
            filters={filters.vehicle}
            component={FilterField}
          />
        </Fragment>
      );
    case 'vehicleVisits':
    case 'vehicleVisitCount':
      return (
        <Fragment>
          <FieldArray
            label="Location Filters"
            name={`${name}.location`}
            filters={filters.location}
            component={FilterField}
          />
          <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          <FieldArray
            label="Vehicle Filters"
            name={`${name}.vehicle`}
            filters={filters.vehicle}
            component={FilterField}
          />
          <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          <FieldArray
            label="Driver Filters"
            name={`${name}.driver`}
            filters={filters.person}
            component={FilterField}
          />
        </Fragment>
      );
    case 'incidents':
    case 'incidentCount':
      return (
        <Fragment>
          <FieldArray
            label="Event Filters"
            name={`${name}.event`}
            filters={filters.incident}
            component={FilterField}
          />
        </Fragment>
      );
    case 'personTrails':
    case 'personTime':
    case 'personPolls':
      return (
        <Fragment>
          <FieldArray
            label="Person Filters"
            name={`${name}.person`}
            filters={filters.person}
            component={FilterField}
          />
        </Fragment>
      );
    case 'personVisits':
    case 'personVisitCount':
      return (
        <Fragment>
          <FieldArray
            label="Location Filters"
            name={`${name}.location`}
            filters={filters.location}
            component={FilterField}
          />
          <Divider style={{ marginTop: 16, marginBottom: 8 }} />
          <FieldArray
            label="Person Filters"
            name={`${name}.person`}
            filters={filters.person}
            component={FilterField}
          />
        </Fragment>
      );
    case 'locations':
      return (
        <Fragment>
          <FieldArray
            label="Location Filters"
            name={`${name}.event`}
            filters={filters.location}
            component={FilterField}
          />
        </Fragment>
      );
    default:
      return '';
  }
}
