import { IconButton, withStyles } from '@material-ui/core';
import {
  Label as LabelIcon,
  LabelOff as LabelOffIcon,
  Map as MapIcon,
  Satellite as SatelliteIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import { Map, View } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { applyTransform, extend } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import {
  defaults as defaultInteractions,
  Select as SelectInteraction,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { getTransform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getStyle, setBaseLayers } from '../../mapStyles';

const { mapExtent, layerSwitcher } = window.config;

const styles = {
  map: {
    width: '100%',
    height: '100%',
  },
  zoomInButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    zIndex: 100,
  },
  zoomOutButton: {
    position: 'absolute',
    top: 40,
    left: 5,
    zIndex: 100,
  },
  fitButton: {
    position: 'absolute',
    top: 75,
    left: 5,
    zIndex: 100,
  },
  labelButton: {
    position: 'absolute',
    top: 110,
    left: 5,
    zIndex: 100,
  },
  layerButton: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    zIndex: 100,
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    color: '#fff',
  },
};

class CollectionMap extends Component {
  state = {
    zoomInDisabled: false,
    zoomOutDisabled: false,
    satelliteMapVisible: false,
    showLabels: false,
  };

  incidentsSource = null;
  crimesSource = null;
  intelligenceSource = null;
  // accelerometerEventsSource = null;
  hoverSource = null;
  map = null;
  select = null;

  selectedFeature = null;

  getFeature() {
    const { selectedEventIndex, events } = this.props;

    return events && selectedEventIndex
      ? events[selectedEventIndex.typeId].features[selectedEventIndex.featureId]
      : null;
  }

  getDefaultStyle = (feature) => {
    return getStyle('default', feature, this.state.showLabels);
  };

  getSelectStyle = (feature) => {
    return getStyle('select', feature, this.state.showLabels);
  };

  getHoverStyle = (feature) => {
    return getStyle('hover', feature, this.state.showLabels);
  };

  fitMapToContents = () => {
    const sources = [
      this.incidentsSource,
      this.crimesSource,
      this.intelligenceSource, //,
      //this.accelerometerEventsSource
    ];
    let ex = null;

    sources.forEach((source) => {
      if (ex) {
        ex = extend(ex, source.getExtent());
      } else {
        ex = source.getExtent();
      }
    });

    if (
      ex[0] === Infinity ||
      ex[1] === Infinity ||
      ex[2] === -Infinity ||
      ex[3] === -Infinity
    ) {
      this.map
        .getView()
        .fit(applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857')));
      if (
        this.state.zoomInDisabled !==
          (this.map.getView().getZoom() === this.map.getView().getMaxZoom()) ||
        this.state.zoomOutDisabled !==
          (this.map.getView().getZoom() === this.map.getView().getMinZoom())
      ) {
        this.setState({
          zoomInDisabled:
            this.map.getView().getZoom() === this.map.getView().getMaxZoom(),
          zoomOutDisabled:
            this.map.getView().getZoom() === this.map.getView().getMinZoom(),
        });
      }
    } else {
      this.map.getView().fit(ex, { maxZoom: 18 });
      if (
        this.state.zoomInDisabled !==
          (this.map.getView().getZoom() === this.map.getView().getMaxZoom()) ||
        this.state.zoomOutDisabled !==
          (this.map.getView().getZoom() === this.map.getView().getMinZoom())
      ) {
        this.setState({
          zoomInDisabled:
            this.map.getView().getZoom() === this.map.getView().getMaxZoom(),
          zoomOutDisabled:
            this.map.getView().getZoom() === this.map.getView().getMinZoom(),
        });
      }
    }
  };

  fitMapToSelected = () => {
    if (this.getFeature().geometry) {
      this.map.getView().fit(
        new GeoJSON()
          .readGeometry(this.getFeature().geometry, {
            featureProjection: 'EPSG:3857',
          })
          .getExtent(),
        { maxZoom: 18 }
      );
    }
  };

  handleMapToggleClick = () => {
    if (this.map) {
      this.map
        .getLayers()
        .item(this.state.satelliteMapVisible ? 0 : 1)
        .setVisible(true);
      this.map
        .getLayers()
        .item(this.state.satelliteMapVisible ? 1 : 0)
        .setVisible(false);
      this.setState({
        satelliteMapVisible: !this.state.satelliteMapVisible,
      });
    }
  };

  handleLabelsToggleClick = () => {
    this.setState({
      showLabels: !this.state.showLabels,
    });
    this.incidentsSource.changed();
    this.crimesSource.changed();
    this.intelligenceSource.changed();
    // this.accelerometerEventsSource.changed();
  };

  handleZoomInClick = () => {
    if (this.map) {
      this.map.getView().setZoom(this.map.getView().getZoom() + 1);
      this.setState({
        zoomInDisabled:
          this.map.getView().getZoom() === this.map.getView().getMaxZoom(),
        zoomOutDisabled:
          this.map.getView().getZoom() === this.map.getView().getMinZoom(),
      });
    }
  };

  handleZoomOutClick = () => {
    if (this.map) {
      this.map.getView().setZoom(this.map.getView().getZoom() - 1);
      this.setState({
        zoomInDisabled:
          this.map.getView().getZoom() === this.map.getView().getMaxZoom(),
        zoomOutDisabled:
          this.map.getView().getZoom() === this.map.getView().getMinZoom(),
      });
    }
  };

  componentDidMount() {
    this.incidentsSource = this.props.events
      ? new VectorSource({
          features: new GeoJSON().readFeatures(this.props.events.incidents, {
            featureProjection: 'EPSG:3857',
          }),
        })
      : new VectorSource({ features: [] });
    const incidentsLayer = new VectorLayer({
      source: this.incidentsSource,
      style: this.getDefaultStyle,
    });

    this.crimesSource = this.props.events
      ? new VectorSource({
          features: new GeoJSON().readFeatures(this.props.events.crimes, {
            featureProjection: 'EPSG:3857',
          }),
        })
      : new VectorSource({ features: [] });
    const crimesLayer = new VectorLayer({
      source: this.crimesSource,
      style: this.getStyle,
    });

    this.intelligenceSource = this.props.events
      ? new VectorSource({
          features: new GeoJSON().readFeatures(this.props.events.intelligence, {
            featureProjection: 'EPSG:3857',
          }),
        })
      : new VectorSource({ features: [] });
    const intelligenceLayer = new VectorLayer({
      source: this.intelligenceSource,
      style: this.getStyle,
    });

    // this.accelerometerEventsSource = this.props.events
    // ? new VectorSource({
    //     features: new GeoJSON().readFeatures(
    //       this.props.events.accelerometerEvents,
    //       { featureProjection: 'EPSG:3857' }
    //     )
    //   })
    // : new VectorSource({ features: [] });
    // const accelerometerEventsLayer = new VectorLayer({
    //   source: this.accelerometerEventsSource,
    //   style: this.getStyle
    // });

    this.select = new SelectInteraction({ style: this.getSelectStyle });
    this.select.on('select', (event) => {
      if (event.selected.length > 0) {
        this.selectedFeature = new GeoJSON().writeFeatureObject(
          event.selected[0],
          { featureProjection: 'EPSG:3857', rightHanded: true }
        );
        this.props.onSelectEvent({
          featureId: this.selectedFeature.id,
          typeId: this.selectedFeature.properties.typeId,
        });
        this.hoverSource.clear();
        this.props.onHoverEvent(null);
      }
    });

    this.map = new Map({
      target: this.refs.map,
      layers: [
        incidentsLayer,
        crimesLayer,
        intelligenceLayer, //,
        // accelerometerEventsLayer
      ],
      interactions: defaultInteractions({
        pinchRotate: false,
        altShiftDragRotate: false,
      }).extend([this.select]),
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
      controls: defaultControls({
        attribution: false,
        rotate: false,
        zoom: false,
      }),
    });
    this.map.on('click', (event) => {
      this.props.onSelectEvent(null);
    });

    setBaseLayers(this.map);

    this.hoverSource = new VectorSource();
    new VectorLayer({
      source: this.hoverSource,
      map: this.map,
      style: this.getHoverStyle,
    });

    this.map.on('pointermove', (event) => {
      if (!event.dragging && !this.getFeature()) {
        const pixel = this.map.getEventPixel(event.originalEvent);
        const feature = this.map.forEachFeatureAtPixel(
          pixel,
          (feature) => feature,
          {
            layerFilter: (layer) => {
              return [
                incidentsLayer,
                crimesLayer,
                intelligenceLayer, //,
                //accelerometerEventsLayer
              ].includes(layer);
            },
          }
        );

        if (feature) {
          const hoveredEventIndex = {
            featureId: feature.getId(),
            typeId: feature.get('typeId'),
          };
          if (!_.isEqual(hoveredEventIndex, this.props.hoveredEventIndex)) {
            this.hoverSource.clear();
            this.hoverSource.addFeature(feature);
            this.props.onHoverEvent(hoveredEventIndex);
          }
        } else {
          this.hoverSource.clear();
          this.props.onHoverEvent(null);
        }
      }
    });

    this.fitMapToContents();
  }

  componentDidUpdate(prevProps) {
    if (this.props.events) {
      if (_.isEqual(prevProps.events, this.props.events)) {
        //nothing has changed so do nothing
      } else {
        this.incidentsSource.clear({ fast: true });
        this.incidentsSource.addFeatures(
          new GeoJSON().readFeatures(this.props.events.incidents, {
            featureProjection: 'EPSG:3857',
          })
        );
        this.crimesSource.clear({ fast: true });
        this.crimesSource.addFeatures(
          new GeoJSON().readFeatures(this.props.events.crimes, {
            featureProjection: 'EPSG:3857',
          })
        );
        this.intelligenceSource.clear({ fast: true });
        this.intelligenceSource.addFeatures(
          new GeoJSON().readFeatures(this.props.events.intelligence, {
            featureProjection: 'EPSG:3857',
          })
        );
        // this.accelerometerEventsSource.clear({ fast: true });
        // this.accelerometerEventsSource.addFeatures(
        //   new GeoJSON().readFeatures(this.props.events.accelerometerEvents, {
        //     featureProjection: 'EPSG:3857'
        //   })
        // );
        this.fitMapToContents();
      }
    }

    if (this.getFeature()) {
      if (_.isEqual(prevProps.feature, this.getFeature())) {
        //nothing has changed so do nothing
      } else {
        const selectedFeature =
          this.select.getFeatures().length > 0
            ? new GeoJSON().writeFeatureObject(this.select.getFeatures()[0], {
                featureProjection: 'EPSG:3857',
                rightHanded: true,
              })
            : null;
        if (_.isEqual(this.getFeature(), selectedFeature)) {
          //nothing has changed so do nothing
        } else {
          this.select.getFeatures().clear();
          switch (this.getFeature().properties.typeId) {
            case 'incidents':
              this.select
                .getFeatures()
                .push(
                  this.incidentsSource.getFeatureById(this.getFeature().id)
                );
              break;
            case 'crimes':
              this.select
                .getFeatures()
                .push(this.crimesSource.getFeatureById(this.getFeature().id));
              break;
            case 'intelligence':
              this.select
                .getFeatures()
                .push(
                  this.intelligenceSource.getFeatureById(this.getFeature().id)
                );
              break;
            // case 'accelerometerEvents':
            //   this.select
            //     .getFeatures()
            //     .push(
            //       this.accelerometerEventsSource.getFeatureById(
            //         this.getFeature().id
            //       )
            //     );
            //   break;
            default:
          }
          this.hoverSource.clear();
          this.props.onHoverEvent(null);
        }
      }
    } else {
      this.select.getFeatures().clear();
    }

    if (!prevProps.feature) {
      if (this.props.hoveredEventIndex) {
        if (
          !_.isEqual(prevProps.hoveredEventIndex, this.props.hoveredEventIndex)
        ) {
          this.hoverSource.clear();
          switch (this.props.hoveredEventIndex.typeId) {
            case 'incidents':
              this.hoverSource.addFeature(
                this.incidentsSource.getFeatureById(
                  this.props.hoveredEventIndex.featureId
                )
              );
              break;
            case 'crimes':
              this.hoverSource.addFeature(
                this.crimesSource.getFeatureById(
                  this.props.hoveredEventIndex.featureId
                )
              );
              break;
            case 'intelligence':
              this.hoverSource.addFeature(
                this.intelligenceSource.getFeatureById(
                  this.props.hoveredEventIndex.featureId
                )
              );
              break;
            // case 'accelerometerEvents':
            //   this.hoverSource.addFeature(
            //     this.accelerometerEventsSource.getFeatureById(
            //       this.props.hoveredEventIndex.featureId
            //     )
            //   );
            //   break;
            default:
          }
        }
      } else {
        this.hoverSource.clear();
        this.props.onHoverEvent(null);
      }
    }
  }

  render() {
    return (
      <div className={this.props.classes.container}>
        <div id="map" className={this.props.classes.map} ref="map" />
        <IconButton
          title="Zoom In"
          className={this.props.classes.zoomInButton}
          aria-label="Zoom In"
          color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
          disabled={this.state.zoomInDisabled}
          onClick={this.handleZoomInClick}
        >
          <ZoomInIcon />
        </IconButton>
        <IconButton
          title="Zoom Out"
          className={this.props.classes.zoomOutButton}
          aria-label="Zoom Out"
          color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
          disabled={this.state.zoomOutDisabled}
          onClick={this.handleZoomOutClick}
        >
          <ZoomOutIcon />
        </IconButton>
        <IconButton
          title="Fit"
          className={this.props.classes.fitButton}
          aria-label="Fit"
          color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
          onClick={
            this.getFeature() ? this.fitMapToSelected : this.fitMapToContents
          }
        >
          <ZoomOutMapIcon />
        </IconButton>
        <IconButton
          title={this.state.showLabels ? 'Hide Labels' : 'Show Labels'}
          className={this.props.classes.labelButton}
          aria-label="Toggle Labels"
          color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
          onClick={this.handleLabelsToggleClick}
        >
          {this.state.showLabels ? <LabelOffIcon /> : <LabelIcon />}
        </IconButton>
        {layerSwitcher && (
          <IconButton
            title={
              this.state.satelliteMapVisible ? 'Road Map' : 'Satellite Map'
            }
            className={this.props.classes.layerButton}
            aria-label="Map"
            color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
            onClick={this.handleMapToggleClick}
          >
            {this.state.satelliteMapVisible ? <MapIcon /> : <SatelliteIcon />}
          </IconButton>
        )}
      </div>
    );
  }
}

CollectionMap.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    events: state.collections.events,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(CollectionMap));
