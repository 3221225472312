import {
  Avatar,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  DirectionsRun as DirectionsRunIcon,
  Timer as TimerIcon,
} from '@material-ui/icons';
import moment from 'moment';
import React, { Fragment } from 'react';
import { objectiveTypeIcons } from '../../../data/constants';
import { ContentViewer } from '../../controls';

const useStyles = makeStyles((theme) => ({
  complianceSeconds: {
    paddingRight: theme.spacing(2),
  },
  description: {
    padding: theme.spacing(2),
  },
  occurrence: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  icon: {
    verticalAlign: 'text-bottom',
    height: 16,
    width: 16,
  },
  areasSubheader: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: 12,
  },
  areasPaper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  areaType: {
    fontSize: 10,
  },
  areaName: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  cardContent: {
    width: '100%',
  },
  edits: {
    paddingLeft: theme.spacing(2),
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    width: 16,
    height: 16,
    fontSize: 8,
    marginRight: theme.spacing(0.25),
  },
  checkedAvatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 16,
    height: 16,
    fontSize: 8,
    marginRight: theme.spacing(0.25),
  },
  activeTimes: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
  },
  flex: {
    flex: 1,
  },
}));

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export default function ObjectiveContent({ feature }) {
  const classes = useStyles();

  return (
    feature && (
      <Fragment>
        <CardHeader
          avatar={
            <Avatar>{objectiveTypeIcons[feature.properties.type]}</Avatar>
          }
          title={feature.properties.title}
          subheader={feature.properties.identifier}
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardContent}>
          {feature.properties.created && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`Created by ${feature.properties.created.userId} ${moment(
                  feature.properties.created.time
                ).format('DD/MM/YYYY, HH:mm')}`}
              </Typography>
            </div>
          )}
          {feature.properties.lastEdit && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`Last edited by ${feature.properties.lastEdit.userId} ${moment(
                  feature.properties.lastEdit.time
                ).format('DD/MM/YYYY, HH:mm')}`}
              </Typography>
            </div>
          )}
          <div className={classes.activeTimes}>
            <Typography variant="caption">
              {`${moment(feature.properties.startTime).format(
                'DD/MM/YYYY'
              )} - ${moment(feature.properties.endTime).format('DD/MM/YYYY')}`}
            </Typography>
            <div className={classes.flex} />
            {feature.properties.type === 'Patrol' &&
            feature.properties.complianceSeconds ? (
              <Typography variant="caption">
                <TimerIcon className={classes.icon} />
                {`${feature.properties.complianceSeconds / 60}m`}
              </Typography>
            ) : (
              feature.properties.requiredVisits && (
                <Typography variant="caption">
                  {`${feature.properties.requiredVisits} visits ${feature.properties.requiredFrequency}`}
                </Typography>
              )
            )}
          </div>
          <div className={classes.activeTimes}>
            {days.map((day, index) => (
              <Avatar
                key={index}
                className={
                  (feature.properties.days || []).includes(index)
                    ? classes.checkedAvatar
                    : classes.avatar
                }
              >
                {day[0]}
              </Avatar>
            ))}
          </div>
          <div className={classes.activeTimes}>
            {[...Array(24).keys()].map((hour) => (
              <Avatar
                key={hour}
                className={
                  (feature.properties.hours || []).includes(hour)
                    ? classes.checkedAvatar
                    : classes.avatar
                }
              >
                {hour.toString()}
              </Avatar>
            ))}
          </div>
          {feature.properties.occurrenceNumber && (
            <Typography variant="caption" className={classes.occurrence}>
              <DirectionsRunIcon className={classes.icon} />
              {feature.properties.occurrenceNumber}
            </Typography>
          )}
          <div className={classes.description}>
            <ContentViewer content={feature.properties.description} />
          </div>
          {feature.properties.areas && (
            <Fragment>
              <Typography
                key="subheader"
                variant="subtitle1"
                className={classes.areasSubheader}
              >
                Assigned Groups/Areas
              </Typography>
              <Paper key="table" className={classes.areasPaper} elevation={0}>
                <Table>
                  <TableBody>
                    {feature.properties.areas.map((area, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography
                            variant="caption"
                            className={classes.areaType}
                          >
                            {area.type}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="caption"
                            className={classes.areaName}
                          >
                            {area.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Fragment>
          )}
        </CardContent>
      </Fragment>
    )
  );
}
