import {
  Avatar,
  Chip,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { AddCircle as AddIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import { DateTimePicker } from '../controls';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  helperText: {
    color: red[500],
  },
  parameterLabel: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const operators = {
  $eq: 'Equals',
  $ne: 'Does not equal',
  $gt: 'Greater than',
  $lt: 'Less than',
};

const typeOperators = {
  default: Object.entries(operators),
  time: Object.entries(operators).slice(2),
  text: Object.entries(operators).slice(0, 2),
  number: Object.entries(operators),
};

function getOperatorCharacter(operator) {
  switch (operator) {
    case '$eq': {
      return '=';
    }
    case '$ne': {
      return '≠';
    }
    case '$gt': {
      return '>';
    }
    case '$lt': {
      return '<';
    }
    default: {
      return '';
    }
  }
}

export default function ParameterField({ input, meta, label, values }) {
  const [operator, setOperator] = useState('');
  const [value, setValue] = useState('');
  const [helperText, setHelperText] = useState('');
  const classes = useStyles();

  function handleOperatorChange(event) {
    setOperator(event.target.value);
  }

  function handleValueChange(event) {
    setValue(event.target.value);
    setHelperText('');
  }

  function handleAddClick() {
    input.onChange([...(input.value || []), { operator, value }]);
    setOperator('');
    setValue('');
    setHelperText('');
  }

  function renderValueField() {
    switch (input.type) {
      case 'time':
        return (
          <DateTimePicker
            value={value}
            onChange={handleValueChange}
            ampm={false}
            className={classes.formControl}
            label="Value"
          />
        );
      case 'text':
      case 'number':
        return values ? (
          <TextField
            select
            className={classes.textField}
            label="Value"
            value={value}
            onChange={handleValueChange}
          >
            {values.map((value, index) => (
              <MenuItem
                key={index}
                value={Array.isArray(value) ? value[0] : value}
              >
                {Array.isArray(value) ? value[1] : value}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            label="Value"
            type={input.type}
            value={value}
            onChange={handleValueChange}
            className={classes.textField}
            helperText={helperText}
            error={helperText !== ''}
          />
        );
      default:
        return '';
    }
  }

  return (
    <div>
      <Typography variant="caption" className={classes.parameterLabel}>
        {label}
      </Typography>
      <div className={classes.row}>
        {(input.value || []).map((filter, index) => (
          <Chip
            key={index}
            className={classes.chip}
            avatar={
              <Avatar style={{ fontSize: 20, fontWeight: 500 }}>
                {getOperatorCharacter(filter.operator)}
              </Avatar>
            }
            label={
              input.type === 'time'
                ? moment(filter.value).format('DD/MM/YYYY, HH:mm')
                : filter.value
            }
            onDelete={() =>
              input.onChange(
                input.value.slice(0, index).concat(input.value.slice(index + 1))
              )
            }
          />
        ))}
      </div>
      <div className={classes.row}>
        <TextField
          select
          className={classes.textField}
          label="Operator"
          value={operator}
          onChange={handleOperatorChange}
        >
          {typeOperators[input.type].map((operator, index) => (
            <MenuItem key={index} value={operator[0]}>
              {operator[1]}
            </MenuItem>
          ))}
        </TextField>
        {renderValueField()}
        <IconButton
          className={classes.button}
          aria-label="Add"
          disabled={!operator || !value}
          onClick={handleAddClick}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}
