import {
  IconButton,
  InputLabel,
  Slider,
  TextField,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import { useInterval } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: 0,
  },
  timeCaption: {
    minWidth: 140,
  },
  fpsLabel: {
    paddingTop: 4,
  },
  fpsField: {
    padding: theme.spacing(1),
    paddingLeft: 0,
    width: 50,
    minWidth: 50,
  },
}));

export default function ReplayControls({
  time,
  frameCount,
  currentFrameIndex,
  onFrameChange,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [fps, setFps] = useState(1.0);
  const classes = useStyles();

  useInterval(advanceReplayFrame, isPlaying ? 1000 / fps : null);

  function advanceReplayFrame() {
    if (currentFrameIndex + 1 === frameCount) {
      setIsPlaying(false);
    } else {
      onFrameChange(currentFrameIndex + 1);
    }
  }

  function handlePlayPauseClick() {
    if (currentFrameIndex + 1 === frameCount && !isPlaying) {
      onFrameChange(0);
    }
    setIsPlaying(!isPlaying);
  }

  function handleFpsChange(event) {
    const value = event.target.value;
    const newFps =
      value === '' ? value : isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    setFps(newFps);
  }

  function handleSliderChange(event, value) {
    onFrameChange(value);
  }

  return (
    <Toolbar className={classes.toolbar} variant="dense">
      <IconButton
        onClick={handlePlayPauseClick}
        className={classes.playPauseButton}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <InputLabel shrink className={classes.fpsLabel}>
        FPS
      </InputLabel>
      <TextField
        type="number"
        margin="dense"
        id="fps"
        className={classes.fpsField}
        defaultValue={fps}
        onChange={handleFpsChange}
        inputProps={{
          style: { textAlign: 'right', fontSize: '0.75rem' },
        }}
      />
      <Typography variant="caption" className={classes.timeCaption}>
        {moment(time).format('DD/MM/YYYY, HH:mm:ss')}
      </Typography>
      <Slider
        value={currentFrameIndex}
        step={1}
        min={0}
        max={frameCount - 1}
        onChange={handleSliderChange}
      />
    </Toolbar>
  );
}
