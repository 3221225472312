import {
  Avatar,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import React, { Fragment } from 'react';
import { briefItemTypeIcons } from '../../../data/constants';
import { ContentViewer } from '../../controls';

const useStyles = makeStyles((theme) => ({
  description: {
    padding: theme.spacing(2),
  },
  areasSubheader: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: 12,
  },
  areasPaper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  areaType: {
    fontSize: 10,
  },
  areaName: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  edits: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function CollectionContent({ collection }) {
  const classes = useStyles();

  return (
    collection && (
      <Fragment>
        <CardHeader
          avatar={
            <Avatar aria-label="Collection" className={classes.avatar}>
              {briefItemTypeIcons[collection.type]}
            </Avatar>
          }
          title={collection.title}
          subheader={collection.identifier}
        />
        <CardContent>
          {collection.created && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`Created by ${collection.created.userId} ${moment(
                  collection.created.time
                ).format('DD/MM/YYYY, HH:mm')}`}
              </Typography>
            </div>
          )}
          {collection.lastEdit && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`Last edited by ${collection.lastEdit.userId} ${moment(
                  collection.lastEdit.time
                ).format('DD/MM/YYYY, HH:mm')}`}
              </Typography>
            </div>
          )}
          {collection.startTime && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`${moment(collection.startTime).format(
                  'DD/MM/YYYY'
                )} - ${moment(collection.endTime).format('DD/MM/YYYY')}`}
              </Typography>
            </div>
          )}
          <div className={classes.description}>
            <ContentViewer content={collection.description} />
          </div>
          {collection.areas && (
            <Fragment>
              <Typography
                key="subheader"
                variant="subtitle1"
                className={classes.areasSubheader}
              >
                Assigned Groups/Areas
              </Typography>
              <Paper key="table" className={classes.areasPaper} elevation={0}>
                <Table>
                  <TableBody>
                    {collection.areas.map((area, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography
                            variant="caption"
                            className={classes.areaType}
                          >
                            {area.type}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="caption"
                            className={classes.areaName}
                          >
                            {area.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Fragment>
          )}
        </CardContent>
      </Fragment>
    )
  );
}
