import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  // Badge,
  Typography,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Close as CloseIcon,
  LocationSearching as FollowIcon,
  GpsFixed as FollowAllIcon,
  FilterCenterFocus as FocusIcon,
  Done as DoneIcon,
  PinDrop as PinDropIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_LIVE_FOLLOW_OVERRIDE,
  UPDATE_LIVE_FILTER_OVERRIDE,
} from '../../../actions';
import { incidentStatusColours, liveFilters } from '../../../data/constants';
import { usePrevious } from '../../../hooks';
import avatarForItem from './avatarUtility';
import { ItemRows /*areasToLabelAccessors*/ } from './ItemRows';
import PeopleLiveListItem from './PeopleLiveListItem';
import VehicleLiveListItem from './VehicleLiveListItem';
import { TagControl } from '../../controls';
import useLazyLoadIncident from './incidentUtility';

// example item
// $type: "add"
// description: "Domestic Violence"
// grade: 5
// number: "DEV-111111-1111"
// openedTime: "2020-01-01T00:00:00.000Z"
// point:
// $reql_type$: "GEOMETRY"
// coordinates: (2) [-0.0858705, 51.5255501]
// type: "Point"
// responseCategory:
//  code: "5"
//  name: "Grade 5"
// searchString: "domestic violence+5+dev-111111-1111+2020-01-01t00:00:00.000z+[object object]+[object object]+opened+test1+[object object]+add+true"
// status: "opened"
// tagChanged: true
// tags: Array(1)
// 0: "test1"
// length: 1
// type:
//  code: "Test"
//  name: "Test/Training"

function FindAssignedResources(incidentNumber, resources) {
  return Object.keys(resources)
    .map((key) => {
      const resource = resources[key];
      return resource?.assignments?.incident?.number === incidentNumber
        ? {
            ...resource,
            id: key,
          }
        : null;
    })
    .filter(Boolean);
}

function CreateFilterOverride(
  incidentNumber,
  assignedVehicles,
  assignedPeoples
) {
  // a true dictionary allows faster lookup than an array of ids, and quicker
  // to deep compare than a dictionary of objects
  function toTrueDictionary(idArray) {
    return Object.fromEntries(idArray.map((resource) => [resource.id, true]));
  }
  const assignedVehiclesDict = toTrueDictionary(assignedVehicles);
  const assignedPeopleDict = toTrueDictionary(assignedPeoples);

  return {
    // make every type filtered out
    ...Object.fromEntries(Object.keys(liveFilters).map((k) => [k, {}])),
    // except for assigned vehicles & people and the incident
    vehicles: assignedVehiclesDict,
    people: assignedPeopleDict,
    incidents: { [incidentNumber]: true },
    // and set the owner of the override (when we nav away etc.)
    owner: incidentNumber,
  };
}

function IncidentLiveItem({
  item,
  classes,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  onFollowBulk,
  followedIdsByType,
  hoveredId,
}) {
  const incident = useLazyLoadIncident(item.id);
  const hideFollow = !incident.point;
  const rowItems = [
    //   { label: 'Type', value: incident.type && incident.type.name },
    //   { label: 'Status', value: incident.status },
    { label: 'Description', value: incident.description },
    //   { label: 'Tags', value: incident.tags.join(',') },
    //   ...areasToLabelAccessors(incident.areas)
    { label: 'Response Category', value: incident.responseCategory?.name },
  ];

  // const gradeBackground = ['rgba(255,0,0,1.0)', 'rgba(255,0,0,1.0)', 'rgba(255,204,0,1.0)', 'rgba(0,255,0,1.0)', 'rgba(0,0,255,1.0)'][
  //   Math.max(incident.grade, 5) - 1
  // ];
  const {
    openedTime,
    assignedTime,
    attendedTime,
    closedTime,
    // responseCategory,
    // grade,
    closingCodes,
  } = incident;

  const type = 'incidents';
  const following = followedIdsByType?.[type]?.[incident.id];

  // find assigned resources
  const vehicles = useSelector((state) => state.live.vehicles);
  const people = useSelector((state) => state.live.people);

  const totalResources =
    Object.keys(vehicles).length + Object.keys(people).length;
  const prevTotalResources = usePrevious(totalResources);

  const dispatch = useDispatch();

  // have to do this every time in case a vehicle/person assignment changes
  const assignedVehicles = FindAssignedResources(incident.id, vehicles);
  const assignedPeople = FindAssignedResources(incident.id, people);

  const allResourcesFollowed =
    assignedVehicles.every((v) => followedIdsByType['vehicles']?.[v.id]) &&
    assignedPeople.every((p) => followedIdsByType['people']?.[p.id]);

  const override = CreateFilterOverride(
    incident.id,
    assignedVehicles,
    assignedPeople
  );

  const prevOverride = usePrevious(override);

  const filterOverride = useSelector(
    (state) => state.live.filteredInIdsByTypeOverride
  );
  const followOverride = useSelector(
    (state) => state.live.followedIdsByTypeOverride
  );

  // if the incident overrides the follow or filter it'll be the owner
  const incidentOverridesFollow = followOverride?.owner === incident.id;
  const incidentOverridesFilter = filterOverride?.owner === incident.id;

  const handleFilterToggle = () => {
    dispatch({
      type: UPDATE_LIVE_FILTER_OVERRIDE,
      payload: incidentOverridesFilter ? null : override,
    });
  };

  const handleFollowToggle = () => {
    onFollowToggle(type, incident.id);
  };

  const handleFollowAllToggle = () => {
    const newFollow = !allResourcesFollowed;
    function toIdDict(itemArray) {
      const idDict = {};
      itemArray.forEach((item) => (idDict[item.id] = true));
      return idDict;
    }

    const items = {
      vehicles: toIdDict(assignedVehicles),
      people: toIdDict(assignedPeople),
    };
    onFollowBulk(items, newFollow);
    // assignedVehicles.forEach(v => onFollowToggle('vehicles', v.id, newFollow));
    // assignedPeople.forEach(p => onFollowToggle('people', p.id, newFollow));
  };

  // if the assigned resources changes dispatch a new override (if overriding)
  useEffect(() => {
    const overrideChanged = !_.isEqual(override, prevOverride);
    const numResourcesChanged = totalResources !== prevTotalResources;

    if (overrideChanged || numResourcesChanged) {
      if (incidentOverridesFollow) {
        dispatch({
          type: UPDATE_LIVE_FOLLOW_OVERRIDE,
          payload: override,
        });
      }

      if (incidentOverridesFilter) {
        dispatch({
          type: UPDATE_LIVE_FILTER_OVERRIDE,
          payload: override,
        });
      }
    }
  }, [
    dispatch,
    incidentOverridesFilter,
    incidentOverridesFollow,
    override,
    prevOverride,
    prevTotalResources,
    totalResources,
  ]);

  // const filteredIncidentsDict = useSelector(
  //   (state) => state.live.filteredIncidentsDict
  // );
  // const shouldFilter = filteredIncidentsDict[incident.id];
  // const prevShouldFilter = usePrevious(shouldFilter);

  // // if previous filter changes, dispatch a new filter override
  // useEffect(() => {
  //   if (shouldFilter) {
  //     // only dispatch a new filter if the filter toggle changed or filter itself changed
  //     const shouldFilterChanged = prevShouldFilter !== shouldFilter;
  //     const overrideChanged = !_.isEqual(override, prevOverride);
  //     const numResourcesChanged = totalResources !== prevTotalResources;
  //     if (shouldFilterChanged || overrideChanged || numResourcesChanged) {
  //       dispatch({
  //         type: UPDATE_LIVE_FOLLOW_OVERRIDE,
  //         payload: override,
  //       });
  //     }
  //   } else if (prevShouldFilter !== shouldFilter) {
  //     // only dispatch clearing the override if the filter toggle changed
  //     dispatch({
  //       type: UPDATE_LIVE_FOLLOW_OVERRIDE,
  //       payload: null,
  //     });
  //   }
  // }, [
  //   dispatch,
  //   override,
  //   prevOverride,
  //   shouldFilter,
  //   prevShouldFilter,
  //   totalResources,
  //   prevTotalResources,
  // ]);

  // const [showFilter, setShowFilter] = useState(shouldFilter);
  // const handleFilterToggle = () => {
  //   const show = !showFilter;
  //   setShowFilter(show);

  //   if (show) {
  //     // this saves it to the incident in case of updates
  //     dispatch({
  //       payload: {
  //         ...filteredIncidentsDict,
  //         [incident.id]: true,
  //       },
  //     });
  //   } else {
  //     dispatch({
  //       payload: {
  //         ...filteredIncidentsDict,
  //         [incident.id]: false,
  //       },
  //     });
  //   }
  // };

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>{`IR3 | Live | Incidents | ${incident.number}`}</title>
      </Helmet>
      <CardHeader
        avatar={
          avatarForItem(incident, 'incidents')
          // <Badge
          //   badgeContent={
          //     responseCategory && responseCategory.code
          //       ? responseCategory.code
          //       : grade || '?'
          //   }
          //   color="primary"
          // >
          // {avatarForItem(incident, 'incidents')}
          // </Badge>
        }
        title={incident.primary || incident.type?.name}
        subheader={incident.id || incident.number}
        action={
          <React.Fragment>
            <IconButton
              aria-label="Focus while incident selected"
              onClick={handleFilterToggle}
              title="Focus while incident selected"
            >
              <FocusIcon
                fontSize="small"
                color={incidentOverridesFilter ? 'primary' : 'inherit'}
              />
            </IconButton>
            {!hideFollow && (
              <IconButton
                aria-label="Toggle follow incident"
                onClick={handleFollowToggle}
                title="Toggle follow incident"
              >
                <FollowIcon
                  fontSize="small"
                  color={following ? 'primary' : 'inherit'}
                />
              </IconButton>
            )}
          </React.Fragment>
        }
        // action={<Avatar style={{background: gradeBackground}}>{incident.responseCategory.code}</Avatar>}
      />
      <CardContent>
        <TagControl item={incident} type={'incidents'} />
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small" className={classes.table}>
          <TableBody>{ItemRows(rowItems, incident)}</TableBody>
        </Table>
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical">
          {openedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    className={classes.subAvatar}
                    style={{
                      background: incidentStatusColours['opened'],
                    }}
                  >
                    <AddIcon className={classes.timelineIcon} />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {openedTime &&
                      moment(openedTime).format('DD/MM/YYYY, HH:mm:ss')}
                  </Typography>
                }
              >
                Opened
              </StepLabel>
              <StepContent>
                {incident.type && (
                  <Typography variant="caption">
                    {incident.type.code}
                  </Typography>
                )}
              </StepContent>
            </Step>
          )}
          {assignedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    className={classes.subAvatar}
                    style={{
                      background: incidentStatusColours['assigned'],
                    }}
                  >
                    <DoneIcon className={classes.timelineIcon} />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {moment(assignedTime).format('DD/MM/YYYY, HH:mm:ss')}
                  </Typography>
                }
              >
                Assigned
              </StepLabel>
              <StepContent />
            </Step>
          )}
          {attendedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    className={classes.subAvatar}
                    style={{
                      background: incidentStatusColours['attended'],
                    }}
                  >
                    <PinDropIcon className={classes.timelineIcon} />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {moment(attendedTime).format('DD/MM/YYYY, HH:mm:ss')}
                  </Typography>
                }
              >
                Attended
              </StepLabel>
              <StepContent />
            </Step>
          )}
          {closedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    className={classes.subAvatar}
                    style={{
                      background: incidentStatusColours['closed'],
                    }}
                  >
                    <CloseIcon className={classes.timelineIcon} />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {moment(closedTime).format('DD/MM/YYYY, HH:mm:ss')}
                  </Typography>
                }
              >
                Closed
              </StepLabel>
              <StepContent>
                {!closingCodes
                  ? ''
                  : (closingCodes || []).map((cc, index) => (
                      <Typography variant="caption" key={index}>
                        {cc.code}
                      </Typography>
                    ))}
              </StepContent>
            </Step>
          )}
        </Stepper>
        {assignedVehicles.length + assignedPeople.length > 0 && (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                Assigned resources
              </Typography>
              <IconButton
                aria-label="Toggle follow all"
                onClick={handleFollowAllToggle}
                title={`Toggle follow all`}
                style={{ marginRight: 16 }}
              >
                <FollowAllIcon
                  fontSize="small"
                  color={allResourcesFollowed ? 'primary' : 'inherit'}
                />
              </IconButton>
            </div>
            {assignedPeople.map((person) => (
              <PeopleLiveListItem
                key={person.id}
                onClick={onSubItemClick}
                highlighted={hoveredId === person.id}
                onHoverItem={onSubItemHover}
                onFollowToggle={onFollowToggle}
                followedIdsByType={followedIdsByType}
                classes={classes}
                item={person}
                // tertiaryPath={'assignments.callSign.code'}
                // hideFollow={true}
              />
            ))}
            {assignedVehicles.map((vehicle) => (
              <VehicleLiveListItem
                key={vehicle.id}
                onClick={onSubItemClick}
                highlighted={hoveredId === vehicle.id}
                onHoverItem={onSubItemHover}
                onFollowToggle={onFollowToggle}
                followedIdsByType={followedIdsByType}
                classes={classes}
                item={vehicle}
                hideLive={false}
                // tertiarPath={'assignments.callSign.code'}
                // hideFollow={true}
              />
            ))}
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );

  // return (
  //   <React.Fragment>
  //     <div className={{ root: { flexGrow: 1 } }}>
  //       <Grid container style={{ padding: '8px' }}>
  //         <Grid item xs={12}>
  //           <h4>Incident Status</h4>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <Typography className={classes.cardText}>
  //             Number: {incident.number}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Description: {incident.description}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Grade: {incident.grade}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Opened: {moment(incident.openedTime).format('DD/MM/YYYY hh:mm:ss')}
  //           </Typography>
  //           {incident.type && (
  //             <Typography className={classes.cardText}>
  //               Type: {incident.type.name}
  //             </Typography>
  //           )}
  //           {incident.category && (
  //             <Typography className={classes.cardText}>
  //               Category: {incident.category.name}
  //             </Typography>
  //           )}
  //         </Grid>
  //       </Grid>
  //     </div>
  //   </React.Fragment>
  // );
}

export default IncidentLiveItem;
