import _ from 'lodash';
import {
  FETCH_PEOPLE,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE,
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_FAILURE,
  CREATE_PERSON,
  CREATE_PERSON_SUCCESS,
  CREATE_PERSON_FAILURE,
  UPDATE_PERSON,
  UPDATE_PERSON_SUCCESS,
  UPDATE_PERSON_FAILURE,
  DELETE_PERSON,
  DELETE_PERSON_SUCCESS,
  DELETE_PERSON_FAILURE,
  FETCH_RFID_CARDS,
  FETCH_RFID_CARDS_SUCCESS,
  FETCH_RFID_CARDS_FAILURE,
  FETCH_RFID_CARD_PEOPLE,
  FETCH_RFID_CARD_PEOPLE_SUCCESS,
  FETCH_RFID_CARD_PEOPLE_FAILURE,
  REMOVE_RFID_CARD,
  REMOVE_RFID_CARD_SUCCESS,
  REMOVE_RFID_CARD_FAILURE,
  FETCH_POST_HIERARCHY,
  FETCH_POST_HIERARCHY_SUCCESS,
  FETCH_POST_HIERARCHY_FAILURE,
} from '../actions';

const INITIAL_STATE = {
  people: [],
  person: null,
  rfidCards: [],
  rfidCardPeople: [],
  postHierarchy: {
    code: '',
    name: 'Root',
    children: [],
  },
  isLoading: false,
  error: null,
};

export default function peopleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PEOPLE:
      return { ...state, isLoading: true, error: null };
    case FETCH_PEOPLE_SUCCESS:
      return {
        ...state,
        people: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_PEOPLE_FAILURE:
      return {
        ...state,
        people: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_PERSON:
      return { ...state, isLoading: true, error: null };
    case FETCH_PERSON_SUCCESS:
      return {
        ...state,
        person: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_PERSON_FAILURE:
      return {
        ...state,
        person: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_PERSON:
      return { ...state, isLoading: true, error: null };
    case UPDATE_PERSON_SUCCESS:
      return {
        ...state,
        person: action.payload,
        people: state.people.map((person) =>
          person.code === action.payload.code
            ? {
                code: action.payload.code,
                picture: action.payload.picture,
                forenames: action.payload.forenames,
                surname: action.payload.surname,
                collarNumber: action.payload.collarNumber,
                rfidCards: action.payload.rfidCards,
                searchString: (
                  `${action.payload.forenames} ${action.payload.surname}+` +
                  `${_.join(
                    (action.payload.rfidCards || []).map(
                      (card) => card.reference
                    ),
                    '+'
                  )}+` +
                  `${_.join(
                    _.values(
                      _.omit(action.payload, [
                        'forenames',
                        'surname',
                        'rfidCards',
                        'picture',
                      ])
                    ),
                    '+'
                  )}`
                ).toLowerCase(),
              }
            : person
        ),
        isLoading: false,
        error: null,
      };
    case UPDATE_PERSON_FAILURE:
      return {
        ...state,
        person: null,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_PERSON:
      return { ...state, isLoading: true, error: null };
    case CREATE_PERSON_SUCCESS:
      return {
        ...state,
        person: action.payload,
        people: state.people.concat({
          code: action.payload.code,
          picture: action.payload.picture,
          forenames: action.payload.forenames,
          surname: action.payload.surname,
          collarNumber: action.payload.collarNumber,
          rfidCards: action.payload.rfidCards,
          searchString: (
            `${action.payload.forenames} ${action.payload.surname}+` +
            `${_.join(
              (action.payload.rfidCards || []).map((card) => card.reference),
              '+'
            )}+` +
            `${_.join(
              _.values(
                _.omit(action.payload, [
                  'forenames',
                  'surname',
                  'rfidCards',
                  'picture',
                ])
              ),
              '+'
            )}`
          ).toLowerCase(),
        }),
        isLoading: false,
        error: null,
      };
    case CREATE_PERSON_FAILURE:
      return {
        ...state,
        person: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_PERSON:
      return { ...state, isLoading: true, error: null };
    case DELETE_PERSON_SUCCESS:
      return {
        ...state,
        person: null,
        people: state.people.filter((person) => person.code !== action.payload),
        isLoading: false,
        error: null,
      };
    case DELETE_PERSON_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_RFID_CARDS:
      return { ...state, isLoading: true, error: null };
    case FETCH_RFID_CARDS_SUCCESS:
      return {
        ...state,
        rfidCards: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_RFID_CARDS_FAILURE:
      return {
        ...state,
        rfidCards: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_RFID_CARD_PEOPLE:
      return { ...state, isLoading: true, error: null };
    case FETCH_RFID_CARD_PEOPLE_SUCCESS:
      return {
        ...state,
        rfidCardPeople: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_RFID_CARD_PEOPLE_FAILURE:
      return {
        ...state,
        rfidCardPeople: [],
        isLoading: false,
        error: action.payload,
      };

    case REMOVE_RFID_CARD:
      return { ...state, isLoading: true, error: null };
    case REMOVE_RFID_CARD_SUCCESS:
      return {
        ...state,
        person: null,
        rfidCardPeople: state.rfidCardPeople.filter(
          (person) => person.code !== action.payload
        ),
        isLoading: false,
        error: null,
      };
    case REMOVE_RFID_CARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_POST_HIERARCHY:
      return { ...state, isLoading: true, error: null };
    case FETCH_POST_HIERARCHY_SUCCESS:
      return {
        ...state,
        postHierarchy: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_POST_HIERARCHY_FAILURE:
      return {
        ...state,
        postHierarchy: {
          code: '',
          name: 'Root',
          children: [],
        },
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
