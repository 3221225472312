import React from 'react';
import { IconButton } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useSideNav } from '../SideNav';

export default function MenuButton(props) {
  const sideNav = useSideNav();

  return (
    <IconButton aria-label="Menu" onClick={sideNav.open} {...props}>
      <MenuIcon />
    </IconButton>
  );
}
