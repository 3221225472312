import React, {
  useState,
  useRef,
  useContext,
  createContext,
  Fragment,
} from 'react';
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { useAuth } from './Auth';
import ir3Logo from '../svg/ir3Logo.svg';
import { siteMap, isVisible } from '../routing';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
  },
  list: {
    width: 280,
    flex: 'initial',
  },
  rootList: {
    paddingRight: theme.spacing(2),
  },
  title: {
    paddingLeft: theme.spacing(0.5),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    flex: 1,
  },
  version: {
    right: theme.spacing(1),
  },
  listItem: {
    // color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(1),
    // width: 232,
    height: 40,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  primary: {
    fontWeight: 500,
  },
  active: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.background,
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
}));

const SideNavContext = createContext();

export const useSideNav = () => useRef(useContext(SideNavContext)).current;

function SideNav({ isOpen, close }) {
  const [expandedNodes, setExpandedNodes] = useState([]);
  const auth = useAuth();
  const classes = useStyles();
  const theme = useTheme();

  function isExpanded(key) {
    return expandedNodes.includes(key);
  }

  const toggleNode = (key) => () => {
    if (isExpanded(key)) {
      setExpandedNodes(
        expandedNodes.filter((expandedKey) => expandedKey !== key)
      );
    } else {
      setExpandedNodes(expandedNodes.concat(key));
    }
  };

  function Tree({ siteMap, level }) {
    return siteMap
      .filter(({ key }) => isVisible(key, auth))
      .map(({ key, label, Icon, path, children }) =>
        !!path ? (
          <ListItem
            key={key}
            dense
            button
            component={NavLink}
            to={path}
            exact={true}
            activeClassName={classes.active}
            className={classes.listItem}
            onClick={close}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText
              primary={label}
              classes={{ primary: classes.primary }}
            />
          </ListItem>
        ) : (
          <Fragment key={key}>
            <ListItem
              dense
              button
              onClick={toggleNode(key)}
              className={classes.listItem}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText
                primary={label}
                classes={{ primary: classes.primary }}
              />
              {isExpanded(key) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={isExpanded(key)} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: (level + 1) * theme.spacing(1) }}
              >
                <Tree siteMap={children} level={level + 1} />
              </List>
            </Collapse>
          </Fragment>
        )
      );
  }

  return (
    <Drawer open={isOpen} onClose={close}>
      <div className={classes.list}>
        <div className={classes.header}>
          <img src={ir3Logo} alt="IR3 Logo" style={{ height: 16 }} />
          <Typography variant="h5" className={classes.title}>
            IR3
          </Typography>
          <Typography variant="caption" className={classes.version}>
            v{process.env.REACT_APP_VERSION}
          </Typography>
        </div>
        <List disablePadding className={classes.rootList}>
          <Tree siteMap={siteMap} level={0} />
        </List>
      </div>
    </Drawer>
  );
}

export function SideNavProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  return (
    <SideNavContext.Provider
      value={{
        open,
        close,
        isOpen,
      }}
    >
      <SideNav isOpen={isOpen} close={close} />
      {children}
    </SideNavContext.Provider>
  );
}
