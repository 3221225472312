import { IconButton, makeStyles } from '@material-ui/core';
import {
  Map as MapIcon,
  Satellite as SatelliteIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons';
import { Collection, Feature, Map, View } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { altKeyOnly } from 'ol/events/condition';
import { GeoJSON } from 'ol/format';
import {
  defaults as defaultInteractions,
  MouseWheelZoom as MouseWheelZoomInteraction,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style, Circle, Icon } from 'ol/style';
import React, { useState, useEffect, useRef } from 'react';
import { Point } from 'ol/geom';
import { setBaseLayers } from '../../mapStyles';
import {
  accelerometerGlyph,
  vehicleStopGlyph,
  idleGlyph,
  eventGlyph,
} from '../../data/constants';

const { layerSwitcher } = window.config;

const useStyles = makeStyles({
  map: {
    width: '100%',
    height: '100%',
  },
  zoomInButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    zIndex: 100,
  },
  zoomOutButton: {
    position: 'absolute',
    top: 40,
    left: 5,
    zIndex: 100,
  },
  fitButton: {
    position: 'absolute',
    top: 75,
    left: 5,
    zIndex: 100,
  },
  layerButton: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    zIndex: 100,
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
});

const defaultStyle = (mapType) => (feature) => {
  switch (feature.get('name')) {
    case 'path':
      const coordinates = feature.getGeometry().getCoordinates();

      const lineStyle = new Style({
        stroke: new Stroke({
          color: 'rgba(0,0,0,1)',
          width: 2,
        }),
      });
      let styles = [lineStyle];

      const startStyle = new Style({
        image: new Circle({
          radius: 5,
          fill: new Fill({
            color: 'rgba(76,175,80,1)',
          }),
        }),
        geometry: new Point(coordinates[0]),
        // zIndex: schema.zindex + 1 || 101
      });

      styles.push(startStyle);

      const endStyle = new Style({
        image: new Circle({
          radius: 5,
          fill: new Fill({
            color: 'rgba(244,67,54,1)',
          }),
        }),
        geometry: new Point(coordinates[coordinates.length - 1]),
        // zIndex: schema.zindex + 1 || 101
      });

      styles.push(endStyle);

      return styles;
    case 'point':
      return [
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({
              color: 'rgb(33,150,243)',
            }),
          }),
        }),
        new Style({
          image: new Icon({
            src: iconType(mapType),
            scale: 0.5,
          }),
        }),
      ];
    default:
      return [];
  }
};

const iconType = (mapType) => {
  switch (mapType) {
    case 'accelerometerEvents':
      return accelerometerGlyph;
    case 'stops':
      return vehicleStopGlyph;
    case 'idles':
      return idleGlyph;
    default:
      return eventGlyph;
  }
};

export default function EventPreviewMap({ point, path, mapType }) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  const [satelliteMapVisible, setSatelliteMapVisible] = useState(false);

  const mapDiv = useRef(null);
  const vectorSource = useRef(null);
  const map = useRef(null);
  const classes = useStyles();

  function resetMapControls() {
    setZoomInDisabled(
      map.current.getView().getZoom() === map.current.getView().getMaxZoom()
    );
    setZoomOutDisabled(
      map.current.getView().getZoom() === map.current.getView().getMinZoom()
    );
  }

  useEffect(() => {
    if (!map.current) {
      vectorSource.current = new VectorSource({
        features: new Collection([]),
      });

      map.current = new Map({
        target: mapDiv.current,
        layers: [
          new VectorLayer({
            source: vectorSource.current,
            style: defaultStyle(mapType),
          }),
        ],
        interactions: defaultInteractions({
          mouseWheelZoom: false,
          pinchRotate: false,
          altShiftDragRotate: false,
        }).extend([
          new MouseWheelZoomInteraction({
            condition: altKeyOnly,
          }),
        ]),
        view: new View({
          center: [0, 0],
          zoom: 2,
        }),
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
      });

      setBaseLayers(map.current);
    }
  }, [mapType]);

  useEffect(() => {
    vectorSource.current.clear();

    if (path) {
      vectorSource.current.addFeature(
        new Feature({
          geometry: new GeoJSON().readGeometry(path, {
            featureProjection: 'EPSG:3857',
          }),
          name: 'path',
        })
      );
    }

    if (point) {
      vectorSource.current.addFeature(
        new Feature({
          geometry: new GeoJSON().readGeometry(point, {
            featureProjection: 'EPSG:3857',
          }),
          name: 'point',
        })
      );
    }

    const geometry = new GeoJSON().readGeometry(path || point, {
      featureProjection: 'EPSG:3857',
    });
    map.current.getView().fit(geometry, { maxZoom: 18 });
    resetMapControls();
  }, [path, point]);

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      resetMapControls();
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      resetMapControls();
    }
  }

  function handleFitClick() {
    if (vectorSource.current && vectorSource.current.getFeatures().length > 0) {
      map.current
        .getView()
        .fit(vectorSource.current.getExtent(), { maxZoom: 18 });
      resetMapControls();
    }
  }

  function handleLayerButtonClick() {
    if (map.current) {
      map.current
        .getLayers()
        .item(satelliteMapVisible ? 0 : 1)
        .setVisible(true);
      map.current
        .getLayers()
        .item(satelliteMapVisible ? 1 : 0)
        .setVisible(false);
      setSatelliteMapVisible(!satelliteMapVisible);
    }
  }

  return (
    <div className={classes.container}>
      <div id="map" className={classes.map} ref={mapDiv} />
      <IconButton
        title="Zoom In"
        className={classes.zoomInButton}
        aria-label="Zoom In"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={zoomInDisabled}
        onClick={handleZoomInClick}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        title="Zoom Out"
        className={classes.zoomOutButton}
        aria-label="Zoom Out"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={zoomOutDisabled}
        onClick={handleZoomOutClick}
      >
        <ZoomOutIcon />
      </IconButton>
      <IconButton
        title="Fit"
        className={classes.fitButton}
        aria-label="Fit"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        onClick={handleFitClick}
      >
        <ZoomOutMapIcon />
      </IconButton>
      {layerSwitcher && (
        <IconButton
          title={satelliteMapVisible ? 'Road Map' : 'Satellite Map'}
          className={classes.layerButton}
          aria-label="Map"
          color={satelliteMapVisible ? 'inherit' : 'default'}
          onClick={handleLayerButtonClick}
        >
          {satelliteMapVisible ? <MapIcon /> : <SatelliteIcon />}
        </IconButton>
      )}
    </div>
  );
}
