import { IconButton, makeStyles } from '@material-ui/core';
import {
  Label as LabelIcon,
  LabelOff as LabelOffIcon,
  Map as MapIcon,
  Satellite as SatelliteIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons';
import { Map, View } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { applyTransform, extend } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import {
  defaults as defaultInteractions,
  Select as SelectInteraction,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { getTransform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { click, pointerMove } from 'ol/events/condition';
import React, { useState, useEffect, useRef } from 'react';
import {
  getDirectedPathStyle,
  getReplayStyle,
  setBaseLayers,
} from '../../mapStyles';
import { useEffectOnce } from '../../hooks';

const { mapExtent, layerSwitcher } = window.config;

const useStyles = makeStyles({
  map: {
    width: '100%',
    height: '100%',
  },
  zoomInButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    zIndex: 100,
  },
  zoomOutButton: {
    position: 'absolute',
    top: 40,
    left: 5,
    zIndex: 100,
  },
  fitButton: {
    position: 'absolute',
    top: 75,
    left: 5,
    zIndex: 100,
  },
  labelButton: {
    position: 'absolute',
    top: 110,
    left: 5,
    zIndex: 100,
  },
  layerButton: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    zIndex: 100,
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    color: '#fff',
  },
});

const typeGroups = {
  location: 'locations',
  event: 'events',
  incident: 'incidents',
  vehicle: 'vehicles',
  person: 'people',
  objective: 'objectives',
};

const emptyFeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};

export default function ReplayMap({
  selectedItemIndex,
  followedItemIndexes,
  hoveredItemIndex,
  featureCollections,
  paths,
  onSelectItem,
  onHoverItem,
  isMobilePreviewMap,
}) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  const [satelliteMapVisible, setSatelliteMapVisible] = useState(false);
  const [showLabelsIcon, setShowLabelsIcon] = useState(false);
  const [refitMap, setRefitMap] = useState(false);

  const mapDiv = useRef(null);
  const itemSources = useRef({});
  const itemLayers = useRef([]);
  const pathSources = useRef({});
  const pathLayers = useRef([]);
  const map = useRef(null);
  const select = useRef(null);
  const hover = useRef(null);
  const showLabels = useRef(false);
  const classes = useStyles();

  function getDirectedStyle(feature) {
    return getDirectedPathStyle(
      feature,
      'default',
      showLabels.current,
      map.current.getView().getZoom()
    );
  }

  function getDefaultStyle(feature) {
    return getReplayStyle(
      'default',
      feature,
      showLabels.current,
      map.current.getView().getZoom()
    );
  }

  function getSelectStyle(feature) {
    return getReplayStyle(
      'select',
      feature,
      showLabels.current,
      map.current.getView().getZoom()
    );
  }

  function getHoverStyle(feature) {
    return getReplayStyle(
      'hover',
      feature,
      showLabels.current,
      map.current.getView().getZoom()
    );
  }

  function handleSelect(event) {
    hover.current.getFeatures().clear();
    select.current.getFeatures().clear();

    if (event.selected.length > 0) {
      onSelectItem({
        id: event.selected[0].getId(),
        type: event.selected[0].get('type'),
      });
      onHoverItem({});
    } else {
      onSelectItem({});
    }
  }

  function handleHover(event) {
    if (event.selected.length > 0) {
      onHoverItem({
        id: event.selected[0].getId(),
        type: event.selected[0].get('type'),
      });
    } else {
      onHoverItem({});
    }
  }

  function resetMapControls() {
    setZoomInDisabled(
      map.current.getView().getZoom() === map.current.getView().getMaxZoom()
    );
    setZoomOutDisabled(
      map.current.getView().getZoom() === map.current.getView().getMinZoom()
    );
  }

  useEffect(() => {
    if (refitMap) {
      const extents =
        followedItemIndexes.length > 0
          ? followedItemIndexes.map((index) =>
              itemSources.current[typeGroups[index.type]]
                .getFeatureById(index.id)
                .getGeometry()
                .getExtent()
            )
          : Object.values(pathSources.current).map((source) =>
              source.getExtent()
            );

      let extent = null;
      extents.forEach((ex) => {
        if (extent) {
          extent = extend(extent, ex);
        } else {
          extent = ex;
        }
      });

      if (
        extent[0] === Infinity ||
        extent[1] === Infinity ||
        extent[2] === -Infinity ||
        extent[3] === -Infinity
      ) {
        map.current
          .getView()
          .fit(
            applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857'))
          );
      } else {
        map.current.getView().fit(extent, {
          maxZoom:
            followedItemIndexes.length > 0
              ? map.current.getView().getZoom()
              : 17,
        });
      }

      resetMapControls();
      setRefitMap(false);
    }
  }, [followedItemIndexes, refitMap]);

  function fitMap() {
    setRefitMap(true);
  }

  useEffectOnce(() => {
    if (!map.current) {
      itemLayers.current = Object.values(typeGroups).map((group) => {
        itemSources.current[group] = featureCollections
          ? new VectorSource({
              features: new GeoJSON().readFeatures(
                featureCollections[group] || emptyFeatureCollection,
                {
                  featureProjection: 'EPSG:3857',
                }
              ),
            })
          : new VectorSource({ features: [] });
        return new VectorLayer({
          source: itemSources.current[group],
          style: getDefaultStyle,
        });
      });

      pathLayers.current = ['vehicles', 'people'].map((group) => {
        pathSources.current[group] = paths
          ? new VectorSource({
              features: new GeoJSON().readFeatures(
                paths[group] || emptyFeatureCollection,
                {
                  featureProjection: 'EPSG:3857',
                }
              ),
            })
          : new VectorSource({ features: [] });
        return new VectorLayer({
          source: pathSources.current[group],
          style: isMobilePreviewMap ? getDirectedStyle : getDefaultStyle,
        });
      });

      select.current = new SelectInteraction({
        filter: (feature, layer) => itemLayers.current.includes(layer),
        style: getSelectStyle,
        condition: click,
      });
      select.current.on('select', handleSelect);

      hover.current = new SelectInteraction({
        filter: (feature, layer) => itemLayers.current.includes(layer),
        style: getHoverStyle,
        condition: pointerMove,
      });
      hover.current.on('select', handleHover);

      map.current = new Map({
        target: mapDiv.current,
        layers: [...pathLayers.current, ...itemLayers.current],
        interactions: defaultInteractions({
          pinchRotate: false,
          altShiftDragRotate: false,
        }).extend([select.current, hover.current]),
        view: new View({
          center: [0, 0],
          zoom: 2,
        }),
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
      });

      map.current
        .getView()
        .fit(
          applyTransform(
            window.config.mapExtent,
            getTransform('EPSG:4326', 'EPSG:3857')
          )
        );

      setBaseLayers(map.current);
    }
  });

  useEffect(() => {
    if (featureCollections) {
      Object.keys(featureCollections).forEach((group) => {
        itemSources.current[group].clear({ fast: true });
        itemSources.current[group].addFeatures(
          new GeoJSON().readFeatures(
            featureCollections[group] || emptyFeatureCollection,
            {
              featureProjection: 'EPSG:3857',
            }
          )
        );
      });
    }
  }, [featureCollections]);

  useEffect(() => {
    select.current.getFeatures().clear();

    if (selectedItemIndex.id) {
      hover.current.getFeatures().clear();
      select.current
        .getFeatures()
        .push(
          itemSources.current[
            typeGroups[selectedItemIndex.type]
          ].getFeatureById(selectedItemIndex.id)
        );
    }
  }, [selectedItemIndex, featureCollections]);

  useEffect(() => {
    hover.current.getFeatures().clear();

    if (hoveredItemIndex.id) {
      hover.current
        .getFeatures()
        .push(
          itemSources.current[typeGroups[hoveredItemIndex.type]].getFeatureById(
            hoveredItemIndex.id
          )
        );
    }
  }, [hoveredItemIndex, featureCollections]);

  useEffect(() => {
    if (paths) {
      Object.keys(paths).forEach((group) => {
        pathSources.current[group].clear({ fast: true });
        pathSources.current[group].addFeatures(
          new GeoJSON().readFeatures(paths[group] || emptyFeatureCollection, {
            featureProjection: 'EPSG:3857',
          })
        );
      });

      fitMap();
    }
  }, [paths]);

  useEffect(() => {
    if (followedItemIndexes.length > 0) {
      fitMap();
    }
  }, [featureCollections, followedItemIndexes.length]);

  function fitMapToSelected() {
    if (selectedItemIndex.id) {
      map.current
        .getView()
        .fit(
          itemSources.current[typeGroups[selectedItemIndex.type]]
            .getFeatureById(selectedItemIndex.id)
            .getGeometry()
            .getExtent(),
          { maxZoom: 17 }
        );
    }
  }

  function handleMapToggleClick() {
    if (map.current) {
      map.current
        .getLayers()
        .item(satelliteMapVisible ? 0 : 1)
        .setVisible(true);
      map.current
        .getLayers()
        .item(satelliteMapVisible ? 1 : 0)
        .setVisible(false);
      setSatelliteMapVisible(!satelliteMapVisible);
    }
  }

  function handleLabelsToggleClick() {
    showLabels.current = !showLabels.current;
    setShowLabelsIcon(!showLabelsIcon);
    Object.values(itemSources.current).forEach((source) => source.changed());
    select.current.getFeatures().forEach((item) => item.changed());
    hover.current.getFeatures().forEach((item) => item.changed());
  }

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      resetMapControls();
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      resetMapControls();
    }
  }

  return (
    <div className={classes.container}>
      <div id="map" className={classes.map} ref={mapDiv} />
      <IconButton
        title="Zoom In"
        className={classes.zoomInButton}
        aria-label="Zoom In"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={zoomInDisabled}
        onClick={handleZoomInClick}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        title="Zoom Out"
        className={classes.zoomOutButton}
        aria-label="Zoom Out"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={zoomOutDisabled}
        onClick={handleZoomOutClick}
      >
        <ZoomOutIcon />
      </IconButton>
      <IconButton
        title="Fit"
        className={classes.fitButton}
        aria-label="Fit"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        onClick={selectedItemIndex.id ? fitMapToSelected : fitMap}
      >
        <ZoomOutMapIcon />
      </IconButton>
      <IconButton
        title={showLabels ? 'Hide Labels' : 'Show Labels'}
        className={classes.labelButton}
        aria-label="Toggle Labels"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        onClick={handleLabelsToggleClick}
      >
        {showLabelsIcon ? <LabelOffIcon /> : <LabelIcon />}
      </IconButton>
      {layerSwitcher && (
        <IconButton
          title={satelliteMapVisible ? 'Road Map' : 'Satellite Map'}
          className={classes.layerButton}
          aria-label="Map"
          color={satelliteMapVisible ? 'inherit' : 'default'}
          onClick={handleMapToggleClick}
        >
          {satelliteMapVisible ? <MapIcon /> : <SatelliteIcon />}
        </IconButton>
      )}
    </div>
  );
}
