import {
  Avatar,
  Paper,
  CardContent,
  Collapse,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Area,
  AreaChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_VEHICLE_IN_BASE_TIME,
  FETCH_VEHICLE_IN_BASE_TIME_CANCELLED,
  LOAD_VEHICLE_IN_BASE_TIME,
} from '../../../actions';
import db from '../../../data/db';
import Container from '../../Container';
import { Parameters, SelectMultiple, CustomTooltip } from '../../controls';
import { randomHsl } from '../../../data/constants';
import { downloadCSV } from '../../../apis/utilities';

const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
    fontSize: 12,
  },
  cardHeader: {
    flexGrow: 1,
  },
  description: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  cardContent: {
    padding: 0,
    paddingTop: theme.spacing(4),
    // width: '100%'
    // overflowX: 'scroll'
  },
  filterField: {
    width: 144,
  },
  progress: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(2),
    width: 200,
  },
  tableSelect: {
    fontSize: '0.8125rem',
  },
  placeholder: {
    color: theme.palette.grey[500],
  },
  collapseContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  legend: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  legendItem: {
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendAvatar: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(0.5),
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const headers = [
  { label: 'VIN', key: 'identificationNumber' },
  ...(useReducedResourceInformation
    ? [
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Vehicle Type', key: 'type' },
      ]
    : [
        { label: 'Registration', key: 'registrationNumber' },
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Vehicle Role', key: 'role' },
      ]),
  { label: 'Location Name', key: 'locationName' },
  { label: 'Location Type', key: 'locationType' },
  { label: 'Hour', key: 'hour' },
  { label: 'Duration (hours)', key: 'durationHours' },
];

export default function HourlyInLocations(props) {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.reports.vehicleInBaseTime.data,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.reports.vehicleInBaseTime.isLoading
  );
  const error = useSelector((state) => state.reports.vehicleInBaseTime.error);
  const filter = useSelector(
    (state) => state.reports.vehicleInBaseTime.filter,
    _.isEqual
  );
  const filterValues = useSelector(
    (state) => state.reports.vehicleInBaseTime.filterValues,
    _.isEqual
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenLines, setHiddenLines] = useState([]);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    dispatch({
      type: LOAD_VEHICLE_IN_BASE_TIME,
      payload: filter,
    });
  }, [filter, dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLE_IN_BASE_TIME,
      payload: { query, filter },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLE_IN_BASE_TIME_CANCELLED,
    });
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: (value || []).map((value) => value.value),
          }
        : {
            ...filter,
            areas: {
              ...filter.areas,
              [name]: (value || []).map((value) => value.value),
            },
          };

    dispatch({
      type: LOAD_VEHICLE_IN_BASE_TIME,
      payload: newFilter,
    });
  }

  function handleLegendClick(selectedLine) {
    const index = hiddenLines.indexOf(selectedLine);

    if (index === -1) {
      setHiddenLines(hiddenLines.concat(selectedLine));
    } else {
      setHiddenLines(
        hiddenLines.slice(0, index).concat(hiddenLines.slice(index + 1))
      );
    }
  }

  function getAreaHeaders() {
    return Object.keys(filterValues.areas).map((key) => ({
      label: _.startCase(key),
      key,
    }));
  }

  async function handleDownloadClick() {
    const allHeaders = headers.concat(getAreaHeaders());
    const filename = 'Hourly In Locations.csv';
    const dataArray = await db.vehicleInBaseTime.toArray();
    const data = dataArray[0].map(
      ({ time, areas, durationSeconds, ...record }) => ({
        ...record,
        time: moment(time).format('YYYY-MM-DD HH:mm:ss'),
        durationHours: _.round(durationSeconds / 3600, 2),
        ...areas,
      })
    );

    downloadCSV(data, filename, allHeaders);
  }

  return (
    <Container title="Hourly in Location">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        className={classes.parameters}
        dateOnly
        vehicle
        location
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Hourly in Location</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Average Vehicles in Location by Hour
          </Typography>
          <IconButton
            title={showFilter ? 'Hide filter' : 'Show filter'}
            onClick={() => handleFilterToggle()}
          >
            <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <div className={classes.collapseContainer}>
            {!useReducedResourceInformation && (
              <SelectMultiple
                fullWidth
                label="Registration Number"
                placeholder="Select..."
                value={filter.registrationNumber.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) =>
                  handleFilterFieldChanged('registrationNumber', value)
                }
                suggestions={filterValues.registrationNumber.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}

            <SelectMultiple
              fullWidth
              label="Fleet Number"
              placeholder="Select..."
              value={filter.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) =>
                handleFilterFieldChanged('fleetNumber', value)
              }
              suggestions={filterValues.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            {useReducedResourceInformation ? (
              <SelectMultiple
                fullWidth
                label="Type"
                placeholder="Select..."
                value={filter.type.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) => handleFilterFieldChanged('type', value)}
                suggestions={filterValues.type.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            ) : (
              <SelectMultiple
                fullWidth
                label="Role"
                placeholder="Select..."
                value={filter.role.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) => handleFilterFieldChanged('role', value)}
                suggestions={filterValues.role.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}
            <SelectMultiple
              fullWidth
              label="Location Name"
              placeholder="Select..."
              value={filter.locationName.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) =>
                handleFilterFieldChanged('locationName', value)
              }
              suggestions={filterValues.locationName.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              fullWidth
              label="Location Type"
              placeholder="Select..."
              value={filter.locationType.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) =>
                handleFilterFieldChanged('locationType', value)
              }
              suggestions={filterValues.locationType.map((value) => ({
                label: value,
                value,
              }))}
            />
            {Object.entries(filterValues.areas).map((entry) => {
              return (
                <SelectMultiple
                  fullWidth
                  key={entry[0]}
                  label={_.startCase(entry[0])}
                  placeholder="Select..."
                  value={(filter.areas[entry[0]] || []).map((value) => ({
                    label: value,
                    value,
                  }))}
                  onChange={(value) =>
                    handleFilterFieldChanged(entry[0], value)
                  }
                  suggestions={entry[1].map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              );
            })}
          </div>
        </Collapse>
        <Paper className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.legend}>
              {Object.keys(data).length > 1 &&
                Object.keys(data[0])
                  .filter((key) => key !== 'Hour')
                  .map((locationName, index) => {
                    const count = Object.keys(data[0]).length - 1;
                    const colour = randomHsl(index, count);
                    return (
                      <div
                        key={locationName}
                        className={classes.legendItem}
                        onClick={() => handleLegendClick(locationName)}
                      >
                        <Avatar
                          className={classes.legendAvatar}
                          style={{
                            backgroundColor:
                              !hiddenLines.includes(locationName) && colour,
                          }}
                        >
                          <Fragment />
                        </Avatar>
                        <Typography variant="caption">
                          {locationName}
                        </Typography>
                      </div>
                    );
                  })}
            </div>
            <ResponsiveContainer width="99%" height={600}>
              <AreaChart
                data={Object.values(data)}
                margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
              >
                <XAxis dataKey="Hour" interval={0}>
                  <Label value="Hour" offset={36} position="bottom" />
                </XAxis>
                <YAxis>
                  <Label
                    value="Count"
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                {Object.keys(data).length > 1 && (
                  <Tooltip
                    cursor={false}
                    content={<CustomTooltip unit="vehicles" height={400} captureMouseMove/>}
                    wrapperStyle={{ pointerEvents: 'auto' }}
                  />
                )}
                {Object.keys(data).length > 1 && (
                  <Brush dataKey="Hour" height={24} />
                )}
                {Object.keys(data).length > 1 &&
                  Object.keys(data[0])
                    .filter((key) => key !== 'Hour')
                    .reverse()
                    .map((locationName, index) => {
                      const count = Object.keys(data[0]).length - 1;
                      const colour = randomHsl(index, count);
                      return (
                        <Area
                          key={locationName}
                          type="monotone"
                          stackId="1"
                          dataKey={locationName}
                          hide={hiddenLines.includes(locationName)}
                          stroke={colour}
                          fill={colour}
                        />
                      );
                    })}
              </AreaChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
      </div>
    </Container>
  );
}
