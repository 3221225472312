import React from 'react';
import {
  AppBar,
  Avatar,
  Card,
  CardHeader,
  Paper,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { red, amber, green, blue } from '@material-ui/core/colors';
import { Helmet } from 'react-helmet-async';
import { LoginAvatar, MenuButton } from '../controls';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    margin: theme.spacing(0, 1, 1, 1),
    display: 'flex',
    // flex: 1,
    height: '100%',
  },
  flex: {
    flex: 1,
  },
  toolbar: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  menuButton: {
    marginRight: 10,
  },
  title: {
    margin: theme.spacing(1),
  },
  columns: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    width: 280,
    height: '100%',
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
}));

export default function Tasks() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>IR3 | Tasks</title>
      </Helmet>
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.toolbar}>
          <MenuButton color="inherit" className={classes.menuButton} />
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Tasks
          </Typography>
          <LoginAvatar />
        </Toolbar>
      </AppBar>
      <Typography variant="h6" className={classes.title}>
        My Team's Tasks
      </Typography>
      <div className={classes.content}>
        <div className={classes.columns}>
          <Paper
            className={classes.column}
            style={{ backgroundColor: red[50] }}
          >
            <Typography variant="subtitle2" className={classes.title}>
              Opened
            </Typography>
            <Card>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    R
                  </Avatar>
                }
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                title="Go do some stuff"
                subheader="September 14, 2016"
              />
            </Card>
          </Paper>
          <Paper
            className={classes.column}
            style={{ backgroundColor: amber[50] }}
          >
            <Typography variant="subtitle2" className={classes.title}>
              Assigned
            </Typography>
          </Paper>
          <Paper
            className={classes.column}
            style={{ backgroundColor: green[50] }}
          >
            <Typography variant="subtitle2" className={classes.title}>
              Completed
            </Typography>
          </Paper>
          <Paper
            className={classes.column}
            style={{ backgroundColor: blue[50] }}
          >
            <Typography variant="subtitle2" className={classes.title}>
              Closed
            </Typography>
          </Paper>
        </div>
      </div>
    </div>
  );
}
