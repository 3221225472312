import { FormHelperText, IconButton, makeStyles } from '@material-ui/core';
import {
  Cancel as CancelIcon,
  Create as CreateIcon,
  Map as MapIcon,
  Satellite as SatelliteIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons';
import { Collection, Feature, Map, View } from 'ol';
import { defaults as defaultControls } from 'ol/control';
// import DragPanInteraction from 'ol/interaction/DragPan';
import { altKeyOnly } from 'ol/events/condition';
import { applyTransform } from 'ol/extent';
import { GeoJSON, GPX, IGC, KML, TopoJSON } from 'ol/format';
import {
  defaults as defaultInteractions,
  DoubleClickZoom as DoubleClickZoomInteraction,
  DragAndDrop as DragAndDropInteraction,
  Draw as DrawInteraction,
  Modify as ModifyInteraction,
  MouseWheelZoom as MouseWheelZoomInteraction,
  Select as SelectInteraction,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { getTransform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import React, { useState, useEffect, useRef } from 'react';
import { setBaseLayers } from '../../mapStyles';

const { simplificationTolerance, mapExtent, layerSwitcher } = window.config;

const useStyles = makeStyles({
  map: {
    width: '100%',
    height: '100%',
  },
  zoomInButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    zIndex: 1,
  },
  zoomOutButton: {
    position: 'absolute',
    top: 40,
    left: 5,
    zIndex: 1,
  },
  fitButton: {
    position: 'absolute',
    top: 75,
    left: 5,
    zIndex: 1,
  },
  drawButton: {
    position: 'absolute',
    top: 110,
    left: 5,
    zIndex: 1,
  },
  layerButton: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    zIndex: 1,
  },
  errorText: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 1,
  },
  container: {
    position: 'relative',
    width: '100%',
    minWidth: 240,
    height: 'calc(100vh - 254px)',
    color: '#fff',
  },
});

const defaultStyle = new Style({
  fill: new Fill({
    color: 'rgba(100,100,100,0.5)',
  }),
  stroke: new Stroke({
    color: 'rgb(0,0,0)',
    width: 2,
  }),
  image: new Circle({
    radius: 8,
    fill: new Fill({
      color: 'rgb(0,0,0)',
    }),
  }),
});

const selectStyle = new Style({
  fill: new Fill({
    color: 'rgba(33,150,243,0.5)',
  }),
  stroke: new Stroke({
    color: 'rgb(33,150,243)',
    width: 2,
  }),
  image: new Circle({
    radius: 8,
    fill: new Fill({
      color: 'rgb(33,150,243)',
    }),
  }),
});

export default function GeometryField({ input, meta, geoType }) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  const [fitDisabled, setFitDisabled] = useState(false);
  const [drawActive, setDrawActive] = useState(false);
  const [satelliteMapVisible, setSatelliteMapVisible] = useState(false);
  // const [refitMap, setRefitMap] = useState(false);
  const mapDiv = useRef(null);
  const map = useRef(null);
  const select = useRef(null);
  const draw = useRef(null);
  const modify = useRef(null);
  const doubleClickZoom = useRef(null);
  const dragAndDrop = useRef(null);
  const vectorSource = useRef(null);
  const classes = useStyles();

  function resetMapControls() {
    setZoomInDisabled(
      map.current.getView().getZoom() === map.current.getView().getMaxZoom()
    );
    setZoomOutDisabled(
      map.current.getView().getZoom() === map.current.getView().getMinZoom()
    );
    setFitDisabled(false);
    // setRefitMap(false);
  }

  useEffect(() => {
    if (!map.current) {
      vectorSource.current = new VectorSource({
        features: new Collection([]),
      });

      select.current = new SelectInteraction({ style: selectStyle });
      modify.current = new ModifyInteraction({
        features: select.current.getFeatures(),
      });

      modify.current.on('modifyend', (event) => {
        const geometry = new GeoJSON().writeGeometryObject(
          event.features.getArray()[0].getGeometry(),
          { featureProjection: 'EPSG:3857', rightHanded: true }
        );
        input.onChange(geometry);
      });

      doubleClickZoom.current = new DoubleClickZoomInteraction();

      dragAndDrop.current = new DragAndDropInteraction({
        formatConstructors: [GPX, GeoJSON, IGC, KML, TopoJSON],
      });
      dragAndDrop.current.on('addfeatures', (event) => {
        const geometry = new GeoJSON().writeGeometryObject(
          event.features[0].getGeometry().simplify(simplificationTolerance),
          { featureProjection: 'EPSG:3857', rightHanded: true }
        );
        // setRefitMap(true);
        input.onChange(geometry);
      });

      draw.current = new DrawInteraction({
        source: vectorSource.current,
        type: geoType,
      });
      draw.current.on('drawend', () => {
        map.current.removeInteraction(draw.current);

        setDrawActive(false);
        setFitDisabled(false);

        setTimeout(() => {
          map.current.addInteraction(select.current);
          map.current.addInteraction(doubleClickZoom.current);
          map.current.addInteraction(dragAndDrop.current);

          const geo = new GeoJSON().writeGeometryObject(
            vectorSource.current.getFeatures()[0].getGeometry(),
            { featureProjection: 'EPSG:3857', rightHanded: true }
          );

          input.onChange(geo);
        }, 0);
      });

      map.current = new Map({
        target: mapDiv.current,
        layers: [
          new VectorLayer({
            source: vectorSource.current,
            style: defaultStyle,
          }),
        ],
        interactions: defaultInteractions({
          doubleClickZoom: false,
          mouseWheelZoom: false,
          pinchRotate: false,
          altShiftDragRotate: false,
        }).extend([
          doubleClickZoom.current,
          select.current,
          modify.current,
          dragAndDrop.current,
          new MouseWheelZoomInteraction({
            condition: altKeyOnly,
          }),
        ]),
        view: new View({
          center: [0, 0],
          zoom: 2,
        }),
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
      });

      setBaseLayers(map.current);

      if (input.value) {
        if (vectorSource.current.getFeatures().length > 0) {
          vectorSource.current.getFeatures()[0].setGeometry(
            new GeoJSON().readGeometry(input.value, {
              featureProjection: 'EPSG:3857',
            })
          );
        } else {
          vectorSource.current.addFeature(
            new Feature(
              new GeoJSON().readGeometry(input.value, {
                featureProjection: 'EPSG:3857',
              })
            )
          );
        }

        const geometry = new GeoJSON().readGeometry(input.value, {
          featureProjection: 'EPSG:3857',
        });
        map.current.getView().fit(geometry, { maxZoom: 18 });
      } else {
        map.current
          .getView()
          .fit(
            applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857'))
          );
      }
      resetMapControls();
    }
  }, [geoType, input]);

  useEffect(() => {
    if (vectorSource.current.getFeatures().length > 0) {
      if (input.value) {
        vectorSource.current.getFeatures()[0].setGeometry(
          new GeoJSON().readGeometry(input.value, {
            featureProjection: 'EPSG:3857',
          })
        );
      } else {
        vectorSource.current.clear();
      }
    } else {
      if (input.value) {
        vectorSource.current.addFeature(
          new Feature(
            new GeoJSON().readGeometry(input.value, {
              featureProjection: 'EPSG:3857',
            })
          )
        );
      } else {
        // do nothing
      }
    }

    if (meta.pristine) {
      if (input.value) {
        const geometry = new GeoJSON().readGeometry(input.value, {
          featureProjection: 'EPSG:3857',
        });
        map.current.getView().fit(geometry, { maxZoom: 18 });
      } else {
        map.current
          .getView()
          .fit(
            applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857'))
          );
      }
      resetMapControls();
    }
  }, [input.value, meta.pristine]);

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      resetMapControls();
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      resetMapControls();
    }
  }

  function handleFitClick() {
    if (vectorSource.current && vectorSource.current.getFeatures().length > 0) {
      map.current
        .getView()
        .fit(vectorSource.current.getExtent(), { maxZoom: 18 });
      resetMapControls();
    }
  }

  function handleDrawClick() {
    vectorSource.current.clear();
    select.current.getFeatures().clear();

    if (drawActive) {
      setDrawActive(false);
      setFitDisabled(false);

      map.current.addInteraction(select.current);
      map.current.addInteraction(doubleClickZoom.current);
      map.current.addInteraction(dragAndDrop.current);
      map.current.removeInteraction(draw.current);

      input.onChange(undefined);
    } else {
      setDrawActive(true);
      setFitDisabled(true);

      map.current.removeInteraction(select.current);
      map.current.removeInteraction(doubleClickZoom.current);
      map.current.removeInteraction(dragAndDrop.current);
      map.current.addInteraction(draw.current);
    }
  }

  function handleLayerButtonClick() {
    if (map.current) {
      map.current
        .getLayers()
        .item(satelliteMapVisible ? 0 : 1)
        .setVisible(true);
      map.current
        .getLayers()
        .item(satelliteMapVisible ? 1 : 0)
        .setVisible(false);
      setSatelliteMapVisible(!satelliteMapVisible);
    }
  }

  return (
    <div className={classes.container}>
      <div id="map" className={classes.map} ref={mapDiv} />
      <IconButton
        title="Zoom In"
        className={classes.zoomInButton}
        aria-label="Zoom In"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={zoomInDisabled}
        onClick={handleZoomInClick}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        title="Zoom Out"
        className={classes.zoomOutButton}
        aria-label="Zoom Out"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={zoomOutDisabled}
        onClick={handleZoomOutClick}
      >
        <ZoomOutIcon />
      </IconButton>
      <IconButton
        title="Fit"
        className={classes.fitButton}
        aria-label="Fit"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={fitDisabled}
        onClick={handleFitClick}
      >
        <ZoomOutMapIcon />
      </IconButton>
      <IconButton
        title={drawActive ? 'Cancel' : 'Draw'}
        className={classes.drawButton}
        aria-label="Draw"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        onClick={handleDrawClick}
      >
        {drawActive ? <CancelIcon /> : <CreateIcon />}
      </IconButton>
      {layerSwitcher && (
        <IconButton
          title={satelliteMapVisible ? 'Road Map' : 'Satellite Map'}
          className={classes.layerButton}
          aria-label="Map"
          color={satelliteMapVisible ? 'inherit' : 'default'}
          onClick={handleLayerButtonClick}
        >
          {satelliteMapVisible ? <MapIcon /> : <SatelliteIcon />}
        </IconButton>
      )}
      {meta.touched && meta.invalid && (
        <FormHelperText className={classes.errorText} error>
          {meta.error}
        </FormHelperText>
      )}
    </div>
  );
}
