import React from 'react';
import { MenuItem, TextField, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { UPDATE_TRIP_CLASSIFICATION } from '../../../actions';

const { tripClassifications } = window.config;

const useStyles = makeStyles({
  tableSelect: {
    fontSize: 14,
  },
});

export default function Classification({ entry }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClassificationChange = (tripId) => (event) => {
    event.stopPropagation();

    dispatch({
      type: UPDATE_TRIP_CLASSIFICATION,
      payload: {
        id: tripId,
        classification: event.target.value,
      },
    });
  };

  return (
    <TextField
      select
      value={entry.classification}
      onChange={handleClassificationChange(entry.identifier)}
      inputProps={{
        className: classes.tableSelect,
      }}
    >
      <MenuItem value="None">None</MenuItem>
      {tripClassifications.map((classification) => (
        <MenuItem key={classification.value} value={classification.value}>
          {classification.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
