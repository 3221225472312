import { CancelToken } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { formatISO } from 'date-fns';
import {
  FETCH_VEHICLE_DAILY_UTILISATION,
  FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
  FETCH_VEHICLE_DAILY_UTILISATION_FAILURE,
  FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS,
  FETCH_VEHICLE_HOURLY_UTILISATION,
  FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
  FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE,
  FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS,
  LOAD_VEHICLE_DAILY_UTILISATION,
  LOAD_VEHICLE_DAILY_UTILISATION_FAILURE,
  LOAD_VEHICLE_DAILY_UTILISATION_SUCCESS,
  LOAD_VEHICLE_HOURLY_UTILISATION,
  LOAD_VEHICLE_HOURLY_UTILISATION_FAILURE,
  LOAD_VEHICLE_HOURLY_UTILISATION_SUCCESS,
} from '../actions';
import api from '../apis';
import {
  getHeaders,
  log,
  reduceByType as reduceAreas,
  areasFilter,
  getGroupKey,
} from '../apis/utilities';
import db, { fetchCachedData } from '../data/db';

const {
  useReducedResourceInformation,
  baseType: { value: baseValue = 'Base' },
} = window.config;
const workshopValue = 'Workshop';
const otherValue = 'Other';

let cancel;

function vehicleUtilisationFilter(record, filter) {
  if (
    filter.registrationNumber.length !== 0 &&
    !filter.registrationNumber.includes(record.registrationNumber)
  ) {
    return false;
  }

  if (
    filter.fleetNumber.length !== 0 &&
    !filter.fleetNumber.includes(record.fleetNumber)
  ) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  if (filter.type.length !== 0 && !filter.type.includes(record.type)) {
    return false;
  }

  return areasFilter(record, filter);
}

function getVehicleUtilisationFilterAndGroupByValues(data, filter) {
  const { areas: _, ...fields } = filter;
  const result = { areas: {} };
  const areas = Array.from(
    new Set([].concat(...data.map((record) => Object.keys(record.areas))))
  );

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    result[key] = Array.from(
      new Set(
        data
          .filter((record) => vehicleUtilisationFilter(record, keyFilter))
          .map((record) => record[key])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  for (const area of areas) {
    const keyFilter = { ...filter, areas: { ...filter.areas, [area]: [] } };
    result.areas[area] = Array.from(
      new Set(
        data
          .filter((record) => vehicleUtilisationFilter(record, keyFilter))
          .map((record) => record.areas[area])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  return {
    filterValues: result,
    groupByValues: useReducedResourceInformation
      ? ['date', 'month', 'fleetNumber', 'type', 'homeStation', ...areas]
      : [
          'date',
          'month',
          'registrationNumber',
          'fleetNumber',
          'role',
          'type',
          'homeStation',
          ...areas,
        ],
  };
}

function getVehicleDailyUtilisation(
  rawData,
  groupBy,
  orderBy,
  usedUnusedUnavailableClassification
) {
  const groupedData =
    rawData.length === 0
      ? new Map()
      : rawData.reduce((accumulator, record) => {
          const groupKey = getGroupKey(groupBy, record);
          let current = accumulator.get(groupKey);

          if (!current) {
            current = {
              group: groupKey,
              movingSeconds: [],
              stoppedBaseSeconds: [],
              stoppedWorkshopSeconds: [],
              stoppedElsewhereSeconds: [],
              idleBaseSeconds: [],
              idleWorkshopSeconds: [],
              idleElsewhereSeconds: [],
              unaccountableSeconds: [],
              tripStarts: [],
              movingKilometres: [],
              identifiers: [],
            };
            accumulator.set(groupKey, current);
          }

          current.movingSeconds.push(
            record.movingSeconds -
              record.idleBaseSeconds -
              record.idleWorkshopSeconds -
              record.idleElsewhereSeconds
          );
          current.stoppedBaseSeconds.push(record.stoppedBaseSeconds);
          current.stoppedWorkshopSeconds.push(record.stoppedWorkshopSeconds);
          current.stoppedElsewhereSeconds.push(record.stoppedElsewhereSeconds);
          current.idleBaseSeconds.push(record.idleBaseSeconds);
          current.idleWorkshopSeconds.push(record.idleWorkshopSeconds);
          current.idleElsewhereSeconds.push(record.idleElsewhereSeconds);
          current.unaccountableSeconds.push(record.unaccountableSeconds);
          current.tripStarts.push(record.tripStarts);
          current.movingKilometres.push(record.movingKilometres);

          if (
            !current.identifiers.includes(
              useReducedResourceInformation
                ? record.fleetNumber
                : record.registrationNumber
            )
          ) {
            current.identifiers.push(
              useReducedResourceInformation
                ? record.fleetNumber
                : record.registrationNumber
            );
          }

          return accumulator;
        }, new Map());

  function roundedAverageHours(groupedValues) {
    return _.round(
      groupedValues.reduce((a, b) => a + b, 0) / groupedValues.length / 3600,
      2
    );
  }

  let data = Array.from(groupedData.values()).map((group) => ({
    group:
      groupBy === 'date' || groupBy === 'month'
        ? new Date(group.group)
        : group.group,
    count: group.identifiers.length,
    moving: roundedAverageHours(group.movingSeconds),
    stoppedBase: roundedAverageHours(group.stoppedBaseSeconds),
    stoppedWorkshop: roundedAverageHours(group.stoppedWorkshopSeconds),
    stoppedElsewhere: roundedAverageHours(group.stoppedElsewhereSeconds),
    idleBase: roundedAverageHours(group.idleBaseSeconds),
    idleWorkshop: roundedAverageHours(group.idleWorkshopSeconds),
    idleElsewhere: roundedAverageHours(group.idleElsewhereSeconds),
    unaccountable: roundedAverageHours(group.unaccountableSeconds),
    totalMileage: _.round(
      group.movingKilometres.reduce((a, b) => a + b, 0) * 0.62137119,
      2
    ),
    averageMileage: _.round(
      (group.movingKilometres.reduce((a, b) => a + b, 0) * 0.62137119) /
        group.identifiers.length,
      2
    ),
    dailyMileage: _.round(
      (group.movingKilometres.reduce((a, b) => a + b, 0) * 0.62137119) /
        group.movingKilometres.length,
      2
    ),
    totalTrips: _.round(
      group.tripStarts.reduce((a, b) => a + b, 0),
      2
    ),
    averageTrips: _.round(
      group.tripStarts.reduce((a, b) => a + b, 0) / group.identifiers.length,
      2
    ),
    dailyTrips: _.round(
      group.tripStarts.reduce((a, b) => a + b, 0) / group.tripStarts.length,
      2
    ),
  }));

  if (usedUnusedUnavailableClassification) {
    data = data.map(
      ({
        idleBase = 0,
        idleElsewhere = 0,
        idleWorkshop = 0,
        moving = 0,
        stoppedBase = 0,
        stoppedElsewhere = 0,
        stoppedWorkshop = 0,
        unaccountable = 0,
        ...item
      }) => ({
        used: _.round(moving + stoppedElsewhere + idleElsewhere, 2),
        unused: _.round(stoppedBase + idleBase, 2),
        unavailable: _.round(stoppedWorkshop + idleWorkshop, 2),
        unaccountable: _.round(unaccountable, 2),
        ...item,
      })
    );
  }

  if (orderBy === 'date' || orderBy === 'month') {
    data.sort((a, b) =>
      moment(a.group, 'DD/MM/YYYY').diff(moment(b.group, 'DD/MM/YYYY'))
    );
  } else {
    data = _.orderBy(data, orderBy);
  }

  return data;
}

async function fetchVehicleDailyUtilisationRequest(
  query,
  filter,
  groupBy,
  orderBy,
  usedUnusedUnavailableClassification
) {
  const response = await api.get('/vehicleDailySummaries', {
    params: {
      query,
      projection: {
        time: true,
        vehicle: true,
        movingKilometres: true,
        movingSeconds: true,
        stoppedBaseSeconds: true,
        stoppedElsewhereSeconds: true,
        stoppedWorkshopSeconds: true,
        idleBaseSeconds: true,
        idleElsewhereSeconds: true,
        idleWorkshopSeconds: true,
        tripStarts: true,
        accelerometerAlerts: true,
        accelerometerEvents: true,
        respondingToIncidents: true,
        respondingToIncidentSeconds: true,
        unaccountableSeconds: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const data = response.data.map(
    ({ vehicle: { areas, ...vehicle }, ...record }) => ({
      ...record,
      ...vehicle,
      areas: reduceAreas(areas),
    })
  );

  await db.vehicleDailyUtilisation.clear();
  await db.vehicleDailyUtilisation.bulkAdd(data);
  await db.parameters.put({
    store: 'vehicleDailyUtilisation',
    query,
  });

  const filteredData = data.filter((record) =>
    vehicleUtilisationFilter(record, filter)
  );

  const results = {
    filter,
    groupBy,
    ...getVehicleUtilisationFilterAndGroupByValues(data, filter),
    data: getVehicleDailyUtilisation(
      filteredData,
      groupBy,
      orderBy,
      usedUnusedUnavailableClassification
    ),
    usedUnusedUnavailableClassification,
  };

  log('Read', 'Vehicle Daily Utilisation', query);

  return results;
}

export function fetchVehicleDailyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_DAILY_UTILISATION),
    mergeMap(
      ({
        payload: {
          query,
          filter,
          groupBy,
          orderBy,
          usedUnusedUnavailableClassification,
        },
      }) =>
        from(
          fetchVehicleDailyUtilisationRequest(
            query,
            filter,
            groupBy,
            orderBy,
            usedUnusedUnavailableClassification
          )
        ).pipe(
          map((payload) => ({
            type: FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED),
              tap((ev) => cancel('cancelled'))
            )
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_VEHICLE_DAILY_UTILISATION_FAILURE,
              payload,
            })
          )
        )
    )
  );
}

async function loadVehicleDailyUtilisationRequest({
  filter,
  groupBy,
  orderBy,
  usedUnusedUnavailableClassification,
}) {
  const reportName = 'vehicleDailyUtilisation';
  const data = await fetchCachedData(reportName);
  const parameters = await db.parameters.get(reportName);

  const filteredData = data.filter((record) =>
    vehicleUtilisationFilter(record, filter)
  );

  const results = {
    filter,
    groupBy,
    ...getVehicleUtilisationFilterAndGroupByValues(data, filter),
    data: getVehicleDailyUtilisation(
      filteredData,
      groupBy,
      orderBy,
      usedUnusedUnavailableClassification
    ),
    query: parameters?.query || {},
    usedUnusedUnavailableClassification,
  };

  log('Load', 'Vehicle Daily Utilisation', parameters);

  return results;
}

export function loadVehicleDailyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(LOAD_VEHICLE_DAILY_UTILISATION),
    mergeMap(({ payload }) =>
      from(loadVehicleDailyUtilisationRequest(payload)).pipe(
        map((payload) => ({
          type: LOAD_VEHICLE_DAILY_UTILISATION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLE_DAILY_UTILISATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchVehicleHourlyUtilisationRequest(
  query,
  filter,
  usedUnusedUnavailableClassification
) {
  const isoStart = formatISO(query.endTime.$gte);
  const isoEnd = formatISO(query.startTime.$lt);

  function hourGrouping(hour) {
    return { $sum: `$hours.h${hour}` };
  }

  function applyAllHours(generatorFunction) {
    let result = {};
    for (let i = 0; i < 24; i++) {
      result['h' + i] = generatorFunction(i);
    }

    return result;
  }

  const allHourGroupings = applyAllHours(hourGrouping);

  let pipeline = [
    // { $match: dates ... }
    // temp
    {
      $match: query,
    },
    {
      $project: {
        // trim the start/end to the range of the query start/end
        startTime: { $max: ['$startTime', { $toDate: isoStart }] },
        endTime: { $min: ['$endTime', { $toDate: isoEnd }] },
        // startTime: true,
        // endTime: true,
        // this is how @Base, @Workshop are differentiated, for trips there's no type
        locationType: {
          $arrayElemAt: ['$locations.type', 0],
        },
      },
    },
    {
      // for example let's say an activity starts 01/01 20:10 & ends 03/01 05:20...
      $project: {
        startHour: { $hour: '$startTime' }, // this will be 20
        // the activity starts at 20:10, there are 50 minutes left in this hour
        // (3600 -60*10 = 3000 seconds or 50 minutes)
        startHourSecsLeft: {
          $add: [
            { $multiply: [{ $minute: '$startTime' }, -60] },
            { $multiply: [{ $second: '$startTime' }, -1] },
            3600,
          ],
        },
        endHour: { $hour: '$endTime' }, // this will be 05
        // the activity ends at 05:20 so 20 minutes of this hour are spent doing
        // this activity (60*20 = 1200 seconds or 20 minutes)
        endHourSecsPast: {
          $add: [
            { $multiply: [{ $minute: '$endTime' }, 60] },
            { $multiply: [{ $second: '$endTime' }, 1] },
          ],
        },
        // subtract the startTime from the endTime to get the milliseconds,
        // for this example
        //     01/01    02/01   03/01
        // 24h-20h10m + 24h +   5h20   = 33h10m === 119,400s
        // days is how many full days are inbetween the start & end of the activity
        days: {
          $floor: {
            $divide: [{ $subtract: ['$endTime', '$startTime'] }, 84600000],
          },
        },
        // locationType: true,
        // there can be many location types, we need to map one for base and one for workshop
        // everything else should be other
        locationType: {
          $switch: {
            branches: [
              {
                case: baseValue,
                then: 'Base',
              },
              {
                case: workshopValue,
                then: 'Workshop',
              },
            ],
            default: 'Other',
          },
        },
        // we need to separate out the time for each hour
        hours: { $range: [0, 24] },
      },
    },
    {
      $project: {
        locationType: true,
        hours: {
          $arrayToObject: {
            // ['h0', 123] => { h0: 123 }
            $map: {
              // [0, 1, ...] => [['h0', 123], ['h1', 456], ...]
              input: '$hours', // for each of 0, 1, 2, ... 23
              as: 'hour',
              in: [
                // create a property name e.g. h0
                { $concat: ['h', { $toString: '$$hour' }] },
                {
                  $add: [
                    // add 3600 for each full day of the activity span
                    { $multiply: ['$days', 3600] },
                    // if this hour is between* the start and the end add an hour
                    // *between: 5 is between 3 and 7, but 5 is not between 7 and 3
                    //           the latter can happen when an activity starts at 7
                    //           but ends the next day at 3
                    //           8 and 0 are between 7 and 3 in this case
                    // for the latter case imagine 2 decimal days:
                    //    0  1  2  3  4  5  6  7  8  9 | 0  1  2  3  4  5  6  7  8  9
                    // the activity goes:      S------------------E
                    // so 8 and 2 are between S and E.
                    //
                    // the 2 of the first day and 8 of the next day aren't bugs, the
                    // result of this activity will have an array something like:
                    //    0  1  2  3  4  5  6  7  8  9
                    //    h  h  h              h  h  h    (h is one hour)
                    {
                      $cond: {
                        if: {
                          $or: [
                            {
                              // handles "is 5 between 3 and 7" above
                              // if so, add an hour
                              $and: [
                                // { $lt: ["$startHour", "$endHour"] }, // a
                                { $gt: ['$$hour', '$startHour'] }, // b
                                { $lt: ['$$hour', '$endHour'] }, // c
                              ],
                            },
                            {
                              // handles "is 5 between 7 and 3" above
                              // if (startHour > endHour)
                              //    if (hour > startHour OR hour < endHour)
                              // add an hour
                              $and: [
                                { $gt: ['$startHour', '$endHour'] }, // !a
                                {
                                  $or: [
                                    { $gt: ['$$hour', '$startHour'] }, // b
                                    { $lt: ['$$hour', '$endHour'] }, // c
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        then: 3600,
                        else: 0,
                      },
                    },
                    {
                      // if hour is the start hour, use the seconds left not a full hour
                      $cond: {
                        if: { $eq: ['$startHour', '$$hour'] },
                        then: '$startHourSecsLeft',
                        else: 0,
                      },
                    },
                    {
                      // if hour is the end hour, use the seconds past not a full hour
                      $cond: {
                        if: { $eq: ['$endHour', '$$hour'] },
                        then: '$endHourSecsPast',
                        else: 0,
                      },
                    },
                    {
                      // if this hour is the start AND end hour, we will have double
                      // counted above so subtract an hour
                      // e.g. 20:10 to 20:50 should be 40 minutes not
                      // (secsLeft) 50m + (secsPast) 50m == 100m = 1h40m
                      $cond: {
                        if: {
                          $and: [
                            { $eq: ['$startHour', '$$hour'] },
                            { $eq: ['$$hour', '$endHour'] },
                          ],
                        },
                        then: -3600,
                        else: 0,
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    },
    {
      $group: {
        _id: '$locationType',
        ...allHourGroupings,
      },
    },
  ];

  // get the activity by location type for each of stops, trips and idles
  // (trips doesn't have locations instead start & end locations
  // this works out as moving isn't categorised by type usually)
  const [stopsResponse, tripsResponse, idlesResponse] = await Promise.all(
    ['/stops', '/trips', '/idles'].map((url) =>
      api.get(url, {
        params: {
          pipeline: JSON.stringify(pipeline),
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      })
    )
  );

  // translate the mongodb result for easier processing
  function mapResponseToResult(response, prefix) {
    let results = {};
    response.data.forEach((hoursAtType) => {
      results[
        hoursAtType._id !== otherValue || hoursAtType._id
          ? prefix + ' @ ' + hoursAtType._id
          : prefix + ' Elsewhere'
      ] = Object.keys(hoursAtType)
        .filter((k) => k.startsWith('h'))
        .map((k) => ({
          hour: k.slice(1),
          seconds: hoursAtType[k],
        }))
        .sort((a, b) => a.hour - b.hour);
    });

    return results;
  }

  const stops = mapResponseToResult(stopsResponse, 'Stopped');
  const idles = mapResponseToResult(idlesResponse, 'Idle');
  const trips = mapResponseToResult(tripsResponse, 'Tripping'); // prefix not used ultimately

  // getting total for the below for hour 1 results in 246 seconds
  // { Base: [{hour: 1, seconds: 123}, {} ...], Workshop: [{hour: 1, seconds: 123}, {} ...] }
  function totalSecondsForHour(activity, hour) {
    return Object.values(activity).reduce(
      (total, arr) =>
        total +
        arr.reduce(
          (total, h) => total + (h.hour - hour === 0 ? h.seconds : 0),
          0
        ),
      0
    );
  }

  // moving seconds is trip - idle, do this for each hour
  let tripsWithoutIdles = [];
  for (let i = 0; i < 24; i++) {
    const tripSeconds = totalSecondsForHour(trips, i);
    const idleSeconds = totalSecondsForHour(idles, i);

    tripsWithoutIdles.push({
      hour: i,
      seconds: tripSeconds - idleSeconds,
    });
  }
  const moves = {
    Moving: tripsWithoutIdles,
  };

  // get total seconds by hour so average per hour can be worked out
  let totalSecondsByHour = {};
  for (let i = 0; i < 24; i++) {
    totalSecondsByHour[i] = [moves, stops, idles].reduce(
      (total, activity) => total + totalSecondsForHour(activity, i),
      0
    );
  }

  // on average how many minutes are spent doing this activity on this hour?
  function minutesForHour(activity, hour) {
    let result = {};
    Object.keys(activity).forEach((key) => {
      result[key] = totalSecondsByHour[hour]
        ? _.round(
            (60 * activity[key][hour].seconds) / totalSecondsByHour[hour],
            2
          )
        : 0;
    });

    return result;
  }

  let data = [];
  for (let i = 0; i < 24; i++) {
    data.push({
      Hour: `${i.toString().padStart(2, '0')}:00`,
      ...minutesForHour(moves, i),
      ...minutesForHour(idles, i),
      ...minutesForHour(stops, i),
    });
  }

  await db.vehicleHourlyUtilisation.clear();
  await db.vehicleHourlyUtilisation.add(data);
  await db.parameters.put({
    store: 'vehicleHourlyUtilisation',
    query,
  });

  const results = {
    filter,
    filterValues: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      areas: {},
    },
    groupByValues: [],
    data: usedUnusedUnavailableClassification
      ? hourlyToUsedUnusedAvailable(data)
      : data,
  };

  log('Read', 'Vehicle Hourly Utilisation', query);

  return results;
}

function hourlyToUsedUnusedAvailable(data) {
  return data.map(
    ({
      'Idle @ Base': idleBase = 0,
      'Idle Elsewhere': idleElsewhere = 0,
      'Idle @ Workshop': idleWorkshop = 0,
      Moving: moving = 0,
      'Stopped @ Base': stoppedBase = 0,
      'Stopped Elsewhere': stoppedElsewhere = 0,
      'Stopped @ Workshop': stoppedWorkshop = 0,
      ...item
    }) => ({
      Used: _.round(moving + stoppedElsewhere + idleElsewhere, 2),
      Unused: _.round(stoppedBase + idleBase, 2),
      Unavailable: _.round(stoppedWorkshop + idleWorkshop, 2),
      ...item,
    })
  );
}

export function fetchVehicleHourlyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_HOURLY_UTILISATION),
    switchMap(
      ({ payload: { query, filter, usedUnusedUnavailableClassification } }) =>
        from(
          fetchVehicleHourlyUtilisationRequest(
            query,
            filter,
            usedUnusedUnavailableClassification
          )
        ).pipe(
          map((payload) => ({
            type: FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED),
              tap((ev) => cancel('cancelled'))
            )
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE,
              payload,
            })
          )
        )
    )
  );
}

async function loadVehicleHourlyUtilisationRequest(
  filter,
  usedUnusedUnavailableClassification
) {
  const reportName = 'vehicleHourlyUtilisation';
  const data = await fetchCachedData(reportName);
  const parameters = await db.parameters.get(reportName);

  const results = {
    filter,
    filterValues: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      areas: {},
    },
    groupByValues: [],
    data: usedUnusedUnavailableClassification
      ? hourlyToUsedUnusedAvailable(data)
      : data,
  };

  log('Load', 'Vehicle Hourly Utilisation', parameters);

  return results;
}

export function loadVehicleHourlyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(LOAD_VEHICLE_HOURLY_UTILISATION),
    mergeMap(({ payload: { filter, usedUnusedUnavailableClassification } }) =>
      from(
        loadVehicleHourlyUtilisationRequest(
          filter,
          usedUnusedUnavailableClassification
        )
      ).pipe(
        map((payload) => ({
          type: LOAD_VEHICLE_HOURLY_UTILISATION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLE_HOURLY_UTILISATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
