import {
  AppBar,
  Drawer,
  makeStyles,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { END_LIVE_STREAM, START_LIVE_STREAM } from '../../actions';
import { log } from '../../apis/utilities';
import { usePrevious } from '../../hooks';
import { useAuth } from '../Auth';
import { LoginAvatar } from '../controls';
import { useSnackbar } from '../Snackbar';
import { liveSubscription } from './constants';
import LiveList from './LiveList';
import TypeSelectorBar from './LiveList/TypeSelectorBar';
import LiveMap from './LiveMap';
import LiveViews from './LiveViews';

const useStyles = makeStyles((theme) => ({
  drawerContent: {
    maxHeight: '80vh',
    overflow: 'scroll',
  },
  tabs: {
    width: 420,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  itemsSection: {
    paddingTop: 48,
    width: 420,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      width: '100%',
    },
    float: 'left',
    height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    ['-ms-overflow-style']: '-ms-autohiding-scrollbar', // eslint-disable-line no-useless-computed-key
  },
  mapSection: {
    height: 'calc(100vh - 48px)', // used for full app bar
    top: 48, // used for full app bar
    position: 'relative', // used for full app bar
    width: 'calc(100% - 420px)',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 48,
      width: '100%',
      height: '100vh',
      top: 0,
    },
    float: 'left',
    overflowY: 'auto',
    overflowX: 'hidden',
    ['-ms-overflow-style']: '-ms-autohiding-scrollbar', // eslint-disable-line no-useless-computed-key
  },
  toolBar: {
    minHeight: 48,
    maxHeight: 48,
    display: 'flex',
    justifyContent: 'space-between',
  },
  liveViewsInAppBar: {
    display: 'flex',
    // width: `calc(100% - ${420 + 2 * theme.spacing(8)}px)`,
    // right: theme.spacing(8),
    paddingRight: theme.spacing(1.5),
    zIndex: 100,
    minHeight: 48,
    maxHeight: 48,
    width: 400,
    minWidth: 250,
    maxWidth: 400,
  },
  avatarDiv: {
    marginTop: 6,
  },
  avatarWhenCantEditView: {
    marginLeft: theme.spacing(1.5),
    marginTop: 6,
  },
}));

const { liveCanEditViewsGroup } = window.config;

//class Live extends Component {
export default function Live() {
  // console.log('Live render');

  // const { auth } = props;
  const auth = useAuth();
  const snackbar = useSnackbar();
  const error = useSelector(
    (state) => state.live.socketError || state.live.generalError
  );
  const warning = useSelector((state) => state.live.socketWarning);

  function checkAuth(group) {
    return !!group && auth.getGroupNames()?.includes(group);
  }
  const canEdit = checkAuth(liveCanEditViewsGroup);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
      console.error(error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (warning) {
      snackbar.notify('warning', warning);
      console.warn(warning);
    }
  }, [warning, snackbar]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { type = 'vehicles', id } = useParams();

  const prevId = usePrevious(id);
  useEffect(() => {
    if (id && prevId !== id) {
      setDrawerOpen(true);
    }
  }, [prevId, id]);

  const [authorisedTypes, setAuthorisedTypes] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  // JLSETTINGS const [showSettings, setShowSettings] = useState(false);
  const [itemInteractions, setItemInteractions] = useState({
    hoveredItem: {},
    selectedItem: {},
    scrollToItem: {},
  });

  // const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    function mapType(type) {
      switch (type) {
        case 'objectives':
        case 'plans':
          return 'briefs';
        default:
          return type;
      }
    }

    // init authorised types once (instead of sub-components repeating it on refresh)
    let authTypes = Object.keys(liveSubscription).filter((type) =>
      auth.isAuthorised(mapType(type), false)
    );
    // accelerometerEvents is often shortened to events so use that
    if (authTypes.includes('accelerometerAlerts')) {
      authTypes.push('events');
    }

    if (window.config.isFleet) {
      const fleetTypes = ['vehicles', 'locations'];
      authTypes = authTypes.filter((t) => fleetTypes.includes(t));
    }

    if (authTypes.includes('people')) {
      authTypes.push('callSigns');
    }

    // testing authTypes = ['plans', 'locations'];
    authTypes.push('tags');
    setAuthorisedTypes(authTypes);
    // testing setAuthorisedTypes(authTypes.concat(['events']));
    // testing setAuthorisedTypes(['vehicles', 'people', 'incidents', 'plans']);

    dispatch({
      type: START_LIVE_STREAM,
      payload: {
        //subscriptions: subscriptions.filter(s => authTypes.includes(s.type))
        subscriptionTypes: authTypes,
      },
    });

    return () => {
      dispatch({ type: END_LIVE_STREAM });
    };
  }, [auth, dispatch]);

  // not currently used
  // const handleOpen = () => {
  //   setDrawerOpen(true);
  // };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  // const login = () => {
  //   props.auth.login();
  //   props.handleMenuClose();
  // }

  // const logout = () => {
  //   props.auth.logout();
  //   props.handleMenuClose();
  // }

  // detail select will be handled by change in route e.g. /live/vehicles/1234
  // handleDetailSelect = item => {
  //   if (!item) {
  //     this.setState({ scrollToItem: {}, hoveredItem: {}, selectedItem: {} });
  //   } else {
  //     if (item.type) item.listName = item.type && liveTypes[item.type].listName;
  //     this.setState({
  //       scrollToItem: item,
  //       hoveredItem: {},
  //       selectedItem: item
  //     });
  //   }
  // };

  function handleItemSelect(item, scrollToItem) {
    if (!item || !item.id) {
      // if we were looking at an id, go back to the type
      if (id) {
        history.push(`/live/${type}`);
      }
      setItemInteractions({
        scrollToItem: {},
        hoveredItem: {},
        selectedItem: {},
      });
    } else {
      const newRoute =
        item.route || `/live/${item.type}/${encodeURIComponent(item.id)}`;
      if (newRoute !== history.location.pathname) {
        history.push(newRoute);
      }

      log('Read', 'Live Resource', item);

      setItemInteractions({
        scrollToItem,
        hoveredItem: item,
        selectedItem: item,
      });
    }
  }

  function handleSelectFromMap(item) {
    handleItemSelect(item, item);
  }

  function handleSelectFromList(item) {
    handleItemSelect(item, {});
  }

  const handleHover = (item) => {
    setItemInteractions({
      ...itemInteractions,
      hoveredItem: item,
    });
  };

  const handleTypeChange = (type) => {
    setItemInteractions({
      scrollToItem: {},
      hoveredItem: {},
      selectedItem: {},
    });

    // JLSETTINGS setShowSettings(false);
    setDrawerOpen(true);

    // this will do a filter change
    history.push(`/live/${type}`);
  };

  const handleSettingsSelected = () => {
    // JLSETTINGS setShowSettings(true);
    setDrawerOpen(true);

    history.push(`/live/settings`);
  };

  const renderList = () => {
    return (
      <LiveList
        selectedItem={itemInteractions.selectedItem}
        hoveredItem={itemInteractions.hoveredItem}
        scrollToItem={itemInteractions.scrollToItem}
        auth={auth}
        onHover={handleHover}
        onSelect={handleSelectFromList}
      />
    );
  };

  // JLSETTINGS
  // const renderSettings = () => {
  //   return <div style={{background: 'red', height: '100%'}}></div>;
  // }

  const renderContent = () => {
    // JLSETTINGS return showSettings ? renderSettings() : renderList();
    return renderList();
  };

  const showList = id == null;

  return (
    <div>
      <div className={classes.content}>
        {showList && (
          <Helmet>
            <title>{`IR3 | Live | ${_.capitalize(type)}`}</title>
          </Helmet>
        )}
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar} disableGutters>
            <div className={classes.tabs}>
              <TypeSelectorBar
                showBack={!showList}
                type={type}
                onTypeChange={handleTypeChange}
                onSettingsSelected={handleSettingsSelected}
                authorisedTypes={authorisedTypes}
              />
            </div>
            {!isMobile && (
              <div className={classes.liveViewsInAppBar}>
                <LiveViews authorisedTypes={authorisedTypes} />

                <div
                  className={
                    canEdit ? classes.avatarDiv : classes.avatarWhenCantEditView
                  }
                >
                  <LoginAvatar />
                </div>
              </div>
            )}
          </Toolbar>
        </AppBar>
        {isMobile ? (
          <Drawer anchor="bottom" open={drawerOpen} onClose={handleClose}>
            <div className={classes.drawerContent}>{renderContent()}</div>
          </Drawer>
        ) : (
          <div className={classes.itemsSection}>{renderContent()}</div>
        )}

        <div className={classes.mapSection}>
          {authorisedTypes.length > 0 && (
            <LiveMap
              authorisedTypes={authorisedTypes}
              selectedItem={itemInteractions.selectedItem}
              hoveredItem={itemInteractions.hoveredItem}
              onHover={handleHover}
              onSelect={handleSelectFromMap}
            />
          )}
        </div>
      </div>
    </div>
  );
}
