import React from 'react';
import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export function SelectMultiple({
  value,
  suggestions,
  onChange,
  isDisabled,
  typeIcons,
  ...props
}) {
  function handleChange(event, value) {
    onChange(value);
  }

  return (
    <Autocomplete
      multiple
      value={value || []}
      disabled={isDisabled}
      onChange={handleChange}
      options={suggestions || []}
      getOptionSelected={(option, selected) => option.value === selected.value}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => <TextField {...params} {...props} />}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          return (
            <Chip
              label={option.label}
              {...getTagProps({ index })}
              icon={typeIcons && typeIcons[option.type]}
            />
          );
        });
      }}
    />
  );
}
