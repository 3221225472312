import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Container from './Container';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 96px)',
    flex: 1,
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  // footer: {
  //   marginTop: theme.spacing(8),
  //   borderTop: `1px solid ${theme.palette.divider}`,
  //   padding: `${theme.spacing(6)}px 0`
  // },
  title: {
    fontWeight: 'bold',
  },
}));

export default function Home() {
  const classes = useStyles();

  return (
    <Container title="Not Found">
      <div className={classes.root}>
        <Helmet>
          <title>IR3 | Not Found</title>
        </Helmet>
        <main className={classes.main}>
          <div className={classes.heroContent}>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              component="p"
            >
              Whoops! I'm not sure how you got here but there's nothing here.
            </Typography>
          </div>
        </main>
      </div>
    </Container>
  );
}
