import {
  FETCH_FEATURES,
  FETCH_FEATURES_SUCCESS,
  FETCH_FEATURES_FAILURE,
  FETCH_FEATURE,
  FETCH_FEATURE_SUCCESS,
  FETCH_FEATURE_FAILURE,
  CREATE_FEATURE,
  CREATE_FEATURE_SUCCESS,
  CREATE_FEATURE_FAILURE,
  UPDATE_FEATURE,
  UPDATE_FEATURE_SUCCESS,
  UPDATE_FEATURE_FAILURE,
  DELETE_FEATURE,
  DELETE_FEATURE_SUCCESS,
  DELETE_FEATURE_FAILURE,
  FETCH_FEATURE_COLLECTION,
  FETCH_FEATURE_COLLECTION_SUCCESS,
  FETCH_FEATURE_COLLECTION_FAILURE,
  UPDATE_FEATURE_COLLECTION,
  SAVE_FEATURE_COLLECTION,
  SAVE_FEATURE_COLLECTION_SUCCESS,
  SAVE_FEATURE_COLLECTION_FAILURE,
  ADD_FEATURE_TO_COLLECTION,
  UPDATE_SELECTED_FEATURE_INDEX,
  UPDATE_SELECTED_FEATURE,
  UPDATE_SELECTED_FEATURE_GROUPS,
  UPDATE_SELECTED_FEATURE_TIME_PERIODS,
  UPDATE_SELECTED_FEATURE_GEOMETRY,
  UPDATE_HOVER_FEATURE_INDEX,
} from '../actions';

const INITIAL_STATE = {
  featureNames: [],
  feature: null,
  featureCollection: null,
  selectedFeatureIndex: null,
  hoverFeatureIndex: null,
  isLoading: false,
  error: null,
};

export default function featuresReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_FEATURES:
      return { ...state, isLoading: true, error: null };
    case FETCH_FEATURES_SUCCESS:
      return {
        ...state,
        featureNames: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_FEATURES_FAILURE:
      return {
        ...state,
        featureNames: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_FEATURE:
      return { ...state, isLoading: true, error: null };
    case FETCH_FEATURE_SUCCESS:
      return {
        ...state,
        feature: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_FEATURE_FAILURE:
      return {
        ...state,
        feature: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_FEATURE:
      return { ...state, isLoading: true, error: null };
    case UPDATE_FEATURE_SUCCESS:
      return {
        ...state,
        feature: action.payload,
        featureNames: state.featureNames.map((feature) =>
          feature.identifier === action.payload.identifier
            ? {
                identifier: action.payload.identifier,
                title: action.payload.title,
                subtype: action.payload.subtype,
              }
            : feature
        ),
        isLoading: false,
        error: null,
      };
    case UPDATE_FEATURE_FAILURE:
      return {
        ...state,
        feature: null,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_FEATURE:
      return { ...state, isLoading: true, error: null };
    case CREATE_FEATURE_SUCCESS:
      return {
        ...state,
        feature: action.payload,
        featureNames: state.featureNames.concat({
          identifier: action.payload.identifier,
          title: action.payload.title,
          subtype: action.payload.subtype,
        }),
        isLoading: false,
        error: null,
      };
    case CREATE_FEATURE_FAILURE:
      return {
        ...state,
        feature: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_FEATURE:
      return { ...state, isLoading: true, error: null };
    case DELETE_FEATURE_SUCCESS:
      const index = state.featureNames.findIndex(
        (feature) => feature.identifier === action.payload
      );

      return {
        ...state,
        feature: null,
        featureNames: state.featureNames
          .slice(0, index)
          .concat(state.featureNames.slice(index + 1)),
        isLoading: false,
        error: null,
      };
    case DELETE_FEATURE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_FEATURE_COLLECTION:
      return { ...state, isLoading: true, error: null };
    case FETCH_FEATURE_COLLECTION_SUCCESS:
      return {
        ...state,
        featureCollection: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_FEATURE_COLLECTION_FAILURE:
      return {
        ...state,
        featureCollection: null,
        isLoading: false,
        error: action.payload,
      };
    case SAVE_FEATURE_COLLECTION:
      return { ...state, isLoading: true, error: null };
    case SAVE_FEATURE_COLLECTION_SUCCESS:
      return { ...state, isLoading: false, error: null };
    case SAVE_FEATURE_COLLECTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_FEATURE_COLLECTION:
      return { ...state, featureCollection: action.payload };
    case ADD_FEATURE_TO_COLLECTION:
      return {
        ...state,
        featureCollection: {
          ...state.featureCollection,
          features: state.featureCollection.features.concat(action.payload),
        },
      };
    case UPDATE_SELECTED_FEATURE_INDEX:
      return { ...state, selectedFeatureIndex: action.payload };
    case UPDATE_SELECTED_FEATURE:
      return {
        ...state,
        featureCollection: {
          ...state.featureCollection,
          features: state.featureCollection.features
            .slice(0, action.payload.id)
            .concat(action.payload)
            .concat(
              state.featureCollection.features.slice(action.payload.id + 1)
            ),
        },
      };
    case UPDATE_SELECTED_FEATURE_GROUPS:
      return {
        ...state,
        featureCollection: {
          ...state.featureCollection,
          features: state.featureCollection.features
            .slice(0, state.selectedFeatureIndex)
            .concat({
              ...state.featureCollection.features[state.selectedFeatureIndex],
              properties: {
                ...state.featureCollection.features[state.selectedFeatureIndex]
                  .properties,
                areas: action.payload,
              },
            })
            .concat(
              state.featureCollection.features.slice(
                state.selectedFeatureIndex + 1
              )
            ),
        },
      };
    case UPDATE_SELECTED_FEATURE_TIME_PERIODS:
      return {
        ...state,
        featureCollection: {
          ...state.featureCollection,
          features: state.featureCollection.features
            .slice(0, state.selectedFeatureIndex)
            .concat({
              ...state.featureCollection.features[state.selectedFeatureIndex],
              properties: {
                ...state.featureCollection.features[state.selectedFeatureIndex]
                  .properties,
                activeTimePeriods: action.payload,
              },
            })
            .concat(
              state.featureCollection.features.slice(
                state.selectedFeatureIndex + 1
              )
            ),
        },
      };
    case UPDATE_SELECTED_FEATURE_GEOMETRY:
      return {
        ...state,
        featureCollection: {
          ...state.featureCollection,
          features: state.featureCollection.features
            .slice(0, state.selectedFeatureIndex)
            .concat({
              ...state.featureCollection.features[state.selectedFeatureIndex],
              geometry: action.payload,
            })
            .concat(
              state.featureCollection.features.slice(
                state.selectedFeatureIndex + 1
              )
            ),
        },
      };
    case UPDATE_HOVER_FEATURE_INDEX:
      return { ...state, hoverFeatureIndex: action.payload };
    default:
      return state;
  }
}
