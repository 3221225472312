import _ from 'lodash';
import { IconButton, Popover, TextField, makeStyles } from '@material-ui/core';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Fragment, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  popoverSheet: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    margin: theme.spacing(1),
    width: 200,
  },
}));

export default function FilterPicker({
  headers,
  data,
  filter,
  onFilterChange,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleFilterClose() {
    setAnchorEl(null);
  }

  function handleFilterOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <Fragment>
      <IconButton
        title="Filter"
        aria-owns={anchorEl ? 'filter-popover' : null}
        aria-haspopup="true"
        onClick={handleFilterOpen}
      >
        <FilterListIcon />
      </IconButton>
      <Popover
        id="filter-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div className={classes.popoverSheet}>
          <FilterPopover
            headers={headers}
            data={data}
            filter={filter}
            onFilterChange={onFilterChange}
          />
        </div>
      </Popover>
    </Fragment>
  );
}

function FilterPopover({ headers, data, filter, onFilterChange }) {
  const classes = useStyles();
  const filterArray = headers.filter(({ filter }) => filter !== false);

  return filterArray.map((filterItem, index) => {
    function createOptions() {
      return _.uniq(
        data
          .map((dataItem) => _.get(dataItem, filterItem.key))
          .filter((value) => value !== undefined)
          .sort()
      );
    }

    function updateFilter(update) {
      onFilterChange({
        ...filter,
        fields: {
          ...filter.fields,
          ...update,
        },
      });
    }

    const handleFilterChange = (name) => (_, value) => {
      let key = name;
      if (Array.isArray(key)) {
        key = key.join('.');
      }
      updateFilter({ [key]: value });
    };

    const path = Array.isArray(filterItem.key) ? filterItem.key.join('.') : filterItem.key;
    const fieldValue = filter.fields[path];

    return (
      <Autocomplete
        key={index}
        value={(fieldValue === null || typeof fieldValue === 'undefined')
          ? null : fieldValue}
        options={createOptions()}
        getOptionLabel={(option) =>
          typeof option === 'boolean' ? (option ? 'Yes' : 'No') : option
        }
        onChange={handleFilterChange(filterItem.key)}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.textField}
            label={filterItem.label}
          />
        )}
      />
    );
  });
}
