import { Typography, useTheme } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import {
  Error as ErrorIcon,
  Input as InputIcon,
  Navigation as NavigationIcon,
  VolumeUp as VolumeUpIcon,
  VpnKey as VpnKeyIcon,
  Warning as WarningIcon,
  WbIncandescent as WbIncandescentIcon,
} from '@material-ui/icons';
import RvHookupIcon from '@material-ui/icons/RvHookup';
import {
  AlarmLight as AlarmLightIcon,
  CarSide as CarSideIcon,
  Engine as EngineIcon,
  RadioHandheld as RadioHandheldIcon,
  CarLightHigh as HeadlightsFlashIcon
} from 'mdi-material-ui';
import _ from 'lodash';
import React, { Fragment } from 'react';
import LiveListItem, { getIfNotAlreadyShown } from './LiveListItem';

const { useReducedResourceInformation, dioStates } = window.config;

function VehicleListItem({ item: vehicle, classes, tertiaryPath, ...props }) {
  const {
    driverName,
    speedKilometresPerHour,
    headingDegrees,
    ignitionOn,
    sirensOn,
    beaconsOn,
    headlightsFlashOn,
    malfunctionIndicatorLightOn,
    accelerometerAlert,
    rearBlueLightsOn,
    rearRedLightsOn,
    strikeButtonOn,
    frontPWEOn,
    rearPWEOn,
    airwaveOn,
    ancillaryEquipmentOn,
    amberLightsOn,
    trailerOn,
  } = vehicle;

  const theme = useTheme();
  const labelOverridePath =
    window.config.liveOptions?.labelOverridePaths?.['vehicles'];
  const primaryOverride = _.get(vehicle, labelOverridePath);

  const primaryPath = useReducedResourceInformation
    ? 'fleetNumber'
    : 'registrationNumber';
  let shownPaths = [
    primaryPath,
    'speedKilometresPerHour',
    'driverName',
    'headingDegrees',
    'ignitionOn',
  ].concat(Object.keys(dioStates));
  const tertiaryValue = primaryOverride
    ? _.get(vehicle, primaryPath)
    : getIfNotAlreadyShown(vehicle, tertiaryPath, shownPaths);

  const secondaryComponent = (
    <Fragment>
      {
        <div style={{ display: 'flex' }}>
          {driverName}
          <Typography
            title={tertiaryValue}
            className={classes.tertiaryValueRightAligned}
          >
            {tertiaryValue}
          </Typography>
        </div>
      }
      {
        <div style={{ display: 'flex' }}>
          <NavigationIcon
            // fromCharCode so degrees symbol doesn't stop chrome debugger mapping
            titleAccess={'Heading ' + headingDegrees + String.fromCharCode(176)}
            className={classes.statusIcon}
            style={{
              transform: `rotate(${headingDegrees}deg)`,
            }}
            color="action"
          />
          {ignitionOn !== undefined && (
            <VpnKeyIcon
              titleAccess={'Ignition ' + (ignitionOn ? 'on' : 'off')}
              className={classes.statusIcon}
              htmlColor={
                ignitionOn ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          {sirensOn !== undefined && (
            <VolumeUpIcon
              titleAccess={'Siren ' + (sirensOn ? 'on' : 'off')}
              className={classes.statusIcon}
              htmlColor={sirensOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          {beaconsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={'Beacons ' + (beaconsOn ? 'on' : 'off')}
              className={classes.statusIcon}
              htmlColor={beaconsOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          {headlightsFlashOn !== undefined && (
            <HeadlightsFlashIcon
              titleAccess={'Headlights flash ' + (headlightsFlashOn ? 'on' : 'off')}
              className={classes.statusIcon}
              htmlColor={headlightsFlashOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          {malfunctionIndicatorLightOn !== undefined && (
            <EngineIcon
              titleAccess={
                'Malfunction Indicator Light ' +
                (malfunctionIndicatorLightOn ? 'on' : 'off')
              }
              className={classes.statusIcon}
              htmlColor={
                malfunctionIndicatorLightOn
                  ? amber[700]
                  : theme.palette.action.disabled
              }
            />
          )}
          {accelerometerAlert !== undefined && (
            <WarningIcon
              className={classes.statusIcon}
              htmlColor={
                accelerometerAlert ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          {rearBlueLightsOn !== undefined && (
            <WbIncandescentIcon
              titleAccess={
                'Rear Blue Lights ' + (rearBlueLightsOn ? 'on' : 'off')
              }
              className={classes.statusIcon}
              color={rearBlueLightsOn ? 'primary' : 'disabled'}
            />
          )}
          {rearRedLightsOn !== undefined && (
            <WbIncandescentIcon
              titleAccess={
                'Rear Red Lights ' + (rearRedLightsOn ? 'on' : 'off')
              }
              className={classes.statusIcon}
              color={rearRedLightsOn ? 'error' : 'disabled'}
            />
          )}
          {strikeButtonOn !== undefined && (
            <ErrorIcon
              className={classes.statusIcon}
              htmlColor={
                strikeButtonOn ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          {(frontPWEOn !== undefined || rearPWEOn !== undefined) && (
            <CarSideIcon
              className={classes.statusIcon}
              component={(svgProps) => {
                return (
                  <svg {...svgProps}>
                    <defs>
                      <linearGradient id="gradient1">
                        <stop
                          offset="50%"
                          stopColor={
                            rearPWEOn
                              ? theme.palette.primary.dark
                              : theme.palette.action.disabled
                          }
                        />
                        <stop
                          offset="50%"
                          stopColor={
                            frontPWEOn
                              ? theme.palette.primary.dark
                              : theme.palette.action.disabled
                          }
                        />
                      </linearGradient>
                    </defs>
                    {React.cloneElement(svgProps.children[0], {
                      fill: 'url(#gradient1)',
                    })}
                  </svg>
                );
              }}
            />
          )}
          {airwaveOn !== undefined && (
            <RadioHandheldIcon
              className={classes.statusIcon}
              htmlColor={airwaveOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          {ancillaryEquipmentOn !== undefined && (
            <InputIcon
              className={classes.statusIcon}
              htmlColor={
                ancillaryEquipmentOn
                  ? amber[700]
                  : theme.palette.action.disabled
              }
            />
          )}
          {amberLightsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={
                amberLightsOn ? 'Amber lights on' : 'Amber lights off'
              }
              className={classes.statusIcon}
              htmlColor={
                amberLightsOn ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          {trailerOn !== undefined && ( //nature rv_hookup
            <RvHookupIcon
              titleAccess={trailerOn ? 'Trailer on' : 'No trailer'}
              className={classes.statusIcon}
              htmlColor={trailerOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          <Typography
            style={{
              flex: 1,
              textAlign: 'right',
              fontSize: 12,
              // marginRight: theme.spacing(1),
            }}
          >
            {`${Math.round(speedKilometresPerHour * 0.62137119)} MPH`}
          </Typography>
        </div>
      }
    </Fragment>
  );

  return (
    <LiveListItem
      type="vehicles"
      primaryPath={primaryPath}
      secondaryComponent={secondaryComponent}
      item={vehicle}
      classes={classes}
      {...props}
    />
  );
}

export default VehicleListItem;
