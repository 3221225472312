import React from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { LocationTypeIcon } from '../../../data/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0.5, 1, 1),
    fontSize: 12,
  },
}));

export default function Location({ color, background, name, type, subtype }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>IR3 | Location | {name}</title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title={type}
          >
            <LocationTypeIcon type={type} />
          </Avatar>
        }
        title={name}
        subheader={subtype}
      />
      <CardContent></CardContent>
    </Card>
  );
}
