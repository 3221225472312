import {
  FETCH_REPLAY,
  FETCH_REPLAY_SUCCESS,
  FETCH_REPLAY_FAILURE,
} from '../actions';

const INITIAL_STATE = {
  id: null,
  frames: [],
  paths: null,
  isLoading: false,
  error: null,
};

export default function replayReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_REPLAY:
      return { ...state, isLoading: true, error: null };
    case FETCH_REPLAY_SUCCESS:
      return { ...state, ...action.payload, isLoading: false, error: null };
    case FETCH_REPLAY_FAILURE:
      return {
        ...state,
        id: null,
        frames: [],
        paths: null,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
