import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import { Field, TextField, SelectField, CheckboxField } from '../../fields';

const useStyles = makeStyles((theme) => ({
  field: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
}));

export default function CrimeParameters({ name, filters }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Field
        name={`${name}.number`}
        component={TextField}
        label="Number"
        type="text"
        className={classes.field}
      />
      <Field
        name={`${name}.type^code`}
        component={SelectField}
        label="Type"
        className={classes.field}
        values={(filters.types || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <Field
        name={`${name}.dispatchType^code`}
        component={SelectField}
        label="Dispatch Type"
        className={classes.field}
        values={(filters.dispatchTypes || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <Field
        name={`${name}.classifications^code`}
        component={SelectField}
        label="Classification"
        className={classes.field}
        values={(filters.classifications || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <Field
        name={`${name}.status`}
        component={SelectField}
        label="Status"
        className={classes.field}
        values={(filters.statuses || []).map((item) => {
          return { value: item.code, label: item.name };
        })}
      />
      <Field
        name={`${name}.areas^bcu^name`}
        component={SelectField}
        label="BCU"
        className={classes.field}
        values={(filters.bcus || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <Field
        name={`${name}.areas^localAuthority^name`}
        component={SelectField}
        label="Local Authority"
        className={classes.field}
        values={(filters.localAuthorities || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <Field
        name={`${name}.areas^section^name`}
        component={SelectField}
        label="Section"
        className={classes.field}
        values={(filters.sections || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <Field
        name={`${name}.areas^sector^name`}
        component={SelectField}
        label="Sector"
        className={classes.field}
        values={(filters.sectors || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <Field
        name={`${name}.areas^beat^name`}
        component={SelectField}
        label="Beat"
        className={classes.field}
        values={(filters.beats || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <Field
        name={`${name}.areas^ward^name`}
        component={SelectField}
        label="Ward"
        className={classes.field}
        values={(filters.wards || []).map((item) => {
          return { value: item.name, label: item.name };
        })}
      />
      <Field
        name={`${name}.isConcluded`}
        component={CheckboxField}
        label="Concluded"
        className={classes.field}
      />
    </Fragment>
  );
}
