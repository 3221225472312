import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import  from 'recharts/lib/component/ResponsiveContainer';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Label,
} from 'recharts';
import { blue, red, green } from '@material-ui/core/colors';
import moment from 'moment';
import _ from 'lodash';
import { Avatar, Typography, makeStyles } from '@material-ui/core';
import { FETCH_TELEMATICS_BOX_POLLS } from '../../../../actions';
import { decodeSignalStrength } from '../../../resources/TelematicsBoxList/utilities';

const useStyles = makeStyles((theme) => ({
  legend: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  legendItem: {
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendAvatar: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(0.5),
  },
  noData: {
    height: 320,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    color: theme.palette.text.disabled,
  },
}));

const lines = [
  {
    name: 'Battery',
    colour: red[500],
    dataKey: 'batteryVoltage',
    yAxisId: 'voltage',
    yLabel: 'Volts',
    type: 'monotone',
  },
  {
    name: 'Satellites',
    colour: green[500],
    dataKey: 'satelliteCount',
    yAxisId: 'count',
    yLabel: 'Count',
    type: 'stepAfter',
  },
  {
    name: 'GPRS',
    colour: blue[500],
    dataKey: (p) => decodeSignalStrength(p.signalStrength), //'internalCell',
    yAxisId: 'count',
    yLabel: 'Signal',
    type: 'stepAfter',
  },
];

// all in poll.deviceProperties, maybe deviceSignalStrength
const extractData = (polls, imei) => {
  return _.orderBy(
    (polls || []).map(
      ({
        deviceProperties: {
          batteryVoltage,
          satelliteCount,
          deviceSignalStrength: signalStrength,
        },
        time,
      }) => {
        return {
          batteryVoltage,
          satelliteCount,
          signalStrength,
          time,
        };
      }
    ),
    'time'
  );
};

const memoizedExtractData = _.memoize(extractData);

export default function TelematicsBoxChart({ imei, lastContact }) {
  const [hiddenLines, setHiddenLines] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: FETCH_TELEMATICS_BOX_POLLS,
      payload: {
        imei,
        start: moment(lastContact).utc().subtract(1, 'd'),
        end: moment(lastContact).utc(),
      },
    });
  }, [imei, lastContact, dispatch]);

  const data = useSelector(
    (state) => state.telematicsBoxes.boxesByImei[imei].polls
  );
  const extractedData = memoizedExtractData(data, imei);

  function handleLegendClick(selectedLine) {
    const index = hiddenLines.indexOf(selectedLine);

    if (index === -1) {
      setHiddenLines(hiddenLines.concat(selectedLine));
    } else {
      setHiddenLines(
        hiddenLines.slice(0, index).concat(hiddenLines.slice(index + 1))
      );
    }
  }

  return (
    <Fragment>
      {extractedData.length > 0 ? (
        <Fragment>
          <div className={classes.legend}>
            {lines.map((line) => (
              <div
                key={line.name}
                className={classes.legendItem}
                onClick={() => handleLegendClick(line.name)}
              >
                <Avatar
                  className={classes.legendAvatar}
                  style={{
                    backgroundColor:
                      !hiddenLines.includes(line.name) && line.colour,
                  }}
                >
                  <Fragment />
                </Avatar>
                <Typography variant="caption">{line.name}</Typography>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex' }}>
            {lines
              .filter((line) => !hiddenLines.includes(line.name))
              .map((line) => (
                <div key={line.name} style={{ flex: 'auto', width: 0 }}>
                  {/* width is 0 due to https://github.com/recharts/recharts/issues/172 (but workaround results in slow animation)
                    and https://stackoverflow.com/questions/7985021/css-flexbox-issue-why-is-the-width-of-my-flexchildren-affected-by-their-content 
                */}
                  <ResponsiveContainer height={280}>
                    <LineChart data={extractedData}>
                      <XAxis
                        dataKey="time"
                        tickFormatter={(timeStr) =>
                          moment(timeStr).format('DD/MM HH:mm:ss')
                        }
                      >
                        <Label value="Time" position="bottom" offset={36} />
                      </XAxis>
                      <YAxis yAxisId={line.yAxisId}>
                        <Label
                          value={line.yLabel}
                          angle={-90}
                          position="insideLeft"
                          offset={24}
                        />
                      </YAxis>
                      <Tooltip
                        labelFormatter={(timeStr) =>
                          moment(timeStr).format('DD/MM HH:mm:ss')
                        }
                      />
                      <Line
                        key={line.dataKey}
                        yAxisId={line.yAxisId}
                        type={line.type}
                        name={line.name}
                        dataKey={line.dataKey}
                        stroke={line.colour}
                        strokeWidth={1}
                        dot={false}
                        //hide={hiddenLines.includes(line.name)}
                        connectNulls={true}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              ))}
          </div>
        </Fragment>
      ) : (
        <div className={classes.noData}>
          <div>
            <Typography variant="caption" className={classes.caption}>
              No data
            </Typography>
          </div>
        </div>
      )}
      {/* </ResponsiveContainer> */}
    </Fragment>
  );
}
