import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import { Field, ParameterField } from '../../fields';

const useStyles = makeStyles((theme) => ({
  field: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
}));

export default function IncidentParameters({ name, filters }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Field
        name={`${name}.number`}
        component={ParameterField}
        label="Number"
        type="text"
        className={classes.field}
      />
      <Field
        name={`${name}.type^code`}
        component={ParameterField}
        label="Type"
        type="text"
        className={classes.field}
        values={Object.entries(filters.types || {})}
      />
      <Field
        name={`${name}.responseCategory^code`}
        component={ParameterField}
        label="Response Category"
        type="text"
        className={classes.field}
        values={[
          'Immediate Urban',
          'Immediate Rural',
          'Priority',
          'Scheduled',
          'Unknown',
        ]}
      />
      <Field
        name={`${name}.grade`}
        component={ParameterField}
        label="Grade"
        type="number"
        className={classes.field}
      />
      <Field
        name={`${name}.status`}
        component={ParameterField}
        label="Status"
        type="text"
        className={classes.field}
        values={['Opened', 'Assigned', 'Deployed', 'Attended', 'Closed']}
      />
      <Field
        name={`${name}.closingCodes@code`}
        component={ParameterField}
        label="Closing Code"
        type="text"
        className={classes.field}
        values={Object.entries(filters.closingCodes || {})}
      />
    </Fragment>
  );
}
