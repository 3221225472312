import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  icon: {
    margin: theme.spacing(1),
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
}));

export default function ListSearch(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SearchIcon className={classes.icon} width={24} height={24} />
      <InputBase
        className={classes.input}
        placeholder="Search"
        value={props.value}
        onChange={props.onChange}
      />
      {props.onFilterToggle && (
        <Fragment>
          <Divider className={classes.divider} />
          <IconButton
            className={classes.iconButton}
            title={props.filterVisible ? 'Hide filter' : 'Show filter'}
            aria-label="Toggle filter"
            onClick={props.onFilterToggle}
          >
            <FilterListIcon
              color={props.filterVisible ? 'primary' : 'inherit'}
            />
          </IconButton>
        </Fragment>
      )}
    </div>
  );
}
