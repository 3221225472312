import React, { Fragment, useState } from 'react';
import {
  Checkbox,
  Collapse,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons';
import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  headerCell: {
    backgroundColor: theme.palette.background.paper,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (_.get(b, orderBy, '') < _.get(a, orderBy, '')) {
    return -1;
  }
  if (_.get(b, orderBy, '') > _.get(a, orderBy, '')) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const rightAlignedTypes = ['number'];

function TableHead({
  headers,
  order,
  orderBy,
  onSort,
  numSelected,
  rowCount,
  selectMode,
  onSelectAllClick,
}) {
  const classes = useStyles();

  const handleSortClick = (key) => (event) => {
    onSort(event, key);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {selectMode === 'multi' && (
          <TableCell padding="checkbox" className={classes.headerCell}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headers.map((header) => (
          <TableCell
            key={header.key}
            align={rightAlignedTypes.includes(header.type) ? 'right' : 'left'}
            sortDirection={orderBy === header.key ? order : false}
            className={classes.headerCell}
          >
            {!order || header.type === 'component' ? (
              header.label
            ) : (
              <TableSortLabel
                active={orderBy === header.key}
                direction={orderBy === header.key ? order : 'asc'}
                onClick={handleSortClick(header.key)}
              >
                {header.label}
                {orderBy === header.key ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
}

function Value({ header, row }) {
  if (header.type !== 'component' && _.get(row, header.key) === undefined) {
    return '';
  }

  switch (header.type) {
    case 'text':
      return header.format
        ? header.format(_.get(row, header.key))
        : _.get(row, header.key);
    case 'number':
      return header.format
        ? header.format(_.get(row, header.key))
        : _.round(_.get(row, header.key), 2);
    case 'date':
      return header.format
        ? header.format(_.get(row, header.key))
        : moment(_.get(row, header.key)).format('DD/MM/YYYY, HH:mm:ss');
    case 'boolean':
      return header.format
        ? header.format(_.get(row, header.key))
        : _.get(row, header.key)
        ? 'Yes'
        : 'No';
    case 'component':
      return header.component ? <header.component entry={row} /> : '';
    default:
      return '';
  }
}

function Row({ row, headers, selectMode, onClick, keyName, selected }) {
  const [expandedKey, setExpandedKey] = useState(null);
  const classes = useStyles();

  const handleClick = (key) => () => {
    onClick(key);
  };

  const Expanded = expandedKey
    ? headers.find((h) => h.key === expandedKey).component
    : () => '';

  const selectableProps = selectMode
    ? {
        hover: true,
        onClick: handleClick(keyName ? row[keyName] : row),
        role: 'checkbox',
        tabIndex: -1,
        selected,
      }
    : {};

  return (
    <Fragment>
      <TableRow className={classes.root} {...selectableProps}>
        {selectMode === 'multi' && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} />
          </TableCell>
        )}
        {headers.map((header) => (
          <TableCell
            key={header.key}
            align={rightAlignedTypes.includes(header.type) ? 'right' : 'left'}
          >
            {header.type === 'expand' ? (
              <IconButton
                size="small"
                onClick={() => {
                  if (expandedKey === header.key) {
                    setExpandedKey(null);
                  } else {
                    setExpandedKey(header.key);
                  }
                }}
              >
                {expandedKey === header.key
                  ? header.closeIcon || <KeyboardArrowUpIcon />
                  : header.openIcon || <KeyboardArrowDownIcon />}
              </IconButton>
            ) : (
              <Value header={header} row={row} />
            )}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headers.length + (selectMode === 'multi' ? 1 : 0)}
        >
          <Collapse in={expandedKey !== null} timeout="auto" unmountOnExit>
            <Expanded entry={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function Table({
  data,
  headers,
  footer,
  page,
  rowsPerPage,
  selectMode,
  classes = {},
  dense,
  keyName,
  selectedKeys = [],
  onSelectAllClick,
  onSelectClick,
  order,
  orderBy,
  onOrderChange,
  onOrderByChange,
}) {
  function handleSort(event, key) {
    if (key === orderBy) {
      onOrderChange(order === 'asc' ? 'desc' : 'asc');
    } else {
      onOrderByChange(key);
    }
  }

  const headerProps =
    selectMode === 'multi'
      ? {
          numSelected: selectedKeys.length,
          rowCount: data.length,
          onSelectAllClick: onSelectAllClick,
        }
      : {};

  return (
    <TableContainer className={classes.tableContainer}>
      <MuiTable
        className={classes.table}
        size={dense ? 'small' : 'medium'}
        stickyHeader
      >
        <TableHead
          headers={headers}
          order={order}
          orderBy={orderBy}
          onSort={handleSort}
          selectMode={selectMode}
          {...headerProps}
        />
        <TableBody>
          {stableSort(data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const selectableProps = selectMode
                ? {
                    selectMode,
                    keyName: keyName,
                    selected: selectedKeys.includes(row[keyName]),
                    onClick: onSelectClick,
                  }
                : {};

              return (
                <Row
                  key={row[keyName] || index}
                  row={row}
                  headers={headers}
                  {...selectableProps}
                />
              );
            })}
          <TableRow />
        </TableBody>
        {footer && (
          <TableFooter>
            <TableRow>
              {footer.map((col, index) => (
                <TableCell
                  key={index}
                  colSpan={col.colSpan || 1}
                  align={
                    rightAlignedTypes.includes(col.type) ? 'right' : 'left'
                  }
                >
                  {col.value}
                </TableCell>
              ))}
            </TableRow>
          </TableFooter>
        )}
      </MuiTable>
    </TableContainer>
  );
}
