import React, { Fragment, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';
import { blue, red, orange, green } from '@material-ui/core/colors';
import moment from 'moment';
import { CustomTooltip } from '../../controls';
import { Avatar, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  legend: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  legendItem: {
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendAvatar: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(0.5),
  },
}));

const lines = [
  {
    name: 'Speed',
    colour: red[500],
    dataKey: 'speedMilesPerHour',
    yAxisId: 'speed',
  },
  {
    name: 'Horizontal Force',
    colour: green[500],
    dataKey: 'horizontalForce',
    yAxisId: 'g',
  },
  {
    name: 'Vertical Force',
    colour: orange[500],
    dataKey: 'verticalForce',
    yAxisId: 'g',
  },
  {
    name: 'Lateral Force',
    colour: blue[500],
    dataKey: 'lateralForce',
    yAxisId: 'g',
  },
];

export default function AccelerometerChart({ data }) {
  const [hiddenLines, setHiddenLines] = useState([]);
  const classes = useStyles();

  function handleLegendClick(selectedLine) {
    const index = hiddenLines.indexOf(selectedLine);

    if (index === -1) {
      setHiddenLines(hiddenLines.concat(selectedLine));
    } else {
      setHiddenLines(
        hiddenLines.slice(0, index).concat(hiddenLines.slice(index + 1))
      );
    }
  }

  function formatLabel(timeStr) {
    return moment(timeStr).format('DD/MM HH:mm:ss:SSS');
  }

  return (
    <Fragment>
      <div className={classes.legend}>
        {lines.map((line) => (
          <div
            key={line.name}
            className={classes.legendItem}
            onClick={() => handleLegendClick(line.name)}
          >
            <Avatar
              className={classes.legendAvatar}
              style={{
                backgroundColor:
                  !hiddenLines.includes(line.name) && line.colour,
              }}
            >
              <Fragment />
            </Avatar>
            <Typography variant="caption">{line.name}</Typography>
          </div>
        ))}
      </div>
      <ResponsiveContainer height={280}>
        <LineChart data={data}>
          <XAxis dataKey="time" tickFormatter={formatLabel}>
            <Label value="Time" position="bottom" offset={36} />
          </XAxis>
          <YAxis yAxisId="g">
            <Label
              value="Force (g)"
              angle={-90}
              position="insideLeft"
              offset={16}
            />
          </YAxis>
          <YAxis yAxisId="speed" orientation="right">
            <Label
              value="Speed (mph)"
              angle={90}
              position="insideRight"
              offset={16}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip labelFormatter={formatLabel} />} />
          {lines.map((line) => (
            <Line
              key={line.dataKey}
              yAxisId={line.yAxisId}
              type="monotone"
              name={line.name}
              dataKey={line.dataKey}
              stroke={line.colour}
              strokeWidth={1}
              dot={false}
              hide={hiddenLines.includes(line.name)}
              connectNulls={true}
            />
          ))}
          <ReferenceArea x1={data[330].time} x2={data[339].time} yAxisId="g" />
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  );
}
