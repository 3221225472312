import {
  Avatar,
  Paper,
  CardHeader,
  CircularProgress,
  IconButton,
  Popover,
  TablePagination,
  TextField,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  ChromeReaderMode as ChromeReaderModeIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Autorenew as AutorenewIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_AUDIT_LOG_ENTRIES,
  FETCH_AUDIT_LOG_ENTRIES_CANCELLED,
  FETCH_USER_IDS,
  UPDATE_AUDIT_LOG_FILTER,
} from '../../../actions';
import Container from '../../Container';
import { DatePicker, Table } from '../../controls';
import { downloadCSV } from '../../../apis/utilities';
import Link from './Link';
import Parameters from './Parameters';

const { rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(1),
  },
  cardHeader: {
    flexGrow: 1,
  },
  filterField: {
    width: 144,
  },
  progress: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    width: 200,
  },
  placeholder: {
    color: theme.palette.grey[500],
  },
  popoverSheet: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  userId: {
    width: 320,
    paddingRight: theme.spacing(1),
  },
  tableContainer: {
    height: 'calc(100vh - 188px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
}));

const headers = [
  { label: 'User ID', key: 'userId', type: 'text' },
  { label: 'Time', key: 'time', type: 'date' },
  { label: 'Data Type', key: 'dataType', type: 'text' },
  { label: 'Action', key: 'action', type: 'text' },
  { label: 'Parameters', key: 'parameters', type: 'text' },
];

const tableHeaders = [
  { label: 'User ID', key: 'userId', type: 'text' },
  { label: 'Time', key: 'time', type: 'date' },
  { label: 'Data Type', key: 'dataType', type: 'text' },
  { label: 'Action', key: 'action', type: 'text' },
  {
    label: 'Parameters',
    key: 'parameters',
    type: 'component',
    component: Parameters,
  },
  { label: 'Link', key: 'link', type: 'component', component: Link },
];

export default function Audit() {
  const dispatch = useDispatch();
  const entries = useSelector(
    (state) => state.reports.auditLogEntries,
    _.isEqual
  );
  const isLoading = useSelector((state) => state.reports.isLoading);
  const error = useSelector((state) => state.reports.error);
  const filter = useSelector((state) => state.filters.auditLog, _.isEqual);
  const userIds = useSelector((state) => state.filters.userIds, _.isEqual);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (userIds.length === 0) {
      dispatch({
        type: FETCH_USER_IDS,
      });
    }
  }, [dispatch, userIds]);

  function filterEntries(entry) {
    if (filter.userId && filter.userId !== entry.userId) {
      return false;
    }

    if (filter.application && filter.application !== entry.application) {
      return false;
    }

    if (filter.dataType && filter.dataType !== entry.dataType) {
      return false;
    }

    if (filter.action && filter.action !== entry.action) {
      return false;
    }

    const json = entry.parameters && JSON.stringify(entry.parameters);
    if (
      filter.searchText &&
      json &&
      !json.toLowerCase().includes(filter.searchText.toLowerCase())
    ) {
      return false;
    }

    return true;
  }

  function handleRefreshClick() {
    if (isLoading) {
      dispatch({
        type: FETCH_AUDIT_LOG_ENTRIES_CANCELLED,
      });
    } else {
      dispatch({
        type: FETCH_AUDIT_LOG_ENTRIES,
        payload: {
          startTime: filter.startTime,
          endTime: moment(filter.endTime).add(1, 'd').toDate(),
          userId: filter.userId,
        },
      });
    }
  }

  function handleFilterOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleFilterClose() {
    setAnchorEl(null);
  }

  function updateFilter(update) {
    dispatch({
      type: UPDATE_AUDIT_LOG_FILTER,
      payload: {
        ...filter,
        ...update,
      },
    });
  }

  const handleFilterChange = (name) => (event, value) => {
    updateFilter({ [name]: value });
  };

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleStartTimeChange(startTime) {
    updateFilter({ startTime });
  }

  function handleEndTimeChange(endTime) {
    updateFilter({ endTime });
  }

  function handleUserIdChange(event, userId) {
    updateFilter({ userId });
  }

  function handleDownloadClick() {
    const data = filteredEntries.map((entry) => ({
      userId: entry.userId,
      time: moment(entry.time).format('YYYY-MM-DD HH:mm:ss'),
      application: entry.application,
      dataType: entry.dataType,
      action: entry.action,
      parameters: entry.parameters
        ? JSON.stringify(entry.parameters)
        : //.replace(/"/g,`""`)
          '',
    }));

    downloadCSV(data, 'Audits Log Entries.csv', headers);
  }

  const filteredEntries = entries.filter(filterEntries);
  if (filteredEntries.length < filter.page * filter.rowsPerPage) {
    updateFilter({ page: ~~(filteredEntries.length / filter.rowsPerPage) });
  }

  return (
    <Container title="Audit">
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Audit</title>
        </Helmet>
        <Paper className={classes.card}>
          <Toolbar disableGutters style={{ paddingRight: 12 }}>
            <CardHeader
              avatar={
                <Avatar>
                  <ChromeReaderModeIcon />
                </Avatar>
              }
              className={classes.cardHeader}
              title="Audit Log"
            />
            {isLoading && (
              <CircularProgress
                className={classes.progress}
                size={16}
                thickness={6}
              />
            )}
            <Autocomplete
              value={filter.userId}
              options={Object.values(userIds)}
              onChange={handleUserIdChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="User ID"
                  className={classes.userId}
                />
              )}
            />
            <DatePicker
              value={filter.startTime}
              onChange={handleStartTimeChange}
              clearable
              className={classes.filterField}
              maxDate={filter.endTime || '2100-01-01'}
            />
            &nbsp;-&nbsp;
            <DatePicker
              value={filter.endTime}
              onChange={handleEndTimeChange}
              clearable
              className={classes.filterField}
              minDate={filter.startTime || '1900-01-01'}
              disableFuture
            />
            <IconButton
              title={isLoading ? 'Cancel' : 'Fetch'}
              onClick={handleRefreshClick}
            >
              {isLoading ? <CloseIcon color="error" /> : <AutorenewIcon />}
            </IconButton>
            <IconButton
              title="Filter"
              aria-owns={anchorEl ? 'filter-popover' : null}
              aria-haspopup="true"
              onClick={handleFilterOpen}
            >
              <FilterListIcon />
            </IconButton>
            <Popover
              id="filter-popover"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleFilterClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <div className={classes.popoverSheet}>
                <Autocomplete
                  value={filter.userId}
                  options={_.uniq(
                    entries
                      .map((entry) => entry.userId)
                      .filter((value) => value)
                      .sort()
                  )}
                  onChange={handleFilterChange('userId')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.textField}
                      label="User ID"
                    />
                  )}
                />
                <Autocomplete
                  value={filter.dataType}
                  options={_.uniq(
                    entries
                      .map((entry) => entry.dataType)
                      .filter((value) => value)
                      .sort()
                  )}
                  onChange={handleFilterChange('dataType')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.textField}
                      label="Data Type"
                    />
                  )}
                />
                <Autocomplete
                  value={filter.action}
                  options={_.uniq(
                    entries
                      .map((entry) => entry.action)
                      .filter((value) => value)
                      .sort()
                  )}
                  onChange={handleFilterChange('action')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.textField}
                      label="Action"
                    />
                  )}
                />
                <TextField
                  className={classes.textField}
                  value={filter.searchText || ''}
                  onChange={(e) => updateFilter({ searchText: e.target.value })}
                  label="Search"
                />
              </div>
            </Popover>
            <IconButton
              disabled={filteredEntries.length === 0}
              title="Download"
              onClick={handleDownloadClick}
            >
              <GetAppIcon />
            </IconButton>
          </Toolbar>
          <Table
            classes={classes}
            data={filteredEntries}
            headers={tableHeaders}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredEntries.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}
