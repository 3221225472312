import {
  mergeMap,
  map,
  catchError,
  takeUntil,
  endWith,
  switchMap,
} from 'rxjs/operators';
import { from, of, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { getHeaders } from '../apis/utilities';
import _ from 'lodash';
import {
  FETCH_TELEMATICS_BOX_POLLS,
  FETCH_TELEMATICS_BOX_POLLS_SUCCESS,
  FETCH_TELEMATICS_BOX_POLLS_FAILURE,
  FETCH_TELEMATICS_BOXES,
  FETCH_TELEMATICS_BOXES_SUCCESS,
  FETCH_TELEMATICS_BOXES_FAILURE,
  START_TELEMATICS_BOX_POLLS_STREAM,
  START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS,
  START_TELEMATICS_BOX_POLLS_STREAM_FAILURE,
  END_TELEMATICS_BOX_POLLS_STREAM,
  END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS,
  RECEIVE_TELEMATICS_BOX_POLL,
} from '../actions';
import api from '../apis';
import { log, reduceByType } from '../apis/utilities';

async function apiGet(url, params) {
  const response = await api.get(url, {
    params,
    headers: getHeaders(),
  });

  return response.data;
}

async function fetchTelematicsBoxesRequest(boxQuery, vehicleQuery) {
  const boxParams = {
    projection: {
      imei: true,
      mostRecentPoll: true,
      lastPosition: true,
      gpsValidPollCount: true,
      lastIgnitionOffTime: true,
    },
    query: boxQuery,
  };

  const vehicleParams = {
    projection: {
      identificationNumber: true,
      registrationNumber: true,
      fleetNumber: true,
      telematicsBoxImei: true,
      areas: true,
      role: true,
      disposalDate: true,
    },
    query: vehicleQuery,
  };

  const [boxes, vehicles] = await Promise.all([
    apiGet('/telematicsBoxes', boxParams),
    apiGet('/vehicles', vehicleParams),
  ]);

  log('Read', 'TelematicsBoxes');

  const vehiclesByImei = _.mapKeys(vehicles, 'telematicsBoxImei');
  const multiAssignedImeis = _(vehicles)
    .groupBy((v) => v.telematicsBoxImei)
    .pickBy((x) => x.length > 1)
    .keys()
    .value();

  const boxesWithVehicles = boxes.map((box) => {
    const { mostRecentPoll: poll } = box;
    const {
      time: mostRecentTime,
      position: lastPosition,
      ignitionOn,
      locations,
      deviceProperties,
    } = poll || {}; // sometimes there's no poll
    const { batteryVoltage = '', deviceSignalStrength: signalStrength } =
      deviceProperties || {};
    const {
      identificationNumber,
      registrationNumber,
      fleetNumber,
      areas = [],
      role,
      disposalDate,
    } = box.imei in vehiclesByImei ? vehiclesByImei[box.imei] : {};
    const isMultiAssigned = multiAssignedImeis.includes(box.imei);
    const multiAssignments = vehicles.filter(
      (v) => v.telematicsBoxImei === box.imei
    );

    return {
      ...box,
      batteryVoltage,
      signalStrength,
      mostRecentTime,
      lastPosition,
      registrationNumber,
      fleetNumber,
      identificationNumber,
      ignitionOn,
      locations,
      areas: reduceByType(areas),
      isMultiAssigned,
      multiAssignments,
      role,
      disposalDate,
    };
  });

  return _.mapKeys(boxesWithVehicles, 'imei');
}

async function fetchTelematicsBoxPollsRequest(imei, start, end) {
  const params = {
    query: {
      imei,
      time: {
        $gte: start.toISOString(),
        $lte: end.toISOString(),
      },
      // TODO date query not working
      // start: moment().subtract(1, 'm'),
      // end: moment().add(24, 'h')
    },
    projection: {
      imei: true,
      time: true,
      diagnosticCode: true,
      deviceProperties: true,
      position: true,
      driver: true,
      ignitionOn: true,
      ...Object.fromEntries(
        Object.keys(window.config.dioStates).map((key) => [key, true])
      ),
    },
  };

  const data = await apiGet('/telematicsBoxPolls', params);

  log('Read', 'TelematicsBoxPolls', { imei });

  return data.map((telematicsBoxPoll) => {
    return {
      ...telematicsBoxPoll,
      searchString: `${telematicsBoxPoll.imei}`.toLowerCase(),
    };
  });
}

export function fetchTelematicsBoxPollsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TELEMATICS_BOX_POLLS),
    mergeMap(({ payload: { imei, start, end } }) =>
      from(fetchTelematicsBoxPollsRequest(imei, start, end)).pipe(
        map((payload) => ({
          type: FETCH_TELEMATICS_BOX_POLLS_SUCCESS,
          payload: {
            imei,
            polls: payload,
          },
        })),
        catchError(({ message }) =>
          of({
            type: FETCH_TELEMATICS_BOX_POLLS_FAILURE,
            payload: {
              imei,
              message,
            },
          })
        )
      )
    )
  );
}

export function fetchTelematicsBoxesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TELEMATICS_BOXES),
    mergeMap(({ boxQuery, vehicleQuery }) =>
      from(fetchTelematicsBoxesRequest(boxQuery, vehicleQuery)).pipe(
        map((payload) => ({
          type: FETCH_TELEMATICS_BOXES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TELEMATICS_BOXES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

// internal actions just used by socket & epic
const SOCKET_SUBSCRIBED = 'SOCKET_SUBSCRIBED';
const SOCKET_POLL_RECEIVED = 'SOCKET_POLL_RECEIVED';
function getTelematicsBoxPollsObservable(imei) {
  return Observable.create((observer) => {
    const socket = new WebSocket(window.config.wsRootUrl);

    const inputParams = {
      action: 'SUBSCRIBE',
      authorization: 'Bearer ' + localStorage.getItem('access_token'),
      payload: {
        telematicsBoxes: {
          query: {
            imei: { $eq: imei },
          },
          projection: {
            imei: true,
            'mostRecentPoll.identifier': true,
            'mostRecentPoll.time': true,
            'mostRecentPoll.diagnosticCode': true,
            'mostRecentPoll.deviceProperties': true,
            'mostRecentPoll.position': true,
            'mostRecentPoll.driver': true,
            'mostRecentPoll.ignitionOn': true,
            ...Object.fromEntries(
              Object.keys(window.config.dioStates).map((key) => [
                'mostRecentPoll.' + key,
                true,
              ])
            ),
            // mostRecentPoll: true
          },
        },
      },
    };

    socket.onerror = (e) => observer.error(e);

    socket.onmessage = (poll) => {
      try {
        const data = JSON.parse(poll.data);

        if (data.action === 'ERROR') {
          observer.error({ message: data.payload });
        } else {
          observer.next({
            type: SOCKET_POLL_RECEIVED,
            payload: data.payload.telematicsBoxes[imei].mostRecentPoll,
          });
        }
      } catch (e) {
        observer.error(e);
      }
    };

    socket.onopen = () => {
      socket.send(JSON.stringify(inputParams));
    };

    return () => {
      socket.close();
    };
  });
}

export function socketTelematicsBoxPollsEpic(action$) {
  return action$.pipe(
    ofType(START_TELEMATICS_BOX_POLLS_STREAM),
    switchMap(({ payload: { imei } }) =>
      getTelematicsBoxPollsObservable(imei).pipe(
        map((message) => {
          switch (message.type) {
            case SOCKET_SUBSCRIBED:
              return { type: START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS };
            case SOCKET_POLL_RECEIVED:
              return {
                type: RECEIVE_TELEMATICS_BOX_POLL,
                payload: {
                  imei,
                  poll: message.payload,
                },
              };
            default:
              // shouldn't happen but warning if no default
              return {
                type: START_TELEMATICS_BOX_POLLS_STREAM_FAILURE,
                payload: 'Unknown message from socket',
              };
          }
        }),
        takeUntil(action$.pipe(ofType(END_TELEMATICS_BOX_POLLS_STREAM))),
        catchError(({ message: payload }) =>
          of({
            type: START_TELEMATICS_BOX_POLLS_STREAM_FAILURE,
            payload,
          })
        ),
        endWith({ type: END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS })
      )
    )
  );
}
