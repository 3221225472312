import {
  Avatar,
  Button,
  Collapse,
  Fab,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Switch,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  DirectionsCar as CarIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, Route, useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Helmet } from 'react-helmet-async';
import { FixedSizeList } from 'react-window';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import { useAuth } from '../Auth';
import { FETCH_VEHICLES } from '../../actions';
import Vehicle from './Vehicle';
import ReducedVehicle from './ReducedVehicle';
import {
  checkLuhn,
  checkVin,
  downloadCSV,
  downloadJSON,
  getVehicles,
  getVehiclesAndHeaders,
} from '../../apis/utilities';
import Container from '../Container';
import { SearchBox } from '../controls';

const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '100%',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  list: {
    overflow: 'none',
    flex: 1,
  },
  active: {
    backgroundColor: theme.palette.action.focus,
  },
  toolbar: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(1),
    },
  },
  searchBox: {
    width: '100%',
    padding: theme.spacing(1, 0, 1, 1),
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  settingsPanel: {
    padding: theme.spacing(0, 1, 1, 1),
  },
  sortAsc: {
    transform: 'rotate(0deg)',
    // marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  sortDesc: {
    transform: 'rotate(180deg)',
  },
  sortTextField: {
    paddingBottom: theme.spacing(1),
  },
  exportButton: {
    margin: theme.spacing(1, 1, 1, 0),
  },
}));

export default function VehicleList() {
  const { id, report } = useParams();
  const dispatch = useDispatch();
  const vehicles = useSelector((state) => state.vehicles.vehicles, _.isEqual);
  const [showDisposed, setShowDisposed] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState(
    useReducedResourceInformation ? 'fleetNumber' : 'registrationNumber'
  );
  const [sortDesc, setSortDesc] = useState(false);
  const [filter, setFilter] = useState('all');
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const auth = useAuth();
  const editable = auth.isAuthorised('vehicles', true);

  const sortOptions = [
    ...(useReducedResourceInformation
      ? []
      : [{ label: 'Registration', value: 'registrationNumber' }]),
    { label: 'Fleet Number', value: 'fleetNumber' },
    { label: 'Last Poll Time', value: 'lastPollTime' },
    { label: 'VIN', value: 'identificationNumber' },
  ];

  const filters = [
    { label: 'All', value: 'all' },
    { label: 'No IMEI', value: 'noImei' },
    { label: 'Invalid IMEI', value: 'invalidImei' },
    { label: 'Invalid VIN', value: 'invalidVin' },
    ...(showDisposed ? [{ label: 'Disposed', value: 'disposed' }] : []),
  ];

  useEffect(() => {
    dispatch({
      type: FETCH_VEHICLES,
    });
  }, [dispatch]);

  const list = vehicles.filter(
    (vehicle) => !vehicle.disposalDate || showDisposed
  );

  const filteredList = list
    .filter((item) => {
      switch (filter) {
        case 'all':
          return true;
        case 'disposed':
          return !!item.disposalDate;
        case 'noImei':
          return !item.telematicsBoxImei;
        case 'invalidImei':
          return item.telematicsBoxImei
            ? !checkLuhn(item.telematicsBoxImei)
            : false;
        case 'invalidVin':
          return !checkVin(item.identificationNumber);
        default:
          return true;
      }
    })
    .filter(
      (item) =>
        item.searchString.indexOf(searchText.toLowerCase()) > -1 ||
        searchText === ''
    )
    .sort(
      (a, b) =>
        (sortBy.endsWith('Time')
          ? new Date(a[sortBy] || '1900-1-1') -
            new Date(b[sortBy] || '1900-1-1')
          : (a[sortBy] || '').localeCompare(b[sortBy] || '')) *
        (sortDesc ? -1 : 1)
    );

  function Row({ data, index, style }) {
    const item = data[index];

    return (
      <ListItem
        dense
        button
        key={index}
        style={style}
        component={NavLink}
        to={`/resources/vehicles/${encodeURIComponent(
          item.identificationNumber
        )}`}
        activeClassName={classes.active}
      >
        <ListItemAvatar>
          <Avatar src={item.picture}>
            <CarIcon />
          </Avatar>
        </ListItemAvatar>
        {useReducedResourceInformation ? (
          <ListItemText
            primary={item.fleetNumber}
            secondary={
              sortBy === 'lastPollTime'
                ? item.lastPollTime
                  ? moment(item.lastPollTime).format('DD/MM/YYYY, HH:mm:ss')
                  : ''
                : item.identificationNumber
            }
          />
        ) : (
          <ListItemText
            primary={(
              item.registrationNumber || item.identificationNumber
            ).toUpperCase()}
            secondary={
              sortBy === 'lastPollTime'
                ? item.lastPollTime
                  ? moment(item.lastPollTime).format('DD/MM/YYYY, HH:mm:ss')
                  : ''
                : item.fleetNumber
            }
            className={classes.listItemText}
          />
        )}
      </ListItem>
    );
  }

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }

  function handleFilterChange(event) {
    setFilter(event.target.value);
  }

  function handleDisposedChange(event) {
    setShowDisposed(event.target.checked);

    if (filter === 'disposed') {
      setFilter('all');
    }
  }

  async function handleCsvClick() {
    const data = await getVehiclesAndHeaders();

    downloadCSV(data.vehicles, 'vehicles.csv', data.headers);
  }

  async function handleJsonClick() {
    const data = await getVehicles();

    downloadJSON(data, 'vehicles.json');
  }

  return (
    <Container title="Vehicles" showBack={!!report}>
      <div className={classes.page}>
        <Helmet>
          <title>IR3 | Vehicles</title>
        </Helmet>
        {(!isXs || !id) && (
          <div className={classes.sideBar}>
            <div className={classes.searchBar}>
              <div className={classes.toolbar}>
                <SearchBox
                  value={searchText}
                  onChange={handleSearchChange}
                  className={classes.searchBox}
                  endAdornment={
                    <InputAdornment position="start">
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.count}
                      >
                        {`${filteredList.length}/${list.length}`}
                      </Typography>
                    </InputAdornment>
                  }
                />
                <IconButton
                  title={showSettings ? 'Hide settings' : 'Show settings'}
                  onClick={handleSettingsToggle}
                >
                  <SettingsIcon color={showSettings ? 'primary' : 'inherit'} />
                </IconButton>
              </div>
              <Collapse in={showSettings} unmountOnExit>
                <div className={classes.settingsPanel}>
                  <TextField
                    className={classes.sortTextField}
                    select
                    fullWidth
                    label="Filter"
                    value={filter}
                    onChange={handleFilterChange}
                  >
                    {filters.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.sortTextField}
                    select
                    fullWidth
                    label="Sort by"
                    value={sortBy}
                    onChange={handleSortByChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            title={sortDesc ? 'Descending' : 'Ascending'}
                            className={clsx(classes.sortAsc, {
                              [classes.sortDesc]: sortDesc,
                            })}
                            onClick={handleSortToggle}
                          >
                            <ArrowUpwardIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    {sortOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showDisposed}
                          onChange={handleDisposedChange}
                        />
                      }
                      label="Show Disposed"
                    />
                  </div>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    Export
                  </Typography>
                  <div>
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      className={classes.exportButton}
                      onClick={handleCsvClick}
                    >
                      CSV
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      disableElevation
                      className={classes.exportButton}
                      onClick={handleJsonClick}
                    >
                      JSON
                    </Button>
                  </div>
                </div>
              </Collapse>
            </div>
            {filteredList.length > 0 && (
              <div className={classes.list}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      width={width}
                      height={height}
                      overscanCount={10}
                      itemData={filteredList}
                      itemCount={filteredList.length}
                      itemSize={56}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            )}
            {!report && editable && (
              <Fab
                color="secondary"
                aria-label="add"
                className={classes.fab}
                component={Link}
                to="/resources/vehicles/new"
              >
                <AddIcon />
              </Fab>
            )}
          </div>
        )}
        {(!isXs || id) && (
          <div className={classes.main}>
            <Route exact path="/resources/vehicles/:id">
              {useReducedResourceInformation ? <ReducedVehicle /> : <Vehicle />}
            </Route>
          </div>
        )}
      </div>
    </Container>
  );
}
