import { CancelToken } from 'axios';
import { mergeMap, map, catchError, takeUntil, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import moment from 'moment';
import {
  FETCH_OBJECTIVES,
  FETCH_OBJECTIVES_SUCCESS,
  FETCH_OBJECTIVES_FAILURE,
  FETCH_OBJECTIVE,
  FETCH_OBJECTIVE_SUCCESS,
  FETCH_OBJECTIVE_FAILURE,
  CREATE_OBJECTIVE,
  CREATE_OBJECTIVE_SUCCESS,
  CREATE_OBJECTIVE_FAILURE,
  UPDATE_OBJECTIVE,
  UPDATE_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE_FAILURE,
  DELETE_OBJECTIVE,
  DELETE_OBJECTIVE_SUCCESS,
  DELETE_OBJECTIVE_FAILURE,
  FETCH_OBJECTIVE_ATTENDANCES,
  FETCH_OBJECTIVE_ATTENDANCES_SUCCESS,
  FETCH_OBJECTIVE_ATTENDANCES_FAILURE,
  FETCH_OBJECTIVE_ATTENDANCES_CANCELLED,
} from '../actions';
import api from '../apis';
import { getHeaders, log } from '../apis/utilities';
import history from '../history';

let cancel;

async function fetchObjectivesRequest(type) {
  const response = await api.get('/objectives', {
    params: {
      query:
        type !== 'All'
          ? {
              type,
            }
          : undefined,
      projection: {
        identifier: true,
        title: true,
        type: true,
        startTime: true,
        endTime: true,
        areas: true,
        wards: true,
        created: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'Objectives', { type });

  return response.data;
}

export function fetchObjectivesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_OBJECTIVES),
    mergeMap(({ payload: type }) =>
      from(fetchObjectivesRequest(type)).pipe(
        map((payload) => ({
          type: FETCH_OBJECTIVES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_OBJECTIVES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchObjectiveRequest(id) {
  const response = await api.get(`/objectives/${id}`, {
    params: {
      projection: {
        identifier: true,
        title: true,
        type: true,
        description: true,
        startTime: true,
        endTime: true,
        days: true,
        hours: true,
        complianceSeconds: true,
        requiredVisits: true,
        requiredFrequency: true,
        areas: true,
        briefs: true,
        boundaryType: true,
        boundarySubtype: true,
        boundaryIdentifier: true,
        boundary: true,
        compliantPersonAttendanceCount: true,
        nonCompliantPersonAttendanceCount: true,
        compliantPeople: true,
        created: true,
        lastEdit: true,
        occurrenceNumber: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'Objective', { id });

  return response.data;
}

export function fetchObjectiveEpic(action$) {
  return action$.pipe(
    ofType(FETCH_OBJECTIVE),
    mergeMap(({ payload: id }) =>
      from(fetchObjectiveRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_OBJECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_OBJECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function createObjectiveRequest(values) {
  // const wards = await getObjectiveWards(values);
  // const objective = {
  //   ...values,
  //   created: { userId: localStorage.getItem('username'), time: new Date() },
  //   wards
  // };

  const response = await api.post(`/objectives`, values, {
    headers: getHeaders(),
  });

  history.replace(response.data.identifier);

  return response.data;
}

export function createObjectiveEpic(action$) {
  return action$.pipe(
    ofType(CREATE_OBJECTIVE),
    mergeMap(({ payload: values }) =>
      from(createObjectiveRequest(values)).pipe(
        map((payload) => ({
          type: CREATE_OBJECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_OBJECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateObjectiveRequest(values) {
  // const wards = await getObjectiveWards(values);
  const objective = {
    ...values,
    // wards,
  };

  await api.patch(`/objectives/${objective.identifier}`, objective, {
    headers: {
      ...getHeaders(),
      'Content-Type': 'application/merge-patch+json',
    },
  });

  return objective;
}

export function updateObjectiveEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_OBJECTIVE),
    mergeMap(({ payload: values }) =>
      from(updateObjectiveRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_OBJECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_OBJECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function deleteObjectiveRequest(id) {
  await api.delete(`/objectives/${id}`, {
    headers: getHeaders(),
  });

  history.push('.');

  return id;
}

export function deleteObjectiveEpic(action$) {
  return action$.pipe(
    ofType(DELETE_OBJECTIVE),
    mergeMap(({ payload: id }) =>
      from(deleteObjectiveRequest(id)).pipe(
        map((payload) => ({
          type: DELETE_OBJECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_OBJECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

function getGroupedAttendances(attendances, type) {
  switch (type) {
    case 'Patrol':
      return Object.values(
        attendances.reduce((accumulator, currentValue) => {
          if (!(currentValue.code in accumulator)) {
            accumulator[currentValue.code] = {
              code: currentValue.code,
              name: currentValue.name,
              collarNumber: currentValue.collarNumber,
              role: currentValue.role,
              compliantAttendanceCount: 0,
              compliantAttendanceMinutes: 0.0,
              nonCompliantAttendanceCount: 0,
              nonCompliantAttendanceMinutes: 0.0,
              visits: [],
            };
          }

          accumulator[currentValue.code].visits.push({
            identifier: currentValue.identifier,
            startTime: currentValue.startTime,
            endTime: currentValue.endTime,
            isCompliant: currentValue.isCompliant,
            durationMinutes: currentValue.durationMinutes,
          });

          if (currentValue.isCompliant) {
            accumulator[currentValue.code].compliantAttendanceCount += 1;
            accumulator[currentValue.code].compliantAttendanceMinutes +=
              currentValue.durationMinutes;
          } else {
            accumulator[currentValue.code].nonCompliantAttendanceCount += 1;
            accumulator[currentValue.code].nonCompliantAttendanceMinutes +=
              currentValue.durationMinutes;
          }

          return accumulator;
        }, {})
      );
    case 'Visit':
      return Object.values(
        attendances.reduce((accumulator, currentValue) => {
          if (!(currentValue.group in accumulator)) {
            accumulator[currentValue.group] = {
              group:
                currentValue.group === 'All'
                  ? 'All'
                  : moment(currentValue.group).toDate(),
              attendanceCount: 0,
              attendanceMinutes: 0.0,
              visits: [],
            };
          }

          accumulator[currentValue.group].visits.push({
            identifier: currentValue.identifier,
            code: currentValue.code,
            name: currentValue.name,
            collarNumber: currentValue.collarNumber,
            role: currentValue.role,
            startTime: currentValue.startTime,
            endTime: currentValue.endTime,
            durationMinutes: currentValue.durationMinutes,
          });

          accumulator[currentValue.group].attendanceCount += 1;
          accumulator[currentValue.group].attendanceMinutes +=
            currentValue.durationMinutes;

          return accumulator;
        }, {})
      );
    default:
      return [];
  }
}

async function fetchObjectiveAttendancesRequest(
  id,
  type,
  timeGroup,
  startTime,
  endTime
) {
  const response = await api.get(`/personObjectiveAttendances`, {
    params: {
      query: {
        'objective.identifier': id,
        startTime: { $lt: endTime },
        endTime: { $gt: startTime },
      },
      projection: {
        identifier: true,
        objective: true,
        person: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        compliant: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const mappedAttendances = (response.data || []).map((attendance) => {
    return {
      identifier: attendance.identifier,
      code: attendance.person.code,
      name: `${attendance.person.forenames} ${attendance.person.surname}`,
      collarNumber: attendance.person.collarNumber,
      role: attendance.person.role,
      startTime: attendance.startTime,
      endTime: attendance.endTime,
      isCompliant: attendance.compliant,
      durationMinutes: attendance.durationSeconds / 60.0,
      group: timeGroup
        ? moment(attendance.startTime).local().startOf(timeGroup).toString()
        : 'All',
    };
  });

  const attendances =
    mappedAttendances.length === 0
      ? []
      : getGroupedAttendances(mappedAttendances, type);

  log('Read', 'Objective Attendances', { id, startTime, endTime });

  return { id, attendances };
}

export function fetchObjectiveAttendancesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_OBJECTIVE_ATTENDANCES),
    mergeMap(({ payload: { id, type, timeGroup, startTime, endTime } }) =>
      from(
        fetchObjectiveAttendancesRequest(
          id,
          type,
          timeGroup,
          startTime,
          endTime
        )
      ).pipe(
        map((payload) => ({
          type: FETCH_OBJECTIVE_ATTENDANCES_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_OBJECTIVE_ATTENDANCES_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_OBJECTIVE_ATTENDANCES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
