import { IconButton, withStyles } from '@material-ui/core';
import {
  Cancel as CancelIcon,
  Create as CreateIcon,
  Label as LabelIcon,
  LabelOff as LabelOffIcon,
  Map as MapIcon,
  Satellite as SatelliteIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import { Feature, Map, View } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { applyTransform, extend } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import {
  defaults as defaultInteractions,
  DoubleClickZoom as DoubleClickZoomInteraction,
  Draw as DrawInteraction,
  Modify as ModifyInteraction,
  Select as SelectInteraction,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { getTransform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  UPDATE_HOVER_FEATURE_INDEX,
  UPDATE_SELECTED_FEATURE,
  UPDATE_SELECTED_FEATURE_GEOMETRY,
  UPDATE_SELECTED_FEATURE_INDEX,
} from '../../actions';
import { boundaryStyle, getStyle, setBaseLayers } from '../../mapStyles';

const { mapExtent, layerSwitcher } = window.config;

const styles = {
  map: {
    width: '100%',
    height: '100%',
  },
  zoomInButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    zIndex: 100,
  },
  zoomOutButton: {
    position: 'absolute',
    top: 40,
    left: 5,
    zIndex: 100,
  },
  fitButton: {
    position: 'absolute',
    top: 75,
    left: 5,
    zIndex: 100,
  },
  labelButton: {
    position: 'absolute',
    top: 110,
    left: 5,
    zIndex: 100,
  },
  drawButton: {
    position: 'absolute',
    top: 145,
    left: 5,
    zIndex: 100,
  },
  layerButton: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    zIndex: 100,
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    color: '#fff',
  },
};

const geoJsonTypes = {
  Perimeter: 'Polygon',
  Path: 'LineString',
  Marker: 'Point',
};

class PlanMap extends Component {
  state = {
    zoomInDisabled: false,
    zoomOutDisabled: false,
    fitDisabled: false,
    drawActive: false,
    satelliteMapVisible: false,
    showLabels: false,
    previousGeometry: null,
    featureCollectionChanged: false,
  };

  contentsSource = null;
  boundarySource = null;
  hoverSource = null;
  map = null;
  draw = null;
  select = null;
  modify = null;
  doubleClickZoom = null;

  selectedFeature = null;

  getDefaultStyle = (feature) => {
    return getStyle('default', feature, this.state.showLabels);
  };

  getSelectStyle = (feature) => {
    return getStyle('select', feature, this.state.showLabels);
  };

  getHoverStyle = (feature) => {
    return getStyle('hover', feature, this.state.showLabels);
  };

  fitMapToContents = () => {
    const sources = [this.contentsSource, this.boundarySource];
    let ex = null;

    sources.forEach((source) => {
      if (ex) {
        ex = extend(ex, source.getExtent());
      } else {
        ex = source.getExtent();
      }
    });

    if (
      ex[0] === Infinity ||
      ex[1] === Infinity ||
      ex[2] === -Infinity ||
      ex[3] === -Infinity
    ) {
      this.map
        .getView()
        .fit(applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857')));
      if (
        this.state.zoomInDisabled !==
          (this.map.getView().getZoom() === this.map.getView().getMaxZoom()) ||
        this.state.zoomOutDisabled !==
          (this.map.getView().getZoom() === this.map.getView().getMinZoom())
      ) {
        this.setState({
          zoomInDisabled:
            this.map.getView().getZoom() === this.map.getView().getMaxZoom(),
          zoomOutDisabled:
            this.map.getView().getZoom() === this.map.getView().getMinZoom(),
        });
      }
    } else {
      this.map.getView().fit(ex, { maxZoom: 18 });
      if (
        this.state.zoomInDisabled !==
          (this.map.getView().getZoom() === this.map.getView().getMaxZoom()) ||
        this.state.zoomOutDisabled !==
          (this.map.getView().getZoom() === this.map.getView().getMinZoom())
      ) {
        this.setState({
          zoomInDisabled:
            this.map.getView().getZoom() === this.map.getView().getMaxZoom(),
          zoomOutDisabled:
            this.map.getView().getZoom() === this.map.getView().getMinZoom(),
        });
      }
    }
  };

  fitMapToSelected = () => {
    if (this.props.feature.geometry) {
      this.map.getView().fit(
        new GeoJSON()
          .readGeometry(this.props.feature.geometry, {
            featureProjection: 'EPSG:3857',
          })
          .getExtent(),
        { maxZoom: 18 }
      );
    }
  };

  handleMapToggleClick = () => {
    if (this.map) {
      this.map
        .getLayers()
        .item(this.state.satelliteMapVisible ? 0 : 1)
        .setVisible(true);
      this.map
        .getLayers()
        .item(this.state.satelliteMapVisible ? 1 : 0)
        .setVisible(false);
      this.setState({
        satelliteMapVisible: !this.state.satelliteMapVisible,
      });
    }
  };

  handleLabelsToggleClick = () => {
    this.setState({
      showLabels: !this.state.showLabels,
    });
    this.contentsSource.changed();
  };

  handleZoomInClick = () => {
    if (this.map) {
      this.map.getView().setZoom(this.map.getView().getZoom() + 1);
      this.setState({
        zoomInDisabled:
          this.map.getView().getZoom() === this.map.getView().getMaxZoom(),
        zoomOutDisabled:
          this.map.getView().getZoom() === this.map.getView().getMinZoom(),
      });
    }
  };

  handleZoomOutClick = () => {
    if (this.map) {
      this.map.getView().setZoom(this.map.getView().getZoom() - 1);
      this.setState({
        zoomInDisabled:
          this.map.getView().getZoom() === this.map.getView().getMaxZoom(),
        zoomOutDisabled:
          this.map.getView().getZoom() === this.map.getView().getMinZoom(),
      });
    }
  };

  handleDrawClick = () => {
    this.select.getFeatures().clear();

    if (this.state.drawActive) {
      this.map.addInteraction(this.select);
      this.map.addInteraction(this.doubleClickZoom);
      this.map.removeInteraction(this.draw);

      this.props.dispatch({
        type: UPDATE_SELECTED_FEATURE_GEOMETRY,
        payload: this.state.previousGeometry,
      });

      this.setState({
        drawActive: false,
        previousGeometry: null,
      });
    } else {
      const { geometry, ...feature } = this.props.feature;
      this.props.dispatch({
        type: UPDATE_SELECTED_FEATURE,
        payload: feature,
      });
      this.setState({
        drawActive: true,
        previousGeometry: geometry,
      });

      this.map.removeInteraction(this.select);
      this.map.removeInteraction(this.doubleClickZoom);

      this.draw = new DrawInteraction({
        type: geoJsonTypes[this.props.feature.properties.type],
      });
      this.draw.on('drawend', (event) => {
        this.map.removeInteraction(this.draw);

        const geo = new GeoJSON().writeGeometryObject(
          event.feature.getGeometry(),
          { featureProjection: 'EPSG:3857', rightHanded: true }
        );
        this.props.dispatch({
          type: UPDATE_SELECTED_FEATURE_GEOMETRY,
          payload: geo,
        });

        setTimeout(() => {
          this.setState({
            drawActive: false,
          });

          this.map.addInteraction(this.select);
          this.map.addInteraction(this.doubleClickZoom);
        }, 0);
      });
      this.map.addInteraction(this.draw);
    }
  };

  componentDidMount() {
    this.boundarySource = new VectorSource({
      features: this.props.collection
        ? [
            new Feature(
              new GeoJSON().readGeometry(this.props.collection.boundary, {
                featureProjection: 'EPSG:3857',
              })
            ),
          ]
        : [],
    });
    const boundaryLayer = new VectorLayer({
      source: this.boundarySource,
      style: boundaryStyle,
    });

    this.contentsSource = this.props.featureCollection
      ? new VectorSource({
          features: new GeoJSON().readFeatures(
            {
              ...this.props.featureCollection,
              features: this.props.featureCollection.features.filter(
                (feature) =>
                  'geometry' in feature &&
                  feature.properties.collections.includes(
                    this.props.collection
                      ? this.props.collection.identifier
                      : ''
                  )
              ),
            },
            { featureProjection: 'EPSG:3857' }
          ),
        })
      : new VectorSource({ features: [] });
    const contentsLayer = new VectorLayer({
      source: this.contentsSource,
      style: this.getDefaultStyle,
    });

    this.select = new SelectInteraction({
      layers: [contentsLayer],
      style: this.getSelectStyle,
    });
    this.select.on('select', (event) => {
      if (event.selected.length > 0) {
        if (
          !this.props.feature ||
          (this.props.feature.geometry &&
            this.props.feature.properties.title &&
            this.props.feature.properties.identifier &&
            this.props.feature.properties.subtype)
        ) {
          this.selectedFeature = new GeoJSON().writeFeatureObject(
            event.selected[0],
            { featureProjection: 'EPSG:3857', rightHanded: true }
          );
          this.props.dispatch({
            type: UPDATE_SELECTED_FEATURE_INDEX,
            payload: this.selectedFeature.id,
          });
          this.hoverSource.clear();
          this.props.dispatch({
            type: UPDATE_HOVER_FEATURE_INDEX,
            payload: null,
          });
        } else {
          this.select.getFeatures().clear();
        }
      }
    });

    this.modify = new ModifyInteraction({
      features: this.select.getFeatures(),
    });
    this.modify.on('modifyend', (event) => {
      const selectedFeature = new GeoJSON().writeFeatureObject(
        event.features.getArray()[0],
        { featureProjection: 'EPSG:3857', rightHanded: true }
      );
      this.props.dispatch({
        type: UPDATE_SELECTED_FEATURE,
        payload: selectedFeature,
      });
    });

    this.doubleClickZoom = new DoubleClickZoomInteraction();

    this.map = new Map({
      target: this.refs.map,
      layers: [boundaryLayer, contentsLayer],
      interactions: defaultInteractions({
        doubleClickZoom: false,
        pinchRotate: false,
        altShiftDragRotate: false,
      }).extend([this.doubleClickZoom, this.select, this.modify]),
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
      controls: defaultControls({
        attribution: false,
        rotate: false,
        zoom: false,
      }),
    });
    this.map.on('click', (event) => {
      if (!this.state.drawActive) {
        if (
          !this.props.feature ||
          (this.props.feature.geometry &&
            this.props.feature.properties.title &&
            this.props.feature.properties.identifier &&
            this.props.feature.properties.subtype)
        ) {
          this.props.dispatch({
            type: UPDATE_SELECTED_FEATURE_INDEX,
            payload: null,
          });
        }
      }
    });

    setBaseLayers(this.map);

    this.hoverSource = new VectorSource();
    new VectorLayer({
      source: this.hoverSource,
      map: this.map,
      style: this.getHoverStyle,
    });

    this.map.on('pointermove', (event) => {
      if (!event.dragging && !this.state.drawActive && !this.props.feature) {
        const pixel = this.map.getEventPixel(event.originalEvent);
        const feature = this.map.forEachFeatureAtPixel(
          pixel,
          (feature) => {
            return feature;
          },
          {
            layerFilter: (layer) => {
              return layer === contentsLayer;
            },
          }
        );

        if (feature) {
          if (feature.getId() !== this.props.hoverFeatureIndex) {
            this.hoverSource.clear();
            this.hoverSource.addFeature(feature);
            this.props.dispatch({
              type: UPDATE_HOVER_FEATURE_INDEX,
              payload: feature.getId(),
            });
          }
        } else {
          this.hoverSource.clear();
          this.props.dispatch({
            type: UPDATE_HOVER_FEATURE_INDEX,
            payload: null,
          });
        }
      }
    });

    this.fitMapToContents();
  }

  componentDidUpdate(prevProps) {
    if (this.props.featureCollection) {
      if (
        _.isEqual(prevProps.featureCollection, this.props.featureCollection)
      ) {
        //nothing has changed so do nothing
      } else {
        this.setState({ featureCollectionChanged: true });
      }
    }

    if (this.props.collection) {
      if (_.isEqual(prevProps.collection, this.props.collection)) {
        //nothing has changed so do nothing
      } else {
        this.boundarySource.clear({ fast: true });
        this.boundarySource.addFeature(
          new Feature(
            new GeoJSON().readGeometry(this.props.collection.boundary, {
              featureProjection: 'EPSG:3857',
            })
          )
        );

        this.fitMapToContents();
      }

      if (this.state.featureCollectionChanged) {
        this.contentsSource.clear({ fast: true });
        const features = this.props.featureCollection
          ? new GeoJSON().readFeatures(
              {
                ...this.props.featureCollection,
                features: this.props.featureCollection.features.filter(
                  (feature) =>
                    'geometry' in feature &&
                    feature.properties.collections.includes(
                      this.props.collection
                        ? this.props.collection.identifier
                        : ''
                    )
                ),
              },
              { featureProjection: 'EPSG:3857' }
            )
          : [];
        this.contentsSource.addFeatures(features);

        if (
          this.props.featureCollection.features.filter(
            (feature) =>
              'geometry' in feature &&
              feature.properties.collections.includes(
                this.props.collection ? this.props.collection.identifier : ''
              )
          ).length ===
          (prevProps.featureCollection
            ? prevProps.featureCollection.features.filter(
                (feature) =>
                  'geometry' in feature &&
                  feature.properties.collections.includes(
                    prevProps.collection ? prevProps.collection.identifier : ''
                  )
              ).length
            : -1)
        ) {
          //same collection so don't resize
        } else {
          this.fitMapToContents();
        }
      }
    }

    if (this.props.feature) {
      if (this.props.feature.geometry) {
        if (_.isEqual(prevProps.feature, this.props.feature)) {
          //nothing has changed so do nothing
        } else {
          const selectedFeature =
            this.select.getFeatures().length > 0
              ? new GeoJSON().writeFeatureObject(this.select.getFeatures()[0], {
                  featureProjection: 'EPSG:3857',
                  rightHanded: true,
                })
              : null;
          if (_.isEqual(this.props.feature, selectedFeature)) {
            //nothing has changed so do nothing
          } else {
            this.select.getFeatures().clear();
            this.select
              .getFeatures()
              .push(this.contentsSource.getFeatureById(this.props.feature.id));
            this.hoverSource.clear();
            this.props.dispatch({
              type: UPDATE_HOVER_FEATURE_INDEX,
              payload: null,
            });

            this.contentsSource.getFeatureById(this.props.feature.id).changed();
          }
        }
      } else {
        //feature without a geometry is selected
      }
    } else {
      this.select.getFeatures().clear();
    }

    if (!this.state.drawActive && !prevProps.feature) {
      if (Number.isInteger(this.props.hoverFeatureIndex)) {
        if (prevProps.hoverFeatureIndex !== this.props.hoverFeatureIndex) {
          const feature = this.contentsSource.getFeatureById(
            this.props.hoverFeatureIndex
          );
          this.hoverSource.clear();
          this.hoverSource.addFeature(feature);
        }
      } else {
        this.hoverSource.clear();
        this.props.dispatch({
          type: UPDATE_HOVER_FEATURE_INDEX,
          payload: null,
        });
      }
    }
  }

  render() {
    return (
      <div className={this.props.classes.container}>
        <div id="map" className={this.props.classes.map} ref="map" />
        <IconButton
          title="Zoom In"
          className={this.props.classes.zoomInButton}
          aria-label="Zoom In"
          color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
          disabled={this.state.zoomInDisabled}
          onClick={this.handleZoomInClick}
        >
          <ZoomInIcon />
        </IconButton>
        <IconButton
          title="Zoom Out"
          className={this.props.classes.zoomOutButton}
          aria-label="Zoom Out"
          color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
          disabled={this.state.zoomOutDisabled}
          onClick={this.handleZoomOutClick}
        >
          <ZoomOutIcon />
        </IconButton>
        <IconButton
          title="Fit"
          className={this.props.classes.fitButton}
          aria-label="Fit"
          color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
          disabled={this.state.fitDisabled}
          onClick={
            this.props.feature ? this.fitMapToSelected : this.fitMapToContents
          }
        >
          <ZoomOutMapIcon />
        </IconButton>
        <IconButton
          title={this.state.showLabels ? 'Hide Labels' : 'Show Labels'}
          className={this.props.classes.labelButton}
          aria-label="Toggle Labels"
          color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
          onClick={this.handleLabelsToggleClick}
        >
          {this.state.showLabels ? <LabelOffIcon /> : <LabelIcon />}
        </IconButton>
        {this.props.feature && (
          <IconButton
            title={this.state.drawActive ? 'Cancel' : 'Draw'}
            className={this.props.classes.drawButton}
            aria-label="Draw"
            color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
            onClick={this.handleDrawClick}
          >
            {this.state.drawActive ? <CancelIcon /> : <CreateIcon />}
          </IconButton>
        )}
        {layerSwitcher && (
          <IconButton
            title={
              this.state.satelliteMapVisible ? 'Road Map' : 'Satellite Map'
            }
            className={this.props.classes.layerButton}
            aria-label="Map"
            color={this.state.satelliteMapVisible ? 'inherit' : 'default'}
            onClick={this.handleMapToggleClick}
          >
            {this.state.satelliteMapVisible ? <MapIcon /> : <SatelliteIcon />}
          </IconButton>
        )}
      </div>
    );
  }
}

PlanMap.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    collection: state.collections.collection,
    featureCollection: state.features.featureCollection,
    feature:
      state.features.featureCollection &&
      Number.isInteger(state.features.selectedFeatureIndex)
        ? state.features.featureCollection.features[
            state.features.selectedFeatureIndex
          ]
        : null,
    hoverFeatureIndex: state.features.hoverFeatureIndex,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(PlanMap));
