import { CancelToken } from 'axios';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { quantileRankSorted } from 'simple-statistics';
import {
  CREATE_RETROSPECTIVE,
  CREATE_RETROSPECTIVE_FAILURE,
  CREATE_RETROSPECTIVE_SUCCESS,
  DELETE_RETROSPECTIVE,
  DELETE_RETROSPECTIVE_FAILURE,
  DELETE_RETROSPECTIVE_SUCCESS,
  FETCH_RETROSPECTIVE,
  FETCH_RETROSPECTIVES,
  FETCH_RETROSPECTIVES_FAILURE,
  FETCH_RETROSPECTIVES_SUCCESS,
  FETCH_RETROSPECTIVE_FAILURE,
  FETCH_RETROSPECTIVE_ITEM,
  FETCH_RETROSPECTIVE_ITEM_FAILURE,
  FETCH_RETROSPECTIVE_ITEM_SUCCESS,
  FETCH_RETROSPECTIVE_LAYER,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS,
  FETCH_RETROSPECTIVE_LAYER_CANCELLED,
  FETCH_RETROSPECTIVE_LAYER_FAILURE,
  FETCH_RETROSPECTIVE_LAYER_SUCCESS,
  FETCH_RETROSPECTIVE_SUCCESS,
  PUSH_RETROSPECTIVE_FORM,
  SYNC_RETROSPECTIVE_FORM,
  UPDATE_RETROSPECTIVE,
  UPDATE_RETROSPECTIVE_FAILURE,
  UPDATE_RETROSPECTIVE_SUCCESS,
} from '../actions';
import api from '../apis';
import { getHeaders, log } from '../apis/utilities';
import history from '../history';

const { dioStates } = window.config;

const cancels = {};

async function fetchRetrospectivesRequest(isAudit) {
  const response = await api.get('/retrospectives', {
    params: {
      query: isAudit
        ? undefined
        : {
            'created.userId': localStorage.getItem('username'),
          },
      projection: {
        identifier: true,
        title: true,
        created: true,
        lastEdit: true,
      },
    },
    headers: getHeaders(),
  });

  return response.data;
}

export function fetchRetrospectivesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVES),
    mergeMap(({ payload: isAudit }) =>
      from(fetchRetrospectivesRequest(isAudit)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchBoundary(type, identifier) {
  switch (type) {
    case 'Location': {
      const response = await api.get(`/locations/${identifier}`, {
        params: {
          projection: {
            boundary: true,
          },
        },
        headers: getHeaders(),
      });

      return response.data.boundary;
    }
    case 'Perimeter': {
      const response = await api.get(`/features/${identifier}`, {
        params: {
          projection: {
            geometry: true,
          },
        },
        headers: getHeaders(),
      });

      return response.data.geometry;
    }
    case 'Objective': {
      let response = await api.get(`/objectives/${identifier}`, {
        params: {
          projection: {
            identifier: true,
            boundaryType: true,
            boundaryIdentifier: true,
            boundary: true,
          },
        },
        headers: getHeaders(),
      });

      if (response.data.boundaryType === 'Custom') {
        return response.data.boundary;
      } else if (response.data.boundaryType === 'Location') {
        response = await api.get(
          `/locations/${response.data.boundaryIdentifier}`,
          {
            params: {
              projection: {
                boundary: true,
              },
            },
            headers: getHeaders(),
          }
        );

        return response.data.boundary;
      } else {
        response = await api.get(
          `/features/${response.data.boundaryIdentifier}`,
          {
            params: {
              projection: {
                geometry: true,
              },
            },
            headers: getHeaders(),
          }
        );

        return response.data.geometry;
      }
    }
    default:
      return undefined;
  }
}

async function fetchRetrospectiveRequest(id) {
  const response = await api.get(`/retrospectives/${id}`, {
    params: {
      projection: {
        identifier: true,
        title: true,
        description: true,
        layers: true,
        created: true,
        lastEdit: true,
      },
    },
    headers: getHeaders(),
  });

  const layers = await Promise.all(
    response.data.layers.map(async (layer) => {
      const boundaryGeometry = await fetchBoundary(
        layer.boundaryType,
        layer.boundaryIdentifier
      );

      return { boundaryGeometry, ...layer };
    })
  );

  log('Read', 'Retrospective', { id });

  return { ...response.data, layers };
}

export function fetchRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE),
    mergeMap(({ payload: id }) =>
      from(fetchRetrospectiveRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function createRetrospectiveRequest({ layers, ...values }) {
  const retrospective = {
    ...values,
    layers: layers.map(({ featureCollection, ...layer }) => layer),
  };

  const response = await api.post('/retrospectives', retrospective, {
    headers: getHeaders(),
  });

  history.replace(`/retrospective/${response.data.identifier}`);

  return { ...retrospective, layers, identifier: response.data.identifier };
}

export function createRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(CREATE_RETROSPECTIVE),
    mergeMap(({ payload: values }) =>
      from(createRetrospectiveRequest(values)).pipe(
        map((payload) => ({
          type: CREATE_RETROSPECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_RETROSPECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateRetrospectiveRequest({ layers, ...values }) {
  const retrospective = {
    ...values,
    layers: layers.map(({ featureCollection, boundaryGeometry, ...layer }) =>
      layer.boundaryType === 'Custom' ? { boundaryGeometry, ...layer } : layer
    ),
  };
  await api.patch(
    `/retrospectives/${retrospective.identifier}`,
    retrospective,
    {
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/merge-patch+json',
      },
    }
  );

  return { ...retrospective, layers };
}

export function updateRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_RETROSPECTIVE),
    mergeMap(({ payload: values }) =>
      from(updateRetrospectiveRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_RETROSPECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_RETROSPECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function deleteRetrospectiveRequest(id) {
  await api.delete(`/retrospectives/${id}`, {
    headers: getHeaders(),
  });

  return id;
}

export function deleteRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(DELETE_RETROSPECTIVE),
    mergeMap(({ payload: id }) =>
      from(deleteRetrospectiveRequest(id)).pipe(
        map((payload) => ({
          type: DELETE_RETROSPECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_RETROSPECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchLayerData(index, layer, filters) {
  // const startTime = moment(layer.startTime)
  //   .utc()
  //   .startOf('day')
  //   .toDate();
  // const endTime = moment(layer.endTime)
  //   .utc()
  //   .startOf('day')
  //   .toDate();

  switch (layer.source) {
    case 'vehicleTrips': {
      const response = await api.get('/trips', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            path: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            identifier: true,
            vehicle: true,
            driver: true,
            startTime: true,
            endTime: true,
            path: true,
            distanceKilometres: true,
            maxSpeedKilometresPerHour: true,
            durationSeconds: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ path: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'vehicleStops': {
      const response = await api.get('/stops', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            point: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            identifier: true,
            vehicle: true,
            startTime: true,
            endTime: true,
            point: true,
            durationSeconds: true,
            lastDriver: true
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ point: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'vehicleStopCount': {
      const response = await api.get('/stops', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            'locations.type': layer.areaType,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: { locations: true },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      const data = response.data.reduce((accumulator, row) => {
        for (let location of row.locations) {
          if (location.type === layer.areaType) {
            if (accumulator[location.code]) {
              accumulator[location.code]++;
            } else {
              accumulator[location.code] = 1;
            }
          }
        }

        return accumulator;
      }, {});

      return data;
    }
    case 'vehicleIdles': {
      const response = await api.get('/idles', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            point: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            identifier: true,
            vehicle: true,
            driver: true,
            startTime: true,
            endTime: true,
            point: true,
            durationSeconds: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ point: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'vehicleIdleCount': {
      const response = await api.get('/idles', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            'locations.type': layer.areaType,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: { locations: true },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      const data = response.data.reduce((accumulator, row) => {
        for (let location of row.locations) {
          if (location.type === layer.areaType) {
            if (accumulator[location.code]) {
              accumulator[location.code]++;
            } else {
              accumulator[location.code] = 1;
            }
          }
        }

        return accumulator;
      }, {});

      return data;
    }
    case 'vehiclePolls': {
      const response = await api.get('/vehiclePolls', {
        params: {
          query: {
            time: { $gte: layer.startTime, $lt: layer.endTime },
            position: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            identifier: true,
            imei: true,
            identificationNumber: true,
            time: true,
            position: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      const vehiclesResponse = await api.get('/vehicles', {
        params: {
          projection: {
            identificationNumber: true,
            fleetNumber: true,
            registrationNumber: true,
            role: true,
            homeStation: true,
            areas: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      const vehicleLookup = new Map(
        vehiclesResponse.data.map((vehicle) => [
          vehicle.identificationNumber,
          vehicle,
        ])
      );

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          (
            {
              position: geometry,
              identifier: id,
              identificationNumber,
              ...properties
            },
            index
          ) => {
            return {
              type: 'Feature',
              id: index,
              properties: {
                ...properties,
                id,
                source: layer.source,
                vehicle: vehicleLookup.get(identificationNumber),
              },
              geometry,
            };
          }
        ),
      };
    }
    case 'vehicleVisits': {
      const response = await api.get('/intersections', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            path: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            identifier: true,
            vehicle: true,
            driver: true,
            startTime: true,
            endTime: true,
            path: true,
            location: true,
            distanceKilometres: true,
            durationSeconds: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ path: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'vehicleTime': {
      const response = await api.get('/intersections', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            'location.type': layer.areaType,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            identifier: true,
            location: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      const data = response.data.reduce((accumulator, row) => {
        if (accumulator[row.location.code]) {
          // accumulator[location.code].add(
          //   moment.duration(
          //     moment
          //       .min(moment(row.endTime), moment(layer.endTime))
          //       .diff(
          //         moment.max(moment(row.startTime), moment(layer.startTime))
          //       )
          //   )
          // );
          accumulator[row.location.code] +=
            Math.min(new Date(row.endTime), new Date(layer.endTime)) -
            Math.max(new Date(row.startTime), new Date(layer.startTime));
        } else {
          // accumulator[location.code] = moment.duration(
          //   moment
          //     .min(moment(row.endTime), moment(layer.endTime))
          //     .diff(
          //       moment.max(moment(row.startTime), moment(layer.startTime))
          //     )
          // );
          accumulator[row.location.code] =
            Math.min(new Date(row.endTime), new Date(layer.endTime)) -
            Math.max(new Date(row.startTime), new Date(layer.startTime));
        }
        return accumulator;
      }, {});

      return data;
    }
    case 'vehicleVisitCount': {
      const response = await api.get('/intersections', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            'location.type': layer.areaType,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: { location: true },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      const data = response.data.reduce((accumulator, { location }) => {
        if (location.type === layer.areaType) {
          if (accumulator[location.code]) {
            accumulator[location.code]++;
          } else {
            accumulator[location.code] = 1;
          }
        }

        return accumulator;
      }, {});

      return data;
    }
    case 'incidents': {
      const response = await api.get('/incidents', {
        params: {
          query: {
            openedTime: { $gte: layer.startTime, $lt: layer.endTime },
            point: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            number: true,
            description: true,
            type: true,
            category: true,
            responseCategory: true,
            status: true,
            grade: true,
            point: true,
            openedTime: true,
            closingCodes: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ point: geometry, number: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'incidentCount': {
      const response = await api.get('/stops', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            'locations.type': layer.areaType,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: { locations: true },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      const data = response.data.reduce((accumulator, row) => {
        for (let location of row.locations) {
          if (location.type === layer.areaType) {
            if (accumulator[location.code]) {
              accumulator[location.code]++;
            } else {
              accumulator[location.code] = 1;
            }
          }
        }

        return accumulator;
      }, {});

      return data;
    }
    case 'personTrails': {
      const response = await api.get('/personTrails', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            path: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            identifier: true,
            person: true,
            startTime: true,
            endTime: true,
            path: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ path: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'personVisits': {
      const response = await api.get('/personLocationIntersections', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            path: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            identifier: true,
            person: true,
            startTime: true,
            endTime: true,
            location: true,
            path: true,
            durationSeconds: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ path: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'personTime': {
      const response = await api.get('/personLocationIntersections', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            'location.type': layer.areaType,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            identifier: true,
            location: true,
            startTime: true,
            endTime: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      const data = response.data.reduce((accumulator, row) => {
        if (accumulator[row.location.code]) {
          // accumulator[location.code].add(
          //   moment.duration(
          //     moment
          //       .min(moment(row.endTime), moment(layer.endTime))
          //       .diff(
          //         moment.max(moment(row.startTime), moment(layer.startTime))
          //       )
          //   )
          // );
          accumulator[row.location.code] +=
            Math.min(new Date(row.endTime), new Date(layer.endTime)) -
            Math.max(new Date(row.startTime), new Date(layer.startTime));
        } else {
          // accumulator[location.code] = moment.duration(
          //   moment
          //     .min(moment(row.endTime), moment(layer.endTime))
          //     .diff(
          //       moment.max(moment(row.startTime), moment(layer.startTime))
          //     )
          // );
          accumulator[row.location.code] =
            Math.min(new Date(row.endTime), new Date(layer.endTime)) -
            Math.max(new Date(row.startTime), new Date(layer.startTime));
        }
        return accumulator;
      }, {});

      return data;
    }
    case 'personVisitCount': {
      const response = await api.get('/personLocationIntersections', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            'location.type': layer.areaType,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: { location: true },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      const data = response.data.reduce((accumulator, { location }) => {
        if (location.type === layer.areaType) {
          if (accumulator[location.code]) {
            accumulator[location.code]++;
          } else {
            accumulator[location.code] = 1;
          }
        }

        return accumulator;
      }, {});

      return data;
    }
    case 'personPolls': {
      const response = await api.get('/radioPolls', {
        params: {
          query: {
            time: { $gte: layer.startTime, $lt: layer.endTime },
            position: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: {
            identifier: true,
            ssi: true,
            code: true,
            time: true,
            position: true,
          },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ position: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'locations':
      const response = await api.get('/locations', {
        params: {
          query: {
            startTime: { $lt: layer.endTime },
            endTime: { $gt: layer.startTime },
            boundary: layer.boundaryGeometry
              ? {
                  $geoIntersects: {
                    $geometry: layer.boundaryGeometry,
                  },
                }
              : undefined,
            $and: filters.length > 0 ? filters : undefined,
          },
          projection: { code: true, name: true, subtype: true, type: true, boundary: true },
        },
        headers: getHeaders(),
        cancelToken: new CancelToken((c) => {
          cancels[index] = c;
        }),
      });

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ boundary: geometry, code: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: {
                ...properties,
                id,
                code: id,
                source: 'locations',
              },
              geometry,
            };
          }
        ),
      };
    default:
      return {
        type: 'FeatureCollection',
        features: [],
      };
  }
}

async function fetchAreas(index, layer, data) {
  const response = await api.get('/locations', {
    params: {
      query: {
        type: layer.areaType,
        boundary: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
      },
      projection: { code: true, name: true,
        //  type: true, subtype: true, 
        boundary: true },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancels[index] = c;
    }),
  });

  const counts = Object.values(data).sort((a, b) => a - b);

  return {
    type: 'FeatureCollection',
    features: response.data.map(
      ({ boundary: geometry, code: id, ...properties }, index) => {
        return {
          type: 'Feature',
          id: index,
          properties: {
            ...properties,
            // code: id,
            id,
            source: 'areas',
            measure: layer.source,
            count: data[id] || 0,
            quantile: data[id] ? quantileRankSorted(counts, data[id]) : 0,
          },
          geometry,
        };
      }
    ),
  };
}

async function fetchRetrospectiveLayerRequest(index, layer, parsedFilters) {
  const data = await fetchLayerData(index, layer, parsedFilters);

  log('Read', 'Layer', layer);

  switch (layer.type) {
    case 'shape':
    case 'bubble':
    case 'heat':
      return { index, layer: { ...layer, featureCollection: data } };
    case 'area':
      const areas = await fetchAreas(index, layer, data);

      return { index, layer: { ...layer, featureCollection: areas } };
    default:
      return { index, layer };
  }
}

export function fetchRetrospectiveLayerEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_LAYER),
    mergeMap(({ payload: { index, layer, parsedFilters } }) =>
      from(fetchRetrospectiveLayerRequest(index, layer, parsedFilters)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_LAYER_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_RETROSPECTIVE_LAYER_CANCELLED),
            filter((action) => action.payload === index),
            tap((ev) => cancels[index]('cancelled'))
          )
        ),
        catchError(({ message }) =>
          of({
            type: FETCH_RETROSPECTIVE_LAYER_FAILURE,
            payload: {
              message,
              index,
            },
          })
        )
      )
    )
  );
}

async function fetchRetrospectiveLayerBoundaryRequest(index, layer) {
  const boundaryGeometry = await fetchBoundary(
    layer.boundaryType,
    layer.boundaryIdentifier
  );

  return {
    index,
    layer: { ...layer, boundaryGeometry },
  };
}

export function fetchRetrospectiveLayerBoundaryEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_LAYER_BOUNDARY),
    mergeMap(({ payload: { index, layer } }) =>
      from(fetchRetrospectiveLayerBoundaryRequest(index, layer)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchRetrospectiveItemRequest({
  id,
  source,
  count,
  quantile,
  measure,
}) {
  let response, visitLocation, event;

  switch (source) {
    case 'vehicleTrips':
      response = await api.get(`/trips/${id}`, {
        params: {
          projection: {
            identifier: true,
            classification: true,
            driver: true,
            vehicle: true,
            startTime: true,
            endTime: true,
            distanceKilometres: true,
            maxSpeedKilometresPerHour: true,
            startLocations: true,
            endLocations: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'vehicleTrip', ...response.data };
    case 'vehicleStops':
      response = await api.get(`/stops/${id}`, {
        params: {
          projection: {
            identifier: true,
            lastDriver: true,
            vehicle: true,
            startTime: true,
            endTime: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'vehicleStop', ...response.data };
    case 'vehicleIdles':
      response = await api.get(`/idles/${id}`, {
        params: {
          projection: {
            identifier: true,
            driver: true,
            vehicle: true,
            startTime: true,
            endTime: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'vehicleIdle', ...response.data };
    case 'vehiclePolls':
      response = await api.get(`/vehiclePolls/${id}`, {
        params: {
          projection: {
            identifier: true,
            imei: true,
            identificationNumber: true,
            time: true,
            position: true,
            headingDegrees: true,
            speedKilometresPerHour: true,
            malfunctionIndicatorLightOn: true,
            accelerometerAlert: true,
            ignitionOn: true,
            driver: true,
            ...Object.keys(dioStates).reduce((acc, key) => {
              acc[key] = true;
              return acc;
            }, {}),
          },
        },
        headers: getHeaders(),
      });

      const vehicleResponse = await api.get(
        `/vehicles/${response.data.identificationNumber}`,
        {
          params: {
            projection: {
              identificationNumber: true,
              registrationNumber: true,
              fleetNumber: true,
              role: true,
              type: true,
              homeStation: true,
              areas: true,
            },
          },
          headers: getHeaders(),
        }
      );

      return {
        itemType: 'vehiclePoll',
        vehicle: vehicleResponse.data,
        ...response.data,
      };
    case 'vehicleVisits':
      response = await api.get(`/intersections/${id}`, {
        params: {
          projection: {
            identifier: true,
            driver: true,
            vehicle: true,
            location: true,
            startTime: true,
            endTime: true,
            distanceKilometres: true,
            maxSpeedKilometresPerHour: true,
          },
        },
        headers: getHeaders(),
      });

      ({ location: visitLocation, ...event } = response.data);

      return { itemType: 'vehicleVisit', visitLocation, ...event };
    case 'incidents':
      response = await api.get(`/incidents/${id}`, {
        params: {
          projection: {
            number: true,
            description: true,
            type: true,
            category: true,
            responseCategory: true,
            grade: true,
            point: true,
            address: true,
            openedTime: true,
            assignedTime: true,
            attendedTime: true,
            closedTime: true,
            status: true,
            closingCodes: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'incident', ...response.data };
    case 'personTrails':
      response = await api.get(`/personTrails/${id}`, {
        params: {
          projection: {
            identifier: true,
            startTime: true,
            endTime: true,
            person: true,
            startLocations: true,
            endLocations: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'personTrail', ...response.data };
    case 'personVisits':
      response = await api.get(`/personLocationIntersections/${id}`, {
        params: {
          projection: {
            identifier: true,
            startTime: true,
            endTime: true,
            person: true,
            location: true,
          },
        },
        headers: getHeaders(),
      });

      ({ location: visitLocation, ...event } = response.data);

      return { itemType: 'personVisit', visitLocation, ...event };
    case 'personPolls':
      response = await api.get(`/radioPolls/${id}`, {
        params: {
          projection: {
            identifier: true,
            ssi: true,
            code: true,
            time: true,
            position: true,
            emergencyButtonOn: true,
          },
        },
        headers: getHeaders(),
      });

      // const personResponse = await api.get(`/people/${response.data.code}`, {
      //   params: {
      //     projection: {
      //       code: true,
      //       collarNumber: true,
      //       forenames: true,
      //       surname: true,
      //       category: true,
      //     },
      //   },
      //   headers: getHeaders(),
      // });

      return {
        itemType: 'personPoll',
        // person: personResponse.data,
        ...response.data,
      };
    case 'areas':
      response = await api.get(`/locations/${id}`, {
        params: {
          projection: {
            code: true,
            name: true,
            type: true,
            areas: true,
            subtype: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'area', count, quantile, measure, ...response.data };
    case 'locations':
      response = await api.get(`/locations/${id}`, {
        params: {
          query: {
            fields: ['code', 'name', 'type', 'areas', 'subtype'],
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'location', ...response.data };
    default:
      return null;
  }
}

export function fetchRetrospectiveItemEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_ITEM),
    mergeMap(({ payload: properties }) =>
      from(fetchRetrospectiveItemRequest(properties)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_ITEM_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_ITEM_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function pushRetrospectiveFormEpic(action$) {
  return action$.pipe(
    ofType(PUSH_RETROSPECTIVE_FORM),
    // the debounce can lead to some timing issues such as results for a boundary
    // query coming back before there are any layers saved (pushed & synced)
    // debounceTime(5000),
    switchMap(({ payload }) =>
      of({
        type: SYNC_RETROSPECTIVE_FORM,
        payload,
      })
    )
  );
}
