import React from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  Typography,
  IconButton,
} from '@material-ui/core';
import { LocationSearching as FollowIcon } from '@material-ui/icons';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';
import { ItemRows, areasToLabelAccessors } from './ItemRows';
import avatarForItem from './avatarUtility';
import { ContentViewer, TagControl } from '../../controls';

// example object
/*
$type: "add"
areas: Array(1)
  0:
    name: "City Centre"
    type: "district"
boundaryIdentifier: "OS_BRTN"
boundarySubtype: "Base"
boundaryType: "Location"
briefs: Array(2)
  0: "OPS"
  1: "AAA"
complianceSeconds: 1200
occurrenceNumber: "OCUR123"
days: Array(7)
  0: 0
  1: 1
  2: 2
  3: 3
  4: 4
  5: 5
  6: 6
description: "<p><img src="data:image/jpeg;base64,/9j/4AAQSkZJRg"
endTime: "2021-04-11T10:59:00.000Z"
hours: Array(12)
  0: 0
  1: 2
  2: 4
  3: 6
  4: 8
  5: 10
  6: 12
  7: 14
  8: 16
  9: 18
  10: 20
  11: 22
id: "FO"
identifier: "FO"
lastEdit:
  time: "2019-12-03T11:39:59.286Z"
  userId: "john.leonard@landaconsultants.com"
searchString: "[object object]+os_brtn+base+location+ops,aaa+1200"
startTime: "2018-09-09T23:00:00.000Z"
title: "First Objective"
type: "Patrol"
// visits have:
requiredFrequency: 'daily',
requiredVisits: 5,

*/

function ObjectiveLiveItem({
  item,
  classes,
  onFollowToggle,
  followedIdsByType,
}) {
  const hideFollow = !item.boundary;
  const type = 'objectives';
  const following = followedIdsByType?.[type]?.[item.id];

  const rowItems = [
    { label: 'Type', value: item.type },
    !!item.occurrenceNumber && {
      label: 'Occurrence Number',
      value: item.occurrenceNumber,
    },
    {
      label: 'Start',
      value: item.startTime && moment(item.startTime).format('DD/MM/YYYY'),
    },
    { label: 'End', value: moment(item.endTime).format('DD/MM/YYYY') },
    item.complianceSeconds && {
      label: 'Compliant Minutes',
      value: `${item.complianceSeconds / 60} m`,
    },
    item.requiredVisits && {
      label: 'Visits',
      value: item.requiredVisits,
    },
    item.requiredFrequency && {
      label: 'Frequency',
      value: _.startCase(item.requiredFrequency),
    },
    item.briefs && { label: 'Briefs', value: item.briefs.join(', ') },
    ...areasToLabelAccessors(item.areas),
  ].filter(Boolean);

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>{`IR3 | Live | Objectives | ${item.title || item.id}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'objectives')}
        title={item.title}
        subheader={item.id}
        action={
          !hideFollow && (
            <React.Fragment>
              <IconButton
                aria-label="Toggle follow"
                onClick={() => onFollowToggle(type, item.id)}
                title="Toggle follow"
              >
                <FollowIcon
                  fontSize="small"
                  color={following ? 'primary' : 'inherit'}
                />
              </IconButton>
            </React.Fragment>
          )
        }
      />
      <CardContent>
        <TagControl item={item} type={'objectives'} />
        <Typography variant="subtitle2" color="textSecondary">
          Description
        </Typography>
        <div className={classes.contentViewer}>
          <ContentViewer content={item.description} />
        </div>
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small" className={classes.table}>
          <TableBody>{ItemRows(rowItems, item)}</TableBody>
        </Table>
        <Typography variant="subtitle2" color="textSecondary">
          Days
        </Typography>
        <div className={classes.timeContainer}>
          {[...'MTWTFSS'].map((letter, index) => (
            <div className={classes.timeWrapper} key={letter + index}>
              <Avatar
                className={
                  item.days.includes(index)
                    ? classes.selectedTime
                    : classes.unselectedTime
                }
              >
                {letter}
              </Avatar>
            </div>
          ))}
        </div>
        <Typography variant="subtitle2" color="textSecondary">
          Hours
        </Typography>
        <div className={classes.timeContainer}>
          {[...Array(24).keys()].map((hour) => (
            <div className={classes.timeWrapper} key={hour}>
              <Avatar
                className={
                  item.hours.includes(hour)
                    ? classes.selectedTime
                    : classes.unselectedTime
                }
              >
                {hour}
              </Avatar>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

export default ObjectiveLiveItem;
