import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from './Auth';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.default,
  },
  progress: {
    margin: theme.spacing(2),
  },
}));

export default function Callback() {
  const location = useLocation();
  const classes = useStyles();
  const auth = useAuth();

  useEffect(() => {
    if (/code|error/.test(location.search)) {
      auth.handleAuthentication();
    }
  });

  return (
    <div className={classes.container}>
      <CircularProgress className={classes.progress} size={200} thickness={2} />
    </div>
  );
}
