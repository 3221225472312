import {
  Avatar,
  Button,
  Paper,
  CardActions,
  CardHeader,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Person as PersonIcon } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { FETCH_RFID_CARD_PEOPLE, REMOVE_RFID_CARD } from '../../actions';
import { ConfirmationDialog } from '../dialogs';
import { useAuth } from '../Auth';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  back: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  heading: {
    paddingLeft: theme.spacing(1),
    paddingTop: 27,
  },
  remove: {
    color: red[500],
  },
}));

export default function RfidCardPeople() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const people = useSelector((state) => state.people.rfidCardPeople, _.isEqual);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [person, setPerson] = useState({});
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const auth = useAuth();
  const editable = auth.isAuthorised('people', true);

  useEffect(() => {
    dispatch({
      type: FETCH_RFID_CARD_PEOPLE,
      payload: id,
    });
  }, [id, dispatch]);

  function handleRemove() {
    const values = {
      code: person.code,
      rfidCards:
        person.rfidCards.filter(
          (card) => (card.reference || '').toLowerCase() !== id.toLowerCase()
        ) || [],
    };

    dispatch({
      type: REMOVE_RFID_CARD,
      payload: values,
    });

    setRemoveOpen(false);
  }

  function handleRemoveClick(person) {
    setPerson(person);
    setRemoveOpen(true);
  }

  function handleCancel() {
    setPerson({});
    setRemoveOpen(false);
  }

  return (
    <div>
      <Helmet>
        <title>IR3 | RFID Card | {id}</title>
      </Helmet>
      {isXs && <Typography variant="subtitle1">{id}</Typography>}
      {people.map((person, index) => (
        <Paper className={classes.card} key={index}>
          <CardHeader
            avatar={
              <Avatar aria-label="Person" className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            }
            title={`${
              person.forenames
                ? `${person.forenames} ${person.surname}`
                : person.code
            }`}
            subheader={person.collarNumber}
          />
          <CardActions>
            <Button
              color="primary"
              aria-label="View"
              component={Link}
              to={`/resources/people/${person.code}`}
            >
              View
            </Button>
            {editable && (
              <Button
                className={classes.remove}
                onClick={() => handleRemoveClick(person)}
              >
                Remove
              </Button>
            )}
          </CardActions>
        </Paper>
      ))}
      <ConfirmationDialog
        action="Remove"
        open={removeOpen}
        itemId={
          `${person.forenames || ''} ${person.surname || ''}` ||
          person.collarNumber ||
          person.code
        }
        onOk={handleRemove}
        onCancel={handleCancel}
      />
    </div>
  );
}
