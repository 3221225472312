import {
  Avatar,
  Button,
  Paper,
  CardActions,
  CardHeader,
  makeStyles,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { DirectionsCar as CarIcon } from '@material-ui/icons';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { REMOVE_VEHICLE_TELEMATICS_BOX } from '../../../actions';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import { useAuth } from '../../Auth';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  back: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  heading: {
    paddingLeft: theme.spacing(1),
    paddingTop: 27,
  },
  remove: {
    color: red[500],
  },
}));

export default function TelematicsBoxVehicles({ imei }) {
  const dispatch = useDispatch();
  const box = useSelector((state) => state.telematicsBoxes.boxesByImei[imei]);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const classes = useStyles();
  const auth = useAuth();
  const editable = auth.isAuthorised('vehicles', true);

  function handleRemove() {
    const values = { telematicsBoxImei: '' };

    dispatch({
      type: REMOVE_VEHICLE_TELEMATICS_BOX,
      payload: {
        identificationNumber: encodeURIComponent(box.identificationNumber),
        previousImei: imei,
        ...values,
      },
    });

    setRemoveOpen(false);
  }

  function handleRemoveClick(vehicle) {
    setVehicle(vehicle);
    setRemoveOpen(true);
  }

  function handleCancel() {
    setVehicle({});
    setRemoveOpen(false);
  }

  return (
    <Fragment>
      {!(box && box.identificationNumber)
        ? ''
        : (box.isMultiAssigned ? box.multiAssignments : [box]).map(
            (vehicle, index) => (
              <Paper className={classes.card} key={index}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="Vehicle" className={classes.avatar}>
                      <CarIcon />
                    </Avatar>
                  }
                  title={
                    window.config.useReducedResourceInformation
                      ? vehicle.fleetNumber
                      : vehicle.registrationNumber
                  }
                  subheader={
                    window.config.useReducedResourceInformation
                      ? vehicle.identificationNumber
                      : vehicle.fleetNumber
                  }
                />
                <CardActions>
                  <Button
                    color="primary"
                    aria-label="View"
                    component={Link}
                    to={`/resources/vehicles/${vehicle.identificationNumber}`}
                  >
                    View
                  </Button>
                  {editable && (
                    <Button
                      className={classes.remove}
                      onClick={handleRemoveClick}
                    >
                      Remove
                    </Button>
                  )}
                </CardActions>
              </Paper>
            )
          )}
      <ConfirmationDialog
        action="Remove"
        open={removeOpen}
        itemId={
          vehicle ? vehicle.registrationNumber || vehicle.fleetNumber : ''
        }
        onOk={handleRemove}
        onCancel={handleCancel}
      />
    </Fragment>
  );
}
