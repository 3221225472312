// https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const { defaultRouteLeavingHeader, defaultRouteLeavingText } = window.config;

const RouteLeavingGuard = ({ when, routeLeavingHeader, routeLeavingText }) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname);
      setLastLocation(null);
      setConfirmedNavigation(false);
    }
  }, [history, confirmedNavigation, lastLocation]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <Dialog
        open={modalVisible}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ routeLeavingHeader ? routeLeavingHeader : defaultRouteLeavingHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {routeLeavingText? routeLeavingText : defaultRouteLeavingText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirmNavigationClick} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default RouteLeavingGuard;
