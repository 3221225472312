import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';

export default function TextField({ input, meta, ...props }) {
  return (
    <MuiTextField
      helperText={meta.touched && (meta.error || meta.submitError)}
      error={meta.touched && meta.invalid}
      {...input}
      {...props}
    />
  );
}
