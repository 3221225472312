import {
  FETCH_VEHICLE_DAILY_UTILISATION,
  FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS,
  FETCH_VEHICLE_DAILY_UTILISATION_FAILURE,
  FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
  LOAD_VEHICLE_DAILY_UTILISATION,
  LOAD_VEHICLE_DAILY_UTILISATION_SUCCESS,
  LOAD_VEHICLE_DAILY_UTILISATION_FAILURE,
  FETCH_VEHICLE_HOURLY_UTILISATION,
  FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS,
  FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE,
  FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
  LOAD_VEHICLE_HOURLY_UTILISATION,
  LOAD_VEHICLE_HOURLY_UTILISATION_SUCCESS,
  LOAD_VEHICLE_HOURLY_UTILISATION_FAILURE,
  UPDATE_VEHICLE_HOURLY_UTILISATION_TRANSFORM_OPTIONS,
} from '../actions';

const INITIAL_STATE = {
  daily: {
    data: [],
    groupBy: 'all',
    orderBy: 'group',
    usedUnusedUnavailableClassification: 
      window.config.reports?.vehicles?.daily?.defaultUsedUnusedUnavailableClassification || false,
    filter: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      areas: {},
    },
    query: {},
    groupByValues: ['all', 'date'],
    filterValues: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      areas: {},
    },
    isLoading: false,
    error: null,
  },
  hourly: {
    data: [],
    usedUnusedUnavailableClassification: 
      window.config.reports?.vehicles?.hourly?.defaultUsedUnusedUnavailableClassification || false,
    filter: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      areas: {},
    },
    filterValues: {
      registrationNumber: [],
      fleetNumber: [],
      role: [],
      type: [],
      areas: {},
    },
  },
  isLoading: false,
  error: null,
};

export default function utilisationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_VEHICLE_DAILY_UTILISATION:
    case LOAD_VEHICLE_DAILY_UTILISATION:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS:
    case LOAD_VEHICLE_DAILY_UTILISATION_SUCCESS:
      return {
        ...state,
        daily: {
          ...state.daily,
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_DAILY_UTILISATION_FAILURE:
    case LOAD_VEHICLE_DAILY_UTILISATION_FAILURE:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: false,
        },
      };
    case FETCH_VEHICLE_HOURLY_UTILISATION:
    case LOAD_VEHICLE_HOURLY_UTILISATION:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS:
    case LOAD_VEHICLE_HOURLY_UTILISATION_SUCCESS:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE:
    case LOAD_VEHICLE_HOURLY_UTILISATION_FAILURE:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: false,
        },
      };
    case UPDATE_VEHICLE_HOURLY_UTILISATION_TRANSFORM_OPTIONS:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          usedUnusedUnavailableClassification: action.payload,
        },
      };
    default:
      return state;
  }
}
