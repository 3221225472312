import {
  Avatar,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Assignment as AssignmentIcon,
} from '@material-ui/icons';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, Route, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { FETCH_PLANS } from '../../actions';
import Container from '../Container';
import Plan from './Plan';
import { SearchBox } from '../controls';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  listSection: {
    width: 220,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  itemSection: {
    width: 'calc(100% - 220px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  list: {
    height: 'calc(100vh - 148px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  active: {
    backgroundColor: theme.palette.action.focus,
  },
  searchBox: {
    padding: theme.spacing(1, 0, 1, 1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(1),
    },
  },
}));

export default function PlanList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const planNames = useSelector((state) => state.plans.planNames, _.isEqual);
  const [filterText, setFilterText] = useState('');
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    dispatch({
      type: FETCH_PLANS,
    });
  }, [dispatch]);

  function renderListItems() {
    const sortedNames = planNames
      .filter(
        (plan) =>
          `${plan.title}+${plan.identifier}`
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) > -1 || filterText === ''
      )
      .sort((a, b) =>
        `${a.title}+${a.identifier}`.localeCompare(`${b.title}+${b.identifier}`)
      );
    return sortedNames.map((plan) => (
      <ListItem
        dense
        button
        key={plan.identifier}
        to={`/plans/${encodeURIComponent(plan.identifier)}`}
        exact={false}
        activeClassName={classes.active}
        isActive={() => plan.identifier === id}
        component={NavLink}
      >
        <ListItemAvatar>
          <Avatar>
            <AssignmentIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={plan.title || plan.identifier}
          secondary={plan.title ? plan.identifier : ''}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>
    ));
  }

  function handleFilterChange(event) {
    setFilterText(event.target.value);
  }
  return (
    <Container title="Plans">
      <Helmet>
        <title>IR3 | Plans</title>
      </Helmet>
      {(!isXs || !id) && (
        <Fragment>
          <div className={classes.listSection}>
            <SearchBox
              value={filterText}
              onChange={handleFilterChange}
              className={classes.searchBox}
            />
            <List dense disablePadding className={classes.list}>
              {renderListItems()}
            </List>
          </div>
          <Fab
            color="secondary"
            aria-label="add"
            className={classes.fab}
            component={Link}
            to={`/plans/new`}
          >
            <AddIcon />
          </Fab>
        </Fragment>
      )}
      {(!isXs || id) && (
        <div className={classes.itemSection}>
          <Route path="/plans/:id">
            <Plan />
          </Route>
        </div>
      )}
    </Container>
  );
}
