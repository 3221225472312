import {
  Event as EventIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';

export default function DatePicker(props) {
  return (
    <KeyboardDatePicker
      {...props}
      invalidDateMessage=""
      leftArrowIcon={<ChevronLeftIcon />}
      rightArrowIcon={<ChevronRightIcon />}
      keyboardIcon={<EventIcon />}
      // format="dd/MM/yyyy"
    />
  );
}
