import {
  Avatar,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import moment from 'moment';
import {
  featureSubtypeColours,
  featureSubtypeIcons,
} from '../../../data/constants';
import { ContentViewer } from '../../controls';

const useStyles = makeStyles((theme) => ({
  description: {
    padding: theme.spacing(2),
  },
  areasSubheader: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: 12,
  },
  areasPaper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  areaType: {
    fontSize: 10,
  },
  areaName: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  edits: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function FeatureContent({ feature }) {
  const classes = useStyles();

  return (
    feature && (
      <Fragment>
        <CardHeader
          avatar={
            <Avatar
              style={{
                backgroundColor:
                  feature.properties.type === 'Perimeter' &&
                  feature.properties.subtype
                    ? featureSubtypeColours.perimeters[
                        feature.properties.subtype
                      ].stroke
                    : null,
              }}
            >
              {feature.properties.type === 'Marker'
                ? featureSubtypeIcons.markers[feature.properties.subtype] ||
                  featureSubtypeIcons.markers.default
                : feature.properties.subtype}
            </Avatar>
          }
          title={feature.properties.title}
          subheader={feature.properties.identifier}
        />
        <CardContent>
          {feature.properties.created && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`Created by ${feature.properties.created.userId} ${moment(
                  feature.properties.created.time
                ).format('DD/MM/YYYY, HH:mm')}`}
              </Typography>
            </div>
          )}
          {feature.properties.lastEdit && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`Last edited by ${feature.properties.lastEdit.userId} ${moment(
                  feature.properties.lastEdit.time
                ).format('DD/MM/YYYY, HH:mm')}`}
              </Typography>
            </div>
          )}
          <div className={classes.description}>
            <ContentViewer content={feature.properties.description} />
          </div>
          {feature.properties.areas && (
            <Fragment>
              <Typography
                key="subheader"
                variant="subtitle1"
                className={classes.areasSubheader}
              >
                Assigned Groups/Areas
              </Typography>
              <Paper key="table" className={classes.areasPaper} elevation={0}>
                <Table>
                  <TableBody>
                    {feature.properties.areas.map((area, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography
                            variant="caption"
                            className={classes.areaType}
                          >
                            {area.type}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="caption"
                            className={classes.areaName}
                          >
                            {area.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Fragment>
          )}
        </CardContent>
      </Fragment>
    )
  );
}
