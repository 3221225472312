import {
  Avatar,
  Paper,
  CardContent,
  Collapse,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';
import { blue, amber } from '@material-ui/core/colors';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Area,
  AreaChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_VEHICLES_IN_LOCATIONS,
  FETCH_VEHICLES_IN_LOCATIONS_CANCELLED,
  LOAD_VEHICLES_IN_LOCATIONS,
} from '../../../actions';
import db from '../../../data/db';
import Container from '../../Container';
import { Parameters, SelectMultiple, CustomTooltip } from '../../controls';
import { downloadCSV } from '../../../apis/utilities';

const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
    fontSize: 12,
  },
  cardHeader: {
    flexGrow: 1,
  },
  chartTitle: {
    padding: theme.spacing(1, 2),
  },
  description: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  cardContent: {
    padding: 0,
    paddingTop: theme.spacing(4),
    // width: '100%'
    // overflowX: 'scroll'
  },
  filterField: {
    width: 144,
  },
  progress: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(0.5),
    width: 200,
  },
  tableSelect: {
    fontSize: '0.8125rem',
  },
  placeholder: {
    color: theme.palette.grey[500],
  },
  collapseContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  legend: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  legendItem: {
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendAvatar: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(0.5),
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? [
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Vehicle Type', key: 'type' },
      ]
    : [
        { label: 'Registration', key: 'registrationNumber' },
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Vehicle Role', key: 'role' },
      ]),
  { label: 'Location Name', key: 'locationName' },
  { label: 'Location Type', key: 'locationType' },
  { label: 'Time', key: 'time' },
  { label: 'Change', key: 'change' },
];

export default function TimelineInLocations() {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.reports.vehiclesInLocations.data,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.reports.vehiclesInLocations.isLoading
  );
  const error = useSelector((state) => state.reports.vehiclesInLocations.error);
  const filter = useSelector(
    (state) => state.reports.vehiclesInLocations.filter,
    _.isEqual
  );
  const filterValues = useSelector(
    (state) => state.reports.vehiclesInLocations.filterValues,
    _.isEqual
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenAreas, setHiddenAreas] = useState([]);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    dispatch({
      type: LOAD_VEHICLES_IN_LOCATIONS,
      payload: filter,
    });
  }, [filter, dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLES_IN_LOCATIONS,
      payload: { query, filter },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLES_IN_LOCATIONS_CANCELLED,
    });
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: (value || []).map((value) => value.value),
          }
        : {
            ...filter,
            areas: {
              ...filter.areas,
              [name]: (value || []).map((value) => value.value),
            },
          };

    dispatch({
      type: LOAD_VEHICLES_IN_LOCATIONS,
      payload: newFilter,
    });
  }

  function handleLegendClick(selectedArea) {
    const index = hiddenAreas.indexOf(selectedArea);

    if (index === -1) {
      setHiddenAreas(hiddenAreas.concat(selectedArea));
    } else {
      setHiddenAreas(
        hiddenAreas.slice(0, index).concat(hiddenAreas.slice(index + 1))
      );
    }
  }

  function getAreaHeaders() {
    return Object.keys(filterValues.areas).map((key) => ({
      label: _.startCase(key),
      key,
    }));
  }

  function formatLabel(epochSeconds) {
    // + is shorthand to convert string to number
    return moment(new Date(+epochSeconds)).format('DD/MM HH:mm:ss');
  }

  async function handleDownloadClick() {
    const allHeaders = headers.concat(getAreaHeaders());
    const filename = 'Timeline In Locations.csv';
    const dataArray = await db.vehiclesInLocations.toArray();
    const data = dataArray[0]
      .filter(({ change }) => change !== 0)
      .map(({ time, areas, ...record }) => ({
        ...record,
        time: moment(time).format('YYYY-MM-DD HH:mm:ss'),
        ...areas,
      }));

    downloadCSV(data, filename, allHeaders);
  }

  return (
    <Container title="Timeline in Location">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        className={classes.parameters}
        vehicle
        location
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Timeline in Location</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Vehicles in Location Timelines
          </Typography>
          <div className={classes.legend}>
            <div
              className={classes.legendItem}
              onClick={() => handleLegendClick('resident')}
            >
              <Avatar
                className={classes.legendAvatar}
                style={{
                  backgroundColor:
                    !hiddenAreas.includes('resident') && blue[500],
                }}
              >
                <Fragment />
              </Avatar>
              <Typography variant="caption">Home Vehicles</Typography>
            </div>
            <div
              className={classes.legendItem}
              onClick={() => handleLegendClick('visitor')}
            >
              <Avatar
                className={classes.legendAvatar}
                style={{
                  backgroundColor:
                    !hiddenAreas.includes('visitor') && amber[500],
                }}
              >
                <Fragment />
              </Avatar>
              <Typography variant="caption">Visiting Vehicles</Typography>
            </div>
          </div>
          <IconButton
            title={showFilter ? 'Hide filter' : 'Show filter'}
            onClick={() => handleFilterToggle()}
          >
            <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <div className={classes.collapseContainer}>
            {!useReducedResourceInformation && (
              <SelectMultiple
                fullWidth
                label="Registration Number"
                placeholder="Select..."
                value={filter.registrationNumber.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) =>
                  handleFilterFieldChanged('registrationNumber', value)
                }
                suggestions={filterValues.registrationNumber.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}
            <SelectMultiple
              fullWidth
              label="Fleet Number"
              placeholder="Select..."
              value={filter.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) =>
                handleFilterFieldChanged('fleetNumber', value)
              }
              suggestions={filterValues.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            {useReducedResourceInformation ? (
              <SelectMultiple
                fullWidth
                label="Type"
                placeholder="Select..."
                value={filter.type.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) => handleFilterFieldChanged('type', value)}
                suggestions={filterValues.type.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            ) : (
              <SelectMultiple
                fullWidth
                label="Role"
                placeholder="Select..."
                value={filter.role.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) => handleFilterFieldChanged('role', value)}
                suggestions={filterValues.role.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}
            <SelectMultiple
              fullWidth
              label="Location Name"
              placeholder="Select..."
              value={filter.locationName.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) =>
                handleFilterFieldChanged('locationName', value)
              }
              suggestions={filterValues.locationName.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              fullWidth
              label="Location Type"
              placeholder="Select..."
              value={filter.locationType.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) =>
                handleFilterFieldChanged('locationType', value)
              }
              suggestions={filterValues.locationType.map((value) => ({
                label: value,
                value,
              }))}
            />
            {Object.entries(filterValues.areas).map((entry) => {
              return (
                <SelectMultiple
                  fullWidth
                  key={entry[0]}
                  label={_.startCase(entry[0])}
                  placeholder="Select..."
                  value={(filter.areas[entry[0]] || []).map((value) => ({
                    label: value,
                    value,
                  }))}
                  onChange={(value) =>
                    handleFilterFieldChanged(entry[0], value)
                  }
                  suggestions={entry[1].map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              );
            })}
          </div>
        </Collapse>
        {_.sortBy(data, ['name']).map((series) => (
          <Fragment key={series.name}>
            <Typography className={classes.chartTitle} variant="subtitle2">
              {series.name}
            </Typography>
            <Paper className={classes.card}>
              <CardContent className={classes.cardContent}>
                <ResponsiveContainer width="99%" height={200}>
                  <AreaChart
                    data={series.values}
                    margin={{ top: 8, right: 16, left: 0, bottom: 32 }}
                  >
                    <XAxis
                      dataKey="time"
                      type="number"
                      domain={['dataMin', 'dataMax']}
                      tickFormatter={formatLabel}
                    >
                      <Label value="Time" position="bottom" offset={36} />
                    </XAxis>
                    <YAxis interval={0} allowDecimals={false}>
                      <Label
                        value="Count"
                        angle={-90}
                        position="left"
                        offset={-24}
                      />
                    </YAxis>
                    <Tooltip
                      content={
                        <CustomTooltip
                          unit="vehicles"
                          labelFormatter={formatLabel}
                        />
                      }
                    />
                    <Brush
                      dataKey="time"
                      height={24}
                      tickFormatter={formatLabel}
                    />
                    <Area
                      type="stepAfter"
                      dataKey="residentCount"
                      name="Home Vehicles"
                      dot={false}
                      stroke={blue[500]}
                      fill={blue[500]}
                      strokeWidth={1}
                      stackId="1"
                      hide={hiddenAreas.includes('resident')}
                    />
                    <Area
                      type="stepAfter"
                      dataKey="visitorCount"
                      name="Visiting Vehicles"
                      dot={false}
                      stroke={amber[500]}
                      fill={amber[500]}
                      strokeWidth={1}
                      stackId="1"
                      hide={hiddenAreas.includes('visitor')}
                    />
                    );
                  </AreaChart>
                </ResponsiveContainer>
              </CardContent>
            </Paper>
          </Fragment>
        ))}
      </div>
    </Container>
  );
}
