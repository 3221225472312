import {
  AccessTime as StaleIcon,
  Assignment as PlanIcon,
  Business as LocationIcon,
  DataUsage as ObjectivesIcon,
  DirectionsCar as CarIcon,
  DoneAll as TaskIcon,
  Feedback as IncidentIcon,
  FilterList as FilteredIcon,
  Person as PersonIcon,
  WarningRounded as EventIcon,
  More as TagsIcon,
  GroupWork as CallSignIcon,
} from '@material-ui/icons';
import moment from 'moment';

export const typeIcons = {
  vehicles: CarIcon,
  locations: LocationIcon,
  people: PersonIcon,
  callSigns: CallSignIcon,
  events: EventIcon,
  incidents: IncidentIcon,
  tasks: TaskIcon,
  plans: PlanIcon,
  objectives: ObjectivesIcon,
  stale: StaleIcon,
  filtered: FilteredIcon,
  tags: TagsIcon,
};

export const singularToPluralTypeMap = {
  vehicle: 'vehicles',
  location: 'locations',
  person: 'people',
  event: 'events',
  incident: 'incidents',
  task: 'tasks',
  plan: 'plans',
  objective: 'objectives',
  tag: 'tags',
  callSign: 'callSigns',
};

export const pluralToSingularTypeMap = {
  vehicles: 'vehicle',
  locations: 'location',
  people: 'person',
  events: 'event',
  incidents: 'incident',
  tasks: 'task',
  plans: 'plan',
  objectives: 'objective',
  tags: 'tag',
  callSigns: 'callSign',
};

const { useReducedResourceInformation } = window.config;
export const labelPaths = {
  vehicles: useReducedResourceInformation
    ? 'fleetNumber'
    : 'registrationNumber',
  locations: 'name',
  people: 'name', // the useReducedResourceInformation is covered in the epic
  events: 'code',
  incidents: 'number',
  tasks: 'task',
  plans: 'title',
  objectives: 'title',
  // tags: 'name', // doesn't have a map label
  // callSigns: 'name', // doesn't have a map label
};

export const liveSubscription = {
  objectives: {
    query: {
      startTime: { $lte: moment().toISOString() },
      endTime: { $gte: moment().toISOString() },
    },
    projection: {
      identifier: true,
      type: true,
      boundaryType: true,
      boundarySubtype: true,
      boundaryIdentifier: true,
      boundary: true,
      startTime: true,
      endTime: true,
      days: true,
      hours: true,
      complianceSeconds: true,
      title: true,
      description: true,
      areas: true,
      created: true,
      lastEdit: true,
      briefs: true,
      occurrenceNumber: true,
      requiredFrequency: true,
      requiredVisits: true,
    },
  },
  tasks: {
    projection: {
      number: true,
      title: true,
      description: true,
      status: true,
      type: true,
      priority: true,
      dueTime: true,
      assignedPerson: true,
      subject: true,
      occurrences: true,
      requiresReview: true,
      group: true,
      created: true,
      lastEdit: true,
    },
  },
  people: {
    query: {
      radioSsi: { $nin: [null, ''] },
    },
    projection: {
      code: true,
      collarNumber: true,
      forenames: true,
      surname: true,
      category: true,
      duties: true,
      radioSsi: true,
      rfidCards: true,
      skills: true,
      position: true,
      lastPollTime: true,
      currentLocations: true,
      headingDegrees: true,
      speedKilometresPerHour: true,
      gpsAvailable: true,
      emergencyButtonOn: true,
      role: true,
      // tags: true,
      // tagChanged: true,
      assignments: true,
      homeStation: true,
      areas: true,
      rank: true,
    },
  },
  locations: {
    query: {
      type: { $in: ['Police Station', 'Workshop', 'Base'] },
    },
    projection: {
      code: true,
      name: true,
      type: true,
      areas: true,
      district: true,
      subtype: true,
      boundary: true,
      // tags: true,
      // tagChanged: true,
    },
  },
  plans: {
    // type: 'plans', // subscribes as collections
    query: {
      type: { $eq: 'Plan' },
      startTime: { $lte: moment().toISOString() },
      endTime: { $gte: moment().toISOString() },
    },
    projection: {
      identifier: true,
      type: true,
      boundary: true,
      startTime: true,
      endTime: true,
      title: true,
      description: true,
      areas: true,
      briefs: true,
      // tags: true,
      // tagChanged: true,
    },
  },
  accelerometerAlerts: {
    query: {
      eventType: 'ACCELEROMETERALERT',
      time: {
        $gte: moment()
          .add(-120, 'm') //should be 120 minutes
          .toISOString(),
      },
    },
    projection: {
      identifier: true,
      eventType: true,
      vehicle: true,
      people: true,
      locations: true,
      time: true,
      point: true,
      // tags: true,
      // tagChanged: true,
    },
  },
  incidents: {
    query: {
      status: { $ne: 'closed' },
      grade: { $ne: 0 },
      openedTime: {
        $gte: moment()
          .add(-window.config.liveOptions.incidentLookbackMinutes || 10080, 'm')
          .toISOString(),
      },
    },
    projection: {
      number: true,
      description: true,
      type: true,
      category: true,
      grade: true,
      point: true,
      openedTime: true,
      assignedTime: true,
      attendedTime: true,
      closedTime: true,
      status: true,
      closingCodes: true,
      // tags: true,
      // tagChanged: true,
      responseCategory: true,
    },
  },
  vehicles: {
    query: {
      telematicsBoxImei: { $nin: [null, ''] },
      lastPollTime: { $exists: true },
      disposalDate: { $in: [null, ''] },
    },
    projection: {
      identificationNumber: true,
      telematicsBoxImei: true,
      fleetNumber: true,
      registrationNumber: true,
      make: true,
      model: true,
      colour: true,
      role: true,
      homeStation: true,
      areas: true,
      lastPollTime: true,
      position: true,
      headingDegrees: true,
      speedKilometresPerHour: true,
      malfunctionIndicatorLightOn: true,
      ignitionOn: true,
      assignedIncidentNumber: true,
      currentLocations: true,
      driver: true,
      lastDriver: true,
      type: true,
      // tags: true,
      // tagChanged: true,
      lastIgnitionOffTime: true,
      assignments: true,
      beaconsOn: true,
      sirensOn: true,
      rearRedLightsOn: true,
      rearBlueLightsOn: true,
      ...Object.fromEntries(
        Object.keys(window.config.dioStates).map((k) => [k, true])
      ),
      equipment: true,
    },
  },
};
