import moment from 'moment';

export function required(value) {
  // was: return value ? undefined : 'Required';
  // 0 could be a valid value
  if (typeof value === 'undefined' || value === '' || value === null) {
    return 'Required';
  }

  return undefined;
}

export function notNegative(value) {
  return value > 0 ? undefined : 'Must be > 0.';
}

function isDateValid(date) {
  return moment(date).isValid();
}

export function isDateValidOrEmpty(date) {
  return !date || moment(date).isValid()
    ? undefined
    : 'Date or time is not valid';
}

export function requiredValidDate(date) {
  return isDateValid(date) ? undefined : 'Date or time is not valid';
}

const before = (endDate) => (value) =>
  !isDateValid(endDate) || moment(value) <= moment(endDate)
    ? undefined
    : 'Must be before ' + moment(endDate).format('DD/MM/YYYY, HH:mm');

const after = (startDate) => (value) => {
  return !isDateValid(startDate) || moment(value) >= moment(startDate)
    ? undefined
    : 'Must be after ' + moment(startDate).format('DD/MM/YYYY, HH:mm');
};

const beforeInclusiveDate = (endDate) => (value) =>
  !isDateValid(endDate) || moment(value) <= moment(endDate)
    ? undefined
    : 'Must be before ' + moment(endDate).format('DD/MM/YYYY');

const afterInclusiveDate = (startDate) => (value) => {
  return !isDateValid(startDate) || moment(value) >= moment(startDate)
    ? undefined
    : 'Must be after ' + moment(startDate).format('DD/MM/YYYY');
};

const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const requiredNotNegative = composeValidators(required, notNegative);
export const requiredDateBefore = (endDate) =>
  composeValidators(required, requiredValidDate, before(endDate));
export const requiredDateAfter = (startDate) =>
  composeValidators(required, requiredValidDate, after(startDate));

export const requiredInclusiveDateBefore = (endDate) =>
  composeValidators(required, requiredValidDate, beforeInclusiveDate(endDate));
export const requiredInclusiveDateAfter = (startDate) =>
  composeValidators(required, requiredValidDate, afterInclusiveDate(startDate));
