import {
  AppBar,
  Button,
  Paper,
  CardActions,
  CircularProgress,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  ViewAgenda as ViewAgendaIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import {
  CLEAR_BRIEF_COLLECTIONS,
  CLEAR_BRIEF_OBJECTIVES,
  FETCH_BRIEF,
  FETCH_BRIEF_COLLECTIONS,
  FETCH_BRIEF_OBJECTIVES,
} from '../../actions';
import BriefCollectionList from './BriefCollectionList';
import BriefMap from './BriefMap';
import {
  BriefContent,
  CollectionContent,
  CrimeContent,
  FeatureContent,
  IncidentContent,
  IntelligenceContent,
  ObjectiveContent,
} from './contents';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingRight: theme.spacing(1),
  },
  subtitle: {
    flex: 1,
  },
  toolbar: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  content: {
    marginTop: 48,
    display: 'flex',
  },
  drawerContent: {
    maxHeight: '80vh',
    overflow: 'scroll',
  },
  backButton: {
    marginRight: 10,
  },
  itemsSection: {
    width: 300,
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mapSection: {
    width: 'calc(100% - 300px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mapCard: {
    height: 'calc(100vh - 64px)',
    margin: theme.spacing(1),
    minWidth: 250,
  },
  smallMapCard: {
    height: 'calc(25vh - 80px)',
    margin: theme.spacing(1),
    minWidth: 250,
  },
  mapContainer: {
    height: '100%',
  },
  smallMapContainer: {
    height: '20%',
  },
  itemCard: {
    // minHeight: 'calc(75vh)',
    margin: theme.spacing(1),
    minWidth: 250,
  },
  waitingPrompt: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
  toolbarIcon: {
    marginRight: 0,
  },
}));

export default function BriefContents() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const brief = useSelector((state) => state.briefs.brief || {}, _.isEqual);
  const collections = useSelector(
    (state) => state.briefs.collections,
    _.isEqual
  );
  const objectives = useSelector((state) => state.briefs.objectives, _.isEqual);
  const [selectedItemIndex, setSelectedItemIndex] = useState({});
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(true);
  const [listOpen, setListOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    dispatch({
      type: FETCH_BRIEF,
      payload: id,
    });
    dispatch({
      type: FETCH_BRIEF_COLLECTIONS,
      payload: id,
    });
    dispatch({
      type: FETCH_BRIEF_OBJECTIVES,
      payload: id,
    });

    return () => {
      dispatch({
        type: CLEAR_BRIEF_COLLECTIONS,
      });
      dispatch({
        type: CLEAR_BRIEF_OBJECTIVES,
      });
    };
  }, [id, dispatch]);

  function handleListOpen() {
    setListOpen(true);
  }

  function handleListClose() {
    setListOpen(false);
  }

  function toggleDetails() {
    setDetailsOpen(!detailsOpen);
  }

  function handleBackClick() {
    history.goBack();
  }

  function handleDoneClick() {
    const { collectionId, collectionTypeId, featureId } = selectedItemIndex;

    if (featureId) {
      setSelectedItemIndex({
        collectionId,
        collectionTypeId,
      });
    } else {
      setSelectedItemIndex({});
    }
  }

  function renderItem() {
    const {
      collectionTypeId,
      collectionId,
      typeId,
      featureId,
    } = selectedItemIndex;
    const feature = collectionTypeId
      ? collections[collectionTypeId][collectionId].items[typeId].features[
          featureId
        ]
      : objectives.features[featureId];

    switch (typeId) {
      case 'incidents':
        return <IncidentContent feature={feature} />;
      case 'crimes':
        return <CrimeContent feature={feature} />;
      case 'intelligence':
        return <IntelligenceContent feature={feature} />;
      case 'features':
        return <FeatureContent feature={feature} />;
      case 'objectives':
        return <ObjectiveContent feature={feature} />;
      default:
        return '';
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          IR3 | Brief Contents{brief.title ? ` | ${brief.title}` : ''}
        </title>
      </Helmet>
      <AppBar>
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Back"
            className={classes.backButton}
            onClick={handleBackClick}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {brief ? brief.title : ''}
          </Typography>
          <Typography variant="caption" className={classes.subtitle}>
            {brief ? brief.identifier : ''}
          </Typography>
          {isXs && (
            <IconButton
              title={listOpen ? 'Hide List' : 'Show List'}
              color="inherit"
              aria-label="List"
              className={classes.toolbarIcon}
              onClick={handleListOpen}
            >
              <ViewListIcon />
            </IconButton>
          )}
          <IconButton
            title={detailsOpen ? 'Hide Details' : 'Show Details'}
            color="inherit"
            aria-label="Details"
            className={classes.toolbarIcon}
            onClick={toggleDetails}
          >
            <ViewAgendaIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        {!isXs && (
          <div className={classes.itemsSection}>
            {collections || objectives ? (
              <BriefCollectionList
                collections={collections}
                objectives={objectives}
                selectedItemIndex={selectedItemIndex}
                onSelectItem={setSelectedItemIndex}
                hoveredItemIndex={hoveredItemIndex}
                onHoverItem={setHoveredItemIndex}
              />
            ) : (
              <div className={classes.waitingPrompt}>
                <CircularProgress />
                <Typography>
                  Waiting for Crime &amp; Intelligence...
                  <span role="img" aria-label="Sad face">
                    🙁
                  </span>
                </Typography>
              </div>
            )}
          </div>
        )}
        <div className={classes.mapSection}>
          {isXs ? (
            <Fragment>
              <div
                className={
                  detailsOpen ? classes.smallMapContainer : classes.mapContainer
                }
              >
                <BriefMap
                  collections={collections}
                  objectives={objectives}
                  smallMap={detailsOpen}
                  selectedItemIndex={selectedItemIndex}
                  onSelectItem={setSelectedItemIndex}
                  hoveredItemIndex={hoveredItemIndex}
                  onHoverItem={setHoveredItemIndex}
                />
              </div>
              {detailsOpen && (
                <div>
                  {Number.isInteger(selectedItemIndex.featureId) ? (
                    renderItem()
                  ) : selectedItemIndex.collectionId ? (
                    <CollectionContent
                      collecion={
                        collections[selectedItemIndex.collectionTypeId][
                          selectedItemIndex.collectionId
                        ]
                      }
                    />
                  ) : (
                    <BriefContent />
                  )}
                  {(selectedItemIndex.collectionId ||
                    Number.isInteger(selectedItemIndex.featureId)) && (
                    <CardActions disableSpacing>
                      <Button color="primary" onClick={handleDoneClick}>
                        Done
                      </Button>
                    </CardActions>
                  )}
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Paper
                className={detailsOpen ? classes.smallMapCard : classes.mapCard}
              >
                <BriefMap
                  collections={collections}
                  objectives={objectives}
                  smallMap={detailsOpen}
                  selectedItemIndex={selectedItemIndex}
                  onSelectItem={setSelectedItemIndex}
                  hoveredItemIndex={hoveredItemIndex}
                  onHoverItem={setHoveredItemIndex}
                />
              </Paper>
              {detailsOpen && (
                <Paper className={classes.itemCard}>
                  {Number.isInteger(selectedItemIndex.featureId) ? (
                    renderItem()
                  ) : selectedItemIndex.collectionId ? (
                    <CollectionContent
                      collection={
                        collections[selectedItemIndex.collectionTypeId][
                          selectedItemIndex.collectionId
                        ]
                      }
                    />
                  ) : (
                    <BriefContent />
                  )}
                  {(selectedItemIndex.collectionId ||
                    Number.isInteger(selectedItemIndex.featureId)) && (
                    <CardActions disableSpacing>
                      <Button color="primary" onClick={handleDoneClick}>
                        Done
                      </Button>
                    </CardActions>
                  )}
                </Paper>
              )}
            </Fragment>
          )}
        </div>
      </div>
      {isXs && (
        <Drawer anchor="bottom" open={listOpen} onClose={handleListClose}>
          <div className={classes.drawerContent}>
            {collections ? (
              <BriefCollectionList
                collections={collections}
                objectives={objectives}
                selectedItemIndex={selectedItemIndex}
                onSelectItem={setSelectedItemIndex}
                hoveredItemIndex={hoveredItemIndex}
                onHoverItem={setHoveredItemIndex}
              />
            ) : (
              <div className={classes.waitingPrompt}>
                <CircularProgress />
                <Typography>
                  Waiting for Crime &amp; Intelligence...
                  <span role="img" aria-label="Sad face">
                    🙁
                  </span>
                </Typography>
              </div>
            )}
          </div>
        </Drawer>
      )}
    </Fragment>
  );
}
