import {
  Avatar,
  Paper,
  CardContent,
  Collapse,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  green,
  orange,
  red,
  teal,
  purple,
  brown,
  cyan,
} from '@material-ui/core/colors';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_PERSON_HOURLY_ACTIVITY,
  FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED,
  LOAD_PERSON_HOURLY_ACTIVITY,
} from '../../../actions';
import Container from '../../Container';
import { Parameters, SelectMultiple, CustomTooltip } from '../../controls';
import { downloadCSV } from '../../../apis/utilities';

const { useReducedResourceInformation, showDoubleCrews } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
    fontSize: 12,
  },
  description: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  cardContent: {
    padding: 0,
    paddingTop: theme.spacing(4),
  },
  filterField: {
    width: 144,
  },
  progress: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(0.5),
    width: 200,
  },
  tableSelect: {
    fontSize: '0.8125rem',
  },
  placeholder: {
    color: theme.palette.grey[500],
  },
  collapseContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  cardHeader: {
    flexGrow: 1,
  },
  legend: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  legendItem: {
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendAvatar: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(0.5),
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const bars = [
  { name: 'On Radio', colour: green[500] },
  { name: 'Driving', colour: purple[500] },
  { name: 'In Base', colour: brown[500] },
  { name: 'In Home Ward', colour: orange[500] },
  { name: 'Responding to Incidents', colour: red[500] },
  { name: 'Attending Objectives', colour: teal[500] },
  ...(showDoubleCrews ? [{ name: 'Double Crewing', colour: cyan[500] }] : []),
];

export default function HourlyActivity(props) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity.hourly.data, _.isEqual);
  const isLoading = useSelector((state) => state.activity.hourly.isLoading);
  const error = useSelector((state) => state.activity.hourly.error);
  const filter = useSelector(
    (state) => state.activity.hourly.filter,
    _.isEqual
  );
  const filterValues = useSelector(
    (state) => state.activity.hourly.filterValues,
    _.isEqual
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenBars, setHiddenBars] = useState([]);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    dispatch({
      type: LOAD_PERSON_HOURLY_ACTIVITY,
      payload: filter,
    });
  }, [filter, dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_PERSON_HOURLY_ACTIVITY,
      payload: { query, filter },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED,
    });
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: (value || []).map((value) => value.value),
          }
        : {
            ...filter,
            areas: {
              ...filter.areas,
              [name]: (value || []).map((value) => value.value),
            },
          };

    dispatch({
      type: LOAD_PERSON_HOURLY_ACTIVITY,
      payload: newFilter,
    });
  }

  function handleLegendClick(selectedBar) {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1))
      );
    }
  }

  async function handleDownloadClick() {
    const filename = 'Person Hourly Activity.csv';

    downloadCSV(data, filename);
  }

  return (
    <Container title="Hourly Activity">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        className={classes.parameters}
        pointEvent
        dateOnly
        person
      />
      <div className={classes.itemSection}>
        {' '}
        <Helmet>
          <title>IR3 | Hourly Activity</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Average Hourly Activity by Hour
          </Typography>
          <IconButton
            title={showFilter ? 'Hide filter' : 'Show filter'}
            onClick={() => handleFilterToggle()}
          >
            <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <div className={classes.collapseContainer}>
            {useReducedResourceInformation ? (
              <SelectMultiple
                fullWidth
                label="Staff ID"
                placeholder="Select..."
                value={filter.code.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) => handleFilterFieldChanged('code', value)}
                suggestions={filterValues.code.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            ) : (
              <Fragment>
                <SelectMultiple
                  fullWidth
                  label="Name"
                  placeholder="Select..."
                  value={filter.name.map((value) => ({
                    label: value,
                    value,
                  }))}
                  onChange={(value) => handleFilterFieldChanged('name', value)}
                  suggestions={filterValues.name.map((value) => ({
                    label: value,
                    value,
                  }))}
                />
                <SelectMultiple
                  fullWidth
                  label="Role"
                  placeholder="Select..."
                  value={filter.role.map((value) => ({
                    label: value,
                    value,
                  }))}
                  onChange={(value) => handleFilterFieldChanged('role', value)}
                  suggestions={filterValues.role.map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              </Fragment>
            )}
            {Object.entries(filterValues.areas).map((entry) => {
              return (
                <SelectMultiple
                  fullWidth
                  key={entry[0]}
                  label={_.startCase(entry[0])}
                  placeholder="Select..."
                  value={(filter.areas[entry[0]] || []).map((value) => ({
                    label: value,
                    value,
                  }))}
                  onChange={(value) =>
                    handleFilterFieldChanged(entry[0], value)
                  }
                  suggestions={entry[1].map((value) => ({
                    label: value,
                    value,
                  }))}
                />
              );
            })}
          </div>
        </Collapse>
        <Paper className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.legend}>
              {bars.map((bar) => (
                <div
                  key={bar.name}
                  className={classes.legendItem}
                  onClick={() => handleLegendClick(bar.name)}
                >
                  <Avatar
                    className={classes.legendAvatar}
                    style={{
                      backgroundColor:
                        !hiddenBars.includes(bar.name) && bar.colour,
                    }}
                  >
                    <Fragment />
                  </Avatar>
                  <Typography variant="caption">{bar.name}</Typography>
                </div>
              ))}
            </div>
            <ResponsiveContainer width="99%" height={600}>
              <BarChart
                data={data}
                margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                barGap={0}
                barCategoryGap={4}
              >
                <XAxis dataKey="Hour">
                  <Label value="Hour" offset={36} position="bottom" />
                </XAxis>
                <YAxis>
                  <Label
                    value="Minutes"
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                <Tooltip
                  cursor={data.length > 0}
                  content={<CustomTooltip unit="minutes" />}
                />
                {data.length > 0 && <Brush dataKey="Hour" height={24} />}
                {bars.map((bar) => (
                  <Bar
                    key={bar.name}
                    dataKey={bar.name}
                    fill={bar.colour}
                    hide={hiddenBars.includes(bar.name)}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
      </div>
    </Container>
  );
}
