import { Chip, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ADD_TAG_ITEM,
  DELETE_TAG_ITEM,
  CREATE_TAG,
  FETCH_TAGS,
} from '../../actions';
import { usePrevious } from '../../hooks';
import { useSnackbar } from '../Snackbar';
import { doesIdExist, urlInvalidCharactersRegex } from '../../apis/utilities';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function TagControl({ item, type }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUserTags = useSelector((state) => state.tags.tags);

  const itemTags = getItemTags();
  const prevItemTags = usePrevious(itemTags);
  const [savedTags, setSavedTags] = useState(itemTags);
  const [error, setError] = useState('');
  const snackbar = useSnackbar();
  const tagExistsMessage = 'Tag already exists';
  const tagInvalidCharacterMessage = 'Tag contains invalid characters!';
  //const regex = /[\^%/\\]+/;

  useEffect(() => {
    if (!_.isEqual(prevItemTags, itemTags)) {
      setSavedTags(getItemTags());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    dispatch({
      type: FETCH_TAGS,
    });
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar, setError]);

  const userTags = currentUserTags
    .map((tag) => tag['identifier'])
    .sort((a, b) => a.localeCompare(b));
  const tagsWithDescription = currentUserTags.map((tag) => {
    return { [tag.identifier]: tag.description?.blocks[0].text };
  });

  function getDescription(key) {
    let desc = '';
    tagsWithDescription.forEach((tag) => {
      if (tag[key]) {
        desc = tag[key];
      }
    });
    return desc;
  }

  function removeTagFromItem(tag) {
    dispatch({
      type: DELETE_TAG_ITEM,
      payload: {
        id: tag,
        item: `items.${type}`,
        value: item.id,
        type: type,
      },
    });

    const newTags = savedTags.filter((t) => t !== tag);
    setSavedTags(newTags);
    setError('');
  }

  function addTagToItem(tag) {
    const trimmedTag = tag.trim();
    if (!!trimmedTag) {
      dispatch({
        type: ADD_TAG_ITEM,
        payload: {
          id: trimmedTag,
          item: `items.${type}`,
          value: item.id,
          type,
        },
      });
      setSavedTags([...savedTags, tag]);
      setError('');
    }
  }

  async function addNewTagToItem(tag) {
    const trimmedTag = tag.trim();
    const tagAlreadyExists = await doesIdExist('tags', trimmedTag);
    const hasSpecialCharaters = urlInvalidCharactersRegex.test(trimmedTag);

    if (tagAlreadyExists) {
      setError(tagExistsMessage);
      snackbar.notify('error', error);
    }

    if (hasSpecialCharaters) {
      setError(tagInvalidCharacterMessage);
      snackbar.notify('error', error);
    }

    if (!!trimmedTag && !tagAlreadyExists && !hasSpecialCharaters) {
      dispatch({
        type: CREATE_TAG,
        payload: {
          identifier: trimmedTag,
          items: { [type]: [item.id] },
        },
      });
      setSavedTags([...savedTags, tag]);
      setError('');
    }
  }

  function handleOnChange(event, newTags) {
    // something changed, was something added or cut out?
    const added = newTags.filter((t) => !savedTags.includes(t)).pop();
    const deleted = savedTags.filter((t) => !newTags.includes(t)).pop();

    if (added) {
      const isNewTag = !userTags.includes(added);
      isNewTag ? addNewTagToItem(added) : addTagToItem(added);
    } else if (deleted) {
      removeTagFromItem(deleted);
    }
  }

  function getItemTags() {
    const selectedTags = [];
    currentUserTags.forEach((tag, i, arr) => {
      if (tag.items) {
        tag.items[type]?.forEach((tagItem) => {
          if (tagItem === item.id) {
            selectedTags.push(tag.identifier);
          }
        });
      }
    });
    return _.uniqBy(selectedTags);
  }

  function handleChipClick(tag) {
    history.push(`/live/tags/${encodeURIComponent(tag)}`);
  }

  return (
    <div className={classes.container}>
      <Autocomplete
        disableClearable
        fullWidth
        freeSolo
        clearOnBlur={false}
        label="Add tag"
        multiple
        value={savedTags}
        onChange={handleOnChange}
        options={userTags}
        renderInput={(params) => (
          <TextField
            size="small"
            label="Tags"
            variant="outlined"
            {...params}
            InputLabelProps={{ shrink: true }}
          />
        )}
        renderOption={(option) => {
          const isNew = !userTags.includes(option);
          return (
            <div>
              {isNew ? (
                `Create new tag "${option}"`
              ) : (
                <Tooltip title={getDescription(option)} placement="bottom">
                  <div>{option}</div>
                </Tooltip>
              )}
            </div>
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return (
              <Chip
                onClick={(e) => handleChipClick(savedTags[index])}
                label={option}
                {...getTagProps({ index })}
              />
            );
          })
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value if typed tag doesn't exist
          const typedOption = params.inputValue?.trim();
          if (typedOption && !userTags.includes(typedOption)) {
            filtered.push(params.inputValue.trim());
          }

          return filtered;
        }}
      ></Autocomplete>
    </div>
  );
}
