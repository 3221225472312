import axios from 'axios';
import { ajax } from 'rxjs/ajax';
import { fromFetch as rxjsFromFetch } from 'rxjs/fetch';
import { webSocket } from 'rxjs/webSocket';

const { apiRootUrl, apiPath } = window.config;

export default axios.create({
  baseURL: `${apiRootUrl}${apiPath}`,
});

export const test = axios.create({
  baseURL: 'http://localhost:4000',
});

export const police = axios.create({
  baseURL: 'https://data.police.uk/api',
});

export function fromAjax(path, { method = 'GET', body, headers, params }) {
  const encodedParams = Object.entries(params || {})
    .map((kv) => `${kv[0]}=${encodeURIComponent(JSON.stringify(kv[1]))}`)
    .join('&');

  return ajax({
    url: `${apiRootUrl}${apiPath}${path}${
      encodedParams ? `?${encodedParams}` : ''
    }`,
    method: method,
    body: body ? JSON.stringify(body) : undefined,
    headers,
  });
}

export function fromFetch(path, { method = 'GET', body, headers, params }) {
  const encodedParams = Object.entries(params)
    .map((kv) => `${kv[0]}=${encodeURIComponent(JSON.stringify(kv[1]))}`)
    .join('&');

  return rxjsFromFetch(
    `${apiRootUrl}${apiPath}${path}${encodedParams ? `?${encodedParams}` : ''}`,
    {
      method: method,
      body: body ? JSON.stringify(body) : undefined,
      headers,
    }
  );
}

export function fromWebSocket({ headers, params }) {
  return webSocket(`${apiRootUrl}/3000`, {
    headers: {
      ...headers,
      message: JSON.stringify(params),
    },
  });
}
