import { Avatar } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { liveIconsByTypeAndSubtype } from '../../../data/constants';
import { pluralToSingularTypeMap } from '../constants';
import { getStatusIconColours } from '../../../mapStyles';

function getAvatarContent(item, type) {
  switch (type) {
    case 'incident':
      // explicitly requested that it's not EME PRI etc.
      // "In case it wasn't clear the circles with numbers in them are incidents. The number is the grade."
      return item.grade || '?';
    case 'objective':
    case 'tag':
      return (
        liveIconsByTypeAndSubtype[type][item.type] ||
        liveIconsByTypeAndSubtype[type]['default']
      );
    default:
      return liveIconsByTypeAndSubtype[type]['default'];
  }
}

export default function avatarForItem(item, type) {
  let status = item.iconStatus || item.status;

  // for consistency elsewhere outside live - I might change this -
  // the type is singular not plural
  type = pluralToSingularTypeMap[type] || type;
  // outline isnt used
  const [, background, color] = getStatusIconColours(type, status);

  return (
    <Avatar title={_.startCase(status)} style={{ background, color }}>
      {getAvatarContent(item, type)}
    </Avatar>
  );
}
