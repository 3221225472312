import {
  Collapse,
  Fab,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, Route } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import { FETCH_OBJECTIVES, FETCH_WARDS } from '../../actions';
import { objectiveTypes } from '../../data/constants';
import { SearchBox, ObjectiveAvatar } from '../controls';
import Container from '../Container';
import Objective from './Objective';
import ObjectiveAttendances from './ObjectiveAttendances';

const { personGroups } = window.config;

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '100%',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  list: {
    overflow: 'none',
    flex: 1,
  },
  active: {
    backgroundColor: theme.palette.action.focus,
  },
  toolbar: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(1),
    },
  },
  searchBox: {
    width: '100%',
    padding: theme.spacing(1, 0, 1, 1),
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  typeTextField: {
    marginRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: 104,
  },
  valueTextField: {
    paddingBottom: theme.spacing(1),
    flex: 1,
  },
  filterListItem: {
    padding: 0,
  },
  settingsPanel: {
    padding: theme.spacing(0, 1, 1, 1),
  },
  sortAsc: {
    transform: 'rotate(0deg)',
    // marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  sortDesc: {
    transform: 'rotate(180deg)',
  },
  sortTextField: {
    paddingBottom: theme.spacing(1),
  },
  flex: {
    display: 'flex',
  },
}));

export default function ObjectiveList() {
  const { id, objectiveType, report } = useParams();
  const dispatch = useDispatch();
  const objectives = useSelector(
    (state) => state.objectives.objectives,
    _.isEqual
  );
  const wards = useSelector((state) => state.locations.wardNames, _.isEqual);
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState('title');
  const [sortDesc, setSortDesc] = useState(false);
  const [filter, setFilter] = useState({
    area: {
      type: '',
      name: '',
    },
    createdBy: '',
    ward: '',
    status: '',
  });
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const sortOptions = [
    { label: 'Title', value: 'title' },
    { label: 'Identifier', value: 'identifier' },
    { label: 'Start Date', value: 'startTime' },
    { label: 'End Date', value: 'endTime' },
  ];

  function areaFilter(objective) {
    if (filter.area.name === '') {
      return true;
    }

    if (!objective.areas) {
      return false;
    }

    return (
      objective.areas.filter(
        (area) =>
          area.type === filter.area.type && area.name === filter.area.name
      ).length > 0
    );
  }

  function createdByFilter(objective) {
    if (filter.createdBy === '') {
      return true;
    }

    if (!objective.created) {
      return false;
    }

    return objective.created.userId === filter.createdBy;
  }

  function wardFilter(objective) {
    if (filter.ward === '') {
      return true;
    }

    if (!objective.wards) {
      return false;
    }

    return objective.wards.includes(filter.ward);
  }

  function statusFilter(objective) {
    const now = moment();

    switch (filter.status) {
      case 'Active':
        return (
          moment(objective.startTime) <= now && now < moment(objective.endTime)
        );
      case 'Upcoming':
        return moment(objective.startTime) > now;
      case 'Expired':
        return moment(objective.endTime) < now;
      default:
        return true;
    }
  }

  const filteredList = objectives
    .filter(
      (objective) =>
        `${objective.title}+${objective.identifier}`
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) > -1 || searchText === ''
    )
    .filter(areaFilter)
    .filter(createdByFilter)
    .filter(wardFilter)
    .filter(statusFilter)
    .sort(
      (a, b) =>
        (sortBy.endsWith('Time')
          ? new Date(a[sortBy] || '1900-1-1') -
            new Date(b[sortBy] || '1900-1-1')
          : (a[sortBy] || '').localeCompare(b[sortBy] || '')) *
        (sortDesc ? -1 : 1)
    );

  useEffect(() => {
    if (wards.length === 0) {
      dispatch({
        type: FETCH_WARDS,
      });
    }
  }, [wards, dispatch]);

  useEffect(() => {
    dispatch({
      type: FETCH_OBJECTIVES,
      payload: objectiveTypes[objectiveType].name,
    });
    setFilter({
      area: {
        type: '',
        name: '',
      },
      createdBy: '',
      ward: '',
      status: '',
    });
  }, [objectiveType, dispatch]);

  function Row({ data, index, style }) {
    const item = data[index];

    return (
      <ListItem
        className={classes.listItem}
        dense
        button
        key={index}
        style={style}
        component={NavLink}
        to={`/objectives/${objectiveType}/${encodeURIComponent(
          item.identifier
        )}`}
        exact={false}
        activeClassName={classes.active}
        isActive={() => item.identifier === id}
      >
        <ListItemAvatar>
          <ObjectiveAvatar
            type={item.type}
            startTime={item.startTime}
            endTime={item.endTime}
          />
        </ListItemAvatar>
        <ListItemText
          title={item.title}
          primary={item.title}
          secondary={
            sortBy.endsWith('Time')
              ? item[sortBy]
                ? moment(item[sortBy]).format('DD/MM/YYYY, HH:mm:ss')
                : ''
              : item.identifier
          }
        />
      </ListItem>
    );
  }

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }

  function handleAreaTypeChange(event) {
    setFilter({
      ...filter,
      area: { name: '', type: event.target.value },
    });
  }

  function handleAreaNameChange(event) {
    setFilter({
      ...filter,
      area: { ...filter.area, name: event.target.value },
    });
  }

  function handleCreatedByChange(event) {
    setFilter({ ...filter, createdBy: event.target.value });
  }

  function handleWardChange(event) {
    setFilter({
      ...filter,
      ward: event.target.value,
    });
  }

  function handleStatusChange(event) {
    setFilter({ ...filter, status: event.target.value });
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  //TODO: Fix this so it works with new group objects
  function getAreas() {
    const names = []
      .concat(...objectives.map((objective) => objective.areas || []))
      .reduce((accumulator, area) => {
        if (area.type === filter.area.type) {
          accumulator.add(area.name);
        }

        return accumulator;
      }, new Set());

    return Array.from(names.values());
  }

  function getCreatorIds() {
    const ids = new Set(
      objectives
        .filter((objective) => objective.created)
        .map((objective) => objective.created.userId)
        .sort()
    );

    return Array.from(ids.values());
  }

  function getWards() {
    const ids = new Set(
      [].concat.apply(
        [],
        objectives
          .filter((objective) => 'wards' in objective)
          .map((objective) => objective.wards)
          .sort()
      )
    );

    const list = Array.from(ids.values()).map((id) => {
      const ward = wards.find((ward) => ward.code === id);

      return {
        value: id,
        label: ward ? ward.name : id,
      };
    });

    return list;
  }

  return (
    <Container title={_.startCase(objectiveType)} showBack={!!report}>
      <div className={classes.page}>
        <Helmet>
          <title>IR3 | {_.startCase(objectiveType)}</title>
        </Helmet>
        {(!isXs || !id) && (
          <div className={classes.sideBar}>
            <div className={classes.searchBar}>
              <div className={classes.toolbar}>
                <SearchBox
                  value={searchText}
                  onChange={handleSearchChange}
                  className={classes.searchBox}
                  endAdornment={
                    <InputAdornment position="start">
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.count}
                      >
                        {`${filteredList.length}/${objectives.length}`}
                      </Typography>
                    </InputAdornment>
                  }
                />
                <IconButton
                  title={showSettings ? 'Hide settings' : 'Show settings'}
                  onClick={handleSettingsToggle}
                >
                  <SettingsIcon color={showSettings ? 'primary' : 'inherit'} />
                </IconButton>
              </div>
            </div>
            <Collapse
              in={showSettings}
              unmountOnExit
              className={classes.settingsPanel}
            >
              <TextField
                className={classes.sortTextField}
                select
                fullWidth
                label="Sort by"
                value={sortBy}
                onChange={handleSortByChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        title={sortDesc ? 'Descending' : 'Ascending'}
                        className={clsx(classes.sortAsc, {
                          [classes.sortDesc]: sortDesc,
                        })}
                        onClick={handleSortToggle}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                {sortOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Filters
              </Typography>
              <div className={classes.flex}>
                <TextField
                  className={classes.typeTextField}
                  select
                  label="Group/Area"
                  value={filter.area.type}
                  onChange={handleAreaTypeChange}
                >
                  <MenuItem value="">
                    <em>Any</em>
                  </MenuItem>
                  {Object.entries(personGroups).map((item) => (
                    <MenuItem key={item[0]} value={item[0]}>
                      {item[1].label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className={classes.valueTextField}
                  select
                  label=" "
                  value={filter.area.name}
                  onChange={handleAreaNameChange}
                >
                  <MenuItem value="">
                    <em>Any</em>
                  </MenuItem>
                  {getAreas().map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <TextField
                className={classes.sortTextField}
                fullWidth
                select
                label="Ward"
                value={filter.ward}
                onChange={handleWardChange}
              >
                <MenuItem value="">
                  <em>Any</em>
                </MenuItem>
                {getWards().map((ward) => (
                  <MenuItem key={ward.value} value={ward.value}>
                    {ward.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.sortTextField}
                fullWidth
                select
                label="Created By"
                value={filter.createdBy}
                onChange={handleCreatedByChange}
              >
                <MenuItem value="">
                  <em>Any</em>
                </MenuItem>
                {getCreatorIds().map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.sortTextField}
                fullWidth
                select
                label="Status"
                value={filter.status}
                onChange={handleStatusChange}
              >
                <MenuItem value="">
                  <em>Any</em>
                </MenuItem>
                {['Active', 'Upcoming', 'Expired'].map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </Collapse>
            {filteredList.length > 0 && (
              <div className={classes.list}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      width={width}
                      height={height}
                      overscanCount={10}
                      itemData={filteredList}
                      itemCount={filteredList.length}
                      itemSize={56}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            )}
            {!report && (
              <Fab
                color="secondary"
                aria-label="add"
                className={classes.fab}
                component={Link}
                to={`/objectives/${objectiveType}/new`}
              >
                <AddIcon />
              </Fab>
            )}
          </div>
        )}
        {(!isXs || id) && (
          <div className={classes.main}>
            <Route exact path="/objectives/:objectiveType/:id">
              <Objective />
            </Route>
            <Route exact path="/objectives/:objectiveType/:id/attendances">
              <ObjectiveAttendances />
            </Route>
          </div>
        )}
      </div>
    </Container>
  );
}
