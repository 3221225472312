import {
  FETCH_QUERIES,
  FETCH_QUERY,
  CREATE_QUERY,
  UPDATE_QUERY,
  DELETE_QUERY,
  FETCH_QUERIES_SUCCESS,
  FETCH_QUERIES_FAILURE,
  FETCH_QUERY_SUCCESS,
  FETCH_QUERY_FAILURE,
  CREATE_QUERY_SUCCESS,
  CREATE_QUERY_FAILURE,
  UPDATE_QUERY_SUCCESS,
  UPDATE_QUERY_FAILURE,
  DELETE_QUERY_SUCCESS,
  DELETE_QUERY_FAILURE,
} from '../actions';

const INITIAL_STATE = {
  queryNames: [],
  query: null,
  isLoading: false,
  error: null,
  events: null,
};

export default function queriesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_QUERIES:
      return { ...state, isLoading: true, error: null };
    case FETCH_QUERIES_SUCCESS:
      return {
        ...state,
        queryNames: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_QUERIES_FAILURE:
      return {
        ...state,
        queryNames: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_QUERY:
      return { ...state, isLoading: true, error: null };
    case FETCH_QUERY_SUCCESS:
      return {
        ...state,
        query: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_QUERY_FAILURE:
      return {
        ...state,
        query: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_QUERY:
      return { ...state, isLoading: true, error: null };
    case UPDATE_QUERY_SUCCESS:
      const updatedQueries = state.queryNames.filter(
        (query) => query.identifier !== action.payload.identifier
      );
      return {
        ...state,
        query: action.payload,
        queryNames: [...updatedQueries, action.payload],
        isLoading: false,
        error: null,
      };
    case UPDATE_QUERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_QUERY:
      return { ...state, isLoading: true, error: null };
    case CREATE_QUERY_SUCCESS:
      return {
        ...state,
        query: action.payload,
        queryNames: [...state.queryNames, action.payload],
        isLoading: false,
        error: null,
      };
    case CREATE_QUERY_FAILURE:
      return {
        ...state,
        query: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_QUERY:
      return { ...state, isLoading: true, error: null };
    case DELETE_QUERY_SUCCESS:
      return {
        ...state,
        query: null,
        queryNames: state.queryNames.filter(
          (query) => query.identifier !== action.payload
        ),
        isLoading: false,
        error: null,
      };
    case DELETE_QUERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
