/** @jsxRuntime classic */ // IE 11 fix https://github.com/facebook/create-react-app/issues/9906
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import MomentUtils from '@date-io/moment';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { HelmetProvider } from 'react-helmet-async';
import 'moment/locale/en-gb';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'typeface-roboto';
import App from './components/App';
import { AuthProvider } from './components/Auth';
import { OptionsProvider } from './components/options';
import history from './history';
import store from './store';
import theme from './theme';
import './css/index.css';

class LocalizedUtils extends MomentUtils {
  getWeekdays() {
    return ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  }
  yearFormat = 'YYYY';
  // yearMonthFormat = 'MM/YYYY';
  dateTime24hFormat = 'DD/MM/YYYY, HH:mm';
  time24hFormat = 'HH:mm';
  dateFormat = 'DD/MM/YYYY';
}

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={LocalizedUtils}>
          <CssBaseline />
          <Router history={history}>
            <AuthProvider>
              <OptionsProvider>
                <App />
              </OptionsProvider>
            </AuthProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);
