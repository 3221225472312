import {
  Avatar,
  Paper,
  CardContent,
  Checkbox,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { green, orange, red, teal } from '@material-ui/core/colors';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Bar,
  BarChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_VEHICLE_HOURLY_UTILISATION,
  FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
  LOAD_VEHICLE_HOURLY_UTILISATION,
  UPDATE_VEHICLE_HOURLY_UTILISATION_TRANSFORM_OPTIONS,
} from '../../../actions';
import Container from '../../Container';
import { Parameters, CustomTooltip } from '../../controls';
import { downloadCSV } from '../../../apis/utilities';

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
    fontSize: 12,
  },
  cardContent: {
    padding: 0,
    paddingTop: theme.spacing(4),
  },
  collapseContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  flex: {
    flexGrow: 1,
  },
  legend: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  legendItem: {
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendAvatar: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(0.5),
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const usedUnusedUnavailableClassificationSettings = {
  bars: [
    { name: 'Unused', colour: red[800] },
    { name: 'Unavailable', colour: orange[800] },
    { name: 'Used', colour: green[500] },
  ],
};

const movingStoppedIdleClassificationSettings = {
  bars: [
    { name: 'Stopped @ Base', colour: red[800] },
    { name: 'Idle @ Base', colour: red[500] },
    { name: 'Stopped @ Workshop', colour: orange[800] },
    { name: 'Idle @ Workshop', colour: orange[500] },
    { name: 'Stopped Elsewhere', colour: teal[800] },
    { name: 'Idle Elsewhere', colour: teal[500] },
    { name: 'Moving', colour: green[500] },
  ],
};

export default function HourlyUtilisation() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.utilisation.hourly.data, _.isEqual);
  const usedUnusedUnavailableClassification = useSelector(
    (state) => state.utilisation.hourly.usedUnusedUnavailableClassification,
    _.isEqual
  );
  const isLoading = useSelector((state) => state.utilisation.hourly.isLoading);
  const error = useSelector((state) => state.utilisation.hourly.error);
  const filter = useSelector(
    (state) => state.utilisation.hourly.filter,
    _.isEqual
  );
  const [hiddenBars, setHiddenBars] = useState([]);
  const classes = useStyles();
  const snackbar = useSnackbar();

  const { bars } = usedUnusedUnavailableClassification
    ? usedUnusedUnavailableClassificationSettings
    : movingStoppedIdleClassificationSettings;

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    dispatch({
      type: LOAD_VEHICLE_HOURLY_UTILISATION,
      payload: {
        filter,
        usedUnusedUnavailableClassification,
      },
    });
  }, [filter, dispatch, usedUnusedUnavailableClassification]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLE_HOURLY_UTILISATION,
      payload: { query, filter, usedUnusedUnavailableClassification },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
    });
  }

  function handleLegendClick(selectedBar) {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1))
      );
    }
  }

  function handleClassificationSettingsChange(event) {
    dispatch({
      type: UPDATE_VEHICLE_HOURLY_UTILISATION_TRANSFORM_OPTIONS,
      payload: event.target.checked,
    });
  }

  async function handleDownloadClick() {
    const filename = 'Vehicle Hourly Utilisation.csv';

    downloadCSV(data, filename);
  }

  return (
    <Container title="Hourly Utilisation">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        className={classes.parameters}
        dateOnly
        vehicle
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Hourly Utilisation</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography variant="subtitle1">
            Average Hourly Utilisation by Hour
          </Typography>
          <div className={classes.flex} />
          <Checkbox
            checked={usedUnusedUnavailableClassification}
            onChange={handleClassificationSettingsChange}
          />
          <Typography variant="subtitle1">
            Classify by Used/Unused/Unavailable
          </Typography>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.legend}>
              {bars.map((bar) => (
                <div
                  key={bar.name}
                  className={classes.legendItem}
                  onClick={() => handleLegendClick(bar.name)}
                >
                  <Avatar
                    className={classes.legendAvatar}
                    style={{
                      backgroundColor:
                        !hiddenBars.includes(bar.name) && bar.colour,
                    }}
                  >
                    <Fragment />
                  </Avatar>
                  <Typography variant="caption">{bar.name}</Typography>
                </div>
              ))}
            </div>
            <ResponsiveContainer width="99%" height={600}>
              <BarChart
                data={data}
                margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                barCategoryGap={4}
              >
                <XAxis dataKey="Hour">
                  <Label value="Hour" offset={36} position="bottom" />
                </XAxis>
                <YAxis>
                  <Label
                    value="Minutes"
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                <Tooltip
                  cursor={false}
                  content={<CustomTooltip unit="minutes" />}
                />
                {data.length > 0 && <Brush dataKey="Hour" height={24} />}
                {bars.map((bar) => (
                  <Bar
                    key={bar.name}
                    dataKey={bar.name}
                    stackId="a"
                    fill={bar.colour}
                    hide={hiddenBars.includes(bar.name)}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
      </div>
    </Container>
  );
}
