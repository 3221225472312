import React, {
  Fragment,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  DialogTitle,
  Slider,
} from '@material-ui/core';
import { Upload as UploadIcon } from 'mdi-material-ui';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';

export default function AvatarField({ input, icon, disabled }) {
  const [image, setImage] = useState(input.value);
  const [open, setOpen] = useState(false);
  const [scale, setScale] = useState(1.0);
  const editor = useRef(null);

  useEffect(() => {
    setImage(input.value);
  }, [input.value]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setScale(1);
  }

  function handleOkClick() {
    input.onChange(editor.current.getImageScaledToCanvas().toDataURL());
    setOpen(false);
    setScale(1);
  }

  function handleSliderChange(event, value) {
    setScale(value);
  }

  function handleFileChanged(event) {
    handleDrop(event.target.files);
  }

  const handleDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  return disabled ? (
    <Avatar src={input.value}>{icon}</Avatar>
  ) : (
    <Fragment>
      <IconButton style={{ padding: 0 }} onClick={handleClickOpen}>
        <Avatar src={input.value}>{icon}</Avatar>
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="avatar-picker-title"
      >
        <DialogTitle id="avatar-picker-title">Select photo</DialogTitle>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleFileChanged}
        />
        <label htmlFor="contained-button-file">
          <IconButton
            title="Upload"
            component="span"
            style={{ position: 'absolute', top: 8, right: 8 }}
          >
            <UploadIcon />
          </IconButton>
        </label>
        <Dropzone
          onDrop={handleDrop}
          disableClick
          style={{ width: 250, height: 250 }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <AvatarEditor
                width={250}
                height={250}
                borderRadius={125}
                image={image}
                scale={scale}
                ref={editor}
              />
            </div>
          )}
        </Dropzone>
        <div style={{ paddingLeft: 22, paddingRight: 22 }}>
          <Slider
            value={scale}
            step={0.1}
            min={1}
            max={5}
            onChange={handleSliderChange}
            style={{
              paddingTop: 22,
              paddingBottom: 22,
              paddingLeft: 2,
              paddingRight: 2,
            }}
          />
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOkClick} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
