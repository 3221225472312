import {
  IconButton,
  Snackbar as MuiSnackbar,
  SnackbarContent,
  makeStyles,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@material-ui/icons';
import { amber, green } from '@material-ui/core/colors';
import React, { useState, useRef, useContext, createContext } from 'react';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  // snackBar: {
  //   top: 80
  // },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  typeIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  closeIcon: {
    fontSize: 20,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const typeIcons = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const SnackbarContext = createContext();

export const useSnackbar = () => useRef(useContext(SnackbarContext)).current;

function Snackbar({ notification, clear }) {
  const classes = useStyles();

  const Icon = typeIcons[notification.type];

  function handleSnackbarClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    clear();
  }

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification.message !== ''}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
    >
      <SnackbarContent
        className={classes[notification.type]}
        aria-describedby="message-id"
        message={
          <span id="message-id" className={classes.message}>
            <Icon className={classes.typeIcon} />
            {notification.message}
          </span>
        }
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        }
      />
    </MuiSnackbar>
  );
}

export function SnackbarProvider({ children }) {
  const [notification, setNotification] = useState({
    type: 'info',
    message: '',
  });

  function notify(type, message) {
    if (!_.isEqual({ type, message }, notification)) {
      setNotification({ type, message });
    }
  }

  function clear() {
    setNotification({ type: notification.type, message: '' });
  }

  return (
    <SnackbarContext.Provider
      value={{
        notification,
        notify,
        clear,
      }}
    >
      {children}
      <Snackbar notification={notification} clear={clear} />
    </SnackbarContext.Provider>
  );
}
