import React, { Fragment, useState } from 'react';
import { InputAdornment, Menu, MenuItem } from '@material-ui/core';

export default function AdornmentField({ input, position, values }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const label = (values.find((item) => item.value === input.value) || values[0])
    .label;

  // if (!input.value) {
  //   input.onChange(values[0].value);
  // }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleItemClick = (value) => () => {
    input.onChange(value);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <InputAdornment
        position={position}
        aria-controls="adornment-menu"
        aria-haspopup="true"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        {label}
      </InputAdornment>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(values || []).map((item) => (
          <MenuItem
            key={item.value || item}
            value={item.value || item}
            onClick={handleItemClick(item.value || item)}
          >
            {item.label || item}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
