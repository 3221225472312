import {
  IconButton,
  makeStyles,
  Paper,
  TablePagination,
  Toolbar,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_STOPS,
  FETCH_STOPS_CANCELLED,
  UPDATE_STOPS_FILTER,
  UPDATE_STOPS_QUERY,
} from '../../actions';
import Container from '../Container';
import { FilterPicker, Parameters, Table } from '../controls';
import { useSnackbar } from '../Snackbar';
import EventPreviewMap from '../controls/EventPreviewMap';
import { filterLocally } from '../../data/utilities';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import { downloadCSV } from '../../apis/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },

  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  drillDown: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  map: {
    width: 320,
    height: 320,
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? [
        {
          label: 'Fleet Number',
          key: 'vehicle.fleetNumber',
          type: 'text',
          filter: true,
          // Footer: 'Totals',
        },
        {
          label: 'Type',
          key: 'vehicle.type',
          type: 'text',
          filter: true,
          // Footer: `${filteredstops.length} ${
          //   filteredstops.length === 1 ? 'stop' : 'stops'
          // }`,
        },
        {
          label: 'Staff ID',
          key: 'driver.code',
          type: 'text',
          filter: true,
          // Footer: 'Totals',
        },
      ]
    : [
        {
          label: 'Registration',
          key: 'vehicle.registrationNumber',
          type: 'text',
          filter: true,
          // Footer: 'Totals',
        },
        {
          label: 'Fleet Number',
          key: 'vehicle.fleetNumber',
          type: 'text',
          filter: true,
          // Footer: `${filteredstops.length} ${
          //   filteredstops.length === 1 ? 'stop' : 'stops'
          // }`,
        },
        { label: 'Role', key: 'vehicle.role', type: 'text', filter: true },
        {
          label: 'Type',
          key: 'vehicle.type',
          type: 'text',
          filter: true,
          // Footer: `${filteredstops.length} ${
          //   filteredstops.length === 1 ? 'stop' : 'stops'
          // }`,
        },
        {
          label: 'Forenames',
          key: 'lastDriver.forenames',
          type: 'text',
          filter: true,
          // Footer: 'Totals',
        },
        {
          label: 'Surname',
          key: 'lastDriver.surname',
          type: 'text',
          filter: true,
          // Footer: 'Totals',
        },
        {
          label: 'Collar Number',
          key: 'lastDriver.collarNumber' ? 'lastDriver.collarNumber' : '',
          type: 'text',
          filter: true,
          // Footer: `${filteredstops.length} ${
          //   filteredstops.length === 1 ? 'stop' : 'stops'
          // }`,
        },
        {
          label: 'Driver Role',
          key: 'stops.lastDriver' ? 'lastDriver.role' : '',
          type: 'text',
          filter: true,
        },
      ]),
  {
    label: 'IMEI',
    key: 'vehicle.telematicsBoxImei',
    type: 'text',
    filter: true,
  },
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    type: 'number',
    filter: false,
    // Footer: _.round(
    //   filteredstops
    //     .map((stop) => stop.durationMinutes)
    //     .reduce((a, b) => a + b, 0) || 0,
    //   2
    // ).toString(),
  },
  { label: 'Home Location', key: 'homeStation', type: 'text', filter: true },
  { label: 'Location Name', key: 'locationName', type: 'text', filter: true },
  { label: 'Location Type', key: 'locationType', type: 'text', filter: true },
];

function Event({ entry }) {
  const classes = useStyles();

  return (
    <div className={classes.drillDown}>
      <div className={classes.map}>
        {entry.point && <EventPreviewMap point={entry.point} mapType="stops" />}
      </div>
    </div>
  );
}

export default function Stops() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.events.stops.isLoading);
  const error = useSelector((state) => state.events.stops.error);
  const stops = useSelector((state) => state.events.stops.list, _.isEqual);
  const filter = useSelector((state) => state.events.stops.filter, _.isEqual);
  const query = useSelector((state) => state.events.stops.query, _.isEqual);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_STOPS,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_STOPS_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_STOPS_FILTER,
      payload,
    });
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      updateFilter({
        selectedIdentifiers: filterLocally(filter, stops).map(
          (n) => n.identifier
        ),
      });
    } else {
      updateFilter({
        selectedIdentifiers: [],
      });
    }
  }

  function handleSelectClick(id) {
    const index = filter.selectedIdentifiers.indexOf(id);

    if (index === -1) {
      updateFilter({
        selectedIdentifiers: filter.selectedIdentifiers.concat(id),
      });
    } else {
      updateFilter({
        selectedIdentifiers: filter.selectedIdentifiers
          .slice(0, index)
          .concat(filter.selectedIdentifiers.slice(index + 1)),
      });
    }
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleQueryChange(query) {
    dispatch({
      type: UPDATE_STOPS_QUERY,
      payload: query,
    });
  }

  const filteredStops = filterLocally(filter, stops);

  function handleDownloadClick() {
    const filename = 'Vehicle Stops.csv';
    const data = filteredStops.map((stop) => ({
      ...stop,
      'vehicle.registrationNumber': stop.vehicle.registrationNumber,
      'vehicle.fleetNumber': stop.vehicle.fleetNumber,
      'vehicle.role': stop.vehicle.role,
      'vehicle.type': stop.vehicle.type,
      'lastDriver.forenames': stop.lastDriver ? stop.lastDriver.forenames : '',
      'lastDriver.surname': stop.lastDriver ? stop.lastDriver.surname : '',
      'lastDriver.collarNumber': stop.lastDriver
        ? stop.lastDriver.collarNumber
        : '',
      'lastDriver.role': stop.lastDriver ? stop.lastDriver.role : '',
      'vehicle.telematicsBoxImei': stop.vehicle.telematicsBoxImei,
      startTime: new Date(stop.startTime),
      endTime: new Date(stop.endTime),
      durationMinutes: _.round(stop.durationMinutes || 0.0, 2),
    }));

    downloadCSV(data, filename, headers);
  }

  return (
    <Container title="Stops">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={handleQueryChange}
        className={classes.parameters}
        vehicle
        driver
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Stops</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Stops
          </Typography>
          <FilterPicker
            headers={headers}
            data={stops}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            disabled={filteredStops.length === 0}
            title="Download"
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={filteredStops}
            headers={[
              {
                label: '',
                key: 'expand',
                type: 'expand',
                component: Event,
              },
              ...headers,
            ]}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            keyName="identifier"
            selectedKeys={filter.selectedIdentifiers}
            onSelectAllClick={handleSelectAllClick}
            onSelectClick={handleSelectClick}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredStops.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}
