import {
  Button,
  Paper,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import { useAuth } from '../Auth';
import {
  CREATE_LOCATION,
  DELETE_LOCATION,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCESS,
  UPDATE_LOCATION,
} from '../../actions';
import { locationTypes } from '../../data/constants';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  AvatarField,
  TextField,
  SelectField,
  DateTimeField,
  TypesField,
  GeometryField,
  required,
  requiredDateBefore,
  requiredDateAfter,
} from '../fields';
import { BoundaryMap, TagControl } from '../controls';
import { doesIdExist } from '../../apis/utilities';
import { RouteLeavingGuard } from '../controls';

const {
  showTranmanIdentifier,
  locationSubtypes,
  baseType,
  locationGroups,
  locationEditableFields,
} = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  header: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  delete: {
    color: theme.palette.error.main,
  },
}));

export default function Location() {
  const { id, locationType } = useParams();
  const dispatch = useDispatch();
  const location = useSelector((state) => state.locations.location, _.isEqual);
  const error = useSelector((state) => state.locations.error);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const auth = useAuth();
  const snackbar = useSnackbar();
  const editable = auth.isAuthorised('locations', true);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_LOCATION_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_LOCATION,
        payload: id,
      });
    }
  }, [id, dispatch]);

  async function onSubmit(values) {
    if (location) {
      dispatch({
        type: UPDATE_LOCATION,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('locations', values.code);
      if (exists) {
        return { code: 'Exists' };
      }

      dispatch({
        type: CREATE_LOCATION,
        payload: values,
      });
    }
  }

  function handleDelete() {
    if (location) {
      dispatch({
        type: DELETE_LOCATION,
        payload: encodeURIComponent(location.code),
      });
    }
  }

  function isDisabled(fieldName) {
    return !(
      editable &&
      (locationEditableFields[fieldName] || location === null)
    );
  }

  function validate(values) {
    let errors = {};

    // start/end validation
    errors.startTime = requiredDateBefore(values.endTime)(values.startTime);
    errors.endTime = requiredDateAfter(values.startTime)(values.endTime);

    return errors;
  }

  return (
    <Form
      initialValues={location || { type: locationTypes[locationType]?.name }}
      onSubmit={onSubmit}
      validate={validate}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>
              IR3 | {locationTypes[locationType].name}
              {values.name ? ` | ${values.name}` : ''}
            </title>
          </Helmet>
          <Paper className={classes.card}>
            <CardHeader
              avatar={
                <Field
                  name="picture"
                  component={AvatarField}
                  icon={locationTypes[locationType].icon}
                  disabled={isDisabled('picture')}
                />
              }
              title={values.name || ''}
              subheader={values.code || ''}
            />
            <CardContent className={classes.cardContent}>
              <List>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="code"
                      component={TextField}
                      label="Code"
                      className={classes.textField}
                      validate={required}
                      disabled={!(editable && location === null)}
                    />
                    <Field
                      name="name"
                      component={TextField}
                      label="Name"
                      className={classes.textField}
                      validate={required}
                      disabled={isDisabled('name')}
                    />
                    {showTranmanIdentifier &&
                      values.type === baseType.label && (
                        <Field
                          name="tranmanIdentifier"
                          component={TextField}
                          label="Tranman Identifier"
                          className={classes.textField}
                          disabled={isDisabled('tranmanIdentifier')}
                        />
                      )}
                    <Field
                      name="subtype"
                      component={SelectField}
                      label="Type"
                      values={
                        values.type && locationSubtypes[values.type]
                          ? locationSubtypes[values.type].sort()
                          : []
                      }
                      className={classes.textField}
                      disabled={isDisabled('subtype')}
                    />
                    {/* <Field
                      name="district"
                      component={TextField}
                      label="District"
                      className={classes.textField}
                      disabled={isDisabled('district')}
                    /> */}
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Active Time Period</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="startTime"
                      component={DateTimeField}
                      label="Start Time"
                      className={classes.textField}
                      maxDate={values.endTime || '2100-01-01'}
                      validate={required}
                      disabled={isDisabled('startTime')}
                    />
                    <Field
                      name="endTime"
                      component={DateTimeField}
                      label="End Time"
                      className={classes.textField}
                      minDate={values.startTime || '1900-01-01'}
                      validate={required}
                      disabled={isDisabled('endTime')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Groups & Areas</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="areas"
                      types={locationGroups}
                      component={TypesField}
                      disabled={isDisabled('areas')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>
                  <TagControl item={{ id }} type={'locations'} />
                </ListSubheader>
                <Divider />
                <ListSubheader disableSticky>Boundary</ListSubheader>
                {isDisabled('boundary') ? (
                  <BoundaryMap boundary={values.boundary} />
                ) : (
                  <Field
                    name="boundary"
                    component={GeometryField}
                    geoType="Polygon"
                    validate={required}
                  />
                )}
              </List>
            </CardContent>
            <CardActions disableSpacing>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              {editable && (
                <Button
                  className={classes.delete}
                  onClick={() => setDeleteOpen(true)}
                  disabled={location === null}
                >
                  Delete
                </Button>
              )}
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.name || values.code}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
          <RouteLeavingGuard when={dirty} />
        </form>
      )}
    />
  );
}
