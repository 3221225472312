import React from 'react';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { LoginAvatar, MenuButton } from './controls';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    display: 'flex',
    // flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  flex: {
    flex: 1,
  },
  toolbar: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  menuButton: {
    marginRight: 10,
  },
}));

export default function Container({ showBack, title, children }) {
  const history = useHistory();
  const classes = useStyles();

  function handleBackClick() {
    history.goBack();
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.toolbar}>
          {showBack ? (
            <IconButton
              color="inherit"
              className={classes.menuButton}
              onClick={handleBackClick}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <MenuButton color="inherit" className={classes.menuButton} />
          )}
          <Typography variant="h6" color="inherit" className={classes.flex}>
            {title}
          </Typography>
          <LoginAvatar />
        </Toolbar>
      </AppBar>
      <div className={classes.content}>{children}</div>
    </div>
  );
}
