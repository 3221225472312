import React, { Fragment } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Flag as FlagIcon,
  Person as PersonIcon,
  OutlinedFlag as OutlinedFlagIcon,
} from '@material-ui/icons';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';
import { RetrospectiveTypeIcon } from '../../../data/constants';

const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0.5, 1, 1),
  },
  vehicleTable: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  driverItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  timelineAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
  },
  timelineIcon: {
    fontSize: 16,
  },
}));

export default function VehicleStop({
  color,
  background,
  lastDriver,
  vehicle,
  startTime,
  endTime,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>
          IR3 | Stop |{' '}
          {useReducedResourceInformation
            ? vehicle.fleetNumber
            : vehicle.registrationNumber}
        </title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Vehicle Stop"
          >
            <RetrospectiveTypeIcon type="vehicleStops" />
          </Avatar>
        }
        title={
          useReducedResourceInformation
            ? vehicle.fleetNumber
            : vehicle.registrationNumber
        }
        subheader={
          useReducedResourceInformation
            ? vehicle.identificationNumber
            : vehicle.fleetNumber
        }
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Vehicle
        </Typography>
        <Table size="small" className={classes.vehicleTable}>
          <TableBody>
            {!useReducedResourceInformation && (
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    Role
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{vehicle.role}</Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  {useReducedResourceInformation
                    ? 'Home Location'
                    : 'Home Station'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{vehicle.homeStation}</Typography>
              </TableCell>
            </TableRow>
            {(vehicle.areas || []).map((area, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {_.startCase(area.type)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{area.name}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {lastDriver && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Last Driver
            </Typography>
            <ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  useReducedResourceInformation
                    ? lastDriver.code
                    : `${lastDriver.forenames || ''}  ${
                        lastDriver.surname || ''
                      }`
                }
                secondary={lastDriver.collarNumber}
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical">
          <Step active>
            <StepLabel
              icon={
                <Avatar className={classes.timelineAvatar}>
                  <FlagIcon className={classes.timelineIcon} />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(startTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              Start
            </StepLabel>
            <StepContent />
          </Step>
          <Step active>
            <StepLabel
              icon={
                <Avatar className={classes.timelineAvatar}>
                  <OutlinedFlagIcon className={classes.timelineIcon} />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(endTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              End
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Table size="small" className={classes.vehicleTable}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {humanizeDuration(moment(endTime).diff(moment(startTime)))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
