import { IconButton, makeStyles } from '@material-ui/core';
import {
  Label as LabelIcon,
  LabelOff as LabelOffIcon,
  LocationDisabled as LocationDisabledIcon,
  Map as MapIcon,
  MyLocation as MyLocationIcon,
  Satellite as SatelliteIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import { Feature, Geolocation, Map, View } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { applyTransform, extend } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import { Point } from 'ol/geom';
import {
  defaults as defaultInteractions,
  Select as SelectInteraction,
} from 'ol/interaction';
import { Group, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { getTransform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  boundaryStyle,
  confidenceRadiusStyle,
  getStyle,
  positionStyle,
  setBaseLayers,
} from '../../mapStyles';

const { mapExtent, layerSwitcher } = window.config;

const useStyles = makeStyles({
  map: {
    width: '100%',
    height: '100%',
  },
  zoomInButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    zIndex: 100,
  },
  zoomOutButton: {
    position: 'absolute',
    top: 40,
    left: 5,
    zIndex: 100,
  },
  fitButton: {
    position: 'absolute',
    top: 75,
    left: 5,
    zIndex: 100,
  },
  labelButton: {
    position: 'absolute',
    top: 110,
    left: 5,
    zIndex: 100,
  },
  positionButton: {
    position: 'absolute',
    top: 145,
    left: 5,
    zIndex: 100,
  },
  layerButton: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    zIndex: 100,
  },
  smallZoomOutButton: {
    position: 'absolute',
    top: 5,
    left: 40,
    zIndex: 100,
  },
  smallFitButton: {
    position: 'absolute',
    top: 5,
    left: 75,
    zIndex: 100,
  },
  smllLabelButton: {
    position: 'absolute',
    top: 5,
    left: 110,
    zIndex: 100,
  },
  smallPositionButton: {
    position: 'absolute',
    top: 5,
    left: 145,
    zIndex: 100,
  },
  smallLayerButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 100,
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    color: '#fff',
  },
});

//TODO: This isn't fully working yet
export default function BriefMap({
  selectedItemIndex,
  hoveredItemIndex,
  collections,
  objectives,
  smallMap,
  onSelectItem,
  onHoverItem,
}) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  const [satelliteMapVisible, setSatelliteMapVisible] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [showPosition, setShowPosition] = useState(false);

  const mapDiv = useRef(null);
  const sources = useRef({
    plans: {},
    selections: {},
    queries: {},
    objectives: {},
  });
  const hoverSource = useRef(null);
  const map = useRef(null);
  const select = useRef(null);
  const collectionsLayerGroup = useRef(new Group());
  const geolocation = useRef(null);
  const positionFeature = useRef(null);
  const accuracyFeature = useRef(null);

  const classes = useStyles();

  const getFeature = useCallback(() => {
    if (!(selectedItemIndex && Number.isInteger(selectedItemIndex.featureId))) {
      return null;
    }

    if (selectedItemIndex.typeId === 'objectives') {
      return objectives
        ? objectives.features[selectedItemIndex.featureId]
        : null;
    } else {
      return collections
        ? collections[selectedItemIndex.collectionTypeId][
            selectedItemIndex.collectionId
          ].items[selectedItemIndex.typeId].features[
            selectedItemIndex.featureId
          ]
        : null;
    }
  }, [collections, objectives, selectedItemIndex]);

  function getExtent() {
    let ex = null;

    collectionsLayerGroup.current
      .getLayers()
      .getArray()
      .forEach((group) => {
        group
          .getLayers()
          .getArray()
          .forEach((collection) => {
            collection
              .getLayers()
              .getArray()
              .forEach((layer) => {
                if (ex) {
                  ex = extend(ex, layer.getSource().getExtent());
                } else {
                  ex = layer.getSource().getExtent();
                }
              });
          });
      });

    if (ex) {
      ex = extend(ex, sources.current.objectives.getExtent());
    } else {
      ex = sources.current.objectives.getExtent();
    }

    return ex;
  }

  function getCollectionExtent() {
    let ex = null;

    Object.values(
      sources.current[selectedItemIndex.collectionTypeId][
        selectedItemIndex.collectionId
      ]
    ).forEach((source) => {
      if (ex) {
        ex = extend(ex, source.getExtent());
      } else {
        ex = source.getExtent();
      }
    });

    if (ex[0] === Infinity) {
      return getExtent();
    } else {
      return ex;
    }
  }

  function getCollectionFeatures({ collectionId, collectionTypeId }) {
    let features = [];

    Object.entries(
      sources.current[collectionTypeId][collectionId] || {}
    ).forEach((entry) => {
      if (entry[0] !== 'boundary') {
        features = features.concat(entry[1].getFeatures());
      }
    });

    return features;
  }

  function getFeatureCount() {
    return (
      collectionsLayerGroup.current
        .getLayers()
        .getArray()
        .reduce((ac1, group) => {
          return (
            ac1 +
            group
              .getLayers()
              .getArray()
              .reduce((ac2, collection) => {
                return (
                  ac2 +
                  collection
                    .getLayers()
                    .getArray()
                    .reduce((ac3, layer) => {
                      return ac3 + layer.getSource().getFeatures().length;
                    }, 0)
                );
              }, 0)
          );
        }, 0) +
      (sources.current.objectives.features
        ? sources.current.objectives.features.length
        : 0)
    );
  }

  function resetMapControls() {
    setZoomInDisabled(
      map.current.getView().getZoom() === map.current.getView().getMaxZoom()
    );
    setZoomOutDisabled(
      map.current.getView().getZoom() === map.current.getView().getMinZoom()
    );
  }

  const fitMapToContents = useCallback(() => {
    if (getFeatureCount() > 0) {
      map.current.getView().fit(getExtent(), { maxZoom: 18 });
    } else {
      map.current
        .getView()
        .fit(applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857')));
    }
    resetMapControls();
  }, []);

  function fitMapToCollection() {
    if (selectedItemIndex) {
      map.current.getView().fit(getCollectionExtent(), { maxZoom: 18 });
      resetMapControls();
    }
  }

  function fitMapToSelected() {
    if (getFeature().geometry) {
      map.current.getView().fit(
        new GeoJSON()
          .readGeometry(getFeature().geometry, {
            featureProjection: 'EPSG:3857',
          })
          .getExtent(),
        { maxZoom: 18 }
      );
    }
  }

  function handleMapToggleClick() {
    if (map.current) {
      map.current
        .getLayers()
        .item(satelliteMapVisible ? 0 : 1)
        .setVisible(true);
      map.current
        .getLayers()
        .item(satelliteMapVisible ? 1 : 0)
        .setVisible(false);
      setSatelliteMapVisible(!satelliteMapVisible);
    }
  }

  function handleLabelsToggleClick() {
    setShowLabels(!showLabels);
    collectionsLayerGroup.current
      .getLayers()
      .getArray()
      .forEach((group) => {
        group
          .getLayers()
          .getArray()
          .forEach((collection) => {
            collection
              .getLayers()
              .getArray()
              .forEach((layer) => {
                layer.getSource().changed();
              });
          });
      });
    sources.current.objectives.changed();
    select.current.getFeatures().forEach((feature) => {
      feature.changed();
    });
    hoverSource.current.changed();
  }

  function handlePositionToggleClick() {
    geolocation.current.setTracking(!showPosition);
    setShowPosition(!showPosition);
  }

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      resetMapControls();
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      resetMapControls();
    }
  }

  function handleFitClick() {
    if (selectedItemIndex) {
      if (getFeature()) {
        fitMapToSelected();
      } else {
        fitMapToCollection();
      }
    } else {
      fitMapToContents();
    }
  }

  function setCollectionsSources(collections) {
    const coll = Object.values({
      ...collections.plans,
      ...collections.selections,
      ...collections.queries,
    });

    coll.forEach((collection) => {
      switch (collection.type) {
        case 'Plan':
          sources.current.plans[collection.identifier] = {
            boundary: new VectorSource({
              features: new GeoJSON().readFeatures(collection.items.boundary, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            features: new VectorSource({
              features: new GeoJSON().readFeatures(collection.items.features, {
                featureProjection: 'EPSG:3857',
              }),
            }),
          };
          break;
        case 'Selection':
          sources.current.selections[collection.identifier] = {
            incidents: new VectorSource({
              features: new GeoJSON().readFeatures(collection.items.incidents, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            crimes: new VectorSource({
              features: new GeoJSON().readFeatures(collection.items.crimes, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            intelligence: new VectorSource({
              features: new GeoJSON().readFeatures(
                collection.items.intelligence,
                { featureProjection: 'EPSG:3857' }
              ),
            }),
          };
          break;
        case 'Query':
          sources.current.queries[collection.identifier] = {
            incidents: new VectorSource({
              features: new GeoJSON().readFeatures(collection.items.incidents, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            crimes: new VectorSource({
              features: new GeoJSON().readFeatures(collection.items.crimes, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            intelligence: new VectorSource({
              features: new GeoJSON().readFeatures(
                collection.items.intelligence,
                { featureProjection: 'EPSG:3857' }
              ),
            }),
          };
          break;
        default:
          break;
      }
    });
  }

  const pushCollectionsLayers = useCallback(() => {
    collectionsLayerGroup.current.getLayers().push(
      new Group({
        layers: Object.values(sources.current.plans).map(
          (planSources) =>
            new Group({
              layers: [
                new VectorLayer({
                  source: planSources.boundary,
                  style: boundaryStyle,
                }),
                new VectorLayer({
                  source: planSources.features,
                  style: (feature) => getStyle('default', feature, showLabels),
                }),
              ],
            })
        ),
      })
    );
    collectionsLayerGroup.current.getLayers().push(
      new Group({
        layers: Object.values(sources.current.selections).map(
          (selectionSources) =>
            new Group({
              layers: [
                new VectorLayer({
                  source: selectionSources.incidents,
                  style: (feature) => getStyle('default', feature, showLabels),
                }),
                new VectorLayer({
                  source: selectionSources.crimes,
                  style: (feature) => getStyle('default', feature, showLabels),
                }),
                new VectorLayer({
                  source: selectionSources.intelligence,
                  style: (feature) => getStyle('default', feature, showLabels),
                }),
              ],
            })
        ),
      })
    );
    collectionsLayerGroup.current.getLayers().push(
      new Group({
        layers: Object.values(sources.current.queries).map(
          (querySources) =>
            new Group({
              layers: [
                new VectorLayer({
                  source: querySources.incidents,
                  style: (feature) => getStyle('default', feature, showLabels),
                }),
                new VectorLayer({
                  source: querySources.crimes,
                  style: (feature) => getStyle('default', feature, showLabels),
                }),
                new VectorLayer({
                  source: querySources.intelligence,
                  style: (feature) => getStyle('default', feature, showLabels),
                }),
              ],
            })
        ),
      })
    );
  }, [showLabels]);

  useEffect(() => {
    if (!map.current) {
      if (collections) {
        setCollectionsSources(collections);
        pushCollectionsLayers();
      }

      sources.current.objectives = objectives
        ? new VectorSource({
            features: new GeoJSON().readFeatures(objectives, {
              featureProjection: 'EPSG:3857',
            }),
          })
        : new VectorSource({ features: [] });
      const objectivesLayer = new VectorLayer({
        source: sources.current.objectives,
        style: (feature) => getStyle('default', feature, showLabels),
      });

      const layers = [collectionsLayerGroup.current, objectivesLayer];

      select.current = new SelectInteraction({
        style: (feature) => getStyle('select', feature, showLabels),
      });
      select.current.on('select', (event) => {
        if (event.selected.length > 0) {
          const selectedFeature = new GeoJSON().writeFeatureObject(
            event.selected[0],
            { featureProjection: 'EPSG:3857', rightHanded: true }
          );
          if (selectedFeature.properties.typeId === 'boundary') {
            onSelectItem({
              collectionId: selectedFeature.properties.collectionId,
              collectionTypeId: selectedFeature.properties.collectionTypeId,
            });
            select.current.getFeatures().clear();
          } else if (selectedFeature.properties.typeId === 'objectives') {
            onSelectItem({
              featureId: selectedFeature.id,
              typeId: selectedFeature.properties.typeId,
            });
          } else {
            onSelectItem({
              featureId: selectedFeature.id,
              typeId: selectedFeature.properties.typeId,
              collectionId: selectedFeature.properties.collectionId,
              collectionTypeId: selectedFeature.properties.collectionTypeId,
            });
          }
          hoverSource.current.clear();
          onHoverItem(null);
        }
      });

      map.current = new Map({
        target: mapDiv.current,
        layers,
        interactions: defaultInteractions({
          pinchRotate: false,
          altShiftDragRotate: false,
        }).extend([select.current]),
        view: new View({
          center: [0, 0],
          zoom: 2,
        }),
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
      });
      map.current.on('click', (event) => {
        onSelectItem({});
      });

      setBaseLayers(map.current);

      hoverSource.current = new VectorSource();
      new VectorLayer({
        source: hoverSource.current,
        map: map.current,
        style: (feature) => getStyle('hover', feature, showLabels),
      });

      map.current.on('pointermove', (event) => {
        if (!event.dragging && !getFeature()) {
          const pixel = map.current.getEventPixel(event.originalEvent);
          const feature = map.current.forEachFeatureAtPixel(
            pixel,
            (feature) => {
              return feature;
            }
          );

          if (
            feature &&
            !['My Location', 'Confidence Radius'].includes(
              feature.getProperties().name
            )
          ) {
            const hoveredItemIndex = ((typeId) => {
              switch (typeId) {
                case 'objectives':
                  return {
                    featureId: feature.getId(),
                    typeId: feature.get('typeId'),
                  };
                case 'boundary':
                  return {
                    collectionId: feature.get('collectionId'),
                    collectionTypeId: feature.get('collectionTypeId'),
                  };
                default:
                  return {
                    featureId: feature.getId(),
                    typeId: feature.get('typeId'),
                    collectionId: feature.get('collectionId'),
                    collectionTypeId: feature.get('collectionTypeId'),
                  };
              }
            })(feature.get('typeId'));

            if (_.isEqual(hoveredItemIndex, hoveredItemIndex)) {
              //nothing has changed so do nothing
            } else {
              hoverSource.current.clear();
              //hoverSource.current.addFeature(feature);
              onHoverItem(hoveredItemIndex);
            }
          } else {
            if (hoveredItemIndex) {
              hoverSource.current.clear();
              onHoverItem(null);
            }
          }
        }
      });

      geolocation.current = new Geolocation({
        // enableHighAccuracy must be set to true to have the heading value.
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: map.current.getView().getProjection(),
      });

      accuracyFeature.current = new Feature({ name: 'Confidence Radius' });
      accuracyFeature.current.setStyle(confidenceRadiusStyle);
      geolocation.current.on('change:accuracyGeometry', () => {
        accuracyFeature.current.setGeometry(
          geolocation.current.getAccuracyGeometry()
        );
      });

      positionFeature.current = new Feature({ name: 'My Location' });
      positionFeature.current.setStyle(positionStyle);

      geolocation.current.on('change:position', () => {
        const coordinates = geolocation.current.getPosition();
        positionFeature.current.setGeometry(
          coordinates ? new Point(coordinates) : null
        );
      });

      new VectorLayer({
        map: map.current,
        source: new VectorSource({
          features: [accuracyFeature.current, positionFeature.current],
        }),
      });

      fitMapToContents();
    }
  }, [
    collections,
    fitMapToContents,
    showLabels,
    getFeature,
    hoveredItemIndex,
    objectives,
    onHoverItem,
    onSelectItem,
    pushCollectionsLayers,
  ]);

  useEffect(() => {
    if (collections) {
      setCollectionsSources(collections);
      collectionsLayerGroup.current.getLayers().clear();
      pushCollectionsLayers();

      fitMapToContents();
    }
  }, [collections, fitMapToContents, pushCollectionsLayers]);

  useEffect(() => {
    if (objectives) {
      sources.current.objectives.clear({ fast: true });
      sources.current.objectives.addFeatures(
        new GeoJSON().readFeatures(objectives, {
          featureProjection: 'EPSG:3857',
        })
      );
    }
  }, [objectives]);

  useEffect(() => {
    if (getFeature()) {
      const selectedFeature =
        select.current.getFeatures().length > 0
          ? new GeoJSON().writeFeatureObject(select.current.getFeatures()[0], {
              featureProjection: 'EPSG:3857',
              rightHanded: true,
            })
          : null;
      if (_.isEqual(getFeature(), selectedFeature)) {
        //nothing has changed so do nothing
      } else {
        select.current.getFeatures().clear();
        if (getFeature().properties.typeId === 'boundary') {
          select.current
            .getFeatures()
            .push(
              sources.current[getFeature().properties.collectionTypeId][
                getFeature().properties.collectionId
              ].boundary.getFeatureById(0)
            );
        } else if (getFeature().properties.typeId === 'objectives') {
          select.current
            .getFeatures()
            .push(sources.current.objectives.getFeatureById(getFeature().id));
        } else {
          select.current
            .getFeatures()
            .push(
              sources.current[getFeature().properties.collectionTypeId][
                getFeature().properties.collectionId
              ][getFeature().properties.typeId].getFeatureById(getFeature().id)
            );
        }
        hoverSource.current.clear();
        onHoverItem(null);
      }
    } else {
      select.current.getFeatures().clear();
    }
  }, [getFeature, onHoverItem]);

  useEffect(() => {
    hoverSource.current.clear();

    if (hoveredItemIndex) {
      if (Number.isInteger(hoveredItemIndex.featureId)) {
        if (hoveredItemIndex.typeId === 'objectives') {
          hoverSource.current.addFeature(
            sources.current.objectives.getFeatureById(
              hoveredItemIndex.featureId
            )
          );
        } else {
          hoverSource.current.addFeature(
            sources.current[hoveredItemIndex.collectionTypeId][
              hoveredItemIndex.collectionId
            ][hoveredItemIndex.typeId].getFeatureById(
              hoveredItemIndex.featureId
            )
          );
        }
      } else {
        hoverSource.current.addFeatures(
          getCollectionFeatures(hoveredItemIndex)
        ); //Bug/limitation where it only seems to add features from one layer
      }
    }
  }, [hoveredItemIndex]);

  useEffect(() => {
    map.current.updateSize();
  }, [smallMap]);

  return (
    <div className={classes.container}>
      <div id="map" className={classes.map} ref={mapDiv} />
      <IconButton
        title="Zoom In"
        className={classes.zoomInButton}
        aria-label="Zoom In"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={zoomInDisabled}
        onClick={handleZoomInClick}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        title="Zoom Out"
        className={
          smallMap ? classes.smallZoomOutButton : classes.zoomOutButton
        }
        aria-label="Zoom Out"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={zoomOutDisabled}
        onClick={handleZoomOutClick}
      >
        <ZoomOutIcon />
      </IconButton>
      <IconButton
        title="Fit"
        className={smallMap ? classes.smallFitButton : classes.fitButton}
        aria-label="Fit"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        onClick={handleFitClick}
      >
        <ZoomOutMapIcon />
      </IconButton>
      <IconButton
        title={showLabels ? 'Hide Labels' : 'Show Labels'}
        className={smallMap ? classes.smllLabelButton : classes.labelButton}
        aria-label="Toggle Labels"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        onClick={handleLabelsToggleClick}
      >
        {showLabels ? <LabelOffIcon /> : <LabelIcon />}
      </IconButton>
      <IconButton
        title={showPosition ? 'Stop Tracking' : 'Start Tracking'}
        className={
          smallMap ? classes.smallPositionButton : classes.positionButton
        }
        aria-label="Toggle Tracking"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        onClick={handlePositionToggleClick}
      >
        {showPosition ? <LocationDisabledIcon /> : <MyLocationIcon />}
      </IconButton>
      {layerSwitcher && (
        <IconButton
          title={satelliteMapVisible ? 'Road Map' : 'Satellite Map'}
          className={smallMap ? classes.smallLayerButton : classes.layerButton}
          aria-label="Map"
          color={satelliteMapVisible ? 'inherit' : 'default'}
          onClick={handleMapToggleClick}
        >
          {satelliteMapVisible ? <MapIcon /> : <SatelliteIcon />}
        </IconButton>
      )}
    </div>
  );
}
