import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  MenuItem,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
  withStyles,
  withWidth,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import {
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  CropSquare as CropSquareIcon,
  Delete as DeleteIcon,
  Place as PlaceIcon,
  ShowChart as ShowChartIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  UPDATE_SELECTED_FEATURE,
  UPDATE_SELECTED_FEATURE_INDEX,
} from '../../actions';
import {
  featureSubtypeColours,
  featureSubtypeIcons,
  featureSubtypes,
} from '../../data/constants';
import { ConfirmationDialog } from '../dialogs';
import Groups from './properties/Groups';
import { ContentEditor } from '../controls';

const { maxUploadSize, personGroupTypes } = window.config;

const styles = (theme) => ({
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    width: 200,
  },
  card: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardHeader: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
    flexGrow: 1,
  },
  cardContent: {
    padding: 0,
    width: '100%',
  },
  edits: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  editor: {
    width: '100%',
    marginBottom: 16,
  },
  back: {
    marginLeft: -12,
  },
  remove: {
    color: red[500],
  },
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});

const featureTypes = {
  perimeters: { name: 'Perimeter', icon: <CropSquareIcon /> },
  paths: { name: 'Path', icon: <ShowChartIcon /> },
  markers: { name: 'Marker', icon: <PlaceIcon /> },
};

class FeatureCard extends Component {
  state = {
    errors: {},
    removeOpen: false,
    snackbarOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.errors) !== JSON.stringify(prevProps.errors)
    ) {
      this.setState({
        errors: this.props.errors,
      });
    }
  }

  validate() {
    const { feature } = this.props;
    let hasErrors = false;
    let newState = {};

    if (!feature.properties.title) {
      newState.title = true;
      hasErrors = true;
    }

    if (!feature.properties.identifier) {
      newState.identifier = true;
      hasErrors = true;
    }

    if (!feature.properties.subtype) {
      newState.subtype = true;
      hasErrors = true;
    }

    if (!feature.geometry) {
      newState.geometry = true;
      hasErrors = true;
    }

    this.setState({
      errors: newState,
    });

    return hasErrors;
  }

  handleDoneClick = () => {
    const blob = new Blob([JSON.stringify(this.props.feature)], {
      type: 'application/json',
    });

    if (blob.size < maxUploadSize) {
      const hasErrors = this.validate();
      if (!hasErrors) {
        this.props.dispatch({
          type: UPDATE_SELECTED_FEATURE_INDEX,
          payload: null,
        });
      }
    } else {
      this.setState({ snackbarOpen: true });
    }
  };

  handleRemove = () => {
    this.props.dispatch({
      type: UPDATE_SELECTED_FEATURE,
      payload: {
        ...this.props.feature,
        properties: {
          ...this.props.feature.properties,
          collections: this.props.feature.properties.collections.filter(
            (c) => c !== this.props.collection.identifier
          ),
        },
      },
    });
    this.props.dispatch({
      type: UPDATE_SELECTED_FEATURE_INDEX,
      payload: null,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.props.dispatch({
      type: UPDATE_SELECTED_FEATURE,
      payload: {
        ...this.props.feature,
        properties: {
          ...this.props.feature.properties,
          [name]: value,
        },
      },
    });
  };

  handleStartTimeChange = (date) => {
    this.props.dispatch({
      type: UPDATE_SELECTED_FEATURE,
      payload: {
        ...this.props.feature,
        properties: {
          ...this.props.feature.properties,
          startTime: date,
        },
      },
    });
  };

  handleEndTimeChange = (date) => {
    this.props.dispatch({
      type: UPDATE_SELECTED_FEATURE,
      payload: {
        ...this.props.feature,
        properties: {
          ...this.props.feature.properties,
          endTime: date,
        },
      },
    });
  };

  handleComplianceChange = (e) => {
    const { name, value } = e.target;

    this.props.dispatch({
      type: UPDATE_SELECTED_FEATURE,
      payload: {
        ...this.props.feature,
        properties: {
          ...this.props.feature.properties,
          [name]: value === '' ? '' : value * 60,
        },
      },
    });
  };

  handleEditorChange = (newValue) => {
    this.props.dispatch({
      type: UPDATE_SELECTED_FEATURE,
      payload: {
        ...this.props.feature,
        properties: {
          ...this.props.feature.properties,
          description: newValue,
        },
      },
    });
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  renderContent = () => {
    const { classes, feature, width } = this.props;

    return (
      <div>
        <Toolbar>
          {width === 'xs' && (
            <IconButton onClick={this.handleDoneClick} className={classes.back}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <CardHeader
            avatar={
              <Avatar
                style={{
                  backgroundColor:
                    feature.properties.type === 'Perimeter' &&
                    feature.properties.subtype
                      ? featureSubtypeColours.perimeters[
                          feature.properties.subtype
                        ].stroke
                      : null,
                }}
              >
                {feature.properties.type === 'Marker'
                  ? featureSubtypeIcons.markers[feature.properties.subtype] ||
                    featureSubtypeIcons.markers.default
                  : feature.properties.subtype ||
                    (feature.properties.type
                      ? _.find(_.values(featureTypes), (item) => {
                          return item.name === feature.properties.type;
                        }).icon
                      : '')}
              </Avatar>
            }
            title={feature.properties.title}
            subheader={feature.properties.identifier}
            className={classes.cardHeader}
          />
          {width === 'xs' && (
            <IconButton className={classes.remove}>
              <DeleteIcon />
            </IconButton>
          )}
        </Toolbar>
        <CardContent className={classes.cardContent}>
          {feature.properties.created && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`Created by ${feature.properties.created.userId} ${moment(
                  feature.properties.created.time
                ).format('DD/MM/YYYY, HH:mm')}`}
              </Typography>
            </div>
          )}
          {feature.properties.lastEdit && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`Last edited by ${feature.properties.lastEdit.userId} ${moment(
                  feature.properties.lastEdit.time
                ).format('DD/MM/YYYY, HH:mm')}`}
              </Typography>
            </div>
          )}
          <List disablePadding>
            <ListSubheader disableSticky>Key Information</ListSubheader>
            <ListItem className={classes.section}>
              <div>
                <TextField
                  margin="dense"
                  label="Title"
                  className={classes.textField}
                  error={this.state.errors.title || false}
                  helperText={this.state.errors.title && 'Required'}
                  value={feature.properties.title || ''}
                  onChange={this.handleChange}
                  name="title"
                />
                <TextField
                  margin="dense"
                  label="Identifier"
                  className={classes.textField}
                  error={this.state.errors.identifier || false}
                  helperText={this.state.errors.identifier && 'Required'}
                  value={feature.properties.identifier || ''}
                  onChange={this.handleChange}
                  name="identifier"
                  disabled={!feature.isNew}
                />
                <TextField
                  className={classes.textField}
                  select
                  label="Type"
                  error={this.state.errors.subtype || false}
                  helperText={this.state.errors.subtype && 'Required'}
                  value={feature.properties.subtype || ''}
                  onChange={this.handleChange}
                  name="subtype"
                >
                  {Object.entries(
                    featureSubtypes[`${feature.properties.type.toLowerCase()}s`]
                  ).map((item) => (
                    <MenuItem key={item[0]} value={item[0]}>
                      {item[1]}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </ListItem>
            <Divider />
            <ListSubheader disableSticky>Description</ListSubheader>
            <ListItem>
              <ContentEditor
                name="description"
                placeholder="Write something..."
                value={feature.properties.description || ''}
                onChange={this.handleEditorChange}
                className={classes.editor}
              />
            </ListItem>
            <Divider />
            <ListSubheader disableSticky>Groups & Areas</ListSubheader>
            <ListItem className={classes.section}>
              <Groups types={personGroupTypes} />
            </ListItem>
          </List>
        </CardContent>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
              Unable to save, content greater than 5MB
            </span>
          }
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </div>
    );
  };

  render() {
    const { classes, feature, width } = this.props;

    return (
      feature &&
      (width === 'xs' ? (
        this.renderContent()
      ) : (
        <Card className={classes.card}>
          {this.renderContent()}
          <CardActions disableSpacing>
            <Button color="primary" onClick={this.handleDoneClick}>
              Done
            </Button>
            <Button
              className={classes.remove}
              onClick={() => this.setState({ removeOpen: true })}
            >
              Remove
            </Button>
            <ConfirmationDialog
              action="Remove"
              open={this.state.removeOpen}
              itemId={feature.properties.title || feature.properties.identifier}
              onOk={this.handleRemove}
              onCancel={() => this.setState({ removeOpen: false })}
            />
            {this.state.errors.geometry && (
              <Typography variant="caption" color="error">
                Shape required
              </Typography>
            )}
          </CardActions>
        </Card>
      ))
    );
  }
}

FeatureCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, { feature }) {
  return {
    collection: state.collections.collection,
    featureCollection: state.features.featureCollection,
    //selectedFeatureIndex: state.features.selectedFeatureIndex
    feature:
      feature ||
      (state.features.featureCollection &&
      Number.isInteger(state.features.selectedFeatureIndex)
        ? state.features.featureCollection.features[
            state.features.selectedFeatureIndex
          ]
        : null),
    recurrence:
      state.features.featureCollection &&
      Number.isInteger(state.features.selectedFeatureIndex)
        ? state.features.featureCollection.features[
            state.features.selectedFeatureIndex
          ].properties.recurrence
        : null,
  };
}

export default connect(mapStateToProps)(
  withWidth()(withStyles(styles)(FeatureCard))
);
