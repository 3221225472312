import React, { Fragment } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  Error as ErrorIcon,
  Input as InputIcon,
  Person as PersonIcon,
  VpnKey as VpnKeyIcon,
  VolumeUp as VolumeUpIcon,
  Navigation as NavigationIcon,
  Warning as WarningIcon,
  WbIncandescent as WbIncandescentIcon,
} from '@material-ui/icons';
import { Helmet } from 'react-helmet-async';
import { amber } from '@material-ui/core/colors';
import {
  Engine as EngineIcon,
  AlarmLight as AlarmLightIcon,
  CarSide as CarSideIcon,
  RadioHandheld as RadioHandheldIcon,
} from 'mdi-material-ui';
import moment from 'moment';
import _ from 'lodash';
import { RetrospectiveTypeIcon } from '../../../data/constants';

const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0.5, 1, 1),
  },
  vehicleTable: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  statusGrid: {
    marginTop: theme.spacing(1),
  },
  gridItem: {
    textAlign: 'center',
  },
}));

export default function VehiclePoll({
  color,
  background,
  vehicle,
  imei,
  time,
  headingDegrees,
  speedKilometresPerHour,
  malfunctionIndicatorLightOn,
  accelerometerAlert,
  ignitionOn,
  driver,
  sirensOn,
  beaconsOn,
  rearBlueLightsOn,
  rearRedLightsOn,
  strikeButtonOn,
  frontPWEOn,
  rearPWEOn,
  airwaveOn,
  ancillaryEquipmentOn,
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>
          IR3 | Poll |{' '}
          {(useReducedResourceInformation
            ? (vehicle || {}).fleetNumber
            : (vehicle || {}).registrationNumber) || imei}
        </title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Vehicle Poll"
          >
            <RetrospectiveTypeIcon type="vehiclePolls" />
          </Avatar>
        }
        title={
          (useReducedResourceInformation
            ? (vehicle || {}).fleetNumber
            : (vehicle || {}).registrationNumber) || imei
        }
        subheader={
          useReducedResourceInformation
            ? (vehicle || {}).identificationNumber
            : (vehicle || {}).fleetNumber
        }
      />
      <CardContent>
        {vehicle && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Vehicle
            </Typography>
            <Table size="small" className={classes.vehicleTable}>
              <TableBody>
                {!useReducedResourceInformation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{vehicle.role}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      {useReducedResourceInformation
                        ? 'Home Location'
                        : 'Home Station'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {vehicle.homeStation}
                    </Typography>
                  </TableCell>
                </TableRow>
                {(vehicle.areas || []).map((area, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {_.startCase(area.type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{area.name}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {driver && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Driver
            </Typography>
            <ListItem component="div" className={classes.driverItem}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  useReducedResourceInformation
                    ? driver.code
                    : `${driver.forenames || ''}  ${driver.surname || ''}`
                }
                secondary={driver.collarNumber}
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Status
        </Typography>
        <Grid
          container
          spacing={2}
          justify="space-around"
          alignItems="center"
          className={classes.statusGrid}
        >
          <Grid item xs={4} className={classes.gridItem}>
            <Typography variant="caption">
              {moment(time).format('DD/MM/YYYY, HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.gridItem}>
            <Typography variant="caption">
              {`${Math.round(speedKilometresPerHour * 0.62137119)} MPH`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.gridItem}
            title={`Heading ${headingDegrees}°`}
          >
            <NavigationIcon
              style={{
                transform: `rotate(${headingDegrees}deg)`,
                transformOrigin: ['50%', '50%'],
              }}
              color="action"
            />
          </Grid>
          {ignitionOn !== undefined && (
            <Grid item xs={2} className={classes.gridItem} title="Ignition">
              <VpnKeyIcon
                className={classes.statusIcon}
                htmlColor={
                  ignitionOn ? amber[700] : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {malfunctionIndicatorLightOn !== undefined && (
            <Grid item xs={2} title="Malfunction Indicator Light">
              <EngineIcon
                className={classes.statusIcon}
                htmlColor={
                  malfunctionIndicatorLightOn
                    ? amber[700]
                    : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {accelerometerAlert !== undefined && (
            <Grid item xs={2} title="Accelerometer">
              <WarningIcon
                className={classes.statusIcon}
                htmlColor={
                  accelerometerAlert
                    ? amber[700]
                    : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {sirensOn !== undefined && (
            <Grid item xs={2} title="Sirens">
              <VolumeUpIcon
                className={classes.statusIcon}
                htmlColor={
                  sirensOn ? amber[700] : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {beaconsOn !== undefined && (
            <Grid item xs={2} title="Beacon">
              <AlarmLightIcon
                className={classes.statusIcon}
                htmlColor={
                  beaconsOn ? amber[700] : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {rearBlueLightsOn !== undefined && (
            <Grid item xs={2} title="Rear Blues">
              <WbIncandescentIcon
                className={classes.statusIcon}
                color={rearBlueLightsOn ? 'primary' : 'disabled'}
              />
            </Grid>
          )}
          {rearRedLightsOn !== undefined && (
            <Grid item xs={2} title="Rear Reds">
              <WbIncandescentIcon
                className={classes.statusIcon}
                color={rearRedLightsOn ? 'error' : 'disabled'}
              />
            </Grid>
          )}
          {strikeButtonOn !== undefined && (
            <Grid item xs={2} title="Strike Button">
              <ErrorIcon
                className={classes.statusIcon}
                htmlColor={
                  strikeButtonOn ? amber[700] : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {(frontPWEOn !== undefined || rearPWEOn !== undefined) && (
            <Grid
              item
              xs={2}
              title={`PWE: front ${frontPWEOn ? 'on' : 'off'}, rear ${
                rearPWEOn ? 'on' : 'off'
              }`}
            >
              <CarSideIcon
                component={(svgProps) => {
                  return (
                    <svg {...svgProps}>
                      <defs>
                        <linearGradient id="gradient1">
                          <stop
                            offset="50%"
                            stopColor={
                              rearPWEOn
                                ? theme.palette.primary.dark
                                : theme.palette.action.disabled
                            }
                          />
                          <stop
                            offset="50%"
                            stopColor={
                              frontPWEOn
                                ? theme.palette.primary.dark
                                : theme.palette.action.disabled
                            }
                          />
                        </linearGradient>
                      </defs>
                      {React.cloneElement(svgProps.children[0], {
                        fill: 'url(#gradient1)',
                      })}
                    </svg>
                  );
                }}
              />
            </Grid>
          )}
          {airwaveOn !== undefined && (
            <Grid item xs={2} title="Airwave Radio">
              <RadioHandheldIcon
                className={classes.statusIcon}
                htmlColor={
                  airwaveOn ? amber[700] : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
          {ancillaryEquipmentOn !== undefined && (
            <Grid item xs={2} title="Ancillary Equipment">
              <InputIcon
                className={classes.statusIcon}
                htmlColor={
                  ancillaryEquipmentOn
                    ? amber[700]
                    : theme.palette.action.disabled
                }
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
