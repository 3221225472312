import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import { LocationSearching as FollowIcon } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import avatarForItem from './avatarUtility';
import IncidentLiveListItem from './IncidentLiveListItem';
import CallSignLiveListItem from './CallSignLiveListItem';
import { areasToLabelAccessors, ItemRows, toLabelAccessors } from './ItemRows';
import { FETCH_LIVE_PERSON } from '../../../actions/types';
import { usePrevious } from '../../../hooks';
import { TagControl } from '../../controls';

const { useReducedResourceInformation, useDallasKeys } = window.config;
const { offDutyNames = [] } = window.config.liveOptions;

// example object
// $type: "add"
// assignments:
//   callSign:
//     category: "Available"
//     code: "BT2000"
//     status: "On Duty"
//     time: "2019-01-01T03:00:00Z"
//   incident:
//     number: "DEV-111111-1111"
//     time: "2019-01-01T03:00:00Z"
// code: "124442"
// collarNumber: "collarNo"
// forenames: "Forename"
// homeStation: "Old Street"
// lastPollTime: "2020-01-01T00:00:00.000Z"
// position:
// $reql_type$: "GEOMETRY"
// coordinates: (2) [-0.1787633114341185, 52.3975937602478]
// type: "Point"
// radioSsi: "796284"
// role: "Tommy Role"
// searchString: "[object object]+124442+collarno+forename+old street+2020-01-01t00:00:00.000z+[object object]+796284+tommy role+[object object],[object object]+surname+add+true"
// skills: Array(2)
// 0: {name: "skill 1", type: "not test"}
// 1: {name: "skill 1", type: "not test again"}
// surname: "Surname"
// tagChanged: true

// // none have it but
// duties: [
//   {
//    "available": true,
//    "code": "0900",
//    "endTime": "2019-03-22T17:00:00",
//    "name": "DAY (9AM - 5PM)",
//    "startTime": "2019-03-22T09:00:00",
//    "type": "DAYS"
//   },
//   {
//    "available": false,
//    "code": "RD",
//    "endTime": "2019-03-23T17:00:00",
//    "name": "REST DAY",
//    "startTime": "2019-03-23T09:00:00",
//    "type": "REST DAY"
//   }
//  ]

function dutiesToLabelAccessors(duties) {
  return (duties || []).map((d) => ({
    label: moment(d.startTime).format('ddd D/MM'),
    value: d.name,
    disabled: offDutyNames.some(
      (o) => 0 === o.localeCompare(d.name, undefined, { sensitivity: 'base' })
    ),
  }));
}

function PersonLiveItem({
  item,
  classes,
  onFollowToggle,
  followedIdsByType,
  onSubItemClick,
  onSubItemHover,
  hoveredId,
}) {
  const hideFollow = !item.position;
  const personRecord = useSelector(
    (state) => state.live.personRecordsById[item.id]
  );

  const dispatch = useDispatch();
  const prevId = usePrevious(item.id);
  useEffect(() => {
    if (prevId !== item.id && !personRecord) {
      dispatch({
        type: FETCH_LIVE_PERSON,
        payload: item.id,
      });
    }
  }, [dispatch, item.id, prevId, personRecord]);

  const incidents = useSelector((state) => state.live.incidents);
  const incident =
    item.assignments?.incident?.number &&
    (incidents[item.assignments.incident.number] || {
      id: item.assignments.incident.number,
    });

  const callSigns = useSelector((state) => state.live.callSigns);
  const callSign = callSigns[item.assignments?.callSign?.code];

  const type = 'people';
  const following = followedIdsByType?.[type]?.[item.id];

  const sections = {
    details: [
      !useReducedResourceInformation && { label: 'Role', value: item.role },
      { label: 'Category', value: item.category },
      {
        label: useReducedResourceInformation ? 'Home Location' : 'Home Station',
        value: item.homeStation,
      },
      { label: 'Status', value: _.startCase(item.status) },
      { label: 'Radio SSI', value: item.radioSsi },
      {
        label: 'Emergency Button',
        value: item.emergencyButtonOn ? 'On' : 'Off',
      },
      // { label: 'GPS Available', value: item.gpsAvailable ? 'Yes' : 'No' },
      // { label: "Call sign", value: item.callSign},
      {
        label: 'Last poll time',
        value: item.lastPollTime
          ? moment(item.lastPollTime).format('DD/MM/YYYY HH:mm:ss')
          : '-',
      },
      item.driverKeysArray.length > 0 && {
        label: useDallasKeys ? 'Dallas Keys' : 'RFIDs',
        value: item.driverKeysArray?.join(', ') || '',
      },
      ...areasToLabelAccessors(item.areas),
    ].filter(Boolean),
    skills: toLabelAccessors(item.skills, 'type', 'name'),
    duties: dutiesToLabelAccessors(item.duties),
    locations: toLabelAccessors(item.currentLocations, 'type', 'name'),
  };

  const title = item.name || item.radioSsi;

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>{`IR3 | Live | People | ${title}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'people')}
        title={title}
        // subheader={item.collarNumber}
        action={
          !hideFollow && (
            <IconButton
              title={following ? 'Stop following' : 'Follow'}
              onClick={(e) => {
                e.stopPropagation();
                onFollowToggle('people', item.id);
              }}
            >
              <FollowIcon
                titleAccess="Follow"
                fontSize="small"
                color={following ? 'primary' : 'disabled'}
              />
            </IconButton>
          )
        }
      />
      {personRecord && personRecord.picture && (
        <CardMedia style={{ height: 420 }} image={personRecord.picture} />
      )}

      <CardContent>
        <TagControl item={item} type={type} />
        {item.assignments && (
          <React.Fragment>
            {callSign && (
              <React.Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Call Sign
                </Typography>
                <CallSignLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  classes={classes}
                  item={callSign}
                />
              </React.Fragment>
            )}
            {incident && (
              <React.Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Assigned Incident
                </Typography>
                <IncidentLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  classes={classes}
                  item={incident}
                  highlighted={hoveredId === incident.id}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {Object.keys(sections)
          .filter((key) => sections[key] && sections[key].length > 0)
          .map((key) => (
            <React.Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {_.startCase(key)}
              </Typography>
              <Table size="small" className={classes.table}>
                <TableBody>{ItemRows(sections[key], item)}</TableBody>
              </Table>
            </React.Fragment>
          ))}
      </CardContent>
    </Card>
  );

  // return (
  //   <React.Fragment>
  //     <div className={{ root: { flexGrow: 1 } }}>
  //       <Grid container style={{ padding: '8px' }}>
  //         <Grid item xs={12}>
  //           <React.Fragment>
  //             <h4>Resource Status</h4>
  //             <IconButton
  //               aria-label="Follow"
  //               style={{ float: 'right', padding: '8px' }}
  //               onClick={e => {
  //                 e.stopPropagation();
  //                 onFollowToggle('people', item.id);
  //               }}
  //             >
  //               <FollowIcon
  //                 fontSize="small"
  //                 color={following ? 'primary' : 'disabled'}
  //               />
  //             </IconButton>
  //           </React.Fragment>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <Typography className={classes.cardText}>
  //             Collar Number: {item.collarNumber}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Forenames: {item.forenames}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Surname: {item.surnames}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Callsign: {item.assignments && item.assignments.callsign}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Incident: {item.assignments && item.assignments.incident}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Role: {item.role}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Code: {item.code}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Radio SSI: {item.radioSsi}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Base Station: {item.homeStation}
  //           </Typography>
  //           <Typography className={classes.cardText}>Skills: </Typography>
  //           <Grid style={{ paddingLeft: '8px' }}>
  //             {item.skills &&
  //               item.skills.length > 0 &&
  //               item.skills.map((skill, i) => (
  //                 <Typography className={classes.cardText} key={i}>
  //                   {skill.name}: {skill.type}
  //                 </Typography>
  //               ))}
  //           </Grid>
  //           <Typography className={classes.cardText}>
  //             Last Poll Time:{' '}
  //             {moment(item.lastPollTime).format('DD/MM/YYYY HH:mm:ss')}
  //           </Typography>
  //         </Grid>
  //       </Grid>
  //     </div>
  //   </React.Fragment>
  // );
}

export default PersonLiveItem;
