import React from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';

export default function CheckboxField({
  input,
  meta,
  label,
  disabled,
  className,
}) {
  return (
    <FormControl className={className} error={meta.touched && meta.invalid}>
      <InputLabel shrink={true}>{label}</InputLabel>
      <Checkbox
        checked={Boolean(input.value)}
        onChange={(event) => input.onChange(event.target.checked)}
        style={{
          marginTop: 8,
          width: 24,
        }}
        disabled={Boolean(disabled)}
      />
      <FormHelperText>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  );
}
