import {
  FETCH_PLANS,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_FAILURE,
  FETCH_PLAN,
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAILURE,
  CREATE_PLAN,
  CREATE_PLAN_SUCCESS,
  CREATE_PLAN_FAILURE,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAILURE,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAILURE,
} from '../actions';

const INITIAL_STATE = {
  planNames: [],
  plan: null,
  isLoading: false,
  error: null,
};

export default function plansReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PLANS:
      return { ...state, isLoading: true, error: null };
    case FETCH_PLANS_SUCCESS:
      return {
        ...state,
        planNames: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_PLANS_FAILURE:
      return {
        ...state,
        planNames: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_PLAN:
      return { ...state, isLoading: true, error: null };
    case FETCH_PLAN_SUCCESS:
      return { ...state, plan: action.payload, isLoading: false, error: null };
    case FETCH_PLAN_FAILURE:
      return {
        ...state,
        plan: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_PLAN:
      return { ...state, isLoading: true, error: null };
    case UPDATE_PLAN_SUCCESS:
      const updatedPlans = state.planNames.filter(
        (plan) => plan.identifier !== action.payload.identifier
      );
      return {
        ...state,
        plan: action.payload,
        planNames: [...updatedPlans, action.payload],
        isLoading: false,
        error: null,
      };
    case UPDATE_PLAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_PLAN:
      return { ...state, isLoading: true, error: null };
    case CREATE_PLAN_SUCCESS:
      return {
        ...state,
        plan: action.payload,
        planNames: [...state.planNames, action.payload],
        isLoading: false,
        error: null,
      };
    case CREATE_PLAN_FAILURE:
      return {
        ...state,
        plan: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_PLAN:
      return { ...state, isLoading: true, error: null };
    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        plan: null,
        planNames: state.planNames.filter(
          (plan) => plan.identifier !== action.payload
        ),
        isLoading: false,
        error: null,
      };
    case DELETE_PLAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
