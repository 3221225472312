import {
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  Paper,
  Popover,
  Radio,
  Slider,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  AddCircle as AddIcon,
  Check as CheckIcon,
  RemoveCircle as RemoveIcon,
} from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import React, { Fragment, useState } from 'react';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  fieldAvatar: {
    marginTop: theme.spacing(2.5),
    marginRight: theme.spacing(0.5),
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 24,
    height: 24,
  },
  paper: {
    width: 144,
    padding: theme.spacing(1),
  },
  radio: {
    padding: theme.spacing(0.5),
  },
  avatars: {
    display: 'flex',
  },
  button: {
    marginTop: theme.spacing(2.5),
    padding: 0,
  },
}));

const hues = Object.keys(colors).slice(1);
const shades = [
  '900',
  '800',
  '700',
  '600',
  '500',
  '400',
  '300',
  '200',
  '100',
  '50',
  'A700',
  'A400',
  'A200',
  'A100',
];
const hueAndShade = Object.assign(
  {},
  ...[].concat(
    ...Object.entries(colors)
      .slice(1)
      .map((hue) =>
        Object.entries(hue[1]).map((shade) => ({
          [shade[1]]: { hue: hue[0], shade: shade[0] },
        }))
      )
  )
);
const grey = colors.grey[500];

function getArray(value, min) {
  if (Array.isArray(value)) {
    if (value.length < (min || 1)) {
      return _.fill(Array(min || 1), grey).map(
        (item, index) => value[index] || item
      );
    } else {
      return value;
    }
  } else {
    return _.fill(Array(min || 1), grey);
  }
}

export default function ColorsField({
  input,
  meta,
  label,
  min,
  max,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const theme = useTheme();

  const array = getArray(input.value, min);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChangeHue(event) {
    const {
      target: { value: hue },
    } = event;

    const index = anchorEl ? parseInt(anchorEl.id) : 0;
    const shade = hueAndShade[input.value[index] || grey].shade;
    const color = colors[hue][shade];

    input.onChange(
      array.map((item, itemIndex) => (itemIndex === index ? color : item))
    );
  }

  function handleChangeShade(event, shadeIndex) {
    const index = anchorEl ? parseInt(anchorEl.id) : 0;
    const hue = hueAndShade[input.value[index] || grey].hue;
    const color = colors[hue][shades[shadeIndex]];

    input.onChange(
      array.map((item, itemIndex) => (itemIndex === index ? color : item))
    );
  }

  function handleAdd() {
    input.onChange(array.concat(grey));
  }

  function handleRemove() {
    input.onChange(array.slice(0, -1));
  }

  const open = Boolean(anchorEl);
  const id = open ? 'color-popper' : undefined;

  return (
    <Fragment>
      <FormControl {...props}>
        <InputLabel shrink>{label}</InputLabel>
        <div className={classes.avatars}>
          {array.map((value, index) => (
            <Avatar
              key={index}
              id={index}
              className={classes.fieldAvatar}
              onClick={handleClick}
              style={{
                backgroundColor: value || grey,
                color: theme.palette.getContrastText(value || grey),
              }}
            >
              <Fragment />
            </Avatar>
          ))}
          {array.length > (min || 1) && (
            <IconButton
              className={classes.button}
              aria-label="Remove"
              title="Remove"
              onClick={handleRemove}
            >
              <RemoveIcon />
            </IconButton>
          )}
          {array.length < (max || 10) && (
            <IconButton
              className={classes.button}
              aria-label="Add"
              title="Add"
              onClick={handleAdd}
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
      </FormControl>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper className={classes.paper}>
          <div className={classes.sliderContainer}>
            <Slider
              title="Shade"
              className={classes.slider}
              value={shades.indexOf(
                hueAndShade[
                  input.value[anchorEl ? parseInt(anchorEl.id) : 0] || grey
                ].shade
              )}
              min={0}
              max={13}
              step={1}
              onChange={handleChangeShade}
            />
          </div>
          <div>
            {hues.map((hue) => {
              const index = anchorEl ? parseInt(anchorEl.id) : 0;
              const shade = hueAndShade[input.value[index] || grey].shade;
              const backgroundColor = colors[hue][shade];

              return (
                <Radio
                  title={_.startCase(hue)}
                  key={hue}
                  className={classes.radio}
                  checked={(input.value[index] || grey) === backgroundColor}
                  onChange={handleChangeHue}
                  value={hue}
                  icon={
                    <Avatar
                      className={classes.avatar}
                      style={{ backgroundColor }}
                    >
                      <Fragment />
                    </Avatar>
                  }
                  checkedIcon={
                    <Avatar
                      className={classes.avatar}
                      style={{ backgroundColor }}
                    >
                      <CheckIcon
                        style={{
                          color: theme.palette.getContrastText(backgroundColor),
                          fontSize: 16,
                        }}
                      />
                    </Avatar>
                  }
                />
              );
            })}
          </div>
        </Paper>
      </Popover>
    </Fragment>
  );
}
