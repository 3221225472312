import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import avatarForItem from './avatarUtility';

function EventLiveListItem({
  item,
  style,
  classes,
  onHoverItem,
  highlighted,
  onClick,
}) {
  const idType = { id: item.identifier, type: 'events' };
  const handleHoverEnter = () => onHoverItem(idType);
  const handleHoverLeave = () => onHoverItem({});
  const handleClick = () => onClick(idType);

  return (
    <ListItem
      className={highlighted ? classes.highlightListItem : null}
      style={style}
      button
      onClick={handleClick}
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
    >
      <ListItemAvatar>{avatarForItem(item, 'events')}</ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ variant: 'body2' }}
        primary={item.vehicle && item.vehicle.registrationNumber}
        secondary={moment(item.time).format('DD/MM/YYYY hh:mm')}
      />
    </ListItem>
  );
}

export default EventLiveListItem;
