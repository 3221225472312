import {
  AppBar,
  Paper,
  CircularProgress,
  Drawer,
  IconButton,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { FETCH_REPLAY } from '../../actions';
import ReplayControls from './ReplayControls';
import ReplayItemList from './ReplayItemList';
import ReplayMap from './ReplayMap';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 0,
  },
  content: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  drawerContent: {
    maxHeight: '80vh',
    overflow: 'scroll',
  },
  backButton: {
    marginLeft: -12,
    marginRight: 10,
  },
  itemsSection: {
    width: 300,
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mapSection: {
    width: 'calc(100% - 300px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mapCard: {
    height: 'calc(100vh - 125px)',
    margin: theme.spacing(1),
    marginTop: 0,
    minWidth: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mapContainer: {
    height: 'calc(100% - 45px)',
  },
  waitingPrompt: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
  toolbarIcon: {
    marginRight: 0,
  },
}));

export default function Replay() {
  const history = useHistory();
  const { id, type } = useParams();
  const dispatch = useDispatch();
  const frames = useSelector((state) => state.replay.frames, _.isEqual);
  const paths = useSelector((state) => state.replay.paths, _.isEqual);
  const [selectedItemIndex, setSelectedItemIndex] = useState({});
  const [hoveredItemIndex, setHoveredItemIndex] = useState({});
  const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
  const [followedItemIndexes, setFollowedItemIndexes] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    dispatch({
      type: FETCH_REPLAY,
      payload: { type, id },
    });
  }, [type, id, dispatch]);

  useEffect(() => {
    if (Number.isInteger(selectedItemIndex)) {
      setDrawerOpen(true);
    }
  }, [selectedItemIndex]);

  function handleOpen() {
    setDrawerOpen(true);
  }

  function handleClose() {
    setDrawerOpen(false);
  }

  function handleBackClick() {
    history.goBack();
  }

  const isLoading = useSelector((state) => state.replay.isLoading);

  function showLoader() {
    return (
      <div className={classes.waitingPrompt}>
        <CircularProgress
          className={classes.progress}
          size={80}
          thickness={6}
        />
        <Typography>Fetching replay...</Typography>
      </div>
    );
  }

  function renderReplayMap() {
    return (
      <ReplayMap
        paths={paths}
        featureCollections={
          frames.length > 0
            ? frames[currentFrameIndex].featureCollections
            : null
        }
        followedItemIndexes={followedItemIndexes}
        selectedItemIndex={selectedItemIndex}
        hoveredItemIndex={hoveredItemIndex}
        onSelectItem={setSelectedItemIndex}
        onHoverItem={setHoveredItemIndex}
      />
    );
  }

  function renderReplayControls() {
    return (
      <ReplayControls
        time={
          frames.length > 0
            ? frames[currentFrameIndex].time
            : new Date('1900-1-1')
        }
        frameCount={frames.length}
        currentFrameIndex={currentFrameIndex}
        onFrameChange={setCurrentFrameIndex}
      />
    );
  }

  function renderReplayItemList() {
    return (
      <ReplayItemList
        featureCollections={
          frames.length > 0
            ? frames[currentFrameIndex].featureCollections
            : null
        }
        followedItemIndexes={followedItemIndexes}
        selectedItemIndex={selectedItemIndex}
        hoveredItemIndex={hoveredItemIndex}
        onFollowedItemsChange={setFollowedItemIndexes}
        onSelectItem={setSelectedItemIndex}
        onHoverItem={setHoveredItemIndex}
      />
    );
  }

  function mapSection() {
    if (isLoading) {
      return <Paper className={classes.mapCard}>{showLoader()}</Paper>;
    } else if (isXs) {
      return (
        <Fragment>
          {renderReplayControls()}
          <div className={classes.mapContainer}>{renderReplayMap()}</div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Paper className={classes.mapCard}>{renderReplayMap()}</Paper>
          {renderReplayControls()}
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>IR3 | Replay | {id}</title>
      </Helmet>
      <AppBar position="static">
        <Toolbar variant="dense">
          <ListItem className={classes.title} component="div">
            <IconButton
              color="inherit"
              aria-label="Back"
              className={classes.backButton}
              onClick={handleBackClick}
            >
              <ArrowBackIcon />
            </IconButton>
            <ListItemText
              className={classes.title}
              primaryTypographyProps={{ color: 'inherit', noWrap: true }}
              secondaryTypographyProps={{ color: 'inherit', noWrap: true }}
              primary={id || ''}
              // secondary={replay ? replay.identifier : ''}
            />
            {isXs && (
              <IconButton
                color="inherit"
                aria-label="Details"
                className={classes.toolbarIcon}
                onClick={handleOpen}
              >
                <ViewListIcon />
              </IconButton>
            )}
          </ListItem>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        {!isXs && (
          <div className={classes.itemsSection}>
            {id ? renderReplayItemList() : showLoader()}
          </div>
        )}
        <div className={classes.mapSection}>{mapSection()}</div>
      </div>
      {isXs && (
        <Drawer anchor="bottom" open={drawerOpen} onClose={handleClose}>
          <div className={classes.drawerContent}>
            {id ? renderReplayItemList() : showLoader()}
          </div>
        </Drawer>
      )}
    </Fragment>
  );
}
