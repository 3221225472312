import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Assignment as AssignmentIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Form } from 'react-final-form';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import {
  CREATE_PLAN,
  DELETE_PLAN,
  FETCH_PLAN,
  FETCH_PLAN_SUCCESS,
  UPDATE_PLAN,
  FETCH_BRIEFS,
} from '../../actions';
import { doesIdExist } from '../../apis/utilities';
import { planSubtypes } from '../../data/constants';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  TextField,
  SelectField,
  DateTimeField,
  ContentField,
  TypesField,
  SelectMultipleField,
  GeometryField,
  required,
  requiredDateBefore,
  requiredDateAfter,
} from '../fields';
import { RouteLeavingGuard } from '../controls';

const { maxUploadSize, personGroups } = window.config;

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  editor: {
    width: '100%',
    marginBottom: 16,
  },
  delete: {
    color: theme.palette.error.main,
  },
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  edits: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  briefs: {
    width: '100%',
  },
}));

const initialValues = {
  type: 'Plan',
};

export default function Plan() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.plans.plan, _.isEqual);
  const error = useSelector((state) => state.plans.error);
  const briefs = useSelector((state) => state.briefs.briefNames, _.isEqual);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_PLAN_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_PLAN,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_BRIEFS,
    });
  }, [id, dispatch]);

  function handleDelete() {
    if (plan) {
      dispatch({
        type: DELETE_PLAN,
        payload: encodeURIComponent(plan.identifier),
      });
    }
  }

  async function validate(values) {
    const errors = {};

    if (!plan) {
      if (values.identifier) {
        if (values.identifier === 'new') {
          errors.identifier = 'Invalid';
        }
        const exists = await doesIdExist('plans', values.identifier);
        if (exists) {
          errors.identifier = 'Exists';
        }
      }
    }

    const blob = new Blob([JSON.stringify(values)], {
      type: 'application/json',
    });

    if (blob.size > maxUploadSize) {
      snackbar.notify('warning', 'Unable to save, content greater than 5MB');
      errors.description = 'Content greater than 5MB';
    }

    // start/end validation
    errors.startTime = requiredDateBefore(values.endTime)(values.startTime);
    errors.endTime = requiredDateAfter(values.startTime)(values.endTime);

    return errors;
  }

  function onSubmit(values) {
    if (plan) {
      dispatch({
        type: UPDATE_PLAN,
        payload: values,
      });
    } else {
      dispatch({
        type: CREATE_PLAN,
        payload: values,
      });
    }
  }

  return (
    <Form
      initialValues={plan || initialValues}
      validate={validate}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>IR3 | Plan{values.title ? ` | ${values.title}` : ''}</title>
          </Helmet>
          <Paper className={classes.paper}>
            <CardHeader
              avatar={
                <Avatar aria-label="Collection" className={classes.avatar}>
                  <AssignmentIcon />
                </Avatar>
              }
              title={values.title}
              subheader={values.identifier}
            />
            <CardContent className={classes.cardContent}>
              {values.created && (
                <div className={classes.edits}>
                  <Typography variant="caption">
                    {`Created by ${values.created.userId} ${moment(
                      values.created.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </div>
              )}
              {values.lastEdit && (
                <div className={classes.edits}>
                  <Typography variant="caption">
                    {`Last edited by ${values.lastEdit.userId} ${moment(
                      values.lastEdit.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </div>
              )}
              <List>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="title"
                      component={TextField}
                      label="Title"
                      className={classes.textField}
                      validate={required}
                    />
                    <Field
                      name="identifier"
                      component={TextField}
                      label="Identifier"
                      className={classes.textField}
                      disabled={plan && 'identifier' in plan}
                    />
                    <Field
                      name="subtype"
                      component={SelectField}
                      label="Type"
                      className={classes.textField}
                      validate={required}
                      values={Object.entries(planSubtypes).map((item) => {
                        return { value: item[0], label: item[1] };
                      })}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Description</ListSubheader>
                <ListItem>
                  <Field
                    name="description"
                    component={ContentField}
                    className={classes.editor}
                  />
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Active Time Period</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="startTime"
                      component={DateTimeField}
                      label="Start Time"
                      className={classes.textField}
                      maxDate={values.endTime || '2100-01-01'}
                    />
                    <Field
                      name="endTime"
                      component={DateTimeField}
                      label="End Time"
                      className={classes.textField}
                      minDate={values.startTime || '1900-01-01'}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Groups & Areas</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="areas"
                      types={personGroups}
                      component={TypesField}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Briefs</ListSubheader>
                <ListItem>
                  <div className={classes.briefs}>
                    <Field
                      name="briefs"
                      fullWidth
                      component={SelectMultipleField}
                      suggestions={briefs.map((brief) => ({
                        label: brief.title,
                        value: brief.identifier,
                      }))}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Boundary</ListSubheader>
                <Field
                  name="boundary"
                  component={GeometryField}
                  geoType="Polygon"
                  validate={required}
                />
              </List>
            </CardContent>
            <CardActions disableSpacing>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={dirty || submitting}
                onClick={() => history.push(`${id}/contents`)}
              >
                Contents
              </Button>
              <Button
                className={classes.delete}
                onClick={() => setDeleteOpen(true)}
                disabled={plan === null}
              >
                Delete
              </Button>
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.title || values.identifier}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
          <RouteLeavingGuard when={dirty} />
        </form>
      )}
    />
  );
}
