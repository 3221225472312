import React from 'react';
import LiveListItem from './LiveListItem';
import useLazyLoadIncident from './incidentUtility';

function IncidentLiveListItem(props) {
  const { item } = props;
  const incident = useLazyLoadIncident(item.id);

  return (
    <LiveListItem
      type="incidents"
      primaryPath="primary"
      secondaryPath="id"
      {...props}
      item={incident}
      hideFollow={!incident.point}
    />
  );
}

export default IncidentLiveListItem;
