import { WMTSCapabilities } from 'ol/format';
import {
  Group as LayerGroup,
  Tile as TileLayer,
  Image as ImageLayer,
} from 'ol/layer';
import {
  BingMaps as BingMapsSource,
  WMTS,
  XYZ,
  ImageWMS,
  TileJSON,
} from 'ol/source';
import { optionsFromCapabilities } from 'ol/source/WMTS';
import { Point } from 'ol/geom';
import {
  Circle,
  Fill,
  Icon,
  RegularShape,
  Stroke,
  Style,
  Text,
  AtlasManager,
} from 'ol/style';
import GeometryCollection from 'ol/geom/GeometryCollection';
import _ from 'lodash';
import {
  featureSubtypeColours,
  featureSubtypeGlyphs,
  positionGlyph,
  replayTypeGlyphs,
  mapGlyphsByTypeAndSubtype,
  statusIconColoursByType,
} from './data/constants';
import resourceShape from './img/resource.png';
import resourceShapeNoDir from './img/resource_no_dir.png';
import db from './data/db';

const {
  useReducedResourceInformation,
  useWebGL,
  baseMap,
  wmtsGetCapsUrl,
  wmtsMatrixSet,
  wmsZoom,
  wmsParams,
  wmsUrl,
  wmtsLayer,
  azureMapsKey,
  bingMapsKey,
  mapTiler,
  osmUrl,
  liveOptions,
} = window.config;

const followColour = liveOptions.followColor || '#1992F0BB'; // || 'rgb(0,0,255)';

var atlasManager = new AtlasManager({ initialSize: 4096 });
let mergedCache = {};

// given an image source, colour and scale it in a promise returning an Image object
const colorAndScaleImage = (source, color, scale = 1) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = (e) => {
      return reject(new Error('Could not load image', e));
    };
    img.onload = () => {
      const canvas = window.document.createElement('canvas');
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      const ctx = canvas.getContext('2d');

      ctx.fillStyle = color;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // fill it with desired colour then only set the non-transparent pixels
      // in the following drawImage, taken from
      // https://stackoverflow.com/questions/45706829/change-color-image-in-canvas
      ctx.globalCompositeOperation = 'destination-in';

      ctx.scale(scale, scale);
      ctx.drawImage(img, 0, 0);

      const coloredImg = new Image();
      coloredImg.onerror = () => reject(new Error('Could not load image'));
      coloredImg.onload = () => resolve(coloredImg);
      const dataUrl = canvas.toDataURL('image/png');
      // console.log(dataUrl);

      // when this is loaded it will resolve the promise
      coloredImg.src = dataUrl;
    };

    // this will kick off img.onload to color and scale
    img.src = source;
  });
};

// the smaller this is, the more icons there will be,
// for an accuracy of 2 degrees there will be 360/2 === 180 icons
// other e.g.s accuracy of 1: 360 icons, accuracy of 6: 60 icons, accuracy of 4: 90 icons
const rotationAccuracy = 6;
const roundRotation = (degrees) =>
  (Math.round(degrees / rotationAccuracy) * rotationAccuracy) % 360;

// this merges images together into an icon and adds to the atlasManager
// e.g. rotate a background pointer 60 degrees & merge a (non-rotated) vehicle icon on top
const mergeImages = (
  id,
  sources = [],
  colors = [],
  scales = [],
  rotationDegrees = null,
  immediate = true
) => {
  if (sources.length !== colors.length || colors.length !== scales.length) {
    throw new Error('Mismatched image source, colour and scale arrays');
  }

  if (id in mergedCache) {
    if (rotationDegrees !== null && mergedCache[id] !== null) {
      rotationDegrees = roundRotation(rotationDegrees);
      if (rotationDegrees in mergedCache[id]) {
        return mergedCache[id][rotationDegrees];
      } else {
        return mergedCache[id];
      }
    } else {
      return mergedCache[id];
    }
  }

  // don't let other requests spawn a new merge
  mergedCache[id] = null;

  const promise = new Promise((resolve) => {
    const imagePromises = [];
    for (var i = 0; i < sources.length; i++) {
      const promise = colorAndScaleImage(sources[i], colors[i], scales[i]);
      imagePromises.push(promise);
    }

    // get canvas context
    const canvas = window.document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;
    const ctx = canvas.getContext('2d');
    ctx.globalCompositeOperation = 'source-over';

    const centreImage = (image) =>
      ctx.drawImage(
        image,
        (canvas.width - image.width) / 2,
        (canvas.height - image.height) / 2
      );

    // when all images have loaded
    resolve(
      Promise.all(imagePromises).then((images) => {
        let imgData = null;
        if (rotationDegrees === null) {
          // write each image sequentially to the canvas (background then foreground typically)
          images.forEach((image) => {
            centreImage(image);
          });

          // use the merged images as the source for a map icon
          imgData = canvas.toDataURL('image/png');

          // store it in our own cache and in the atlasManager
          mergedCache[id] = new Icon({
            opacity: 1,
            src: imgData,
            atlasManager,
          });
        } else {
          let rotMap = {};
          // same as above but generate and cache 360/rotationAccuracy versions
          // to cover all our rotation needs.
          for (let r = 0; r < 360; r += rotationAccuracy) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < images.length; i++) {
              let image = images[i];
              // rotate the background images not the foreground glyph
              if (i !== images.length - 1) {
                ctx.save();

                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((r * Math.PI) / 180);
                ctx.translate(-canvas.width / 2, -canvas.height / 2);

                centreImage(image);
                ctx.restore();
              } else {
                centreImage(image);
              }
            }

            imgData = canvas.toDataURL('image/png');
            rotMap[r] = new Icon({
              opacity: 1,
              src: imgData,
              atlasManager,
            });
          }

          // this cache element is a dictionary of all icons
          mergedCache[id] = rotMap;
        }

        return imgData;
      })
    );
  });

  if (immediate) {
    // kick off the promise, it will cache the result for another render
    promise.then(() => {}); //console.log('completed ' + img));

    // but we don't have anything right at this moment
    return null;
  } else {
    return promise;
  }
};

export const boundaryStyle = new Style({
  fill: new Fill({
    color: 'rgba(255,255,255,0.5)',
  }),
  stroke: new Stroke({
    color: '#ffffff',
    width: 2,
  }),
  zIndex: 0,
});

export const positionStyle = [
  new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: 'rgb(118,255,3)',
      }),
    }),
  }),
  new Style({
    image: new Icon({
      src: positionGlyph,
      color: 'rgb(0,0,0)',
      scale: 0.5,
    }),
  }),
];

export const confidenceRadiusStyle = new Style({
  fill: new Fill({
    color: 'rgba(118,255,3,0.5)',
  }),
  stroke: new Stroke({
    color: 'rgb(118,255,3)',
    width: 2,
  }),
});

export function getStyle(layer, feature, showLabels) {
  if (showLabels) {
    staticStyle
      .getText()
      .setText(feature.get('number') || feature.get('identifier'));
  } else {
    staticStyle.getText().setText('');
  }

  if (feature.getGeometry() && feature.getGeometry().getType() === 'Point') {
    staticStyle.getText().setTextAlign('left');
    staticStyle.getText().setOffsetX(12);
  } else {
    staticStyle.getText().setTextAlign('center');
    staticStyle.getText().setOffsetX(0);
  }

  const subtypeStyle = getSubtypeStyle(layer, feature);

  switch (feature.getGeometry() && feature.getGeometry().getType()) {
    case 'Polygon':
      staticStyle.setZIndex(1);
      break;
    case 'Linestring':
      staticStyle.setZIndex(2);
      break;
    case 'Point':
      staticStyle.setZIndex(3);
      break;
    default:
      staticStyle.setZIndex(0);
  }

  return [staticStyle, ...subtypeStyle];
}

// blank one used while mergeImages is working through the backlog
var blankIcon = new Icon({
  opacity: 1,
  src:
    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
  atlasManager,
});

function addTransparency(hexColour, hexTransparency) {
  if (hexColour.startsWith('rgb')) {
    const parts = hexColour.split(/[\s,()]+/).filter(Boolean);
    return `rgba(${parts.splice(1, 3).toString()}, ${
      parseInt(hexTransparency, 16) / 256
    })`;
  }

  // change #abc to #aabbcc
  if (hexColour.length === 4) {
    hexColour = hexColour
      .split('')
      .map((c, i) => (i > 0 ? c + c : c))
      .join('');
  }

  if (hexColour.length === 7 && hexTransparency.length === 2) {
    return hexColour + hexTransparency;
  } else {
    throw new Error('Invalid hex colour in mapStyles icon colour config');
  }
}

const statusFillColoursByType = Object.fromEntries(
  Object.keys(statusIconColoursByType).map((type) => [
    type,
    Object.fromEntries(
      Object.keys(statusIconColoursByType[type]).map((status) => [
        status,
        [
          statusIconColoursByType[type][status][0],
          addTransparency(statusIconColoursByType[type][status][1], '40'),
        ],
      ])
    ),
  ])
);

export function getStatusIconColours(type, status) {
  return (
    (statusIconColoursByType[type] &&
      (statusIconColoursByType[type][status] ||
        statusIconColoursByType[type].default)) ||
    statusIconColoursByType.default[status] ||
    statusIconColoursByType.default.default
  );
}

function getStatusFillColours(type, status) {
  return (
    (statusFillColoursByType[type] &&
      (statusFillColoursByType[type][status] ||
        statusFillColoursByType[type].default)) ||
    statusFillColoursByType.default[status] ||
    statusFillColoursByType.default.default
  );
}

const layerColours = {
  default: {
    background: 'rgb(0,0,0)',
    icon: 'rgb(255,255,255)',
    fill: 'rgba(0,0,0,0.5)',
    scale: 1,
  },
  select: {
    background: 'rgb(33,150,243)',
    //inactiveBackground: '#01579b',
    //emergencyBackground: '#ffbf00',
    icon: 'rgb(255,255,255)',
    fill: 'rgba(33,150,243,0.5)',
    scale: 1,
  },
  hover: {
    background: 'rgb(255,235,59)',
    //inactiveBackground: 'rgb(255,235,59)',
    //emergencyBackground: '#ffbf00',
    icon: 'rgb(0,0,0)',
    fill: 'rgba(255,235,59,0.5)',
    scale: 1,
  },
  path: {
    background: 'rgba(0,0,0,0.25)',
  },
};

const mergeImagesWrapper = (
  type,
  subtype,
  status,
  filteredIn,
  followed,
  rotation = null,
  immediate = true
) => {
  // outline should be glyphColour
  let [outlineColour, backgroundColour, glyphColour] = getStatusIconColours(
    type,
    status
  );

  if (liveOptions.mapFollowOverridesOutlineAndGlyph && followed) {
    glyphColour = followColour;
  }

  const selectionAsBorder = false; // liveOptions && liveOptions.mapSelectionAsBorder;
  const selectionColour = undefined; // no selection as border, selection overwrites status
  const borderOutline = liveOptions.mapFollowBorder && followed;
  // !!liveOptions?.mapIconOutline &&
  // selectionAsBorder &&
  // selectionColour &&
  // filteredIn;
  const outline = true; //liveOptions && liveOptions.mapIconOutline && filteredIn;

  const numberOfBackgrounds =
    (borderOutline ? 1 : 0) +
    (selectionAsBorder && selectionColour ? 1 : 0) +
    (outline ? 1 : 0) +
    1; // need at least one for background to icon/glyph

  const outlineRatio = 0.01;
  const borderRatio = 0.01 * (liveOptions.mapFollowBorderSize || 1);
  const selectionBorderRatio = 0.02;

  // base background scale will be built up as outline, border and borderOutline added
  let backgroundScale =
    0.12 -
    (outline ? outlineRatio : 0) -
    (borderOutline ? borderRatio : 0) -
    (selectionAsBorder ? selectionBorderRatio : 0);

  const background = rotation !== null ? resourceShape : resourceShapeNoDir;

  subtype = subtype || 'default'; // to makes sure preload and getStyle are consistent

  const baseGlyph = mapGlyphsByTypeAndSubtype[type];
  const scale = liveOptions.mapFollowBorderSuperSize ? 0.5 : 1;

  return mergeImages(
    // type + filteredIn + statusColour + selectionColour, // id
    type + subtype + status + !!filteredIn + !!followed, // id
    [
      // img sources for back and foreground
      ...Array(numberOfBackgrounds).fill(background),
      // replayTypeGlyphs[type]],
      baseGlyph && (baseGlyph[subtype] || baseGlyph['default']),
    ], // sources
    [
      ...[
        borderOutline && followColour, //glyphColour,
        selectionAsBorder && selectionColour,
        // maybe outline && (followed ? 'rgba(33,150,243,1.0)' : glyphColour),
        outline && outlineColour,
        selectionAsBorder
          ? backgroundColour
          : selectionColour || backgroundColour,
      ].filter(Boolean),
      selectionAsBorder ? 'rgba(255,255,255,1.0)' : glyphColour,
    ], // colours for back and foreground
    // this is backwards so we can build it up from the inside out
    [
      1 * scale, // the icon scale
      backgroundScale * scale,
      // maybe outline && (backgroundScale += followed ? 0.04 : 0.01),
      outline && (backgroundScale += outlineRatio) * scale,
      selectionAsBorder &&
        selectionColour &&
        (backgroundScale += selectionBorderRatio) * scale,
      borderOutline &&
        (backgroundScale += borderRatio) *
          scale *
          (liveOptions.mapFollowBorderSuperSize ? 1.4 : 1),
    ]
      .filter(Boolean)
      .reverse(),
    rotation,
    immediate
  );
};

// a helper to transform the db/ol cache types
function transform({ source, hasRotationsTest, action }) {
  let result = {};

  Object.keys(source).forEach((key) => {
    if (source[key]) {
      // for items with rotations they have a dictionary for each rotation
      // e.g. { 0: Icon ..., 6: Icon ..., ... }
      if (hasRotationsTest(source[key])) {
        result[key] = {};

        // if it does have rotations we'll have to recreate the dictionary
        // performing the transform action on each one
        Object.keys(source[key]).forEach((rotation) => {
          result[key][rotation] = action(source[key][rotation]);
        });
      } else {
        result[key] = action(source[key]);
      }
    } else {
      result[key] = null;
    }
  });

  return result;
}

// it helps to load all the possible images into the atlasManager before rendering
// otherwise a refresh with a new icon will be held up while mergeImages is generating
// the new Icon(s)
var rotatingTypes = ['vehicle'];
// var inactiveTypes = ['vehicle'];
// var inactiveKeys = { vehicle: 'ignitionOn' };
// var statusTypes = {
//   'vehicle': {
//     'active': 'background',
//     'inactive': 'inactiveBackground',
//     'emergency': 'emergencyBackground'
//   }
// }
const CACHED_OPTIONS_DATATYPE = 'cachedOptions';
const MAP_ICONS_DATATYPE = 'mapIcons';
export const preloadLiveIcons = async ({
  authorisedTypes,
  allowRotations = true,
}) => {
  // is it in the IndexedDB and is it relevant?
  const cachedOptions = await db.live?.get(CACHED_OPTIONS_DATATYPE);
  if (cachedOptions && _.isEqual(cachedOptions.options, liveOptions)) {
    const saved = await db.live.get(MAP_ICONS_DATATYPE);

    // need to translate the dataUrls to icons
    mergedCache = transform({
      source: saved.icons,
      hasRotationsTest: (i) => typeof i !== 'string',
      action: (src) => new Icon({ src, atlasManager }),
    });
  } else {
    // otherwise generate it and save
    let possibleTypes = Object.keys(mapGlyphsByTypeAndSubtype).filter(
      (type) => !authorisedTypes || authorisedTypes.includes(type)
    );
    let possibleFiltered = [true, false];
    let possibleFollowed = [true, false];
    let promises = [];

    for (let t = 0; t < possibleTypes.length; t++) {
      let type = possibleTypes[t];

      for (let f = 0; f < possibleFiltered.length; f++) {
        for (let l = 0; l < possibleFollowed.length; l++) {
          let possibleStates =
            type in statusIconColoursByType
              ? Object.keys(statusIconColoursByType[type])
              : [];
          ['default', 'hover', 'select'].forEach((commonState) => {
            if (!possibleStates.includes(commonState)) {
              possibleStates.push(commonState);
            }
          });
          let possibleSubtypes = Object.keys(mapGlyphsByTypeAndSubtype[type]);
          for (let s = 0; s < possibleStates.length; s++) {
            for (let b = 0; b < possibleSubtypes.length; b++) {
              let subtype = possibleSubtypes[b];
              let filtered = possibleFiltered[f];
              let followed = possibleFollowed[l];
              let status = possibleStates[s];
              let needsRotation =
                allowRotations && rotatingTypes.includes(type);

              promises.push(
                mergeImagesWrapper(
                  type,
                  subtype,
                  status,
                  filtered,
                  followed,
                  needsRotation ? 0 : null,
                  false // I don't need it immediately, the promise will do fine
                )
              );
            }
          }
        }
      }
    }

    await Promise.all(promises);

    // Dexie can't serialise <img> so get the source instead
    const srcCache = transform({
      source: mergedCache,
      hasRotationsTest: (i) => i[0], // rotation dictionaries have { 0: ..., 6: ... }
      action: (icon) => icon.getSrc(),
    });

    db.live.put({ dataType: CACHED_OPTIONS_DATATYPE, options: liveOptions });
    db.live.put({ dataType: MAP_ICONS_DATATYPE, icons: srcCache });
  }
};

function getPointOfGeometryCollection(geometries, fprops) {
  for (let i = 0; i < geometries.length; i++) {
    if (geometries[i].getType() === 'Point') {
      return geometries[i];
    }
  }

  return new Point(fprops['clusterPoint']);
}

function excludePointOfGeometryCollection(geometries) {
  const nonPoints = [];
  for (let i = 0; i < geometries.length; i++) {
    if (geometries[i].getType() !== 'Point') {
      nonPoints.push(geometries[i]);
    }
  }

  return new GeometryCollection(nonPoints);
}

export const getLiveStyle = ({
  layer,
  feature,
  showLabels,
  // zoom,
  iconsOnly,
  polygonsOnly,
  polygonIsSelected,
  allowRotations,
  showStale,
}) => {
  const cluster = feature.get('features');
  if (cluster) {
    feature = _.maxBy(cluster, (f) => f.getProperties().zIndex || 0);
  }

  const fprops = feature.getProperties();
  const status = fprops.status;

  if (!showStale && status === 'stale') {
    return null;
  }

  const hoverLayer = layer === 'hover';
  const selectLayer = layer === 'select';
  const hoverOrSelectLayer = hoverLayer || selectLayer;

  if (fprops.type === 'plan' && fprops.subtype === 'ER') {
    return getDirectedPathStyle(feature, layer);
  }

  let geometry = feature.getGeometry();
  if (geometry && geometry.getType() === 'GeometryCollection') {
    if (iconsOnly || hoverOrSelectLayer) {
      geometry = getPointOfGeometryCollection(geometry.getGeometries(), fprops);
    } else if (polygonsOnly) {
      geometry = excludePointOfGeometryCollection(geometry.getGeometries());
    }
  }

  // always true now filtering is done in separate layer
  const { filteredIn, followed } = fprops;

  const type = 'type' in fprops ? fprops.type : 'default';

  const typeLabels = {
    vehicle: 'registrationNumber',
    person: 'collarNumber',
    location: 'name',
    event: 'code',
    incident: 'number',
    plan: 'id',
    //objective: 'id'
  };

  var offsetX = ['vehicle', 'person'].includes(type) ? 16 : 12;

  // if (type === 'user') {
  //   const userStyle = positionStyle;
  //   userStyle[0].setZIndex(4);
  //   userStyle[1].setZIndex(5);
  //   return userStyle;
  // }

  const heading =
    allowRotations && 'headingDegrees' in fprops
      ? fprops.headingDegrees || 0
      : null;
  var mergedIcon = mergeImagesWrapper(
    type,
    fprops['subtype'],
    layer === 'default' ? status || layer : layer, // layer overwrites status colour
    filteredIn,
    followed,
    heading
  );

  let z = fprops['zIndex'] || 10;
  var icon = mergedIcon || blankIcon;
  showLabels =
    showLabels ||
    hoverOrSelectLayer ||
    (liveOptions.mapFollowLabel && followed);

  const opacity = fprops['opacity'];
  if (opacity) {
    icon.setOpacity(opacity);
  }

  // * 0.5 because icons are 64x64 to give 32x32 normally and allow scaling up
  // to max of 64x64 e.g. in hover or select style layerColours scale is 2x
  // the (0.3 + 0.7*zoom/20) bit shrinks the icons as you zoom out min of
  // 0.3 ish to max of 1.1 ish (23 zoom levels)
  let scale =
    layerColours[layer].scale *
    (liveOptions.mapFollowBorderSuperSize ? 1 : 0.5); // * (0.3 + (0.7 * zoom) / 20);
  if (type === 'person') {
    scale *= 0.9; // officers are supposed to be smaller than vehicles
  }
  icon.setScale(scale);

  let [strokeColour, fillColour] = getStatusFillColours(
    type,
    status || fprops['subtype']
  );

  if (fprops.type === 'plan') {
    if (fprops.subtype) {
      if (selectLayer) {
        strokeColour = 'rgb(33,150,243)';
        fillColour = 'rgba(33,150,243,0.5)';
      } else if (hoverLayer) {
        strokeColour = 'rgb(255,235,59)';
        fillColour = 'rgba(255,235,59,0.5)';
      } else if (polygonIsSelected) {
        return null; // don't show the polygon underneath
      }
    } else if (polygonsOnly) {
      strokeColour = 'rgb(0,0,0)';
    }
  } else if (polygonIsSelected) {
    strokeColour = 'rgb(255,255,255)';
    fillColour = 'rgba(255,255,255,.4)';
  }

  return new Style({
    image: icon,
    stroke: new Stroke({
      color: strokeColour,
      width: 2,
    }),
    fill: new Fill({
      color: fillColour,
    }),
    text: new Text({
      font: '12px Roboto,sans-serif',
      fill: new Fill({
        // color: false,
        color: liveOptions.mapFollowLabel && followed ? followColour : false,
        // color: (followed ? 'rgba(255,255,255,1.0)' : false),
      }),
      stroke: new Stroke({
        color: 'rgba(255,255,255,1.0)',
        // color: (followed ? 'rgba(255,255,255,1.0)' : false),
        // color: (followed ? 'rgba(0,0,255,1.0)' : 'rgba(255,255,255,1.0)'),
        width: 3,
      }),
      text: showLabels
        ? fprops['label'] || fprops[typeLabels[type]] || fprops.id
        : '',
      textAlign: 'left',
      offsetX: offsetX,
    }),
    zIndex: z,
    geometry,
  });
};

export function getReplayStyle(layer, feature, showLabels) {
  const type =
    typeof feature.getProperties().type === 'string'
      ? feature.getProperties().type
      : 'default';
  const layerColours = {
    default: {
      background: 'rgb(0,0,0)',
      icon: 'rgb(255,255,255)',
      fill: 'rgba(0,0,0,0.5)',
    },
    select: {
      background: 'rgb(33,150,243)',
      icon: 'rgb(255,255,255)',
      fill: 'rgba(33,150,243,0.5)',
    },
    hover: {
      background: 'rgb(255,235,59)',
      icon: 'rgb(0,0,0)',
      fill: 'rgba(255,235,59,0.5)',
    },
    path: {
      background: 'rgba(0,0,0,0.75)',
    },
  };
  const typeLabels = useReducedResourceInformation
    ? {
        vehicle: 'fleetNumber',
        person: 'code',
        location: 'name',
        event: 'code',
        incident: 'number',
      }
    : {
        vehicle: 'registrationNumber',
        person: 'collarNumber',
        location: 'name',
        event: 'code',
        incident: 'number',
      };

  switch (type) {
    case 'user':
      const userStyle = positionStyle;
      userStyle[0].setZIndex(4);
      userStyle[1].setZIndex(5);
      return userStyle;
    case 'vehicle':
      const heading =
        typeof feature.getProperties().type === 'string'
          ? feature.getProperties().headingDegrees
          : 0;

      return [
        new Style({
          image: new Icon({
            opacity: 1,
            src: resourceShape,
            scale: 0.05,
            color: layerColours[layer].background,
            rotation: (heading * Math.PI) / 180.0,
          }),
          text: new Text({
            font: '12px Roboto,sans-serif',
            fill: new Fill({
              color: '#00000',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3,
            }),
            text: showLabels ? feature.getProperties()[typeLabels[type]] : '',
            textAlign: 'left',
            offsetX: 16,
          }),
        }),
        new Style({
          image: new Icon({
            src: replayTypeGlyphs[type],
            color: layerColours[layer].icon,
            scale: 0.5,
          }),
        }),
      ];
    case 'person':
      return [
        new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: layerColours[layer].background,
            }),
          }),
          text: new Text({
            font: '12px Roboto,sans-serif',
            fill: new Fill({
              color: '#00000',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3,
            }),
            text: showLabels ? feature.getProperties()[typeLabels[type]] : '',
            textAlign: 'left',
            offsetX: 16,
          }),
        }),
        new Style({
          image: new Icon({
            src: replayTypeGlyphs[type],
            color: layerColours[layer].icon,
            scale: 0.5,
          }),
        }),
      ];
    case 'incident':
    case 'event':
      return [
        new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: layerColours[layer].background,
            }),
          }),
          text: new Text({
            font: '12px Roboto,sans-serif',
            fill: new Fill({
              color: '#00000',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3,
            }),
            text: showLabels ? feature.getProperties()[typeLabels[type]] : '',
            textAlign: 'left',
            offsetX: 12,
          }),
        }),
        new Style({
          image: new Icon({
            src: replayTypeGlyphs[type],
            color: layerColours[layer].icon,
            scale: 0.5,
          }),
        }),
      ];
    case 'location':
      return [
        new Style({
          fill: new Fill({
            color: layerColours[layer].fill,
          }),
          stroke: new Stroke({
            color: layerColours[layer].background,
            width: 2,
          }),
        }),
        new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: layerColours[layer].background,
            }),
          }),
          text: new Text({
            font: '12px Roboto,sans-serif',
            fill: new Fill({
              color: '#00000',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3,
            }),
            text: showLabels ? feature.getProperties()[typeLabels[type]] : '',
            textAlign: 'left',
            offsetX: 12,
          }),
          geometry: (feature) => feature.getGeometry().getInteriorPoint(),
        }),
        new Style({
          image: new Icon({
            src: replayTypeGlyphs[type],
            color: layerColours[layer].icon,
            scale: 0.5,
          }),
          geometry: (feature) => feature.getGeometry().getInteriorPoint(),
        }),
      ];
    default:
      return [
        new Style({
          fill: new Fill({
            color: layerColours[layer].fill,
          }),
          stroke: new Stroke({
            color: layerColours[layer].background,
            width: 2,
          }),
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: layerColours[layer].background,
            }),
          }),
          text: new Text({
            font: '12px Roboto,sans-serif',
            fill: new Fill({
              color: '#00000',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3,
            }),
            text: showLabels ? feature.getProperties()[typeLabels[type]] : '',
            textAlign: 'left',
            offsetX: 12,
          }),
        }),
      ];
  }
}

function getSubtypeStyle(layer, feature) {
  const type =
    typeof feature.getProperties().type === 'string'
      ? feature.getProperties().type
      : 'Marker';
  const subtype =
    feature.getProperties().subtype ||
    feature.getProperties().typeId ||
    'default';
  const styles = {
    default: featureSubtypeStyles,
    select: featureSelectSubtypeStyles,
    hover: featureHoverSubtypeStyles,
  };

  switch (type) {
    case 'Perimeter':
      return [styles[layer][subtype] || styles[layer].default];
    case 'Path':
      return subtype === 'ER'
        ? getDirectedPathStyle(feature, layer)
        : subtype !== 'features'
        ? [styles[layer]['ER']]
        : [styles[layer].default];
    case 'Marker':
      return [styles[layer].markers, styles[layer][subtype]];
    case 'Visit':
    case 'Patrol':
      return [styles[layer].objectives];
    default:
      return [styles[layer].default];
  }
}

export function getDirectedPathStyle(feature, layer) {
  const geometry = feature.getGeometry();
  const coordinates = geometry.getCoordinates();
  const lineStyles = {
    default: featureSubtypeStyles[feature.getProperties().subtype || 'default'],
    hover:
      featureHoverSubtypeStyles[feature.getProperties().subtype || 'default'],
    select:
      featureSelectSubtypeStyles[feature.getProperties().subtype || 'default'],
  };
  const lineStyle = lineStyles[layer];
  lineStyle.setGeometry(geometry);
  let styles = [lineStyle];

  const startStyle = new Style({
    image: new Circle({
      radius: 5,
      fill: new Fill({
        color: 'green',
      }),
    }),
    geometry: new Point(coordinates[0]),
    // zIndex: schema.zindex + 1 || 101
  });

  styles.push(startStyle);

  const endStyle = new Style({
    image: new Circle({
      radius: 5,
      fill: new Fill({
        color: 'red',
      }),
    }),
    geometry: new Point(coordinates[coordinates.length - 1]),
    // zIndex: schema.zindex + 1 || 101
  });

  styles.push(endStyle);

  let midCoords = [];

  coordinates.forEach((coordinate, index) => {
    if (index > 0) {
      const c1 = coordinates[index - 1];
      const c2 = coordinate;

      const dx = c2[0] - c1[0];
      const dy = c2[1] - c1[1];

      const a = Math.atan2(dy, dx) * -1;

      midCoords.push([(c1[0] + c2[0]) / 2, (c1[1] + c2[1]) / 2, a]);
    }
  });

  midCoords.forEach((coordinate) => {
    styles.push(
      new Style({
        image: new RegularShape({
          fill: new Fill({ color: '#000000' }),
          points: 3,
          radius: 6,
          rotation: coordinate[2],
          angle: Math.PI / 2,
        }),
        geometry: new Point([coordinate[0], coordinate[1]]),
        // zIndex: schema.zindex + 1 || 101
      })
    );
  });

  return styles;
}

const featureSubtypeStyles = {
  PA: new Style({
    fill: new Fill({
      color: featureSubtypeColours.perimeters.PA.fill,
    }),
    stroke: new Stroke({
      color: featureSubtypeColours.perimeters.PA.stroke,
      width: 2,
    }),
  }),
  MB: new Style({
    fill: new Fill({
      color: featureSubtypeColours.perimeters.MB.fill,
    }),
    stroke: new Stroke({
      color: featureSubtypeColours.perimeters.MB.stroke,
      width: 2,
    }),
  }),
  OL: new Style({
    fill: new Fill({
      color: featureSubtypeColours.perimeters.OL.fill,
    }),
    stroke: new Stroke({
      color: featureSubtypeColours.perimeters.OL.stroke,
      width: 2,
    }),
  }),
  EX: new Style({
    fill: new Fill({
      color: featureSubtypeColours.perimeters.EX.fill,
    }),
    stroke: new Stroke({
      color: featureSubtypeColours.perimeters.EX.stroke,
      width: 2,
    }),
  }),
  SE: new Style({
    fill: new Fill({
      color: featureSubtypeColours.perimeters.SE.fill,
    }),
    stroke: new Stroke({
      color: featureSubtypeColours.perimeters.SE.stroke,
      width: 2,
    }),
  }),
  CO: new Style({
    fill: new Fill({
      color: featureSubtypeColours.perimeters.CO.fill,
    }),
    stroke: new Stroke({
      color: featureSubtypeColours.perimeters.CO.stroke,
      width: 2,
    }),
  }),
  CL: new Style({
    fill: new Fill({
      color: featureSubtypeColours.perimeters.CL.fill,
    }),
    stroke: new Stroke({
      color: featureSubtypeColours.perimeters.CL.stroke,
      width: 2,
    }),
  }),
  ST: new Style({
    fill: new Fill({
      color: featureSubtypeColours.perimeters.ST.fill,
    }),
    stroke: new Stroke({
      color: featureSubtypeColours.perimeters.ST.stroke,
      width: 2,
    }),
  }),
  WM: new Style({
    fill: new Fill({
      color: featureSubtypeColours.perimeters.WM.fill,
    }),
    stroke: new Stroke({
      color: featureSubtypeColours.perimeters.WM.stroke,
      width: 2,
    }),
  }),
  FP: new Style({
    stroke: new Stroke({
      color: 'rgb(0,0,0)',
      lineDash: [16, 8],
      width: 2,
    }),
  }),
  MP: new Style({
    stroke: new Stroke({
      color: 'rgb(0,0,0)',
      width: 2,
    }),
  }),
  ER: new Style({
    stroke: new Stroke({
      color: 'rgb(0,0,0)',
      width: 2,
    }),
  }),
  PI: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.PI,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  SL: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.SL,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  VL: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.VL,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  RV: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.RV,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  incidents: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.incidents,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  crimes: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.crimes,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  markers: new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: 'rgb(0,0,0)',
      }),
    }),
  }),
  features: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.features,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  paths: new Style({
    stroke: new Stroke({
      color: 'rgb(0,0,0)',
      width: 2,
    }),
  }),
  perimeters: new Style({
    fill: new Fill({
      color: 'rgba(0,0,0,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(0,0,0)',
      width: 2,
    }),
  }),
  objectives: new Style({
    fill: new Fill({
      color: 'rgba(189,189,189,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(189,189,189)',
      width: 2,
    }),
  }),
  default: new Style({
    fill: new Fill({
      color: 'rgba(0,0,0,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(0,0,0)',
      width: 2,
    }),
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: 'rgb(0,0,0)',
      }),
    }),
  }),
};

const featureSelectSubtypeStyles = {
  PA: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  MB: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  OL: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  EX: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  SE: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  CO: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  CL: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  ST: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  WM: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  FP: new Style({
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      lineDash: [16, 8],
      width: 2,
    }),
  }),
  MP: new Style({
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  ER: new Style({
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  PI: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.PI,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  SL: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.SL,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  VL: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.VL,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  RV: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.RV,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  incidents: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.incidents,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  crimes: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.crimes,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  features: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.features,
      color: 'rgb(255,255,255)',
      scale: 0.5,
    }),
  }),
  markers: new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: 'rgb(33,150,243)',
      }),
    }),
  }),
  paths: new Style({
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  perimeters: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  objectives: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
  }),
  default: new Style({
    fill: new Fill({
      color: 'rgba(33,150,243,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(33,150,243)',
      width: 2,
    }),
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: 'rgb(33,150,243)',
      }),
    }),
  }),
};

const featureHoverSubtypeStyles = {
  PA: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  MB: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  OL: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  EX: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  SE: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  CO: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  CL: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  ST: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  WM: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  FP: new Style({
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      lineDash: [16, 8],
      width: 2,
    }),
  }),
  MP: new Style({
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  ER: new Style({
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  PI: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.PI,
      color: 'rgb(0,0,0)',
      scale: 0.5,
    }),
  }),
  SL: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.SL,
      color: 'rgb(0,0,0)',
      scale: 0.5,
    }),
  }),
  VL: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.VL,
      color: 'rgb(0,0,0)',
      scale: 0.5,
    }),
  }),
  RV: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.markers.RV,
      color: 'rgb(0,0,0)',
      scale: 0.5,
    }),
  }),
  incidents: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.incidents,
      color: 'rgb(0,0,0)',
      scale: 0.5,
    }),
  }),
  crimes: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.crimes,
      color: 'rgb(0,0,0)',
      scale: 0.5,
    }),
  }),
  features: new Style({
    image: new Icon({
      src: featureSubtypeGlyphs.features,
      color: 'rgb(0,0,0)',
      scale: 0.5,
    }),
  }),
  markers: new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: 'rgb(255,235,59)',
      }),
    }),
  }),
  paths: new Style({
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  perimeters: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  objectives: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
  }),
  default: new Style({
    fill: new Fill({
      color: 'rgba(255,235,59,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,235,59)',
      width: 2,
    }),
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: 'rgb(255,235,59)',
      }),
    }),
  }),
};

const staticStyle = new Style({
  text: new Text({
    font: '12px Roboto,sans-serif',
    fill: new Fill({
      color: '#00000',
    }),
    stroke: new Stroke({
      color: '#ffffff',
      width: 3,
    }),
  }),
});

export function setBaseLayers(map) {
  const currentLayers = map.getLayers().getArray();
  const crossOrigin = useWebGL ? 'anonymous' : null;

  switch (baseMap) {
    case 'wmts&wms':
    case 'wmts':
      // wmts options will be retrieved with get caps url (async)
      fetch(wmtsGetCapsUrl)
        .then((response) => response.text())
        .then((text) => {
          const parser = new WMTSCapabilities();
          const result = parser.read(text);
          const options = optionsFromCapabilities(result, {
            layer: wmtsLayer,
            matrixSet: wmtsMatrixSet,
            crossOrigin,
          });

          const wmtsTileLayer = new TileLayer({
            opacity: 1,
            source: new WMTS(options),
            isBaseLayer: true,
          });
          // if it's wmts and wms, add both layers and switch visibility when zoom limit reached
          if (baseMap === 'wmts&wms') {
            const wmsLayer = new ImageLayer({
              opacity: 1,
              visible: false,
              source: new ImageWMS({
                url: wmsUrl,
                params: wmsParams,
                ratio: 1,
                serverType: 'geoserver',
                crossOrigin,
              }),
            });

            const layers = [wmsLayer, wmtsTileLayer].concat(currentLayers);

            map.setLayerGroup(new LayerGroup({ layers }));

            map.on('moveend', (event) => {
              const map = event.map;
              const zoom = map.getView().getZoom();

              if (zoom >= wmsZoom) {
                wmsLayer.setVisible(true);
                wmtsLayer.setVisible(false);
              } else {
                wmsLayer.setVisible(false);
                wmtsLayer.setVisible(true);
              }
            });
          } else {
            // otherwise it's just wmts so just use that
            const layers = [wmtsLayer].concat(currentLayers);
            map.setLayerGroup(new LayerGroup({ layers }));
          }
        });
      break;
    case 'bing':
      map.setLayerGroup(
        new LayerGroup({
          layers: [
            new TileLayer({
              visible: true,
              preload: Infinity,
              source: new BingMapsSource({
                key: bingMapsKey,
                imagerySet: 'RoadOnDemand',
              }),
            }),
            new TileLayer({
              visible: false,
              preload: Infinity,
              source: new BingMapsSource({
                key: bingMapsKey,
                imagerySet: 'AerialWithLabelsOnDemand',
              }),
            }),
          ].concat(currentLayers),
        })
      );
      break;
    case 'azure':
      // unicode in a file messes up chrome debugger this is (C) copyright char
      const copyrightChar = String.fromCharCode(169);
      map.setLayerGroup(
        new LayerGroup({
          layers: [
            new TileLayer({
              visible: true,
              source: new XYZ({
                url:
                  'https://atlas.microsoft.com/map/tile/png?api-version=1&layer=basic&style=main&tileSize=256&zoom={z}&x={x}&y={y}' +
                  `&subscription-key=${azureMapsKey}`,
                attributions: `${copyrightChar} ${new Date().getFullYear()} Microsoft, ${copyrightChar} ${new Date().getFullYear()}  TomTom`,
                attributionsCollapsible: false,
                tileSize: 256,
              }),
            }),
            new TileLayer({
              visible: false,
              source: new XYZ({
                url:
                  'https://atlas.microsoft.com/map/imagery/png?api-version=1&style=satellite&tileSize=256&zoom={z}&x={x}&y={y}' +
                  `&subscription-key=${azureMapsKey}`,
                attributions: `${copyrightChar} ${new Date().getFullYear()} Microsoft, ${copyrightChar} ${new Date().getFullYear()}  DigitalGlobe`,
                attributionsCollapsible: false,
                tileSize: 256,
              }),
            }),
          ].concat(currentLayers),
        })
      );
      break;
    case 'maptiler':
      // const attributions =
      //   '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
      //   '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';

      map.setLayerGroup(
        new LayerGroup({
          layers: mapTiler.layers
            .map(
              (id, index) =>
                new TileLayer({
                  visible: index === 0,
                  source: new TileJSON({
                    url: `https://api.maptiler.com/maps/${id}/256/tiles.json?key=${mapTiler.key}`,
                    crossOrigin: 'anonymous',
                  }),
                })
            )
            .concat(currentLayers),
        })
      );
      break;
    case 'osm':
    default:
      const copyright = String.fromCharCode(169); // so chrome debugger works
      map.setLayerGroup(
        new LayerGroup({
          layers: [
            new TileLayer({
              visible: true,
              source: new XYZ({
                url:
                  osmUrl ||
                  'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attributions: [
                  `<a href='www.openstreetmap.org/copyright www.openstreetmap.org/copyright'>${copyright} OpenStreetMap</a>`,
                ],
                attributionsCollapsible: false,
                crossOrigin,
              }),
            }),
          ].concat(currentLayers),
        })
      );
      break;
  }
}
