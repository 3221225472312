import React, { Fragment } from 'react';
import { red } from '@material-ui/core/colors';
import {
  SignalCellular0Bar as SignalCellular0BarIcon,
  SignalCellular1Bar as SignalCellular1BarIcon,
  SignalCellular2Bar as SignalCellular2BarIcon,
  SignalCellular3Bar as SignalCellular3BarIcon,
  SignalCellular4Bar as SignalCellular4BarIcon,
  SignalCellularNull as SignalCellularNullIcon,
} from '@material-ui/icons';
import { Avatar } from '@material-ui/core';

const staticStyles = {
  badIcon: {
    color: red[500],
  },
  warningIcon: {},
};

export function decodeSignalStrength(signalStrength) {
  if (typeof signalStrength === 'undefined') {
    return -1;
  }
  if (signalStrength < 4 || signalStrength === 99) {
    return 0;
  }
  if (signalStrength < 10) {
    return 1;
  }
  if (signalStrength < 16) {
    return 2;
  }
  if (signalStrength < 22) {
    return 3;
  }
  // if (signalStrength < 28) { return <SignalCellular4BarIcon />; }
  return 4;
}

export function signalStrengthIcon(signalStrength, isDecoded = false) {
  if (!isDecoded) {
    signalStrength = decodeSignalStrength(signalStrength);
  }

  switch (signalStrength) {
    case -1:
      return (
        <SignalCellularNullIcon
          titleAccess="null bars"
          style={staticStyles.badIcon}
        />
      );
    case 0:
      return (
        <SignalCellular0BarIcon
          titleAccess="0 bars"
          style={staticStyles.badIcon}
        />
      );
    case 1:
      return (
        <SignalCellular1BarIcon
          titleAccess="1 bar"
          style={staticStyles.badIcon}
        />
      );
    case 2:
      return (
        <SignalCellular2BarIcon
          titleAccess="2 bars"
          style={staticStyles.warningIcon}
        />
      );
    case 3:
      return <SignalCellular3BarIcon titleAccess="3 bars" />;
    default:
      return <SignalCellular4BarIcon titleAccess="full bars" />;
  }
}

export function decodeGpsValidPollCount(gpsValidPollCount) {
  if (!gpsValidPollCount || gpsValidPollCount.total === 0) {
    return -1;
  }

  const { total, valid } = gpsValidPollCount;
  return valid / total;
}

export function convertSoftwareId(sid) {
  return (
    String('00' + (sid & 0x0ff).toString(16)).slice(-2) +
    '.' +
    String('00' + ((sid / 0x100) | 0).toString(16)).slice(-2)
  );
}

export function mapCanBusStatus(status) {
  switch (status) {
    case 0x00:
      return 'Unknown';
    case 0x01:
    case 0x02:
    case 0x03:
      return 'Connected';
    case 0x04:
      return 'Bus Off';
    case 0x05:
      return 'Tester Detected';
    case 0x0e:
      return 'Deactivated';
    case 0x0f:
      return 'Not Connected';
    default:
      return status;
  }
}

export const OnOffIcon = ({ on, onTooltip = 'On', offTooltip = 'Off' }) => {
  const title = on ? onTooltip : offTooltip;
  return (
    <Avatar
      title={title}
      style={{
        width: 20,
        height: 20,
        backgroundColor: on ? '#3c3' : '#666',
      }}
    >
      <Fragment />
    </Avatar>
  );
};
