import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  FETCH_QUERIES,
  FETCH_QUERY,
  CREATE_QUERY,
  UPDATE_QUERY,
  DELETE_QUERY,
  FETCH_QUERIES_SUCCESS,
  FETCH_QUERIES_FAILURE,
  FETCH_QUERY_SUCCESS,
  FETCH_QUERY_FAILURE,
  CREATE_QUERY_SUCCESS,
  CREATE_QUERY_FAILURE,
  UPDATE_QUERY_SUCCESS,
  UPDATE_QUERY_FAILURE,
  DELETE_QUERY_SUCCESS,
  DELETE_QUERY_FAILURE,
  FETCH_QUERY_EVENTS,
  FETCH_SELECTION_EVENTS_SUCCESS,
  FETCH_SELECTION_EVENTS_FAILURE,
} from '../actions';
import api from '../apis';
import { getQueryEvents, getHeaders, log } from '../apis/utilities';
import history from '../history';

async function fetchQueriesRequest() {
  const response = await api.get('/queries', {
    params: {
      projection: {
        identifier: true,
        title: true,
        type: true,
        description: true,
        items: true,
        created: true,
      },
      sort: { type: 1, title: 1 },
    },
    headers: getHeaders(),
  });

  log('Read', 'Queries', {});

  return response.data;
}

export function fetchQueriesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_QUERIES),
    mergeMap(() =>
      from(fetchQueriesRequest()).pipe(
        map((payload) => ({
          type: FETCH_QUERIES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_QUERIES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchQueryRequest(id) {
  const response = await api.get(`/queries/${id}`, {
    params: {
      projection: {
        identifier: true,
        title: true,
        type: true,
        source: true,
        description: true,
        isRelativeTimePeriod: true,
        timePeriod: true,
        areas: true,
        briefs: true,
        boundary: true,
        parameters: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'Query', { id });

  return response.data;
}

export function fetchQueryEpic(action$) {
  return action$.pipe(
    ofType(FETCH_QUERY),
    mergeMap(({ payload: id }) =>
      from(fetchQueryRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_QUERY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_QUERY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function createQueryRequest(values) {
  const response = await api.post(`/queries`, values, {
    headers: getHeaders(),
  });

  history.replace(response.data.identifier);

  return response.data;
}

export function createQueryEpic(action$) {
  return action$.pipe(
    ofType(CREATE_QUERY),
    mergeMap(({ payload: values }) =>
      from(createQueryRequest(values)).pipe(
        map((payload) => ({
          type: CREATE_QUERY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_QUERY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateQueryRequest(values) {
  await api.patch(
    `/queries/${values.identifier}`,
    {
      ...values,
    },
    {
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/merge-patch+json',
      },
    }
  );

  return values;
}

export function updateQueryEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_QUERY),
    mergeMap(({ payload: values }) =>
      from(updateQueryRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_QUERY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_QUERY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function deleteQueryRequest(id) {
  await api.delete(`/queries/${id}`, {
    params: {
      permanent: true,
    },
    headers: getHeaders(),
  });
  history.push('.');
  return id;
}

export function deleteQueryEpic(action$) {
  return action$.pipe(
    ofType(DELETE_QUERY),
    mergeMap(({ payload: id }) =>
      from(deleteQueryRequest(id)).pipe(
        map((payload) => ({
          type: DELETE_QUERY_SUCCESS,
          payload: decodeURIComponent(payload),
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_QUERY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchQueryEventsRequest(id) {
  const events = await getQueryEvents(id);

  log('Read', 'Query Events', { id, type: 'Query' });

  return events;
}

export function fetchQueryEventsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_QUERY_EVENTS),
    mergeMap(({ payload: id }) =>
      from(fetchQueryEventsRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_SELECTION_EVENTS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_SELECTION_EVENTS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
