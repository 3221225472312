import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  FETCH_HOME_STATIONS,
  FETCH_HOME_STATIONS_SUCCESS,
  FETCH_HOME_STATIONS_FAILURE,
  FETCH_WARDS,
  FETCH_WARDS_SUCCESS,
  FETCH_WARDS_FAILURE,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILURE,
  CREATE_LOCATION,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from '../actions';
import api from '../apis';
import { getHeaders, log, fetchLocationRequest } from '../apis/utilities';
import history from '../history';

const { homeStationTypes } = window.config;

async function fetchHomeStationsRequest() {
  const response = await api.get('/locations', {
    params: {
      query: {
        type: { $in: homeStationTypes },
      },
      projection: { code: true, name: true },
      sort: { name: 1 },
    },
    headers: getHeaders(),
  });

  return response.data;
}

export function fetchHomeStationsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_HOME_STATIONS),
    mergeMap(() =>
      from(fetchHomeStationsRequest()).pipe(
        map((payload) => ({
          type: FETCH_HOME_STATIONS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_HOME_STATIONS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchWardsRequest() {
  const response = await api.get('/locations', {
    params: {
      query: {
        type: 'Ward',
      },
      projection: { code: true, name: true },
      sort: { name: 1 },
    },
    headers: getHeaders(),
  });

  return response.data;
}

export function fetchWardsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_WARDS),
    mergeMap(() =>
      from(fetchWardsRequest()).pipe(
        map((payload) => ({
          type: FETCH_WARDS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_WARDS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchLocationsRequest(type) {
  const response = await api.get('/locations', {
    params: {
      query:
        type !== 'All'
          ? {
              type: type,
            }
          : undefined,
      projection: { code: true, name: true, type: true, picture: true },
      sort: { name: 1 },
    },
    headers: getHeaders(),
  });

  log('Read', 'Locations', { type });

  return response.data.map((location) => ({
    ...location,
    searchString: [location.name, location.code].join('+').toLowerCase(),
  }));
}

export function fetchLocationsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LOCATIONS),
    mergeMap(({ payload: type }) =>
      from(fetchLocationsRequest(type)).pipe(
        map((payload) => ({
          type: FETCH_LOCATIONS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LOCATIONS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchLocationEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LOCATION),
    mergeMap(({ payload: id }) =>
      from(fetchLocationRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_LOCATION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LOCATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function createLocationRequest(location) {
  await api.post(`/locations`, location, {
    headers: getHeaders(),
  });

  history.replace(location.code);

  return location;
}

export function createLocationEpic(action$) {
  return action$.pipe(
    ofType(CREATE_LOCATION),
    mergeMap(({ payload: location }) =>
      from(createLocationRequest(location)).pipe(
        map((payload) => ({
          type: CREATE_LOCATION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_LOCATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateLocationRequest(location) {
  await api.patch(`/locations/${location.code}`, location, {
    headers: {
      ...getHeaders(),
      'Content-Type': 'application/merge-patch+json',
    },
  });

  return location;
}

export function updateLocationEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_LOCATION),
    mergeMap(({ payload: location }) =>
      from(updateLocationRequest(location)).pipe(
        map((payload) => ({
          type: UPDATE_LOCATION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_LOCATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function deleteLocationRequest(id) {
  await api.delete(`/locations/${id}`, {
    headers: getHeaders(),
  });

  history.push('.');

  return id;
}

export function deleteLocationEpic(action$) {
  return action$.pipe(
    ofType(DELETE_LOCATION),
    mergeMap(({ payload: id }) =>
      from(deleteLocationRequest(id)).pipe(
        map((payload) => ({
          type: DELETE_LOCATION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_LOCATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

/*
async function fetchPersonVisitsRequest(id, startTime, endTime) {
  const response = await api.get('/personLocationIntersections', {
    params: {
      query: {
        'location.code': id,
        startTime: { $lt: endTime },
        endTime: { $gt: startTime },
      },
      projection: {
        identifier: true,
        person: true,
        location: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const visits = (response.data || []).map((visit) => {
    return {
      identifier: visit.identifier,
      code: visit.person.code,
      name: `${visit.person.forenames} ${visit.person.surname}`,
      collarNumber: visit.person.collarNumber,
      role: visit.person.role,
      startTime: visit.startTime,
      endTime: visit.endTime,
      durationMinutes: visit.durationSeconds / 60,
      locationType: visit.location.type,
      locationName: visit.location.name,
    };
  });

  log('Read', 'Person Visits', {
    id,
    startTime,
    endTime,
  });

  return { id, visits };
}

export function fetchPersonVisitsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON_VISITS),
    mergeMap(({ payload: { id, startTime, endTime } }) =>
      from(fetchPersonVisitsRequest(id, startTime, endTime)).pipe(
        map((payload) => ({
          type: FETCH_PERSON_VISITS_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_PERSON_VISITS_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PERSON_VISITS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchVehicleVisitsRequest(id, startTime, endTime) {
  const response = await api.get('/intersections', {
    params: {
      query: {
        'location.code': id,
        startTime: { $lt: endTime },
        endTime: { $gt: startTime },
      },
      projection: {
        identifier: true,
        vehicle: true,
        location: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        distanceKilometres: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const visits = (response.data || []).map((visit) => {
    return {
      identifier: visit.identifier,
      registrationNumber: visit.vehicle.registrationNumber,
      fleetNumber: visit.vehicle.fleetNumber,
      role: visit.vehicle.role,
      type: visit.vehicle.type,
      startTime: visit.startTime,
      endTime: visit.endTime,
      durationMinutes: visit.durationSeconds / 60,
      distanceMiles: visit.distanceKilometres * 0.62137119,
      locationType: visit.location.type,
      locationName: visit.location.name,
    };
  });

  log('Read', 'Vehicle Visits', {
    id,
    startTime,
    endTime,
  });

  return { id, visits };
}

export function fetchVehicleVisitsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_VISITS),
    mergeMap(({ payload: { id, startTime, endTime } }) =>
      from(fetchVehicleVisitsRequest(id, startTime, endTime)).pipe(
        map((payload) => ({
          type: FETCH_VEHICLE_VISITS_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_VEHICLE_VISITS_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLE_VISITS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
*/
