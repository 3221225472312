import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Table,
  TableBody,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  CropSquare as PerimiterIcon,
  Place as MarkerIcon,
  ShowChart as PathIcon,
  LocationSearching as FollowIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FETCH_FEATURE_COLLECTION } from '../../../actions';
import {
  featureSubtypeColours,
  featureSubtypeIcons,
} from '../../../data/constants';
import { usePrevious } from '../../../hooks';
import avatarForItem from './avatarUtility';
import { areasToLabelAccessors, ItemRows } from './ItemRows';
import { ContentViewer, TagControl } from '../../controls';

// example object
/*
$type: "add"
areas: Array(1)
  0: {name: "Special Ops", type: "team"}
boundary:
  $reql_type$: "GEOMETRY"
  coordinates: [Array(5)]
  type: "Polygon"
briefs: Array(3)
  0: "Leics Ops Plans"
  1: "OPS"
  2: "BRIEF3"
description: "<p>Op Plan for Abbey Park Firework Display.</p>"
endTime: "2018-11-03T22:00:00.000Z"
id: "Abbey Park Fireworks"
identifier: "Abbey Park Fireworks"
searchString: "[object object]+[object object]+leics ops plans,ops,brief3+<p>op plan for abbey park firework display.</p>+2018-11-03t22:00:00.000z+abbey park fireworks+2018-11-03t17:00:00.000z++abbey park fireworks, this is another super long title+plan+add+true"
startTime: "2018-11-03T17:00:00.000Z"
tagChanged: true
tags: Array(0)

title: "Abbey Park Fireworks, this is another super long title"
type: "Plan"
*/

const featureTypeIconMap = {
  Perimeter: PerimiterIcon,
  Path: PathIcon,
  Marker: MarkerIcon,
};

function subAvatar(feature, avatarClass = null) {
  const { perimeters } = featureSubtypeColours;
  const { subtype, type: featureType } = feature.properties;
  const Icon = featureTypeIconMap[featureType];
  const backgroundColor =
    featureType === 'Perimeter' && subtype && perimeters[subtype]?.stroke;
  const markerIcon =
    featureType === 'Marker' &&
    (featureSubtypeIcons.markers[subtype] ||
      featureSubtypeIcons.markers.default);

  return (
    <Avatar className={avatarClass} style={{ backgroundColor }}>
      {markerIcon || subtype || (Icon && <Icon />) || featureType}
    </Avatar>
  );
}

function PlanLiveItem({
  item,
  classes,
  onSubItemHover,
  hoveredId,
  onFollowToggle,
  followedIdsByType,
}) {
  const hideFollow = !item.boundary;
  const type = 'plans';
  const following = followedIdsByType?.[type]?.[item.id];

  const rowItems = [
    // { label: 'Type', value: item.type },
    {
      label: 'Start',
      value: moment(item.startTime).format('DD/MM/YYYY HH:mm:ss'),
    },
    { label: 'End', value: moment(item.endTime).format('DD/MM/YYYY HH:mm:ss') },
    item.briefs && { label: 'Briefs', value: item.briefs.join(', ') },
    ...areasToLabelAccessors(item.areas),
  ].filter(Boolean);

  const { id: encodedPlanId, subId: encodedSubId } = useParams();
  const planId = encodedPlanId && decodeURIComponent(encodedPlanId);
  const subId = encodedSubId && decodeURIComponent(encodedSubId);
  const history = useHistory();
  let selectedFeature = null;
  let selectedHistory = null;
  let selectedInfo = null;

  // JL TODO only get this if the id has changed?
  const dispatch = useDispatch();
  const planFeatures = useSelector((state) => state.live.planFeatures);
  const prevId = usePrevious(item.id);
  if (!planFeatures || prevId !== item.id) {
    dispatch({
      type: FETCH_FEATURE_COLLECTION,
      payload: item.id,
    });
  } else if (subId) {
    selectedFeature = null;
    planFeatures.forEach((feature) => {
      if (!selectedFeature && feature.properties.identifier === subId) {
        selectedFeature = feature;
        const fprops = feature.properties;
        selectedHistory = [
          ...((fprops.created && [
            {
              label: 'Created time',
              value:
                fprops.created.time &&
                moment(fprops.created.time).format('DD/MM/YYYY HH:mm:ss'),
            },
            { label: 'Created user', value: fprops.created.userId },
          ]) ||
            []),
          ...((fprops.lastEdit && [
            {
              label: 'Last edit time',
              value:
                fprops.lastEdit.time &&
                moment(fprops.lastEdit.time).format('DD/MM/YYYY HH:mm:ss'),
            },
            { label: 'Last edit user', value: fprops.lastEdit.userId },
          ]) ||
            []),
        ].filter(Boolean);
        selectedInfo = [
          { label: 'Title', value: fprops.title },
          { label: 'Identifier', value: fprops.identifier },
          { label: 'Type', value: fprops.subtype },
        ];
      }
    });
  }

  const groupedPlanFeatures = _.groupBy(planFeatures, 'properties.type');

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>
          {[
            'iR3',
            'Live',
            'Plans',
            item.title,
            selectedFeature?.properties?.title,
          ]
            .filter(Boolean)
            .join(' | ')}
        </title>
      </Helmet>
      {selectedFeature ? (
        <Fragment>
          <CardHeader
            avatar={subAvatar(selectedFeature)}
            title={selectedFeature.properties.title}
            subheader={selectedFeature.properties.identifier}
          />
          <CardContent>
            <Typography variant="subtitle2" color="textSecondary">
              Description
            </Typography>
            <div className={classes.contentViewer}>
              <ContentViewer content={selectedFeature.properties.description} />
            </div>
            <Typography variant="subtitle2" color="textSecondary">
              Key Information
            </Typography>
            <Table size="small" className={classes.table}>
              <TableBody>{ItemRows(selectedInfo)}</TableBody>
            </Table>

            {selectedFeature.properties.areas?.length > 0 && (
              <Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Areas
                </Typography>
                <Table size="small" className={classes.table}>
                  <TableBody>
                    {ItemRows(
                      areasToLabelAccessors(selectedFeature.properties.areas)
                    )}
                  </TableBody>
                </Table>
              </Fragment>
            )}

            <Typography variant="subtitle2" color="textSecondary">
              History
            </Typography>
            <Table size="small" className={classes.table}>
              <TableBody>{ItemRows(selectedHistory)}</TableBody>
            </Table>
          </CardContent>
        </Fragment>
      ) : (
        <Fragment>
          <CardHeader
            avatar={avatarForItem(item, 'plans')}
            title={item.title}
            subheader={item.id}
            action={
              !hideFollow && (
                <React.Fragment>
                  <IconButton
                    aria-label="Toggle follow"
                    onClick={() => onFollowToggle(type, item.id)}
                    title="Toggle follow"
                  >
                    <FollowIcon
                      fontSize="small"
                      color={following ? 'primary' : 'inherit'}
                    />
                  </IconButton>
                </React.Fragment>
              )
            }
          />
          <CardContent>
            <TagControl item={item} type={'plans'} />
            <Typography variant="subtitle2" color="textSecondary">
              Description
            </Typography>
            <div className={classes.contentViewer}>
              <ContentViewer content={item.description} />
            </div>
            {/* <ReactQuill
              className={classes.table}
              modules={{ toolbar: false }}
              readOnly={true}
              value={item.description}
            ></ReactQuill> */}
            <Typography variant="subtitle2" color="textSecondary">
              Details
            </Typography>
            <Table size="small" className={classes.table}>
              <TableBody>{ItemRows(rowItems, item)}</TableBody>
            </Table>
            {groupedPlanFeatures && (
              <Fragment>
                {/* <Typography variant="subtitle2" color="textSecondary">
              Features
            </Typography> */}
                {
                  Object.keys(featureTypeIconMap) // so order of Perim, Path, Mark preserved
                    .filter((featureType) => groupedPlanFeatures[featureType])
                    .map((featureType) => (
                      <Fragment key={featureType}>
                        <ListSubheader
                          // style={{ fontSize: 12 }}
                          disableSticky
                          disableGutters
                        >
                          {`${featureType}s`}
                        </ListSubheader>
                        <List dense className={classes.subList}>
                          {groupedPlanFeatures[featureType].map((feature) => {
                            const { featureType, title } = feature.properties;
                            const featureId = feature.properties.identifier;

                            return (
                              <ListItem
                                disableGutters
                                key={featureType + title}
                                className={
                                  hoveredId === featureId
                                    ? classes.highlightListItem
                                    : null
                                }
                                button // makes it highlight when hovered
                                onMouseEnter={(_) =>
                                  onSubItemHover({
                                    type: 'plans',
                                    id: featureId,
                                  })
                                }
                                onMouseLeave={(_) => onSubItemHover({})}
                                onClick={(_) =>
                                  history.push(
                                    `/live/plans/${encodeURIComponent(
                                      planId
                                    )}/${encodeURIComponent(featureId)}`
                                  )
                                }
                              >
                                <ListItemAvatar>
                                  {subAvatar(feature, classes.subAvatar)}
                                </ListItemAvatar>
                                <ListItemText
                                  primaryTypographyProps={{ variant: 'body2' }}
                                  primary={feature.properties.title}
                                  secondary={featureId}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Fragment>
                    ))
                  /* 
type: "FeatureCollection"
features: Array(4)
  0:
    type: "Feature"
    id: 0
    properties:
      areas: (2) [{...}, {...}]
      collections: ["kyle_001"]
      description: "TEsting description text"
      identifier: "POI_kyle_001"
      subtype: ""
      title: "POI_001"
      type: "Marker"
      typeId: "features"
      collectionId: "kyle_001"
      collectionTypeId: "plans"
    geometry:
      $reql_type$: "GEOMETRY"
      coordinates: (2) [-3.4167480468750004, 51.57408237343995]
      type: "Point"
  1:
    type: "Feature"
    id: 1
    properties:
      areas: (2) [{...}, {...}]
      collections: ["kyle_001"]
      description: "Print this in details"
      identifier: "path_001_kyle"
      subtype: ""
      title: "Pathtest"
      type: "Path"
      typeId: "features"
      collectionId: "kyle_001"
      collectionTypeId: "plans"
    geometry:
      $reql_type$: "GEOMETRY"
      coordinates: (5) [Array(2), Array(2), Array(2), Array(2), Array(2)]
      type: "LineString"
  2:
    type: "Feature"
    id: 2
    properties:
      areas: (2) [{...}, {...}]
      collections: ["kyle_001"]
      description: "Testing recall"
      identifier: "perim_001_kyle"
      subtype: "PA"
      title: "test-perim_001"
      type: "Perimeter"
      typeId: "features"
      collectionId: "kyle_001"
      collectionTypeId: "plans"
    geometry:
      $reql_type$: "GEOMETRY"
      coordinates: [Array(5)]
      type: "Polygon"
  3: {type: "Feature", id: 3, properties: {...}, geometry: {...}}
  length: 4
              */
                  /*              <PeopleLiveListItem
                key={person.id}
                onClick={onSubItemClick}
                onHoverItem={onSubItemHover}
                onFollowToggle={onFollowToggle}
                followedIdsByType={followedIdsByType}
                classes={classes}
                item={person}
              />*/
                }
              </Fragment>
            )}
          </CardContent>
        </Fragment>
      )}
    </Card>
  );
}

export default PlanLiveItem;
