import moment from 'moment';
import {
  FETCH_CRIME_FILTERS,
  FETCH_CRIME_FILTERS_SUCCESS,
  FETCH_CRIME_FILTERS_FAILURE,
  FETCH_INCIDENT_FILTERS,
  FETCH_INCIDENT_FILTERS_SUCCESS,
  FETCH_INCIDENT_FILTERS_FAILURE,
  FETCH_INTELLIGENCE_FILTERS,
  FETCH_INTELLIGENCE_FILTERS_SUCCESS,
  FETCH_INTELLIGENCE_FILTERS_FAILURE,
  FETCH_STOP_CHECK_FILTERS,
  FETCH_STOP_CHECK_FILTERS_SUCCESS,
  FETCH_STOP_CHECK_FILTERS_FAILURE,
  FETCH_USER_IDS,
  FETCH_USER_IDS_SUCCESS,
  FETCH_USER_IDS_FAILURE,
  UPDATE_FILTER_END_TIME,
  UPDATE_FILTER_LOCATION_TYPE,
  UPDATE_FILTER_CARD_USED,
  UPDATE_FILTER_START_TIME,
  UPDATE_FILTER_EMERGENCY_EQUIPMENT_USED,
  UPDATE_FILTER_USER_ID,
  UPDATE_AUDIT_LOG_FILTER,
} from '../actions';

const { rowsPerPageOptions } = window.config;

const INITIAL_STATE = {
  incidents: null,
  crimes: null,
  intelligence: null,
  stopChecks: null,
  startTime: moment().startOf('day').subtract(2, 'days').toDate(),
  endTime: moment().startOf('day').toDate(),
  // startTime: moment('2020-05-01').toDate(),
  // endTime: moment('2020-05-30').toDate(),
  locationType: '',
  cardUsed: 2,
  emergencyEquipmentUsed: 2,
  userIds: [],
  userId: null,
  isLoading: false,
  error: null,
  auditLog: {
    startTime: moment().startOf('day').subtract(2, 'days').toDate(),
    endTime: moment().startOf('day').toDate(),
    page: 0,
    rowsPerPage: rowsPerPageOptions[0],
    order: 'asc',
    orderBy: 'time',
    userId: null,
    dataType: null,
    action: null,
  },
};

export default function filtersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_INCIDENT_FILTERS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_INCIDENT_FILTERS_SUCCESS:
      return {
        ...state,
        incidents: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_INCIDENT_FILTERS_FAILURE:
      return {
        ...state,
        incidents: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_CRIME_FILTERS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_CRIME_FILTERS_SUCCESS:
      return {
        ...state,
        crimes: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_CRIME_FILTERS_FAILURE:
      return { ...state, crimes: [], isLoading: false, error: action.payload };
    case FETCH_INTELLIGENCE_FILTERS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_INTELLIGENCE_FILTERS_SUCCESS:
      return {
        ...state,
        intelligence: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_INTELLIGENCE_FILTERS_FAILURE:
      return {
        ...state,
        intelligence: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_STOP_CHECK_FILTERS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_STOP_CHECK_FILTERS_SUCCESS:
      return {
        ...state,
        stopChecks: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_STOP_CHECK_FILTERS_FAILURE:
      return {
        ...state,
        stopChecks: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_USER_IDS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_USER_IDS_SUCCESS:
      return {
        ...state,
        userIds: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_USER_IDS_FAILURE:
      return { ...state, userIds: [], isLoading: false, error: action.payload };
    case UPDATE_FILTER_START_TIME:
      return {
        ...state,
        startTime: action.payload
          ? moment(action.payload).startOf('day').toDate()
          : null,
      };
    case UPDATE_FILTER_END_TIME:
      return {
        ...state,
        endTime: action.payload
          ? moment(action.payload).startOf('day').toDate()
          : null,
      };
    case UPDATE_FILTER_LOCATION_TYPE:
      return { ...state, locationType: action.payload };
    case UPDATE_FILTER_CARD_USED:
      return { ...state, cardUsed: action.payload };
    case UPDATE_FILTER_EMERGENCY_EQUIPMENT_USED:
      return { ...state, emergencyEquipmentUsed: action.payload };
    case UPDATE_FILTER_USER_ID:
      return { ...state, userId: action.payload };
    case UPDATE_AUDIT_LOG_FILTER:
      return { ...state, auditLog: action.payload };
    default:
      return state;
  }
}
