import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import TelematicsBoxList from './TelematicsBoxList';
import TelematicsBoxDetail from './TelematicsBoxDetail';
// import TelematicsBoxDiagnostics from './TelematicsBoxDiagnostics';
import { FETCH_TELEMATICS_BOXES } from '../../../actions';
import Container from '../../Container';
import { useSnackbar } from '../../Snackbar';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  listSection: {
    width: 280,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  itemSection: {
    width: 'calc(100% - 280px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

export default function TelematicsBoxPage() {
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  // get the tbs when starting up
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: FETCH_TELEMATICS_BOXES });
  }, [dispatch]);

  const snackbar = useSnackbar();
  const error = useSelector((state) => state.telematicsBoxes.error, _.isEqual);
  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  return (
    <Container title="Telematics Boxes">
      <Helmet>
        <title>IR3 | Telematics Boxes</title>
      </Helmet>
      {(!isXs || !id) && (
        <div className={classes.listSection}>
          <TelematicsBoxList props={{ match: { params: id } }} />
        </div>
      )}
      {(!isXs || id) && (
        <div className={classes.itemSection}>
          {id ? ( // if there's an id show the individual box
            <Route path="/resources/telematicsboxes/:id">
              <TelematicsBoxDetail />
            </Route>
          ) : (
            <Fragment />
            // move to reports instead
            // otherwise there's no id, show the diagnostics
            // <TelematicsBoxDiagnostics />
          )}
        </div>
      )}
    </Container>
  );
}
