import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import _ from 'lodash';
import {
  FETCH_VEHICLES,
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLES_FAILURE,
  FETCH_VEHICLE,
  FETCH_VEHICLE_SUCCESS,
  FETCH_VEHICLE_FAILURE,
  CREATE_VEHICLE,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAILURE,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAILURE,
  REMOVE_VEHICLE_TELEMATICS_BOX,
  REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS,
  REMOVE_VEHICLE_TELEMATICS_BOX_FAILURE,
  DELETE_VEHICLE,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAILURE,
} from '../actions';
import { fromAjax } from '../apis';
import { getHeaders, log } from '../apis/utilities';
import history from '../history';

const { useRestricted } = window.config;

export function fetchVehiclesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLES),
    mergeMap(() =>
      fromAjax('/vehicles', {
        params: {
          projection: {
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            telematicsBoxImei: true,
            lastPollTime: true,
            disposalDate: true,
            picture: true,
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const payload = (response || []).map((vehicle) => {
            return {
              ...vehicle,
              searchString: [
                vehicle.identificationNumber,
                vehicle.registrationNumber,
                vehicle.fleetNumber,
              ]
                .join('+')
                .toLowerCase(),
            };
          });

          log('Read', 'Vehicles');

          return {
            type: FETCH_VEHICLES_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchVehicleEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE),
    mergeMap(({ payload: id }) =>
      fromAjax(`/vehicles/${id}`, {
        params: {
          projection: {
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            picture: true,
            role: true,
            type: true,
            make: true,
            model: true,
            colour: true,
            marked: true,
            homeStation: true,
            areas: true,
            equipment: true,
            telematicsBoxImei: true,
            lastPollTime: true,
            lastOdometerReading: true,
            fuelType: true,
            disposedTime: true,
            restricted: true,
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const payload = {
            ...response,
            lastOdometerReading: {
              distanceMiles:
                response.lastOdometerReading &&
                response.lastOdometerReading.distanceKilometres
                  ? _.round(
                      response.lastOdometerReading.distanceKilometres *
                        0.62137119,
                      2
                    )
                  : null,
              time:
                response.lastOdometerReading &&
                response.lastOdometerReading.time
                  ? response.lastOdometerReading.time
                  : null,
            },
          };

          log('Read', 'Vehicle', { id });

          return {
            type: FETCH_VEHICLE_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function createVehicleEpic(action$) {
  return action$.pipe(
    ofType(CREATE_VEHICLE),
    mergeMap(({ payload }) =>
      fromAjax(`/vehicles`, {
        body: {
          ...payload,
          restricted:
            useRestricted && payload.restricted === undefined
              ? false
              : payload.restricted,
          lastOdometerReading: payload.lastOdometerReading
            ? {
                time: payload.lastOdometerReading.time
                  ? payload.lastOdometerReading.time
                  : null,
                distanceKilometres: payload.lastOdometerReading.distanceMiles
                  ? _.round(
                      payload.lastOdometerReading.distanceMiles * 1.609344,
                      2
                    )
                  : null,
              }
            : null,
        },
        method: 'POST',
        headers: { ...getHeaders(), 'Content-Type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', 'Vehicle', payload);

          history.replace(payload.identificationNumber);

          return {
            type: CREATE_VEHICLE_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_VEHICLE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function updateVehicleEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_VEHICLE),
    mergeMap(({ payload }) =>
      fromAjax(`/vehicles/${payload.identificationNumber}`, {
        body: {
          ...payload,
          lastOdometerReading: payload.lastOdometerReading
            ? {
                time: payload.lastOdometerReading.time
                  ? payload.lastOdometerReading.time
                  : null,
                distanceKilometres: payload.lastOdometerReading.distanceMiles
                  ? _.round(
                      payload.lastOdometerReading.distanceMiles * 1.609344,
                      2
                    )
                  : null,
              }
            : null,
        },
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: payload }) => {
          log('Update', 'Vehicle', payload);

          return {
            type: UPDATE_VEHICLE_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_VEHICLE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function removeVehicleTelematicsBoxEpic(action$) {
  return action$.pipe(
    ofType(REMOVE_VEHICLE_TELEMATICS_BOX),
    mergeMap(({ payload: values }) =>
      fromAjax(`/vehicles/${values.identificationNumber}`, {
        body: {
          identificationNumber: values.identificationNumber,
          telematicsBoxImei: values.telematicsBoxImei,
        },
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: payload }) => ({
          type: REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS,
          payload: { ...payload, previousImei: values.previousImei },
        })),
        catchError(({ message: payload }) =>
          of({
            type: REMOVE_VEHICLE_TELEMATICS_BOX_FAILURE,
            payload: { ...payload, previousImei: values.previousImei },
          })
        )
      )
    )
  );
}

export function deleteVehicleEpic(action$) {
  return action$.pipe(
    ofType(DELETE_VEHICLE),
    mergeMap(({ payload: id }) =>
      fromAjax(`/vehicles/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          log('Delete', 'Vehicle', { id });

          history.push('.');

          return {
            type: DELETE_VEHICLE_SUCCESS,
            payload: response.identificationNumber,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_VEHICLE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
