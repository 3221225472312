import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  FETCH_INCIDENT_FILTERS,
  FETCH_CRIME_FILTERS,
  FETCH_INTELLIGENCE_FILTERS,
  FETCH_STOP_CHECK_FILTERS,
  FETCH_USER_IDS,
  FETCH_INCIDENT_FILTERS_SUCCESS,
  FETCH_INCIDENT_FILTERS_FAILURE,
  FETCH_CRIME_FILTERS_SUCCESS,
  FETCH_CRIME_FILTERS_FAILURE,
  FETCH_INTELLIGENCE_FILTERS_SUCCESS,
  FETCH_INTELLIGENCE_FILTERS_FAILURE,
  FETCH_STOP_CHECK_FILTERS_SUCCESS,
  FETCH_STOP_CHECK_FILTERS_FAILURE,
  FETCH_USER_IDS_SUCCESS,
  FETCH_USER_IDS_FAILURE,
} from '../actions';
import api from '../apis';
import { getHeaders } from '../apis/utilities';
import { incidentFilters } from '../data/constants';

const sourceTypes = {
  Incidents: 'incidents',
  Crimes: 'crimes',
  Intelligence: 'intelligence',
  'Stop Checks': 'intelligence',
};

async function getFilterValues(source, filter) {
  const response = await await api.get(
    `/${sourceTypes[source]}/fields/${filter}/values`,
    { headers: getHeaders() }
  );
  return response.data;
}

export function fetchIncidentFiltersEpic(action$) {
  return action$.pipe(
    ofType(FETCH_INCIDENT_FILTERS),
    mergeMap(() =>
      from(of(incidentFilters)).pipe(
        map((payload) => ({
          type: FETCH_INCIDENT_FILTERS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_INCIDENT_FILTERS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchCrimeFiltersRequest() {
  return {
    types: await getFilterValues('Crimes', 'type'),
    dispatchTypes: await getFilterValues('Crimes', 'dispatchType'),
    classifications: await getFilterValues('Crimes', 'classifications'),
    statuses: await getFilterValues('Crimes', 'status'),
    bcus: await getFilterValues('Crimes', 'bcu'),
    localAuthorities: await getFilterValues('Crimes', 'localAuthority'),
    sections: await getFilterValues('Crimes', 'section'),
    sectors: await getFilterValues('Crimes', 'sector'),
    beats: await getFilterValues('Crimes', 'beat'),
    wards: await getFilterValues('Crimes', 'ward'),
  };
}

export function fetchCrimeFiltersEpic(action$) {
  return action$.pipe(
    ofType(FETCH_CRIME_FILTERS),
    mergeMap(() =>
      from(fetchCrimeFiltersRequest()).pipe(
        map((payload) => ({
          type: FETCH_CRIME_FILTERS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_CRIME_FILTERS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchIntelligenceFiltersRequest() {
  return {
    classifications: await getFilterValues('Intelligence', 'classifications'),
    subclassifications: await getFilterValues(
      'Intelligence',
      'classifications2'
    ),
    sourceReliabilities: await getFilterValues(
      'Intelligence',
      'sourceReliability'
    ),
    intelligenceReliabilities: await getFilterValues(
      'Intelligence',
      'intelligenceReliability'
    ),
    bcus: await getFilterValues('Intelligence', 'bcu'),
    localAuthorities: await getFilterValues('Intelligence', 'localAuthority'),
    sections: await getFilterValues('Intelligence', 'section'),
    sectors: await getFilterValues('Intelligence', 'sector'),
    beats: await getFilterValues('Intelligence', 'beat'),
    wards: await getFilterValues('Intelligence', 'ward'),
  };
}

export function fetchIntelligenceFiltersEpic(action$) {
  return action$.pipe(
    ofType(FETCH_INTELLIGENCE_FILTERS),
    mergeMap(() =>
      from(fetchIntelligenceFiltersRequest()).pipe(
        map((payload) => ({
          type: FETCH_INTELLIGENCE_FILTERS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_INTELLIGENCE_FILTERS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchStopCheckFiltersRequest() {
  return {
    bcus: await getFilterValues('Intelligence', 'bcu'),
    localAuthorities: await getFilterValues('Intelligence', 'localAuthority'),
    sections: await getFilterValues('Intelligence', 'section'),
    sectors: await getFilterValues('Intelligence', 'sector'),
    beats: await getFilterValues('Intelligence', 'beat'),
    wards: await getFilterValues('Intelligence', 'ward'),
  };
}

export function fetchStopCheckFiltersEpic(action$) {
  return action$.pipe(
    ofType(FETCH_STOP_CHECK_FILTERS),
    mergeMap(() =>
      from(fetchStopCheckFiltersRequest()).pipe(
        map((payload) => ({
          type: FETCH_STOP_CHECK_FILTERS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_STOP_CHECK_FILTERS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchUserIdsRequest() {
  const response = await api.get(`/audits/fields/user/values`, {
    headers: getHeaders(),
  });

  const userIds = response.data;
  userIds.sort();

  return userIds;
}

export function fetchUserIdsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_USER_IDS),
    mergeMap(() =>
      from(fetchUserIdsRequest()).pipe(
        map((payload) => ({
          type: FETCH_USER_IDS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_USER_IDS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
