import {
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import {
  Accessibility as FriskIcon,
  Feedback as FeedbackIcon,
  Gavel as GavelIcon,
  GpsOff as GpsOffIcon,
  SpeakerNotes as SpeakerNotesIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  hoverListItem: {
    backgroundColor: theme.palette.action.hover,
  },
  noGpsIcon: {
    color: theme.palette.divider,
    fontSize: 16,
  },
}));

export default function CollectionEventList({
  hoveredEventIndex,
  onSelectEvent,
  onHoverEvent,
}) {
  const events = useSelector((state) => state.collections.events, _.isEqual);
  const classes = useStyles();

  return (
    events && (
      <List className={classes.list} dense disablePadding>
        {events.incidents?.features?.length > 0 ? (
          <ListSubheader disableSticky>Incidents</ListSubheader>
        ) : (
          ''
        )}
        {events.incidents?.features?.map((feature, index) => (
          <ListItem
            button
            key={index}
            className={
              _.isEqual(
                { featureId: feature.id, typeId: 'incidents' },
                hoveredEventIndex
              )
                ? classes.hoverListItem
                : null
            }
            onMouseEnter={() =>
              onHoverEvent({
                typeId: 'incidents',
                featureId: feature.id,
              })
            }
            onMouseLeave={() => onHoverEvent(null)}
            onClick={() => {
              onSelectEvent({
                typeId: 'incidents',
                featureId: feature.id,
              });
            }}
          >
            <ListItemAvatar>
              <Badge
                badgeContent={
                  feature.properties.responseCategory &&
                  feature.properties.responseCategory.code
                    ? feature.properties.responseCategory.code
                    : feature.properties.grade || '?'
                }
                color="primary"
              >
                <Avatar>
                  <FeedbackIcon />
                </Avatar>
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={feature.properties.description}
              secondary={feature.properties.number}
            />
            {!feature.geometry && (
              <ListItemSecondaryAction>
                <GpsOffIcon
                  title="No GPS Location"
                  className={classes.noGpsIcon}
                />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
        {events.crimes?.features?.length > 0 ? (
          <ListSubheader disableSticky>Crimes</ListSubheader>
        ) : (
          ''
        )}
        {events.crimes?.features?.map((feature, index) => (
          <ListItem
            button
            key={index}
            className={
              _.isEqual(
                { featureId: feature.id, typeId: 'crimes' },
                hoveredEventIndex
              )
                ? classes.hoverListItem
                : null
            }
            onMouseEnter={() =>
              onHoverEvent({
                typeId: 'crimes',
                featureId: feature.id,
              })
            }
            onMouseLeave={() => onHoverEvent(null)}
            onClick={() => {
              onSelectEvent({
                typeId: 'crimes',
                featureId: feature.id,
              });
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <GavelIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                feature.properties.type ? feature.properties.type.name : ''
              }
              secondary={feature.properties.number}
            />
            {!feature.geometry && (
              <ListItemSecondaryAction>
                <GpsOffIcon
                  title="No GPS Location"
                  className={classes.noGpsIcon}
                />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
        {events.intelligence?.features?.length > 0 ? (
          <ListSubheader disableSticky>Intelligence</ListSubheader>
        ) : (
          ''
        )}
        {events.intelligence?.features?.map((feature, index) => (
          <ListItem
            button
            key={index}
            className={
              _.isEqual(
                { featureId: feature.id, typeId: 'intelligence' },
                hoveredEventIndex
              )
                ? classes.hoverListItem
                : null
            }
            onMouseEnter={() =>
              onHoverEvent({
                typeId: 'intelligence',
                featureId: feature.id,
              })
            }
            onMouseLeave={() => onHoverEvent(null)}
            onClick={() => {
              onHoverEvent({
                typeId: 'intelligence',
                featureId: feature.id,
              });
            }}
          >
            <ListItemAvatar>
              {feature.properties.intelligenceReliability ||
              feature.properties.sourceReliability ? (
                <Badge
                  badgeContent={
                    (feature.properties.intelligenceReliability
                      ? feature.properties.intelligenceReliability.code
                      : '?') +
                    (feature.properties.sourceReliability
                      ? feature.properties.sourceReliability.code
                      : '')
                  }
                  color="primary"
                >
                  <Avatar>
                    {feature.properties.type &&
                    feature.properties.type.toLowerCase() ===
                      'stop and search' ? (
                      <FriskIcon />
                    ) : (
                      <SpeakerNotesIcon />
                    )}
                  </Avatar>
                </Badge>
              ) : (
                <Avatar>
                  {feature.properties.type &&
                  feature.properties.type.toLowerCase() ===
                    'stop and search' ? (
                    <FriskIcon />
                  ) : (
                    <SpeakerNotesIcon />
                  )}
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                feature.properties.classifications &&
                feature.properties.classifications.length > 0 &&
                (feature.properties.classifications[0].name || '')
              }
              secondary={feature.properties.number}
            />
            {!feature.geometry && (
              <ListItemSecondaryAction>
                <GpsOffIcon
                  title="No GPS Location"
                  className={classes.noGpsIcon}
                />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    )
  );
}
