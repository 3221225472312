import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';

export default function SelectField({
  input: { onChange, ...input },
  onChange: callback,
  meta,
  values,
  styles = {},
  ...props
}) {
  if (
    input.value &&
    !(values || []).map((item) => item.value || item).includes(input.value)
  ) {
    values = [{ label: input.value, value: input.value }, ...(values || [])];
  }

  return (
    <TextField
      select
      helperText={meta.touched && meta.error}
      error={meta.touched && meta.invalid}
      {...input}
      {...props}
      onChange={(event) => {
        onChange(event);
        if (callback) {
          callback(event);
        }
      }}
    >
      {(values || []).map((item) => {
        return (
          <MenuItem
            key={item.value || item}
            value={item.value || item}
            style={styles[item?.className]}
          >
            {item.label || item}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
