import {
  Avatar,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Gavel as GavelIcon,
  Link as LinkIcon,
} from '@material-ui/icons';
import moment from 'moment';
import React, { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  cardHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    flexGrow: 1,
  },
  remove: {
    color: red[500],
  },
  openedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: red[500],
  },
  closedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: grey[500],
  },
  areasPaper: {
    marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2)
  },
  areaType: {
    fontSize: 10,
  },
  areaName: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  setThemeWorkaround: {
    noret: (theme.overrides = {
      MuiStepper: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    }),
  },
  subheader: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
    fontSize: 14,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  header: {
    paddingLeft: theme.spacing(2),
    fontSize: 14,
    lineHeight: 2,
    paddingBottom: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noTopPadding: {
    paddingTop: 0,
  },
  link: {
    color: theme.palette.text.primary,
    marginLeft: 'auto',
    display: 'flex',
  },
  desc: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    whiteSpace: 'pre-line',
  },
  leftMargin: {
    marginLeft: theme.spacing(1),
  },
  iconButton: {
    marginLeft: -12,
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  actionMargin: {
    marginRight: 0,
  },
}));

function formatDate(date) {
  return moment(date).format('DD/MM/YYYY, HH:mm');
}

function isDict(v) {
  return (
    typeof v === 'object' &&
    v !== null &&
    !(v instanceof Array) &&
    !(v instanceof Date)
  );
}

function renderDictTable(list, classes) {
  let dict = list;
  // make sure we have a dict, if it's an array of blah.code & blah.name
  // let's reformat to a dictionary
  if (!isDict(dict)) {
    dict = {};
    for (let k in list) {
      if (list[k].hasOwnProperty('code') && list[k].hasOwnProperty('name')) {
        dict[list[k].code] = list[k].name;
      } else {
        dict[k] = list[k];
      }
    }
    list = dict;
  }

  return (
    <Table>
      <TableBody>
        {Object.keys(dict).map((key, index) => (
          <TableRow key={index}>
            <TableCell>
              <Typography variant="caption" className={classes.areaType}>
                {key}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="caption" className={classes.areaName}>
                {dict[key]}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default function CrimeContent({ feature }) {
  const p = feature.properties;
  const classes = useStyles();

  return (
    feature && (
      <Fragment>
        <CardHeader
          avatar={
            <Avatar aria-label="Feature" className={classes.avatar}>
              <GavelIcon />
            </Avatar>
          }
          title={p.type.code + ': ' + p.type.name}
          subheader={feature.properties.number}
          className={classes.cardHeader}
          classes={{ action: classes.actionMargin }}
          action={
            p.url && (
              <IconButton className={classes.iconButton}>
                <Link className={classes.link} href={p.url}>
                  <LinkIcon />
                </Link>
              </IconButton>
            )
          }
        />
        <CardContent className={classes.cardContent}>
          <div className={classes.flex}>
            <div className={classes.header}>{p.status}</div>
            {/* {p.url && <Link className={classes.link} href={p.url}><LinkIcon /></Link>} */}
          </div>
          <div className={classes.desc}>{p.description}</div>
          {p.address && (
            <Fragment>
              <Typography
                key="subheader"
                variant="subtitle1"
                className={classes.subheader}
              >
                Address
              </Typography>
              <Paper key="table" className={classes.areasPaper} elevation={0}>
                <div className={classes.flex}>
                  <Typography variant="caption" className={classes.leftMargin}>
                    {p.address.buildingName}, {p.address.streetName},{' '}
                    {p.address.postcode}
                  </Typography>
                  {p.address.url && (
                    <IconButton className={classes.iconButton}>
                      <Link className={classes.link} href={p.address.url}>
                        <LinkIcon />
                      </Link>
                    </IconButton>
                  )}
                </div>
              </Paper>
            </Fragment>
          )}
          <Typography className={classes.subheader} type="subtitle1">
            Timeline
          </Typography>
          <Stepper orientation="vertical" className={classes.noTopPadding}>
            <Step active>
              <StepLabel
                icon={
                  <Avatar className={classes.openedAvatar}>
                    <AddIcon />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {formatDate(p.startTime)}
                  </Typography>
                }
              >
                {<Typography>Start</Typography>}
              </StepLabel>
              <StepContent>
                <div>
                  {p.dispatchType && (
                    <Typography variant="caption">
                      {p.dispatchType.name}
                    </Typography>
                  )}
                </div>
              </StepContent>
            </Step>

            {p.reportedTime && (
              <Step active>
                <StepLabel
                  icon={
                    <Avatar className={classes.closedAvatar}>
                      <CloseIcon />
                    </Avatar>
                  }
                  optional={
                    <Typography variant="caption">
                      {formatDate(p.reportedTime)}
                    </Typography>
                  }
                >
                  {<Typography>Reported</Typography>}
                </StepLabel>
                <StepContent>
                  {!p.classifications
                    ? ''
                    : p.classifications.map((c, index) => (
                        <Typography variant="caption" key={index}>
                          {c.code} {c.name ? ':' : ''} {c.name}
                        </Typography>
                      ))}
                </StepContent>
              </Step>
            )}
          </Stepper>
          {p.areas && (
            <Fragment>
              <Typography
                key="subheader"
                variant="subtitle1"
                className={classes.subheader}
              >
                Areas
              </Typography>
              <Paper key="table" className={classes.areasPaper} elevation={0}>
                {renderDictTable(p.areas, classes)}
              </Paper>
            </Fragment>
          )}
        </CardContent>
      </Fragment>
    )
  );
}
