import {
  AppBar,
  Paper,
  Drawer,
  IconButton,
  Snackbar,
  Toolbar,
  Typography,
  withStyles,
  withWidth,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  CropSquare as CropSquareIcon,
  Place as PlaceIcon,
  Save as SaveIcon,
  ShowChart as ShowChartIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import {
  ADD_FEATURE_TO_COLLECTION,
  FETCH_PLAN,
  FETCH_FEATURE_COLLECTION,
  SAVE_FEATURE_COLLECTION,
  UPDATE_FEATURE_COLLECTION,
  UPDATE_SELECTED_FEATURE_INDEX,
} from '../../actions';
import FeatureCard from '../collections/FeatureCard';
import PlanFeatureList from './PlanFeatureList';
import PlanMap from './PlanMap';

const styles = (theme) => ({
  title: {
    paddingRight: theme.spacing(1),
  },
  subtitle: {
    flex: 1,
  },
  toolbar: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  content: {
    marginTop: 48,
    display: 'flex',
  },
  drawerContent: {
    maxHeight: '80vh',
    overflow: 'scroll',
  },
  backButton: {
    marginRight: 10,
  },
  itemsSection: {
    width: 500,
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mapSection: {
    width: 'calc(100% - 500px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mapCard: {
    height: 'calc(100vh - 64px)',
    margin: theme.spacing(1),
    minWidth: 250,
  },
  speedDial: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  toolbarIcon: {
    marginRight: 0,
  },
});

const featureTypes = [
  { name: 'Perimeter', icon: <CropSquareIcon /> },
  { name: 'Path', icon: <ShowChartIcon /> },
  { name: 'Marker', icon: <PlaceIcon /> },
];

class PlanContents extends Component {
  state = {
    speedDialOpen: false,
    snackbarOpen: false,
    hidden: false,
    drawerOpen: true,
    errors: {},
  };

  validate() {
    const { feature } = this.props;
    let hasErrors = false;
    let newState = {};

    if (!feature.properties.title) {
      newState.title = true;
      hasErrors = true;
    }

    if (!feature.properties.identifier) {
      newState.identifier = true;
      hasErrors = true;
    }

    if (!feature.properties.subtype) {
      newState.subtype = true;
      hasErrors = true;
    }

    if (!feature.geometry) {
      newState.geometry = true;
      hasErrors = true;
    }

    this.setState({
      errors: newState,
    });

    return hasErrors;
  }

  handleSpeedDialActionClick = (type) => {
    this.setState((state) => ({
      speedDialOpen: !state.speedDialOpen,
    }));

    this.props.dispatch({
      type: ADD_FEATURE_TO_COLLECTION,
      payload: {
        id: this.props.featureCollection.features.length,
        type: 'Feature',
        properties: {
          type,
          collections: [this.props.plan.identifier],
        },
        isNew: true,
      },
    });

    this.props.dispatch({
      type: UPDATE_SELECTED_FEATURE_INDEX,
      payload: this.props.featureCollection.features.length,
    });
  };

  handleSpeedDialOpen = () => {
    if (!this.state.hidden) {
      this.setState({
        speedDialOpen: true,
      });
    }
  };

  handleSpeedDialClose = () => {
    this.setState({
      speedDialOpen: false,
    });
  };

  handleDrawerOpen = () => {
    this.setState({
      drawerOpen: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      drawerOpen: false,
    });
  };

  componentDidMount() {
    this.props.dispatch({
      type: FETCH_PLAN,
      payload: this.props.match.params.id,
    });
    this.props.dispatch({
      type: FETCH_FEATURE_COLLECTION,
      payload: this.props.match.params.id,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.props.dispatch({
        type: FETCH_PLAN,
        payload: this.props.match.params.id,
      });
      this.props.dispatch({
        type: FETCH_FEATURE_COLLECTION,
        payload: this.props.match.params.id,
      });
    }

    if (
      Number.isInteger(this.props.selectedFeatureIndex) &&
      this.props.selectedFeatureIndex !== prevProps.selectedFeatureIndex
    ) {
      this.setState({
        drawerOpen: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: UPDATE_SELECTED_FEATURE_INDEX,
      payload: null,
    });
    this.props.dispatch({ type: UPDATE_FEATURE_COLLECTION, payload: null });
  }

  handleBackClick = () => {
    const { selectedFeatureIndex, history } = this.props;

    if (Number.isInteger(selectedFeatureIndex)) {
      const hasErrors = this.validate();
      if (!hasErrors) {
        this.props.dispatch({
          type: UPDATE_SELECTED_FEATURE_INDEX,
          payload: null,
        });
      }
    } else {
      history.goBack();
    }
  };

  handleSaveClick = () => {
    this.props.dispatch({
      type: SAVE_FEATURE_COLLECTION,
      payload: this.props.featureCollection,
    });
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  render() {
    const {
      classes,
      plan,
      featureCollection,
      selectedFeatureIndex,
      width,
    } = this.props;
    const { speedDialOpen } = this.state;

    let isTouch;
    if (typeof document !== 'undefined') {
      isTouch = 'ontouchstart' in document.documentElement;
    }

    return (
      <Fragment>
        <Helmet>
          <title>
            IR3 | Plan Contents
            {plan && plan.title ? ` | ${plan.title}` : ''}
          </title>
        </Helmet>
        <AppBar>
          <Toolbar variant="dense" className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Back"
              className={classes.backButton}
              onClick={this.handleBackClick}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {plan ? plan.title : ''}
            </Typography>
            <Typography variant="caption" className={classes.subtitle}>
              {plan ? plan.identifier : ''}
            </Typography>
            {width === 'xs' && (
              <IconButton
                color="inherit"
                aria-label="Details"
                className={classes.toolbarIcon}
                onClick={this.handleDrawerOpen}
              >
                <ViewListIcon />
              </IconButton>
            )}
            <IconButton
              color="inherit"
              onClick={this.handleSaveClick}
              className={classes.toolbarIcon}
            >
              <SaveIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          {width !== 'xs' && (
            <div className={classes.itemsSection}>
              {Number.isInteger(selectedFeatureIndex) ? (
                <FeatureCard errors={this.state.errors} />
              ) : featureCollection ? (
                <PlanFeatureList />
              ) : (
                ''
              )}
            </div>
          )}
          <div className={classes.mapSection}>
            {width === 'xs' ? (
              <PlanMap />
            ) : (
              <Paper className={classes.mapCard}>
                <PlanMap />
              </Paper>
            )}
          </div>
        </div>
        {width === 'xs' && (
          <Drawer
            anchor="bottom"
            open={this.state.drawerOpen}
            onClose={this.handleDrawerClose}
          >
            <div className={classes.drawerContent}>
              {Number.isInteger(selectedFeatureIndex) ? (
                <FeatureCard errors={this.state.errors} />
              ) : featureCollection ? (
                <PlanFeatureList />
              ) : (
                ''
              )}
            </div>
          </Drawer>
        )}
        <SpeedDial
          FabProps={{
            color: 'secondary',
          }}
          direction="up"
          ariaLabel="Add Feature"
          className={classes.speedDial}
          hidden={selectedFeatureIndex !== null}
          icon={<SpeedDialIcon />}
          onBlur={this.handleSpeedDialClose}
          onClick={this.handleSpeedDialClose}
          onClose={this.handleSpeedDialClose}
          onFocus={isTouch ? undefined : this.handleSpeedDialOpen}
          onMouseEnter={isTouch ? undefined : this.handleSpeedDialOpen}
          onMouseLeave={this.handleSpeedDialClose}
          open={speedDialOpen}
        >
          {featureTypes.map((type) => (
            <SpeedDialAction
              key={type.name}
              icon={type.icon}
              tooltipTitle={type.name}
              onClick={() => this.handleSpeedDialActionClick(type.name)}
            />
          ))}
        </SpeedDial>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Plan saved</span>}
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </Fragment>
    );
  }
}

PlanContents.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    plan: state.plans.plan,
    featureCollection: state.features.featureCollection,
    selectedFeatureIndex: state.features.selectedFeatureIndex,
    feature:
      state.features.featureCollection &&
      Number.isInteger(state.features.selectedFeatureIndex)
        ? state.features.featureCollection.features[
            state.features.selectedFeatureIndex
          ]
        : null,
  };
}

export default connect(mapStateToProps)(
  withWidth()(withStyles(styles)(PlanContents))
);
