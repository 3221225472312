import {
  Avatar,
  Button,
  Paper,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListSubheader,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import {
  CREATE_QUERY,
  DELETE_QUERY,
  FETCH_QUERY_SUCCESS,
  FETCH_QUERY,
  UPDATE_QUERY,
  FETCH_CRIME_FILTERS,
  FETCH_INCIDENT_FILTERS,
  FETCH_INTELLIGENCE_FILTERS,
  FETCH_STOP_CHECK_FILTERS,
  FETCH_BRIEFS,
} from '../../actions';
import { doesIdExist } from '../../apis/utilities';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import { sourceTypes } from '../../data/constants';
import {
  Field,
  TextField,
  SelectField,
  BooleanSelectField,
  DateTimeField,
  ContentField,
  CheckboxField,
  TypesField,
  SelectMultipleField,
  GeometryField,
  required,
  //requiredDateBefore,
  //requiredDateAfter,
} from '../fields';
import Parameters from './parameters';
import { RouteLeavingGuard } from '../controls';

const { maxUploadSize, personGroups } = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  shortField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 100,
  },
  formControl: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  row: {
    width: '100%',
  },
  editor: {
    width: '100%',
    marginBottom: 16,
  },
  header: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  delete: {
    color: theme.palette.error.main,
  },
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  edits: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  briefs: {
    minWidth: 220,
  },
}));

const initialValues = {
  type: 'Query',
};

const mutators = {
  clearValue: ([name], state, { changeValue }) => {
    changeValue(state, name, () => undefined);
  },
  resetFilter: ({ 1: name }, state, { changeValue }) => {
    function wipeSelections(filter) {
      delete filter.value;
      return filter;
    }

    changeValue(state, name, wipeSelections);
  },
};

export default function Query() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const query = useSelector((state) => state.queries.query, _.isEqual);
  const error = useSelector((state) => state.queries.error);
  const briefs = useSelector((state) => state.briefs.briefNames, _.isEqual);
  const filters = useSelector(
    (state) => ({
      incidents: state.filters.incidents,
      crimes: state.filters.crimes,
      intelligence: state.filters.intelligence,
      stopChecks: state.filters.stopChecks,
    }),
    _.isEqual
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_QUERY_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_QUERY,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_BRIEFS,
    });
  }, [id, dispatch]);

  useEffect(() => {
    if (!filters.incidents) {
      dispatch({
        type: FETCH_INCIDENT_FILTERS,
      });
    }
  }, [filters.incidents, dispatch]);

  useEffect(() => {
    if (!filters.crimes) {
      dispatch({
        type: FETCH_CRIME_FILTERS,
      });
    }
  }, [filters.crimes, dispatch]);

  useEffect(() => {
    if (!filters.intelligence) {
      dispatch({
        type: FETCH_INTELLIGENCE_FILTERS,
      });
    }
  }, [filters.intelligence, dispatch]);

  useEffect(() => {
    if (!filters.stopChecks) {
      dispatch({
        type: FETCH_STOP_CHECK_FILTERS,
      });
    }
  }, [filters.stopChecks, dispatch]);

  function handleDelete() {
    if (query) {
      dispatch({
        type: DELETE_QUERY,
        payload: encodeURIComponent(query.identifier),
      });
    }
  }

  async function validate(values) {
    const errors = {};

    if (!query) {
      if (values.identifier) {
        if (values.identifier === 'new') {
          errors.identifier = 'Invalid';
        }
        const exists = await doesIdExist('queries', values.identifier);
        if (exists) {
          errors.identifier = 'Exists';
        }
      }
    }

    const blob = new Blob([JSON.stringify(values)], {
      type: 'application/json',
    });

    if (blob.size > maxUploadSize) {
      snackbar.notify('warning', 'Unable to save, content greater than 5MB');
      errors.description = 'Content greater than 5MB';
    }

    // start/end validation
    /*
    errors.timePeriod = {};
    errors.timePeriod.startTime = requiredDateBefore(values.timePeriod.endTime)(
      values.timePeriod.startTime
    );
    errors.timePeriod.endTime = requiredDateAfter(values.timePeriod.startTime)(
      values.timePeriod.endTime
    );
    */

    return errors;
  }

  function handleSubmit(values) {
    if (query) {
      dispatch({
        type: UPDATE_QUERY,
        payload: values,
      });
    } else {
      dispatch({
        type: CREATE_QUERY,
        payload: values,
      });
    }
  }

  return (
    <Form
      initialValues={query || initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      mutators={mutators}
      render={({
        handleSubmit,
        form: { mutators, reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>IR3 | Query{values.title ? ` | ${values.title}` : ''}</title>
          </Helmet>
          <Paper className={classes.card}>
            <CardHeader
              avatar={
                <Avatar aria-label="Collection" className={classes.avatar}>
                  <SearchIcon />
                </Avatar>
              }
              title={values.title}
              subheader={values.identifier}
            />
            <CardContent className={classes.cardContent}>
              {values.created && (
                <div className={classes.edits}>
                  <Typography variant="caption">
                    {`Created by ${values.created.userId} ${moment(
                      values.created.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </div>
              )}
              {values.lastEdit && (
                <div className={classes.edits}>
                  <Typography variant="caption">
                    {`Last edited by ${values.lastEdit.userId} ${moment(
                      values.lastEdit.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </div>
              )}
              <List>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="title"
                      component={TextField}
                      label="Title"
                      className={classes.textField}
                      validate={required}
                    />
                    <Field
                      name="identifier"
                      component={TextField}
                      label="Identifier"
                      className={classes.textField}
                      disabled={query && 'identifier' in query}
                    />
                    <Field
                      name="source"
                      component={SelectField}
                      label="Source"
                      onChange={() => mutators.clearValue('parameters')}
                      values={Object.entries(sourceTypes).map((source) => {
                        return {
                          value: source[0],
                          label: source[1],
                        };
                      })}
                      className={classes.textField}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Description</ListSubheader>
                <ListItem>
                  <Field
                    name="description"
                    component={ContentField}
                    className={classes.editor}
                  />
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Time Period</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="isRelativeTimePeriod"
                      component={BooleanSelectField}
                      label="Time Period Type"
                      trueLabel="Relative"
                      falseLabel="Absolute"
                      className={classes.textField}
                      onChange={() => mutators.clearValue('timePeriod')}
                    />
                    {values.isRelativeTimePeriod !== undefined &&
                    values.isRelativeTimePeriod ? (
                      <Fragment>
                        <Field
                          name="timePeriod.isRetrospective"
                          label="Direction"
                          component={BooleanSelectField}
                          trueLabel="Last"
                          falseLabel="Next"
                          className={classes.shortField}
                        />
                        <Field
                          name="timePeriod.amount"
                          type="number"
                          label="Amount"
                          component={TextField}
                          className={classes.shortField}
                        />
                        <Field
                          name="timePeriod.unit"
                          label="Unit"
                          component={SelectField}
                          values={['days', 'weeks', 'months'].map((type) => {
                            return { value: type, label: type };
                          })}
                          className={classes.shortField}
                        />
                        <Field
                          name="timePeriod.includeCurrent"
                          component={CheckboxField}
                          label={`Include current ${(
                            (values.timePeriod || {}).unit || ''
                          ).slice(0, -1)}`}
                          className={classes.textField}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Field
                          name="timePeriod.startTime"
                          component={DateTimeField}
                          label="Start Time"
                          className={classes.textField}
                          maxDate={values.endTime || '2100-01-01'}
                        />
                        <Field
                          name="timePeriod.endTime"
                          component={DateTimeField}
                          label="End Time"
                          className={classes.textField}
                          minDate={values.startTime || '1900-01-01'}
                        />
                      </Fragment>
                    )}
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Parameters</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Parameters
                      name="parameters"
                      filters={filters[values.source] || {}}
                      source={values.source}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Groups & Areas</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="areas"
                      types={personGroups}
                      component={TypesField}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Briefs</ListSubheader>
                <ListItem>
                  <div className={classes.briefs}>
                    <Field
                      name="briefs"
                      fullWidth
                      component={SelectMultipleField}
                      suggestions={briefs.map((query) => ({
                        label: query.title,
                        value: query.identifier,
                      }))}
                    />
                  </div>
                </ListItem>
                {values.source === 'Incidents' && (
                  <Fragment>
                    <Divider />
                    <ListSubheader disableSticky>Boundary</ListSubheader>
                    <Field
                      name="boundary"
                      component={GeometryField}
                      geoType="Polygon"
                    />
                  </Fragment>
                )}
              </List>
            </CardContent>
            <CardActions disableSpacing>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={dirty || submitting}
                onClick={() => history.push(`${id}/contents`)}
              >
                Contents
              </Button>
              <Button
                className={classes.delete}
                onClick={() => setDeleteOpen(true)}
                disabled={query === null}
              >
                Delete
              </Button>
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.title || values.identifier}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
          <RouteLeavingGuard when={dirty} />
        </form>
      )}
    />
  );
}
