import React, { Fragment } from 'react';
import { FormHelperText } from '@material-ui/core';
import { ContentEditor } from '../controls';

export default function EditorField({
  input: { value, onChange },
  meta,
  ...props
}) {
  return (
    <Fragment>
      {meta.touched && meta.invalid && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
      <ContentEditor
        {...props}
        placeholder="Write something..."
        value={value}
        onChange={onChange}
      />
    </Fragment>
  );
}
