import {
  Chip,
  InputLabel,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ItemsField({ input: { onChange, value, ...input } }) {
  const classes = useStyles();

  const handleDelete = (type, deletedEntry) => () => {
    onChange({
      ...value,
      [type]: value[type].filter((entry) => entry !== deletedEntry),
    });
  };

  return value ? (
    <List>
      {Object.entries(value)
        .filter(([key, items]) => items && items.length > 0)
        .map((entry) => (
          <ListItem key={entry[0]}>
            <div>
              <InputLabel shrink>{_.startCase(entry[0])}</InputLabel>
              {entry[1].map((item) => (
                <Chip
                  key={item}
                  label={item}
                  onDelete={handleDelete(entry[0], item)}
                  className={classes.chip}
                />
              ))}
            </div>
          </ListItem>
        ))}
    </List>
  ) : null;
}
