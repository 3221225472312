import _ from 'lodash';
import { match } from '../components/retrospective/constants';

export function applySearch(item, searchTexts) {
  if (!searchTexts || searchTexts === '' || !item) {
    return true;
  }

  return _.includes(item.searchString, searchTexts);
}

// export function applyExtendedFilters(item, filters) {
//   if (!item) return true;

//   return filters
//     ? Object.entries(filters).every(([key, options]) =>
//         // for single select: val !== '' ? item[key] === val : true
//         options.length !== 0
//           ? options.some(option => item[key] === option.value)
//           : true
//       )
//     : [];
// }

export function applyAdvancedFilters(item, filters) {
  if (!item || !filters) {
    return true;
  }

  return filters.every((filter) => {
    const itemValue = _.get(item, filter.field);
    const filterValueIsArray = Array.isArray(filter.value);
    const unfinishedFilterDefinition =
      filter.field == null ||
      filter.field === '' ||
      filter.value == null ||
      filter.value === '' ||
      (filterValueIsArray && filter.value.length === 0);

    // if the filter isn't complete don't exclude (i.e. return true)
    if (unfinishedFilterDefinition) {
      return true;
    }

    // if the item's value is an array (e.g. skills)
    // test that any of item's values match
    if (Array.isArray(itemValue)) {
      const itemArrayMatch = (option) =>
        itemValue.some((element) => match(element, filter.condition, option));

      // if the filter's value is also an array...
      if (filterValueIsArray) {
        return filter.value.some((filterOption) =>
          itemArrayMatch(filterOption)
        );
      }

      return itemArrayMatch(filter.value);
    }

    // if the filter is an array test the item's value
    // against any of the filter's values
    if (filterValueIsArray) {
      return filter.value.some((filterOption) =>
        match(itemValue, filter.condition, filterOption)
      );
    }

    // otherwise match filter value against item value
    return match(itemValue, filter.condition, filter.value);
  });
}

// handle filtering in the epic, whenever the filters/search texts change,
// adjust the filteredInIdsByType
// helper for filtering a dictionary
export function objFilter(obj, predicate, arg) {
  return Object.keys(obj)
    .filter((key) => predicate(obj[key], arg))
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
}

// filters an item dictionary based on searchTexts (e.g. "abc") and filters
// (e.g. vehicle.registrations = ["REG1234"])
export function filterDict(dict, searchText, filters, advancedFilters) {
  dict = dict && objFilter(dict, applySearch, searchText?.toLowerCase());
  // dict = dict && objFilter(dict, applyExtendedFilters, filters);
  dict = dict && objFilter(dict, applyAdvancedFilters, advancedFilters);

  return dict;
}

export function filterList(list, searchText, filters, advancedFilters) {
  list =
    list && list.filter((item) => applySearch(item, searchText?.toLowerCase()));
  // list = list && list.filter(item => applyExtendedFilters(item, filters));
  list =
    list && list.filter((item) => applyAdvancedFilters(item, advancedFilters));

  return list;
}

//general filtering function to filter fetched data locally
export function filterLocally(filter, data) {
  return data.filter((dataItem) => {
    return Object.entries(filter.fields).every(([key, value]) => {
      return value === null || value === _.get(dataItem, key);
    });
  });
}

export const idProperties = {
  vehicles: 'identificationNumber',
  people: 'code',
  incidents: 'number',
  accelerometerAlerts: 'identifier',
  plans: 'identifier',
  objectives: 'identifier',
  locations: 'code',
  tasks: 'number',
};
