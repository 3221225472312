import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Container from './IndividualContainer';
import { Helmet } from 'react-helmet-async';
import IndividualTrips from './IndividualTrips';
import IndividualSummary from './IndividualSummary';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function IndividualDriving() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container title="Driving" noShadow>
      <Helmet>
        <title>Driving</title>
      </Helmet>
      <div className={classes.root}>
        <AppBar position="static" title="Driving">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="driving"
            variant="fullWidth"
          >
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Trips" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <IndividualSummary />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <IndividualTrips />
        </TabPanel>
      </div>
    </Container>
  );
}
