import { IconButton } from '@material-ui/core';
//import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrowBack as BackIcon,
  // JLSETTINGS Settings as SettingsIcon,
} from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { MenuButton } from '../../controls';
import { typeIcons } from '../constants';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    minHeight: 48,
    maxHeight: 48,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 420,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  squashedIconContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between',
  },
  squashedIcon: {
    width: 24,
    height: 24,
    [theme.breakpoints.down('xs')]: {
      // margin: -12,
      padding: 2, // ultimately controls the space left/right of the icons
    },
  },
  checked: {
    color: [theme.palette.secondary.main, '!important'],
  },
  unchecked: {
    color: theme.palette.common.white,
  },
  settingsAtEnd: {
    marginLeft: 'auto',
    marginRight: -12,
  },
}));

//class TypeSelectorBar extends React.Component {
function TypeSelectorBar({
  showBack,
  onTypeChange,
  onSettingsSelected,
  type,
  authorisedTypes,
}) {
  const classes = useStyles();
  const history = useHistory();

  //const layerVisibilities = useSelector(state => state.live.layerVisibilities);
  function getIconClassName(checked) {
    return checked ? classes.checked : classes.unchecked;
  }

  const shownTypes = Object.keys(typeIcons).filter((type) =>
    authorisedTypes.includes(type)
  );
  const squashed = shownTypes.length > 6;
  const iconClass = squashed ? classes.squashedIcon : '';

  return (
    <div
      className={`${classes.iconContainer} ${
        squashed ? classes.squashedIconContainer : ''
      }`}
    >
      {showBack ? (
        <IconButton
          className={iconClass}
          color="inherit"
          aria-label="Back"
          onClick={() => history.goBack()}
          //style={{ marginLeft: -12 }}
        >
          <BackIcon />
        </IconButton>
      ) : (
        <MenuButton
          color="inherit"
          className={iconClass}
          //style={{ marginLeft: -12 }}
        />
      )}
      {shownTypes.map((key) => {
        const Icon = typeIcons[key];
        return (
          <IconButton
            key={key}
            className={`${iconClass} ${getIconClassName(key === type)}`}
            onClick={() => onTypeChange(key)}
            title={_.startCase(key)}
          >
            <Icon
            // color={
            //   key !== type && layerVisibilities[key] === false
            //     ? 'disabled'
            //     : undefined
            // }
            />
          </IconButton>
        );
      })}
      {/* JLSETTINGS <IconButton
          className={!!iconClass ? iconClass : classes.settingsAtEnd}
          color="inherit"
          aria-label="Back"
          onClick={() => onSettingsSelected()}
        >
          <SettingsIcon />
        </IconButton> */}
    </div>
  );
}

export default TypeSelectorBar;
