import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Button, Divider, Typography, makeStyles } from '@material-ui/core';
import { Autorenew as AutorenewIcon } from '@material-ui/icons';
import { DateTimePicker, DatePicker, SelectMultiple } from '.';
import { useOptions } from '../options';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0, 2, 2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  field: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  divider: {
    margin: theme.spacing(2, 2, 2, 0),
  },
  filters: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },
  actions: {
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  flex: {
    flexGrow: 1,
  },
  loading: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  spinIcon: {
    // animationName: 'spin'
    animation: '$spin 2000ms linear infinite',
  },
}));

export default function Parameters({
  onFetch,
  onCancel,
  isFetching,
  dateOnly,
  pointEvent,
  value,
  onChange,
  initialValue,
  className,
  style,
  options: externalOptions,
  ...visibleFilters
}) {
  const [state, setState] = useState(initialValue || {});
  const [errors, setErrors] = useState({});
  const classes = useStyles();
  const timeHeaderRef = useRef(null);
  const optionsProvider = useOptions();
  const options = !!externalOptions ? externalOptions : optionsProvider.options;

  const Picker = dateOnly ? DatePicker : DateTimePicker;
  const start = pointEvent ? 'time' : 'startTime';
  const end = pointEvent ? 'time' : 'endTime';

  const query = !!value ? value : state;

  useEffect(() => {
    if (!!initialValue) {
      setState(initialValue);
    }
  }, [initialValue]);

  function updateQuery(update) {
    if (!!value) {
      onChange({
        ...query,
        ...update,
      });
    } else {
      setState({
        ...query,
        ...update,
      });
    }
  }

  function handleClick(event) {
    setErrors({
      startTime: query[end]?.$gte ? null : 'Required',
      endTime: query[start]?.$lt ? null : 'Required',
    });

    if (!query[end]?.$gte || !query[start]?.$lt) {
      timeHeaderRef.current.scrollIntoView();
    } else {
      if (isFetching) {
        onCancel(event);
      } else {
        onFetch(event, query);
      }
    }
  }

  const handleFieldChange = (name) => (value) => {
    updateQuery({
      [name]:
        value.length > 0
          ? { $in: value.map((value) => value.value) }
          : undefined,
    });
  };

  function handleStartTimeChange(value) {
    setErrors({
      ...errors,
      startTime: null,
    });

    updateQuery({
      [end]: { ...query[end], $gte: value ? value.toDate() : undefined },
    });
  }

  function handleEndTimeChange(value) {
    setErrors({
      ...errors,
      endTime: null,
    });

    updateQuery({
      [start]: { ...query[start], $lt: value ? value.toDate() : undefined },
    });
  }

  return (
    <div className={className} style={style}>
      <div className={classes.root}>
        <div className={classes.filters}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            ref={timeHeaderRef}
          >
            Time Period
          </Typography>
          <Picker
            label="Start"
            value={query[end]?.$gte || null}
            onChange={handleStartTimeChange}
            clearable
            className={classes.field}
            maxDate={query[start]?.$lt || '2100-01-01'}
            error={!!errors.startTime}
            helperText={errors.startTime}
          />
          <Picker
            label="End"
            value={query[start]?.$lt || null}
            onChange={handleEndTimeChange}
            clearable
            className={classes.field}
            minDate={query[end]?.$gte || '1900-01-01'}
            disableFuture
            error={!!errors.endTime}
            helperText={errors.endTime}
          />
          {options
            .filter((field) => visibleFilters[field.name])
            .map((filter) => (
              <Fragment key={filter.name}>
                <Divider className={classes.divider} />
                <Typography variant="subtitle2" color="textSecondary">
                  {filter.label}
                </Typography>
                {filter.fields.map((field) => (
                  <SelectMultiple
                    key={field.name}
                    className={classes.field}
                    label={field.label}
                    placeholder="Select..."
                    value={
                      query[`${filter.name}.${field.name}`]?.$in.map(
                        (value) => ({
                          label:
                            field.values.find((field) => field.value === value)
                              ?.label || value,
                          value,
                        })
                      ) || []
                    }
                    onChange={handleFieldChange(`${filter.name}.${field.name}`)}
                    suggestions={field.values}
                  />
                ))}
              </Fragment>
            ))}
        </div>
        <Divider className={classes.divider} />
        <div className={classes.actions}>
          <div className={classes.flex} />
          <Button
            variant="contained"
            color="primary"
            endIcon={
              <AutorenewIcon
                className={isFetching ? classes.spinIcon : undefined}
              />
            }
            onClick={handleClick}
            className={isFetching ? classes.loading : undefined}
          >
            {isFetching ? 'Cancel' : 'Fetch'}
          </Button>
        </div>
      </div>
    </div>
  );
}
