import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Flag as FlagIcon,
  OutlinedFlag as OutlinedFlagIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';
import { RetrospectiveTypeIcon } from '../../../data/constants';
import {
  getPersonPolls,
  shortPersonHeaders,
  radioPollHeaders,
} from '../../../apis/utilities';
import { ExportPicker } from '../../controls';

const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0.5, 1, 1),
  },
  personTable: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  timelineAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
  },
  timelineIcon: {
    fontSize: 16,
  },
}));

export default function PersonTrail({
  color,
  background,
  identifier,
  person,
  startTime,
  endTime,
  distanceKilometres,
}) {
  const history = useHistory();
  const classes = useStyles();

  function handleViewClick() {
    history.push(`/replay/personTrails/${identifier}`);
  }

  const personTrailHeaders = [...shortPersonHeaders, ...radioPollHeaders];

  async function fetchDataToConvert() {
    const polls = await getPersonPolls(person.radioSsi, startTime, endTime);

    const data = polls.map((poll) => ({
      ...poll,
      ...(useReducedResourceInformation
        ? { staffId: person.code, radioSsi: person.radioSsi }
        : {
            name: person.forenames + ' ' + person.surname,
            personRole: person.role,
            collarNumber: person.collarNumber,
            radioSsi: person.radioSsi,
          }),
      time: new Date(poll.time),
      longitude: poll.position ? poll.position.coordinates[0] : 0,
      latitude: poll.position ? poll.position.coordinates[1] : 0,
    }));

    return data;
  }

  const cardHeaderButtons = (
    <div>
      <IconButton
        aria-label="replay"
        title="View Replay"
        onClick={handleViewClick}
      >
        <PlayArrowIcon />
      </IconButton>
      <ExportPicker
        title="Download Person Trail"
        fetchDataToConvert={fetchDataToConvert}
        filename="Person Trail"
        headers={personTrailHeaders}
      />
    </div>
  );

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>
          IR3 | Trail |{' '}
          {useReducedResourceInformation
            ? person.code
            : `${person.forenames || ''}  ${person.surname || ''}`}
        </title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Person Trail"
          >
            <RetrospectiveTypeIcon type="personTrails" />
          </Avatar>
        }
        title={
          useReducedResourceInformation
            ? person.code
            : `${person.forenames || ''}  ${person.surname || ''}`
        }
        subheader={person.collarNumber}
        action={cardHeaderButtons}
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Person
        </Typography>
        <Table size="small" className={classes.personTable}>
          <TableBody>
            {!useReducedResourceInformation && (
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    Role
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{person.role}</Typography>
                </TableCell>
              </TableRow>
            )}
            {person.homeStation && (
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {useReducedResourceInformation
                      ? 'Home Location'
                      : 'Home Station'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {person.homeStation}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {(person.areas || []).map((area, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {_.startCase(area.type)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{area.name}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical">
          <Step active>
            <StepLabel
              icon={
                <Avatar className={classes.timelineAvatar}>
                  <FlagIcon className={classes.timelineIcon} />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(startTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              Start
            </StepLabel>
            <StepContent />
          </Step>
          <Step active>
            <StepLabel
              icon={
                <Avatar className={classes.timelineAvatar}>
                  <OutlinedFlagIcon className={classes.timelineIcon} />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(endTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              End
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Table size="small" className={classes.personTable}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Distance
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${_.round(distanceKilometres * 0.62137119, 2)} miles`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {humanizeDuration(moment(endTime).diff(moment(startTime)))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
