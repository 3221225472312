import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  Paper,
} from '@material-ui/core';
import {
  Business as BusinessIcon,
  Face as FaceIcon,
  Person as PersonIcon,
  SwapHoriz as SwapHorizIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CREATE_PERSON,
  DELETE_PERSON,
  FETCH_HOME_STATIONS,
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  FETCH_WARDS,
  UPDATE_PERSON,
} from '../../actions';
import { doesIdExist, getRfidErrors, ssiValid } from '../../apis/utilities';
import { useAuth } from '../Auth';
import { TagControl } from '../controls';
import { ConfirmationDialog } from '../dialogs';
import {
  AutosuggestField,
  AvatarField,
  CheckboxField,
  ChipField,
  Field,
  required,
  SelectField,
  SelectMultipleField,
  TextField,
  TypesField,
} from '../fields';
import { useSnackbar } from '../Snackbar';
import { RouteLeavingGuard } from '../controls';

const {
  personRoles,
  personRanks,
  personGroups,
  personSkills,
  personEditableFields,
  useDallasKeys,
  useRestricted,
} = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  delete: {
    color: theme.palette.error.main,
  },
  skills: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tagControl: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const categoryIcons = {
  Business: <BusinessIcon />,
  Commute: <SwapHorizIcon />,
  Personal: <FaceIcon />,
};

export default function Person() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const person = useSelector((state) => state.people.person, _.isEqual);
  const error = useSelector((state) => state.people.error);
  const people = useSelector((state) => state.people.people, _.isEqual);
  const homeStationNames = useSelector(
    (state) => state.locations.homeStationNames,
    _.isEqual
  );
  const wards = useSelector((state) => state.locations.wardNames, _.isEqual);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const auth = useAuth();
  const snackbar = useSnackbar();
  const editable = auth.isAuthorised('people', true);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_PERSON_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_PERSON,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
    dispatch({
      type: FETCH_WARDS,
    });
  }, [id, dispatch]);

  async function onSubmit(values) {
    const validSsi = await ssiValid(values.radioSsi, values.code);
    if (!validSsi) {
      return { radioSsi: 'In use' };
    }

    const rfidErrors = await getRfidErrors(values.code, values.rfidCards);

    if (rfidErrors) {
      return { rfidCards: rfidErrors };
    }

    if (person) {
      dispatch({
        type: UPDATE_PERSON,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('people', values.code);
      if (exists) {
        return { code: 'Exists' };
      }

      dispatch({
        type: CREATE_PERSON,
        payload: values,
      });
    }
  }

  function handleDelete() {
    if (person) {
      dispatch({
        type: DELETE_PERSON,
        payload: person.code,
      });
    }
  }

  function isDisabled(fieldName) {
    return !(editable && (personEditableFields[fieldName] || person === null));
  }

  return (
    <Form
      initialValues={person || {}}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>
              IR3 | Person
              {values.forenames
                ? ` | ${values.forenames || ''}  ${values.surname || ''}`
                : ''}
            </title>
          </Helmet>
          <Paper className={classes.card}>
            <CardHeader
              avatar={
                <Field
                  name="picture"
                  component={AvatarField}
                  icon={<PersonIcon />}
                  disabled={isDisabled('picture')}
                />
              }
              title={`${values.forenames || ''}  ${values.surname || ''}`}
              subheader={values.collarNumber}
            />
            <CardContent className={classes.cardContent}>
              <List>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="code"
                      component={TextField}
                      label="Payroll Number"
                      className={classes.textField}
                      validate={required}
                      disabled={!(editable && person === null)}
                    />
                    <Field
                      name="forenames"
                      component={TextField}
                      label="Forenames"
                      className={classes.textField}
                      validate={required}
                      disabled={isDisabled('forenames')}
                    />
                    <Field
                      name="surname"
                      component={TextField}
                      label="Surname"
                      className={classes.textField}
                      validate={required}
                      disabled={isDisabled('surname')}
                    />
                    <Field
                      name="collarNumber"
                      component={TextField}
                      label="Collar Number"
                      className={classes.textField}
                      validate={required}
                      disabled={isDisabled('collarNumber')}
                    />
                    <Field
                      name="rank.code"
                      component={SelectField}
                      label="Rank"
                      values={personRanks.map((rank) => ({
                        label: rank.name,
                        value: rank.code,
                      }))}
                      className={classes.textField}
                      disabled={isDisabled('rank')}
                    />
                    <Field
                      name="role"
                      component={SelectField}
                      label="Role"
                      className={classes.textField}
                      validate={required}
                      values={personRoles}
                      disabled={isDisabled('role')}
                    />
                    <Field
                      name="emailAddress"
                      type="email"
                      component={TextField}
                      label="Email"
                      className={classes.textField}
                      disabled={isDisabled('emailAddress')}
                    />
                    <Field
                      name="supervisorCode"
                      component={AutosuggestField}
                      label="Supervisor"
                      className={classes.textField}
                      disabled={isDisabled('supervisorCode')}
                      suggestions={people
                        .sort((a, b) =>
                          `${a.surname}, ${a.forenames}`.localeCompare(
                            `${b.surname}, ${b.forenames}`
                          )
                        )
                        .map((person) => ({
                          label: `${person.forenames} ${person.surname}`,
                          value: person.code,
                        }))}
                    />
                    {useRestricted && (
                      <Field
                        name="restricted"
                        component={CheckboxField}
                        label="Restricted"
                        className={classes.textField}
                        disabled={isDisabled('restricted')}
                      />
                    )}
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Home</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="homeStation"
                      component={SelectField}
                      label="Station"
                      values={homeStationNames.map((station) => ({
                        label: station.name,
                        value: station.code,
                      }))}
                      className={classes.textField}
                      disabled={isDisabled('homeStation')}
                    />
                  </div>
                </ListItem>
                <ListItem>
                  <div className={classes.skills}>
                    <Field
                      name="wards"
                      label="Wards"
                      fullWidth
                      component={SelectMultipleField}
                      suggestions={wards.map((ward) => ({
                        label: ward.name,
                        value: ward.code,
                      }))}
                      disabled={isDisabled('wards')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Groups & Areas</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="areas"
                      types={personGroups}
                      component={TypesField}
                      disabled={isDisabled('areas')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Skills</ListSubheader>
                <ListItem>
                  <div className={classes.skills}>
                    <Field
                      name="skills"
                      fullWidth
                      types={personSkills}
                      component={TypesField}
                      disabled={isDisabled('skills')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Radio</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="radioSsi"
                      component={TextField}
                      label="SSI"
                      className={classes.textField}
                      disabled={isDisabled('radioSsi')}
                    />
                    <Field
                      label="Last Poll Time"
                      name="lastPollTime"
                      component={TextField}
                      className={classes.textField}
                      format={(value) =>
                        value ? moment(value).fromNow() : 'never'
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>
                  {useDallasKeys ? 'Dallas Keys' : 'RFID Cards'}
                </ListSubheader>
                <ListItem>
                  <div className={classes.skills}>
                    <Field
                      name={'rfidCards'}
                      component={ChipField}
                      categoryIcons={categoryIcons}
                      defaultCategory="Business"
                      fullWidth
                      disabled={isDisabled('rfidCards')}
                    />
                  </div>
                </ListItem>
                {/* TODO: add/remove tag items should be part of the form and control by save/cancle buttons  */}
                <div className={classes.tagControl}>
                  <TagControl item={{ id }} type={'people'} />
                </div>
              </List>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              {editable && (
                <Button
                  className={classes.delete}
                  onClick={() => setDeleteOpen(true)}
                  disabled={person === null}
                >
                  Delete
                </Button>
              )}
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.name || values.collarNumber}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
          <RouteLeavingGuard when={dirty} />
        </form>
      )}
    />
  );
}
