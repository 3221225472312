import {
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  FETCH_TAG,
  FETCH_TAG_SUCCESS,
  FETCH_TAG_FAILURE,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  ADD_TAG_ITEM,
  ADD_TAG_ITEM_SUCCESS,
  ADD_TAG_ITEM_FAILURE,
  DELETE_TAG_ITEM,
  DELETE_TAG_ITEM_SUCCESS,
  DELETE_TAG_ITEM_FAILURE,
} from '../actions';

const INITIAL_STATE = {
  tag: null,
  isLoading: false,
  error: null,
  tags: [],
};

export default function tagsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TAGS:
      return { ...state, isLoading: true, error: null };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_TAGS_FAILURE:
      return {
        ...state,
        tags: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_TAG:
      return { ...state, isLoading: true, error: null };
    case FETCH_TAG_SUCCESS:
      return {
        ...state,
        tag: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_TAG_FAILURE:
      return {
        ...state,
        tag: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_TAG:
      return { ...state, isLoading: true, error: null };
    case UPDATE_TAG_SUCCESS:
      const updatedTags = state.tags.filter(
        (tag) => tag.identifier !== action.payload.identifier
      );
      return {
        ...state,
        tag: action.payload,
        tags: [...updatedTags, action.payload],
        isLoading: false,
        error: null,
      };
    case UPDATE_TAG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_TAG:
      return { ...state, isLoading: true, error: null };
    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        tag: action.payload,
        tags: [...state.tags, action.payload],
        isLoading: false,
        error: null,
      };
    case CREATE_TAG_FAILURE:
      return {
        ...state,
        tag: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_TAG:
      return { ...state, isLoading: true, error: null };
    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        tag: null,
        tags: state.tags.filter((tag) => tag.identifier !== action.payload),
        isLoading: false,
        error: null,
      };
    case DELETE_TAG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case ADD_TAG_ITEM:
      return { ...state };
    case ADD_TAG_ITEM_SUCCESS: {
      const { id, type, value } = action.payload;
      const tagToUpdate = state.tags.find((tag) => tag.identifier === id);

      if (tagToUpdate.items && tagToUpdate.items[type]) {
        tagToUpdate.items[type].push(value);
      } else {
        if (!tagToUpdate.items) {
          tagToUpdate.items = {};
        }
        tagToUpdate.items[type] = [value];
      }

      const newTags = state.tags.filter((tag) => tag.identifier !== id);
      return {
        ...state,
        tags: [...newTags, tagToUpdate],
      };
    }
    case ADD_TAG_ITEM_FAILURE:
      return {
        ...state,
        generalError: `Error adding tags ${action.payload}`,
      };
    case DELETE_TAG_ITEM:
      return { ...state, isLoading: true, error: null };
    case DELETE_TAG_ITEM_SUCCESS: {
      const { identifier, type, value } = action.payload;
      const tagToRemoveItem = state.tags.find(
        (tag) => tag.identifier === identifier
      );

      if (tagToRemoveItem.items && tagToRemoveItem.items[type]) {
        const arr = tagToRemoveItem.items[type];
        arr.splice(arr.indexOf(value), 1);
      }

      const newtTags = state.tags.filter(
        (tag) => tag.identifier !== identifier
      );
      return {
        ...state,
        tag: { ...state.tag, items: action.payload.updatedItems },
        tags: [...newtTags, tagToRemoveItem],
      };
    }
    case DELETE_TAG_ITEM_FAILURE:
      return {
        ...state,
        generalError: `Error deleting tag ${action.payload}`,
      };
    default:
      return state;
  }
}
