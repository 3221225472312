import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import React from 'react';
import avatarForItem from './avatarUtility';

function TaskLiveListItem({
  highlighted,
  onClick,
  item,
  style,
  classes,
  onHoverItem,
}) {
  const idType = { id: item.number, type: 'tasks' };
  const handleHoverEnter = () => onHoverItem(idType);
  const handleHoverLeave = () => onHoverItem({});
  const handleClick = () => onClick(idType);

  return (
    <ListItem
      className={highlighted ? classes.highlightListItem : null}
      button
      style={style}
      onClick={handleClick}
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
    >
      <ListItemAvatar>{avatarForItem(item, 'tasks')}</ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ variant: 'body2' }}
        primary={item.title}
        secondary={item.number}
      />
    </ListItem>
  );
}

export default TaskLiveListItem;
