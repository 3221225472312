import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Container from './Container';
import ir3Logo from '../svg/ir3Logo.svg';
import lbaLogo from '../svg/lbaLogo.svg';

const { announcement } = window.config;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 96px)',
    flex: 1,
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  // footer: {
  //   marginTop: theme.spacing(8),
  //   borderTop: `1px solid ${theme.palette.divider}`,
  //   padding: `${theme.spacing(6)}px 0`
  // },
  title: {
    fontWeight: 'bold',
  },
  announcement: {
    padding: theme.spacing(2, 6),
  },
  announcementHeader: {
    color: theme.palette.error.main,
  },
}));

export default function Home() {
  const classes = useStyles();

  return (
    <Container title="Home">
      <div className={classes.root}>
        <Helmet>
          <title>IR3 | Home</title>
        </Helmet>
        <main className={classes.main}>
          <div className={classes.heroContent}>
            <Typography align="center">
              <img src={ir3Logo} alt="IR3 Logo" style={{ height: 200 }} />
            </Typography>
            <Typography
              component="h1"
              className={classes.title}
              variant="h1"
              align="center"
              color="primary"
              gutterBottom
            >
              IR3
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              component="p"
            >
              IR3 records a complete picture of resource behaviour in response
              to demand. It uses spatial analysis algorithms to classify
              resource activity in real time and retrospectively. IR3 can inform
              strategic and tactical decision making to optimise resource
              allocation and productivity against prioritised demand.
            </Typography>
          </div>
          {announcement && (
            <div className={classes.announcement}>
              <Typography
                variant="subtitle1"
                className={classes.announcementHeader}
              >
                Announcement
              </Typography>
              <Typography variant="body1">{announcement}</Typography>
            </div>
          )}
        </main>
        <footer className={classes.footer}>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            {`© ${new Date().getFullYear()} `}
            <img
              src={lbaLogo}
              alt="L&A Consultants"
              style={{ height: 30, verticalAlign: 'middle' }}
            />
          </Typography>
        </footer>
      </div>
    </Container>
  );
}
