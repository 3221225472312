import {
  Paper,
  IconButton,
  TablePagination,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_ACCELEROMETER_EVENTS,
  FETCH_ACCELEROMETER_EVENTS_CANCELLED,
  UPDATE_ACCELEROMETER_EVENTS_FILTER,
} from '../../../actions';
import Container from '../../Container';
import { FilterPicker, Parameters, Table } from '../../controls';
import { downloadCSV } from '../../../apis/utilities';
import AccelerometerChart from './AccelerometerChart';
import EventPreviewMap from '../../controls/EventPreviewMap';
import { filterLocally } from '../../../data/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  filterField: {
    width: 144,
  },
  progress: {
    margin: theme.spacing(1),
  },
  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
  linkButton: {
    padding: 0,
  },
  drillDown: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  map: {
    width: 320,
    height: 320,
  },
  chart: {
    flex: 1,
    height: 320,
    minWidth: 320,
  },
  noData: {
    height: 320,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    color: theme.palette.text.disabled,
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? [
        {
          label: 'Fleet Number',
          key: 'fleetNumber',
          type: 'text',
          filter: true,
        },
        {
          label: 'Vehicle Type',
          key: 'vehicleType',
          type: 'text',
          filter: true,
        },
      ]
    : [
        {
          label: 'Registration',
          key: 'registrationNumber',
          type: 'text',
          filter: true,
        },
        {
          label: 'Fleet Number',
          key: 'fleetNumber',
          type: 'text',
          filter: true,
        },
        {
          label: 'Vehicle Role',
          key: 'vehicleRole',
          type: 'text',
          filter: true,
        },
      ]),
  { label: 'Time', key: 'time', type: 'date', filter: false },
  { label: 'Has Data', key: 'hasData', type: 'text', filter: true },
  {
    label: 'Maximum Horizontal Force (g)',
    key: 'maxHorizontalForce',
    type: 'number',
    filter: false,
  },
  {
    label: 'Maximum Vertical Force (g)',
    key: 'maxVerticalForce',
    type: 'number',
    filter: false,
  },
  {
    label: 'Maximum Lateral Force (g)',
    key: 'maxLateralForce',
    type: 'number',
    filter: false,
  },
];

function DownloadPollsLink({ entry }) {
  const handleDownloadDataClick = (sourceData) => () => {
    const filename = 'Accelerometer Event Data.csv';
    const data = (sourceData || []).map(
      ({ position: { coordinates }, headingDegrees, time, ...event }) => ({
        ...event,
        time: moment(time).format('YYYY-MM-DD HH:mm:ss.S'),
        headingDegrees: moment(time).millisecond() === 0 ? headingDegrees : '',
        longitude: moment(time).millisecond() === 0 ? coordinates[1] : '',
        latitude: moment(time).millisecond() === 0 ? coordinates[0] : '',
      })
    );

    downloadCSV(data, filename, eventHeaders);
  };

  return (
    <IconButton
      title="Download accelerometer data"
      disabled={entry.hasData === 'No'}
      onClick={handleDownloadDataClick(entry.data)}
    >
      <GetAppIcon />
    </IconButton>
  );
}

const eventHeaders = [
  { label: 'Longitude', key: 'longitude' },
  { label: 'Latitude', key: 'latitude' },
  { label: 'Heading (degrees)', key: 'headingDegrees' },
  { label: 'Speed (mph)', key: 'speedMilesPerHour' },
  { label: 'Time', key: 'time' },
  { label: 'Horizontal Force (g)', key: 'horizontalForce' },
  { label: 'Vertical Force (g)', key: 'verticalForce' },
  { label: 'Lateral Force (g)', key: 'lateralForce' },
];

function Event({ entry }) {
  const classes = useStyles();

  return (
    <div className={classes.drillDown}>
      <div className={classes.map}>
        {entry.point && (
          <EventPreviewMap
            path={entry.path}
            point={entry.point}
            mapType="accelerometerEvents"
          />
        )}
      </div>
      <div className={classes.chart}>
        {entry.data ? (
          <AccelerometerChart data={entry.data} />
        ) : (
          <div className={classes.noData}>
            <div>
              <Typography variant="caption" className={classes.caption}>
                No data
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default function AccelerometerEvents() {
  const dispatch = useDispatch();
  const accelerometerEvents = useSelector(
    (state) => state.events.accelerometerEvents.list,
    _.isEqual
  );
  const filter = useSelector(
    (state) => state.events.accelerometerEvents.filter,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.events.accelerometerEvents.isLoading
  );
  const error = useSelector((state) => state.events.accelerometerEvents.error);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_ACCELEROMETER_EVENTS,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_ACCELEROMETER_EVENTS_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_ACCELEROMETER_EVENTS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const filename = 'Accelerometer Events.csv';
    const data = filteredAccelerometerEvents.map(
      ({ time, point, ...accelerometerEvent }) => ({
        ...accelerometerEvent,
        time: moment(time).format('YYYY-MM-DD HH:mm:ss'),
        latitude: point ? point.coordinates[0] : '',
        longitude: point ? point.coordinates[0] : '',
      })
    );

    downloadCSV(data, filename, [
      ...headers,
      ...[
        { label: 'Longitude', key: 'longitude', type: 'number' },
        { label: 'Latitude', key: 'latitude', type: 'number' },
      ],
    ]);
  }

  const filteredAccelerometerEvents = filterLocally(
    filter,
    accelerometerEvents
  );

  return (
    <Container title="Accelerometer Events">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        className={classes.parameters}
        vehicle
        driver
        pointEvent
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Accelerometer Events</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Accelerometer Events
          </Typography>
          <FilterPicker
            headers={headers}
            data={accelerometerEvents}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            title="Download data"
            disabled={filteredAccelerometerEvents.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={filteredAccelerometerEvents}
            headers={[
              {
                label: '',
                key: 'expand',
                type: 'expand',
                component: Event,
              },
              ...headers,
              {
                label: '',
                key: 'polls',
                type: 'component',
                component: DownloadPollsLink,
              },
            ]}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            keyName="identifier"
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredAccelerometerEvents.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}
