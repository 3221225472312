import {
  Avatar,
  Paper,
  CardContent,
  CardHeader,
  ListSubheader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Router as BoxIcon } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  FETCH_TELEMATICS_BOX_POLLS,
  START_TELEMATICS_BOX_POLLS_STREAM,
  END_TELEMATICS_BOX_POLLS_STREAM,
} from '../../../actions';
import TelematicsBoxVehicles from './TelematicsBoxVehicles';
import {
  signalStrengthIcon,
  convertSoftwareId,
  mapCanBusStatus,
  OnOffIcon,
} from './utilities';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  switch: {
    width: 190,
  },
  cardContent: {
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  centeredCell: {
    textAlign: 'center',
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  setThemeWorkaround: {
    noret: (theme.overrides = {
      MuiTableCell: {
        root: {
          // textAlign: 'center',
          padding: 0,
          [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            borderRight: '1px solid rgb(224,224,224)',
            whiteSpace: 'pre-line',
          },
          [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            borderRight: '1px solid rgb(224,224,224)',
            whiteSpace: 'pre-line',
          },
        },
      },
    }),
  },
  content: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  header: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
}));

export default function TelematicsBoxDetail() {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isMedium = useMediaQuery(theme.breakpoints.down(1000));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  // console.log(isMedium, isXSmall);

  const box = useSelector((state) => state.telematicsBoxes.boxesByImei[id]);
  const { imei, polls, mostRecentTime } = box || {};
  const ordered = _.orderBy(polls || [], ['time'], ['desc']); // sort by time

  const socketIsSubscribed = useSelector(
    (state) => state.telematicsBoxes.socketIsSubscribed
  );

  // startup (only times when id, selectedXXX change)
  useEffect(() => {
    if (imei && !socketIsSubscribed) {
      let lastTime = moment(mostRecentTime || undefined);

      //console.log("Going to listen for " + imei + " around " + lastTime.format());
      // if there is a last response time, and it's not from today, query from then
      if (!lastTime.isValid() || lastTime > moment().subtract(1, 'days')) {
        lastTime = moment();
      }

      let start = lastTime.clone().utc().subtract(1, 'days');
      let end = lastTime.clone().utc();

      dispatch({
        type: FETCH_TELEMATICS_BOX_POLLS,
        payload: {
          imei,
          start,
          end,
        },
      });

      dispatch({
        type: START_TELEMATICS_BOX_POLLS_STREAM,
        payload: { imei },
      });
    }

    // shutdown
    return () => {
      if (socketIsSubscribed) {
        dispatch({
          type: END_TELEMATICS_BOX_POLLS_STREAM,
        });
      }
    };
  }, [imei, mostRecentTime, socketIsSubscribed, dispatch]);

  const dioLabelsFromConfig = (config) => {
    return (
      <TableRow>
        <TableCell>Time</TableCell>
        {Object.keys(config).map((k) => (
          <TableCell key={k} style={{ width: '12%' }}>
            {config[k]}
          </TableCell>
        ))}
        <TableCell>Driver</TableCell>
      </TableRow>
    );
  };

  const dioRows = (polls, config) => {
    return _.filter(polls, (p) => p.diagnosticCode === '7' || p.driver)
      .slice(0, 12)
      .map((poll) => {
        return (
          <TableRow key={poll.identifier}>
            <TableCell>
              {moment(poll.time).format('DD/MM/YYYY, HH:mm:ss')}
            </TableCell>
            {dioValuesFromConfig(poll, config)}
            <TableCell>
              {poll.driver ? poll.driver.identificationReference : ''}
            </TableCell>
          </TableRow>
        );
      });
  };

  const rawInputOn = (inputsBin, key) => {
    let inputNumber = parseInt(key[key.length - 1], 10);
    if (isNaN(inputNumber) || inputNumber > 8) {
      return false;
    }

    return inputsBin[8 - inputNumber] === '1';
  };

  const dioValuesFromConfig = (poll, config) => {
    let inputString = poll.deviceProperties.inputs;
    let inputsDec = parseInt(inputString, 10);
    let inputsBin = (inputsDec >>> 0).toString(2);

    return Object.keys(config).map((k) => {
      return (
        <TableCell key={k} style={{ width: '12%' }}>
          <OnOffIcon
            on={k.startsWith('unused') ? rawInputOn(inputsBin, k) : poll[k]}
          />
        </TableCell>
      );
    });
  };

  const pollLabels = () => {
    return isMedium ? (
      <TableRow>
        <TableCell>F/W</TableCell>
        <TableCell>Time</TableCell>
        <TableCell>Lat</TableCell>
        <TableCell>Lon</TableCell>
        <TableCell>OK</TableCell>
        <TableCell>Ign</TableCell>
        <TableCell>Batt</TableCell>
        <TableCell>Signal</TableCell>
        <TableCell>CAN</TableCell>
      </TableRow>
    ) : (
      <TableRow>
        <TableCell>Firmware Version</TableCell>
        <TableCell>Time</TableCell>
        <TableCell>Latitude</TableCell>
        <TableCell>Longitude</TableCell>
        <TableCell>Location Valid</TableCell>
        <TableCell>Ignition</TableCell>
        <TableCell>Battery</TableCell>
        <TableCell>Signal Strength</TableCell>
        <TableCell>CAN Bus Status</TableCell>
      </TableRow>
    );
  };

  const breakValAt = (val, n) => {
    return val.toString().substring(0, n) + '\n' + val.toString().substring(n);
  };

  const pollRows = (polls, numberToShow = 12) => {
    return polls.slice(0, numberToShow).map((poll) => {
      if (!poll || !poll.position) {
        return null;
      }
      const ll = poll.position.coordinates;
      return (
        <TableRow key={poll.identifier}>
          <TableCell>
            {convertSoftwareId(poll.deviceProperties.softwareId)}
          </TableCell>
          <TableCell>
            {moment(poll.time).format(
              isMedium ? 'DD/MM/YY HH:mm:ss' : 'DD/MM/YYYY, HH:mm:ss'
            )}
          </TableCell>
          <TableCell>{isXSmall ? breakValAt(ll[1], 5) : ll[1]}</TableCell>
          <TableCell>{isXSmall ? breakValAt(ll[0], 5) : ll[0]}</TableCell>
          <TableCell>
            <OnOffIcon
              on={poll.deviceProperties.isValidated}
              onTooltip="valid"
              offTooltip="invalid"
            />
          </TableCell>
          <TableCell>
            <OnOffIcon on={poll.ignitionOn} />
          </TableCell>
          <TableCell>{poll.deviceProperties.batteryVoltage}V</TableCell>
          <TableCell>
            {signalStrengthIcon(poll.deviceProperties.deviceSignalStrength)}
          </TableCell>
          <TableCell>
            {mapCanBusStatus(poll.deviceProperties.canBusStatus)}
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderIoEvents = () => {
    const config = window.config.dioStates;

    return (
      <div>
        <Toolbar disableGutters className={classes.toolbar}>
          <div className={classes.title}>
            <ListSubheader disableGutters disableSticky>
              I/O Events
            </ListSubheader>
          </div>
        </Toolbar>
        <Table>
          <TableHead>{dioLabelsFromConfig(config)}</TableHead>
          <TableBody>{dioRows(ordered, config)}</TableBody>
        </Table>
      </div>
    );
  };

  return (
    <div>
      <Paper className={classes.card}>
        <Helmet>
          <title>IR3 | Telematics Box | {id}</title>
        </Helmet>
        <Toolbar disableGutters style={{ paddingRight: 12 }}>
          <CardHeader
            avatar={
              <Avatar aria-label="Box" className={classes.avatar}>
                <BoxIcon />
              </Avatar>
            }
            title={id}
          />
        </Toolbar>
        <CardContent className={classes.cardContent}>
          <div className={classes.content}>
            <Toolbar disableGutters className={classes.toolbar}>
              <div className={classes.title}>
                <ListSubheader disableGutters disableSticky>
                  Latest Polls
                </ListSubheader>
              </div>
              <div className={classes.spacer} />
            </Toolbar>
            <Table>
              <TableHead>{pollLabels()}</TableHead>
              <TableBody>{pollRows(ordered, 12)}</TableBody>
            </Table>
            {renderIoEvents()}
          </div>
        </CardContent>
      </Paper>
      <TelematicsBoxVehicles imei={id} />
    </div>
  );
}
