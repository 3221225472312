import {
  Avatar,
  Paper,
  CardHeader,
  CircularProgress,
  IconButton,
  TablePagination,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  GetApp as GetAppIcon,
  HowToReg as HowToRegIcon,
  Autorenew as AutorenewIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_OBJECTIVE,
  FETCH_OBJECTIVE_ATTENDANCES,
  FETCH_OBJECTIVE_ATTENDANCES_CANCELLED,
  UPDATE_OBJECTIVE_ATTENDANCES_FILTER,
} from '../../actions';
import { FilterPicker, DatePicker, Table } from '../controls';
import { downloadCSV } from '../../apis/utilities';
import { filterLocally } from '../../data/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  filterField: {
    width: 144,
  },
  progress: {
    margin: theme.spacing(1),
  },
  tableContainer: {
    height: 'calc(100vh - 236px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
  linkButton: {
    padding: 0,
  },
  dateSection: {
    display: 'flex',
  },
  dash: {
    width: theme.spacing(3),
    margin: 'auto',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? [{ label: 'Staff ID', key: 'code', filter: true }]
    : [
        { label: 'Name', key: 'name', filter: true },
        { label: 'Collar Number', key: 'collarNumber', filter: true },
        { label: 'Role', key: 'role', filter: true },
      ]),
  { label: 'Start Time', key: 'startTime', filter: false },
  { label: 'End Time', key: 'endTime', filter: false },
  { label: 'Compliant', key: 'isCompliant', filter: false },
  { label: 'Duration (minutes)', key: 'durationMinutes', filter: false },
];

function ReplayLink({ entry }) {
  const history = useHistory();

  const handleViewClick = (identifier) => () => {
    history.push(`/replay/personObjectiveAttendances/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

const attendeeVisitHeaders = [
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
  },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    type: 'number',
  },
  {
    label: 'Compliant',
    key: 'isCompliant',
    type: 'boolean',
  },
  {
    label: '',
    key: 'replay',
    type: 'component',
    component: ReplayLink,
  },
];

const visitFor = (headers) => (props) => Visit(props, headers);

function Visit({ entry }, headers) {
  const [orderBy, setOrderBy] = useState('startTime');
  const [order, setOrder] = useState('asc');

  function handleOrderChange(order) {
    setOrder(order);
  }

  function handleOrderByChange(orderBy) {
    setOrderBy(orderBy);
    setOrder('asc');
  }

  return (
    <Table
      data={entry.visits}
      headers={headers}
      rowsPerPage={entry.visits.length}
      page={0}
      keyName="identifier"
      orderBy={orderBy}
      order={order}
      onOrderChange={handleOrderChange}
      onOrderByChange={handleOrderByChange}
    />
  );
}

const patrolHeaders = [
  {
    label: '',
    key: 'expand',
    type: 'expand',
    component: visitFor(attendeeVisitHeaders),
    // Footer: useReducedResourceInformation ? 'Totals' : '',
  },
  ...(useReducedResourceInformation
    ? [
        {
          label: 'Staff ID',
          key: 'code',
          type: 'text',
          // Footer: `${filteredAttendances.length} ${
          //   filteredAttendances.length === 1 ? 'person' : 'people'
          // }`,
        },
      ]
    : [
        {
          label: 'Name',
          key: 'name',
          type: 'text',
          // Footer: 'Totals',
        },
        {
          label: 'Collar Number',
          key: 'collarNumber',
          type: 'text',
          // Footer: `${filteredAttendances.length} ${
          //   filteredAttendances.length === 1 ? 'person' : 'people'
          // }`,
        },
        {
          label: 'Role',
          key: 'role',
          type: 'text',
        },
      ]),
  {
    label: 'Compliant Attendances',
    key: 'compliantAttendanceCount',
    type: 'number',
    // Footer: _.round(
    //   filteredAttendances
    //     .map((attendance) => attendance.compliantAttendanceCount)
    //     .reduce((a, b) => a + b, 0) || 0,
    //   2
    // ),
  },
  {
    label: 'Compliant Minutes',
    key: 'compliantAttendanceMinutes',
    type: 'number',
    // Footer: _.round(
    //   filteredAttendances
    //     .map((attendance) => attendance.compliantAttendanceMinutes)
    //     .reduce((a, b) => a + b, 0) || 0,
    //   2
    // ),
  },
  {
    label: 'Non-compliant Attendances',
    key: 'nonCompliantAttendanceCount',
    type: 'number',
    // Footer: _.round(
    //   filteredAttendances
    //     .map((attendance) => attendance.nonCompliantAttendanceCount)
    //     .reduce((a, b) => a + b, 0) || 0,
    //   2
    // ),
  },
  {
    label: 'Non-compliant Minutes',
    key: 'nonCompliantAttendanceMinutes',
    type: 'number',
    // Footer: _.round(
    //   filteredAttendances
    //     .map(
    //       (attendance) => attendance.nonCompliantAttendanceMinutes
    //     )
    //     .reduce((a, b) => a + b, 0) || 0,
    //   2
    // ),
  },
];

const timedVisitHeaders = [
  ...(useReducedResourceInformation
    ? [
        {
          label: 'Staff ID',
          key: 'code',
          type: 'text',
        },
      ]
    : [
        {
          label: 'Name',
          key: 'name',
          type: 'text',
        },
        {
          label: 'Collar Number',
          key: 'collarNumber',
          type: 'text',
        },
        {
          label: 'Role',
          key: 'role',
          type: 'text',
        },
      ]),
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
  },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    type: 'number',
  },
  {
    label: '',
    key: 'replay',
    type: 'component',
    component: ReplayLink,
  },
];

const timeGroups = {
  hourly: 'hour',
  daily: 'day',
  total: null,
};

// const dateFormat = {
//   hourly: 'DD/MM/YYYY, HH:mm',
//   daily: 'DD/MM/YYYY',
//   total: null,
// };

const groupHeader = {
  hourly: 'Hour',
  daily: 'Date',
  total: 'All',
};

export default function ObjectiveAttendances() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const objective = useSelector(
    (state) => state.objectives.objective,
    _.isEqual
  );
  const attendances = useSelector(
    (state) => state.objectives.objectiveAttendances[id] || [],
    _.isEqual
  );
  const isLoading = useSelector((state) => state.objectives.isLoading);
  const filter = useSelector(
    (state) => state.objectives.objectiveAttendances.filter,
    _.isEqual
  );
  const classes = useStyles();

  const visitHeaders = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: visitFor(timedVisitHeaders),
    },
    {
      label: objective ? groupHeader[objective.requiredFrequency] : 'All',
      key: 'group',
      type: objective?.requiredFrequency === 'total' ? 'text' : 'date',
      // accessor: (attendance) =>
      //   objective && objective.requiredFrequency === 'total'
      //     ? 'All'
      //     : moment(attendance.group).format(
      //         dateFormat[objective.requiredFrequency]
      //       ),
      // Footer: 'Totals',
    },
    {
      label: 'Compliant',
      key: 'compliant',
      type: 'boolean',
      // Footer: `${filteredAttendances.length} ${
      //   filteredAttendances.length === 1
      //     ? 'attendance period'
      //     : 'attendance periods'
      // }`,
    },
    {
      label: 'Attendances',
      key: 'attendanceCount',
      type: 'number',
      // Footer: _.round(
      //   filteredAttendances
      //     .map((attendance) => attendance.attendanceCount)
      //     .reduce((a, b) => a + b, 0) || 0,
      //   2
      // ).toString(),
    },
    {
      label: 'Attendance Minutes',
      key: 'attendanceMinutes',
      type: 'number',
      // Footer: _.round(
      //   filteredAttendances
      //     .map((attendance) => attendance.attendanceMinutes)
      //     .reduce((a, b) => a + b, 0) || 0,
      //   2
      // ).toString(),
    },
  ];

  useEffect(() => {
    if (!objective) {
      dispatch({
        type: FETCH_OBJECTIVE,
        payload: id,
      });
    }
  }, [objective, id, dispatch]);

  useEffect(() => {
    if (objective) {
      dispatch({
        type: UPDATE_OBJECTIVE_ATTENDANCES_FILTER,
        payload: {
          ...filter,
          startTime: objective.startTime,
          endTime: objective.endTime,
        },
      });

      fetchObjectiveAttendances();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objective, dispatch]);

  function fetchObjectiveAttendances() {
    dispatch({
      type: FETCH_OBJECTIVE_ATTENDANCES,
      payload: {
        id: objective.identifier,
        type: objective.type,
        timeGroup: timeGroups[objective.requiredFrequency],
        startTime: filter.startTime,
        endTime: moment(filter.endTime).endOf('day').utc().toDate(),
      },
    });
  }

  function handleRefreshClick() {
    if (isLoading) {
      dispatch({
        type: FETCH_OBJECTIVE_ATTENDANCES_CANCELLED,
      });
    } else {
      fetchObjectiveAttendances();
    }
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_OBJECTIVE_ATTENDANCES_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleStartTimeChange(startTime) {
    updateFilter({ startTime });
  }

  function handleEndTimeChange(endTime) {
    updateFilter({ endTime });
  }

  function AttendancesTable({ type, data }) {
    switch (type) {
      case 'Patrol':
        return (
          <Table
            classes={classes}
            data={data}
            headers={patrolHeaders}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            keyName="identifier"
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
        );
      case 'Visit':
        return (
          <Table
            classes={classes}
            data={data.map((entry) => ({
              ...entry,
              compliant:
                entry.attendanceCount >= objective.requiredVisits
                  ? true
                  : false,
            }))}
            headers={visitHeaders}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            keyName="group"
            order={filter.order}
            orderBy={'group'}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
        );
      default:
        return '';
    }
  }

  function handleDownloadClick() {
    const filename = 'Objective Attendances.csv';
    const data = [].concat
      .apply(
        [],
        filteredAttendances.map((group) =>
          group.visits.map((attendance) => ({
            ...group,
            ...attendance,
          }))
        )
      )
      .map((attendance) => ({
        code: attendance.code,
        name: attendance.name,
        collarNumber: attendance.collarNumber,
        role: attendance.role,
        startTime: moment(attendance.startTime).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(attendance.endTime).format('YYYY-MM-DD HH:mm:ss'),
        isCompliant: attendance.isCompliant,
        durationMinutes: _.round(attendance.durationMinutes || 0.0, 2),
      }));

    downloadCSV(data, filename, headers);
  }

  const filteredAttendances = filterLocally(filter, attendances);

  return (
    <Paper className={classes.card}>
      <Helmet>
        <title>
          IR3 | Objective Attendances | {objective ? objective.title : ''}
        </title>
      </Helmet>
      <Toolbar disableGutters style={{ paddingRight: 12 }}>
        <CardHeader
          avatar={
            <Avatar>
              <HowToRegIcon />
            </Avatar>
          }
          className={classes.cardHeader}
          title={objective ? `${objective.title} Attendances` : 'Attendances'}
        />
        {isLoading && (
          <CircularProgress
            className={classes.progress}
            size={16}
            thickness={6}
          />
        )}
        <div className={classes.dateSection}>
          <DatePicker
            value={filter.startTime}
            onChange={handleStartTimeChange}
            clearable
            className={classes.filterField}
            maxDate={filter.endTime || '2100-01-01'}
            minDate={objective ? objective.startTime : undefined}
          />
          <div className={classes.dash}>&nbsp;-&nbsp;</div>
          <DatePicker
            value={moment(filter.endTime).endOf('day').utc()}
            onChange={handleEndTimeChange}
            clearable
            className={classes.filterField}
            minDate={filter.startTime || '1900-01-01'}
            maxDate={objective ? moment(objective.endTime).toDate() : undefined}
          />
        </div>
        <IconButton
          title={isLoading ? 'Cancel' : 'Fetch'}
          onClick={handleRefreshClick}
        >
          {isLoading ? <CloseIcon color="error" /> : <AutorenewIcon />}
        </IconButton>
        <FilterPicker
          headers={headers}
          data={attendances}
          filter={filter}
          onFilterChange={onFilterChange}
        />
        <IconButton
          disabled={attendances.length === 0}
          title="Download"
          onClick={handleDownloadClick}
        >
          <GetAppIcon />
        </IconButton>
      </Toolbar>
      {objective && (
        <AttendancesTable type={objective.type} data={filteredAttendances} />
      )}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={filteredAttendances.length}
        rowsPerPage={filter.rowsPerPage}
        page={filter.page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </Paper>
  );
}
