import _ from 'lodash';
import moment from 'moment';
import {
  FETCH_PERSON_DAILY_ACTIVITY,
  FETCH_PERSON_DAILY_ACTIVITY_SUCCESS,
  FETCH_PERSON_DAILY_ACTIVITY_FAILURE,
  FETCH_PERSON_DAILY_ACTIVITY_CANCELLED,
  LOAD_PERSON_DAILY_ACTIVITY,
  LOAD_PERSON_DAILY_ACTIVITY_SUCCESS,
  LOAD_PERSON_DAILY_ACTIVITY_FAILURE,
  SORT_PERSON_DAILY_ACTIVITY,
  FETCH_PERSON_HOURLY_ACTIVITY,
  FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS,
  FETCH_PERSON_HOURLY_ACTIVITY_FAILURE,
  FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED,
  LOAD_PERSON_HOURLY_ACTIVITY,
  LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS,
  LOAD_PERSON_HOURLY_ACTIVITY_FAILURE,
} from '../actions';

const INITIAL_STATE = {
  daily: {
    data: [],
    groupBy: 'all',
    groupByValues: ['all', 'date'],
    orderBy: 'Group',
    orderByValues: [
      'onRadio',
      'driving',
      'inBase',
      'inHomeWard',
      'respondingToIncidents',
      'attendingObjectives',
      'doubleCrewing',
    ],
    filter: {
      code: [],
      name: [],
      role: [],
      areas: {},
    },
    filterValues: {
      code: [],
      name: [],
      role: [],
      areas: {},
    },
    isLoading: false,
    error: null,
  },
  hourly: {
    data: [],
    filter: {
      code: [],
      name: [],
      role: [],
      areas: {},
    },
    filterValues: {
      code: [],
      name: [],
      role: [],
      areas: {},
    },
    isLoading: false,
    error: null,
  },
};

export default function reportsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PERSON_DAILY_ACTIVITY:
    case LOAD_PERSON_DAILY_ACTIVITY:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_PERSON_DAILY_ACTIVITY_SUCCESS:
    case LOAD_PERSON_DAILY_ACTIVITY_SUCCESS:
      return {
        ...state,
        daily: {
          ...state.daily,
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_PERSON_DAILY_ACTIVITY_FAILURE:
    case LOAD_PERSON_DAILY_ACTIVITY_FAILURE:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_PERSON_DAILY_ACTIVITY_CANCELLED:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: false,
        },
      };
    case SORT_PERSON_DAILY_ACTIVITY:
      return {
        ...state,
        daily: {
          ...state.daily,
          data:
            action.payload === 'Date'
              ? _.sortBy(state.daily.data, [
                  (item) => moment(item[action.payload], 'DD/MM/YYYY'),
                ])
              : _.sortBy(state.daily.data, [action.payload]),
        },
      };
    case FETCH_PERSON_HOURLY_ACTIVITY:
    case LOAD_PERSON_HOURLY_ACTIVITY:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS:
    case LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS:
      return {
        ...state,
        hourly: { ...action.payload, isLoading: false, error: null },
      };
    case FETCH_PERSON_HOURLY_ACTIVITY_FAILURE:
    case LOAD_PERSON_HOURLY_ACTIVITY_FAILURE:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: false,
        },
      };
    default:
      return state;
  }
}
