import {
  Avatar,
  Checkbox,
  FormControl,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    paddingLeft: theme.spacing(1),
    width: '100%',
  },
  label: {
    paddingLeft: 12,
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  checkbox: {
    padding: theme.spacing(0.5),
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    width: 24,
    height: 24,
    fontSize: 12,
  },
  checkedAvatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 24,
    height: 24,
    fontSize: 12,
  },
}));

export default function ToggleListField({ input, meta, values, ...props }) {
  const classes = useStyles();

  function handleChange(event) {
    if (event.target.checked) {
      input.onChange([
        ...(input.value || []),
        parseInt(event.target.value, 10),
      ]);
    } else {
      const index = (input.value || []).indexOf(
        parseInt(event.target.value, 10)
      );
      if (index !== -1) {
        input.onChange(
          input.value.slice(0, index).concat(input.value.slice(index + 1))
        );
      }
    }
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.label} shrink>
          {props.label}
        </InputLabel>
        <div className={classes.container}>
          {values.map((value, index) => (
            <Checkbox
              key={index}
              checked={(input.value || []).includes(index)}
              onChange={handleChange}
              value={index.toString()}
              className={classes.checkbox}
              icon={<Avatar className={classes.avatar}>{value}</Avatar>}
              checkedIcon={
                <Avatar className={classes.checkedAvatar}>{value}</Avatar>
              }
            />
          ))}
        </div>
      </FormControl>
    </div>
  );
}
