import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  CartesianGrid,
  Cell,
  Label,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import { LocationTypeIcon, getTextWidth } from '../../../data/constants';
import { shortHumanizer } from '../../../apis/utilities';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0.5, 1, 1),
    fontSize: 12,
  },
  table: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  caption: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

function CustomTooltip({ active, payload, measure }) {
  if (active) {
    return (
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>{payload[2].value}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  {payload[0].name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{payload[0].value}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  {payload[1].name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {measure.includes('Time')
                    ? shortHumanizer(payload[1].value)
                    : payload[1].value}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  return null;
}

// We need to pass these props in order to avoid having a warning about x, y, width, height being NaN coming from the Cell below
const cellDummyProps = {
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

export default function Area({
  color,
  background,
  name,
  type,
  subtype,
  count,
  quantile,
  measure,
}) {
  const { layerIndex } = useParams();
  const retrospective = useSelector(
    (state) => state.retrospectives.retrospective,
    _.isEqual
  );
  const classes = useStyles();
  const theme = useTheme();
  const data = retrospective.layers[layerIndex].featureCollection.features
    .map(({ properties: { name, count, quantile } }) => ({
      name,
      percentile: _.round(quantile * 100, 0),
      count,
    }))
    .filter((entry) => entry.name !== name)
    .concat({
      name,
      percentile: _.round(quantile * 100, 0),
      count,
    });

  function formatYAxis(tickItem) {
    return measure !== undefined && measure.includes('Time')
      ? shortHumanizer(tickItem)
      : tickItem;
  }

  function getTicks() {
    const max = _.max(data.map((item) => item.count));
    let interval;

    if (max > 86400000 * 28) {
      interval = 604800000;
    } else if (max > 86400000 * 3) {
      interval = 86400000;
    } else if (max > 3600000 * 3) {
      interval = 3600000;
    } else {
      interval = 60000;
    }

    const range = _.range(interval, max, interval);
    return [...range, range[range.length - 1] + interval];
  }

  function getYAxisWidth() {
    if (measure !== undefined && measure.includes('Time')) {
      return (
        _.max(
          getTicks().map((item) =>
            getTextWidth(shortHumanizer(item), '12px Roboto')
          )
        ) + 18
      );
    } else {
      return (
        _.max(
          data.map((item) =>
            getTextWidth((item.count || 0).toString(), '12px Roboto')
          )
        ) + 18
      );
    }
  }

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>IR3 | Area | {name}</title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title={type}
          >
            <LocationTypeIcon type={type} />
          </Avatar>
        }
        title={name}
        subheader={subtype}
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <p align="center">
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.caption}
          >
            {_.startCase(measure)}
          </Typography>
        </p>
        <ResponsiveContainer width="99%" height={280}>
          <ScatterChart>
            <CartesianGrid />
            <XAxis dataKey="percentile" type="number" name="Percentile">
              <Label value="Percentile" position="bottom" offset={-4} />
            </XAxis>
            <YAxis
              dataKey="count"
              type="number"
              name="Count"
              width={getYAxisWidth()}
              tickFormatter={formatYAxis}
              ticks={measure && (measure.includes('Time') ? getTicks() : null)}
            >
              <Label value="Count" position="left" angle={-90} offset={-1} />
            </YAxis>
            <ZAxis dataKey="name" name="Name" />
            <Scatter data={data}>
              {data.map((entry, index) => (
                <Cell
                  key={index}
                  fill={
                    data[index].name === name
                      ? theme.palette.primary.main
                      : theme.palette.grey[500]
                  }
                  {...cellDummyProps}
                />
              ))}
            </Scatter>
            <Tooltip content={<CustomTooltip measure={measure} />} />
          </ScatterChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
