import {
  Avatar,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Add as AddIcon,
  CropSquare as CropSquareIcon,
  Place as PlaceIcon,
  ShowChart as ShowChartIcon,
} from '@material-ui/icons';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, Route, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { FETCH_FEATURES } from '../../actions';
import {
  featureSubtypeColours,
  featureSubtypeIcons,
} from '../../data/constants';
import Container from '../Container';
import Feature from './Feature';
import { SearchBox } from '../controls';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  listSection: {
    width: 220,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  itemSection: {
    width: 'calc(100% - 220px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  list: {
    height: 'calc(100vh - 148px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  active: {
    backgroundColor: theme.palette.action.focus,
  },
  searchBox: {
    padding: theme.spacing(1, 0, 1, 1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(1),
    },
  },
}));

const featureTypes = {
  perimeters: { name: 'Perimeter', icon: <CropSquareIcon /> },
  paths: { name: 'Path', icon: <ShowChartIcon /> },
  markers: { name: 'Marker', icon: <PlaceIcon /> }, //,
};

export default function FeatureList() {
  const { id, featureType } = useParams();
  const dispatch = useDispatch();
  const featureNames = useSelector(
    (state) => state.features.featureNames,
    _.isEqual
  );
  const [filterText, setFilterText] = useState('');
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    dispatch({
      type: FETCH_FEATURES,
      payload: featureTypes[featureType].name,
    });
  }, [dispatch, featureType]);

  function renderListItems() {
    const sortedNames = featureNames
      .filter(
        (feature) =>
          `${feature.title}+${feature.identifier}`
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) > -1 || filterText === ''
      )
      .sort((a, b) =>
        `${a.title}+${a.identifier}`.localeCompare(`${b.title}+${b.identifier}`)
      );
    return sortedNames.map((feature) => (
      <ListItem
        dense
        button
        key={feature.identifier}
        component={NavLink}
        to={`/features/${featureType}/${encodeURIComponent(
          feature.identifier
        )}`}
        exact={false}
        activeClassName={classes.active}
        isActive={() => feature.identifier === id}
      >
        <ListItemAvatar>
          <Avatar
            style={{
              backgroundColor:
                featureType === 'perimeters' &&
                featureSubtypeColours.perimeters[feature.subtype]
                  ? featureSubtypeColours.perimeters[feature.subtype].stroke
                  : null,
            }}
          >
            {featureType === 'markers'
              ? featureSubtypeIcons.markers[feature.subtype] ||
                featureSubtypeIcons.markers.default
              : feature.subtype ||
                (featureType ? featureTypes[featureType].icon : '')}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={feature.title}
          secondary={feature.identifier}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>
    ));
  }

  function handleFilterChange(event) {
    setFilterText(event.target.value);
  }

  return (
    <Container title={_.startCase(featureType)}>
      <Helmet>
        <title>IR3 | {_.startCase(featureType)}</title>
      </Helmet>
      {(!isXs || !id) && (
        <Fragment>
          <div className={classes.listSection}>
            <SearchBox
              value={filterText}
              onChange={handleFilterChange}
              className={classes.searchBox}
            />
            <List dense disablePadding className={classes.list}>
              {renderListItems()}
            </List>
          </div>
          <Fab
            color="secondary"
            aria-label="add"
            className={classes.fab}
            component={Link}
            to={`/features/${featureType}/new`}
          >
            <AddIcon />
          </Fab>
        </Fragment>
      )}
      {(!isXs || id) && (
        <div className={classes.itemSection}>
          <Route path="/features/:featureType/:id">
            <Feature />
          </Route>
        </div>
      )}
    </Container>
  );
}
