import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  UPDATE_HOVER_FEATURE_INDEX,
  UPDATE_SELECTED_FEATURE_INDEX,
} from '../../actions';
import {
  featureSubtypeColours,
  featureSubtypeIcons,
  featureTypeIcons,
} from '../../data/constants';

const useStyles = makeStyles((theme) => ({
  subheader: {
    paddingRight: 0,
  },
  hoverListItem: {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function PlanFeatureList(props) {
  const dispatch = useDispatch();
  const collection = useSelector(
    (state) => state.collections.collection,
    _.isEqual
  );
  const featureCollection = useSelector(
    (state) => state.features.featureCollection,
    _.isEqual
  );
  const hoverFeatureIndex = useSelector(
    (state) => state.features.hoverFeatureIndex
  );
  const classes = useStyles();

  return (
    <List className={classes.list} dense disablePadding>
      {Object.entries(
        featureCollection.features
          .filter((feature) =>
            feature.properties.collections.includes(
              collection ? collection.identifier : ''
            )
          )
          .reduce(
            function (r, a) {
              r[a.properties.type] = r[a.properties.type] || [];
              r[a.properties.type].push(a);
              return r;
            },
            { Perimeter: [], Path: [], Marker: [] }
          )
      ).map((group) => (
        <div key={group[0]}>
          <ListSubheader
            className={classes.subheader}
            disableSticky
          >{`${group[0]}s`}</ListSubheader>
          {group[1].map((feature, index) => (
            <ListItem
              button
              key={index}
              className={
                feature.id === hoverFeatureIndex ? classes.hoverListItem : null
              }
              onMouseEnter={() =>
                dispatch({
                  type: UPDATE_HOVER_FEATURE_INDEX,
                  payload: feature.id,
                })
              }
              onMouseLeave={() =>
                dispatch({
                  type: UPDATE_HOVER_FEATURE_INDEX,
                  payload: null,
                })
              }
              onClick={() => {
                dispatch({
                  type: UPDATE_SELECTED_FEATURE_INDEX,
                  payload: feature.id,
                });
                dispatch({
                  type: UPDATE_HOVER_FEATURE_INDEX,
                  payload: null,
                });
              }}
            >
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor:
                      feature.properties.type === 'Perimeter' &&
                      feature.properties.subtype
                        ? featureSubtypeColours.perimeters[
                            feature.properties.subtype
                          ].stroke
                        : null,
                  }}
                >
                  {feature.properties.type === 'Marker'
                    ? featureSubtypeIcons.markers[feature.properties.subtype] ||
                      featureSubtypeIcons.markers.default
                    : feature.properties.subtype ||
                      (feature.properties.type
                        ? featureTypeIcons[feature.properties.type]
                        : '')}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={feature.properties.title}
                secondary={feature.properties.identifier}
              />
            </ListItem>
          ))}
        </div>
      ))}
    </List>
  );
}
