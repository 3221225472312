import React, { Fragment, useState, useEffect } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';
import { RetrospectiveTypeIcon } from '../../../data/constants';
import { getPersonForPoll } from '../../../apis/utilities';

const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0.5, 1, 1),
  },
  personTable: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  statusGrid: {
    marginTop: theme.spacing(1),
  },
  gridItem: {
    textAlign: 'center',
  },
}));

export default function PersonPoll({
  color,
  background,
  ssi,
  time,
  emergencyButtonOn,
  // person = {},
}) {
  const classes = useStyles();
  const [person, setPerson] = useState({});

  // a radio's person isn't used anywhere else so get & set here instead
  // of going through overkill with an epic and reducer
  useEffect(() => {
      getPersonForPoll(ssi, time).then(person => {
        setPerson(person);
      });
  }, [ssi, time]);

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>
          IR3 | Poll |{' '}
          {(useReducedResourceInformation
            ? person.code
            : `${person.forenames || ''}  ${person.surname || ''}`) || ssi}
        </title>
      </Helmet>
      <CardHeader
        avatar={
          <Avatar
            style={{
              background,
              color,
            }}
            title="Person Poll"
          >
            <RetrospectiveTypeIcon type="personPolls" />
          </Avatar>
        }
        title={
          (useReducedResourceInformation
            ? person.code
            : `${person.forenames || ''}  ${person.surname || ''}`) || ssi
        }
        subheader={person.collarNumber}
      />
      <CardContent>
        {person && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Person
            </Typography>
            <Table size="small" className={classes.personTable}>
              <TableBody>
                {!useReducedResourceInformation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{person.role}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {person.homeStation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {useReducedResourceInformation
                          ? 'Home Location'
                          : 'Home Station'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {person.homeStation}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {(person.areas || []).map((area, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {_.startCase(area.type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{area.name}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Status
        </Typography>
        <Grid
          container
          spacing={2}
          justify="space-around"
          alignItems="center"
          className={classes.statusGrid}
        >
          <Grid item xs={4} className={classes.gridItem}>
            <Typography variant="caption">
              {moment(time).format('DD/MM/YYYY, HH:mm:ss')}
            </Typography>
          </Grid>
          {emergencyButtonOn !== undefined && (
            <Grid item xs={4}>
              <ErrorIcon
                className={classes.statusIcon}
                color={emergencyButtonOn ? 'error' : 'disabled'}
                title="Emergency"
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
