import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import avatarForItem from './avatarUtility';
import { ItemRows } from './ItemRows';
import { TagControl } from '../../controls';

// example object
// $type: "add"
// assignedPerson:
//  code: "124442"
//  collarNumber: "collarNo"
//  forenames: "Tommy"
//  role: "Tommy Role"
//  surname: "Tommy"

// created:
//  time: "2018-12-03T09:42:18.210Z"
//  userId: "sachin.karia@landaconsultants.com"

// group:
//  code: "FOUR"
//  name: "Testing Four"

// id: "TASK_00015"
// lastEdit:
//  time: "2019-01-03T14:02:30.340Z"
//  userId: "sachin.karia@landaconsultants.com"

// number: "TASK_00015"
// priority:
//  code: "2"
//  name: "medium"

// requiresReview: true
// searchString: "[object object]+[object object]+[object object]+[object object]+task_00015+[object object]+true+[object object]+unassigned+[object object]+add+true"
// status:
//  code: "cancelled"
//  name: "cancelled"

// tagChanged: true
// title: "Unassigned"
// type:
//  code: "PATROL002"
//  name: "PATROL002"

const { useReducedResourceInformation } = window.config;

function TaskLiveItem({ item, classes }) {
  const personId =
    (item.assignedPerson &&
      (useReducedResourceInformation
        ? item.assignedPerson.code
        : `${item.assignedPerson.forenames || ''}  ${
            item.assignedPerson.surname || ''
          }`)) ||
    '';

  const sections = {
    details: [
      { label: 'Group', value: (item.group && item.group.name) || '' },
      {
        label: 'Priority',
        value: (item.priority && item.priority.name) || '',
      },
      { label: 'Status', value: (item.status && item.status.name) || '' },
      {
        label: 'Requires review',
        value: item.requiresReview ? 'Yes' : 'No',
      },
    ],
    assignedPerson: [
      { label: 'Person', value: personId },
      {
        label: 'Collar number',
        value: item.assignedPerson && item.assignedPerson.collarNumber,
      },
      !useReducedResourceInformation && {
        label: 'Role',
        value: item.assignedPerson && item.assignedPerson.role,
      },
    ],
    history: [
      ...((item.created && [
        {
          label: 'Created time',
          value:
            item.created.time &&
            moment(item.created.time).format('DD/MM/YYYY HH:mm:ss'),
        },
        { label: 'Created user', value: item.created.userId },
      ]) ||
        []),
      ...((item.lastEdit && [
        {
          label: 'Last edit time',
          value:
            item.lastEdit.time &&
            moment(item.lastEdit.time).format('DD/MM/YYYY HH:mm:ss'),
        },
        { label: 'Last edit user', value: item.lastEdit.userId },
      ]) ||
        []),
    ].filter(Boolean),
  };

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>{`IR3 | Live | Tasks | ${item.name}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'tasks')}
        title={item.name}
        subheader={item.title}
      />
      <CardContent>
        <TagControl item={item} type={'tasks'} />
        {Object.keys(sections)
          .filter((key) => sections[key] && sections[key].length > 0)
          .map((key) => (
            <React.Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {_.startCase(key)}
              </Typography>
              <Table size="small" className={classes.table}>
                <TableBody>{ItemRows(sections[key], item)}</TableBody>
              </Table>
            </React.Fragment>
          ))}
      </CardContent>
    </Card>
  );

  // return (
  //   <React.Fragment>
  //     <div className={{ root: { flexGrow: 1 } }}>
  //       <Grid container style={{ padding: '8px' }}>
  //         <Grid item xs={12}>
  //           <h3>Resource Status</h3>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <p>Number: {item.number}</p>
  //           <p>Title: {item.title}</p>
  //           <p>Description: {item.description}</p>
  //           <p>Status: {item.status.code}</p>
  //           <p>Created: {moment(item.created).format('DD/MM/YYYY hh:mm')}</p>
  //         </Grid>
  //       </Grid>
  //     </div>
  //   </React.Fragment>
  // );
}

export default TaskLiveItem;
