import {
  FETCH_TRIPS,
  FETCH_TRIPS_SUCCESS,
  FETCH_TRIPS_FAILURE,
  FETCH_TRIPS_CANCELLED,
  UPDATE_TRIPS_FILTER,
  UPDATE_TRIPS_QUERY,
  UPDATE_TRIP_CLASSIFICATION,
  UPDATE_TRIP_CLASSIFICATION_SUCCESS,
  UPDATE_TRIP_CLASSIFICATION_FAILURE,
  UPDATE_TRIP_DRIVER,
  UPDATE_TRIP_DRIVER_SUCCESS,
  UPDATE_TRIP_DRIVER_FAILURE,
  FETCH_SPEED_INFRACTIONS,
  FETCH_SPEED_INFRACTIONS_SUCCESS,
  FETCH_SPEED_INFRACTIONS_FAILURE,
  FETCH_SPEED_INFRACTIONS_CANCELLED,
  UPDATE_SPEED_INFRACTIONS_FILTER,
  UPDATE_SPEED_INFRACTIONS_QUERY,
  FETCH_ACCELEROMETER_EVENTS,
  FETCH_ACCELEROMETER_EVENTS_SUCCESS,
  FETCH_ACCELEROMETER_EVENTS_FAILURE,
  FETCH_ACCELEROMETER_EVENTS_CANCELLED,
  UPDATE_ACCELEROMETER_EVENTS_FILTER,
  UPDATE_ACCELEROMETER_EVENTS_QUERY,
  FETCH_VEHICLE_LOCATION_VISITS,
  FETCH_VEHICLE_LOCATION_VISITS_SUCCESS,
  FETCH_VEHICLE_LOCATION_VISITS_FAILURE,
  FETCH_VEHICLE_LOCATION_VISITS_CANCELLED,
  UPDATE_VEHICLE_LOCATION_VISITS_FILTER,
  UPDATE_VEHICLE_LOCATION_VISITS_QUERY,
  FETCH_STOPS,
  FETCH_STOPS_SUCCESS,
  FETCH_STOPS_FAILURE,
  FETCH_STOPS_CANCELLED,
  UPDATE_STOPS_FILTER,
  UPDATE_STOPS_QUERY,
  FETCH_IDLES,
  FETCH_IDLES_SUCCESS,
  FETCH_IDLES_FAILURE,
  FETCH_IDLES_CANCELLED,
  UPDATE_IDLES_FILTER,
  UPDATE_IDLES_QUERY,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED,
  UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER,
  UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_QUERY,
  FETCH_ON_BOARD_DIAGNOSTICS,
  FETCH_ON_BOARD_DIAGNOSTICS_SUCCESS,
  FETCH_ON_BOARD_DIAGNOSTICS_FAILURE,
  FETCH_ON_BOARD_DIAGNOSTICS_CANCELLED,
  UPDATE_ON_BOARD_DIAGNOSTICS_FILTER,
  UPDATE_ON_BOARD_DIAGNOSTICS_QUERY,
  FETCH_TRAILS,
  FETCH_TRAILS_SUCCESS,
  FETCH_TRAILS_FAILURE,
  FETCH_TRAILS_CANCELLED,
  UPDATE_TRAILS_FILTER,
  UPDATE_TRAILS_QUERY,
  FETCH_PERSON_LOCATION_VISITS,
  FETCH_PERSON_LOCATION_VISITS_SUCCESS,
  FETCH_PERSON_LOCATION_VISITS_FAILURE,
  FETCH_PERSON_LOCATION_VISITS_CANCELLED,
  UPDATE_PERSON_LOCATION_VISITS_FILTER,
  UPDATE_PERSON_LOCATION_VISITS_QUERY,
  FETCH_DOUBLE_CREWS,
  FETCH_DOUBLE_CREWS_SUCCESS,
  FETCH_DOUBLE_CREWS_FAILURE,
  FETCH_DOUBLE_CREWS_CANCELLED,
  UPDATE_DOUBLE_CREWS_FILTER,
  UPDATE_DOUBLE_CREWS_QUERY,
  FETCH_ATTENDANCES,
  FETCH_ATTENDANCES_SUCCESS,
  FETCH_ATTENDANCES_FAILURE,
  FETCH_ATTENDANCES_CANCELLED,
  UPDATE_ATTENDANCES_FILTER,
  UPDATE_ATTENDANCES_QUERY,
  FETCH_OUTAGES,
  FETCH_OUTAGES_SUCCESS,
  FETCH_OUTAGES_FAILURE,
  FETCH_OUTAGES_CANCELLED,
  UPDATE_OUTAGES_FILTER,
  UPDATE_OUTAGES_QUERY,
} from '../actions';

const { rowsPerPageOptions } = window.config;

const INITIAL_STATE = {
  trips: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        driverCode: null,
        forenames: null,
        surname: null,
        driverCollarNumber: null,
        personRole: null,
        registrationNumber: null,
        fleetNumber: null,
        vehicleRole: null,
        type: null,
        startLocationType: null,
        startLocationName: null,
        endLocationType: null,
        endLocationName: null,
        classification: null,
      },
      selectedIdentifiers: [],
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
    },
  },
  tripsWithSpeedInfractions: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      fields: {
        driverCode: null,
        driverName: null,
        forenames: null,
        surname: null,
        collarNumber: null,
        personRole: null,
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        vehicleType: null,
        homeStation: null,
        classification: null,
        emergencyEquipmentUsed: null,
        areas: null,
      },
    },
  },
  accelerometerEvents: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        vehicleRole: null,
        vehicleType: null,
        hasData: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'time',
    },
  },
  vehicleLocationVisits: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        locationType: null,
        locationSubtype: null,
        locationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
    },
  },
  stops: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        forenames: null,
        surname: null,
        collarNumber: null,
        personRole: null,
        locationType: null,
        locationSubtype: null,
        locationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
    },
  },
  idles: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        locationType: null,
        locationSubtype: null,
        locationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
    },
  },
  tripsWithMalfunctionIndicatorLight: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        homeStation: null,
        areas: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
    },
  },
  onBoardDiagnostics: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'time',
    },
  },
  trails: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        code: null,
        forenames: null,
        surname: null,
        collarNumber: null,
        role: null,
        startLocationType: null,
        startLocationName: null,
        endLocationType: null,
        endLocationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
    },
  },
  personLocationVisits: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        code: null,
        forenames: null,
        surname: null,
        collarNumber: null,
        role: null,
        rank: null,
        locationType: null,
        locationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
    },
  },
  doubleCrews: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        primaryCode: null,
        primaryName: null,
        primaryCollarNumber: null,
        primaryRole: null,
        secondaryCode: null,
        secondaryName: null,
        secondaryCollarNumber: null,
        secondaryRole: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
    },
  },
  attendances: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
      fields: {
        code: null,
        forenames: null,
        surname: null,
        role: null,
        collarNumber: null,
        identifier: null,
        title: null,
        type: null,
      },
    },
  },
  outages: {
    list: [],
    isLoading: false,
    error: null,
    query: {},
    filter: {
      fields: {
        imei: null,
        registrationNumber: null,
        fleetNumber: null,
        role: null,
        type: null,
        startLocationType: null,
        startLocationName: null,
        endLocationType: null,
        endLocationName: null,
      },
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'startTime',
    },
  },
};

export default function eventsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TRIPS:
    case UPDATE_TRIP_CLASSIFICATION:
    case UPDATE_TRIP_DRIVER:
      return {
        ...state,
        trips: {
          ...state.trips,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_TRIPS_SUCCESS:
      return {
        ...state,
        trips: {
          ...state.trips,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_TRIPS_FAILURE:
    case UPDATE_TRIP_CLASSIFICATION_FAILURE:
    case UPDATE_TRIP_DRIVER_FAILURE:
      return {
        ...state,
        trips: {
          ...state.trips,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_TRIPS_CANCELLED:
      return {
        ...state,
        trips: {
          ...state.trips,
          isLoading: false,
        },
      };
    case UPDATE_TRIPS_FILTER:
      return {
        ...state,
        trips: {
          ...state.trips,
          filter: action.payload,
        },
      };
    case UPDATE_TRIPS_QUERY:
      return {
        ...state,
        trips: {
          ...state.trips,
          query: action.payload,
        },
      };
    case UPDATE_TRIP_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        trips: {
          ...state.trips,
          list: state.trips.list.map((trip) =>
            trip.identifier === action.payload.id
              ? {
                  ...trip,
                  classification: action.payload.classification,
                }
              : trip
          ),
          isLoading: false,
          error: null,
        },
      };
    case UPDATE_TRIP_DRIVER_SUCCESS:
      return {
        ...state,
        trips: {
          ...state.trips,
          list: state.trips.list.map((trip) =>
            trip.identifier === action.payload.id
              ? {
                  ...trip,
                  driver: action.payload.driver,
                }
              : trip
          ),
          isLoading: false,
          error: null,
        },
      };
    case FETCH_SPEED_INFRACTIONS:
      return {
        ...state,
        tripsWithSpeedInfractions: {
          ...state.tripsWithSpeedInfractions,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_SPEED_INFRACTIONS_SUCCESS:
      return {
        ...state,
        tripsWithSpeedInfractions: {
          ...state.tripsWithSpeedInfractions,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_SPEED_INFRACTIONS_FAILURE:
      return {
        ...state,
        tripsWithSpeedInfractions: {
          ...state.tripsWithSpeedInfractions,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_SPEED_INFRACTIONS_CANCELLED:
      return {
        ...state,
        tripsWithSpeedInfractions: {
          ...state.tripsWithSpeedInfractions,
          isLoading: false,
        },
      };
    case UPDATE_SPEED_INFRACTIONS_FILTER:
      return {
        ...state,
        tripsWithSpeedInfractions: {
          ...state.tripsWithSpeedInfractions,
          filter: action.payload,
        },
      };
    case UPDATE_SPEED_INFRACTIONS_QUERY:
      return {
        ...state,
        tripsWithSpeedInfractions: {
          ...state.tripsWithSpeedInfractions,
          query: action.payload,
        },
      };
    case FETCH_ACCELEROMETER_EVENTS:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_ACCELEROMETER_EVENTS_SUCCESS:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_ACCELEROMETER_EVENTS_FAILURE:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_ACCELEROMETER_EVENTS_CANCELLED:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          isLoading: false,
        },
      };
    case UPDATE_ACCELEROMETER_EVENTS_FILTER:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          filter: action.payload,
        },
      };
    case UPDATE_ACCELEROMETER_EVENTS_QUERY:
      return {
        ...state,
        accelerometerEvents: {
          ...state.accelerometerEvents,
          query: action.payload,
        },
      };
    case FETCH_VEHICLE_LOCATION_VISITS:
      return {
        ...state,
        vehicleLocationVisits: {
          ...state.vehicleLocationVisits,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_VEHICLE_LOCATION_VISITS_SUCCESS:
      return {
        ...state,
        vehicleLocationVisits: {
          ...state.vehicleLocationVisits,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_VEHICLE_LOCATION_VISITS_FAILURE:
      return {
        ...state,
        vehicleLocationVisits: {
          ...state.vehicleLocationVisits,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_LOCATION_VISITS_CANCELLED:
      return {
        ...state,
        vehicleLocationVisits: {
          ...state.vehicleLocationVisits,
          isLoading: false,
        },
      };
    case UPDATE_VEHICLE_LOCATION_VISITS_FILTER:
      return {
        ...state,
        vehicleLocationVisits: {
          ...state.vehicleLocationVisits,
          filter: action.payload,
        },
      };
    case UPDATE_VEHICLE_LOCATION_VISITS_QUERY:
      return {
        ...state,
        vehicleLocationVisits: {
          ...state.vehicleLocationVisits,
          query: action.payload,
        },
      };
    case FETCH_STOPS:
      return {
        ...state,
        stops: {
          ...state.stops,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_STOPS_SUCCESS:
      return {
        ...state,
        stops: {
          ...state.stops,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_STOPS_FAILURE:
      return {
        ...state,
        stops: {
          ...state.stops,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_STOPS_CANCELLED:
      return {
        ...state,
        stops: {
          ...state.stops,
          isLoading: false,
        },
      };
    case UPDATE_STOPS_FILTER:
      return {
        ...state,
        stops: {
          ...state.stops,
          filter: action.payload,
        },
      };
    case UPDATE_STOPS_QUERY:
      return {
        ...state,
        stops: {
          ...state.stops,
          query: action.payload,
        },
      };
    case FETCH_IDLES:
      return {
        ...state,
        idles: {
          ...state.idles,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_IDLES_SUCCESS:
      return {
        ...state,
        idles: {
          ...state.idles,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_IDLES_FAILURE:
      return {
        ...state,
        idles: {
          ...state.idles,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_IDLES_CANCELLED:
      return {
        ...state,
        idles: {
          ...state.idles,
          isLoading: false,
        },
      };
    case UPDATE_IDLES_FILTER:
      return {
        ...state,
        idles: {
          ...state.idles,
          filter: action.payload,
        },
      };
    case UPDATE_IDLES_QUERY:
      return {
        ...state,
        idles: {
          ...state.idles,
          query: action.payload,
        },
      };
    case FETCH_ON_BOARD_DIAGNOSTICS:
      return {
        ...state,
        onBoardDiagnostics: {
          ...state.onBoardDiagnostics,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_ON_BOARD_DIAGNOSTICS_SUCCESS:
      return {
        ...state,
        onBoardDiagnostics: {
          ...state.onBoardDiagnostics,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_ON_BOARD_DIAGNOSTICS_FAILURE:
      return {
        ...state,
        onBoardDiagnostics: {
          ...state.onBoardDiagnostics,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_ON_BOARD_DIAGNOSTICS_CANCELLED:
      return {
        ...state,
        onBoardDiagnostics: {
          ...state.onBoardDiagnostics,
          isLoading: false,
        },
      };
    case UPDATE_ON_BOARD_DIAGNOSTICS_FILTER:
      return {
        ...state,
        onBoardDiagnostics: {
          ...state.onBoardDiagnostics,
          filter: action.payload,
        },
      };
    case UPDATE_ON_BOARD_DIAGNOSTICS_QUERY:
      return {
        ...state,
        onBoardDiagnostics: {
          ...state.onBoardDiagnostics,
          query: action.payload,
        },
      };

    case FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT:
      return {
        ...state,
        tripsWithMalfunctionIndicatorLight: {
          ...state.tripsWithMalfunctionIndicatorLight,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS:
      return {
        ...state,
        tripsWithMalfunctionIndicatorLight: {
          ...state.tripsWithMalfunctionIndicatorLight,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE:
      return {
        ...state,
        tripsWithMalfunctionIndicatorLight: {
          ...state.tripsWithMalfunctionIndicatorLight,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED:
      return {
        ...state,
        tripsWithMalfunctionIndicatorLight: {
          ...state.tripsWithMalfunctionIndicatorLight,
          isLoading: false,
        },
      };
    case UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER:
      return {
        ...state,
        tripsWithMalfunctionIndicatorLight: {
          ...state.tripsWithMalfunctionIndicatorLight,
          filter: action.payload,
        },
      };
    case UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_QUERY:
      return {
        ...state,
        tripsWithMalfunctionIndicatorLight: {
          ...state.tripsWithMalfunctionIndicatorLight,
          query: action.payload,
        },
      };
    case FETCH_TRAILS:
      return {
        ...state,
        trails: {
          ...state.trails,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_TRAILS_SUCCESS:
      return {
        ...state,
        trails: {
          ...state.trails,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_TRAILS_FAILURE:
      return {
        ...state,
        trails: {
          ...state.trails,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_TRAILS_CANCELLED:
      return {
        ...state,
        trails: {
          ...state.trails,
          isLoading: false,
        },
      };
    case UPDATE_TRAILS_FILTER:
      return {
        ...state,
        trails: {
          ...state.trails,
          filter: action.payload,
        },
      };
    case UPDATE_TRAILS_QUERY:
      return {
        ...state,
        trails: {
          ...state.trails,
          query: action.payload,
        },
      };
    case FETCH_PERSON_LOCATION_VISITS:
      return {
        ...state,
        personLocationVisits: {
          ...state.personLocationVisits,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_PERSON_LOCATION_VISITS_SUCCESS:
      return {
        ...state,
        personLocationVisits: {
          ...state.personLocationVisits,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_PERSON_LOCATION_VISITS_FAILURE:
      return {
        ...state,
        personLocationVisits: {
          ...state.personLocationVisits,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_PERSON_LOCATION_VISITS_CANCELLED:
      return {
        ...state,
        personLocationVisits: {
          ...state.personLocationVisits,
          isLoading: false,
        },
      };
    case UPDATE_PERSON_LOCATION_VISITS_FILTER:
      return {
        ...state,
        personLocationVisits: {
          ...state.personLocationVisits,
          filter: action.payload,
        },
      };
    case UPDATE_PERSON_LOCATION_VISITS_QUERY:
      return {
        ...state,
        personLocationVisits: {
          ...state.personLocationVisits,
          query: action.payload,
        },
      };
    case FETCH_DOUBLE_CREWS:
      return {
        ...state,
        doubleCrews: {
          ...state.doubleCrews,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_DOUBLE_CREWS_SUCCESS:
      return {
        ...state,
        doubleCrews: {
          ...state.doubleCrews,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_DOUBLE_CREWS_FAILURE:
      return {
        ...state,
        doubleCrews: {
          ...state.doubleCrews,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_DOUBLE_CREWS_CANCELLED:
      return {
        ...state,
        doubleCrews: {
          ...state.doubleCrews,
          isLoading: false,
        },
      };
    case UPDATE_DOUBLE_CREWS_FILTER:
      return {
        ...state,
        doubleCrews: {
          ...state.doubleCrews,
          filter: action.payload,
        },
      };
    case UPDATE_DOUBLE_CREWS_QUERY:
      return {
        ...state,
        doubleCrews: {
          ...state.doubleCrews,
          query: action.payload,
        },
      };
    case FETCH_ATTENDANCES:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_ATTENDANCES_SUCCESS:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_ATTENDANCES_FAILURE:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_ATTENDANCES_CANCELLED:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          isLoading: false,
        },
      };
    case UPDATE_ATTENDANCES_FILTER:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          filter: action.payload,
        },
      };
    case UPDATE_ATTENDANCES_QUERY:
      return {
        ...state,
        attendances: {
          ...state.attendances,
          query: action.payload,
        },
      };
    case FETCH_OUTAGES:
      return {
        ...state,
        outages: {
          ...state.outages,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_OUTAGES_SUCCESS:
      return {
        ...state,
        outages: {
          ...state.outages,
          list: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case FETCH_OUTAGES_FAILURE:
      return {
        ...state,
        outages: {
          ...state.outages,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_OUTAGES_CANCELLED:
      return {
        ...state,
        outages: {
          ...state.outages,
          isLoading: false,
        },
      };
    case UPDATE_OUTAGES_FILTER:
      return {
        ...state,
        outages: {
          ...state.outages,
          filter: action.payload,
        },
      };
    case UPDATE_OUTAGES_QUERY:
      return {
        ...state,
        outages: {
          ...state.outages,
          query: action.payload,
        },
      };
    default:
      return state;
  }
}
