import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';
import {
  ChevronRight as ChevronRightIcon,
  //   EventSeat as EventSeatIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  // NavLink,
  Route,
  useParams,
} from 'react-router-dom';
import _ from 'lodash';
import { FETCH_POST_HIERARCHY } from '../../actions';
import Container from '../Container';
import PostPeople from './RfidCardPeople';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '100%',
    overflow: 'scroll',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    height: '100%',
    overflow: 'auto',
  },
}));

function Tree({ node }) {
  return (
    <TreeItem key={node.code} nodeId={node.code} label={node.name}>
      {Array.isArray(node.children) ? (
        node.children.map((childNode) => <Tree node={childNode} />)
      ) : (
        <Fragment />
      )}
    </TreeItem>
  );
}

export default function PostList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const postHierarchy = useSelector(
    (state) => state.people.postHierarchy,
    _.isEqual
  );
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    dispatch({
      type: FETCH_POST_HIERARCHY,
    });
  }, [dispatch]);

  return (
    <Container title="Posts">
      <div className={classes.page}>
        <Helmet>
          <title>IR3 | Posts</title>
        </Helmet>
        {(!isXs || !id) && (
          <div className={classes.sideBar}>
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpanded={['root']}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <Tree node={postHierarchy} />
            </TreeView>
          </div>
        )}
        {(!isXs || id) && (
          <div className={classes.main}>
            <Route path={`/resources/posts/:id`}>
              <PostPeople />
            </Route>
          </div>
        )}
      </div>
    </Container>
  );
}
