import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  // CardMedia,
  Typography,
} from '@material-ui/core';
import {
  FilterCenterFocus as FocusIcon,
  GpsFixed as FollowAllIcon,
  Delete as DeleteIcon,
  GpsOff as StopFollowingIcon,
} from '@material-ui/icons';
import { red, grey } from '@material-ui/core/colors';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_LIVE_FILTER_OVERRIDE,
  UPDATE_LIVE_FOLLOWED,
  // UPDATE_LIVE_FOLLOW_OVERRIDE,
  DELETE_TAG,
} from '../../../actions';
import { liveFilters } from '../../../data/constants';
import { usePrevious } from '../../../hooks';
import avatarForItem from './avatarUtility';
//import { ItemRows } from './ItemRows';
import EventListItem from './EventLiveListItem';
import IncidentListItem from './IncidentLiveListItem';
import LocationListItem from './LocationLiveListItem';
import ObjectiveListItem from './ObjectiveLiveListItem';
import PeopleListItem from './PeopleLiveListItem';
import PlanListItem from './PlanLiveListItem';
import TaskListItem from './TaskLiveListItem';
import VehicleListItem from './VehicleLiveListItem';
import LiveListItem from './LiveListItem';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';

// TODO refactor as it's shared with livelist index.js
const typeToListItemMap = {
  vehicles: VehicleListItem,
  locations: LocationListItem,
  people: PeopleListItem,
  events: EventListItem,
  incidents: IncidentListItem,
  tasks: TaskListItem,
  plans: PlanListItem,
  objectives: ObjectiveListItem,
};

function TagDetail({
  item: tag,
  classes,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  onFollowBulk,
  followedIdsByType,
  hoveredId,
}) {
  // need access to all the resources for any that have this tag
  const state = useSelector((state) => state.live);

  const filterOverride = useSelector(
    (state) => state.live.filteredInIdsByTypeOverride
  );

  const dispatch = useDispatch();

  const taggedIdsByType = {};
  let taggedCount = 0;
  Object.keys(tag.itemsByType).forEach((type) => {
    const idsOfType = Object.keys(tag.itemsByType[type]);
    if (idsOfType.length > 0) {
      taggedIdsByType[type] = idsOfType;
      taggedCount += idsOfType.length;
    }
  });

  // if the tag overrides the filter it'll be the owner
  const tagOverridesFilter = filterOverride?.owner === tag.id;
  const isFollowType = tag.type === 'follow';
  const [deleteOpen, setDeleteOpen] = useState(false);

  const override = useMemo(
    () => ({
      // make every type filtered out
      ...Object.fromEntries(Object.keys(liveFilters).map((k) => [k, {}])),
      // except for our tagged items and this tag
      ...tag.itemsByType,
      tags: { [tag.id]: true },
      // and set the owner of the override (when we nav away etc.)
      owner: tag.id,
    }),
    [tag]
  );

  const allFollowed = Object.keys(override)
    .filter((type) => !['owner', 'tags'].includes(type))
    .every((type) =>
      Object.keys(override[type]).every((id) => followedIdsByType[type]?.[id])
    );

  function handleFilterToggle() {
    dispatch({
      type: UPDATE_LIVE_FILTER_OVERRIDE,
      payload: tagOverridesFilter ? null : override,
    });
  }

  // function handleFollowToggle() {
  //   dispatch({
  //     type: UPDATE_LIVE_FOLLOW_OVERRIDE,
  //     payload: tagOverridesFollow ? null : override,
  //   });
  // }

  function handleUnfollowingAll() {
    dispatch({
      type: UPDATE_LIVE_FOLLOWED,
      payload: {},
    });

    if (tagOverridesFilter) {
      handleFilterToggle();
    }
  }

  const handleFollowAllToggle = () => {
    const newFollow = !allFollowed;

    onFollowBulk(_.omit(override, ['owner', 'tags']), newFollow);
  };

  function handleTagDelete() {
    dispatch({ type: DELETE_TAG, payload: encodeURIComponent(tag.id) });
  }

  // if the tagged items change (e.g. they hadn't fully loaded yet)
  // re-dispatch the overrides if necessary
  const prevOverride = usePrevious(override);
  useEffect(() => {
    if (!_.isEqual(override, prevOverride)) {
      if (tagOverridesFilter) {
        dispatch({
          type: UPDATE_LIVE_FILTER_OVERRIDE,
          payload: override,
        });
      }
    }
  }, [dispatch, prevOverride, override, tagOverridesFilter]);

  const showTypeHeader = (type) => {
    const header = _.startCase(type);
    const headerWithMessage = `${header} (${taggedIdsByType[type].length})`;
    return _.has(typeToListItemMap, type) ? header : headerWithMessage;
  };

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>{`IR3 | Live | Tags | ${tag.id}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(tag, 'tags')}
        title={tag.id}
        subheader={`${taggedCount} item${taggedCount === 1 ? '' : 's'}`}
        action={
          <React.Fragment>
            <IconButton
              aria-label={`Focus on ${
                isFollowType ? 'followed' : 'tagged'
              } items`}
              onClick={handleFilterToggle}
              disabled={isFollowType && taggedCount === 0}
              title={`Focus on ${isFollowType ? 'followed' : 'tagged'} items`}
            >
              <FocusIcon
                fontSize="small"
                color={tagOverridesFilter ? 'primary' : 'inherit'}
              />
              {/* <TemporaryIcon className={classes.temporary} /> */}
            </IconButton>
            {isFollowType ? (
              <IconButton
                aria-label="Stop following all"
                onClick={handleUnfollowingAll}
                title="Stop following all"
                disabled={taggedCount === 0}
              >
                <StopFollowingIcon
                  fontSize="small"
                  htmlColor={taggedCount === 0 ? grey[500] : red[500]}
                />
              </IconButton>
            ) : (
              <React.Fragment>
                <IconButton
                  aria-label="Toggle follow on tagged items"
                  onClick={handleFollowAllToggle}
                  title={`Toggle follow on tagged items`}
                >
                  <FollowAllIcon
                    fontSize="small"
                    color={allFollowed ? 'primary' : 'inherit'}
                  />
                  {/* <TemporaryIcon className={classes.temporary} /> */}
                </IconButton>
                <IconButton
                  aria-label="Delete tag"
                  onClick={() => setDeleteOpen(true)}
                  title="Delete tag"
                  disabled={isFollowType && taggedCount === 0}
                >
                  <DeleteIcon fontSize="small" htmlColor={red[500]} />
                </IconButton>
                <ConfirmationDialog
                  action="Delete"
                  open={deleteOpen}
                  itemId={tag.id}
                  onOk={handleTagDelete}
                  onCancel={() => setDeleteOpen(false)}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        }
      />

      <CardContent>
        {Object.keys(taggedIdsByType)
          // show types that we can show first
          .filter((type) => typeToListItemMap[type])
          .sort()
          .map((type) => (
            <React.Fragment key={type}>
              <Typography variant="subtitle2" color="textSecondary">
                {showTypeHeader(type)}
              </Typography>
              {taggedIdsByType[type].map((id) => {
                // try to find the item
                const item = state[type]?.[id];
                if (!item) {
                  return (
                    <LiveListItem
                      key={id}
                      item={{ id, secondary: '[Unavailable on live]' }}
                      type={type}
                      primaryPath={'id'}
                      secondaryPath={'secondary'}
                      hideFollow
                    />
                  );
                } else {
                  const ListComponent = typeToListItemMap[type];
                  return (
                    <ListComponent
                      key={id}
                      onClick={onSubItemClick}
                      highlighted={hoveredId === id}
                      onHoverItem={onSubItemHover}
                      onFollowToggle={onFollowToggle}
                      followedIdsByType={followedIdsByType}
                      classes={classes}
                      item={item}
                      // hideFollow={true}
                    />
                  );
                }
              })}
            </React.Fragment>
          ))}
        {Object.keys(taggedIdsByType)
          // show the types that we can't display on live last
          .filter((type) => !typeToListItemMap[type])
          .sort()
          .map((type) => (
            <React.Fragment key={type}>
              <Typography variant="subtitle2" color="textSecondary">
                {showTypeHeader(type)}
              </Typography>
            </React.Fragment>
          ))}
      </CardContent>
    </Card>
  );
}

export default TagDetail;
