import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import { LocationSearching as FollowIcon } from '@material-ui/icons';
import NavigationIcon from '@material-ui/icons/Navigation';
import RvHookupIcon from '@material-ui/icons/RvHookup';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WarningIcon from '@material-ui/icons/Warning';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import _ from 'lodash';
import {
  AlarmLight as AlarmLightIcon,
  Engine as EngineIcon,
  CarLightHigh as HeadlightsFlashIcon
} from 'mdi-material-ui';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import avatarForItem from './avatarUtility';
import IncidentLiveListItem from './IncidentLiveListItem';
import PeopleLiveListItem from './PeopleLiveListItem';
import CallSignLiveListItem from './CallSignLiveListItem';
import { areasToLabelAccessors, toLabelAccessors, ItemRows } from './ItemRows';
import { FETCH_LIVE_VEHICLE } from '../../../actions/types';
import { usePrevious } from '../../../hooks';
import { TagControl } from '../../controls';

// sample object
/*
areas: Array(1)
  0: {name: "West Midlands", type: "region"}

// assume the same as officers
assignments:
  callSign:
    category: "Available"
    code: "BT2000"
    status: "On Duty"
    time: "2019-01-01T03:00:00Z"
  incident:
    number: "DEV-111111-1111"
    time: "2019-01-01T03:00:00Z"


currentLocations: Array(1)
  0: {code: "TEST", name: "Test Boundary", type: "Force Boundary"}

driver:
  areas: (6) [{...}, {...}, {...}, {...}, {...}, {...}]
  category: "Constable"
  class: "Driver"
  code: "EMP10005"
  collarNumber: "1563"
  forenames: "Forename"
  identificationCategory: "Driver"
  identificationMethod: "rfid"
  identificationReference: "BBBBCCCCCC999F"
  identificationTime: "2018-06-01T08:56:35.000Z"
  role: "Specalist Operations Territorial Support Officer"
  skills: Array(10)
    0: {name: "NPoCC - CBRN Lic Search Offr", type: "skill"}
    1: {name: "NPoCC - CBRN Responder", type: "skill"}
    2: {name: "NPoCC - Level 2 PSU Officer", type: "skill"}
    3: {name: "NPoCC - Licensed Search Officer", type: "skill"}
    4: {name: "NPoCC - TASER X2 SPEC TRAIN OFF", type: "skill"}
    5: {name: "SW - 4x4 Off Road", type: "skill"}
    6: {name: "SW - 4x4 On Road", type: "skill"}
    7: {name: "SW - Method of Entry", type: "skill"}
    8: {name: "SW - Police Constable Tutor", type: "skill"}
    9: {name: "SW - Taser Officer", type: "skill"}
  length: 10
  surname: "Surname"

fleetNumber: "HA0786"
headingDegrees: 199
homeStation: "Hilton Park Outstation"
id: "HA0786"
identificationNumber: "HA0786"
ignitionOn: true
lastDriver:
  category: ""
  code: ""
  collarNumber: ""
  forenames: ""
  identificationCategory: ""
  identificationMethod: ""
  identificationReference: ""
  identificationTime: "2018-06-01T07:09:07.000Z"
  skills: []
  surname: ""

lastIgnitionOffTime: "2018-06-01T08:56:35.000Z"
lastPollTime: "2019-06-01T00:00:00.000Z"
make: "Landrover"
malfunctionIndicatorLightOn: false
model: "Discovery"
position:
  coordinates: (2) [-2.290308, 52.994519]
  type: "Point"

registrationNumber: "LJ15LTE"
role: "Traffic Officer"
speedKilometresPerHour: 0
tagChanged: true
telematicsBoxImei: "357207051045995"
*/

const { useReducedResourceInformation } = window.config;
const knownDioStates = [
  'beaconsOn',
  'sirensOn',
  'amberLightsOn',
  'trailerOn',
  'rearBlueLightsOn',
  'rearRedLightsOn',
];

function VehicleLiveItem({
  item,
  classes,
  onFollowToggle,
  followedIdsByType,
  onSubItemClick,
  onSubItemHover,
  hoveredId,
}) {
  const vehicleRecord = useSelector(
    (state) => state.live.vehicleRecordsById[item.id]
  );

  const dispatch = useDispatch();
  const prevId = usePrevious(item.id);
  useEffect(() => {
    if (prevId !== item.id && !vehicleRecord) {
      dispatch({
        type: FETCH_LIVE_VEHICLE,
        payload: item.id,
      });
    }
  }, [dispatch, item.id, prevId, vehicleRecord]);

  const incidents = useSelector((state) => state.live.incidents);
  const incident =
    item.assignments?.incident?.number &&
    (incidents[item.assignments.incident.number] || {
      id: item.assignments.incident.number,
    });

  const callSigns = useSelector((state) => state.live.callSigns);
  const callSign = callSigns[item.assignments?.callSign?.code];

  const people = useSelector((state) => state.live.people);
  const driver = item.ignitionOn ? item.driver : item.lastDriver;
  const matchedDriver = driver && people[driver.code];
  if (matchedDriver) {
    matchedDriver.id = matchedDriver.code;
  }

  const type = 'vehicles';
  const following = followedIdsByType?.[type]?.[item.id];

  function getDriverSection(driverName, driver) {
    driver = matchedDriver || driver;
    if (!driver) {
      return null;
    }

    let title = driverName;
    if (title === null || title.match(/^ *$/) !== null) {
      title = driver.code;
    }

    function handleClick() {
      if (matchedDriver) {
        onSubItemClick({ id: matchedDriver.id, type: 'people' });
      } // o/w nothing to nav to
    }

    return (
      <PeopleLiveListItem
        onClick={handleClick}
        onHoverItem={onSubItemHover}
        classes={classes}
        item={matchedDriver || { id: '_nomatch', name: driverName, role: '' }}
        highlighted={hoveredId === driver.code}
        onFollowToggle={onFollowToggle}
        followedIdsByType={followedIdsByType}
        hideFollow={!matchedDriver}
      />
      // <ListItem>
      //   <ListItemAvatar>
      //     <Avatar title="Person">{replayItemTypeIcons['person']}</Avatar>
      //   </ListItemAvatar>
      //   <ListItemText
      //     primaryTypographyProps={{ variant: 'body2' }}
      //     primary={title}
      //     secondary={driver.role}
      //   />
      // </ListItem>
    );
  }

  const sections = {
    status: [
      // these are already in as status icons
      // { label: 'Ignition', value: item.ignitionOn ? 'On' : 'Off' },
      // {
      //   label: 'Malfunction Indicator Light',
      //   value: item.malfunctionIndicatorLightOn ? 'On' : 'Off'
      // },
      ...Object.keys(window.config.dioStates)
        .filter((key) => !knownDioStates.includes(key)) // filter out ones covered by icons
        .map(
          (key) =>
            item[key] !== undefined && {
              label: window.config.dioStates[key],
              value: item[key] ? 'On' : 'Off',
            }
        )
        .filter(Boolean),
      {
        label: 'Speed',
        value: _.round(item.speedKilometresPerHour * 0.62137199, 2) + ' MPH',
      },
      {
        label: 'Last poll time',
        value:
          item.lastPollTime &&
          moment(item.lastPollTime).format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        label: 'Last ignition off time',
        value:
          item.lastIgnitionOffTime &&
          moment(item.lastIgnitionOffTime).format('DD/MM/YYYY HH:mm:ss'),
      },
    ],
    detail: [
      {
        label: 'Description',
        value: [item.colour, item.make, item.model].filter(Boolean).join(' '),
      },
      ...(useReducedResourceInformation
        ? [{ label: 'Home Location', value: item.homeStation }]
        : [
            { label: 'Role', value: item.role },
            { label: 'Home Station', value: item.homeStation },
          ]),
      // these are already in the avatar, no need to repeat
      // { label: "Fleet Number", value: item.fleetNumber },
      // { label: "Registration", value: item.registrationNumber },
      { label: 'Type', value: item.type },
      // these are covered by description
      // { label: 'Make', value: item.make },
      // { label: 'Model', value: item.model },
      // { label: 'Colour', value: item.colour },
      item.equipment?.length > 0
        ? { label: 'Equipment', value: item.equipment?.join(', ') || '' }
        : false,
      { label: 'IMEI', value: item.telematicsBoxImei },
      ...areasToLabelAccessors(item.areas),
    ].filter(Boolean),
    driver: item.ignitionOn && getDriverSection(item.driverName, item.driver),
    lastDriver:
      !item.ignitionOn && getDriverSection(item.driverName, item.lastDriver),
    locations: toLabelAccessors(item.currentLocations, 'type', 'name'),
  };

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>
          {`IR3 | Live | Vehicles | 
          ${
            useReducedResourceInformation
              ? item.fleetNumber
              : item.registrationNumber
          }`}
        </title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'vehicles')}
        title={
          useReducedResourceInformation
            ? item.fleetNumber
            : item.registrationNumber
        }
        subheader={
          useReducedResourceInformation
            ? item.identificationNumber
            : item.fleetNumber
        }
        action={
          <IconButton
            title={following ? 'Stop following' : 'Follow'}
            onClick={(e) => {
              e.stopPropagation();
              onFollowToggle('vehicles', item.id);
            }}
          >
            <FollowIcon
              titleAccess="Follow"
              fontSize="small"
              color={following ? 'primary' : 'disabled'}
            />
          </IconButton>
        }
      />
      {vehicleRecord && vehicleRecord.picture && (
        <CardMedia style={{ height: 420 }} image={vehicleRecord.picture} />
      )}
      <CardContent>
        <React.Fragment>
          <NavigationIcon
            // fromCharCode so degrees symbol doesn't stop chrome debugger mapping
            titleAccess={
              'Heading ' + item.headingDegrees + String.fromCharCode(176)
            }
            className={classes.statusIconLarge}
            style={{
              transform: `rotate(${item.headingDegrees}deg)`,
              transformOrigin: ['50%', '50%'],
            }}
          />
          {item.ignitionOn !== undefined && (
            <VpnKeyIcon
              titleAccess={'Ignition ' + (item.ignitionOn ? 'on' : 'off')}
              className={classes.statusIconLarge}
              htmlColor={item.ignitionOn ? amber[700] : grey[400]}
            />
          )}
          {item.sirensOn !== undefined && (
            <VolumeUpIcon
              titleAccess={'Siren ' + (item.sirensOn ? 'on' : 'off')}
              className={classes.statusIconLarge}
              htmlColor={item.sirensOn ? amber[700] : grey[400]}
            />
          )}
          {item.beaconsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={'Beacons ' + (item.beaconsOn ? 'on' : 'off')}
              className={classes.statusIconLarge}
              htmlColor={item.beaconsOn ? amber[700] : grey[400]}
            />
          )}
          {item.headlightsFlashOn !== undefined && (
            <HeadlightsFlashIcon
              titleAccess={'Headlights flash ' + (item.headlightsFlashOn ? 'on' : 'off')}
              className={classes.statusIconLarge}
              htmlColor={item.headlightsFlashOn ? amber[700] : grey[400]}
            />
          )}
          {item.malfunctionIndicatorLightOn !== undefined && (
            <EngineIcon
              titleAccess={
                'Malfunction Indicator Light ' +
                (item.malfunctionIndicatorLightOn ? 'on' : 'off')
              }
              className={classes.statusIconLarge}
              htmlColor={
                item.malfunctionIndicatorLightOn ? amber[700] : grey[400]
              }
            />
          )}
          {item.accelerometerAlert !== undefined && (
            <WarningIcon
              className={classes.statusIcon}
              htmlColor={item.accelerometerAlert ? amber[700] : grey[400]}
            />
          )}

          {item.amberLightsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={'Amber lights ' + item.amberLightsOn ? 'on' : 'off'}
              className={classes.statusIconLarge}
              htmlColor={item.amberLightsOn ? amber[700] : grey[400]}
            />
          )}
          {item.trailerOn !== undefined && ( //nature rv_hookup
            <RvHookupIcon
              titleAccess={'Trailer ' + (item.trailerOn ? 'on' : 'off')}
              className={classes.statusIconLarge}
              htmlColor={item.trailerOn ? amber[700] : grey[400]}
            />
          )}
          {item.rearBlueLightsOn !== undefined && (
            <WbIncandescentIcon
              titleAccess={
                'Rear Blue Lights ' + (item.rearBlueLightsOn ? 'on' : 'off')
              }
              className={classes.statusIconLarge}
              htmlColor={item.rearBlueLightsOn ? blue[500] : grey[400]}
            />
          )}
          {item.rearRedLightsOn !== undefined && (
            <WbIncandescentIcon
              titleAccess={
                'Rear Red Lights ' + (item.rearRedLightsOn ? 'on' : 'off')
              }
              className={classes.statusIconLarge}
              htmlColor={item.rearRedLightsOn ? red[500] : grey[400]}
            />
          )}
        </React.Fragment>
        <TagControl item={item} type={type} />
        {item.assignments && (
          <React.Fragment>
            {item.assignments.callSign && (
              <React.Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Call Sign
                </Typography>
                <CallSignLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  classes={classes}
                  item={callSign}
                />
              </React.Fragment>
            )}
            {incident && (
              <React.Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Assigned Incident
                </Typography>
                <IncidentLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  onFollowToggle={onFollowToggle}
                  followedIdsByType={followedIdsByType}
                  classes={classes}
                  item={incident}
                  highlighted={hoveredId === incident.id}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {Object.keys(sections)
          .filter(
            (key) =>
              sections[key] &&
              (!Array.isArray(sections[key]) || sections[key].length > 0)
          )
          .map((key) => (
            <React.Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {_.startCase(key)}
              </Typography>
              {Array.isArray(sections[key]) ? (
                <Table size="small" className={classes.table}>
                  <TableBody>{ItemRows(sections[key], item)}</TableBody>
                </Table>
              ) : (
                sections[key]
              )}
            </React.Fragment>
          ))}
      </CardContent>
    </Card>
  );
}

export default VehicleLiveItem;
