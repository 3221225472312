import {
  Avatar,
  Badge,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Nfc as NfcIcon, VpnKey as VpnKeyIcon } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { NavLink, Route, useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import _ from 'lodash';
import { FETCH_RFID_CARDS } from '../../actions';
import Container from '../Container';
import RfidCardPeople from './RfidCardPeople';
import { SearchBox } from '../controls';

const { useDallasKeys } = window.config;

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '100%',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  list: {
    overflow: 'none',
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(1),
    },
  },
  searchBox: {
    width: '100%',
    padding: theme.spacing(1, 0, 1, 1),
  },
}));

function compare(a, b) {
  const countA = a[1];
  const countB = b[1];
  const idA = a[0].toUpperCase();
  const idB = b[0].toUpperCase();
  if (countA > countB) {
    return -1;
  }
  if (countA < countB) {
    return 1;
  }
  if (idA < idB) {
    return -1;
  }
  if (idA > idB) {
    return 1;
  }

  return 0;
}

export default function RfidCardList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.people.rfidCards, _.isEqual);
  const [filterText, setFilterText] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    dispatch({
      type: FETCH_RFID_CARDS,
    });
  }, [dispatch]);

  useEffect(() => {
    const filteredList = Object.entries(list)
      .filter(
        (item) =>
          filterText === '' ||
          item[0].toLowerCase().includes(filterText.toLowerCase())
      )
      .sort(compare);

    setFilteredList(filteredList);
  }, [list, filterText, setFilteredList]);

  function Row({ data, index, style }) {
    const item = data[index];

    return (
      <ListItem
        dense
        button
        key={index}
        style={style}
        component={NavLink}
        to={`/resources/${useDallasKeys ? 'dallaskeys' : 'rfidcards'}/${
          item[0]
        }`}
        activeClassName={classes.active}
      >
        <ListItemAvatar>
          {item[1] > 1 ? (
            <Badge badgeContent={item[1]} color="error">
              <Avatar>{useDallasKeys ? <VpnKeyIcon /> : <NfcIcon />}</Avatar>
            </Badge>
          ) : (
            <Avatar>{useDallasKeys ? <VpnKeyIcon /> : <NfcIcon />}</Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={item[0].toUpperCase()}
          className={classes.listItemText}
        />
      </ListItem>
    );
  }

  function handleFilterChange(event) {
    const filteredList = Object.entries(list)
      .filter(
        (item) =>
          item[0].toUpperCase().indexOf(event.target.value.toUpperCase()) >
            -1 || event.target.value === ''
      )
      .sort(compare);

    setFilterText(event.target.value);
    setFilteredList(filteredList);
  }

  return (
    <Container title={useDallasKeys ? 'Dallas Keys' : 'RFID Cards'}>
      <div className={classes.page}>
        <Helmet>
          <title>{`IR3 | ${
            useDallasKeys ? 'Dallas Keys' : 'RFID Cards'
          }`}</title>
        </Helmet>
        {(!isXs || !id) && (
          <div className={classes.sideBar}>
            <div className={classes.searchBar}>
              <div className={classes.toolbar}>
                <SearchBox
                  value={filterText}
                  onChange={handleFilterChange}
                  className={classes.searchBox}
                  endAdornment={
                    <InputAdornment position="start">
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.count}
                      >
                        {`${filteredList.length}/${
                          Object.entries(list).length
                        }`}
                      </Typography>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            {filteredList.length > 0 && (
              <div className={classes.list}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      height={height}
                      overscanCount={10}
                      itemData={filteredList}
                      itemCount={filteredList.length}
                      itemSize={56}
                      width={width}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            )}
          </div>
        )}
        {(!isXs || id) && (
          <div className={classes.main}>
            <Route
              path={`/resources/${
                useDallasKeys ? 'dallaskeys' : 'rfidcards'
              }/:id`}
            >
              <RfidCardPeople />
            </Route>
          </div>
        )}
      </div>
    </Container>
  );
}
