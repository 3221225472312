import React from 'react';
import { Chip, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ListSubheader from '@material-ui/core/ListSubheader';
import { VariableSizeList } from 'react-window';

const defaultValue = [];
const LISTBOX_PADDING = 8; // px

function getLabel(option) {
  return (
    (_.isString(option?.label) && option.label) ||
    (_.isString(option) && option) ||
    ''
  );
}

function renderRow(props) {
  const {
    data,
    index,
    style,
    data: { styles = {}, options = [] },
  } = props;
  let { children: option } = data[index].props;

  // if the option is a string, we've had to do getOptionLabel(getLabel)
  // because the option is an object but autocomplete wanted a string to
  // do it's own filtering. We can find the matching original object to
  // get the className property
  if (_.isString(option)) {
    option = options.find((o) => o.label === option) || option;
  }

  const newStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
    ...(styles[option?.className] || {}),
  };

  const element = (
    <Typography {...data[index].props} style={newStyle}>
      {getLabel(option)}
    </Typography>
  );
  return React.cloneElement(element);
}
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, styles, options, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={{ ...itemData, styles, options }}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

export default function SelectMultipleField({
  input: { value, onChange, ...input },
  meta,
  suggestions,
  disabled,
  typeIcons,
  styles = {},
  filterOptions,
  ...props
}) {
  function handleChange(event, value) {
    const [selection, ...existingValues] = value.slice().reverse();

    if (typeof selection !== 'object') {
      onChange(value);
    } else {
      onChange([...existingValues.reverse(), selection.value]);
    }
  }

  const options = _.orderBy(suggestions, ['type', 'label']);

  return (
    <Autocomplete
      multiple
      ListboxComponent={ListboxComponent}
      ListboxProps={{ styles, options }}
      // !! disallows "" and defaultValue isn't a new [] every time
      value={!!value ? value : defaultValue}
      disabled={disabled}
      onChange={handleChange}
      options={options}
      getOptionSelected={(option, selected) => option.value === selected}
      // if custom filtering isn't applied autocomplete will attempt to do
      // it's own filtering which does option.toLowerCase and if option is
      // an object it will crash
      getOptionLabel={!filterOptions ? getLabel : undefined}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          helperText={meta.touched && (meta.error || meta.submitError)}
          error={meta.touched && meta.invalid}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const suggestion = suggestions.find(
            (suggestion) => suggestion.value === option
          );

          return (
            <Chip
              label={getLabel(suggestion || option)}
              {...getTagProps({ index })}
              style={styles[suggestion?.className]}
              icon={typeIcons && suggestion && typeIcons[suggestion.type]}
            />
          );
        })
      }
      // renderOption={(option) => {
      //   const possible = !option.unavailable;

      //   return (
      //     <Typography
      //       style={possible ? undefined : IMPOSSIBLE_STYLE}
      //       title={possible ? undefined : IMPOSSIBLE_MESSAGE}
      //     >
      //       {option.label}
      //     </Typography>
      //   );
      // }}
      // groupBy={(option) => option.type}
    />
  );
}
