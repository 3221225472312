import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import _ from 'lodash';
import {
  FETCH_COLLECTIONS,
  FETCH_COLLECTION,
  FETCH_QUERY,
  CREATE_COLLECTION,
  UPDATE_COLLECTION,
  DELETE_COLLECTION,
  FETCH_COLLECTIONS_SUCCESS,
  FETCH_COLLECTIONS_FAILURE,
  FETCH_COLLECTION_SUCCESS,
  FETCH_COLLECTION_FAILURE,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_FAILURE,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAILURE,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAILURE,
  FETCH_SELECTION_EVENTS,
  FETCH_QUERY_EVENTS,
  UPDATE_SELECTION_EVENT,
  FETCH_SELECTION_EVENTS_SUCCESS,
  FETCH_SELECTION_EVENTS_FAILURE,
  UPDATE_SELECTION_EVENT_SUCCESS,
  UPDATE_SELECTION_EVENT_FAILURE,
} from '../actions';
import api from '../apis';
import {
  getQuery,
  getSelectionEvents,
  getQueryEvents,
  getHeaders,
  log,
} from '../apis/utilities';
import history from '../history';

async function fetchCollectionsRequest(types) {
  const response = await api.get('/collections', {
    params: {
      query:
        types !== 'All'
          ? {
              type: { $in: types },
            }
          : undefined,
      projection: {
        identifier: true,
        title: true,
        type: true,
        description: true,
        items: true,
        created: true,
      },
      sort: { type: 1, title: 1 },
    },
    headers: getHeaders(),
  });

  log('Read', 'Collections', { types });

  return response.data;
}

export function fetchCollectionsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_COLLECTIONS),
    mergeMap(({ payload: { types, user } }) =>
      from(fetchCollectionsRequest(types, user)).pipe(
        map((payload) => ({
          type: FETCH_COLLECTIONS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_COLLECTIONS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchCollectionRequest(id) {
  const response = await api.get(`/collections/${id}`, {
    params: {
      projection: {
        identifier: true,
        title: true,
        type: true,
        description: true,
        startTime: true,
        endTime: true,
        areas: true,
        briefs: true,
        boundary: true,
        subtype: true,
        created: true,
        lastEdit: true,
        items: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'Collection', { id });

  return response.data;
}

export function fetchCollectionEpic(action$) {
  return action$.pipe(
    ofType(FETCH_COLLECTION),
    mergeMap(({ payload: id }) =>
      from(fetchCollectionRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_COLLECTION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_COLLECTION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchQueryRequest(id) {
  const query = await getQuery(id);

  log('Read', 'Collection', { id, type: 'Query' });

  return query;
}

export function fetchQueryEpic(action$) {
  return action$.pipe(
    ofType(FETCH_QUERY),
    mergeMap(({ payload: id }) =>
      from(fetchQueryRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_COLLECTION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_COLLECTION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function createCollectionRequest(values) {
  const response = await api.post(`/collections`, values, {
    headers: getHeaders(),
  });

  // redirect onle when type created via collections link
  if (values.type !== 'Tag' || !_.has(values, 'items')) {
    history.replace(response.data.identifier);
  }

  return response.data;
}

export function createCollectionEpic(action$) {
  return action$.pipe(
    ofType(CREATE_COLLECTION),
    mergeMap(({ payload: values }) =>
      from(createCollectionRequest(values)).pipe(
        map((payload) => ({
          type: CREATE_COLLECTION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_COLLECTION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateCollectionRequest(values) {
  await api.patch(
    `/collections/${values.identifier}`,
    {
      ...values,
    },
    {
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/merge-patch+json',
      },
    }
  );

  return values;
}

export function updateCollectionEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_COLLECTION),
    mergeMap(({ payload: values }) =>
      from(updateCollectionRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_COLLECTION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_COLLECTION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function deleteCollectionRequest(id) {
  await api.delete(`/collections/${id}`, {
    params: {
      permanent: true,
    },
    headers: getHeaders(),
  });
  history.push('.');
  return id;
}

export function deleteCollectionEpic(action$) {
  return action$.pipe(
    ofType(DELETE_COLLECTION),
    mergeMap(({ payload: id }) =>
      from(deleteCollectionRequest(id)).pipe(
        map((payload) => ({
          type: DELETE_COLLECTION_SUCCESS,
          payload: decodeURIComponent(payload),
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_COLLECTION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchSelectionEventsRequest(id) {
  const events = await getSelectionEvents(id);

  log('Read', 'Selection Events', { id, type: 'Selection' });

  return events;
}

export function fetchSelectionEventsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_SELECTION_EVENTS),
    mergeMap(({ payload: id }) =>
      from(fetchSelectionEventsRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_SELECTION_EVENTS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_SELECTION_EVENTS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchQueryEventsRequest(id) {
  const events = await getQueryEvents(id);

  log('Read', 'Query Events', { id, type: 'Query' });

  return events;
}

export function fetchQueryEventsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_QUERY_EVENTS),
    mergeMap(({ payload: id }) =>
      from(fetchQueryEventsRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_SELECTION_EVENTS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_SELECTION_EVENTS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateSelectionEventRequest(feature) {
  await api.patch(
    `/${feature.properties.typeId}/${feature.id}`,
    {
      collections: feature.properties.collections,
    },
    {
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/merge-patch+json',
      },
    }
  );

  return feature;
}

export function updateSelectionEventEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_SELECTION_EVENT),
    mergeMap(({ payload: values }) =>
      from(updateSelectionEventRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_SELECTION_EVENT_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_SELECTION_EVENT_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
