import {
  AppBar,
  Paper,
  CircularProgress,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import {
  CLEAR_QUERY_EVENTS,
  FETCH_QUERY,
  FETCH_QUERY_EVENTS,
} from '../../actions';
import CollectionEventList from '../collections/CollectionEventList';
import CollectionMap from '../collections/CollectionMap';
import CrimeCard from '../collections/CrimeCard';
import IncidentCard from '../collections/IncidentCard';
import IntelligenceCard from '../collections/IntelligenceCard';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingRight: theme.spacing(1),
  },
  subtitle: {
    flex: 1,
  },
  toolbar: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  content: {
    marginTop: 48,
    display: 'flex',
  },
  drawerContent: {
    maxHeight: '80vh',
    overflow: 'scroll',
  },
  backButton: {
    marginRight: 10,
  },
  itemsSection: {
    width: 500,
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mapSection: {
    width: 'calc(100% - 500px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mapCard: {
    height: 'calc(100vh - 64px)',
    margin: theme.spacing(1),
    minWidth: 250,
  },
  waitingPrompt: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
  toolbarIcon: {
    marginRight: 0,
  },
}));

export default function QueryContents() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const collection = useSelector(
    (state) => state.collections.collection || {},
    _.isEqual
  );
  const events = useSelector((state) => state.collections.events, _.isEqual);
  const error = useSelector((state) => state.collections.error) ;
  const isLoading = useSelector((state) => state.collections.isLoading);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [hoveredEventIndex, setHoveredEventIndex] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    dispatch({
      type: FETCH_QUERY,
      payload: id,
    });
    dispatch({
      type: FETCH_QUERY_EVENTS,
      payload: id,
    });

    return () => {
      dispatch({
        type: CLEAR_QUERY_EVENTS,
      });
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (Number.isInteger((selectedEventIndex || {}).featureId)) {
      setDrawerOpen(true);
    }
  }, [selectedEventIndex]);

  function handleOpen() {
    setDrawerOpen(true);
  }

  function handleClose() {
    setDrawerOpen(false);
  }

  function handleBackClick() {
    if (Number.isInteger((selectedEventIndex || {}).featureId)) {
      setSelectedEventIndex(null);
    } else {
      history.goBack();
    }
  }

  function renderCard() {
    const { typeId, featureId } = selectedEventIndex;
    const feature = events[typeId].features[featureId];

    function handleDone() {
      setSelectedEventIndex(null);
    }

    switch (typeId) {
      case 'incidents':
        return <IncidentCard feature={feature} onDone={handleDone} />;
      case 'crimes':
        return <CrimeCard feature={feature} onDone={handleDone} />;
      case 'intelligence':
        return <IntelligenceCard feature={feature} onDone={handleDone} />;
      default:
        return '';
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          IR3 | Query Contents{collection.title ? ` | ${collection.title}` : ''}
        </title>
      </Helmet>
      <AppBar>
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Back"
            className={classes.backButton}
            onClick={handleBackClick}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {collection ? collection.title : ''}
          </Typography>
          <Typography variant="caption" className={classes.subtitle}>
            {collection ? collection.identifier : ''}
          </Typography>
          {isXs && (
            <IconButton
              color="inherit"
              aria-label="Details"
              className={classes.toolbarIcon}
              onClick={handleOpen}
            >
              <ViewListIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        {!isXs && (
          <div className={classes.itemsSection}>
            {Number.isInteger((selectedEventIndex || {}).featureId) ? (
              renderCard(selectedEventIndex.typeId)
            ) : !isLoading ? (
              <CollectionEventList
                hoveredEventIndex={hoveredEventIndex}
                onSelectEvent={setSelectedEventIndex}
                onHoverEvent={setHoveredEventIndex}
              />
            ) : (
              <div className={classes.waitingPrompt}>
                <CircularProgress />
                <Typography>
                  Waiting for Crime &amp; Intelligence...
                  <span role="img" aria-label="Sad face">
                    🙁
                  </span>
                </Typography>
              </div>
            )}
          </div>
        )}
        <div className={classes.mapSection}>
          {isXs ? (
            <CollectionMap
              selectedEventIndex={selectedEventIndex}
              hoveredEventIndex={hoveredEventIndex}
              onSelectEvent={setSelectedEventIndex}
              onHoverEvent={setHoveredEventIndex}
            />
          ) : (
            <Paper className={classes.mapCard}>
              <CollectionMap
                selectedEventIndex={selectedEventIndex}
                hoveredEventIndex={hoveredEventIndex}
                onSelectEvent={setSelectedEventIndex}
                onHoverEvent={setHoveredEventIndex}
              />
            </Paper>
          )}
        </div>
      </div>
      {isXs && (
        <Drawer anchor="bottom" open={drawerOpen} onClose={handleClose}>
          <div className={classes.drawerContent}>
            {Number.isInteger((selectedEventIndex || {}).featureId) ? (
              renderCard(selectedEventIndex.typeId)
            ) : !isLoading ? (
              <CollectionEventList
                hoveredEventIndex={hoveredEventIndex}
                onSelectEvent={setSelectedEventIndex}
                onHoverEvent={setHoveredEventIndex}
              />
            ) : (
              <div className={classes.waitingPrompt}>
                <CircularProgress />
                <Typography>
                  Waiting for Crime &amp; Intelligence...
                  <span role="img" aria-label="Sad face">
                    🙁
                  </span>
                </Typography>
              </div>
            )}
          </div>
        </Drawer>
      )}
    </Fragment>
  );
}
