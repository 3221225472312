import {
  Avatar,
  CardHeader,
  makeStyles,
  // Dialog,
  // DialogActions,
  // DialogTitle,
  // Button,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { useSnackbar } from '../Snackbar';
import {
  FETCH_RETROSPECTIVE_SUCCESS,
  UPDATE_FILTER_END_TIME,
  UPDATE_FILTER_START_TIME,
} from '../../../actions';
import { AuditTypeIcon } from '../../../data/constants';
import { getAuditItem } from '../../../apis/utilities';

const { useReducedResourceInformation } = window.config;

const liveTypeToAuditTypeMap = {
  vehicles: 'Vehicle',
  people: 'Person',
  locations: 'Location',
  objectives: 'Objective',
  plans: 'Plan',
  incidents: 'Incident',
  tags: 'Tag',
  callSigns: 'Call Sign',
};

const useStyles = makeStyles(() => ({
  itemHeader: {
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  itemAvatar: {
    width: 30,
    height: 30,
  },
}));

function Placeholder() {
  const classes = useStyles();

  return (
    <CardHeader
      className={classes.itemHeader}
      avatar={<Skeleton variant="circle" width={30} height={30} />}
      title={<Skeleton height={6} width="60%" />}
      subheader={<Skeleton height={6} width="40%" />}
    />
  );
}

export default function Link({ entry }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [item, setItem] = useState(null);
  // const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    let didCancel = false;

    async function getItem(entry) {
      let type = entry.dataType;
      let id = entry.itemId;

      if (type?.startsWith('Live')) {
        const liveItem = entry.parameters;
        type = liveTypeToAuditTypeMap[liveItem?.type];
        id = liveItem?.id;
      }

      if (!type || !id) {
        return;
      }

      try {
        const item = await getAuditItem(type, id);

        if (!didCancel) {
          setItem(item);
        }
      } catch {}
    }

    getItem(entry);

    return () => {
      didCancel = true;
    }; // remember if we start fetching something else
  }, [entry]);

  switch (entry.dataType) {
    case 'Replay':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() =>
            history.push(
              `/replay/${entry.parameters[entry.action].type}/${entry.itemId}`
            )
          }
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Replay" />
            </Avatar>
          }
          title="Replay"
          subheader={entry.itemId}
        />
      );
    case 'Task':
    case 'Comments':
    case 'Comment':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            history.push(`/tasks/${entry.itemId}`);
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Task" />
            </Avatar>
          }
          title={entry.itemId}
        />
      );
    case 'Vehicle':
    case 'Vehicle Trips':
    case 'Vehicle Location Visits':
      const words = entry.dataType.split(' ');
      const eventType =
        words.length > 1 ? `/${words[words.length - 1].toLowerCase()}` : '';

      return item ? (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            if (words.length > 1) {
              dispatch({
                type: UPDATE_FILTER_START_TIME,
                payload: entry.startTime,
              });
              dispatch({
                type: UPDATE_FILTER_END_TIME,
                payload: entry.endTime,
              });
            }

            history.push(`/resources/vehicles/${entry.itemId}${eventType}`);
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Vehicle" />
            </Avatar>
          }
          title={`${
            useReducedResourceInformation
              ? item.fleetNumber
              : item.registrationNumber
          } ${words.length > 1 ? words[words.length - 1] : ''}`}
          subheader={
            words.length > 1
              ? `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
                  entry.endTime
                ).format('DD/MM/YYYY')}`
              : useReducedResourceInformation
              ? item.identificationNumber
              : item.fleetNumber
          }
        />
      ) : (
        <Placeholder />
      );
    case 'Person':
    case 'Person Trails':
    case 'Person Location Visits':
    case 'Driver Trips':
      const personWords = entry.dataType.split(' ');
      const personEventType =
        personWords.length > 1
          ? `/${personWords[personWords.length - 1].toLowerCase()}`
          : '';

      return item ? (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            if (personWords.length > 1) {
              dispatch({
                type: UPDATE_FILTER_START_TIME,
                payload: entry.startTime,
              });
              dispatch({
                type: UPDATE_FILTER_END_TIME,
                payload: entry.endTime,
              });
            }

            history.push(`/resources/people/${entry.itemId}${personEventType}`);
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Person" />
            </Avatar>
          }
          title={
            useReducedResourceInformation
              ? item.code
              : `${item.forenames} ${item.surname} ${
                  personWords.length > 1
                    ? personWords[personWords.length - 1]
                    : ''
                }`
          }
          subheader={
            personWords.length > 1
              ? `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
                  entry.endTime || entry.endTime
                ).format('DD/MM/YYYY')}`
              : item.collarNumber
          }
        />
      ) : (
        <Placeholder />
      );
    case 'Location':
    case 'Person Visits':
    case 'Vehicle Visits':
      const locationEventType =
        entry.dataType === 'Location'
          ? ''
          : `/${entry.dataType.replace(' ', '').toLowerCase()}`;

      return item ? (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            if (entry.dataType !== 'Location') {
              dispatch({
                type: UPDATE_FILTER_START_TIME,
                payload: entry.startTime,
              });
              dispatch({
                type: UPDATE_FILTER_END_TIME,
                payload: entry.endTime,
              });
            }

            history.push(
              `/locations/${item.type.replace(/ /g, '').toLowerCase()}s/${
                entry.itemId
              }${locationEventType}`
            );
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Location" />
            </Avatar>
          }
          title={`${item.name} ${
            entry.dataType !== 'Location' ? entry.dataType : ''
          }`}
          subheader={
            entry.dataType !== 'Location'
              ? `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
                  entry.endTime
                ).format('DD/MM/YYYY')}`
              : item.code
          }
        />
      ) : (
        <Placeholder />
      );
    case 'Feature':
    case 'Objective':
    case 'Objective Attendances':
    case 'Brief':
    case 'Collection':
      const isAttendances = entry.dataType === 'Objective Attendances';
      const attendancesPath = isAttendances ? '/attendances' : '';

      return item ? (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            if (isAttendances) {
              dispatch({
                type: UPDATE_FILTER_START_TIME,
                payload: entry.startTime,
              });
              dispatch({
                type: UPDATE_FILTER_END_TIME,
                payload: entry.endTime,
              });
            }

            history.push(
              `/${entry.dataType.split(' ')[0].toLowerCase()}s/${
                item.dataType === 'Brief'
                  ? 'all'
                  : item.type === 'Query'
                  ? 'queries'
                  : `${item.type.toLowerCase()}s`
              }/${entry.itemId}${attendancesPath}`
            );
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type={entry.dataType.split(' ')[0]} />
            </Avatar>
          }
          title={`${item.title} ${isAttendances ? ' Attendances' : ''}`}
          subheader={
            isAttendances
              ? `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
                  entry.endTime
                ).format('DD/MM/YYYY')}`
              : item.identifier
          }
        />
      ) : (
        <Placeholder />
      );
    case 'Vehicle Daily Utilisation':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            history.push('/reports/vehicles/dailyutilisation');
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Chart" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
              entry.endTime || entry.endTime
            ).format('DD/MM/YYYY')}`
          }
        />
      );
    case 'Person Daily Activity':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            history.push('/reports/people/dailyactivity');
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Chart" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
              entry.endTime
            ).format('DD/MM/YYYY')}`
          }
        />
      );
    case 'Vehicle Hourly Utilisation':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            history.push('/reports/vehicles/hourlyutilisation');
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Chart" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
              entry.endTime
            ).format('DD/MM/YYYY')}`
          }
        />
      );
    case 'Person Hourly Activity':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            history.push('/reports/people/hourlyactivity');
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Chart" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
              entry.endTime
            ).format('DD/MM/YYYY')}`
          }
        />
      );
    case 'Vehicle In Base Time':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            history.push('/reports/vehicles/hourlyinlocations');
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Vehicle In Base Time" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
              entry.endTime
            ).format('DD/MM/YYYY')}`
          }
        />
      );
    case 'Vehicle In Locations':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            history.push('/reports/vehicles/timelineinlocations');
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Vehicle In Locations" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
              entry.endTime
            ).format('DD/MM/YYYY')}`
          }
        />
      );
    case 'Unknown Driver Trips':
    case 'Speed Infractions':
    case 'Double Crews':
    case 'Accelerometer Events':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            history.push(
              `/reports/driving/${entry.dataType
                .replace(/ /g, '')
                .toLowerCase()}`
            );
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type={entry.dataType} />
            </Avatar>
          }
          title={entry.dataType}
          subheader={`${moment(entry.startTime).format(
            'DD/MM/YYYY'
          )} - ${moment(entry.endTime).format('DD/MM/YYYY')}`}
        />
      );
    case 'Driving Scores':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            history.push(`/reports/driving/score`);
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type={entry.dataType} />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime
              ? `${moment(entry.startTime).format('DD/MM/YYYY')} - ${moment(
                  entry.endTime
                ).format('DD/MM/YYYY')}`
              : null
          }
        />
      );
    case 'Telematics Box Outages':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            history.push('/reports/telematicsboxes/outages');
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type={entry.dataType} />
            </Avatar>
          }
          title={entry.dataType}
          subheader={`${moment(entry.startTime).format(
            'DD/MM/YYYY'
          )} - ${moment(entry.endTime).format('DD/MM/YYYY')}`}
        />
      );
    case 'Vehicle Mileage':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            history.push(`/reports/vehicles/mileage/${entry.date || ''}`);
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type={entry.dataType} />
            </Avatar>
          }
          title={entry.dataType}
          subheader={entry.date ? moment(entry.date).format('DD/MM/YYYY') : ''}
        />
      );
    case 'Audit Log Entries':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            window.scrollTo(0, 0);
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Audit" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={`${moment(entry.startTime).format(
            'DD/MM/YYYY'
          )} - ${moment(entry.endTime).format('DD/MM/YYYY')}`}
        />
      );
    case 'Brief Collections':
    case 'Brief Objectives':
    case 'Collection Features':
    case 'Collection Events':
      return item ? (
        <CardHeader
          className={classes.itemHeader}
          onClick={() =>
            history.push(
              `/${entry.dataType.split(' ')[0].toLowerCase()}s/${
                entry.dataType === 'Brief'
                  ? 'all'
                  : item.type === 'Query'
                  ? 'queries'
                  : `${item.type.toLowerCase()}s`
              }/${entry.itemId}/contents`
            )
          }
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type={entry.dataType.split(' ')[0]} />
            </Avatar>
          }
          title={item.title}
          subheader={item.identifier}
        />
      ) : (
        entry.itemId
      );
    case 'Telematics Box':
    case 'Telematics Box Vehicles':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            history.push(`/resources/telematicsboxes/${entry.itemId}`);
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Telematics Box" />
            </Avatar>
          }
          title={entry.itemId}
        />
      );
    case 'Retrospective':
      return item ? (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            history.push(`/retrospective/${entry.itemId}`);
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Retrospective" />
            </Avatar>
          }
          title={item.title}
          subheader={entry.itemId}
        />
      ) : (
        <Placeholder />
      );
    case 'Layer':
      return (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            const layer = entry.parameters.Read;

            if (layer.blur) {
              layer.blur = parseInt(layer.blur);
            }

            if (layer.radius) {
              layer.radius = parseInt(layer.radius);
            }

            if (layer.distance) {
              layer.distance = parseInt(layer.distance);
            }

            if (layer.growth) {
              layer.growth = parseFloat(layer.growth);
            }

            if (layer.boundaryGeometry) {
              layer.boundaryGeometry.coordinates = [
                _.chunk(
                  layer.boundaryGeometry.coordinates.map((number) =>
                    parseFloat(number)
                  ),
                  2
                ),
              ];
            }

            if (typeof layer.colors === 'string') {
              layer.colors = [layer.colors];
            }

            if (layer.filters) {
              for (let key in layer.filters) {
                if (!Array.isArray(layer.filters[key])) {
                  layer.filters[key] = [layer.filters[key]];
                }
              }
            }

            dispatch({
              type: FETCH_RETROSPECTIVE_SUCCESS,
              payload: { title: '', layers: [layer] },
            });

            history.push('/retrospective');
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type="Layer" />
            </Avatar>
          }
          title={_.startCase(entry.parameters.source)}
          subheader={`${moment(entry.parameters.startTime).format(
            'DD/MM/YYYY HH:mm'
          )} - ${moment(entry.parameters.endTime).format('DD/MM/YYYY HH:mm')}`}
        />
      );
    case 'Live Resource':
      const { type, id } = entry.parameters; //[entry.action];
      const auditType = liveTypeToAuditTypeMap[type];
      let title = undefined;
      let subheader = '';

      switch (type) {
        case 'vehicles':
          title = useReducedResourceInformation
            ? item?.fleetNumber
            : item?.registrationNumber;
          subheader = useReducedResourceInformation
            ? item?.identificationNumber
            : item?.fleetNumber;
          break;
        case 'people':
          title = useReducedResourceInformation
            ? item?.code
            : `${item?.forenames} ${item?.surname}`; // `
          subheader = item?.collarNumber;
          break;
        case 'locations':
          title = item?.name;
          subheader = item?.code;
          break;
        case 'plans':
        case 'objectives':
          title = item?.title;
          subheader = item?.identifier;
          break;
        case 'incidents':
          title = item?.type?.name;
          subheader = item?.number;
          break;
        case 'tags':
          title = id;
          break;
        case 'callSigns':
          title = id;
          break;
        default:
          title = type;
          subheader = id;
          break;
      }

      return title ? (
        <CardHeader
          className={classes.itemHeader}
          onClick={() => {
            history.push(['/live', type, id].filter(Boolean).join('/'));
          }}
          avatar={
            <Avatar className={classes.itemAvatar}>
              <AuditTypeIcon type={auditType} />
            </Avatar>
          }
          title={title}
          subheader={subheader}
        />
      ) : (
        <Placeholder />
      );
      case 'CSV':
      case 'JSON':
      case 'GeoJSON':
        return (
          <CardHeader
            className={classes.itemHeader}
            avatar={
              <Avatar className={classes.itemAvatar}>
                <AuditTypeIcon type={entry.dataType} />
              </Avatar>
            }
            title={entry.dataType + ' Download'}
            subheader={[
              entry.parameters.filename,
              entry.parameters.startTime && moment(entry.parameters.startTime).format('DD/MM/YYYY'),
              entry.parameters.endTime && moment(entry.parameters.endTime).format('DD/MM/YYYY'),
              entry.parameters.id,
            ].filter(Boolean).join(' ')}
            // subheader={`${moment(entry.startTime).format(
            //   'DD/MM/YYYY'
            // )} - ${moment(entry.endTime).format('DD/MM/YYYY')}`}
          />
        );
    // case 'Live List':
    //   const { searchTexts = {}, advancedFilters = {} } = entry.parameters[entry.action];
    //   const icon = (
    //     <Avatar className={classes.itemAvatar}>
    //       <AuditTypeIcon type={'Filter'} />
    //     </Avatar>
    //   );

    //   function SearchTextsList() {
    //     return (
    //       <div>
    //         {Object.keys(searchTexts).map(type => {
    //           return <div key={type}>{`${type}: ${searchTexts[type]}`}</div>
    //         })}
    //       </div>
    //     );
    //   }

    //   function AdvancedFilterList() {
    //     return (
    //       <div>
    //         {Object.keys(advancedFilters).map(type => {
    //           // TODO JL something odd here, it's always an array when I send it
    //           // how come it's sometimes an object instead of an array in audit?
    //           const arr = Array.isArray(advancedFilters[type])
    //             ? advancedFilters[type]
    //             : [advancedFilters[type]];
    //           const afs = arr.filter(af => af.value).map(af =>
    //             `${af.field} ${af.condition} ${af.value}`).join(',');
    //           return <div key={type}>{`${type}: ${afs}`}</div>;
    //         })}
    //       </div>
    //     );
    //   }

    //   return (
    //     <React.Fragment>
    //       <Button onClick={() => setOpen(true)} color="primary">
    //         {icon}
    //       </Button>
    //       <Dialog
    //         open={open}
    //         onClose={() => setOpen(false)}
    //       >
    //         <DialogTitle>
    //           Live filter settings
    //         </DialogTitle>
    //         <label htmlFor="contained-button-file">{icon}</label>
    //         {SearchTextsList()}
    //         {AdvancedFilterList()}
    //         <DialogActions>
    //           <Button onClick={() => setOpen(false)} color="primary">
    //             Close
    //           </Button>
    //         </DialogActions>
    //       </Dialog>
    //     </React.Fragment>
    //   );
    default:
      return entry.itemId;
  }
}
