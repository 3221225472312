import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import _ from 'lodash';
import {
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  FETCH_TAG,
  FETCH_TAG_SUCCESS,
  FETCH_TAG_FAILURE,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  DELETE_TAG_ITEM,
  DELETE_TAG_ITEM_SUCCESS,
  DELETE_TAG_ITEM_FAILURE,
  ADD_TAG_ITEM,
  ADD_TAG_ITEM_SUCCESS,
  ADD_TAG_ITEM_FAILURE,
} from '../actions';
import api from '../apis';
import { getHeaders, log } from '../apis/utilities';
import history from '../history';

async function fetchTagsRequest() {
  const response = await api.get('/tags', {
    params: {
      projection: {
        identifier: true,
        title: true,
        type: true,
        description: true,
        items: true,
        created: true,
      },
      sort: { type: 1, title: 1 },
    },
    headers: getHeaders(),
  });

  return response.data;
}

export function fetchTagsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TAGS),
    mergeMap(() =>
      from(fetchTagsRequest()).pipe(
        map((payload) => ({
          type: FETCH_TAGS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TAGS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchTagRequest(id) {
  const response = await api.get(`/tags/${id}`, {
    params: {
      projection: {
        identifier: true,
        description: true,
        created: true,
        lastEdit: true,
        items: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'Collection', { id });

  return response.data;
}

export function fetchTagEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TAG),
    mergeMap(({ payload: id }) =>
      from(fetchTagRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_TAG_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TAG_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function createTagRequest(values) {
  const response = await api.post(`/tags`, values, {
    headers: getHeaders(),
  });

  // redirect when tag created via tag link
  if (!_.has(values, 'items')) {
    history.replace(response.data.identifier);
  }

  return response.data;
}

export function createTagEpic(action$) {
  return action$.pipe(
    ofType(CREATE_TAG),
    mergeMap(({ payload: values }) =>
      from(createTagRequest(values)).pipe(
        map((payload) => ({
          type: CREATE_TAG_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_TAG_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateTagRequest(values) {
  await api.patch(
    `/tags/${encodeURIComponent(values.identifier)}`,
    {
      ...values,
    },
    {
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/merge-patch+json',
      },
    }
  );

  return values;
}

export function updateTagEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_TAG),
    mergeMap(({ payload: values }) =>
      from(updateTagRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_TAG_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_TAG_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function deleteTagRequest(id) {
  await api.delete(`/tags/${id}`, {
    params: {
      permanent: true,
    },
    headers: getHeaders(),
  });

  if (history.location.pathname.includes('live')) {
    history.push('/live/tags');
  } else {
    history.push('.');
  }

  return id;
}

export function deleteTagEpic(action$) {
  return action$.pipe(
    ofType(DELETE_TAG),
    mergeMap(({ payload: id }) =>
      from(deleteTagRequest(id)).pipe(
        map((payload) => ({
          type: DELETE_TAG_SUCCESS,
          payload: decodeURIComponent(payload),
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_TAG_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function addTagItemEpic(id, item, value) {
  const response = await api.patch(
    `/tags/${id}/${item}/${value}`,
    {},
    {
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/merge-patch+json',
      },
    }
  );

  return response.data;
}

export function addTagTypeItemEpic(action$) {
  return action$.pipe(
    ofType(ADD_TAG_ITEM),
    mergeMap(({ payload: { id, item, value, type } }) =>
      from(addTagItemEpic(id, item, value)).pipe(
        map((payload) => ({
          type: ADD_TAG_ITEM_SUCCESS,
          payload: { id, type, value },
        })),
        catchError(({ message: payload }) =>
          of({
            type: ADD_TAG_ITEM_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function deleteTagItemEpic(id, item, value) {
  const response = await api.delete(`/tags/${id}/${item}/${value}`, {
    headers: getHeaders(),
  });

  return response.data;
}

export function deleteTagTypeItemEpic(action$) {
  return action$.pipe(
    ofType(DELETE_TAG_ITEM),
    mergeMap(({ payload: { id, item, value, updatedItems, type } }) =>
      from(deleteTagItemEpic(id, item, value)).pipe(
        map((payload) => ({
          type: DELETE_TAG_ITEM_SUCCESS,
          payload: { ...payload, id, type, updatedItems },
        })),
        catchError(({ message }) =>
          of({
            type: DELETE_TAG_ITEM_FAILURE,
            payload: {
              op: 'deleting',
              message,
              tag: id,
              type,
              id,
            },
          })
        )
      )
    )
  );
}
