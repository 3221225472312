export const FETCH_BRIEFS = 'FETCH_BRIEFS';
export const FETCH_BRIEFS_SUCCESS = 'FETCH_BRIEFS_SUCCESS';
export const FETCH_BRIEFS_FAILURE = 'FETCH_BRIEFS_FAILURE';
export const FETCH_BRIEF = 'FETCH_BRIEF';
export const FETCH_BRIEF_SUCCESS = 'FETCH_BRIEF_SUCCESS';
export const FETCH_BRIEF_FAILURE = 'FETCH_BRIEFS_FAILURE';
export const CREATE_BRIEF = 'CREATE_BRIEF';
export const CREATE_BRIEF_SUCCESS = 'CREATE_BRIEF_SUCCESS';
export const CREATE_BRIEF_FAILURE = 'CREATE_BRIEFS_FAILURE';
export const UPDATE_BRIEF = 'UPDATE_BRIEF';
export const UPDATE_BRIEF_SUCCESS = 'UPDATE_BRIEF_SUCCESS';
export const UPDATE_BRIEF_FAILURE = 'UPDATE_BRIEFS_FAILURE';
export const DELETE_BRIEF = 'DELETE_BRIEF';
export const DELETE_BRIEF_SUCCESS = 'DELETE_BRIEF_SUCCESS';
export const DELETE_BRIEF_FAILURE = 'DELETE_BRIEFS_FAILURE';
export const FETCH_BRIEF_COLLECTIONS = 'FETCH_BRIEF_COLLECTIONS';
export const FETCH_BRIEF_COLLECTIONS_SUCCESS =
  'FETCH_BRIEF_COLLECTIONS_SUCCESS';
export const FETCH_BRIEF_COLLECTIONS_FAILURE =
  'FETCH_BRIEF_COLLECTIONS_FAILURE';
export const CLEAR_BRIEF_COLLECTIONS = 'CLEAR_BRIEF_COLLECTIONS';
export const FETCH_BRIEF_OBJECTIVES = 'FETCH_BRIEF_OBJECTIVES';
export const FETCH_BRIEF_OBJECTIVES_SUCCESS = 'FETCH_BRIEF_OBJECTIVES_SUCCESS';
export const FETCH_BRIEF_OBJECTIVES_FAILURE = 'FETCH_BRIEF_OBJECTIVES_FAILURE';
export const CLEAR_BRIEF_OBJECTIVES = 'CLEAR_BRIEF_OBJECTIVES';

export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';
export const FETCH_TAG = 'FETCH_TAG';
export const FETCH_TAG_SUCCESS = 'FETCH_TAG_SUCCESS';
export const FETCH_TAG_FAILURE = 'FETCH_TAGS_FAILURE';
export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAILURE = 'CREATE_TAGS_FAILURE';
export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';
export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';
export const ADD_TAG_ITEM = 'ADD_TAG_ITEM';
export const ADD_TAG_ITEM_SUCCESS = 'ADD_TAG_ITEM_SUCCESS';
export const ADD_TAG_ITEM_FAILURE = 'ADD_TAG_ITEM_FAILURE';
export const DELETE_TAG_ITEM = 'DELETE_TAG_ITEM';
export const DELETE_TAG_ITEM_SUCCESS = 'DELETE_TAG_ITEM_SUCCESS';
export const DELETE_TAG_ITEM_FAILURE = 'DELETE_TAG_ITEM_FAILURE';

export const FETCH_PLANS = 'FETCH_PLANS';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_FAILURE = 'FETCH_PLANS_FAILURE';
export const FETCH_PLAN = 'FETCH_PLAN';
export const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS';
export const FETCH_PLAN_FAILURE = 'FETCH_PLAN_FAILURE';
export const CREATE_PLAN = 'CREATE_PLAN';
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS';
export const CREATE_PLAN_FAILURE = 'CREATE_PLAN_FAILURE';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAILURE = 'UPDATE_PLAN_FAILURE';
export const DELETE_PLAN = 'DELETE_PLAN';
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS';
export const DELETE_PLAN_FAILURE = 'DELETE_PLAN_FAILURE';

export const FETCH_QUERIES = 'FETCH_QUERIES';
export const FETCH_QUERY = 'FETCH_QUERY';
export const CREATE_QUERY = 'CREATE_QUERY';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const DELETE_QUERY = 'DELETE_QUERY';
export const FETCH_QUERIES_SUCCESS = 'FETCH_QUERIES_SUCCESS';
export const FETCH_QUERIES_FAILURE = 'FETCH_QUERIES_FAILURE';
export const FETCH_QUERY_SUCCESS = 'FETCH_QUERY_SUCCESS';
export const FETCH_QUERY_FAILURE = 'FETCH_QUERY_FAILURE';
export const CREATE_QUERY_SUCCESS = 'CREATE_QUERY_SUCCESS';
export const CREATE_QUERY_FAILURE = 'CREATE_QUERY_FAILURE';
export const UPDATE_QUERY_SUCCESS = 'UPDATE_QUERY_SUCCESS';
export const UPDATE_QUERY_FAILURE = 'UPDATE_QUERY_FAILURE';
export const DELETE_QUERY_SUCCESS = 'DELETE_QUERY_SUCCESS';
export const DELETE_QUERY_FAILURE = 'DELETE_QUERY_FAILURE';

export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS';
export const FETCH_COLLECTIONS_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS';
export const FETCH_COLLECTIONS_FAILURE = 'FETCH_COLLECTIONS_FAILURE';
export const FETCH_COLLECTION = 'FETCH_COLLECTION';
export const FETCH_COLLECTION_SUCCESS = 'FETCH_COLLECTION_SUCCESS';
export const FETCH_COLLECTION_FAILURE = 'FETCH_COLLECTIONS_FAILURE';
export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_FAILURE = 'CREATE_COLLECTIONS_FAILURE';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS';
export const UPDATE_COLLECTION_FAILURE = 'UPDATE_COLLECTIONS_FAILURE';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_FAILURE = 'DELETE_COLLECTIONS_FAILURE';
export const FETCH_SELECTION_EVENTS = 'FETCH_SELECTION_EVENTS';
export const FETCH_SELECTION_EVENTS_SUCCESS = 'FETCH_SELECTION_EVENTS_SUCCESS';
export const FETCH_SELECTION_EVENTS_FAILURE = 'FETCH_SELECTION_EVENTS_FAILURE';
export const FETCH_QUERY_EVENTS = 'FETCH_QUERY_EVENTS';
export const FETCH_QUERY_EVENTS_SUCCESS = 'FETCH_QUERY_EVENTS_SUCCESS';
export const FETCH_QUERY_EVENTS_FAILURE = 'FETCH_QUERY_EVENTS_FAILURE';
export const UPDATE_SELECTION_EVENT = 'UPDATE_SELECTION_EVENT';
export const UPDATE_SELECTION_EVENT_SUCCESS = 'UPDATE_SELECTION_EVENT_SUCCESS';
export const UPDATE_SELECTION_EVENT_FAILURE = 'UPDATE_SELECTION_EVENT_FAILURE';
export const CLEAR_SELECTION_EVENTS = 'CLEAR_SELECTION_EVENTS';
export const CLEAR_QUERY_EVENTS = 'CLEAR_QUERY_EVENTS';

export const FETCH_FEATURES = 'FETCH_FEATURES';
export const FETCH_FEATURES_SUCCESS = 'FETCH_FEATURES_SUCCESS';
export const FETCH_FEATURES_FAILURE = 'FETCH_FEATURES_FAILURE';
export const FETCH_FEATURE = 'FETCH_FEATURE';
export const FETCH_FEATURE_SUCCESS = 'FETCH_FEATURE_SUCCESS';
export const FETCH_FEATURE_FAILURE = 'FETCH_FEATURES_FAILURE';
export const CREATE_FEATURE = 'CREATE_FEATURE';
export const CREATE_FEATURE_SUCCESS = 'CREATE_FEATURE_SUCCESS';
export const CREATE_FEATURE_FAILURE = 'CREATE_FEATURES_FAILURE';
export const UPDATE_FEATURE = 'UPDATE_FEATURE';
export const UPDATE_FEATURE_SUCCESS = 'UPDATE_FEATURE_SUCCESS';
export const UPDATE_FEATURE_FAILURE = 'UPDATE_FEATURES_FAILURE';
export const DELETE_FEATURE = 'DELETE_FEATURE';
export const DELETE_FEATURE_SUCCESS = 'DELETE_FEATURE_SUCCESS';
export const DELETE_FEATURE_FAILURE = 'DELETE_FEATURES_FAILURE';
export const FETCH_FEATURE_COLLECTION = 'FETCH_FEATURE_COLLECTION';
export const FETCH_FEATURE_COLLECTION_SUCCESS =
  'FETCH_FEATURE_COLLECTION_SUCCESS';
export const FETCH_FEATURE_COLLECTION_FAILURE =
  'FETCH_FEATURE_COLLECTIONS_FAILURE';
export const INVALIDATE_FEATURE_COLLECTION = 'INVALIDATE_FEATURE_COLLECTION';
export const SAVE_FEATURE_COLLECTION = 'SAVE_FEATURE_COLLECTION';
export const SAVE_FEATURE_COLLECTION_SUCCESS =
  'SAVE_FEATURE_COLLECTION_SUCCESS';
export const SAVE_FEATURE_COLLECTION_FAILURE =
  'SAVE_FEATURE_COLLECTION_FAILURE';
export const UPDATE_FEATURE_COLLECTION = 'UPDATE_FEATURE_COLLECTION';
export const ADD_FEATURE_TO_COLLECTION = 'ADD_FEATURE_TO_COLLECTION';
export const UPDATE_SELECTED_FEATURE_INDEX = 'UPDATE_SELECTED_FEATURE_INDEX';
export const UPDATE_SELECTED_FEATURE = 'UPDATE_SELECTED_FEATURE';
export const UPDATE_SELECTED_FEATURE_GROUPS = 'UPDATE_SELECTED_FEATURE_GROUPS';
export const UPDATE_SELECTED_FEATURE_TIME_PERIODS =
  'UPDATE_SELECTED_FEATURE_TIME_PERIODS';
export const UPDATE_SELECTED_FEATURE_GEOMETRY =
  'UPDATE_SELECTED_FEATURE_GEOMETRY';
export const UPDATE_HOVER_FEATURE_INDEX = 'UPDATE_HOVER_FEATURE_INDEX';

export const FETCH_INCIDENT_FILTERS = 'FETCH_INCIDENT_FILTERS';
export const FETCH_INCIDENT_FILTERS_SUCCESS = 'FETCH_INCIDENT_FILTERS_SUCCESS';
export const FETCH_INCIDENT_FILTERS_FAILURE = 'FETCH_INCIDENT_FILTERS_FAILURE';
export const FETCH_CRIME_FILTERS = 'FETCH_CRIME_FILTERS';
export const FETCH_CRIME_FILTERS_SUCCESS = 'FETCH_CRIME_FILTERS_SUCCESS';
export const FETCH_CRIME_FILTERS_FAILURE = 'FETCH_CRIME_FILTERS_FAILURE';
export const FETCH_INTELLIGENCE_FILTERS = 'FETCH_INTELLIGENCE_FILTERS';
export const FETCH_INTELLIGENCE_FILTERS_SUCCESS =
  'FETCH_INTELLIGENCE_FILTERS_SUCCESS';
export const FETCH_INTELLIGENCE_FILTERS_FAILURE =
  'FETCH_INTELLIGENCE_FILTERS_FAILURE';
export const FETCH_STOP_CHECK_FILTERS = 'FETCH_STOP_CHECK_FILTERS';
export const FETCH_STOP_CHECK_FILTERS_SUCCESS =
  'FETCH_STOP_CHECK_FILTERS_SUCCESS';
export const FETCH_STOP_CHECK_FILTERS_FAILURE =
  'FETCH_STOP_CHECK_FILTERS_FAILURE';
export const UPDATE_FILTER_START_TIME = 'UPDATE_FILTER_START_TIME';
export const UPDATE_FILTER_END_TIME = 'UPDATE_FILTER_END_TIME';
export const UPDATE_FILTER_LOCATION_TYPE = 'UPDATE_FILTER_LOCATION_TYPE';
export const UPDATE_FILTER_CARD_USED = 'UPDATE_FILTER_CARD_USED';
export const UPDATE_FILTER_EMERGENCY_EQUIPMENT_USED =
  'UPDATE_FILTER_EMERGENCY_EQUIPMENT_USED';
export const FETCH_USER_IDS = 'FETCH_USER_IDS';
export const FETCH_USER_IDS_SUCCESS = 'FETCH_USER_IDS_SUCCESS';
export const FETCH_USER_IDS_FAILURE = 'FETCH_USER_IDS_FAILURE';
export const UPDATE_FILTER_USER_ID = 'UPDATE_FILTER_USER_ID';

export const FETCH_HOME_STATIONS = 'FETCH_HOME_STATIONS';
export const FETCH_HOME_STATIONS_SUCCESS = 'FETCH_HOME_STATIONS_SUCCESS';
export const FETCH_HOME_STATIONS_FAILURE = 'FETCH_HOME_STATIONS_FAILURE';
export const FETCH_WARDS = 'FETCH_WARDS';
export const FETCH_WARDS_SUCCESS = 'FETCH_WARDS_SUCCESS';
export const FETCH_WARDS_FAILURE = 'FETCH_WARDS_FAILURE';
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATIONS_FAILURE';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATIONS_FAILURE';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATIONS_FAILURE';

export const FETCH_OBJECTIVES = 'FETCH_OBJECTIVES';
export const FETCH_OBJECTIVES_SUCCESS = 'FETCH_OBJECTIVES_SUCCESS';
export const FETCH_OBJECTIVES_FAILURE = 'FETCH_OBJECTIVES_FAILURE';
export const FETCH_OBJECTIVE = 'FETCH_OBJECTIVE';
export const FETCH_OBJECTIVE_SUCCESS = 'FETCH_OBJECTIVE_SUCCESS';
export const FETCH_OBJECTIVE_FAILURE = 'FETCH_OBJECTIVES_FAILURE';
export const CREATE_OBJECTIVE = 'CREATE_OBJECTIVE';
export const CREATE_OBJECTIVE_SUCCESS = 'CREATE_OBJECTIVE_SUCCESS';
export const CREATE_OBJECTIVE_FAILURE = 'CREATE_OBJECTIVES_FAILURE';
export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE';
export const UPDATE_OBJECTIVE_SUCCESS = 'UPDATE_OBJECTIVE_SUCCESS';
export const UPDATE_OBJECTIVE_FAILURE = 'UPDATE_OBJECTIVES_FAILURE';
export const DELETE_OBJECTIVE = 'DELETE_OBJECTIVE';
export const DELETE_OBJECTIVE_SUCCESS = 'DELETE_OBJECTIVE_SUCCESS';
export const DELETE_OBJECTIVE_FAILURE = 'DELETE_OBJECTIVES_FAILURE';
export const FETCH_ATTENDANCES = 'FETCH_ATTENDANCES';
export const FETCH_ATTENDANCES_SUCCESS = 'FETCH_ATTENDANCES_SUCCESS';
export const FETCH_ATTENDANCES_FAILURE = 'FETCH_ATTENDANCES_FAILURE';
export const FETCH_ATTENDANCES_CANCELLED = 'FETCH_ATTENDANCES_CANCELLED';
export const UPDATE_ATTENDANCES_QUERY = 'UPDATE_ATTENDANCES_QUERY';
export const UPDATE_ATTENDANCES_FILTER = 'UPDATE_ATTENDANCES_FILTER';
export const FETCH_PEOPLE = 'FETCH_PEOPLE';
export const FETCH_PEOPLE_SUCCESS = 'FETCH_PEOPLE_SUCCESS';
export const FETCH_PEOPLE_FAILURE = 'FETCH_PEOPLE_FAILURE';
export const FETCH_PERSON = 'FETCH_PERSON';
export const FETCH_PERSON_SUCCESS = 'FETCH_PERSON_SUCCESS';
export const FETCH_PERSON_FAILURE = 'FETCH_PEOPLE_FAILURE';
export const CREATE_PERSON = 'CREATE_PERSON';
export const CREATE_PERSON_SUCCESS = 'CREATE_PERSON_SUCCESS';
export const CREATE_PERSON_FAILURE = 'CREATE_PEOPLE_FAILURE';
export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';
export const UPDATE_PERSON_FAILURE = 'UPDATE_PEOPLE_FAILURE';
export const DELETE_PERSON = 'DELETE_PERSON';
export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';
export const DELETE_PERSON_FAILURE = 'DELETE_PEOPLE_FAILURE';
export const FETCH_RFID_CARDS = 'FETCH_RFID_CARDS';
export const FETCH_RFID_CARDS_SUCCESS = 'FETCH_RFID_CARDS_SUCCESS';
export const FETCH_RFID_CARDS_FAILURE = 'FETCH_PEOPLE_FAILURE';
export const FETCH_RFID_CARD_PEOPLE = 'FETCH_RFID_CARD_PEOPLE';
export const FETCH_RFID_CARD_PEOPLE_SUCCESS = 'FETCH_RFID_CARD_PEOPLE_SUCCESS';
export const FETCH_RFID_CARD_PEOPLE_FAILURE = 'FETCH_PEOPLE_FAILURE';
export const REMOVE_RFID_CARD = 'REMOVE_RFID_CARD';
export const REMOVE_RFID_CARD_SUCCESS = 'REMOVE_RFID_CARD_SUCCESS';
export const REMOVE_RFID_CARD_FAILURE = 'REMOVE_RFID_CARD_FAILURE';
export const FETCH_POST_HIERARCHY = 'FETCH_POST_HIERARCHY';
export const FETCH_POST_HIERARCHY_SUCCESS = 'FETCH_POST_HIERARCHY_SUCCESS';
export const FETCH_POST_HIERARCHY_FAILURE = 'FETCH_POST_HIERARCHY_FAILURE';
export const FETCH_DRIVER_TRIPS_BY_DRIVER_CODE =
  'FETCH_DRIVER_TRIPS_BY_DRIVER_CODE';
export const FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_SUCCESS =
  'FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_SUCCESS';
export const FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_FAILURE =
  'FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_FAILURE';
export const FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_CANCELLED =
  'FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_CANCELLED';

export const FETCH_REPLAY = 'FETCH_REPLAY';
export const FETCH_REPLAY_SUCCESS = 'FETCH_REPLAY_SUCCESS';
export const FETCH_REPLAY_FAILURE = 'FETCH_REPLAY_FAILURE';

export const UPDATE_SOCKET = 'UPDATE_SOCKET';
export const ADD_LIVE_RESOURCE = 'ADD_LIVE_RESOURCE';
export const UPDATE_LIVE_RESOURCE = 'UPDATE_LIVE_RESOURCE';
export const DELETE_LIVE_RESOURCE = 'DELETE_LIVE_RESOURCE';
export const FILTER_LIVE_LIST = 'FILTER_LIVE_LIST';
export const UPDATE_LIVE_FOLLOW_OVERRIDE = 'UPDATE_LIVE_FOLLOW_OVERRIDE';
export const UPDATE_LIVE_FILTER_OVERRIDE = 'UPDATE_LIVE_FILTER_OVERRIDE';
export const UPDATE_LIVE_RESOURCES = 'UPDATE_LIVE_RESOURCES';
export const UPDATE_LIVE_FOLLOWED = 'UPDATE_LIVE_FOLLOWED';
export const UPDATE_LIVE_FILTERS = 'UPDATE_LIVE_FILTERS';
export const UPDATE_LIVE_SEARCHTEXTS = 'UPDATE_LIVE_SEARCHTEXTS';
export const UPDATE_LIVE_LAYER_VISIBILITIES = 'UPDATE_LIVE_LAYER_VISIBILITIES';
export const UPDATE_LIVE_ADVANCED_FILTERS = 'UPDATE_LIVE_ADVANCED_FILTERS';
export const UPDATE_LIVE_SORTS = 'UPDATE_LIVE_SORTS';
export const ADD_LIVE_TAG = 'ADD_LIVE_TAG';
export const ADD_LIVE_TAG_SUCCESS = 'ADD_LIVE_TAG_SUCCESS';
export const ADD_LIVE_TAG_FAILURE = 'ADD_LIVE_TAG_FAILURE';
export const DELETE_LIVE_TAG = 'DELETE_LIVE_TAG';
export const DELETE_LIVE_TAG_SUCCESS = 'DELETE_LIVE_TAG_SUCCESS';
export const DELETE_LIVE_TAG_FAILURE = 'DELETE_LIVE_TAG_FAILURE';
export const UPDATE_LIVE_VIEW_KEY = 'UPDATE_LIVE_VIEW_KEY';
export const UPDATE_LIVE_SETTINGS = 'UPDATE_LIVE_SETTINGS';

export const FETCH_VEHICLE_AVAILABILITY = 'FETCH_VEHICLE_AVAILABILITY';
export const FETCH_VEHICLE_AVAILABILITY_SUCCESS =
  'FETCH_VEHICLE_AVAILABILITY_SUCCESS';
export const FETCH_VEHICLE_AVAILABILITY_FAILURE =
  'FETCH_VEHICLE_AVAILABILITY_FAILURE';
export const FETCH_VEHICLE_AVAILABILITY_CANCELLED =
  'FETCH_VEHICLE_AVAILABILITY_CANCELLED';
export const FILTER_VEHICLE_AVAILABILITY = 'FILTER_VEHICLE_AVAILABILITY';
export const UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY =
  'UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY';

export const FETCH_VEHICLE_DAILY_UTILISATION =
  'FETCH_VEHICLE_DAILY_UTILISATION';
export const FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS =
  'FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS';
export const FETCH_VEHICLE_DAILY_UTILISATION_FAILURE =
  'FETCH_VEHICLE_DAILY_UTILISATION_FAILURE';
export const FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED =
  'FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED';
export const LOAD_VEHICLE_DAILY_UTILISATION = 'LOAD_VEHICLE_DAILY_UTILISATION';
export const LOAD_VEHICLE_DAILY_UTILISATION_SUCCESS =
  'LOAD_VEHICLE_DAILY_UTILISATION_SUCCESS';
export const LOAD_VEHICLE_DAILY_UTILISATION_FAILURE =
  'LOAD_VEHICLE_DAILY_UTILISATION_FAILURE';
export const FETCH_VEHICLE_HOURLY_UTILISATION =
  'FETCH_VEHICLE_HOURLY_UTILISATION';
export const FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS =
  'FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS';
export const FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE =
  'FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE';
export const FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED =
  'FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED';
export const LOAD_VEHICLE_HOURLY_UTILISATION =
  'LOAD_VEHICLE_HOURLY_UTILISATION';
export const LOAD_VEHICLE_HOURLY_UTILISATION_SUCCESS =
  'LOAD_VEHICLE_HOURLY_UTILISATION_SUCCESS';
export const LOAD_VEHICLE_HOURLY_UTILISATION_FAILURE =
  'LOAD_VEHICLE_HOURLY_UTILISATION_FAILURE';
export const UPDATE_VEHICLE_HOURLY_UTILISATION_TRANSFORM_OPTIONS =
  'UPDATE_VEHICLE_HOURLY_UTILISATION_TRANSFORM_OPTIONS';
export const FETCH_PERSON_DAILY_ACTIVITY = 'FETCH_PERSON_DAILY_ACTIVITY';
export const FETCH_PERSON_DAILY_ACTIVITY_SUCCESS =
  'FETCH_PERSON_DAILY_ACTIVITY_SUCCESS';
export const FETCH_PERSON_DAILY_ACTIVITY_FAILURE =
  'FETCH_PERSON_DAILY_ACTIVITY_FAILURE';
export const FETCH_PERSON_DAILY_ACTIVITY_CANCELLED =
  'FETCH_PERSON_DAILY_ACTIVITY_CANCELLED';
export const LOAD_PERSON_DAILY_ACTIVITY = 'LOAD_PERSON_DAILY_ACTIVITY';
export const LOAD_PERSON_DAILY_ACTIVITY_SUCCESS =
  'LOAD_PERSON_DAILY_ACTIVITY_SUCCESS';
export const LOAD_PERSON_DAILY_ACTIVITY_FAILURE =
  'LOAD_PERSON_DAILY_ACTIVITY_FAILURE';
export const SORT_PERSON_DAILY_ACTIVITY = 'SORT_PERSON_DAILY_ACTIVITY';
export const FETCH_PERSON_HOURLY_ACTIVITY = 'FETCH_PERSON_HOURLY_ACTIVITY';
export const FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS =
  'FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS';
export const FETCH_PERSON_HOURLY_ACTIVITY_FAILURE =
  'FETCH_PERSON_HOURLY_ACTIVITY_FAILURE';
export const FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED =
  'FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED';
export const LOAD_PERSON_HOURLY_ACTIVITY = 'LOAD_PERSON_HOURLY_ACTIVITY';
export const LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS =
  'LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS';
export const LOAD_PERSON_HOURLY_ACTIVITY_FAILURE =
  'LOAD_PERSON_HOURLY_ACTIVITY_FAILURE';
export const FETCH_AUDIT_LOG_ENTRIES = 'FETCH_AUDIT_LOG_ENTRIES';
export const FETCH_AUDIT_LOG_ENTRIES_SUCCESS =
  'FETCH_AUDIT_LOG_ENTRIES_SUCCESS';
export const FETCH_AUDIT_LOG_ENTRIES_FAILURE =
  'FETCH_AUDIT_LOG_ENTRIES_FAILURE';
export const FETCH_AUDIT_LOG_ENTRIES_CANCELLED =
  'FETCH_AUDIT_LOG_ENTRIES_CANCELLED';
export const FETCH_VEHICLE_IN_BASE_TIME = 'FETCH_VEHICLE_IN_BASE_TIME';
export const FETCH_VEHICLE_IN_BASE_TIME_SUCCESS =
  'FETCH_VEHICLE_IN_BASE_TIME_SUCCESS';
export const FETCH_VEHICLE_IN_BASE_TIME_FAILURE =
  'FETCH_VEHICLE_IN_BASE_TIME_FAILURE';
export const FETCH_VEHICLE_IN_BASE_TIME_CANCELLED =
  'FETCH_VEHICLE_IN_BASE_TIME_CANCELLED';
export const LOAD_VEHICLE_IN_BASE_TIME = 'LOAD_VEHICLE_IN_BASE_TIME';
export const LOAD_VEHICLE_IN_BASE_TIME_SUCCESS =
  'LOAD_VEHICLE_IN_BASE_TIME_SUCCESS';
export const LOAD_VEHICLE_IN_BASE_TIME_FAILURE =
  'LOAD_VEHICLE_IN_BASE_TIME_FAILURE';
export const FETCH_DRIVING_SCORES = 'FETCH_DRIVING_SCORES';
export const FETCH_DRIVING_SCORES_SUCCESS = 'FETCH_DRIVING_SCORES_SUCCESS';
export const FETCH_DRIVING_SCORES_FAILURE = 'FETCH_DRIVING_SCORES_FAILURE';
export const FETCH_DRIVING_SCORES_CANCELLED = 'FETCH_DRIVING_SCORES_CANCELLED';

export const FETCH_AGGREGATED_DRIVING_SCORES =
  'FETCH_AGGREGATED_DRIVING_SCORES';
export const FETCH_AGGREGATED_DRIVING_SCORES_SUCCESS =
  'FETCH_AGGREGATED_DRIVING_SCORES_SUCCESS';
export const FETCH_AGGREGATED_DRIVING_SCORES_FAILURE =
  'FETCH_AGGREGATED_DRIVING_SCORES_FAILURE';
export const UPDATE_AGGREGATED_DRIVING_SCORE_FILTER =
  'UPDATE_AGGREGATED_DRIVING_SCORE_FILTER';
export const FETCH_AGGREGATED_DRIVING_SCORES_CANCELLED =
  'FETCH_AGGREGATED_DRIVING_SCORES_CANCELLED';
export const LOAD_AGGREGATED_DRIVING_SCORES = 'LOAD_AGGREGATED_DRIVING_SCORES';
export const LOAD_AGGREGATED_DRIVING_SCORES_SUCCESS =
  'LOAD_AGGREGATED_DRIVING_SCORES_SUCCESS';
export const LOAD_AGGREGATED_DRIVING_SCORES_FAILURE =
  'LOAD_AGGREGATED_DRIVING_SCORES_FAILURE';
export const UPDATE_FILTER_TIME_AGGREGATION = 'UPDATE_FILTER_TIME_AGGREGATION';
export const UPDATE_SELECTED_AGGREGATED_TRIPS_DRIVER =
  'UPDATE_SELECTED_AGGREGATED_TRIPS_DRIVER';

export const FETCH_VEHICLES_IN_LOCATIONS = 'FETCH_VEHICLES_IN_LOCATIONS';
export const FETCH_VEHICLES_IN_LOCATIONS_SUCCESS =
  'FETCH_VEHICLES_IN_LOCATIONS_SUCCESS';
export const FETCH_VEHICLES_IN_LOCATIONS_FAILURE =
  'FETCH_VEHICLES_IN_LOCATIONS_FAILURE';
export const FETCH_VEHICLES_IN_LOCATIONS_CANCELLED =
  'FETCH_VEHICLES_IN_LOCATIONS_CANCELLED';
export const LOAD_VEHICLES_IN_LOCATIONS = 'LOAD_VEHICLES_IN_LOCATIONS';
export const LOAD_VEHICLES_IN_LOCATIONS_SUCCESS =
  'LOAD_VEHICLES_IN_LOCATIONS_SUCCESS';
export const LOAD_VEHICLES_IN_LOCATIONS_FAILURE =
  'LOAD_VEHICLES_IN_LOCATIONS_FAILURE';
export const FETCH_RETROSPECTIVES = 'FETCH_RETROSPECTIVES';
export const FETCH_RETROSPECTIVES_SUCCESS = 'FETCH_RETROSPECTIVES_SUCCESS';
export const FETCH_RETROSPECTIVES_FAILURE = 'FETCH_RETROSPECTIVES_FAILURE';
export const FETCH_RETROSPECTIVE = 'FETCH_RETROSPECTIVE';
export const FETCH_RETROSPECTIVE_SUCCESS = 'FETCH_RETROSPECTIVE_SUCCESS';
export const FETCH_RETROSPECTIVE_FAILURE = 'FETCH_RETROSPECTIVES_FAILURE';
export const CREATE_RETROSPECTIVE = 'CREATE_RETROSPECTIVE';
export const CREATE_RETROSPECTIVE_SUCCESS = 'CREATE_RETROSPECTIVE_SUCCESS';
export const CREATE_RETROSPECTIVE_FAILURE = 'CREATE_RETROSPECTIVES_FAILURE';
export const UPDATE_RETROSPECTIVE = 'UPDATE_RETROSPECTIVE';
export const UPDATE_RETROSPECTIVE_SUCCESS = 'UPDATE_RETROSPECTIVE_SUCCESS';
export const UPDATE_RETROSPECTIVE_FAILURE = 'UPDATE_RETROSPECTIVES_FAILURE';
export const PUSH_RETROSPECTIVE_FORM = 'PUSH_RETROSPECTIVE_FORM';
export const SYNC_RETROSPECTIVE_FORM = 'SYNC_RETROSPECTIVE_FORM';
export const DELETE_RETROSPECTIVE = 'DELETE_RETROSPECTIVE';
export const DELETE_RETROSPECTIVE_SUCCESS = 'DELETE_RETROSPECTIVE_SUCCESS';
export const DELETE_RETROSPECTIVE_FAILURE = 'DELETE_RETROSPECTIVES_FAILURE';
export const CLEAR_RETROSPECTIVE = 'CLEAR_RETROSPECTIVE';
export const FETCH_RETROSPECTIVE_LAYER = 'FETCH_RETROSPECTIVE_LAYER';
export const FETCH_RETROSPECTIVE_LAYER_SUCCESS =
  'FETCH_RETROSPECTIVE_LAYER_SUCCESS';
export const FETCH_RETROSPECTIVE_LAYER_FAILURE =
  'FETCH_RETROSPECTIVE_LAYERS_FAILURE';
export const FETCH_RETROSPECTIVE_LAYER_CANCELLED =
  'FETCH_RETROSPECTIVE_LAYER_CANCELLED;';
export const FETCH_RETROSPECTIVE_LAYER_BOUNDARY =
  'FETCH_RETROSPECTIVE_LAYER_BOUNDARY';
export const FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS =
  'FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS';
export const FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE =
  'FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE';
export const FETCH_RETROSPECTIVE_ITEM = 'FETCH_RETROSPECTIVE_ITEM';
export const FETCH_RETROSPECTIVE_ITEM_SUCCESS =
  'FETCH_RETROSPECTIVE_ITEM_SUCCESS';
export const FETCH_RETROSPECTIVE_ITEM_FAILURE =
  'FETCH_RETROSPECTIVE_ITEM_FAILURE';

export const FETCH_VEHICLES = 'FETCH_VEHICLES';
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS';
export const FETCH_VEHICLES_FAILURE = 'FETCH_VEHICLES_FAILURE';
export const FETCH_VEHICLE = 'FETCH_VEHICLE';
export const FETCH_VEHICLE_SUCCESS = 'FETCH_VEHICLE_SUCCESS';
export const FETCH_VEHICLE_FAILURE = 'FETCH_VEHICLES_FAILURE';
export const CREATE_VEHICLE = 'CREATE_VEHICLE';
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS';
export const CREATE_VEHICLE_FAILURE = 'CREATE_VEHICLES_FAILURE';
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAILURE = 'UPDATE_VEHICLES_FAILURE';
export const REMOVE_VEHICLE_TELEMATICS_BOX = 'REMOVE_VEHICLE_TELEMATICS_BOX';
export const REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS =
  'REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS';
export const REMOVE_VEHICLE_TELEMATICS_BOX_FAILURE =
  'REMOVE_VEHICLE_TELEMATICS_BOX_FAILURE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const DELETE_VEHICLE_FAILURE = 'DELETE_VEHICLES_FAILURE';
export const FETCH_TELEMATICS_BOX_POLLS = 'FETCH_TELEMATICS_BOX_POLLS';
export const FETCH_TELEMATICS_BOX_POLLS_SUCCESS =
  'FETCH_TELEMATICS_BOX_POLLS_SUCCESS';
export const FETCH_TELEMATICS_BOX_POLLS_FAILURE =
  'FETCH_TELEMATICS_BOX_POLLS_FAILURE';
export const FETCH_TELEMATICS_BOXES = 'FETCH_TELEMATICS_BOXES';
export const FETCH_TELEMATICS_BOXES_SUCCESS = 'FETCH_TELEMATICS_BOXES_SUCCESS';
export const FETCH_TELEMATICS_BOXES_FAILURE = 'FETCH_TELEMATICS_BOXES_FAILURE';
export const UPDATE_TELEMATICS_BOXES_FILTER = 'UPDATE_TELEMATICS_BOXES_FILTER';
export const UPDATE_TELEMATICS_BOXES_SORT = 'UPDATE_TELEMATICS_BOXES_SORT';
export const START_TELEMATICS_BOX_POLLS_STREAM =
  'START_TELEMATICS_BOX_POLLS_STREAM';
export const START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS =
  'START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS';
export const START_TELEMATICS_BOX_POLLS_STREAM_FAILURE =
  'START_TELEMATICS_BOX_POLLS_STREAM_FAILURE';
export const END_TELEMATICS_BOX_POLLS_STREAM =
  'END_TELEMATICS_BOX_POLLS_STREAM';
export const END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS =
  'END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS';
export const RECEIVE_TELEMATICS_BOX_POLL = 'RECEIVE_TELEMATICS_BOX_POLL';

export const START_LIVE_STREAM = 'START_LIVE_STREAM';
export const START_LIVE_STREAM_SUCCESS = 'START_LIVE_STREAM_SUCCESS';
export const START_LIVE_STREAM_FAILURE = 'START_LIVE_STREAM_FAILURE';
export const LIVE_STREAM_WARNING = 'LIVE_STREAM_WARNING';
export const END_LIVE_STREAM = 'END_LIVE_STREAM';
export const END_LIVE_STREAM_SUCCESS = 'END_LIVE_STREAM_SUCCESS';

export const FETCH_LIVE_VIEWS = 'FETCH_LIVE_VIEWS';
export const FETCH_LIVE_VIEWS_SUCCESS = 'FETCH_LIVE_VIEWS_SUCCESS';
export const FETCH_LIVE_VIEWS_FAILURE = 'FETCH_LIVE_VIEWS_FAILURE';
export const ADD_LIVE_VIEW = 'ADD_LIVE_VIEW';
export const ADD_LIVE_VIEW_SUCCESS = 'ADD_LIVE_VIEW_SUCCESS';
export const ADD_LIVE_VIEW_FAILURE = 'ADD_LIVE_VIEW_FAILURE';
export const DELETE_LIVE_VIEW = 'DELETE_LIVE_VIEW';
export const DELETE_LIVE_VIEW_SUCCESS = 'DELETE_LIVE_VIEW_SUCCESS';
export const DELETE_LIVE_VIEW_FAILURE = 'DELETE_LIVE_VIEW_FAILURE';

export const FETCH_LIVE_INCIDENT = 'FETCH_LIVE_INCIDENT';
export const FETCH_LIVE_INCIDENT_SUCCESS = 'FETCH_LIVE_INCIDENT_SUCCESS';
export const FETCH_LIVE_INCIDENT_FAILURE = 'FETCH_LIVE_INCIDENT_FAILURE';
export const FETCH_LIVE_LOCATION = 'FETCH_LIVE_LOCATION';
export const FETCH_LIVE_LOCATION_SUCCESS = 'FETCH_LIVE_LOCATION_SUCCESS';
export const FETCH_LIVE_LOCATION_FAILURE = 'FETCH_LIVE_LOCATION_FAILURE';
export const FETCH_LIVE_VEHICLE = 'FETCH_LIVE_VEHICLE';
export const FETCH_LIVE_VEHICLE_SUCCESS = 'FETCH_LIVE_VEHICLE_SUCCESS';
export const FETCH_LIVE_VEHICLE_FAILURE = 'FETCH_LIVE_VEHICLE_FAILURE';
export const FETCH_LIVE_PERSON = 'FETCH_LIVE_PERSON';
export const FETCH_LIVE_PERSON_SUCCESS = 'FETCH_LIVE_PERSON_SUCCESS';
export const FETCH_LIVE_PERSON_FAILURE = 'FETCH_LIVE_PERSON_FAILURE';

export const FETCH_VEHICLE_ODOMETERS = 'FETCH_VEHICLE_ODOMETERS';
export const FETCH_VEHICLE_ODOMETERS_SUCCESS =
  'FETCH_VEHICLE_ODOMETERS_SUCCESS';
export const FETCH_VEHICLE_ODOMETERS_FAILURE =
  'FETCH_VEHICLE_ODOMETERS_FAILURE';
export const UPDATE_AUDIT_LOG_FILTER = 'UPDATE_AUDIT_LOG_FILTER';
export const FETCH_OBJECTIVE_ATTENDANCES = 'FETCH_OBJECTIVE_ATTENDANCES';
export const FETCH_OBJECTIVE_ATTENDANCES_SUCCESS =
  'FETCH_OBJECTIVE_ATTENDANCES_SUCCESS';
export const FETCH_OBJECTIVE_ATTENDANCES_CANCELLED =
  'FETCH_OBJECTIVE_ATTENDANCES_CANCELLED';
export const FETCH_OBJECTIVE_ATTENDANCES_FAILURE =
  'FETCH_OBJECTIVE_ATTENDANCES_FAILURE';
export const UPDATE_OBJECTIVE_ATTENDANCES_FILTER =
  'UPDATE_OBJECTIVE_ATTENDANCES_FILTER';
export const UPDATE_VEHICLE_ODOMETERS_FILTER =
  'UPDATE_VEHICLE_ODOMETERS_FILTER';

export const FETCH_TRIPS = 'FETCH_TRIPS';
export const FETCH_TRIPS_SUCCESS = 'FETCH_TRIPS_SUCCESS';
export const FETCH_TRIPS_FAILURE = 'FETCH_TRIPS_FAILURE';
export const FETCH_TRIPS_CANCELLED = 'FETCH_TRIPS_CANCELLED';
export const UPDATE_TRIPS_FILTER = 'UPDATE_TRIPS_FILTER';
export const UPDATE_TRIPS_QUERY = 'UPDATE_TRIPS_QUERY';
export const UPDATE_TRIP_CLASSIFICATION = 'UPDATE_TRIP_CLASSIFICATION';
export const UPDATE_TRIP_CLASSIFICATION_SUCCESS =
  'UPDATE_TRIP_CLASSIFICATION_SUCCESS';
export const UPDATE_TRIP_CLASSIFICATION_FAILURE =
  'UPDATE_TRIP_CLASSIFICATIONS_FAILURE';
export const UPDATE_TRIP_DRIVER = 'UPDATE_TRIP_DRIVER';
export const UPDATE_TRIP_DRIVER_SUCCESS = 'UPDATE_TRIP_DRIVER_SUCCESS';
export const UPDATE_TRIP_DRIVER_FAILURE = 'UPDATE_TRIP_DRIVERS_FAILURE';

export const FETCH_SPEED_INFRACTIONS = 'FETCH_SPEED_INFRACTIONS';
export const FETCH_SPEED_INFRACTIONS_SUCCESS =
  'FETCH_SPEED_INFRACTIONS_SUCCESS';
export const FETCH_SPEED_INFRACTIONS_FAILURE =
  'FETCH_SPEED_INFRACTIONS_FAILURE';
export const FETCH_SPEED_INFRACTIONS_CANCELLED =
  'FETCH_SPEED_INFRACTIONS_CANCELLED';
export const UPDATE_SPEED_INFRACTIONS_FILTER =
  'UPDATE_SPEED_INFRACTIONS_FILTER';
export const UPDATE_SPEED_INFRACTIONS_QUERY = 'UPDATE_SPEED_INFRACTIONS_QUERY';

export const FETCH_ACCELEROMETER_EVENTS = 'FETCH_ACCELEROMETER_EVENTS';
export const FETCH_ACCELEROMETER_EVENTS_SUCCESS =
  'FETCH_ACCELEROMETER_EVENTS_SUCCESS';
export const FETCH_ACCELEROMETER_EVENTS_FAILURE =
  'FETCH_ACCELEROMETER_EVENTS_FAILURE';
export const FETCH_ACCELEROMETER_EVENTS_CANCELLED =
  'FETCH_ACCELEROMETER_EVENTS_CANCELLED';
export const UPDATE_ACCELEROMETER_EVENTS_FILTER =
  'UPDATE_ACCELEROMETER_EVENTS_FILTER';
export const UPDATE_ACCELEROMETER_EVENTS_QUERY =
  'UPDATE_ACCELEROMETER_EVENTS_QUERY';

export const FETCH_VEHICLE_LOCATION_VISITS = 'FETCH_VEHICLE_LOCATION_VISITS';
export const FETCH_VEHICLE_LOCATION_VISITS_SUCCESS =
  'FETCH_VEHICLE_LOCATION_VISITS_SUCCESS';
export const FETCH_VEHICLE_LOCATION_VISITS_FAILURE =
  'FETCH_VEHICLE_LOCATION_VISITS_FAILURE';
export const FETCH_VEHICLE_LOCATION_VISITS_CANCELLED =
  'FETCH_VEHICLE_LOCATION_VISITS_CANCELLED';
export const UPDATE_VEHICLE_LOCATION_VISITS_FILTER =
  'UPDATE_VEHICLE_LOCATION_VISITS_FILTER';
export const UPDATE_VEHICLE_LOCATION_VISITS_QUERY =
  'UPDATE_VEHICLE_LOCATION_VISITS_QUERY';

export const FETCH_STOPS = 'FETCH_STOPS';
export const FETCH_STOPS_SUCCESS = 'FETCH_STOPS_SUCCESS';
export const FETCH_STOPS_FAILURE = 'FETCH_STOPS_FAILURE';
export const FETCH_STOPS_CANCELLED = 'FETCH_STOPS_CANCELLED';
export const UPDATE_STOPS_FILTER = 'UPDATE_STOPS_FILTER';
export const UPDATE_STOPS_QUERY = 'UPDATE_STOPS_QUERY';

export const FETCH_IDLES = 'FETCH_IDLES';
export const FETCH_IDLES_SUCCESS = 'FETCH_IDLES_SUCCESS';
export const FETCH_IDLES_FAILURE = 'FETCH_IDLES_FAILURE';
export const FETCH_IDLES_CANCELLED = 'FETCH_IDLES_CANCELLED';
export const UPDATE_IDLES_FILTER = 'UPDATE_IDLES_FILTER';
export const UPDATE_IDLES_QUERY = 'UPDATE_IDLES_QUERY';
export const FETCH_ON_BOARD_DIAGNOSTICS = 'FETCH_ON_BOARD_DIAGNOSTICS';
export const FETCH_ON_BOARD_DIAGNOSTICS_SUCCESS =
  'FETCH_ON_BOARD_DIAGNOSTICS_SUCCESS';
export const FETCH_ON_BOARD_DIAGNOSTICS_FAILURE =
  'FETCH_ON_BOARD_DIAGNOSTICS_FAILURE';
export const FETCH_ON_BOARD_DIAGNOSTICS_CANCELLED =
  'FETCH_ON_BOARD_DIAGNOSTICS_CANCELLED';
export const UPDATE_ON_BOARD_DIAGNOSTICS_FILTER =
  'UPDATE_ON_BOARD_DIAGNOSTICS_QUERY';
export const UPDATE_ON_BOARD_DIAGNOSTICS_QUERY =
  'UPDATE_ON_BOARD_DIAGNOSTICS_FILTER';

export const FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT =
  'TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT';
export const FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS =
  'FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS';
export const FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE =
  'FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE';
export const FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED =
  'FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED';
export const UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER =
  'UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_QUERY';
export const UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_QUERY =
  'UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER';

export const FETCH_TRAILS = 'FETCH_TRAILS';
export const FETCH_TRAILS_SUCCESS = 'FETCH_TRAILS_SUCCESS';
export const FETCH_TRAILS_FAILURE = 'FETCH_TRAILS_FAILURE';
export const FETCH_TRAILS_CANCELLED = 'FETCH_TRAILS_CANCELLED';
export const UPDATE_TRAILS_FILTER = 'UPDATE_TRAILS_FILTER';
export const UPDATE_TRAILS_QUERY = 'UPDATE_TRAILS_QUERY';

export const FETCH_PERSON_LOCATION_VISITS = 'FETCH_PERSON_LOCATION_VISITS';
export const FETCH_PERSON_LOCATION_VISITS_SUCCESS =
  'FETCH_PERSON_LOCATION_VISITS_SUCCESS';
export const FETCH_PERSON_LOCATION_VISITS_FAILURE =
  'FETCH_PERSON_LOCATION_VISITS_FAILURE';
export const FETCH_PERSON_LOCATION_VISITS_CANCELLED =
  'FETCH_PERSON_LOCATION_VISITS_CANCELLED';
export const UPDATE_PERSON_LOCATION_VISITS_FILTER =
  'UPDATE_PERSON_LOCATION_VISITS_FILTER';
export const UPDATE_PERSON_LOCATION_VISITS_QUERY =
  'UPDATE_PERSON_LOCATION_VISITS_QUERY';

export const FETCH_DOUBLE_CREWS = 'FETCH_DOUBLE_CREWS';
export const FETCH_DOUBLE_CREWS_SUCCESS = 'FETCH_DOUBLE_CREWS_SUCCESS';
export const FETCH_DOUBLE_CREWS_FAILURE = 'FETCH_DOUBLE_CREWS_FAILURE';
export const FETCH_DOUBLE_CREWS_CANCELLED = 'FETCH_DOUBLE_CREWS_CANCELLED';
export const UPDATE_DOUBLE_CREWS_FILTER = 'UPDATE_DOUBLE_CREWS_FILTER';
export const UPDATE_DOUBLE_CREWS_QUERY = 'UPDATE_DOUBLE_CREWS_QUERY';

export const FETCH_OUTAGES = 'FETCH_OUTAGES';
export const FETCH_OUTAGES_SUCCESS = 'FETCH_OUTAGES_SUCCESS';
export const FETCH_OUTAGES_FAILURE = 'FETCH_OUTAGES_FAILURE';
export const FETCH_OUTAGES_CANCELLED = 'FETCH_OUTAGES_CANCELLED';
export const UPDATE_OUTAGES_FILTER = 'UPDATE_OUTAGES_FILTER';
export const UPDATE_OUTAGES_QUERY = 'UPDATE_OUTAGES_QUERY';
