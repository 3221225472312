import {
  Button,
  Paper,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import {
  DirectionsCar as CarIcon,
  Router as RouterIcon,
} from '@material-ui/icons';
import moment from 'moment';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Form } from 'react-final-form';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import { useAuth } from '../Auth';
import {
  CREATE_VEHICLE,
  DELETE_VEHICLE,
  FETCH_HOME_STATIONS,
  FETCH_VEHICLE,
  FETCH_VEHICLE_SUCCESS,
  UPDATE_VEHICLE,
} from '../../actions';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  AvatarField,
  TextField,
  CheckboxField,
  SelectField,
  TypesField,
  SelectMultipleField,
  DateTimeField,
  required,
  isDateValidOrEmpty,
} from '../fields';
import { doesIdExist, imeiValid } from '../../apis/utilities';
import { TagControl } from '../controls';
import { RouteLeavingGuard } from '../controls';

const {
  vehicleRoles,
  vehicleTypes,
  vehicleFuelTypes,
  vehicleGroups,
  vehicleEquipment,
  vehicleEditableFields,
  useRestricted,
} = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  dateTimeField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  delete: {
    color: theme.palette.error.main,
  },
  equipment: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tagControl: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function Vehicle() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicles.vehicle, _.isEqual);
  const error = useSelector((state) => state.vehicles.error);
  const homeStationNames = useSelector(
    (state) => state.locations.homeStationNames,
    _.isEqual
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const auth = useAuth();
  const snackbar = useSnackbar();
  const editable = auth.isAuthorised('vehicles', true);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_VEHICLE_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_VEHICLE,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
  }, [id, dispatch]);

  async function onSubmit(values) {
    const validImei = await imeiValid(
      values.telematicsBoxImei,
      values.identificationNumber
    );
    if (!validImei) {
      return { telematicsBoxImei: 'In use' };
    }

    if (vehicle) {
      dispatch({
        type: UPDATE_VEHICLE,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('vehicles', values.identificationNumber);
      if (exists) {
        return { identificationNumber: 'Exists' };
      }

      dispatch({
        type: CREATE_VEHICLE,
        payload: values,
      });
    }
  }

  function handleDelete() {
    if (vehicle) {
      dispatch({
        type: DELETE_VEHICLE,
        payload: vehicle.identificationNumber,
      });
    }
  }

  function isDisabled(fieldName) {
    return !(
      editable &&
      (vehicleEditableFields[fieldName] || vehicle === null)
    );
  }

  return (
    <Form
      initialValues={vehicle || {}}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>
              IR3 | Vehicle
              {values.registrationNumber
                ? ` | ${values.registrationNumber}`
                : ''}
            </title>
          </Helmet>
          <Paper className={classes.card}>
            <CardHeader
              avatar={
                <Fragment>
                  <Field
                    name="picture"
                    component={AvatarField}
                    icon={<CarIcon />}
                    disabled={isDisabled('picture')}
                  />
                </Fragment>
              }
              title={values.registrationNumber}
              subheader={values.fleetNumber}
            />
            <CardContent className={classes.cardContent}>
              <List>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="identificationNumber"
                      component={TextField}
                      label="VIN"
                      className={classes.textField}
                      validate={required}
                      disabled={!(editable && vehicle === null)}
                    />
                    <Field
                      name="registrationNumber"
                      component={TextField}
                      label="Registration"
                      className={classes.textField}
                      validate={required}
                      disabled={isDisabled('registrationNumber')}
                    />
                    <Field
                      name="fleetNumber"
                      component={TextField}
                      label="Fleet Number"
                      className={classes.textField}
                      validate={required}
                      disabled={isDisabled('fleetNumber')}
                    />
                    <Field
                      name="role"
                      component={SelectField}
                      label="Role"
                      className={classes.textField}
                      validate={required}
                      values={vehicleRoles}
                      disabled={isDisabled('role')}
                    />
                    {useRestricted && (
                      <Field
                        name="restricted"
                        component={CheckboxField}
                        label="Restricted"
                        className={classes.textField}
                        disabled={isDisabled('restricted')}
                      />
                    )}
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Details</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="type"
                      component={SelectField}
                      label="Type"
                      className={classes.textField}
                      values={vehicleTypes}
                      disabled={isDisabled('type')}
                    />
                    <Field
                      name="make"
                      component={TextField}
                      label="Make"
                      className={classes.textField}
                      disabled={isDisabled('make')}
                    />
                    <Field
                      name="model"
                      component={TextField}
                      label="Model"
                      className={classes.textField}
                      disabled={isDisabled('model')}
                    />
                    <Field
                      name="colour"
                      component={TextField}
                      label="Colour"
                      className={classes.textField}
                      disabled={isDisabled('colour')}
                    />
                    <Field
                      name="fuelType"
                      component={SelectField}
                      label="Fuel Type"
                      className={classes.textField}
                      values={vehicleFuelTypes}
                      disabled={isDisabled('fuelType')}
                    />
                    <Field
                      name="marked"
                      component={CheckboxField}
                      label="Marked"
                      className={classes.textField}
                      disabled={isDisabled('marked')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Home</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="homeStation"
                      component={SelectField}
                      label="Station"
                      values={homeStationNames.map((station) => ({
                        label: station.name,
                        value: station.code,
                      }))}
                      className={classes.textField}
                      InputProps={{
                        readOnly: !editable,
                      }}
                      disabled={isDisabled('homeStation')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Groups & Areas</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="areas"
                      types={vehicleGroups}
                      component={TypesField}
                      disabled={isDisabled('areas')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Equipment</ListSubheader>
                <ListItem>
                  <div className={classes.equipment}>
                    <Field
                      name="equipment"
                      fullWidth
                      component={SelectMultipleField}
                      suggestions={vehicleEquipment}
                      disabled={isDisabled('equipment')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Telematics Box</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="telematicsBoxImei"
                      component={TextField}
                      label="IMEI"
                      className={classes.textField}
                      disabled={isDisabled('telematicsBoxImei')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                history.push(
                                  `/resources/telematicsboxes/${values.telematicsBoxImei}`
                                )
                              }
                            >
                              <RouterIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      label="Last Poll Time"
                      name="lastPollTime"
                      component={TextField}
                      className={classes.textField}
                      format={(value) =>
                        value ? moment(value).fromNow() : 'never'
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Odometer</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="lastOdometerReading.time"
                      component={DateTimeField}
                      label="Reading Date & Time"
                      className={classes.dateTimeField}
                      disabled={isDisabled('lastOdometerReading')}
                      validate={isDateValidOrEmpty}
                    />
                    <Field
                      name="lastOdometerReading.distanceMiles"
                      type="number"
                      component={TextField}
                      label="Reading Miles"
                      className={classes.textField}
                      disabled={isDisabled('lastOdometerReading')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Disposed</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="disposalDate"
                      component={DateTimeField}
                      label="Date & Time"
                      className={classes.dateTimeField}
                      disabled={isDisabled('disposalDate')}
                      validate={isDateValidOrEmpty}
                    />
                  </div>
                </ListItem>
                {/* TODO: add/remove tag items should be part of the form and control by save/cancle buttons  */}
                <div className={classes.tagControl}>
                  <TagControl item={{ id }} type={'vehicles'} />
                </div>
              </List>
            </CardContent>
            <CardActions>
              {editable && (
                <Fragment>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Save
                  </Button>
                  <Button
                    color="primary"
                    disabled={pristine || submitting}
                    onClick={reset}
                  >
                    Cancel
                  </Button>
                  {editable && (
                    <Button
                      className={classes.delete}
                      onClick={() => setDeleteOpen(true)}
                      disabled={vehicle === null}
                    >
                      Delete
                    </Button>
                  )}
                </Fragment>
              )}
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.registrationNumber || values.fleetNumber}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
          <RouteLeavingGuard when={dirty} />
        </form>
      )}
    />
  );
}
