import React from 'react';
import {
  Avatar,
  Card,
  CardHeader,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Place as PlaceIcon } from '@material-ui/icons';
import { Helmet } from 'react-helmet-async';
import Area from './Area';
import Incident from './Incident';
import PersonPoll from './PersonPoll';
import PersonTrail from './PersonTrail';
import PersonVisit from './PersonVisit';
import VehicleIdle from './VehicleIdle';
import VehiclePoll from './VehiclePoll';
import VehicleStop from './VehicleStop';
import VehicleTrip from './VehicleTrip';
import VehicleVisit from './VehicleVisit';
import Location from './Location';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0.5, 1, 1),
  },
}));

export default function Item({ item, colors }) {
  const classes = useStyles();
  const theme = useTheme();
  const background =
    colors.length > 1
      ? `linear-gradient(${colors.join()})`
      : colors[0] || theme.palette.grey[500];
  const color = theme.palette.getContrastText(
    colors[Math.floor(colors.length / 2)] || theme.palette.grey[500]
  );
  const props = {
    ...item,
    background,
    color,
  };

  switch (props.itemType) {
    case 'area':
      return <Area {...props} />;
    case 'incident':
      return <Incident {...props} />;
    case 'personPoll':
      return <PersonPoll {...props} />;
    case 'personTrail':
      return <PersonTrail {...props} />;
    case 'personVisit':
      return <PersonVisit {...props} />;
    case 'vehicleIdle':
      return <VehicleIdle {...props} />;
    case 'vehiclePoll':
      return <VehiclePoll {...props} />;
    case 'vehicleStop':
      return <VehicleStop {...props} />;
    case 'vehicleTrip':
      return <VehicleTrip {...props} />;
    case 'vehicleVisit':
      return <VehicleVisit {...props} />;
    case 'location':
      return <Location {...props} />;
    default:
      return (
        <Card className={classes.card}>
          <Helmet>
            <title>IR3 | Item{item ? ` | ${item.id}` : ''}</title>
          </Helmet>
          <CardHeader
            avatar={
              <Avatar
                style={{
                  background,
                  color,
                }}
              >
                <PlaceIcon />
              </Avatar>
            }
            title={item ? item.id : ''}
          />
        </Card>
      );
  }
}
