import {
  Paper,
  IconButton,
  TablePagination,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  GetApp as GetAppIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from '../Snackbar';
import {
  FETCH_DOUBLE_CREWS,
  FETCH_DOUBLE_CREWS_CANCELLED,
  UPDATE_DOUBLE_CREWS_FILTER,
} from '../../actions';
import Container from '../Container';
import { FilterPicker, Parameters, Table } from '../controls';
import { downloadCSV } from '../../apis/utilities';
import { filterLocally } from '../../data/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  filterField: {
    width: 144,
  },
  progress: {
    margin: theme.spacing(1),
  },
  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? [
        {
          label: 'Primary Staff ID',
          key: 'primaryCode',
          type: 'text',
          filter: true,
        },
        {
          label: 'Secondary Staff ID',
          key: 'secondaryCode',
          type: 'text',
          filter: true,
        },
      ]
    : [
        {
          label: 'Primary Name',
          key: 'primaryName',
          type: 'text',
          filter: true,
        },
        {
          label: 'Primary Collar Number',
          key: 'primaryCollarNumber',
          type: 'text',
          filter: true,
        },
        {
          label: 'Primary Role',
          key: 'primaryRole',
          type: 'text',
          filter: true,
        },
        {
          label: 'Secondary Name',
          key: 'secondaryName',
          type: 'text',
          filter: true,
        },
        {
          label: 'Secondary Collar Number',
          key: 'secondaryCollarNumber',
          type: 'text',
          filter: true,
        },
        {
          label: 'Secondary Role',
          key: 'secondaryRole',
          type: 'text',
          filter: true,
        },
      ]),
  { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
  { label: 'End Time', key: 'endTime', type: 'date', filter: false },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    type: 'number',
    filter: false,
  },
];

function ReplayLink({ entry }) {
  const history = useHistory();

  const handleViewClick = (identifier) => () => {
    history.push(`/replay/personDoubleCrews/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

const replayColumn = {
  label: '',
  key: 'replay',
  type: 'component',
  component: ReplayLink,
};

export default function DoubleCrews() {
  const dispatch = useDispatch();
  const doubleCrews = useSelector(
    (state) => state.events.doubleCrews.list,
    _.isEqual
  );
  const isLoading = useSelector((state) => state.events.doubleCrews.isLoading);
  const error = useSelector((state) => state.events.doubleCrews.error);
  const filter = useSelector(
    (state) => state.events.doubleCrews.filter,
    _.isEqual
  );
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_DOUBLE_CREWS,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_DOUBLE_CREWS_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_DOUBLE_CREWS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const filename = 'Double Crews.csv';
    const data = filteredDoubleCrews.map((doubleCrew) => ({
      primaryCode: doubleCrew.primaryCode,
      primaryName: doubleCrew.primaryName,
      primaryCollarNumber: doubleCrew.primaryCollarNumber,
      primaryRole: doubleCrew.primaryRole,
      secondaryCode: doubleCrew.secondaryCode,
      secondaryName: doubleCrew.secondaryName,
      secondaryCollarNumber: doubleCrew.secondaryCollarNumber,
      secondaryRole: doubleCrew.secondaryRole,
      startTime: moment(doubleCrew.startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(doubleCrew.endTime).format('YYYY-MM-DD HH:mm:ss'),
      durationMinutes: _.round(doubleCrew.durationMinutes || 0.0, 2),
    }));

    downloadCSV(data, filename, headers);
  }

  const filteredDoubleCrews = filterLocally(filter, doubleCrews);

  return (
    <Container title="Double Crews">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        className={classes.parameters}
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Double Crews</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Double Crews
          </Typography>
          <FilterPicker
            headers={headers}
            data={doubleCrews}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            title="Download data"
            disabled={filteredDoubleCrews.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={filteredDoubleCrews}
            headers={headers.concat(replayColumn)}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredDoubleCrews.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}
