import {
  AccessTime as AccessTimeIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  DateRange as DateRangeIcon,
  Event as EventIcon,
} from '@material-ui/icons';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import React from 'react';

export default function DateTimePicker(props) {
  return (
    <KeyboardDateTimePicker
      {...props}
      invalidDateMessage=""
      ampm={false}
      leftArrowIcon={<ChevronLeftIcon />}
      rightArrowIcon={<ChevronRightIcon />}
      dateRangeIcon={<DateRangeIcon />}
      timeIcon={<AccessTimeIcon />}
      keyboardIcon={<EventIcon />}
      // format="dd/MM/yyyy HH:mm"
    />
  );
}
