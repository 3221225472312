import React, { Fragment } from 'react';
import {
  IconButton,
  InputAdornment,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  AddCircle as AddIcon,
  RemoveCircle as RemoveIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { useForm } from "react-final-form";
import Field from './Field';
import SelectField from './SelectField';
import SelectMultipleField from './SelectMultipleField';
import AutosuggestField from './AutosuggestField';
import TextField from './TextField';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import AdornmentField from './AdornmentField';
import { requiredValidDate } from './validators';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(0.5),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  fieldValueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    maxWidth: 'calc(100% - 48px)',
  },
  fieldField: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 160,
  },
  valueField: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  autocompleteField: {
    width: '100%',
  },
  multiSelectField: {
    width: '100%',
  },
  dateTimeField: {
    width: '100%',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  conditionField: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 36,
  },
  dateField: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  },
}));

const conditions = [
  { label: '=', value: '$eq' },
  { label: String.fromCharCode(8800), value: '$ne' }, // != so chrome can debug
  { label: '>', value: '$gt' },
  { label: String.fromCharCode(8805), value: '$gte' }, // >= so chrome can debug
  { label: '<', value: '$lt' },
  { label: String.fromCharCode(8804), value: '$lte' }, // <= so chrome can debug
];

const timeConditions = [
  { label: String.fromCharCode(8805), value: '$gte' }, // >= so chrome can debug
  { label: '<', value: '$lt' },
];

const units = [
  { label: 's', value: 's' },
  { label: 'm', value: 'm' },
  { label: 'h', value: 'h' },
  { label: 'd', value: 'd' },
];

function milesToKilometres(value) {
  if (value === undefined || value === '') {
    return '';
  }

  return parseFloat(value) * 1.609344;
}

function kilometresToMiles(value) {
  if (value === undefined || value === '') {
    return '';
  }

  return Math.round(parseFloat(value) * 0.62137119);
}

function parseFloatOrBlank(value) {
  value = parseFloat(value);
  if (isNaN(value)) {
    return '';
  }

  return value;
}

const NumericTextField = (props) => (
  <TextField
    {...props}
    onChange={(event) =>
      props.input.onChange(parseFloatOrBlank(event.target.value))
    }
  />
);

const numericTypes = ['number', 'miles', 'duration', 'date', 'datetime'];
function ValueField({ name, type, values, unit, styles, filterOptions }) {
  const classes = useStyles();

  switch (type) {
    case 'autocomplete':
      return (
        <div className={classes.valueField}>
          <Field
            name={`${name}.value`}
            component={AutosuggestField}
            label="Value"
            className={classes.autocompleteField}
            suggestions={values || []}
            styles={styles}
          />
        </div>
      );
    case 'select':
      return (
        <Field
          name={`${name}.value`}
          component={SelectField}
          label="Value"
          values={values || []}
          styles={styles}
          className={classes.valueField}
        />
      );
    case 'multiselect':
      return (
        <div className={classes.multiSelectField}>
          <Field
            name={`${name}.value`}
            component={SelectMultipleField}
            label="Values"
            className={classes.valueField}
            suggestions={values || []}
            filterOptions={filterOptions}
            styles={styles}
          />
        </div>
      );
    case 'number':
      return (
        <Fragment>
          <Field
            name={`${name}.value`}
            component={NumericTextField}
            label="Value"
            type="number"
            className={classes.valueField}
            InputProps={{
              startAdornment: (
                <Field
                  name={`${name}.condition`}
                  component={AdornmentField}
                  position="start"
                  values={conditions}
                />
              ),
              endAdornment: unit ? (
                <InputAdornment position="end">{unit}</InputAdornment>
              ) : undefined,
            }}
          />
        </Fragment>
      );
    case 'miles':
      return (
        <Fragment>
          <Field
            name={`${name}.value`}
            component={NumericTextField}
            label="Value"
            type="number"
            className={classes.valueField}
            InputProps={{
              startAdornment: (
                <Field
                  name={`${name}.condition`}
                  component={AdornmentField}
                  position="start"
                  values={conditions}
                />
              ),
              endAdornment: unit ? (
                <InputAdornment position="end">{unit}</InputAdornment>
              ) : undefined,
            }}
            parse={milesToKilometres}
            format={kilometresToMiles}
          />
        </Fragment>
      );
    case 'duration':
      return (
        <Fragment>
          <Field
            name={`${name}.value`}
            component={NumericTextField}
            label="Value"
            type="number"
            className={classes.valueField}
            InputProps={{
              startAdornment: (
                <Field
                  name={`${name}.condition`}
                  component={AdornmentField}
                  position="start"
                  values={conditions}
                />
              ),
              endAdornment: (
                <Field
                  name={`${name}.unit`}
                  component={AdornmentField}
                  position="end"
                  values={units}
                />
              ),
            }}
          />
        </Fragment>
      );
    case 'date':
      return (
        <Fragment>
          <Field
            name={`${name}.value`}
            label="Value"
            component={DateField}
            className={classes.dateField}
            // as this is a filter it is more performant to save the filter value as an
            // ISO string instead of converting every item's value to a moment to compare
            parse={(value) =>
              moment(value).isValid()
                ? value.startOf('day').toISOString()
                : value
            }
            validate={requiredValidDate}
            InputProps={{
              startAdornment: (
                <Field
                  name={`${name}.condition`}
                  component={AdornmentField}
                  position="start"
                  values={timeConditions}
                />
              ),
            }}
          />
        </Fragment>
      );
    case 'datetime':
      return (
        <Fragment>
          <Field
            name={`${name}.value`}
            label="Value"
            component={DateTimeField}
            className={classes.dateTimeField}
            // as this is a filter it is more performant to save the filter value as an
            // ISO string instead of converting every item's value to a moment to compare
            parse={(value) =>
              moment(value).isValid() ? value.toISOString() : value
            }
            validate={requiredValidDate}
            InputProps={{
              startAdornment: (
                <Field
                  name={`${name}.condition`}
                  component={AdornmentField}
                  position="start"
                  values={timeConditions}
                />
              ),
            }}
          />
        </Fragment>
      );
    case 'text':
    default:
      return (
        <Field
          name={`${name}.value`}
          component={TextField}
          label="Value"
          type="text"
          className={classes.valueField}
        />
      );
  }
}

export default function FilterField({ fields, filters, label }) {
  const classes = useStyles();

  // use form mutators as a workaround for retro filter bug
  // https://github.com/final-form/react-final-form-arrays/issues/156
  const form = useForm();
  const formPush = form.mutators.push;
  const formRemove = form.mutators.remove;

  function handleAddClick() {
    // fields.push({ condition: '$eq' });
    formPush(fields.name, { condition: '$eq' });
  }

  const handleRemoveClick = (index) => () => {
    // fields.remove(index);
    formRemove(fields.name, index);
  };

  return (
    <div>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        className={classes.title}
      >
        {label}
      </Typography>
      <div>
        {fields.map((name, index) => {
          const thisField = fields.value[index];
          const fieldValues =
            Object.entries(filters)
              // in the field option, only show ones that aren't picked elsewhere
              // so allow it if it's this field OR it's not in any other field value
              // OR it's numeric; it's possible to have speed > 30 and < 60 for example
              .filter(
                ([key]) =>
                  thisField.field === key ||
                  !fields.value.some((f) => f.field === key) ||
                  numericTypes.includes(filters[key].type)
              )
              .map((filter) => ({
                label: filter[1].label,
                value: filter[0],
              })) || [];

          fieldValues.sort((a, b) => a.label.localeCompare(b.label));

          const filter = filters[thisField.field] || {};

          // if the type is date/time constrain the condition as it doesn't have =
          if (filter.type === 'date' || filter.type === 'datetime') {
            if (
              thisField &&
              !timeConditions.find(({ value }) => value === thisField.condition)
            ) {
              fields.update(index, {
                ...thisField,
                condition: timeConditions[0].value,
              });
            }
          }

          function handleFieldChange() {
            // resetFilter if that mutator exists
            if (fields?.resetFilter) {
              fields.resetFilter(name);
            }
          }

          return (
            <div className={classes.row} key={`${index}_${fields.length}`}>
              <div className={classes.fieldValueContainer}>
                {/* <Field
                  name={`${name}.subject`}
                  component={SelectField}
                  label="Subject"
                  values={}
                  className={classes.fieldField}
                  // if the field changes, wipe the selections it had
                  onChange={wipeFilter}
                /> */}
                <Field
                  name={`${name}.field`}
                  component={SelectField}
                  label="Field"
                  values={fieldValues}
                  className={classes.fieldField}
                  // if the field changes, wipe the selections it had
                  onChange={handleFieldChange}
                />
                <ValueField
                  name={name}
                  type={filter.type}
                  values={filter.values || []}
                  unit={filter.unit}
                  styles={filter.styles}
                  filterOptions={filter.filterOptions}
                />
              </div>
              <IconButton
                className={classes.button}
                title="Remove"
                aria-label="Remove"
                onClick={handleRemoveClick(index)}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          );
        })}
      </div>
      {(fields.length < Object.keys(filters).length ||
        Object.values(filters).some((filter) =>
          numericTypes.includes(filter.type)
        )) && (
        <div className={classes.row}>
          <div style={{ flex: 1 }} />
          <IconButton
            className={classes.addButton}
            title="Add"
            aria-label="Add"
            onClick={handleAddClick}
          >
            <AddIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}
