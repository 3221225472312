import {
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Assignment as AssignmentIcon,
  Beenhere as BeenhereIcon,
  Search as SearchIcon,
  BarChart as BarChartIcon,
  Build as BuildIcon,
  Business as BusinessIcon,
  ChromeReaderMode as ChromeReaderModeIcon,
  CropSquare as CropSquareIcon,
  DirectionsWalk as DirectionsWalkIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Event as EventIcon,
  ExploreOff as ExploreOffIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Timer as TimerIcon,
  Place as PlaceIcon,
  ShowChart as ShowChartIcon,
  Schedule as ScheduleIcon,
  DataUsage as DataUsageIcon,
  DirectionsCar as DirectionsCarIcon,
  EventSeat as EventSeatIcon,
  FormatShapes as FormatShapesIcon,
  Group as GroupIcon,
  History as HistoryIcon,
  Home as HomeIcon,
  Language as LanguageIcon,
  //LibraryBooks as LibraryBooksIcon,
  LocationCity as LocationCityIcon,
  NewReleases as NewReleasesIcon,
  NotInterested as NotInterestedIcon,
  Person as PersonIcon,
  Router as RouterIcon,
  Terrain as TerrainIcon,
  ThreeSixty as ThreeSixtyIcon,
  Timeline as TimelineIcon,
  Work as WorkIcon,
  Nfc as NfcIcon,
  VpnKey as VpnKeyIcon,
  People as PeopleIcon,
  Star as StarIcon,
  More as MoreIcon,
  GpsFixed as NearbyIcon,
  Snooze as SnoozeIcon,
  HowToReg as AttendanceIcon,
} from '@material-ui/icons';
import {
  CarSide as CarSideIcon,
  CarTractionControl as CarTractionControlIcon,
  Counter as CounterIcon,
  Garage as GarageIcon,
  RadioHandheld as RadioHandheldIcon,
  // Steering as SteeringIcon,
  // AccountQuestion as AccountQuestionIcon,
  Speedometer as SpeedometerIcon,
  AxisArrow as AxisArrowIcon,
  CarBrakeParking as CarBrakeParkingIcon,
  AlertCircle as AlertCircleIcon,
  EngineOutline as EngineOutlineIcon,
} from 'mdi-material-ui';
import Home from './components/Home';
import BriefList from './components/briefs/BriefList';
import PlanList from './components/plans/PlanList';
import TagList from './components/tags/TagList';
import QueryList from './components/queries/QueryList';
import FeatureList from './components/features/FeatureList';
import ObjectiveList from './components/objectives/ObjectiveList';
import BriefContents from './components/briefs/BriefContents';
import Callback from './components/Callback';
import PlanContents from './components/plans/PlanContents';
import QueryContents from './components/queries/QueryContents';
import Live from './components/live/Live';
import PersonList from './components/resources/PersonList';
import Radios from './components/resources/Radios';
import RfidCards from './components/resources/RfidCardList';
import TelematicsBoxList from './components/resources/TelematicsBoxList';
import VehicleList from './components/resources/VehicleList';
import PostList from './components/resources/PostList';
import LocationList from './components/locations/LocationList';
import Replay from './components/replay/Replay';
import Retrospective from './components/retrospective/Retrospective';
import Tasks from './components/Tasks';
import Nearby from './components/nearby/Nearby';
import {
  Trips,
  SpeedInfractions,
  AccelerometerEvents,
  VehicleLocationVisits,
  MalfunctionIndicatorLight,
  OnBoardDiagnostics,
  PersonLocationVisits,
  Trails,
  DoubleCrews,
  Outages,
  Stops,
  Idles,
  Attendances,
} from './components/events';
import {
  DailyUtilisation,
  HourlyUtilisation,
  HourlyInLocations,
  TimelineInLocations,
  Mileage,
  Availability,
} from './components/reports/vehiclesReports';
import {
  DailyActivity,
  HourlyActivity,
  DriverBehaviourDashboard,
} from './components/reports/peopleReports';
import { LastContact } from './components/reports/telematicsBoxesReports';
import AuditReport from './components/reports/AuditReport';
import NotFound from './components/NotFound';
import IndividualDriving from './components/individual/IndividualDriving';
import { isMobile } from 'react-device-detect';

const {
  baseType,
  showLive,
  isFleet,
  showDoubleCrews,
  useDallasKeys,
  // unknownDriverTripsGroup,
} = window.config;

export const routes = isMobile
  ? [
      { key: 'home', path: '/', exact: true, Component: Home },
      {
        key: 'nearby',
        path: '/nearby',
        exact: true,
        Component: Nearby,
      },
      {
        key: 'individuaDriving',
        path: '/individual/individualDriving',
        exact: true,
        Component: IndividualDriving,
      },
    ]
  : [
      // { key: '', path: '', exact: true, Component: },
      { key: 'home', path: '/', exact: true, Component: Home },
      {
        key: 'callback',
        path: '/callback',
        exact: true,
        Component: Callback,
      },
      {
        key: 'replay',
        path: '/replay/:type/:id',
        exact: true,
        Component: Replay,
      },
      {
        key: 'live',
        path: '/live/:type?/:id?/:subId?',
        exact: true,
        Component: Live,
      },
      {
        key: 'retrospective',
        path: '/retrospective/:id?/:layerIndex?/:itemIndex?',
        exact: true,
        Component: Retrospective,
      },
      {
        key: 'tasks',
        path: '/tasks/:id?',
        exact: true,
        Component: Tasks,
      },
      {
        key: 'planContents',
        path: '/plans/:id/contents',
        exact: true,
        Component: PlanContents,
      },
      {
        key: 'queryContents',
        path: '/queries/:id/contents',
        exact: true,
        Component: QueryContents,
      },
      {
        key: 'briefContents',
        path: '/briefs/:id/contents',
        exact: true,
        Component: BriefContents,
      },
      {
        key: 'briefs',
        path: '/briefs/:id?',
        exact: true,
        Component: BriefList,
      },
      {
        key: 'plans',
        path: '/plans/:id?',
        exact: true,
        Component: PlanList,
      },
      {
        key: 'tags',
        path: '/tags/:id?',
        exact: true,
        Component: TagList,
      },
      {
        key: 'queries',
        path: '/queries/:id?',
        exact: true,
        Component: QueryList,
      },
      {
        key: 'features',
        path: '/features/:featureType/:id?',
        exact: true,
        Component: FeatureList,
      },
      {
        key: 'objectives',
        path: '/objectives/:objectiveType/:id?/:report?',
        exact: true,
        Component: ObjectiveList,
      },
      {
        key: 'vehicles',
        path: '/resources/vehicles/:id?/:report?',
        exact: true,
        Component: VehicleList,
      },
      {
        key: 'people',
        path: '/resources/people/:id?/:report?',
        exact: true,
        Component: PersonList,
      },
      {
        key: 'telematicsBoxes',
        path: '/resources/telematicsboxes/:id?',
        exact: true,
        Component: TelematicsBoxList,
      },
      {
        key: 'radios',
        path: '/resources/radios/:id?',
        exact: true,
        Component: Radios,
      },
      {
        key: 'rfidCards',
        path: `/resources/${useDallasKeys ? 'dallaskeys' : 'rfidcards'}/:id?`,
        exact: true,
        Component: RfidCards,
      },
      {
        key: 'posts',
        path: '/resources/posts/:id?',
        exact: true,
        Component: PostList,
      },
      {
        key: 'locations',
        path: '/locations/:locationType/:id?/:report?',
        exact: true,
        Component: LocationList,
      },
      {
        key: 'dailyUtilisation',
        path: '/reports/vehicles/dailyutilisation',
        exact: true,
        Component: DailyUtilisation,
      },
      {
        key: 'hourlyUtilisation',
        path: '/reports/vehicles/hourlyutilisation',
        exact: true,
        Component: HourlyUtilisation,
      },
      {
        key: 'hourlyInLocations',
        path: '/reports/vehicles/hourlyinlocations',
        exact: true,
        Component: HourlyInLocations,
      },
      {
        key: 'timelineInLocations',
        path: '/reports/vehicles/timelineinlocations',
        exact: true,
        Component: TimelineInLocations,
      },
      {
        key: 'mileage',
        path: '/reports/vehicles/mileage',
        exact: true,
        Component: Mileage,
      },
      {
        key: 'availability',
        path: '/reports/vehicles/availability',
        exact: true,
        Component: Availability,
      },
      {
        key: 'dailyActivity',
        path: '/reports/people/dailyactivity',
        exact: true,
        Component: DailyActivity,
      },
      {
        key: 'hourlyActivity',
        path: '/reports/people/hourlyactivity',
        exact: true,
        Component: HourlyActivity,
      },
      // {
      //   key: 'unknownDriverTrips',
      //   path: '/reports/driving/unknowndrivertrips',
      //   exact: true,
      //   Component: UnknownDriverTrips,
      // },
      {
        key: 'doubleCrews',
        path: '/events/people/doublecrews',
        exact: true,
        Component: DoubleCrews,
      },
      {
        key: 'driverBehaviour',
        path: '/reports/driving/behaviour',
        exact: true,
        Component: DriverBehaviourDashboard,
      },
      {
        key: 'outages',
        path: '/events/telematicsboxes/outages',
        exact: true,
        Component: Outages,
      },
      {
        key: 'lastContact',
        path: '/reports/telematicsboxes/lastcontact',
        exact: true,
        Component: LastContact,
      },
      {
        key: 'audit',
        path: '/reports/audit',
        exact: true,
        Component: AuditReport,
      },
      {
        key: 'trips',
        path: '/events/vehicles/trips',
        exact: true,
        Component: Trips,
      },
      {
        key: 'stops',
        path: '/events/vehicles/stops',
        exact: true,
        Component: Stops,
      },
      {
        key: 'idles',
        path: '/events/vehicles/idles',
        exact: true,
        Component: Idles,
      },
      {
        key: 'speedInfractions',
        path: '/events/vehicles/speedinfractions',
        exact: true,
        Component: SpeedInfractions,
      },
      {
        key: 'accelerometerEvents',
        path: '/events/vehicles/accelerometerevents',
        exact: true,
        Component: AccelerometerEvents,
      },
      {
        key: 'vehicleLocationVisits',
        path: '/events/vehicles/locationvisits',
        exact: true,
        Component: VehicleLocationVisits,
      },
      {
        key: 'malfunctionIndicatorLight',
        path: '/events/vehicles/malfunctionindicatorlight',
        exact: true,
        Component: MalfunctionIndicatorLight,
      },
      {
        key: 'onBoardDiagnostics',
        path: '/events/vehicles/onboarddiagnostics',
        exact: true,
        Component: OnBoardDiagnostics,
      },
      {
        key: 'personLocationVisits',
        path: '/events/people/locationvisits',
        exact: true,
        Component: PersonLocationVisits,
      },
      {
        key: 'trails',
        path: '/events/people/trails',
        exact: true,
        Component: Trails,
      },
      {
        key: 'attendances',
        path: '/events/people/attendances',
        exact: true,
        Component: Attendances,
      },
      { key: 'notFound', Component: NotFound },
    ];

export function isAllowed(key, auth) {
  const whitelist = ['home', 'callback'];

  if (whitelist.includes(key)) {
    return true;
  }

  if (!auth || !auth.isAuthenticated()) {
    return false;
  }

  // const groupNames = auth.getGroupNames();

  switch (key) {
    case 'live':
      return showLive;
    case 'briefs':
    case 'collections':
    case 'objectives':
    case 'features':
      return auth.isAuthorised('briefs', false) && !isFleet;
    case 'tags':
      return auth.isAuthorised('tags', false);
    case 'plans':
      return auth.isAuthorised('plans', false);
    case 'queries':
      return auth.isAuthorised('queries', false);
    case 'locations':
      return auth.isAuthorised('locations', false);
    case 'doubleCrews':
      return !isFleet && showDoubleCrews;
    // case 'unknownDriverTrips':
    //   return (
    //     (!!unknownDriverTripsGroup &&
    //       (groupNames || []).includes(unknownDriverTripsGroup)) ||
    //     !unknownDriverTripsGroup
    //   );
    default:
      return true;
  }
}

export const siteMap = isMobile
  ? [
      { key: 'home', label: 'Home', Icon: HomeIcon, path: '/' },
      {
        key: 'nearby',
        label: 'Nearby',
        Icon: NearbyIcon,
        path: '/nearby',
      },
      {
        key: 'individualDriving',
        label: 'Driving',
        Icon: PersonIcon,
        path: '/individual/individualDriving',
      },
    ]
  : [
      { key: 'home', label: 'Home', Icon: HomeIcon, path: '/' },
      { key: 'live', label: 'Live', Icon: LanguageIcon, path: '/live' },

      {
        key: 'retrospective',
        label: 'Retrospective',
        Icon: HistoryIcon,
        path: '/retrospective',
      },
      { key: 'briefs', label: 'Briefs', Icon: WorkIcon, path: '/briefs' },
      { key: 'plans', label: 'Plans', Icon: AssignmentIcon, path: '/plans' },
      { key: 'tags', label: 'Tags', Icon: MoreIcon, path: '/tags' },
      { key: 'queries', label: 'Queries', Icon: SearchIcon, path: '/queries' },
      {
        key: 'tasks',
        label: 'Tasks',
        Icon: AssignmentTurnedInIcon,
        path: '/tasks',
      },
      /* {
    key: 'collections',
    label: 'Collections',
    Icon: LibraryBooksIcon,
  }, */
      {
        key: 'features',
        label: 'Features',
        Icon: FormatShapesIcon,
        children: [
          {
            key: 'perimeters',
            label: 'Perimeters',
            Icon: CropSquareIcon,
            path: '/features/perimeters',
          },
          {
            key: 'paths',
            label: 'Paths',
            Icon: ShowChartIcon,
            path: '/features/paths',
          },
          {
            key: 'markers',
            label: 'Markers',
            Icon: PlaceIcon,
            path: '/features/markers',
          },
        ],
      },
      {
        key: 'objectives',
        label: 'Objectives',
        Icon: DataUsageIcon,
        children: [
          {
            key: 'patrols',
            label: 'Patrols',
            Icon: ThreeSixtyIcon,
            path: '/objectives/patrols',
          },
          {
            key: 'visits',
            label: 'Visits',
            Icon: BeenhereIcon,
            path: '/objectives/visits',
          },
          {
            key: 'exclusions',
            label: 'Exclusions',
            Icon: NotInterestedIcon,
            path: '/objectives/exclusions',
          },
        ],
      },
      {
        key: 'resources',
        label: 'Resources',
        Icon: GroupIcon,
        children: [
          {
            key: 'vehicles',
            label: 'Vehicles',
            Icon: DirectionsCarIcon,
            path: '/resources/vehicles',
          },
          {
            key: 'people',
            label: 'People',
            Icon: PersonIcon,
            path: '/resources/people',
          },
          {
            key: 'telematicsBoxes',
            label: 'Telematics Boxes',
            Icon: RouterIcon,
            path: '/resources/telematicsboxes',
          },
          {
            key: 'radios',
            label: 'Radios',
            Icon: RadioHandheldIcon,
            path: '/resources/radios',
          },
          {
            key: 'rfidCards',
            label: useDallasKeys ? 'Dallas Keys' : 'RFID Cards',
            Icon: useDallasKeys ? VpnKeyIcon : NfcIcon,
            path: `/resources/${useDallasKeys ? 'dallaskeys' : 'rfidcards'}`,
          },
          {
            key: 'posts',
            label: 'Posts',
            Icon: EventSeatIcon,
            path: '/resources/posts',
          },
        ],
      },
      {
        key: 'locations',
        label: 'Locations',
        Icon: LocationCityIcon,
        children: [
          {
            key: 'bases',
            label: `${baseType.label}s`,
            Icon: BusinessIcon,
            path: `/locations/${baseType.value}s`,
          },
          {
            key: 'workshops',
            label: 'Workshops',
            Icon: BuildIcon,
            path: '/locations/workshops',
          },
          {
            key: 'wards',
            label: 'Wards',
            Icon: TerrainIcon,
            path: '/locations/wards',
          },
        ],
      },
      {
        key: 'reports',
        label: 'Reports',
        Icon: BarChartIcon,
        children: [
          {
            key: 'vehicleReports',
            label: 'Vehicles',
            Icon: DirectionsCarIcon,
            children: [
              {
                key: 'dailyUtilisation',
                label: 'Daily Utilisation',
                Icon: EventIcon,
                path: '/reports/vehicles/dailyutilisation',
              },
              {
                key: 'hourlyUtilisation',
                label: 'Hourly Utilisation',
                Icon: ScheduleIcon,
                path: '/reports/vehicles/hourlyutilisation',
              },
              {
                key: 'hourlyInLocations',
                label: `Hourly in Location`,
                Icon: GarageIcon,
                path: '/reports/vehicles/hourlyinlocations',
              },
              {
                key: 'timelineInLocations',
                label: `Timeline in Location`,
                Icon: TimelineIcon,
                path: '/reports/vehicles/timelineinlocations',
              },
              {
                key: 'mileage',
                label: 'Mileage',
                Icon: CounterIcon,
                path: '/reports/vehicles/mileage',
              },
              {
                key: 'availability',
                label: 'Availability',
                Icon: EmojiTransportationIcon,
                path: '/reports/vehicles/availability',
              },
            ],
          },
          {
            key: 'peopleReports',
            label: 'People',
            Icon: PersonIcon,
            children: [
              {
                key: 'driverBehaviour',
                label: 'Driver Behaviour',
                Icon: StarIcon,
                path: '/reports/driving/behaviour',
              },
              {
                key: 'dailyActivity',
                label: 'Daily Activity',
                Icon: EventIcon,
                path: '/reports/people/dailyactivity',
              },
              {
                key: 'hourlyActivity',
                label: 'Hourly Activity',
                Icon: ScheduleIcon,
                path: '/reports/people/hourlyactivity',
              },
            ],
          },
          {
            key: 'telematicsBoxReports',
            label: 'Telematics Boxes',
            Icon: RouterIcon,
            children: [
              {
                key: 'lastContact',
                label: 'Last Contact',
                Icon: TimerIcon,
                path: '/reports/telematicsboxes/lastcontact',
              },
            ],
          },
          {
            key: 'audit',
            label: 'Audit',
            Icon: ChromeReaderModeIcon,
            path: '/reports/audit',
          },
        ],
      },
      {
        key: 'events',
        label: 'Events',
        Icon: NewReleasesIcon,
        children: [
          {
            key: 'vehicleEvents',
            label: 'Vehicles',
            Icon: DirectionsCarIcon,
            children: [
              {
                key: 'trips',
                label: 'Trips',
                Icon: CarTractionControlIcon,
                path: '/events/vehicles/trips',
              },
              {
                key: 'stops',
                label: 'Stops',
                Icon: CarBrakeParkingIcon,
                path: '/events/vehicles/stops',
              },
              {
                key: 'idles',
                label: 'Idles',
                Icon: SnoozeIcon,
                path: '/events/vehicles/idles',
              },
              {
                key: 'speedInfractions',
                label: 'Speed Infractions',
                Icon: SpeedometerIcon,
                path: '/events/vehicles/speedinfractions',
              },
              {
                key: 'accelerometerEvents',
                label: 'Accelerometer Events',
                Icon: AxisArrowIcon,
                path: '/events/vehicles/accelerometerevents',
              },
              {
                key: 'vehicleLocationVisits',
                label: 'Location Visits',
                Icon: CarSideIcon,
                path: '/events/vehicles/locationvisits',
              },
              {
                key: 'malfunctionIndicatorLight',
                label: 'Malfunction Indicator',
                Icon: AlertCircleIcon,
                path: '/events/vehicles/malfunctionindicatorlight',
              },
              {
                key: 'onBoardDiagnostics',
                label: 'On-Board Diagnostics',
                Icon: EngineOutlineIcon,
                path: '/events/vehicles/onboarddiagnostics',
              },
            ],
          },
          {
            key: 'peopleEvents',
            label: 'People',
            Icon: PersonIcon,
            children: [
              {
                key: 'trails',
                label: 'Trails',
                Icon: FiberManualRecordIcon,
                path: '/events/people/trails',
              },
              {
                key: 'personLocationVisits',
                label: 'Location Visits',
                Icon: DirectionsWalkIcon,
                path: '/events/people/locationvisits',
              },
              {
                key: 'doubleCrews',
                label: 'Double Crews',
                Icon: PeopleIcon,
                path: '/events/people/doublecrews',
              },
              {
                key: 'attendances',
                label: 'Attendances',
                Icon: AttendanceIcon,
                path: '/events/people/attendances',
              },
            ],
          },
          // {
          //   key: 'locationEvents',
          //   label: 'Locations',
          //   Icon: LocationCityIcon,
          //   children: [],
          // },
          {
            key: 'telematicsBoxEvents',
            label: 'Telematics Boxes',
            Icon: RouterIcon,
            children: [
              {
                key: 'outages',
                label: 'Outages',
                Icon: ExploreOffIcon,
                path: '/events/telematicsboxes/outages',
              },
            ],
          },
        ],
      },
    ];

export function isVisible(key, auth) {
  if (!auth || !auth.isAuthenticated()) {
    return false;
  }

  // const groupNames = auth.getGroupNames();

  if (isMobile) {
    return ['home', 'nearby', 'individualDriving'].includes(key);
  } else {
    switch (key) {
      case 'live':
        return showLive;
      case 'briefs':
      case 'collections':
      case 'features':
      case 'objectives':
        return auth.isAuthorised('briefs', false) && !isFleet;
      case 'tags':
        return auth.isAuthorised('tags', false);
      case 'tasks':
        return false;
      case 'resources':
        return auth.isAuthorised('resources', false);
      case 'locations':
        return auth.isAuthorised('locations', false);
      case 'wards':
        return !isFleet;
      case 'radios':
      case 'posts':
      case 'exclusions':
        return false;
      case 'reports':
        return auth.isAuthorised('vehicles', false);
      case 'peopleReports':
      case 'driverBehaviour':
        return true;
      case 'dailyActivity':
        return !isFleet;
      case 'peopleEvents':
        return !isFleet;
      case 'doubleCrews':
        return !isFleet && showDoubleCrews;
      // case 'unknownDriverTrips':
      //   return (
      //     (!!unknownDriverTripsGroup &&
      //       (groupNames || []).includes(unknownDriverTripsGroup)) ||
      //     !unknownDriverTripsGroup
      //   );
      case 'hourlyActivity':
        return false;
      default:
        return true;
    }
  }
}
