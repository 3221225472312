import React from 'react';
import { IconButton } from '@material-ui/core';
import { PlayArrow as PlayArrowIcon } from '@material-ui/icons';

import { useHistory } from 'react-router-dom';

export default function ReplayLink({ entry }) {
  const history = useHistory();

  const handleClick = (identifier) => (event) => {
    event.stopPropagation();

    history.push(`/replay/trips/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}
