import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { green, grey, orange, red } from '@material-ui/core/colors';
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
  Feedback as FeedbackIcon,
  PinDrop as PinDropIcon,
} from '@material-ui/icons';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { ConfirmationDialog } from '../dialogs';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardHeader: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
    flexGrow: 1,
  },
  openedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: red[500],
  },
  assignedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: orange[500],
  },
  attendedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: green[500],
  },
  closedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: grey[500],
  },
  timelineSubheader: {
    paddingLeft: theme.spacing(2),
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  cardContent: {
    padding: 0,
    width: '100%',
  },
  back: {
    marginLeft: -12,
  },
  remove: {
    color: red[500],
  },
}));

export default function IncidentCard({ feature, onDone, onRemove }) {
  const [removeOpen, setRemoveOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  function renderContent() {
    return (
      <div>
        <Toolbar>
          {isXs && (
            <IconButton onClick={onDone} className={classes.back}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <CardHeader
            avatar={
              <Badge
                badgeContent={
                  feature.properties.responseCategory &&
                  feature.properties.responseCategory.code
                    ? feature.properties.responseCategory.code
                    : feature.properties.grade || '?'
                }
                color="primary"
              >
                <Avatar
                  title={
                    feature.properties.responseCategory.name ||
                    `Grade ${feature.properties.grade}`
                  }
                  aria-label="Feature"
                  className={classes.avatar}
                >
                  <FeedbackIcon />
                </Avatar>
              </Badge>
            }
            title={feature.properties.description}
            subheader={feature.properties.number}
            className={classes.cardHeader}
          />
          {isXs && onRemove && (
            <IconButton
              className={classes.remove}
              onClick={() => setRemoveOpen(true)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Toolbar>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.timelineSubheader} type="subtitle1">
            Timeline
          </Typography>
          <Stepper orientation="vertical">
            <Step active>
              <StepLabel
                icon={
                  <Avatar className={classes.openedAvatar}>
                    <AddIcon />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {moment(feature.properties.openedTime).format(
                      'DD/MM/YYYY, HH:mm'
                    )}
                  </Typography>
                }
              >
                {<Typography>Opened</Typography>}
              </StepLabel>
              <StepContent>
                {feature.properties.type && (
                  <Typography variant="caption">
                    {feature.properties.type.code}
                  </Typography>
                )}
              </StepContent>
            </Step>
            {feature.properties.assignedTime && (
              <Step active>
                <StepLabel
                  icon={
                    <Avatar className={classes.assignedAvatar}>
                      <DoneIcon />
                    </Avatar>
                  }
                  optional={
                    <Typography variant="caption">
                      {moment(feature.properties.assignedTime).format(
                        'DD/MM/YYYY, HH:mm'
                      )}
                    </Typography>
                  }
                >
                  {<Typography>Assigned</Typography>}
                </StepLabel>
                <StepContent />
              </Step>
            )}
            {feature.properties.attendedTime && (
              <Step active>
                <StepLabel
                  icon={
                    <Avatar className={classes.attendedAvatar}>
                      <PinDropIcon />
                    </Avatar>
                  }
                  optional={
                    <Typography variant="caption">
                      {moment(feature.properties.attendedTime).format(
                        'DD/MM/YYYY, HH:mm'
                      )}
                    </Typography>
                  }
                >
                  {<Typography>Attended</Typography>}
                </StepLabel>
                <StepContent />
              </Step>
            )}
            {feature.properties.closedTime && (
              <Step active>
                <StepLabel
                  icon={
                    <Avatar className={classes.closedAvatar}>
                      <CloseIcon />
                    </Avatar>
                  }
                  optional={
                    <Typography variant="caption">
                      {moment(feature.properties.closedTime).format(
                        'DD/MM/YYYY, HH:mm'
                      )}
                    </Typography>
                  }
                >
                  {<Typography>Closed</Typography>}
                </StepLabel>
                <StepContent>
                  {!feature.properties.closingCodes
                    ? ''
                    : feature.properties.closingCodes.map((cc, index) => (
                        <Typography variant="caption" key={index}>
                          {cc.code}
                        </Typography>
                      ))}
                </StepContent>
              </Step>
            )}
          </Stepper>
        </CardContent>
      </div>
    );
  }

  return (
    feature &&
    (isXs ? (
      renderContent()
    ) : (
      <Card className={classes.card}>
        {renderContent()}
        <CardActions disableSpacing>
          <Button color="primary" onClick={onDone}>
            Done
          </Button>
          {onRemove && (
            <Fragment>
              <Button
                className={classes.remove}
                onClick={() => setRemoveOpen(true)}
              >
                Remove
              </Button>
              <ConfirmationDialog
                action="Remove"
                open={removeOpen}
                itemId={
                  feature.properties.title || feature.properties.identifier
                }
                onOk={onRemove}
                onCancel={() => setRemoveOpen(false)}
              />
            </Fragment>
          )}
        </CardActions>
      </Card>
    ))
  );
}
