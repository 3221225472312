import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export default function AutosuggestField({
  input: { value, onChange, ...input },
  meta,
  suggestions,
  disabled,
  styles = {},
  ...props
}) {
  function handleChange(event, suggestion) {
    onChange(suggestion ? suggestion.value : '');
  }

  return (
    <Autocomplete
      disableListWrap
      value={
        suggestions.find((suggestion) => suggestion.value === value) || null
      }
      disabled={disabled}
      onChange={handleChange}
      options={suggestions}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => {
        const option = suggestions.find((s) => s.value === value);

        return (
          <TextField
            {...params}
            {...props}
            helperText={meta.touched && (meta.error || meta.submitError)}
            error={meta.touched && meta.invalid}
            style={styles[option?.className]}
          />
        );
      }}
      renderOption={(option) => {
        return (
          <Typography style={styles[option?.className]}>
            {option.label}
          </Typography>
        );
      }}
    />
  );
}
