import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import avatarForItem from './avatarUtility';
import { areasToLabelAccessors, ItemRows, toLabelAccessors } from './ItemRows';

const { useReducedResourceInformation } = window.config;
// example object
// $type: "add"
// eventType: "ACCELEROMETERALERT"
// identifier: "ACCELEROMETERALERT-2018-05-17T13:50:35.000Z-HA0812"
// locations: Array(1)
// 0: {code: "OS_WMIDQNT", name: "West Mids RCC & Quinton Outstation", type: "Base"}
// people: Array(0)
// length: 0
// point:
// $reql_type$: "GEOMETRY"
// coordinates: (2) [-2.009744, 52.453684]
// type: "Point"
// searchString: "accelerometeralert+accelerometeralert-2018-05-17t13:50:35.000z-ha0812+os_wmidqnt+west mids rcc & quinton outstation+base++geometry++point+2018-05-17t13:50:35.000z+west midlands+region+ha0812+west mids rcc & quinton outstation+ha0812+bw17 ztf+traffic officer+359394057295853+add"
// tagChanged: true
// time: "2018-05-17T13:50:35.000Z"
// vehicle:
// areas: Array(1)
// 0: {name: "West Midlands", type: "region"}
// fleetNumber: "HA0812"
// homeStation: "West Mids RCC & Quinton Outstation"
// identificationNumber: "HA0812"
// registrationNumber: "BW17 ZTF"
// role: "Traffic Officer"
// telematicsBoxImei: "359394057295853"

function EventLiveItem({ item, classes }) {
  const sections = {
    details: [
      {
        label: 'Event time',
        value: moment(item.time).format('DD/MM/YYYY HH:mm:ss'),
      },
      { label: 'Type', value: item.eventType },
      ...toLabelAccessors(item.locations, 'type', 'name'),
    ],
    vehicle: [
      { label: 'Fleet Number', value: item.vehicle.fleetNumber },
      { label: 'Registration', value: item.vehicle.registrationNumber },
      ...(useReducedResourceInformation
        ? [{ label: 'Home Location', value: item.vehicle.homeStation }]
        : [
            { label: 'Role', value: item.vehicle.role },
            { label: 'Home Station', value: item.vehicle.homeStation },
            {
              label: 'Identification Number',
              value: item.identificationNumber,
            },
          ]),
      { label: 'IMEI', value: item.vehicle.telematicsBoxImei },
      ...areasToLabelAccessors(item.vehicle.areas),
    ],
  };

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>{`IR3 | Live | Events | ${item.identifier}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'events')}
        title={item.eventType}
        subheader={item.identifier}
      />
      <CardContent>
        {Object.keys(sections)
          .filter((key) => sections[key] && sections[key].length > 0)
          .map((key) => (
            <React.Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {_.startCase(key)}
              </Typography>
              <Table size="small" className={classes.table}>
                <TableBody>{ItemRows(sections[key], item)}</TableBody>
              </Table>
            </React.Fragment>
          ))}
      </CardContent>
    </Card>
  );

  // return (
  //   <React.Fragment>
  //     <div className={{ root: { flexGrow: 1 } }}>
  //       <Grid container style={{ padding: '8px' }}>
  //         <Grid item xs={12}>
  //           <h3>Event Details</h3>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <Typography className={classes.cardText}>
  //             Type: Accelerometer Alert
  //           </Typography>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <h3>Resource Details</h3>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <Typography className={classes.cardText}>
  //             Identification Number:{' '}
  //             {item.vehicle && item.vehicle.identificationNumber}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Registration Number:{' '}
  //             {item.vehicle && item.vehicle.registrationNumber}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Fleet Number: {item.vehicle && item.vehicle.fleetNumber}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Role: {item.vehicle && item.vehicle.role}
  //           </Typography>
  //           <Typography className={classes.cardText}>
  //             Home Station: {item.vehicle && item.vehicle.homeStation}
  //           </Typography>
  //           <Typography className={classes.cardText}>Locations: </Typography>
  //           {item.locations && item.locations.length > 0 && (
  //             <Grid item xs={12}>
  //               {item.locations.map((location, i) => (
  //                 <Typography
  //                   className={classes.cardText}
  //                   key={i}
  //                 >{`${location.name} (${location.type})`}</Typography>
  //               ))}
  //             </Grid>
  //           )}
  //         </Grid>
  //       </Grid>
  //     </div>
  //   </React.Fragment>
  // );
}

export default EventLiveItem;
