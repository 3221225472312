import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Fab,
  IconButton,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Autorenew as AutorenewIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import React, { Fragment, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';

import SourceFilters from './SourceFilters';
import TypeParameters from './TypeParameters';
import { ConfirmationDialog } from '../dialogs';
import { Field, SelectField, DebouncedTextField } from '../fields';
import { types, sources, compareLabels } from './constants';

const { isFleet } = window.config;

const useStyles = makeStyles((theme) => ({
  list: {
    overflow: 'auto',
  },
  cardContent: {
    paddingTop: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1, 0.5, 1, 1),
    overflow: 'visible',
  },
  hoveredCard: {
    margin: theme.spacing(1, 0.5, 1, 1),
    overflow: 'visible',
    backgroundColor: theme.palette.action.hover,
  },
  labelField: {
    marginRight: theme.spacing(1),
  },
  colorsField: {
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },
  typeField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 80,
  },
  sourceField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 236,
  },
  boundaryTypeField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 96,
  },
  boundarySubtypeField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 160,
  },
  drawButton: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  delete: {
    color: theme.palette.error.main,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  progress: {
    margin: theme.spacing(1),
  },
}));

const boundaryTypes = isFleet
  ? [
      { label: 'None', value: 'None' },
      { label: 'Custom', value: 'Custom' },
      { label: 'Location', value: 'Location' },
    ]
  : [
      { label: 'None', value: 'None' },
      { label: 'Custom', value: 'Custom' },
      { label: 'Location', value: 'Location' },
      { label: 'Objective', value: 'Objective' },
      { label: 'Perimeter', value: 'Perimeter' },
    ];

export default function LayerList({
  fields,
  onSelect,
  hiddenLayerIndexes,
  hoveredItemIndex,
  onVisibilityToggle,
  onRefresh,
  loadingLayers,
  boundaries,
  onBoundaryChange,
  clearValue,
  expandedLayerIndex,
  onExpanded,
  onDraw,
  filters,
}) {
  const [deleteLayer, setDeleteLayer] = useState(null);

  const classes = useStyles();
  const theme = useTheme();

  function handleAddLayer() {
    fields.push({
      label: '',
      type: 'shape',
      source: '',
      startTime: new Date(),
      endTime: new Date(),
      colors: [theme.palette.grey[500]],
      parameters: {},
    });
  }

  function handleDeleteLayer() {
    fields.remove(deleteLayer.index);

    setDeleteLayer(null);
  }

  function handleDeleteCancel() {
    setDeleteLayer(null);
  }

  function handleExpandClick(event) {
    const index = parseInt(event.currentTarget.value);

    if (expandedLayerIndex === index) {
      onExpanded(null);
    } else {
      onExpanded(index);
    }
  }

  function handleDeleteClick(event) {
    const index = parseInt(event.currentTarget.value);
    setDeleteLayer({ label: fields.value[index].label, index });
  }

  function handleItemsClick(event) {
    const index = parseInt(event.currentTarget.value);
    onSelect({ layerIndex: index });
  }

  function handleVisibilityClick(event) {
    const index = parseInt(event.currentTarget.value);
    onVisibilityToggle(index);
  }

  function handleRefreshClick(event) {
    const index = parseInt(event.currentTarget.value);
    onRefresh(index, fields.value[index]);
  }

  function handleDrawClick(event) {
    const index = parseInt(event.currentTarget.value);
    onDraw(index);
  }

  const handleTypeChange = (name) => () => {
    clearValue(`${name}.source`);
  };

  const handleSourceChange = (name) => () => {
    clearValue(`${name}.parameters`);
    // clearValue(`${name}.filters`);
    clearValue(`${name}.clientFilters`);
    clearValue(`${name}.searchText`);
    clearValue(`${name}.sort`);
  };

  const handleBoundaryTypeChange = (name) => () => {
    clearValue(`${name}.boundarySubtype`);
    clearValue(`${name}.boundaryIdentifier`);
    clearValue(`${name}.boundaryGeometry`);
  };

  const handleBoundarySubtypeChange = (name) => () => {
    clearValue(`${name}.boundaryIdentifier`);
    clearValue(`${name}.boundaryGeometry`);
  };

  function handleDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    if (expandedLayerIndex === result.source.index) {
      onExpanded(result.destination.index);
    } else {
      if (
        expandedLayerIndex &&
        expandedLayerIndex > result.source.index &&
        expandedLayerIndex <= result.destination.index
      ) {
        onExpanded(expandedLayerIndex - 1);
      }

      if (
        expandedLayerIndex &&
        expandedLayerIndex >= result.destination.index &&
        expandedLayerIndex < result.source.index
      ) {
        onExpanded(expandedLayerIndex + 1);
      }
    }

    fields.move(result.source.index, result.destination.index);
  }

  const handleBoundaryChange = (index) => (event) => {
    const layer = fields.value[index];

    onBoundaryChange(index, {
      ...layer,
      boundaryIdentifier: event.target.value,
    });
  };

  return (
    <Fragment>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className={classes.list}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields.map((name, index) => {
                const layer = fields.value[index];

                const background =
                  layer.colors.length > 1
                    ? `linear-gradient(${layer.colors.join()})`
                    : layer.colors[0] || theme.palette.grey[500];

                const color = theme.palette.getContrastText(
                  layer.colors[Math.floor(layer.colors.length / 2)] ||
                    theme.palette.grey[500]
                );

                return (
                  <Draggable
                    key={name}
                    draggableId={name}
                    index={index}
                    isDragDisabled={expandedLayerIndex === index}
                  >
                    {(provided, snapshot) => (
                      <Card
                        className={
                          hoveredItemIndex.layerIndex === index
                            ? classes.hoveredCard
                            : classes.card
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CardHeader
                          avatar={
                            <Avatar
                              style={{
                                background,
                                color,
                              }}
                              title={types[layer.type].label}
                            >
                              {types[layer.type].icon}
                            </Avatar>
                          }
                          action={
                            <IconButton
                              className={clsx(classes.expand, {
                                [classes.expandOpen]:
                                  expandedLayerIndex === index,
                              })}
                              onClick={handleExpandClick}
                              aria-expanded={expandedLayerIndex === index}
                              aria-label="Settings"
                              value={index}
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          }
                          title={layer.label}
                          subheader={
                            layer.source &&
                            (
                              sources[layer.type].find(
                                (source) => source.value === layer.source
                              ) || { label: 'Unknown' }
                            ).label
                          }
                        />
                        <Collapse
                          in={expandedLayerIndex === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <CardContent className={classes.cardContent}>
                            <Field
                              fullWidth
                              className={classes.labelField}
                              label="Label"
                              name={`${name}.label`}
                              component={DebouncedTextField}
                              // component={TextField}
                            />
                            <Field
                              label="Type"
                              className={classes.typeField}
                              name={`${name}.type`}
                              values={Object.values(types)
                                .map(({ label, value }) => ({
                                  label,
                                  value,
                                }))
                                .sort(compareLabels)}
                              component={SelectField}
                              onChange={handleTypeChange(name)}
                            />
                            <Field
                              label="Source"
                              className={classes.sourceField}
                              name={`${name}.source`}
                              values={sources[layer.type].sort(compareLabels)}
                              component={SelectField}
                              onChange={handleSourceChange(name)}
                            />
                            <Divider className={classes.divider} />
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              Boundary
                            </Typography>
                            <Field
                              label="Type"
                              className={classes.boundaryTypeField}
                              name={`${name}.boundaryType`}
                              values={boundaryTypes}
                              component={SelectField}
                              onChange={handleBoundaryTypeChange(name)}
                            />
                            {Object.keys(boundaries).includes(
                              layer.boundaryType
                            ) && (
                              <Fragment>
                                <Field
                                  label="Subtype"
                                  className={classes.boundarySubtypeField}
                                  name={`${name}.boundarySubtype`}
                                  values={Object.keys(
                                    boundaries[layer.boundaryType]
                                  ).sort()}
                                  component={SelectField}
                                  onChange={handleBoundarySubtypeChange(name)}
                                />
                                {layer.boundarySubtype && (
                                  <Field
                                    fullWidth
                                    label="Name"
                                    className={classes.colorsField}
                                    name={`${name}.boundaryIdentifier`}
                                    values={(
                                      boundaries[layer.boundaryType]?.[
                                        layer.boundarySubtype
                                      ] || []
                                    ).sort(compareLabels)}
                                    component={SelectField}
                                    onChange={handleBoundaryChange(index)}
                                  />
                                )}
                              </Fragment>
                            )}
                            {layer.boundaryType === 'Custom' && (
                              <IconButton
                                className={classes.drawButton}
                                title="Draw"
                                value={index}
                                onClick={handleDrawClick}
                              >
                                <CreateIcon
                                  color={
                                    Boolean(layer.boundaryGeometry)
                                      ? 'primary'
                                      : 'inherit'
                                  }
                                />
                              </IconButton>
                            )}
                            <Divider className={classes.divider} />
                            <TypeParameters
                              type={layer.type}
                              name={name}
                              value={layer}
                            />
                            {layer.source &&
                              sources[layer.type].find(
                                (source) => source.value === layer.source
                              ) && (
                                <Fragment>
                                  <Divider className={classes.divider} />
                                  <SourceFilters
                                    type={layer.source}
                                    name={`${name}.filters`}
                                    filters={filters}
                                  />
                                </Fragment>
                              )}
                          </CardContent>
                        </Collapse>
                        <CardActions disableSpacing>
                          <IconButton
                            aria-label="Fetch"
                            title={
                              loadingLayers.includes(index) ? 'Cancel' : 'Fetch'
                            }
                            onClick={handleRefreshClick}
                            value={index}
                          >
                            {loadingLayers.includes(index) ? (
                              <CloseIcon color="error" />
                            ) : (
                              <AutorenewIcon />
                            )}
                          </IconButton>
                          <IconButton
                            aria-label="Delete"
                            title="Delete"
                            className={classes.delete}
                            onClick={handleDeleteClick}
                            value={index}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {layer.featureCollection && (
                            <Button onClick={handleItemsClick} value={index}>
                              {`${layer.featureCollection.features.length} items`}
                            </Button>
                          )}
                          {loadingLayers.includes(index) && (
                            <CircularProgress
                              className={classes.progress}
                              size={16}
                              thickness={6}
                            />
                          )}
                          <div style={{ flex: 1 }} />
                          <IconButton
                            aria-label="Visibility"
                            title={
                              hiddenLayerIndexes.includes(index)
                                ? 'Show Layer'
                                : 'Hide Layer'
                            }
                            onClick={handleVisibilityClick}
                            value={index}
                          >
                            {hiddenLayerIndexes.includes(index) ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </CardActions>
                      </Card>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Fab
        color="secondary"
        aria-label="add"
        className={classes.fab}
        onClick={handleAddLayer}
      >
        <AddIcon />
      </Fab>
      <ConfirmationDialog
        action="Delete"
        open={deleteLayer !== null}
        itemId={(deleteLayer && deleteLayer.label) || 'Untitled'}
        onOk={handleDeleteLayer}
        onCancel={handleDeleteCancel}
      />
    </Fragment>
  );
}
