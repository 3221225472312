import {
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE,
  FETCH_DRIVING_SCORES,
} from '../../actions';
import { useAuth } from 'components/Auth';
import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Avatar,
  Card,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import _ from 'lodash';
import { startOfDay, startOfWeek, startOfMonth, startOfYear } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  title: {
    margin: theme.spacing(1),
  },
  scorecard: {
    margin: theme.spacing(2),
  },
  chart: {
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  legend: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendAvatar: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(0.5),
  },
  scores: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'baseline',
    padding: theme.spacing(1),
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: theme.spacing(1),
  },
  select: {
    width: 120,
  },
}));

export default function IndividualSummary() {
  const drivingScores = useSelector((state) => state.reports.drivingScores);
  const driverTrips = useSelector((state) => state.reports.driverTrips);

  const [startTime, setStartTime] = useState(startOfDay(new Date()));
  const [timeRange, setTimeRange] = useState('day');

  const dispatch = useDispatch();
  const auth = useAuth();
  const classes = useStyles();

  const theme = useTheme();

  useEffect(() => {
    const { code } = auth.getProfile();

    const endTime = new Date();

    dispatch({
      type: FETCH_DRIVER_TRIPS_BY_DRIVER_CODE,
      payload: {
        startTime,
        endTime,
        driverCode: code,
      },
    });

    dispatch({
      type: FETCH_DRIVING_SCORES,
      payload: {
        startTime,
        endTime,
        driverCode: code,
        tripsOnly: false,
        // emergencyEquipmentUsed,
        // collarNumber: selectedDriver.collarNumber,
        filter: {
          code: [],
          name: [],
          collarNumber: [],
          role: [],
          areas: {},
        },
        // addDayToEndTime: false,
      },
    });
  }, [dispatch, auth, startTime]);

  // the result is in a bit of a weird format so I'll just pull out the relevant bits
  const { averageScore = 0, highestScore = 0, lowestScore = 0 } =
    drivingScores?.data?.statsAll?.totals || {};

  let classifications = {};
  driverTrips.forEach(
    ({ classification = 'Unclassified', distanceKilometres = 0 }) =>
      (classifications[classification] =
        (classifications[classification] || 0) + distanceKilometres * 0.621371)
  );

  const colours = Object.values(theme.palette.primary);
  const pieData = Object.entries(classifications).map(
    ([name, value], index) => ({
      name,
      value: _.round(value, 1),
      colour: colours[index % colours.length],
    })
  );

  function handleTimeChange(e) {
    const range = e.target.value;
    setTimeRange(range);

    switch (range) {
      case 'all':
        setStartTime(new Date(1900, 0, 1));
        break;
      case 'day':
        setStartTime(startOfDay(new Date()));
        break;
      case 'week':
        setStartTime(startOfWeek(new Date()));
        break;
      case 'month':
        setStartTime(startOfMonth(new Date()));
        break;
      case 'year':
        setStartTime(startOfYear(new Date()));
        break;
      default:
        break;
    }
  }

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.selectContainer}>
          <Select
            fullWidth
            onChange={handleTimeChange}
            value={timeRange}
            className={classes.select}
          >
            <MenuItem value={'day'}>Today</MenuItem>
            <MenuItem value={'week'}>This week</MenuItem>
            <MenuItem value={'month'}>This month</MenuItem>
            <MenuItem value={'year'}>This year</MenuItem>
            <MenuItem value={'all'}>All time</MenuItem>
          </Select>
        </div>
        <Typography className={classes.title} variant="subtitle2">
          Score
        </Typography>

        <Card className={classes.scorecard}>
          <div className={classes.scores}>
            <Typography variant="h5">{`${_.round(
              averageScore === 0 ? 0 : lowestScore,
              1
            )}%`}</Typography>
            <Typography variant="h4">{`${_.round(
              averageScore,
              1
            )}%`}</Typography>
            <Typography variant="h5">{`${_.round(
              highestScore,
              1
            )}%`}</Typography>
          </div>
          <div className={classes.scores}>
            <Typography variant="caption">{`lowest`}</Typography>
            <Typography variant="caption">{`average`}</Typography>
            <Typography variant="caption">{`highest`}</Typography>
          </div>
        </Card>
        <Typography className={classes.title} variant="subtitle2">
          Mileage
        </Typography>

        <Card className={classes.chart}>
          {/* <CardHeader title="Mileage" /> */}

          <div className={classes.legend}>
            {pieData.map(({ name, value, colour }, index) => (
              <div key={index} className={classes.legendItem}>
                <Avatar
                  className={classes.legendAvatar}
                  style={{ backgroundColor: colour }}
                >
                  <Fragment />
                </Avatar>
                <Typography variant="caption">
                  {`${name}: ${value} miles`}
                </Typography>
              </div>
            ))}
          </div>
          <ResponsiveContainer height={200}>
            <PieChart>
              <Pie
                dataKey="value"
                // startAngle={0}
                // endAngle={0}
                data={pieData}
                // cx={100}
                // cy={100}
                outerRadius={80}
                fill="#8884d8"
                isAnimationActive={false}
                label
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.colour} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </div>
    </div>
  );
}
