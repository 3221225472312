import {
  Avatar,
  Button,
  Collapse,
  Fab,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, Route, useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { FETCH_PEOPLE } from '../../actions';
import {
  downloadCSV,
  downloadJSON,
  getPeople,
  getPeopleAndHeaders,
} from '../../apis/utilities';
import { useAuth } from '../Auth';
import Container from '../Container';
import { SearchBox } from '../controls';
import Person from './Person';
import ReducedPerson from './ReducedPerson';

const { useReducedResourceInformation, useDallasKeys, isFleet } = window.config;

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '100%',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  list: {
    overflow: 'none',
    flex: 1,
  },
  toolbar: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(1),
    },
  },
  searchBox: {
    width: '100%',
    padding: theme.spacing(1, 0, 1, 1),
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  settingsPanel: {
    padding: theme.spacing(0, 1, 1, 1),
  },
  sortAsc: {
    transform: 'rotate(0deg)',
    // marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  sortDesc: {
    transform: 'rotate(180deg)',
  },
  sortTextField: {
    paddingBottom: theme.spacing(1),
  },
  exportButton: {
    margin: theme.spacing(1, 1, 1, 0),
  },
}));

export default function PersonList() {
  const { id, report } = useParams();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.people.people, _.isEqual);
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [sortBy, setSortBy] = useState(
    useReducedResourceInformation ? 'code' : 'name'
  );
  const [sortDesc, setSortDesc] = useState(false);
  const [filter, setFilter] = useState('all');
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const auth = useAuth();
  const editable = auth.isAuthorised('vehicles', true);

  const sortOptions = [
    ...(useReducedResourceInformation
      ? [{ label: 'Staff ID', value: 'code' }]
      : [
          { label: 'Payroll Number', value: 'code' },
          { label: 'Name', value: 'name' },
          { label: 'Collar Number', value: 'collarNumber' },
        ]),
    ...(isFleet ? [] : [{ label: 'Last Poll Time', value: 'lastPollTime' }]),
  ];

  const filters = [
    { label: 'All', value: 'all' },
    { label: `No ${useDallasKeys ? 'Dallas Key' : 'RFID'}`, value: 'noRfid' },
    ...(useReducedResourceInformation
      ? []
      : [{ label: 'No Radio', value: 'noRadio' }]),
  ];

  useEffect(() => {
    dispatch({
      type: FETCH_PEOPLE,
    });
  }, [dispatch]);

  useEffect(() => {
    const filteredList = list
      .filter((item) => {
        switch (filter) {
          case 'all':
            return true;
          case 'noRfid':
            return (item.rfidCards || []).length === 0;
          case 'noRadio':
            return !item.radioSsi;
          default:
            return true;
        }
      })
      .filter(
        (item) =>
          item.searchString.indexOf(searchText.toLowerCase()) > -1 ||
          searchText === ''
      )
      .sort(
        (a, b) =>
          (sortBy === 'lastPollTime'
            ? new Date(a.lastPollTime || '1900-1-1') -
              new Date(b.lastPollTime || '1900-1-1')
            : sortBy === 'name'
            ? `${a.surname}, ${a.forenames}`.localeCompare(
                `${b.surname}, ${b.forenames}`
              )
            : (a[sortBy] || '').localeCompare(b[sortBy] || '')) *
          (sortDesc ? -1 : 1)
      );

    setFilteredList(filteredList);
  }, [list, searchText, setFilteredList, filter, sortBy, sortDesc]);

  function Row({ data, index, style }) {
    const item = data[index];

    return (
      <ListItem
        dense
        button
        key={index}
        style={style}
        component={NavLink}
        to={`/resources/people/${encodeURIComponent(item.code)}`}
        activeClassName={classes.active}
      >
        <ListItemAvatar>
          <Avatar src={item.picture}>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        {useReducedResourceInformation ? (
          <ListItemText primary={item.code} />
        ) : (
          <ListItemText
            primary={`${item.forenames} ${item.surname}`}
            secondary={
              sortBy === 'lastPollTime'
                ? item.lastPollTime
                  ? moment(item.lastPollTime).format('DD/MM/YYYY, HH:mm:ss')
                  : ''
                : item.collarNumber
            }
            primaryTypographyProps={{ noWrap: true }}
          />
        )}
      </ListItem>
    );
  }

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }

  function handleFilterChange(event) {
    setFilter(event.target.value);
  }

  async function handleCsvClick() {
    const data = await getPeopleAndHeaders();

    downloadCSV(data.people, 'people.csv', data.headers);
  }

  async function handleJsonClick() {
    const data = await getPeople();

    downloadJSON(data, 'people.json');
  }

  return (
    <Container title="People" showBack={!!report}>
      <div className={classes.page}>
        <Helmet>
          <title>IR3 | People</title>
        </Helmet>
        {(!isXs || !id) && (
          <div className={classes.sideBar}>
            <div className={classes.searchBar}>
              <div className={classes.toolbar}>
                <SearchBox
                  value={searchText}
                  onChange={handleSearchChange}
                  className={classes.searchBox}
                  endAdornment={
                    <InputAdornment position="start">
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.count}
                      >
                        {`${filteredList.length}/${list.length}`}
                      </Typography>
                    </InputAdornment>
                  }
                />
                <IconButton
                  title={showSettings ? 'Hide settings' : 'Show settings'}
                  onClick={handleSettingsToggle}
                >
                  <SettingsIcon color={showSettings ? 'primary' : 'inherit'} />
                </IconButton>
              </div>
              <Collapse in={showSettings} unmountOnExit>
                <div className={classes.settingsPanel}>
                  <TextField
                    className={classes.sortTextField}
                    select
                    fullWidth
                    label="Filter"
                    value={filter}
                    onChange={handleFilterChange}
                  >
                    {filters.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.sortTextField}
                    select
                    fullWidth
                    label="Sort by"
                    value={sortBy}
                    onChange={handleSortByChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            title={sortDesc ? 'Descending' : 'Ascending'}
                            className={clsx(classes.sortAsc, {
                              [classes.sortDesc]: sortDesc,
                            })}
                            onClick={handleSortToggle}
                          >
                            <ArrowUpwardIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    {sortOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    Export
                  </Typography>
                  <div>
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      className={classes.exportButton}
                      onClick={handleCsvClick}
                    >
                      CSV
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      disableElevation
                      className={classes.exportButton}
                      onClick={handleJsonClick}
                    >
                      JSON
                    </Button>
                  </div>
                </div>
              </Collapse>
            </div>
            {filteredList.length > 0 && (
              <div className={classes.list}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      width={width}
                      height={height}
                      overscanCount={10}
                      itemData={filteredList}
                      itemCount={filteredList.length}
                      itemSize={56}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            )}
            {!report && editable && (
              <Fab
                color="secondary"
                aria-label="add"
                className={classes.fab}
                component={Link}
                to="/resources/people/new"
              >
                <AddIcon />
              </Fab>
            )}
          </div>
        )}
        {(!isXs || id) && (
          <div className={classes.main}>
            <Route exact path="/resources/people/:id">
              {useReducedResourceInformation ? <ReducedPerson /> : <Person />}
            </Route>
          </div>
        )}
      </div>
    </Container>
  );
}
