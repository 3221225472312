import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Table,
  TableBody,
  Typography,
  IconButton,
} from '@material-ui/core';
import { LocationSearching as FollowIcon } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import avatarForItem from './avatarUtility';
import { areasToLabelAccessors, ItemRows } from './ItemRows';
import { FETCH_LIVE_LOCATION } from '../../../actions/types';
import { usePrevious } from '../../../hooks';
import { TagControl } from '../../controls';

// example object
// $type: "add"
// areas: Array(0)
// boundary:
// $reql_type$: "GEOMETRY"
// coordinates: [Array(6)]
// type: "Polygon"
// code: "Braunstone"
// subtype: ""
// name: "Braunstone"
// searchString: "+[object object]+braunstone++braunstone+police station+add+true"
// tagChanged: true
// type: "Police Station"

function LocationLiveItem({
  item,
  classes,
  onFollowToggle,
  followedIdsByType,
}) {
  const hideFollow = !item.boundary;
  const locationRecord = useSelector(
    (state) => state.live.locationRecordsById[item.id]
  );

  const dispatch = useDispatch();
  const prevId = usePrevious(item.id);
  useEffect(() => {
    if (prevId !== item.id && !locationRecord) {
      dispatch({
        type: FETCH_LIVE_LOCATION,
        payload: item.id,
      });
    }
  }, [dispatch, item.id, prevId, locationRecord]);

  const rowItems = [
    { label: 'Type', value: item.type },
    { label: 'Subtype', value: item.subtype },
    ...areasToLabelAccessors(item.areas),
  ];

  const type = 'locations';
  const following = followedIdsByType?.[type]?.[item.id];

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>{`IR3 | Live | Locations | ${item.name}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'locations')}
        title={item.name}
        subheader={item.code}
        action={
          !hideFollow && (
            <React.Fragment>
              <IconButton
                aria-label="Toggle follow"
                onClick={() => onFollowToggle(type, item.id)}
                title="Toggle follow"
              >
                <FollowIcon
                  fontSize="small"
                  color={following ? 'primary' : 'inherit'}
                />
              </IconButton>
            </React.Fragment>
          )
        }
      />
      {locationRecord && locationRecord.picture && (
        <CardMedia style={{ height: 420 }} image={locationRecord.picture} />
      )}

      <CardContent>
        <TagControl item={item} type={'locations'} />
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small" className={classes.table}>
          <TableBody>{ItemRows(rowItems, item)}</TableBody>
        </Table>
      </CardContent>
    </Card>
  );

  /*return (
    <React.Fragment>
      <div className={{ root: { flexGrow: 1 } }}>
        <Grid container style={{ padding: '8px' }}>
          <Grid item xs={12}>
            <h4>Resource Status</h4>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.cardText}>
              Code: {item.code}
            </Typography>
            <Typography className={classes.cardText}>
              Type: {item.type}
            </Typography>
            <Typography className={classes.cardText}>
              Subype: {item.subtype}
            </Typography>
            <Typography className={classes.cardText}>
              District: {item.district}
            </Typography>
            <Typography className={classes.cardText}>Areas: </Typography>
            <Grid style={{ paddingLeft: '8px' }}>
              {item.areas &&
                item.areas.length > 0 &&
                item.areas.map((area, i) => (
                  <Typography className={classes.cardText} key={i}>
                    {area.name}
                  </Typography>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );*/
}

export default LocationLiveItem;
