import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Popover,
  makeStyles,
} from '@material-ui/core';
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../Auth';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(2),
  },
  avatar: {
    width: 36,
    height: 36,
    fontSize: 16,
    backgroundColor: theme.palette.primary.dark,
  },
  avatarButton: {
    padding: 0,
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  logout: {
    marginLeft: theme.spacing(1),
  },
  name: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const {
  autoLogin,
  useReducedResourceInformation,
  showAdGroups,
} = window.config;

export default function LoginAvatar() {
  const history = useHistory();
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const profile = auth.getProfile();
  const open = Boolean(anchorEl);
  const id = open ? 'login-popover' : undefined;

  function handleAvatarClick(event) {
    if (auth.isAuthenticated()) {
      setAnchorEl(event.currentTarget);
    } else {
      auth.login();
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleProfileClick() {
    history.push(`/resources/people/${profile.code}`);
    handleClose();
  }

  function handleLogoutClick() {
    auth.logout();
    handleClose();
  }

  return (
    <Fragment>
      <IconButton
        className={classes.avatarButton}
        aria-describedby={id}
        onClick={handleAvatarClick}
      >
        <Avatar
          className={classes.avatar}
          src={auth.isAuthenticated() ? profile.picture : undefined}
        >
          {auth.isAuthenticated() ? (
            `${profile.forenames ? profile.forenames[0] : ''}${
              profile.surname ? profile.surname[0] : ''
            }` || <AccountCircleIcon color="secondary" />
          ) : (
            <AccountCircleIcon />
          )}
        </Avatar>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.section}>
          <Typography variant="caption">
            Logged in as{' '}
            <span style={{ fontWeight: 'bold' }}>{auth.getUsername()}</span>
          </Typography>
        </div>
        <Divider />
        {profile.code && (
          <Fragment>
            <div className={classes.section}>
              {useReducedResourceInformation ? (
                <Typography variant="subtitle2">{`${profile.forenames} ${profile.surname}`}</Typography>
              ) : (
                <div className={classes.name}>
                  <Typography variant="subtitle2">{`${profile.forenames} ${profile.surname}`}</Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {profile.collarNumber}
                  </Typography>
                </div>
              )}
              <Table size="small">
                <TableBody>
                  {!useReducedResourceInformation && (
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption" color="textSecondary">
                          Rank
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {(profile.rank || {}).name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {!useReducedResourceInformation && (
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption" color="textSecondary">
                          Role
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {profile.role}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {(profile.areas || []).map((area, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="caption" color="textSecondary">
                          {_.startCase(area.type)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">{area.name}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <Divider />
          </Fragment>
        )}
        <div className={classes.section}>
          <Typography
            className={classes.header}
            variant="subtitle2"
            color="textSecondary"
          >
            Authorisation Groups
          </Typography>
          {auth.getGroupNames().map((name) => (
            <div key={name}>
              <Typography variant="caption" color="textSecondary">
                {name}
              </Typography>
            </div>
          ))}
        </div>
        {showAdGroups && (
          <Fragment>
            <Divider />
            <div className={classes.section}>
              <Typography
                className={classes.header}
                variant="subtitle2"
                color="textSecondary"
              >
                Active Directory Groups
              </Typography>
              {auth.getDirectoryGroupIdentifiers().map((identifier) => (
                <div key={identifier}>
                  <Typography variant="caption" color="textSecondary">
                    {identifier}
                  </Typography>
                </div>
              ))}
            </div>
          </Fragment>
        )}

        <div className={classes.buttonSection}>
          {profile.code && (
            <Button
              size="small"
              variant="outlined"
              onClick={handleProfileClick}
            >
              Profile
            </Button>
          )}
          {!autoLogin && (
            <Button
              size="small"
              variant="outlined"
              onClick={handleLogoutClick}
              className={classes.logout}
            >
              Logout
            </Button>
          )}
        </div>
      </Popover>
    </Fragment>
  );
}
