import {
  FETCH_OBJECTIVES,
  FETCH_OBJECTIVES_SUCCESS,
  FETCH_OBJECTIVES_FAILURE,
  FETCH_OBJECTIVE,
  FETCH_OBJECTIVE_SUCCESS,
  FETCH_OBJECTIVE_FAILURE,
  CREATE_OBJECTIVE,
  CREATE_OBJECTIVE_SUCCESS,
  CREATE_OBJECTIVE_FAILURE,
  UPDATE_OBJECTIVE,
  UPDATE_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE_FAILURE,
  DELETE_OBJECTIVE,
  DELETE_OBJECTIVE_SUCCESS,
  DELETE_OBJECTIVE_FAILURE,
  FETCH_OBJECTIVE_ATTENDANCES,
  FETCH_OBJECTIVE_ATTENDANCES_SUCCESS,
  FETCH_OBJECTIVE_ATTENDANCES_FAILURE,
  FETCH_OBJECTIVE_ATTENDANCES_CANCELLED,
  UPDATE_OBJECTIVE_ATTENDANCES_FILTER,
} from '../actions';
import moment from 'moment';

const { rowsPerPageOptions } = window.config;

const INITIAL_STATE = {
  objectives: [],
  objective: null,
  attendances: [],
  objectiveAttendances: {
    filter: {
      startTime: moment().startOf('day').subtract(2, 'days').toDate(),
      endTime: moment().startOf('day').toDate(),
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'collarNumber',
      fields: {
        code: null,
        name: null,
        collarNumber: null,
        role: null,
      },
    },
    isLoading: false,
    error: null,
  },
};

export default function objectivesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_OBJECTIVES:
      return { ...state, isLoading: true, error: null };
    case FETCH_OBJECTIVES_SUCCESS:
      return {
        ...state,
        objectives: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_OBJECTIVES_FAILURE:
      return {
        ...state,
        objectives: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_OBJECTIVE:
      return { ...state, isLoading: true, error: null };
    case FETCH_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objective: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_OBJECTIVE_FAILURE:
      return {
        ...state,
        objective: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_OBJECTIVE:
      return { ...state, isLoading: true, error: null };
    case UPDATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objective: action.payload,
        // TODO:Why have I broken it out into individual fields?
        objectives: state.objectives.map((objective) =>
          objective.identifier === action.payload.identifier
            ? {
                identifier: action.payload.identifier,
                title: action.payload.title,
                type: action.payload.type,
                startTime: action.payload.startTime,
                endTime: action.payload.endTime,
                areas: action.payload.areas,
                wards: action.payload.wards,
                created: action.payload.created,
              }
            : objective
        ),
        isLoading: false,
        error: null,
      };
    case UPDATE_OBJECTIVE_FAILURE:
      return {
        ...state,
        objective: null,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_OBJECTIVE:
      return { ...state, isLoading: true, error: null };
    case CREATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objective: action.payload,
        objectives: state.objectives.concat({
          identifier: action.payload.identifier,
          title: action.payload.title,
          type: action.payload.type,
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          areas: action.payload.areas,
          wards: action.payload.wards,
          created: action.payload.created,
        }),
        isLoading: false,
        error: null,
      };
    case CREATE_OBJECTIVE_FAILURE:
      return {
        ...state,
        objective: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_OBJECTIVE:
      return { ...state, isLoading: true, error: null };
    case DELETE_OBJECTIVE_SUCCESS:
      const index = state.objectives.findIndex(
        (objective) => objective.identifier === action.payload
      );

      return {
        ...state,
        objective: null,
        objectives: state.objectives
          .slice(0, index)
          .concat(state.objectives.slice(index + 1)),
        isLoading: false,
        error: null,
      };
    case DELETE_OBJECTIVE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_OBJECTIVE_ATTENDANCES:
      return { ...state, isLoading: true, error: null };
    case FETCH_OBJECTIVE_ATTENDANCES_SUCCESS:
      return {
        ...state,
        objectiveAttendances: {
          ...state.objectiveAttendances,
          [action.payload.id]: action.payload.attendances,
        },
        isLoading: false,
        error: null,
      };
    case FETCH_OBJECTIVE_ATTENDANCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_OBJECTIVE_ATTENDANCES_CANCELLED:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_OBJECTIVE_ATTENDANCES_FILTER:
      return {
        ...state,
        objectiveAttendances: {
          ...state.objectiveAttendances,
          filter: action.payload,
        },
      };
    default:
      return state;
  }
}
