import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  PlayArrow as PlayArrowIcon,
  PinDrop as PinDropIcon,
} from '@material-ui/icons';
import { Helmet } from 'react-helmet-async';
import { green, grey, orange, red } from '@material-ui/core/colors';
import moment from 'moment';
import { RetrospectiveTypeIcon } from '../../../data/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0.5, 1, 1),
  },
  openedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: red[500],
  },
  assignedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: orange[500],
  },
  attendedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: green[500],
  },
  closedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: grey[500],
  },
  timelineIcon: {
    fontSize: 16,
  },
}));

export default function Incident({
  color,
  background,
  description,
  number,
  responseCategory,
  grade,
  openedTime,
  type,
  assignedTime,
  attendedTime,
  closedTime,
  closingCodes,
}) {
  const history = useHistory();
  const classes = useStyles();

  function handleViewClick() {
    history.push(`/replay/incidents/${number}`);
  }

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>IR3 | Incident | {description}</title>
      </Helmet>
      <CardHeader
        avatar={
          <Badge
            badgeContent={
              responseCategory && responseCategory.code
                ? responseCategory.code
                : grade || '?'
            }
            color="primary"
          >
            <Avatar
              title={responseCategory.name || `Grade ${grade}`}
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="incidents" />
            </Avatar>
          </Badge>
        }
        title={description}
        subheader={number}
        action={
          <IconButton
            aria-label="replay"
            title="View Replay"
            onClick={handleViewClick}
          >
            <PlayArrowIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical">
          <Step active>
            <StepLabel
              icon={
                <Avatar className={classes.openedAvatar}>
                  <AddIcon className={classes.timelineIcon} />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {moment(openedTime).format('DD/MM/YYYY, HH:mm:ss')}
                </Typography>
              }
            >
              Opened
            </StepLabel>
            <StepContent>
              {type && <Typography variant="caption">{type.code}</Typography>}
            </StepContent>
          </Step>
          {assignedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar className={classes.assignedAvatar}>
                    <DoneIcon className={classes.timelineIcon} />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {moment(assignedTime).format('DD/MM/YYYY, HH:mm:ss')}
                  </Typography>
                }
              >
                Assigned
              </StepLabel>
              <StepContent />
            </Step>
          )}
          {attendedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar className={classes.attendedAvatar}>
                    <PinDropIcon className={classes.timelineIcon} />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {moment(attendedTime).format('DD/MM/YYYY, HH:mm:ss')}
                  </Typography>
                }
              >
                Attended
              </StepLabel>
              <StepContent />
            </Step>
          )}
          {closedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar className={classes.closedAvatar}>
                    <CloseIcon className={classes.timelineIcon} />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {moment(closedTime).format('DD/MM/YYYY, HH:mm:ss')}
                  </Typography>
                }
              >
                Closed
              </StepLabel>
              <StepContent>
                {!closingCodes
                  ? ''
                  : (closingCodes || []).map((cc, index) => (
                      <Typography variant="caption" key={index}>
                        {cc.code}
                      </Typography>
                    ))}
              </StepContent>
            </Step>
          )}
        </Stepper>
      </CardContent>
    </Card>
  );
}
