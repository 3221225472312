import {
  Avatar,
  Paper,
  CardContent,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  Toolbar,
  Typography,
  makeStyles,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListSubheader,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import {
  green,
  indigo,
  orange,
  red,
  teal,
  lightBlue,
} from '@material-ui/core/colors';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Sort as SortIcon,
  BarChart as GroupByIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Bar,
  BarChart,
  Brush,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_VEHICLE_DAILY_UTILISATION,
  FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
  LOAD_VEHICLE_DAILY_UTILISATION,
} from '../../../actions';
import {
  SelectMultiple,
  Table,
  Parameters,
  CustomTooltip,
} from '../../controls';
import Container from '../../Container';
import { getKeyLabel, getTextWidth } from '../../../data/constants';
import { downloadCSV } from '../../../apis/utilities';
import { useEffectOnce } from 'hooks';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > :last-child': {
      marginBottom: theme.spacing(1),
    },
  },
  card: {
    margin: theme.spacing(0, 1),
    minWidth: 240,
    fontSize: 12,
  },
  description: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  cardContent: {
    padding: 0,
    paddingTop: theme.spacing(4),
  },
  groupByField: {
    margin: theme.spacing(0.5),
  },
  typeField: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
  flex: {
    flexGrow: 1,
  },
  placeholder: {
    color: theme.palette.grey[500],
  },
  collapseContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  legend: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  legendItem: {
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendAvatar: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(0.5),
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  horizontalRadio: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const commonLabels = [
  ['unaccountable', 'Unaccounted'],
  ['totalMileage', 'Total Mileage'],
  ['averageMileage', 'Average Mileage'],
  ['dailyMileage', 'Daily Mileage'],
  ['totalTrips', 'Total Trips'],
  ['averageTrips', 'Average Trips'],
  ['dailyTrips', 'Daily Trips'],
];

const usedUnusedUnavailableClassificationSettings = {
  bars: [
    { name: 'unused', colour: red[800] },
    { name: 'unavailable', colour: orange[800] },
    { name: 'used', colour: green[500] },
    { name: 'unaccountable', colour: lightBlue[400] },
  ],
  labels: new Map([
    ['unused', 'Unused'],
    ['unavailable', 'Unavailable'],
    ['used', 'Used'],
    ...commonLabels,
  ]),
  classificationHeaders: [
    {
      label: 'Unused (hours)',
      key: 'unused',
      type: 'number',
    },
    {
      label: 'Unavailable (hours)',
      key: 'unavailable',
      type: 'number',
    },
    {
      label: 'Used (hours)',
      key: 'used',
      type: 'number',
    },
    {
      label: 'Unaccounted (hours)',
      key: 'unaccountable',
      type: 'number',
    },
  ],
  orderByValues: ['unused', 'unavailable', 'used', 'unaccountable'],
};

const movingStoppedIdleClassificationSettings = {
  bars: [
    { name: 'stoppedBase', colour: red[800] },
    { name: 'idleBase', colour: red[500] },
    { name: 'stoppedWorkshop', colour: orange[800] },
    { name: 'idleWorkshop', colour: orange[500] },
    { name: 'stoppedElsewhere', colour: teal[800] },
    { name: 'idleElsewhere', colour: teal[500] },
    { name: 'moving', colour: green[500] },
    { name: 'unaccountable', colour: lightBlue[400] },
  ],
  labels: new Map([
    ['stoppedBase', 'Stopped @ Base'],
    ['idleBase', 'Idle @ Base'],
    ['stoppedWorkshop', 'Stopped @ Workshop'],
    ['idleWorkshop', 'Idle @ Workshop'],
    ['stoppedElsewhere', 'Stopped Elsewhere'],
    ['idleElsewhere', 'Idle Elsewhere'],
    ['moving', 'Moving'],
    ...commonLabels,
  ]),
  classificationHeaders: [
    {
      label: 'Moving (hours)',
      key: 'moving',
      type: 'number',
    },
    {
      label: 'Stopped @ Base (hours)',
      key: 'stoppedBase',
      type: 'number',
    },
    {
      label: 'Stopped @ Workshop (hours)',
      key: 'stoppedWorkshop',
      type: 'number',
    },
    {
      label: 'Stopped Elsewhere (hours)',
      key: 'stoppedElsewhere',
      type: 'number',
    },
    {
      label: 'Idle @ Base (hours)',
      key: 'idleBase',
      type: 'number',
    },
    {
      label: 'Idle @ Workshop (hours)',
      key: 'idleWorkshop',
      type: 'number',
    },
    {
      label: 'Idle Elsewhere (hours)',
      key: 'idleElsewhere',
      type: 'number',
    },
    {
      label: 'Unaccounted (hours)',
      key: 'unaccountable',
      type: 'number',
    },
  ],
  orderByValues: [
    'stoppedBase',
    'idleBase',
    'stoppedWorkshop',
    'idleWorkshop',
    'stoppedElsewhere',
    'idleElsewhere',
    'moving',
    'unaccountable',
  ],
};

const measureTypes = ['total', 'average', 'daily'];

export default function DailyUtilisation(props) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.utilisation.daily.data, _.isEqual);
  const {
    groupBy,
    orderBy,
    filter,
    usedUnusedUnavailableClassification,
  } = useSelector((state) => state.utilisation.daily);
  const query = useSelector(
    (state) => state.utilisation.daily.query,
    _.isEqual
  );
  const isLoading = useSelector((state) => state.utilisation.daily.isLoading);
  const error = useSelector((state) => state.utilisation.daily.error);
  const groupByValues = useSelector(
    (state) => state.utilisation.daily.groupByValues,
    _.isEqual
  );
  const filterValues = useSelector(
    (state) => state.utilisation.daily.filterValues,
    _.isEqual
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenBars, setHiddenBars] = useState([]);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [groupMenuAnchor, setGroupMenuAnchor] = useState(null);
  const [mileageType, setMileageType] = useState('totalMileage');
  const [tripCountType, setTripCountType] = useState('totalTrips');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [order, setOrder] = useState('asc');
  const classes = useStyles();
  const snackbar = useSnackbar();

  const classificationSettings = usedUnusedUnavailableClassification
    ? usedUnusedUnavailableClassificationSettings
    : movingStoppedIdleClassificationSettings;

  const {
    bars,
    labels,
    classificationHeaders,
    orderByValues,
  } = classificationSettings;

  function formatGroup(value) {
    switch (groupBy) {
      case 'date':
        return moment(value).format('DD/MM/YYYY');
      case 'month':
        return moment(value).format('MMM YYYY');
      default:
        return value;
    }
  }

  const headers = [
    {
      label: `${getKeyLabel(groupBy)}`,
      key: 'group',
      type: groupBy === 'date' ? 'date' : 'text',
      format: formatGroup,
    },
    {
      label: 'Vehicle Count',
      key: 'count',
      type: 'number',
    },
    ...classificationHeaders,
    {
      label: 'Total Mileage',
      key: 'totalMileage',
      type: 'number',
    },
    {
      label: 'Average Mileage',
      key: 'averageMileage',
      type: 'number',
    },
    {
      label: 'Daily Mileage',
      key: 'dailyMileage',
      type: 'number',
    },
    {
      label: 'Total Trips',
      key: 'totalTrips',
      type: 'number',
    },
    {
      label: 'Average Trips',
      key: 'averageTrips',
      type: 'number',
    },
    {
      label: 'Daily Trips',
      key: 'dailyTrips',
      type: 'number',
    },
  ];

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffectOnce(() => {
    dispatch({
      type: LOAD_VEHICLE_DAILY_UTILISATION,
      payload: {
        filter,
        groupBy,
        orderBy,
        usedUnusedUnavailableClassification,
      },
    });
  }, [dispatch, filter, groupBy, orderBy, usedUnusedUnavailableClassification]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLE_DAILY_UTILISATION,
      payload: {
        query,
        filter,
        groupBy,
        orderBy,
        usedUnusedUnavailableClassification,
      },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
    });
  }

  function handleGroupByFieldChanged(groupBy) {
    updateTransformOptions({ groupBy });
    setGroupMenuAnchor(null);
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: (value || []).map((value) => value.value),
          }
        : {
            ...filter,
            areas: {
              ...filter.areas,
              [name]: (value || []).map((value) => value.value),
            },
          };

    updateTransformOptions({ filter: newFilter });
  }

  function handleMileageTypeFieldChanged(mileageType) {
    setMileageType(mileageType);
  }

  function handleTripCountTypeFieldChanged(tripCountType) {
    setTripCountType(tripCountType);
  }

  function handleLegendClick(selectedBar) {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1))
      );
    }
  }

  function handleGroupMenuOpen(target) {
    setGroupMenuAnchor(target);
  }

  function handleGroupMenuClose() {
    setGroupMenuAnchor(null);
  }

  function handleSortMenuOpen(target) {
    setSortMenuAnchor(target);
  }

  function handleSortMenuClose() {
    setSortMenuAnchor(null);
  }

  function updateTransformOptions(optionChanges) {
    dispatch({
      type: LOAD_VEHICLE_DAILY_UTILISATION,
      payload: {
        filter,
        orderBy,
        groupBy,
        usedUnusedUnavailableClassification,
        ...optionChanges,
      },
    });
  }

  function handleSortItemClick(orderBy) {
    updateTransformOptions({ orderBy });
    setSortMenuAnchor(null);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleOrderChange(order) {
    setOrder(order);
  }

  function handleOrderByChange(orderBy) {
    updateTransformOptions({ orderBy });
    setOrder('asc');
  }

  function handleClassificationSettingsChange(event) {
    updateTransformOptions({
      usedUnusedUnavailableClassification: event.target.checked,
    });
  }

  async function handleDownloadClick() {
    const filename = 'Vehicle Daily Utilisation.csv';

    downloadCSV(
      data.map(({ group, ...record }) => ({
        group: formatGroup(group),
        ...record,
      })),
      filename,
      headers
    );
  }

  const xAxisHeight =
    data.length === 0
      ? 0
      : getTextWidth(
          data
            .map((item) => formatGroup(item.group))
            .sort((a, b) => b.length - a.length)[0],
          '12px Roboto'
        ) + 24;

  return (
    <Container title="Daily Utilisation">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        initialValue={query}
        className={classes.parameters}
        pointEvent
        dateOnly
        vehicle
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Daily Utilisation</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Daily Utilisation
          </Typography>
          <div className={classes.flex} />
          {/* <Typography className={classes.cardHeader} variant="subtitle1">
            Group by
          </Typography>
          <TextField
            select
            className={classes.groupByField}
            name="groupBy"
            value={groupBy}
            onChange={(event) => handleGroupByFieldChanged(event.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            {Object.values(groupByValues).map((value) => (
              <MenuItem key={value} value={value}>
                {getKeyLabel(value)}
              </MenuItem>
            ))}
          </TextField> */}
          <Checkbox
            checked={
              classificationSettings ===
              usedUnusedUnavailableClassificationSettings
            }
            onChange={handleClassificationSettingsChange}
          />
          <Typography className={classes.cardHeader} variant="subtitle1">
            Classify by Used/Unused/Unavailable
          </Typography>
          <IconButton
            title={showFilter ? 'Hide filter' : 'Show filter'}
            onClick={handleFilterToggle}
          >
            <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton
            title="Group by"
            aria-owns={groupMenuAnchor ? 'date-menu' : undefined}
            aria-haspopup="true"
            onClick={(event) => handleGroupMenuOpen(event.target)}
          >
            <GroupByIcon />
          </IconButton>
          <Menu
            id="date-menu"
            anchorEl={groupMenuAnchor}
            open={Boolean(groupMenuAnchor)}
            onClose={handleGroupMenuClose}
          >
            <ListSubheader disableSticky>Group by</ListSubheader>
            {['all'].concat(Object.values(groupByValues)).map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === groupBy}
                onClick={() => handleGroupByFieldChanged(value)}
              >
                {getKeyLabel(value)}
              </MenuItem>
            ))}
          </Menu>
          <IconButton
            title="Sort by"
            aria-owns={sortMenuAnchor ? 'date-menu' : undefined}
            aria-haspopup="true"
            onClick={(event) => handleSortMenuOpen(event.target)}
          >
            <SortIcon />
          </IconButton>
          <Menu
            id="date-menu"
            anchorEl={sortMenuAnchor}
            open={Boolean(sortMenuAnchor)}
            onClose={() => handleSortMenuClose()}
          >
            <ListSubheader disableSticky>Sort by</ListSubheader>

            {groupBy !== 'all' && (
              <MenuItem
                onClick={() => handleSortItemClick('group')}
                selected={'group' === orderBy}
              >
                {getKeyLabel(groupBy)}
              </MenuItem>
            )}
            {orderByValues.map((value) => (
              <MenuItem
                key={value}
                onClick={() => handleSortItemClick(value)}
                selected={value === orderBy}
              >
                {labels.get(value)}
              </MenuItem>
            ))}
            <MenuItem onClick={() => handleSortItemClick(mileageType)}>
              {labels.get(mileageType)}
            </MenuItem>
            <MenuItem onClick={() => handleSortItemClick(tripCountType)}>
              {labels.get(tripCountType)}
            </MenuItem>
          </Menu>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <div className={classes.collapseContainer}>
            {!useReducedResourceInformation && (
              <SelectMultiple
                fullWidth
                label="Registration Number"
                placeholder="Select..."
                value={filter.registrationNumber.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) =>
                  handleFilterFieldChanged('registrationNumber', value)
                }
                suggestions={filterValues.registrationNumber.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}
            <SelectMultiple
              fullWidth
              label="Fleet Number"
              placeholder="Select..."
              value={filter.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) =>
                handleFilterFieldChanged('fleetNumber', value)
              }
              suggestions={filterValues.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            {useReducedResourceInformation ? (
              <SelectMultiple
                fullWidth
                label="Type"
                placeholder="Select..."
                value={filter.type.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) => handleFilterFieldChanged('type', value)}
                suggestions={filterValues.type.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            ) : (
              <SelectMultiple
                fullWidth
                label="Role"
                placeholder="Select..."
                value={filter.role.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) => handleFilterFieldChanged('role', value)}
                suggestions={filterValues.role.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            )}
            {Object.entries(filterValues.areas).map((entry) => (
              <SelectMultiple
                fullWidth
                key={entry[0]}
                label={getKeyLabel(entry[0])}
                placeholder="Select..."
                value={(filter.areas[entry[0]] || []).map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={(value) => handleFilterFieldChanged(entry[0], value)}
                suggestions={entry[1].map((value) => ({
                  label: value,
                  value,
                }))}
              />
            ))}
          </div>
        </Collapse>
        <Paper className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.legend}>
              {bars.map((bar) => (
                <div
                  key={bar.name}
                  className={classes.legendItem}
                  onClick={() => handleLegendClick(bar.name)}
                >
                  <Avatar
                    className={classes.legendAvatar}
                    style={{
                      backgroundColor:
                        !hiddenBars.includes(bar.name) && bar.colour,
                    }}
                  >
                    <Fragment />
                  </Avatar>
                  <Typography variant="caption">
                    {labels.get(bar.name)}
                  </Typography>
                </div>
              ))}
            </div>
            <ResponsiveContainer width="99%" height={520}>
              <BarChart
                data={data}
                margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                barCategoryGap={4}
              >
                <XAxis
                  dataKey="group"
                  tickFormatter={formatGroup}
                  angle={-90}
                  textAnchor="end"
                  interval={0}
                  height={xAxisHeight}
                >
                  <Label
                    value={getKeyLabel(groupBy)}
                    offset={36}
                    position="bottom"
                  />
                </XAxis>
                <YAxis>
                  <Label
                    value="Hours"
                    angle={-90}
                    position="left"
                    offset={-24}
                  />
                </YAxis>
                <Tooltip
                  cursor={false}
                  content={
                    <CustomTooltip unit="hours" labelFormatter={formatGroup} />
                  }
                />
                {data.length > 0 && <Brush dataKey="group" height={24} />}
                {bars.map((bar) => (
                  <Bar
                    key={bar.name}
                    dataKey={bar.name}
                    name={labels.get(bar.name)}
                    stackId="a"
                    fill={bar.colour}
                    hide={hiddenBars.includes(bar.name)}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
        <Toolbar className={classes.horizontalRadio}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="mileage chart type"
              name="mileage chart type"
              value={mileageType}
              onChange={(e) => handleMileageTypeFieldChanged(e.target.value)}
            >
              {measureTypes.map((measureType) => (
                <FormControlLabel
                  key={measureType}
                  value={`${measureType}Mileage`}
                  control={<Radio />}
                  label={labels.get(`${measureType}Mileage`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Toolbar>
        <Paper className={classes.card}>
          <CardContent className={classes.cardContent}>
            <ResponsiveContainer width="99%" height={520}>
              <BarChart
                data={data}
                margin={{ top: 32, right: 16, left: 32, bottom: 32 }}
                barCategoryGap={4}
              >
                <XAxis
                  dataKey="group"
                  tickFormatter={formatGroup}
                  angle={-90}
                  textAnchor="end"
                  interval={0}
                  height={xAxisHeight}
                >
                  <Label
                    value={getKeyLabel(groupBy)}
                    offset={36}
                    position="bottom"
                  />
                </XAxis>
                <YAxis>
                  <Label value="Miles" angle={-90} position="left" offset={8} />
                </YAxis>
                <Tooltip
                  cursor={false}
                  content={<CustomTooltip labelFormatter={formatGroup} />}
                />
                {data.length > 0 && <Brush dataKey="group" height={24} />}
                {measureTypes.map((measureType) => (
                  <Bar
                    key={measureType}
                    dataKey={`${measureType}Mileage`}
                    name={labels.get(`${measureType}Mileage`)}
                    fill={indigo[800]}
                    hide={mileageType !== `${measureType}Mileage`}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
        <Toolbar className={classes.horizontalRadio}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="trip chart type"
              name="trip chart type"
              value={tripCountType}
              onChange={(e) => handleTripCountTypeFieldChanged(e.target.value)}
            >
              {measureTypes.map((measureType) => (
                <FormControlLabel
                  key={measureType}
                  value={`${measureType}Trips`}
                  control={<Radio />}
                  label={labels.get(`${measureType}Trips`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Toolbar>
        <Paper className={classes.card}>
          <CardContent className={classes.cardContent}>
            <ResponsiveContainer width="99%" height={520}>
              <BarChart
                data={data}
                margin={{ top: 32, right: 16, left: 32, bottom: 32 }}
                barCategoryGap={4}
              >
                <XAxis
                  dataKey="group"
                  tickFormatter={formatGroup}
                  angle={-90}
                  textAnchor="end"
                  interval={0}
                  height={xAxisHeight}
                >
                  <Label
                    value={getKeyLabel(groupBy)}
                    offset={36}
                    position="bottom"
                  />
                </XAxis>
                <YAxis>
                  <Label value="Trips" angle={-90} position="left" offset={8} />
                </YAxis>
                <Tooltip
                  cursor={false}
                  content={<CustomTooltip labelFormatter={formatGroup} />}
                />
                {data.length > 0 && <Brush dataKey="group" height={24} />}
                {measureTypes.map((measureType) => (
                  <Bar
                    key={measureType}
                    dataKey={`${measureType}Trips`}
                    name={labels.get(`${measureType}Trips`)}
                    fill={indigo[800]}
                    hide={tripCountType !== `${measureType}Trips`}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Paper>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Daily Utilisation
          </Typography>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={data}
            headers={headers}
            rowsPerPage={rowsPerPage}
            page={page}
            dense={true}
            order={order}
            orderBy={orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}
