import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import {
  Accessibility as FriskIcon,
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  Link as LinkIcon,
  SpeakerNotes as SpeakerNotesIcon,
} from '@material-ui/icons';
import { Delete as DeleteIcon } from '@material-ui/icons/Delete';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { ConfirmationDialog } from '../dialogs';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardHeader: {
    paddingLeft: 0,
    paddingRight: 0,
    // paddingLeft: 0,
    // [theme.breakpoints.down('xs')]: {
    //   paddingLeft: theme.spacing(1)
    // },
    flexGrow: 1,
  },
  cardContent: {
    padding: 0,
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(2),
    },
    width: '100%',
  },
  back: {
    marginLeft: -12,
  },
  remove: {
    color: red[500],
  },
  openedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: red[500],
  },
  closedAvatar: {
    margin: -3,
    marginRight: 2,
    width: 30,
    height: 30,
    color: '#fff',
    backgroundColor: grey[500],
  },
  areasPaper: {
    marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2)
  },
  areaType: {
    fontSize: 10,
  },
  areaName: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  setThemeWorkaround: {
    noret: (theme.overrides = {
      MuiStepper: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      MuiCardHeader: {
        action: {
          marginRight: 0,
          alignSelf: 'center',
        },
      },
    }),
  },
  subheader: {
    paddingLeft: theme.spacing(2),
    fontSize: 14,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  header: {
    paddingLeft: theme.spacing(2),
    fontSize: 14,
    lineHeight: 2,
    paddingBottom: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noTopPadding: {
    paddingTop: 0,
  },
  link: {
    color: theme.palette.text.primary,
    marginLeft: 'auto',
    display: 'flex',
  },
  desc: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    whiteSpace: 'pre-line',
  },
  leftMargin: {
    marginLeft: theme.spacing(1),
  },
  actionContainer: {
    display: 'flex',
  },
}));

function formatDate(date) {
  return moment(date).format('DD/MM/YYYY, HH:mm');
}

export default function IntelligenceCard({ feature, onDone, onRemove }) {
  const [removeOpen, setRemoveOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  function isDict(v) {
    return (
      typeof v === 'object' &&
      v !== null &&
      !(v instanceof Array) &&
      !(v instanceof Date)
    );
  }

  function renderContent() {
    const p = feature.properties;
    const icon =
      p.type && p.type.toLowerCase() === 'stop and search' ? (
        <FriskIcon />
      ) : (
        <SpeakerNotesIcon />
      );

    return (
      <div>
        <Toolbar>
          {isXs && (
            <IconButton onClick={onDone} className={classes.back}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <CardHeader
            avatar={
              p.intelligenceReliability || p.sourceReliability ? (
                <Badge
                  badgeContent={
                    (p.intelligenceReliability
                      ? p.intelligenceReliability.code
                      : '?') +
                    (p.sourceReliability ? p.sourceReliability.code : '')
                  }
                  color="primary"
                >
                  <Avatar>{icon}</Avatar>
                </Badge>
              ) : (
                <Avatar>{icon}</Avatar>
              )
            }
            title={feature.properties.number}
            subheader={
              p.classifications &&
              p.classifications.map((c) => c.code).join(', ')
            }
            className={classes.cardHeader}
            action={
              <div className={classes.actionContainer}>
                {isXs && onRemove && (
                  <IconButton className={classes.remove}>
                    <DeleteIcon />
                  </IconButton>
                )}
                {p.url && (
                  <IconButton>
                    <Link className={classes.link} href={p.url}>
                      <LinkIcon />
                    </Link>
                  </IconButton>
                )}
              </div>
            }
          />
        </Toolbar>
        <CardContent className={classes.cardContent}>
          <div className={classes.flex}>
            {/* {p.sourceReliability && <div className={classes.header}>{p.sourceReliability.name}</div>} */}
            {/* {p.url && <Link className={classes.link} href={p.url}><LinkIcon /></Link>} */}
          </div>
          <div className={classes.desc}>{p.description}</div>
          {p.type && p.type !== 'Path' && (
            <Fragment>
              <Typography
                key="subheader"
                variant="subtitle1"
                className={classes.subheader}
              >
                Type
              </Typography>
              <Paper key="table" className={classes.areasPaper} elevation={0}>
                <Typography variant="caption" className={classes.leftMargin}>
                  {p.type.toUpperCase()}
                </Typography>
              </Paper>
            </Fragment>
          )}
          {p.address && (
            <Fragment>
              <Typography
                key="subheader"
                variant="subtitle1"
                className={classes.subheader}
              >
                Address
              </Typography>

              <Paper key="table" className={classes.areasPaper} elevation={0}>
                <div className={classes.flex}>
                  <Typography variant="caption" className={classes.leftMargin}>
                    {[
                      p.address.streetNumber,
                      p.address.buildingName,
                      p.address.streetName,
                      p.address.postcode,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </Typography>
                  {p.address.url && (
                    <IconButton>
                      <Link className={classes.link} href={p.address.url}>
                        <LinkIcon />
                      </Link>
                    </IconButton>
                  )}
                </div>
              </Paper>
            </Fragment>
          )}
          <Typography className={classes.subheader} type="subtitle1">
            Timeline
          </Typography>
          <Stepper orientation="vertical" className={classes.noTopPadding}>
            <Step active>
              <StepLabel
                icon={
                  <Avatar className={classes.openedAvatar}>
                    <AddIcon />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {formatDate(p.startTime)}
                  </Typography>
                }
              >
                {<Typography>Start</Typography>}
              </StepLabel>
              <StepContent>
                <div>
                  {p.dispatchType && (
                    <Typography variant="caption">
                      {p.dispatchType.name}
                    </Typography>
                  )}
                </div>
              </StepContent>
            </Step>

            {p.reportedTime && (
              <Step active>
                <StepLabel
                  icon={
                    <Avatar className={classes.closedAvatar}>
                      <CloseIcon />
                    </Avatar>
                  }
                  optional={
                    <Typography variant="caption">
                      {formatDate(p.reportedTime)}
                    </Typography>
                  }
                >
                  {<Typography>Reported</Typography>}
                </StepLabel>
              </Step>
            )}
          </Stepper>
          {p.areas && (
            <Fragment>
              <Typography
                key="subheader"
                variant="subtitle1"
                className={classes.subheader}
              >
                Areas
              </Typography>
              <Paper key="table" className={classes.areasPaper} elevation={0}>
                {renderDictTable(p.areas)}
              </Paper>
            </Fragment>
          )}
        </CardContent>
      </div>
    );
  }

  function renderDictTable(list) {
    let dict = list;
    // make sure we have a dict, if it's an array of blah.code & blah.name
    // let's reformat to a dictionary
    if (!isDict(dict)) {
      dict = {};
      for (let k in list) {
        if (list[k].hasOwnProperty('code') && list[k].hasOwnProperty('name')) {
          dict[list[k].code] = list[k].name;
        } else {
          dict[k] = list[k];
        }
      }
      list = dict;
    }

    return (
      <Table>
        <TableBody>
          {Object.keys(dict).map((key, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="caption" className={classes.areaType}>
                  {key}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" className={classes.areaName}>
                  {dict[key]}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    feature &&
    (isXs ? (
      renderContent()
    ) : (
      <Card className={classes.card}>
        {renderContent()}
        <CardActions disableSpacing>
          <Button color="primary" onClick={onDone}>
            Done
          </Button>
          {onRemove && (
            <Fragment>
              <Button
                className={classes.remove}
                onClick={() => setRemoveOpen(true)}
              >
                Remove
              </Button>
              <ConfirmationDialog
                action="Remove"
                open={removeOpen}
                itemId={
                  feature.properties.title || feature.properties.identifier
                }
                onOk={onRemove}
                onCancel={() => setRemoveOpen(false)}
              />
            </Fragment>
          )}
        </CardActions>
      </Card>
    ))
  );
}
