import { IconButton, MenuItem, TextField, makeStyles } from '@material-ui/core';
import {
  AddCircle as AddIcon,
  RemoveCircle as RemoveIcon,
} from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
// import { updateSelectedFeatureGroups } from '../../../actions';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
}));

function Groups({ groups, types, updateSelectedFeatureGroups }) {
  const classes = useStyles();

  function handleChange(name, index, value) {
    // updateSelectedFeatureGroups(
    //   groups
    //     .slice(0, index)
    //     .concat([{ ...groups[index], [name]: value }])
    //     .concat(groups.slice(index + 1))
    // );
  }

  function handleAdd() {
    // updateSelectedFeatureGroups(
    //   (groups || []).concat([{ type: '', name: '' }])
    // );
  }

  function handleRemove(index) {
    // updateSelectedFeatureGroups(
    //   groups.slice(0, index).concat(groups.slice(index + 1))
    // );
  }

  return (
    <div>
      <div>
        {(groups || []).map((group, index) => (
          <div className={classes.row} key={index}>
            <TextField
              select
              className={classes.textField}
              margin="dense"
              label="Type"
              value={group.type}
              onChange={(e) => {
                handleChange('type', index, e.target.value);
              }}
            >
              {(types || []).map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              label="Name"
              className={classes.textField}
              value={group.name}
              onChange={(e) => {
                handleChange('name', index, e.target.value);
              }}
            />
            <IconButton
              className={classes.button}
              aria-label="Remove"
              index={index}
              onClick={() => handleRemove(index)}
            >
              <RemoveIcon />
            </IconButton>
          </div>
        ))}
      </div>
      <div className={classes.row}>
        <IconButton
          className={classes.button}
          aria-label="Add"
          onClick={handleAdd}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    groups:
      state.features.featureCollection &&
      Number.isInteger(state.features.selectedFeatureIndex)
        ? state.features.featureCollection.features[
            state.features.selectedFeatureIndex
          ].properties.areas
        : [],
  };
}

export default connect(mapStateToProps)(Groups);
