import {
  Avatar,
  Badge,
  Collapse,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import {
  Assignment as AssignmentIcon,
  Bookmark as BookmarkIcon,
  ExpandMore as ExpandMoreIcon,
  GpsOff as GpsOffIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { ObjectiveAvatar } from '../controls';
import {
  briefItemTypeIcons,
  featureSubtypeColours,
  smallFeatureSubtypeIcons,
  smallFeatureTypeIcons,
} from '../../data/constants';

const useStyles = makeStyles((theme) => ({
  hoverListItem: {
    backgroundColor: theme.palette.action.hover,
  },
  nestedSubheader: {
    fontSize: 12,
  },
  itemList: {
    paddingBottom: theme.spacing(2),
  },
  itemAvatar: {
    width: 30,
    height: 30,
    fontSize: 12,
    backgroundColor: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
  },
  itemIcon: {
    width: 20,
    height: 20,
  },
  description: {
    fontSize: 12,
  },
  duration: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  areasSubheader: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: 12,
  },
  areasPaper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  areaType: {
    fontSize: 10,
  },
  areaName: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  noGpsIcon: {
    color: theme.palette.divider,
    fontSize: 16,
  },
  selectedListItem: {
    backgroundColor: theme.palette.action.selected,
  },
}));

export default function BriefCollectionList({
  collections,
  objectives,
  selectedItemIndex,
  hoveredItemIndex,
  onSelectItem,
  onHoverItem,
}) {
  const classes = useStyles();

  function handleExpandClick(index) {
    if (selectedItemIndex.collectionId === index.collectionId) {
      onSelectItem({});
    } else {
      onSelectItem(index);
    }
  }

  function handleObjectiveClick(feature) {
    onSelectItem({
      featureId: feature.id,
      typeId: feature.properties.typeId,
    });
  }

  function handleListItemClick(feature) {
    onSelectItem({
      featureId: feature.id,
      typeId: feature.properties.typeId,
      collectionId: feature.properties.collectionId,
      collectionTypeId: feature.properties.collectionTypeId,
    });
  }

  return (
    <Fragment>
      {collections && (
        <List dense disablePadding>
          {Object.values(collections.plans || {}).length > 0 && (
            <ListSubheader disableSticky className={classes.sectionSubheader}>
              Plans
            </ListSubheader>
          )}
          {Object.values(collections.plans || {}).map((collection) => (
            <Fragment key={collection.identifier}>
              <ListItem
                button
                className={
                  hoveredItemIndex &&
                  selectedItemIndex &&
                  hoveredItemIndex.collectionId === collection.identifier &&
                  selectedItemIndex.collectionId !== collection.identifier
                    ? classes.hoverListItem
                    : null
                }
                onClick={() =>
                  handleExpandClick({
                    collectionId: collection.identifier,
                    collectionTypeId: 'plans',
                  })
                }
                onMouseEnter={() =>
                  onHoverItem({
                    collectionId: collection.identifier,
                    collectionTypeId: 'plans',
                  })
                }
                onMouseLeave={() => onHoverItem(null)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <AssignmentIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={collection.title}
                  secondary={collection.identifier}
                />
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]:
                      selectedItemIndex.collectionId === collection.identifier,
                  })}
                />
              </ListItem>
              <Collapse
                component="li"
                in={selectedItemIndex.collectionId === collection.identifier}
                timeout="auto"
                unmountOnExit
              >
                <List disablePadding dense className={classes.itemList}>
                  {Object.entries(
                    collection.items.features.features.reduce(
                      function (r, a) {
                        r[a.properties.type] = r[a.properties.type] || [];
                        r[a.properties.type].push(a);
                        return r;
                      },
                      { Perimeter: [], Path: [], Marker: [] }
                    )
                  ).map((group) => [
                    group[1].length === 0 ? (
                      ''
                    ) : (
                      <ListSubheader
                        key={group}
                        className={classes.nestedSubheader}
                        disableSticky
                      >{`${group[0]}s`}</ListSubheader>
                    ),
                    group[1].map((feature, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleListItemClick(feature)}
                        className={
                          _.isEqual(
                            {
                              featureId: feature.id,
                              typeId: feature.properties.typeId,
                              collectionId: feature.properties.collectionId,
                              collectionTypeId:
                                feature.properties.collectionTypeId,
                            },
                            selectedItemIndex
                          )
                            ? classes.selectedListItem
                            : _.isEqual(
                                {
                                  featureId: feature.id,
                                  typeId: feature.properties.typeId,
                                  collectionId: feature.properties.collectionId,
                                  collectionTypeId:
                                    feature.properties.collectionTypeId,
                                },
                                hoveredItemIndex
                              )
                            ? classes.hoverListItem
                            : null
                        }
                        onMouseEnter={() =>
                          onHoverItem({
                            featureId: feature.id,
                            typeId: feature.properties.typeId,
                            collectionId: feature.properties.collectionId,
                            collectionTypeId:
                              feature.properties.collectionTypeId,
                          })
                        }
                        onMouseLeave={() => onHoverItem(null)}
                      >
                        <ListItemAvatar>
                          <Avatar
                            className={classes.itemAvatar}
                            style={{
                              backgroundColor:
                                feature.properties.type === 'Perimeter' &&
                                feature.properties.subtype
                                  ? featureSubtypeColours.perimeters[
                                      feature.properties.subtype
                                    ].stroke
                                  : null,
                            }}
                          >
                            {feature.properties.type === 'Marker'
                              ? smallFeatureSubtypeIcons.markers[
                                  feature.properties.subtype
                                ] || smallFeatureSubtypeIcons.markers.default
                              : feature.properties.subtype ||
                                (feature.properties.type
                                  ? smallFeatureTypeIcons[
                                      feature.properties.type
                                    ]
                                  : '')}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={feature.properties.title}
                          secondary={feature.properties.identifier}
                        />
                        {feature.geometry ? (
                          ''
                        ) : (
                          <ListItemSecondaryAction>
                            <GpsOffIcon
                              title="No GPS Location"
                              className={classes.noGpsIcon}
                            />
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    )),
                  ])}
                </List>
              </Collapse>
            </Fragment>
          ))}
          {Object.values(collections.selections || {}).length > 0 && (
            <ListSubheader disableSticky className={classes.sectionSubheader}>
              Selections
            </ListSubheader>
          )}
          {Object.values(collections.selections || {}).map((collection) => (
            <Fragment key={collection.identifier}>
              <ListItem
                button
                className={
                  hoveredItemIndex &&
                  selectedItemIndex &&
                  hoveredItemIndex.collectionId === collection.identifier &&
                  selectedItemIndex.collectionId !== collection.identifier
                    ? classes.hoverListItem
                    : null
                }
                onClick={() =>
                  handleExpandClick({
                    collectionId: collection.identifier,
                    collectionTypeId: 'selections',
                  })
                }
                onMouseEnter={() =>
                  onHoverItem({
                    collectionId: collection.identifier,
                    collectionTypeId: 'selections',
                  })
                }
                onMouseLeave={() => onHoverItem(null)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <BookmarkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={collection.title}
                  secondary={collection.identifier}
                />
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]:
                      selectedItemIndex.collectionId === collection.identifier,
                  })}
                />
              </ListItem>
              <Collapse
                component="li"
                in={selectedItemIndex.collectionId === collection.identifier}
                timeout="auto"
                unmountOnExit
              >
                <List disablePadding dense className={classes.itemList}>
                  {Object.entries(collection.items).map((group) => [
                    group[1].features.length === 0 ? (
                      ''
                    ) : (
                      <ListSubheader
                        key={group}
                        className={classes.nestedSubheader}
                        disableSticky
                      >
                        {group[0]}
                      </ListSubheader>
                    ),
                    group[1].features.map((feature, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleListItemClick(feature)}
                        className={
                          _.isEqual(
                            {
                              featureId: feature.id,
                              typeId: feature.properties.typeId,
                              collectionId: feature.properties.collectionId,
                              collectionTypeId:
                                feature.properties.collectionTypeId,
                            },
                            selectedItemIndex
                          )
                            ? classes.selectedListItem
                            : _.isEqual(
                                {
                                  featureId: feature.id,
                                  typeId: feature.properties.typeId,
                                  collectionId: feature.properties.collectionId,
                                  collectionTypeId:
                                    feature.properties.collectionTypeId,
                                },
                                hoveredItemIndex
                              )
                            ? classes.hoverListItem
                            : null
                        }
                        onMouseEnter={() =>
                          onHoverItem({
                            featureId: feature.id,
                            typeId: feature.properties.typeId,
                            collectionId: feature.properties.collectionId,
                            collectionTypeId:
                              feature.properties.collectionTypeId,
                          })
                        }
                        onMouseLeave={() => onHoverItem(null)}
                      >
                        {feature.properties.typeId === 'incidents' ? (
                          <Fragment>
                            <ListItemAvatar>
                              <Badge
                                badgeContent={
                                  feature.properties.responseCategory &&
                                  feature.properties.responseCategory.code
                                    ? feature.properties.responseCategory.code
                                    : feature.properties.grade || '?'
                                }
                                color="primary"
                                key="avatar"
                              >
                                <Avatar className={classes.itemAvatar}>
                                  {briefItemTypeIcons[group[0]]}
                                </Avatar>
                              </Badge>
                            </ListItemAvatar>
                            <ListItemText
                              primary={feature.properties.description || ''}
                              secondary={feature.properties.number}
                              key="item"
                            />
                          </Fragment>
                        ) : (
                          <Fragment>
                            <ListItemAvatar>
                              <Avatar
                                className={classes.itemAvatar}
                                key="avatar"
                              >
                                {briefItemTypeIcons[group[0]]}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                feature.properties.type
                                  ? feature.properties.type.name
                                  : ''
                              }
                              secondary={feature.properties.number}
                              key="item"
                            />
                          </Fragment>
                        )}
                        {!feature.geometry && (
                          <ListItemSecondaryAction>
                            <GpsOffIcon
                              title="No GPS Location"
                              className={classes.noGpsIcon}
                            />
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    )),
                  ])}
                </List>
              </Collapse>
            </Fragment>
          ))}
          {Object.values(collections.queries || {}).length > 0 && (
            <ListSubheader disableSticky className={classes.sectionSubheader}>
              Queries
            </ListSubheader>
          )}
          {Object.values(collections.queries || {}).map((collection) => (
            <Fragment key={collection.identifier}>
              <ListItem
                button
                className={
                  hoveredItemIndex &&
                  selectedItemIndex &&
                  hoveredItemIndex.collectionId === collection.identifier &&
                  selectedItemIndex.collectionId !== collection.identifier
                    ? classes.hoverListItem
                    : null
                }
                onClick={() =>
                  handleExpandClick({
                    collectionId: collection.identifier,
                    collectionTypeId: 'queries',
                  })
                }
                onMouseEnter={() =>
                  onHoverItem({
                    collectionId: collection.identifier,
                    collectionTypeId: 'queries',
                  })
                }
                onMouseLeave={() => onHoverItem(null)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <SearchIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={collection.title}
                  secondary={collection.identifier}
                />
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]:
                      selectedItemIndex.collectionId === collection.identifier,
                  })}
                />
              </ListItem>
              <Collapse
                component="li"
                in={selectedItemIndex.collectionId === collection.identifier}
                timeout="auto"
                unmountOnExit
              >
                <List disablePadding dense className={classes.itemList}>
                  {Object.entries(collection.items).map((group) => [
                    group[1].features.length > 0 && (
                      <ListSubheader
                        key={group}
                        className={classes.nestedSubheader}
                        disableSticky
                      >
                        {group[0]}
                      </ListSubheader>
                    ),
                    group[1].features.map((feature, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleListItemClick(feature)}
                        className={
                          _.isEqual(
                            {
                              featureId: feature.id,
                              typeId: feature.properties.typeId,
                              collectionId: feature.properties.collectionId,
                              collectionTypeId:
                                feature.properties.collectionTypeId,
                            },
                            selectedItemIndex
                          )
                            ? classes.selectedListItem
                            : _.isEqual(
                                {
                                  featureId: feature.id,
                                  typeId: feature.properties.typeId,
                                  collectionId: feature.properties.collectionId,
                                  collectionTypeId:
                                    feature.properties.collectionTypeId,
                                },
                                hoveredItemIndex
                              )
                            ? classes.hoverListItem
                            : null
                        }
                        onMouseEnter={() =>
                          onHoverItem({
                            featureId: feature.id,
                            typeId: feature.properties.typeId,
                            collectionId: feature.properties.collectionId,
                            collectionTypeId:
                              feature.properties.collectionTypeId,
                          })
                        }
                        onMouseLeave={() => onHoverItem(null)}
                      >
                        {feature.properties.typeId === 'incidents' ? (
                          <Fragment>
                            <ListItemAvatar>
                              <Badge
                                badgeContent={
                                  feature.properties.responseCategory &&
                                  feature.properties.responseCategory.code
                                    ? feature.properties.responseCategory.code
                                    : feature.properties.grade || '?'
                                }
                                color="primary"
                                key="avatar"
                              >
                                <Avatar className={classes.itemAvatar}>
                                  {briefItemTypeIcons[group[0]]}
                                </Avatar>
                              </Badge>
                            </ListItemAvatar>
                            <ListItemText
                              primary={feature.properties.description || ''}
                              secondary={feature.properties.number}
                              key="item"
                            />
                          </Fragment>
                        ) : (
                          <Fragment>
                            <ListItemAvatar>
                              <Avatar
                                className={classes.itemAvatar}
                                key="avatar"
                              >
                                {briefItemTypeIcons[group[0]]}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                feature.properties.type
                                  ? feature.properties.type.name
                                  : ''
                              }
                              secondary={feature.properties.number}
                              key="item"
                            />
                          </Fragment>
                        )}
                        {!feature.geometry && (
                          <ListItemSecondaryAction>
                            <GpsOffIcon
                              title="No GPS Location"
                              className={classes.noGpsIcon}
                            />
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    )),
                  ])}
                </List>
              </Collapse>
            </Fragment>
          ))}
        </List>
      )}
      {objectives && objectives.features.length > 0 && (
        <List dense disablePadding>
          <ListSubheader disableSticky className={classes.sectionSubheader}>
            Objectives
          </ListSubheader>
          {objectives.features.map((feature) => (
            <ListItem
              key={feature.id}
              button
              className={
                _.isEqual(
                  {
                    featureId: feature.id,
                    typeId: feature.properties.typeId,
                  },
                  selectedItemIndex
                )
                  ? classes.selectedListItem
                  : _.isEqual(
                      {
                        featureId: feature.id,
                        typeId: feature.properties.typeId,
                      },
                      hoveredItemIndex
                    )
                  ? classes.hoverListItem
                  : null
              }
              onClick={() => handleObjectiveClick(feature)}
              onMouseEnter={() =>
                onHoverItem({
                  featureId: feature.id,
                  typeId: 'objectives',
                })
              }
              onMouseLeave={() => onHoverItem(null)}
            >
              <ListItemAvatar>
                <ObjectiveAvatar
                  type={feature.properties.type}
                  startTime={feature.properties.startTime}
                  endTime={feature.properties.endTime}
                />
              </ListItemAvatar>
              <ListItemText
                primary={feature.properties.title}
                secondary={feature.properties.identifier}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Fragment>
  );
}
