import { mergeMap, map, catchError, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import _ from 'lodash';
import moment from 'moment';
import {
  FETCH_TRIPS,
  FETCH_TRIPS_SUCCESS,
  FETCH_TRIPS_FAILURE,
  FETCH_TRIPS_CANCELLED,
  UPDATE_TRIP_CLASSIFICATION,
  UPDATE_TRIP_CLASSIFICATION_SUCCESS,
  UPDATE_TRIP_CLASSIFICATION_FAILURE,
  UPDATE_TRIP_DRIVER,
  UPDATE_TRIP_DRIVER_SUCCESS,
  UPDATE_TRIP_DRIVER_FAILURE,
  FETCH_SPEED_INFRACTIONS,
  FETCH_SPEED_INFRACTIONS_CANCELLED,
  FETCH_SPEED_INFRACTIONS_FAILURE,
  FETCH_SPEED_INFRACTIONS_SUCCESS,
  FETCH_ACCELEROMETER_EVENTS,
  FETCH_ACCELEROMETER_EVENTS_CANCELLED,
  FETCH_ACCELEROMETER_EVENTS_FAILURE,
  FETCH_ACCELEROMETER_EVENTS_SUCCESS,
  FETCH_VEHICLE_LOCATION_VISITS,
  FETCH_VEHICLE_LOCATION_VISITS_SUCCESS,
  FETCH_VEHICLE_LOCATION_VISITS_FAILURE,
  FETCH_VEHICLE_LOCATION_VISITS_CANCELLED,
  FETCH_STOPS,
  FETCH_STOPS_SUCCESS,
  FETCH_STOPS_FAILURE,
  FETCH_STOPS_CANCELLED,
  FETCH_IDLES,
  FETCH_IDLES_SUCCESS,
  FETCH_IDLES_FAILURE,
  FETCH_IDLES_CANCELLED,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED,
  FETCH_ON_BOARD_DIAGNOSTICS,
  FETCH_ON_BOARD_DIAGNOSTICS_SUCCESS,
  FETCH_ON_BOARD_DIAGNOSTICS_FAILURE,
  FETCH_ON_BOARD_DIAGNOSTICS_CANCELLED,
  FETCH_TRAILS,
  FETCH_TRAILS_SUCCESS,
  FETCH_TRAILS_FAILURE,
  FETCH_TRAILS_CANCELLED,
  FETCH_PERSON_LOCATION_VISITS,
  FETCH_PERSON_LOCATION_VISITS_SUCCESS,
  FETCH_PERSON_LOCATION_VISITS_FAILURE,
  FETCH_PERSON_LOCATION_VISITS_CANCELLED,
  FETCH_DOUBLE_CREWS,
  FETCH_DOUBLE_CREWS_CANCELLED,
  FETCH_DOUBLE_CREWS_FAILURE,
  FETCH_DOUBLE_CREWS_SUCCESS,
  FETCH_ATTENDANCES,
  FETCH_ATTENDANCES_SUCCESS,
  FETCH_ATTENDANCES_FAILURE,
  FETCH_ATTENDANCES_CANCELLED,
  FETCH_OUTAGES,
  FETCH_OUTAGES_CANCELLED,
  FETCH_OUTAGES_FAILURE,
  FETCH_OUTAGES_SUCCESS,
} from '../actions';
import { fromAjax } from '../apis';
import {
  getHeaders,
  getPrimaryLocation,
  log,
  reduceByType as reduceAreas,
} from '../apis/utilities';

const {
  tripClassifications,
  minimumSpeedInfractionSeconds,
  minimumDoubleCrewSeconds,
} = window.config;

export function fetchTripsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TRIPS),
    mergeMap(({ payload: query }) =>
      fromAjax('/trips', {
        params: {
          query,
          projection: {
            identifier: true,
            vehicle: true,
            driver: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
            distanceKilometres: true,
            maxSpeedKilometresPerHour: true,
            startLocations: true,
            endLocations: true,
            classification: true,
          },
          sort: { startTime: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const trips = (response || []).map((trip) => {
            const startLocation = getPrimaryLocation(trip.startLocations);
            const endLocation = getPrimaryLocation(trip.endLocations);

            return {
              identifier: trip.identifier,
              vehicle: trip.vehicle,
              driver: trip.driver,
              classification: trip.classification || 'None',
              startTime: trip.startTime,
              endTime: trip.endTime,
              durationMinutes: trip.durationSeconds / 60,
              distanceMiles: trip.distanceKilometres * 0.62137119,
              maxSpeedMilesPerHour: trip.maxSpeedKilometresPerHour * 0.62137119,
              startLocationType: startLocation.type,
              startLocationName: startLocation.name,
              endLocationType: endLocation.type,
              endLocationName: endLocation.name,
            };
          });

          log('Read', 'Trips', query);

          return {
            type: FETCH_TRIPS_SUCCESS,
            payload: trips,
          };
        }),
        takeUntil(action$.pipe(ofType(FETCH_TRIPS_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TRIPS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function updateTripClassificationEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_TRIP_CLASSIFICATION),
    mergeMap(({ payload: { id, classification } }) =>
      fromAjax(`/trips/${id}`, {
        body: {
          classification,
        },
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: { identifier: id, classification } }) => ({
          type: UPDATE_TRIP_CLASSIFICATION_SUCCESS,
          payload: { id, classification },
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_TRIP_CLASSIFICATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function updateTripDriverEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_TRIP_DRIVER),
    mergeMap(({ payload: { id, driver } }) =>
      fromAjax(`/trips/${id}`, {
        body: {
          driver: { ...driver, assigned: true },
        },
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: { identifier: id, driver } }) => ({
          type: UPDATE_TRIP_DRIVER_SUCCESS,
          payload: { id, driver },
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_TRIP_DRIVER_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchVehicleLocationVisitsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_LOCATION_VISITS),
    mergeMap(({ payload: query }) =>
      fromAjax('/intersections', {
        params: {
          query,
          projection: {
            identifier: true,
            vehicle: true,
            location: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
            distanceKilometres: true,
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const visits = (response || []).map((visit) => {
            return {
              identifier: visit.identifier,
              vehicle: visit.vehicle,
              location: visit.location,
              startTime: visit.startTime,
              endTime: visit.endTime,
              durationMinutes: visit.durationSeconds / 60,
              distanceMiles: visit.distanceKilometres * 0.62137119,
            };
          });

          log('Read', 'Vehicle Location Visits', query);

          return {
            type: FETCH_VEHICLE_LOCATION_VISITS_SUCCESS,
            payload: visits,
          };
        }),
        takeUntil(
          action$.pipe(ofType(FETCH_VEHICLE_LOCATION_VISITS_CANCELLED))
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLE_LOCATION_VISITS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchTripsWithSpeedInfractionsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_SPEED_INFRACTIONS),
    mergeMap(({ payload: query }) =>
      forkJoin({
        infractions: fromAjax('/speedInfractions', {
          params: {
            query: {
              ...query,
              durationSeconds: { $gte: minimumSpeedInfractionSeconds || 45 },
            },
            projection: {
              identifier: true,
              startTime: true,
              endTime: true,
              durationSeconds: true,
              distanceKilometres: true,
              maxSpeedKilometresPerHour: true,
              speedRules: true,
              parentEvent: true,
            },
            sort: { startTime: 1 },
          },
          headers: getHeaders(),
        }),
        trips: fromAjax('/trips', {
          params: {
            query: {
              ...query,
              hasSpeedInfractions: true,
            },
            projection: {
              identifier: true,
              vehicle: true,
              driver: true,
              startTime: true,
              endTime: true,
              durationSeconds: true,
              distanceKilometres: true,
              maxSpeedKilometresPerHour: true,
              startLocations: true,
              endLocations: true,
              classification: true,
              equipmentActivations: true,
            },
            sort: { startTime: 1 },
          },
          headers: getHeaders(),
        }),
      }).pipe(
        map(
          ({
            infractions: { response },
            trips: { response: tripResponse },
          }) => {
            const mappedSpeedInfractions = (response || []).map(
              (speedInfraction) => {
                const speedLimitBreakdowns = speedInfraction.speedRules
                  ? speedInfraction.speedRules.reduce(
                      (accumulator, rule) => {
                        if (!accumulator.rules.includes(rule.code)) {
                          accumulator.rules.push(rule.code);
                        }

                        rule.speedLimitBreakdown.forEach((breakdown) => {
                          const speedLimitMilesPerHour = breakdown.unknownLimit
                            ? '?'
                            : _.round(
                                breakdown.kilometresPerHour * 0.62137119,
                                0
                              );
                          let current = accumulator.rows.find(
                            (row) =>
                              row.limitMilesPerHour === speedLimitMilesPerHour
                          );

                          if (!current) {
                            current = {
                              limitMilesPerHour: speedLimitMilesPerHour,
                              ruleDurationMinutes: {
                                [rule.code]: breakdown.durationSeconds / 60,
                              },
                              maxSpeedMilesPerHour:
                                breakdown.maxSpeedKilometresPerHour *
                                0.62137119,
                              excessMilesPerHour:
                                speedLimitMilesPerHour === '?'
                                  ? 0
                                  : breakdown.maxSpeedKilometresPerHour *
                                      0.62137119 -
                                    speedLimitMilesPerHour,
                            };

                            accumulator.rows.push(current);
                          } else {
                            let currentRuleDurationMinutes =
                              current.ruleDurationMinutes[rule.code];

                            if (!currentRuleDurationMinutes) {
                              currentRuleDurationMinutes =
                                breakdown.durationSeconds / 60;

                              current.ruleDurationMinutes[
                                rule.code
                              ] = currentRuleDurationMinutes;
                            } else {
                              currentRuleDurationMinutes +=
                                breakdown.durationSeconds / 60;
                            }

                            current.maxSpeedMilesPerHour = Math.max(
                              current.maxSpeedMilesPerHour,
                              breakdown.maxSpeedKilometresPerHour * 0.62137119
                            );

                            current.excessMilesPerHour =
                              speedLimitMilesPerHour === '?'
                                ? 0
                                : breakdown.maxSpeedKilometresPerHour *
                                    0.62137119 -
                                  speedLimitMilesPerHour;
                          }
                        });

                        return accumulator;
                      },
                      {
                        rules: [],
                        rows: [],
                      }
                    )
                  : {
                      rules: ['EXCESS'],
                      rows: [
                        {
                          limitMilesPerHour: '?',
                          ruleDurationMinutes: {
                            EXCESS: speedInfraction.durationSeconds / 60,
                          },
                          maxSpeedMilesPerHour:
                            speedInfraction.maxSpeedKilometresPerHour *
                            0.62137119,
                          excessMilesPerHour: 0,
                        },
                      ],
                    };

                const maxExcessMilesPerHour = Math.max(
                  ...speedLimitBreakdowns.rows.map(
                    (breakdown) => breakdown.excessMilesPerHour
                  )
                );

                const classificationDurationSeconds = []
                  .concat(
                    ...speedInfraction.speedRules.map((rule) =>
                      tripClassifications
                        .filter(
                          (classification) =>
                            !classification.applicableSpeedRules ||
                            classification.applicableSpeedRules.includes(
                              rule.code
                            )
                        )
                        .map((classification) => ({
                          classification: classification.value,
                          durationSeconds: rule.durationSeconds,
                        }))
                    )
                  )
                  .reduce((accumulator, row) => {
                    if (row.classification in accumulator) {
                      accumulator[row.classification] += row.durationSeconds;
                    } else {
                      accumulator[row.classification] = row.durationSeconds;
                    }

                    return accumulator;
                  }, {});

                return {
                  identifier: speedInfraction.identifier,
                  startTime: speedInfraction.startTime,
                  endTime: speedInfraction.endTime,
                  durationMinutes: speedInfraction.durationSeconds / 60,
                  distanceMiles:
                    speedInfraction.distanceKilometres * 0.62137119,
                  maxSpeedMilesPerHour:
                    speedInfraction.maxSpeedKilometresPerHour * 0.62137119,
                  maxExcessMilesPerHour,
                  speedLimitBreakdowns,
                  tripIdentifier:
                    speedInfraction.parentEvent &&
                    speedInfraction.parentEvent.type === 'TRIP'
                      ? speedInfraction.parentEvent.identifier
                      : 'NONE',
                  classificationDurationSeconds,
                };
              }
            );

            const groupedSpeedInfractions = mappedSpeedInfractions.reduce(
              (accumulator, infraction) => {
                if (infraction.tripIdentifier in accumulator) {
                  accumulator[infraction.tripIdentifier].push(infraction);
                } else {
                  accumulator[infraction.tripIdentifier] = [infraction];
                }

                return accumulator;
              },
              {}
            );

            const trips = (tripResponse || [])
              .map((trip) => {
                const startLocation = getPrimaryLocation(trip.startLocations);
                const endLocation = getPrimaryLocation(trip.endLocations);

                const emergencyEquipmentUsed = trip.equipmentActivations
                  ? trip.equipmentActivations.emergencyOn
                  : false;

                const reducedAreas = reduceAreas(trip.vehicle.areas);

                const speedInfractions = (
                  groupedSpeedInfractions[trip.identifier] || []
                ).filter(
                  (infraction) =>
                    !trip.classification ||
                    (infraction.classificationDurationSeconds[
                      trip.classification
                    ] || 0) > (minimumSpeedInfractionSeconds || 45)
                );

                const speedInfractionDurationMinutes = speedInfractions.reduce(
                  (total, infraction) => total + infraction.durationMinutes,
                  0
                );

                const maxExcessMilesPerHour = Math.max(
                  ...speedInfractions.map(
                    (infraction) => infraction.maxExcessMilesPerHour
                  )
                );

                const driver = trip.driver || {};

                return {
                  identifier: trip.identifier,
                  driverCode: driver.code,
                  driverName: driver
                    ? `${driver.forenames} ${driver.surname}`
                    : '',
                  collarNumber: driver.collarNumber,
                  personRole: driver.role,
                  registrationNumber: trip.vehicle.registrationNumber,
                  fleetNumber: trip.vehicle.fleetNumber,
                  role: trip.vehicle.role,
                  type: trip.vehicle.type,
                  homeStation: trip.vehicle.homeStation,
                  areas: reducedAreas, //trip.vehicle.areas,
                  classification: trip.classification || 'None',
                  startTime: trip.startTime,
                  endTime: trip.endTime,
                  durationMinutes: trip.durationSeconds / 60,
                  distanceMiles: trip.distanceKilometres * 0.62137119,
                  maxSpeedMilesPerHour:
                    trip.maxSpeedKilometresPerHour * 0.62137119,
                  startLocationType: startLocation.type,
                  startLocationName: startLocation.name,
                  endLocationType: endLocation.type,
                  endLocationName: endLocation.name,
                  speedInfractions,
                  speedInfractionCount: speedInfractions.length,
                  speedInfractionDurationMinutes,
                  emergencyEquipmentUsed,
                  maxExcessMilesPerHour,
                };
              })
              .filter((trip) => trip.speedInfractionCount > 0);

            log('Read', 'Speed Infractions', query);

            return {
              type: FETCH_SPEED_INFRACTIONS_SUCCESS,
              payload: trips,
            };
          }
        ),
        takeUntil(action$.pipe(ofType(FETCH_SPEED_INFRACTIONS_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_SPEED_INFRACTIONS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchStopsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_STOPS),
    mergeMap(({ payload: query }) =>
      fromAjax('/stops', {
        params: {
          query,
          projection: {
            identifier: true,
            vehicle: true,
            lastDriver: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
            point: true,
            locations: true,
          },
          sort: { startTime: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const stops = (response || []).map((stop) => {

            const location = getPrimaryLocation(stop.locations);

            return {
              identifier: stop.identifier,
              vehicle: stop.vehicle,
              lastDriver: stop.lastDriver,
              registrationNumber: stop.vehicle.registrationNumber,
              fleetNumber: stop.vehicle.fleetNumber,
              vehicleRole: stop.vehicle.role,
              type: stop.vehicle.type,
              startTime: stop.startTime,
              endTime: stop.endTime,
              durationMinutes: stop.durationSeconds / 60,
              point: stop.point,
              homeStation: stop.vehicle.homeStation,
              locationType: location.type,
              locationName: location.name,
            };
          });

          log('Read', 'Vehicle Stops', query);

          return {
            type: FETCH_STOPS_SUCCESS,
            payload: stops,
          };
        }),
        takeUntil(action$.pipe(ofType(FETCH_STOPS_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_STOPS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchIdlesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_IDLES),
    mergeMap(({ payload: query }) =>
      fromAjax('/idles', {
        params: {
          query,
          projection: {
            identifier: true,
            vehicle: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
            point: true,
            locations: true,
          },
          sort: { startTime: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const idles = (response || []).map((idle) => {

            const location = getPrimaryLocation(idle.locations);

            return {
              identifier: idle.identifier,
              registrationNumber: idle.vehicle.registrationNumber,
              fleetNumber: idle.vehicle.fleetNumber,
              role: idle.vehicle.role,
              type: idle.vehicle.type,
              startTime: idle.startTime,
              endTime: idle.endTime,
              durationMinutes: idle.durationSeconds / 60,
              point: idle.point,
              homeStation: idle.vehicle.homeStation,
              locationType: location.type,
              locationName: location.name,
            };
          });

          log('Read', 'Vehicle Idles', query);

          return {
            type: FETCH_IDLES_SUCCESS,
            payload: idles,
          };
        }),
        takeUntil(action$.pipe(ofType(FETCH_IDLES_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_IDLES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchAccelerometerEventsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_ACCELEROMETER_EVENTS),
    mergeMap(({ payload: { time, ...query } }) =>
      forkJoin({
        alerts: fromAjax('/accelerometerAlerts', {
          params: {
            query: {
              time,
              ...query,
            },
            projection: {
              identifier: true,
              vehicle: true,
              driver: true,
              time: true,
              point: true,
            },
            sort: { time: 1 },
          },
          headers: getHeaders(),
        }),
        events: fromAjax('/accelerometerEvents', {
          params: {
            query: {
              startTime: { $lt: time.$lt },
              endTime: { $gt: time.$gte },
              ...query,
            },
            projection: {
              identifier: true,
              vehicle: true,
              maximumForces: true,
              accelerometerData: true,
              path: true,
            },
            sort: { startTime: 1 },
          },
          headers: getHeaders(),
        }),
      }).pipe(
        map(
          ({
            alerts: { response: alertResponse },
            events: { response: eventResponse },
          }) => {
            const events = new Map(
              eventResponse.map(
                ({
                  identifier,
                  vehicle: { identificationNumber },
                  maximumForces: {
                    horizontal: maxHorizontalForce,
                    vertical: maxVerticalForce,
                    lateral: maxLateralForce,
                  },
                  accelerometerData,
                  path,
                }) => [
                  `${identificationNumber}-${accelerometerData[330].time}`,
                  {
                    identifier,
                    maxHorizontalForce,
                    maxVerticalForce,
                    maxLateralForce,
                    data: accelerometerData.map(
                      ({ speedKilometresPerHour, ...entry }) => ({
                        speedMilesPerHour:
                          moment(entry.time).milliseconds() === 0
                            ? _.round(speedKilometresPerHour * 0.62137119, 2)
                            : null,
                        ...entry,
                      })
                    ),
                    path,
                  },
                ]
              )
            );

            const payload = alertResponse.map(
              ({
                identifier,
                vehicle: {
                  identificationNumber,
                  registrationNumber,
                  fleetNumber,
                  role: vehicleRole,
                  type: vehicleType,
                },
                // driver: { code, forenames, surname, collarNumber, role: driverRole },
                time,
                point,
              }) => {
                const { identifier: eventIdentifier, ...event } =
                  events.get(`${identificationNumber}-${time}`) || {};

                // console.log(
                //   `${identificationNumber}-${time}`,
                //   events.get(`${identificationNumber}-${time}`)
                // );

                return {
                  identifier,
                  // driverCode: code,
                  // driverName: `${forenames} ${surname}`,
                  // collarNumber,
                  // driverRole,
                  registrationNumber,
                  fleetNumber,
                  vehicleRole,
                  vehicleType,
                  time,
                  point,
                  hasData: eventIdentifier ? 'Yes' : 'No',
                  ...event,
                };
              }
            );

            log('Read', 'Accelerometer Events', query);

            return { type: FETCH_ACCELEROMETER_EVENTS_SUCCESS, payload };
          }
        ),
        takeUntil(action$.pipe(ofType(FETCH_ACCELEROMETER_EVENTS_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_ACCELEROMETER_EVENTS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchOnBoardDiagnosticsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_ON_BOARD_DIAGNOSTICS),
    mergeMap(({ payload: query }) =>
      fromAjax('/onBoardDiagnostics', {
        params: {
          query,
          projection: {
            identifier: true,
            vehicle: true,
            area: true,
            class: true,
            code: true,
            time: true,
            isConfirmed: true,
            description: true,
          },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const diagnostics = (response || []).map((diagnostic) => {
            return {
              identifier: diagnostic.identifier,
              vehicle: diagnostic.vehicle,
              area: diagnostic.area,
              class: diagnostic.class,
              code: diagnostic.code,
              time: diagnostic.time,
              isConfirmed: diagnostic.isConfirmed ? 'Yes' : 'No',
              description: diagnostic.description,
            };
          });

          log('Read', 'On Board Diagnostics', query);

          return {
            type: FETCH_ON_BOARD_DIAGNOSTICS_SUCCESS,
            payload: diagnostics,
          };
        }),
        takeUntil(action$.pipe(ofType(FETCH_ON_BOARD_DIAGNOSTICS_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_ON_BOARD_DIAGNOSTICS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchPersonTrailsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TRAILS),
    mergeMap(({ payload: query }) =>
      fromAjax('/personTrails', {
        params: {
          query,
          projection: {
            identifier: true,
            person: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
            startLocations: true,
            endLocations: true,
          },
          sort: { startTime: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const trails = (response || []).map((trail) => {
            const startLocation = getPrimaryLocation(trail.startLocations);
            const endLocation = getPrimaryLocation(trail.endLocations);

            return {
              identifier: trail.identifier,
              person: trail.person,
              startTime: trail.startTime,
              endTime: trail.endTime,
              durationMinutes: trail.durationSeconds / 60,
              startLocationType: startLocation.type,
              startLocationName: startLocation.name,
              endLocationType: endLocation.type,
              endLocationName: endLocation.name,
            };
          });

          log('Read', 'Trails', query);

          return {
            type: FETCH_TRAILS_SUCCESS,
            payload: trails,
          };
        }),
        takeUntil(action$.pipe(ofType(FETCH_TRAILS_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TRAILS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchPersonLocationVisitsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON_LOCATION_VISITS),
    mergeMap(({ payload: query }) =>
      fromAjax('/personLocationIntersections', {
        params: {
          query,
          projection: {
            identifier: true,
            person: true,
            location: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
          },
          sort: { startTime: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const visits = (response || []).map((visit) => {
            return {
              identifier: visit.identifier,
              person: visit.person,
              code: visit.person.code,
              name: `${visit.person.forenames} ${visit.person.surname}`,
              collarNumber: visit.person.collarNumber,
              role: visit.person.role,
              startTime: visit.startTime,
              endTime: visit.endTime,
              durationMinutes: visit.durationSeconds / 60,
              locationType: visit.location.type,
              locationName: visit.location.name,
            };
          });

          log('Read', 'Person Location Visits', query);

          return {
            type: FETCH_PERSON_LOCATION_VISITS_SUCCESS,
            payload: visits,
          };
        }),
        takeUntil(action$.pipe(ofType(FETCH_PERSON_LOCATION_VISITS_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PERSON_LOCATION_VISITS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchDoubleCrewsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_DOUBLE_CREWS),
    mergeMap(({ payload: query }) =>
      fromAjax('/personDoubleCrews', {
        params: {
          query: {
            ...query,
            durationSeconds: { $gte: minimumDoubleCrewSeconds || 45 },
          },
          projection: {
            identifier: true,
            people: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
          },
          sort: { startTime: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const doubleCrews = (response || []).map(
            ({
              people,
              durationSeconds,
              startTime,
              endTime,
              multiCrewPeople,
              identifier,
            }) => ({
              identifier,
              primaryCode: people[0].code,
              primaryName: `${people[0].forenames} ${people[0].surname}`,
              primaryCollarNumber: people[0].collarNumber,
              primaryRole: people[0].role,
              secondaryCode: people[1].code,
              secondaryName: `${people[1].forenames} ${people[1].surname}`,
              secondaryCollarNumber: people[1].collarNumber,
              secondaryRole: people[1].role,
              startTime,
              endTime,
              durationMinutes: durationSeconds / 60,
              multiCrewPeople,
            })
          );

          log('Read', 'Double Crews', query);

          return {
            type: FETCH_DOUBLE_CREWS_SUCCESS,
            payload: doubleCrews,
          };
        }),
        takeUntil(action$.pipe(ofType(FETCH_DOUBLE_CREWS_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_DOUBLE_CREWS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchAttendancesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_ATTENDANCES),
    mergeMap(({ payload: query }) =>
      forkJoin({
        attendances: fromAjax('/personObjectiveAttendances', {
          params: {
            query,
            projection: {
              identifier: true,
              startTime: true,
              endTime: true,
              objective: true,
              durationSeconds: true,
              person: true,
            },
          },
          headers: getHeaders(),
        }),
        objectives: fromAjax('/objectives', {
          params: {
            query: {
              startTime: query.startTime,
              endTime: query.endTime,
            },
            projection: {
              identifier: true,
              startTime: true,
              endTime: true,
              days: true,
              hours: true,
            },
          },
          headers: getHeaders(),
        }),
      }).pipe(
        map(
          ({
            attendances: { response: attendanceResponse },
            objectives: { response: objectiveResponse },
          }) => {
            const attendances = (attendanceResponse || []).map((attendance) => {
              const objective = (objectiveResponse || []).find(
                (objective) =>
                  attendance.objective.identifier === objective.identifier
              );

              return {
                ...attendance,
                areas: reduceAreas(attendance.person.areas),
                durationMinutes: attendance.durationSeconds / 60,
                objective: {
                  ...attendance.objective,
                  ...objective,
                },
              };
            });

            return {
              type: FETCH_ATTENDANCES_SUCCESS,
              payload: attendances,
            };
          }
        ),
        takeUntil(action$.pipe(ofType(FETCH_ATTENDANCES_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_ATTENDANCES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchOutagesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_OUTAGES),
    mergeMap(({ payload: query }) =>
      fromAjax('/telematicsBoxOutages', {
        params: {
          query: {
            ...query,
            distanceKilometres: { $gt: 0 },
          },
          projection: {
            identifier: true,
            imei: true,
            vehicle: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
            distanceKilometres: true,
            startLocations: true,
            endLocations: true,
          },
          sort: { startTime: 1 },
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const outages = (response || []).map((outage) => {
            const startLocation = getPrimaryLocation(outage.startLocations);
            const endLocation = getPrimaryLocation(outage.endLocations);

            return {
              identifier: outage.identifier,
              imei: outage.vehicle.telematicsBoxImei,
              registrationNumber: outage.vehicle.registrationNumber,
              fleetNumber: outage.vehicle.fleetNumber,
              role: outage.vehicle.role,
              type: outage.vehicle.type,
              startTime: outage.startTime,
              endTime: outage.endTime,
              durationMinutes: outage.durationSeconds / 60,
              distanceMiles: outage.distanceKilometres * 0.62137119,
              startLocationType: startLocation.type,
              startLocationName: startLocation.name,
              endLocationType: endLocation.type,
              endLocationName: endLocation.name,
            };
          });

          log('Read', 'Telematics Box Outages', query);

          return {
            type: FETCH_OUTAGES_SUCCESS,
            payload: outages,
          };
        }),
        takeUntil(action$.pipe(ofType(FETCH_OUTAGES_CANCELLED))),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_OUTAGES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchTripsForMalfunctionIndicatorLightEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT),
    mergeMap(({ payload: { time, ...query } }) =>
      forkJoin({
        diagnostics: fromAjax('/onBoardDiagnostics', {
          params: {
            query: {
              time,
              ...query,
            },
            projection: {
              identifier: true,
              time: true,
              area: true,
              class: true,
              code: true,
              isConfirmed: true,
              vehicle: true,
            },
            sort: { time: 1 },
          },
          headers: getHeaders(),
        }),
        trips: fromAjax('/trips', {
          params: {
            query: {
              startTime: { $lt: time.$lt },
              endTime: { $gt: time.$gte },
              ...query,
              hasMalfunctionIndicatorLightOn: true,
            },
            projection: {
              identifier: true,
              vehicle: true,
              driver: true,
              startTime: true,
              endTime: true,
              malfunctionIndicatorLightOnTime: true,
            },
            sort: { startTime: 1 },
          },
          headers: getHeaders(),
        }),
      }).pipe(
        map(
          ({
            diagnostics: { response },
            trips: { response: tripResponse },
          }) => {
            const mappedDiagnostics = (response || []).map((diagnostic) => {
              return {
                identifier: diagnostic.identifier,
                vehicle: diagnostic.vehicle,
                area: diagnostic.area,
                class: diagnostic.class,
                code: diagnostic.code,
                time: diagnostic.time,
                isConfirmed: diagnostic.isConfirmed ? 'Yes' : 'No',
                description: diagnostic.description,
              };
            });

            const trips = (tripResponse || []).map((trip) => {
              const tripStartTime = moment(trip.startTime);
              const tripEndTime = moment(trip.endTime);
              return {
                identifier: trip.identifier,
                registrationNumber: trip.vehicle.registrationNumber,
                fleetNumber: trip.vehicle.fleetNumber,
                role: trip.vehicle.role,
                type: trip.vehicle.type,
                startTime: trip.startTime,
                endTime: trip.endTime,
                malfunctionIndicatorLightOnTime:
                  trip.malfunctionIndicatorLightOnTime,
                diagnostics: mappedDiagnostics.filter(
                  (diagnostic) =>
                    diagnostic.vehicle.registrationNumber ===
                      trip.vehicle.registrationNumber &&
                    moment(diagnostic.time).isBetween(
                      tripStartTime,
                      tripEndTime
                    )
                ),
              };
            });

            log('Read', 'trips', query);

            return {
              type: FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_SUCCESS,
              payload: trips,
            };
          }
        ),
        takeUntil(
          action$.pipe(
            ofType(FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED)
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
