import {
  Button,
  Paper,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Form } from 'react-final-form';
import _ from 'lodash';
import { useAuth } from '../Auth';
import {
  CREATE_PERSON,
  DELETE_PERSON,
  FETCH_HOME_STATIONS,
  FETCH_POST_HIERARCHY,
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  UPDATE_PERSON,
} from '../../actions';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  AvatarField,
  CheckboxField,
  TextField,
  SelectField,
  TypesField,
  required,
  ChipField,
} from '../fields';
import { doesIdExist, getRfidErrors } from '../../apis/utilities';
import { RouteLeavingGuard } from '../controls';

const {
  personGroups,
  personEditableFields,
  useDallasKeys,
  useRestricted,
} = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  header: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  delete: {
    color: theme.palette.error.main,
  },
  skills: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  supervisorChip: {
    marginRight: theme.spacing(1),
  },
}));

export default function ReducedPerson() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const person = useSelector((state) => state.people.person, _.isEqual);
  const homeStationNames = useSelector(
    (state) => state.locations.homeStationNames,
    _.isEqual
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const auth = useAuth();
  const editable = auth.isAuthorised('people', true);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_PERSON_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_PERSON,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
    dispatch({
      type: FETCH_POST_HIERARCHY,
    });
  }, [id, dispatch]);

  async function onSubmit(values) {
    const rfidErrors = await getRfidErrors(values.code, values.rfidCards);

    if (rfidErrors) {
      return { rfidCards: rfidErrors };
    }

    if (person) {
      dispatch({
        type: UPDATE_PERSON,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('people', values.code);
      if (exists) {
        return { code: 'Exists' };
      }

      dispatch({
        type: CREATE_PERSON,
        payload: values,
      });
    }
  }

  function handleDelete() {
    if (person) {
      dispatch({
        type: DELETE_PERSON,
        payload: person.code,
      });
    }
  }

  function isDisabled(fieldName) {
    return !(editable && (personEditableFields[fieldName] || person === null));
  }

  return (
    <Form
      initialValues={person || {}}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>IR3 | Person{values.code ? ` | ${values.code}` : ''}</title>
          </Helmet>
          <Paper className={classes.card}>
            <CardHeader
              avatar={
                <Field
                  name="picture"
                  component={AvatarField}
                  icon={<PersonIcon />}
                  disabled={isDisabled('picture')}
                />
              }
              title={values.code}
              subheader={values.emailAddress}
            />
            <CardContent className={classes.cardContent}>
              <List>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="code"
                      component={TextField}
                      label="Staff ID"
                      className={classes.textField}
                      validate={required}
                      disabled={!(editable && person === null)}
                    />
                    <Field
                      name="emailAddress"
                      type="email"
                      component={TextField}
                      label="AD Username"
                      className={classes.textField}
                      disabled={isDisabled('emailAddress')}
                    />
                    {useRestricted && (
                      <Field
                        name="restricted"
                        component={CheckboxField}
                        label="Restricted"
                        className={classes.textField}
                        disabled={isDisabled('restricted')}
                      />
                    )}
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Supervisors</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    {(values.supervisorCodes || []).map((code) => (
                      <Chip
                        key={code}
                        label={code}
                        className={classes.supervisorChip}
                      />
                    ))}
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Home</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="homeStation"
                      component={SelectField}
                      label="Location"
                      values={homeStationNames.map((station) => ({
                        label: station.name,
                        value: station.code,
                      }))}
                      className={classes.textField}
                      disabled={isDisabled('homeStation')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Groups & Areas</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="areas"
                      types={personGroups}
                      component={TypesField}
                      disabled={isDisabled('areas')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>
                  {useDallasKeys ? 'Dallas Keys' : 'RFID Cards'}
                </ListSubheader>
                <ListItem>
                  <div className={classes.skills}>
                    <Field
                      name={'rfidCards'}
                      component={ChipField}
                      defaultCategory="Business"
                      fullWidth
                      disabled={isDisabled('rfidCards')}
                    />
                  </div>
                </ListItem>
              </List>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                className={classes.delete}
                onClick={() => setDeleteOpen(true)}
                disabled={person === null}
              >
                Delete
              </Button>
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.code}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
          <RouteLeavingGuard when={dirty} />
        </form>
      )}
    />
  );
}
