import { Divider } from '@material-ui/core';
import { List, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
  INVALIDATE_FEATURE_COLLECTION,
  UPDATE_LIVE_FOLLOWED,
  UPDATE_LIVE_FOLLOW_OVERRIDE,
  UPDATE_LIVE_FILTER_OVERRIDE,
  FETCH_TAGS,
} from '../../../actions/types';
import classnames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { log } from '../../../apis/utilities';
import { usePrevious } from '../../../hooks';
import { FixedSizeList as VirtualList } from 'react-window';
// import VirtualList from './AnimatedList';
import EventItem from './EventLiveItem';
import EventListItem from './EventLiveListItem';
import FilterControl from './FilterControl';
import IncidentItem from './IncidentLiveItem';
import IncidentListItem from './IncidentLiveListItem';
import LocationItem from './LocationLiveItem';
import LocationListItem from './LocationLiveListItem';
import ObjectiveItem from './ObjectiveLiveItem';
import ObjectiveListItem from './ObjectiveLiveListItem';
import PeopleListItem from './PeopleLiveListItem';
import PersonItem from './PersonLiveItem';
import PlanItem from './PlanLiveItem';
import PlanListItem from './PlanLiveListItem';
import TaskItem from './TaskLiveItem';
import TaskListItem from './TaskLiveListItem';
import VehicleItem from './VehicleLiveItem';
import VehicleListItem from './VehicleLiveListItem';
import TagDetail from './TagDetail';
import TagListItem from './TagListItem';
import CallSignLiveListItem from './CallSignLiveListItem';
import CallSignDetail from './CallSignDetail';

const useStyles = makeStyles((theme) => {
  theme.overrides = {
    ...theme.overrides,
    MuiBadge: {
      anchorOriginTopRightRectangle: {
        top: 0,
        right: 0,
        transform: 'scale(1) translate(50%, -40%)', // slightly lower so same height for list items
        transformOrigin: '100% 0%',
      },
    },
  };

  /*
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
  */

  return {
    card: {
      margin: theme.spacing(1, 1, 1, 1),
    },
    root: {
      height: '100%',
      display: 'flex',
    },
    content: {
      paddingTop: 48,
      width: '100%',
      height: 'calc(100vh - 48px)',
      [theme.breakpoints.up('xs')]: {
        paddingTop: 0,
        height: 'calc(100vh - 200px)',
        minHeight: 'calc(100vh - 350px)',
      },
    },
    listSection: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      overflow: 'hidden',
      [theme.breakpoints.up('xs')]: {
        height: 'calc(100vh - 49px)',
      },
    },
    list: {
      padding: 0,
      // height: '100%',
      flex: '1 1 auto',
      [theme.breakpoints.up('xs')]: {
        // height: 'calc(100vh - 120px)',
        marginTop: 1,
      },
    },
    // passed to items via classes
    table: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    highlightListItem: {
      backgroundColor: theme.palette.divider,
    },
    statusIcon: {
      fontSize: 16,
      verticalAlign: 'text-top',
      marginRight: theme.spacing(0.5),
    },
    statusIconLarge: {
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
    },
    // textField: {
    //   width: 180
    // },
    subAvatar: {
      width: 30,
      height: 30,
      fontSize: 12,
      backgroundColor: theme.palette.grey[500],
      //marginLeft: theme.spacing(1),
    },
    temporary: {
      width: 12,
      height: 12,
      left: 24,
      top: 24,
      position: 'absolute',
    },
    timelineIcon: {
      fontSize: 16,
    },
    subList: {
      marginLeft: theme.spacing(3),
    },
    cardText: {
      color: grey[800],
    },
    timeContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: theme.spacing(2),
    },
    timeWrapper: {
      flex: '0 0 8.3%',
      margin: theme.spacing(1, 0, 2, 0),
    },
    selectedTime: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      width: 24,
      height: 24,
      fontSize: 12,
    },
    unselectedTime: {
      color: theme.palette.primary.contrastText,
      width: 24,
      height: 24,
      fontSize: 12,
    },
    filterControl: {
      maxHeight: '50%',
    },
    contentViewer: {
      margin: theme.spacing(1, 1, 1, 3),
    },
    tertiaryValueRightAligned: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      flex: 1,
      textAlign: 'right',
      fontSize: 12,
      marginLeft: theme.spacing(2),
    },
    tertiaryValue: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      flex: 1,
      // textAlign: 'right',
      fontSize: 12,
      // marginLeft: theme.spacing(2)
    },
  };
});

const types = {
  vehicles: {
    label: 'Vehicles',
    listItemComponent: VehicleListItem,
    itemComponent: VehicleItem,
  },
  locations: {
    label: 'Locations',
    listItemComponent: LocationListItem,
    itemComponent: LocationItem,
  },
  people: {
    label: 'People',
    listItemComponent: PeopleListItem,
    itemComponent: PersonItem,
  },
  events: {
    label: 'Events',
    listItemComponent: EventListItem,
    itemComponent: EventItem,
  },
  incidents: {
    label: 'Incidents',
    listItemComponent: IncidentListItem,
    itemComponent: IncidentItem,
  },
  tasks: {
    label: 'Tasks',
    listItemComponent: TaskListItem,
    itemComponent: TaskItem,
  },
  plans: {
    label: 'Plans',
    listItemComponent: PlanListItem,
    itemComponent: PlanItem,
  },
  objectives: {
    label: 'Objectives',
    listItemComponent: ObjectiveListItem,
    itemComponent: ObjectiveItem,
  },
  tags: {
    label: 'Tags',
    listItemComponent: TagListItem,
    itemComponent: TagDetail,
  },
  callSigns: {
    label: 'Call Signs',
    listItemComponent: CallSignLiveListItem,
    itemComponent: CallSignDetail,
  },
};

// just for testing
// const renderRowItem = (item, handleClick, style) => {
//   console.count("render item");
//   return <div onClick={handleClick} style={style}>{item.id}</div>;
// };

const Row = ({ data, index, style }) => {
  const {
    finalList,
    handleClick,
    classes,
    hoveredItem,
    selectedItem,
    handleHoverItem,
    handleFollowToggle,
    handleFollowBulk,
    type,
    followedIdsByType,
    sort,
  } = data;

  const item = finalList[index];
  const SpecificListItemComponent = types[type].listItemComponent;

  // refactoring to reduce duplication
  // - in the original, PlanListItem didn't have onClick={selectItem}
  return useMemo(
    () =>
      item &&
      // for testing renderRowItem(item, () => {}, style)
      SpecificListItemComponent && (
        <SpecificListItemComponent
          style={style}
          onClick={handleClick}
          classes={classes}
          // key={key}
          item={item}
          highlighted={
            item.id === hoveredItem.id || item.id === selectedItem.id
          }
          onHoverItem={handleHoverItem}
          onFollowToggle={handleFollowToggle}
          onFollowBulk={handleFollowBulk}
          followedIdsByType={followedIdsByType}
          tertiaryPath={
            window.config.liveOptions?.showSortFieldInListItems
              ? sort?.path
              : undefined
          }
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      classes,
      followedIdsByType,
      hoveredItem.id,
      item,
      selectedItem.id,
      style,
      sort,
    ]
  );
};

export default function LiveList(props) {
  // console.log('live list render');
  // get id and type from props (defaulting type to 'vehicles' if not there)
  const { scrollToItem, onSelect, hoveredItem, selectedItem } = props;

  const { type = 'vehicles', id: encodedId } = useParams();
  const id = encodedId && decodeURIComponent(encodedId);
  // used to see when changes occur for incident filtering
  const prevType = usePrevious(type);
  const prevId = usePrevious(id);

  const allItemsById = useSelector((state) => state.live[type]);

  const filteredInIdsByTypeOverride = useSelector(
    (state) => state.live.filteredInIdsByTypeOverride
  );
  const filteredInIdsByType = useSelector(
    (state) =>
      state.live.filteredInIdsByTypeOverride || state.live.filteredInIdsByType
  );
  const filteredInIds = filteredInIdsByType[type];

  const followedIdsByTypeOverride = useSelector(
    (state) => state.live.followedIdsByTypeOverride
  );
  const followedIdsByType = useSelector(
    (state) =>
      state.live.followedIdsByTypeOverride || state.live.followedIdsByType
  );

  const filters = useSelector((state) => state.live.filters);
  const [showFilter, setShowFilter] = useState(false);
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const [listsForType, setlistsForType] = useState({
    baseList: [],
    filteredSortedList: [],
    type,
  });

  const sorts = useSelector((state) => state.live.sorts);

  const prevScrollToItemId = usePrevious(scrollToItem.id);

  const layerVisibilities = useSelector(
    (state) => state.live.layerVisibilities
  );
  const STALE = 'stale';
  const showStale = !(STALE in layerVisibilities) || layerVisibilities[STALE];

  // get the final sorted, filtered resource list
  // only when sorting, filtering or resources change
  const prevAllItemsById = usePrevious(allItemsById);
  const prevSorts = usePrevious(sorts);
  const prevFilteredInIds = usePrevious(filteredInIds);
  const prevShowStale = usePrevious(showStale);
  useEffect(() => {
    if (
      prevAllItemsById !== allItemsById ||
      prevSorts !== sorts ||
      prevFilteredInIds !== filteredInIds ||
      prevType !== type ||
      prevShowStale !== showStale
    ) {
      const filterStaleIfHidden = (i) => i && (showStale || !i.stale);

      const baseList = Object.values(allItemsById).filter(filterStaleIfHidden);

      // are there filtered in ids for this type
      const filteredList = filteredInIds
        ? Object.keys(filteredInIds)
            .map((id) => allItemsById[id])
            .filter(filterStaleIfHidden)
        : baseList;

      const sort = (sorts && sorts[type]) || { path: 'id', desc: false };
      // put elements with undefined sort.path at the end of the list
      const lowestChar = 0;
      const highestChar = 0xffff;
      const unsortedChar = String.fromCharCode(
        sort.desc ? lowestChar : highestChar
      );
      const topChar = String.fromCharCode(sort.desc ? highestChar : lowestChar);

      function getSortValue(o) {
        const value = _.get(o, sort.path);
        if (typeof value !== 'undefined') {
          return (typeof value === 'string') ? value.toLowerCase() : value;
        }
        return unsortedChar;
      }

      function getTagSortValue(tag) {
        return tag.type === 'follow' ? topChar : getSortValue(tag);
      }

      const getSortValueFunction =
        type === 'tags' ? getTagSortValue : getSortValue;

      const filteredSortedList = _.orderBy(
        filteredList,
        [getSortValueFunction, 'id'],
        [sort.desc ? 'desc' : 'asc', 'asc']
      );

      setlistsForType({
        baseList,
        filteredSortedList,
        type,
      });
    }
  }, [
    filteredInIds,
    sorts,
    allItemsById,
    prevFilteredInIds,
    prevSorts,
    prevAllItemsById,
    prevShowStale,
    showStale,
    prevType,
    type,
  ]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const showList = id == null;

  // if there was a filter override and we nav away wipe it
  useEffect(() => {
    if (
      filteredInIdsByTypeOverride &&
      !filteredInIdsByTypeOverride[type]?.[id]
    ) {
      dispatch({
        type: UPDATE_LIVE_FILTER_OVERRIDE,
        payload: null,
      });
    }
  }, [dispatch, filteredInIdsByTypeOverride, type, id]);

  // if there was a follow override and we nav away wipe it
  useEffect(() => {
    if (followedIdsByTypeOverride && !followedIdsByTypeOverride[type]?.[id]) {
      dispatch({
        type: UPDATE_LIVE_FOLLOW_OVERRIDE,
        payload: null,
      });
    }
  }, [dispatch, followedIdsByTypeOverride, type, id]);

  // if a plan was selected previously and we nav away, wipe the plan features
  useEffect(() => {
    if (
      prevType === 'plans' &&
      (type !== prevType || (!id && !id !== prevId))
    ) {
      dispatch({
        type: INVALIDATE_FEATURE_COLLECTION,
        payload: null,
      });
    }
  });

  const handleFilterToggle = () => {
    setShowFilter(!showFilter);
  };

  const handleFollowBulk = useCallback(
    (resourceIdsByType, follow = undefined) => {
      const newFollowedIdsByType = { ...followedIdsByType };
      let updateNeeded = false;

      Object.keys(resourceIdsByType).forEach((type) => {
        // if we haven't followed anything of this type before create a new dict
        if (!(type in newFollowedIdsByType)) {
          newFollowedIdsByType[type] = {};
        }

        Object.keys(resourceIdsByType[type]).forEach((id) => {
          // if this item wasn't followed already add it and follow, else remove it from the dict
          const alreadyFollowed = !!followedIdsByType[type]?.[id];
          const changeNeeded =
            typeof follow === 'undefined' || follow !== alreadyFollowed;
          if (changeNeeded) {
            updateNeeded = true;
            if (!alreadyFollowed) {
              newFollowedIdsByType[type][id] = true;
            } else {
              delete newFollowedIdsByType[type][id];
            }

            log(alreadyFollowed ? 'Unfollow' : 'Follow', 'Live Resource', {
              type,
              id,
            });
          }
        });
      });

      if (updateNeeded) {
        // update state so map can follow too
        dispatch({ type: UPDATE_LIVE_FOLLOWED, payload: newFollowedIdsByType });
      }
    },
    [followedIdsByType, dispatch]
  );

  const handleFollowToggle = useCallback(
    (type, id, follow = undefined) => {
      handleFollowBulk({ [type]: { [id]: true } }, follow);
    },
    [handleFollowBulk]
  );

  const handleHoverItem = (e) => {
    props.onHover(e);
  };

  const handleClick = useCallback((item) => onSelect(item), [onSelect]);

  // Only scrollToItem if it changes, not if fullResourceList or finalList change
  // nevermind:eslint-disable-next-line react-hooks/exhaustive-deps

  // if the scrollToItem changes, get the index and scroll the list to the item
  useEffect(() => {
    if (scrollToItem.id && scrollToItem.id !== prevScrollToItemId) {
      // console.log("scroll to new id");
      const item = allItemsById[scrollToItem.id];
      if (item) {
        const index = listsForType.filteredSortedList?.indexOf(item);
        setScrollToIndex(index > 0 ? index : 0);
      }
    }
  }, [scrollToItem, listsForType, allItemsById, prevScrollToItemId]);

  const easeInOut = (t) => {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  };

  useEffect(() => {
    dispatch({
      type: FETCH_TAGS,
    });
  }, [id, dispatch]);

  const renderList = () => {
    // console.log("renderList");
    return (
      listsForType?.filteredSortedList &&
      listsForType.filteredSortedList.length > 0 && (
        <List dense className={classnames(classes.list)}>
          <AutoSizer>
            {({ width, height }) => (
              <VirtualList
                style={{ outline: 'none' }}
                //ref="List" // not sure what this was for
                duration={1500}
                easing={easeInOut}
                width={width}
                height={height}
                overscanCount={10}
                itemCount={listsForType.filteredSortedList.length}
                itemSize={56}
                // rowRenderer={renderRow}
                // scrollToIndex={this.state.scrollToIndex} // old way of going to index
                scrollToRow={scrollToIndex}
                scrollToAlignment="start"
                onAnimationComplete={() =>
                  scrollToIndex === -1 || setScrollToIndex(-1)
                }
                itemData={{
                  finalList: listsForType.filteredSortedList,
                  handleClick,
                  classes,
                  hoveredItem,
                  selectedItem,
                  handleHoverItem,
                  handleFollowToggle,
                  handleFollowBulk,
                  type: listsForType.type || type,
                  followedIdsByType,
                  sort: sorts[type],
                }}
              >
                {Row}
              </VirtualList>
            )}
          </AutoSizer>
        </List>
      )
    );
  };

  const renderItem = () => {
    const item = allItemsById[id];
    // the list can lag a little as it does a setState after filtering & sorting
    const listHasCorrectType = (listsForType?.type || type) === type;
    if ((!item && type !== 'incidents') || !listHasCorrectType) {
      return;
    }

    const SpecificItemComponent = types[type].itemComponent;

    return (
      SpecificItemComponent && (
        <SpecificItemComponent
          classes={classes}
          item={item || { id }}
          hoveredId={hoveredItem.id || selectedItem.id}
          onFollowToggle={handleFollowToggle}
          onFollowBulk={handleFollowBulk}
          followedIdsByType={followedIdsByType}
          //following={followedIdsByType?.[type]?.[item.id]}
          onSubItemClick={handleClick}
          onSubItemHover={handleHoverItem}
        />
      )
    );
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classnames(classes.content)}>
          <div
            className={classnames(classes.listSection)}
            style={{
              display: showList ? 'flex' : 'none',
            }}
          >
            <FilterControl
              className={classes.filterControl}
              showFilter={showFilter}
              filters={filters[type]}
              fullList={listsForType.baseList}
              filteredListLength={listsForType.filteredSortedList.length}
              type={type}
              onFilterToggle={handleFilterToggle}
            />
            <Divider></Divider>
            {renderList()}
          </div>
          <div
            className={classes.listSection}
            style={{ display: showList ? 'none' : 'flex' }}
          >
            {id && <div style={{ overflow: 'auto' }}>{renderItem()}</div>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
