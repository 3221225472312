import React from 'react';
import LiveListItem from './LiveListItem';

function LocationLiveListItem(props) {
  return (
    <LiveListItem
      type="locations"
      primaryPath="name"
      secondaryPath="id"
      hideFollow={!props.item.boundary}
      {...props}
    />
  );
}

export default LocationLiveListItem;
