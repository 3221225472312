import { Snackbar, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import { StrictMode } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SideNavProvider } from './SideNav';
import { SnackbarProvider } from './Snackbar';
import { useOptions } from './options';
import { useAuth } from './Auth';
import Maintenance from './Maintenance';
import { log } from '../apis/utilities';
import { routes, isAllowed } from '../routing';

const { autoLogin, promptText, maintenance } = window.config;

export default function App() {
  const auth = useAuth();
  const [accepted, setAccepted] = useState(localStorage.getItem('accepted'));
  const fetch = useOptions().fetch;

  useEffect(() => {
    auth.setupRefresh();
  }, [auth]);

  useEffect(() => {
    if (autoLogin && !auth.isAuthenticated()) {
      auth.login();
    }
  }, [auth]);

  useEffect(() => {
    if (auth.isAuthenticated()) {
      fetch();
    }
  }, [auth, fetch]);

  function handleAccept() {
    localStorage.setItem('accepted', true);
    setAccepted(true);

    log('Accept', 'Prompt');
  }

  if (maintenance) {
    return <Maintenance />;
  }

  return (
    // <StrictMode>
    <SnackbarProvider>
      <SideNavProvider>
        <Switch>
          {routes
            .filter(({ key }) => isAllowed(key, auth))
            .map(({ key, path, exact, Component }) => (
              <Route
                key={key}
                exact={exact}
                path={path}
                component={Component}
              />
              // <Route key={key} exact={exact} path={path}>
              //   <Component />
              // </Route>
            ))}
        </Switch>
        {auth.isAuthenticated() && promptText && (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={!accepted}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{promptText}</span>}
            action={
              <Button color="secondary" size="small" onClick={handleAccept}>
                ACCEPT
              </Button>
            }
          />
        )}
      </SideNavProvider>
    </SnackbarProvider>
    // </StrictMode>
  );
}
