import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  FETCH_REPLAY,
  FETCH_REPLAY_SUCCESS,
  FETCH_REPLAY_FAILURE,
} from '../actions';
import api from '../apis';
import { getHeaders, log } from '../apis/utilities';

const groupTypes = new Map([
  ['vehicle', 'vehicles'],
  ['location', 'locations'],
  ['event', 'events'],
  ['incident', 'incidents'],
  ['person', 'people'],
  ['objective', 'objectives'],
]);

function getInitialFeature(initialState) {
  const {
    stateKey,
    point,
    position,
    boundary,
    stateType,
    ...properties
  } = initialState;
  return {
    type: 'Feature',
    id: stateKey,
    geometry: point || position || boundary,
    properties: { ...properties, type: stateType },
  };
}

function getPaths(initialStates, stateChanges, type) {
  return initialStates
    .filter((state) => type === state.stateType)
    .map((state) => {
      const initialFeature = getInitialFeature(state);
      return {
        id: initialFeature.id,
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            initialFeature.geometry.coordinates,
            ...stateChanges
              .filter(
                (change) =>
                  change.stateKey === initialFeature.id && change.position
              )
              .map((change) => change.position.coordinates),
          ],
        },
      };
    });
}

async function fetchReplayRequest(type, id) {
  const response = await api.get(`/replay/${type}/${id}`, {
    headers: getHeaders(),
  });

  const { initialStates, stateChanges } = response.data;

  const replay = Array.from(
    new Set(stateChanges.map((stateChange) => stateChange.startTime).sort())
  ).reduce(
    (replay, time) => {
      const frames = replay.frames.concat(
        stateChanges
          .filter(
            (stateChange) =>
              stateChange.startTime <= time && time < stateChange.endTime
          )
          .reduce(
            (frame, stateChange) => {
              const {
                stateKey,
                stateType,
                changeIndex,
                startTime,
                endTime,
                point,
                position,
                boundary,
                ...properties
              } = stateChange;
              const groupType = groupTypes.get(stateChange.stateType);

              if (!(groupType in frame.featureCollections)) {
                frame.featureCollections[groupType] = {
                  type: 'FeatureCollection',
                  features: [],
                };
              }

              const index = frame.featureCollections[
                groupType
              ].features.findIndex((feature) => feature.id === stateKey);

              if (index === -1) {
                const initialState = initialStates.find(
                  (state) => state.stateKey === stateKey
                );
                const initialFeature = getInitialFeature(initialState);
                frame.featureCollections[
                  groupType
                ].features = frame.featureCollections[
                  groupType
                ].features.concat({
                  ...initialFeature,
                  geometry:
                    point || position || boundary || initialFeature.geometry,
                  properties: {
                    ...initialFeature.properties,
                    ...properties,
                  },
                });
              } else {
                const existingFeature =
                  frame.featureCollections[groupType].features[index];
                frame.featureCollections[
                  groupType
                ].features = frame.featureCollections[groupType].features
                  .slice(0, index)
                  .concat({
                    ...existingFeature,
                    geometry:
                      point || position || boundary || existingFeature.geometry,
                    properties: {
                      ...existingFeature.properties,
                      ...properties,
                    },
                  })
                  .concat(
                    frame.featureCollections[groupType].features.slice(
                      index + 1
                    )
                  );
              }

              return frame;
            },
            {
              time,
              featureCollections: {},
            }
          )
      );

      const vehiclePaths = getPaths(initialStates, stateChanges, 'vehicle');
      const personPaths = getPaths(initialStates, stateChanges, 'person');
      let paths = {};
      if (vehiclePaths.length > 0) {
        paths.vehicles = {
          type: 'FeatureCollection',
          features: vehiclePaths,
        };
      }
      if (personPaths.length > 0) {
        paths.people = {
          type: 'FeatureCollection',
          features: personPaths,
        };
      }

      return {
        ...replay,
        frames,
        paths,
      };
    },
    {
      id,
      frames: [],
      paths: {
        vehicles: {
          type: 'FeatureCollection',
          features: [],
        },
        people: {
          type: 'FeatureCollection',
          features: [],
        },
      },
    }
  );

  log('View', 'Replay', { id, type });

  return replay;
}

export function fetchReplayEpic(action$) {
  return action$.pipe(
    ofType(FETCH_REPLAY),
    mergeMap(({ payload: { type, id } }) =>
      from(fetchReplayRequest(type, id)).pipe(
        map((payload) => ({
          type: FETCH_REPLAY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_REPLAY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
