import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { Field, DebouncedTextField } from '../fields';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
  },
  content: {
    paddingTop: theme.spacing(2),
  },
}));

export default function SaveDialog({ onClose, onSave, ...other }) {
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} {...other}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography variant="h6" color="inherit">
            Save As
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <DialogContentText>
          Please give this retrospective a name
        </DialogContentText>
        <Field
          autoFocus
          margin="dense"
          fullWidth
          label="Title"
          name="title"
          component={DebouncedTextField}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
