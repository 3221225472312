import React from 'react';
import {
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Collapse,
  Badge,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { List as DefaultIcon } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import _ from 'lodash';
import moment from 'moment';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { liveIconsByTypeAndSubtype } from '../../data/constants';
import { pluralToSingularTypeMap } from './constants';
import { liveFilters } from '../../data/constants';
import { filterFullyDefined } from './utilities';

const {
  liveOptions: { overriddenFilterLabels = {} },
} = window.config;

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: '.725 em',
  },
  nestedMore: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  badgeShift: {
    transform: 'translate(100%, -40%)',
  },
  emptyBadge: {
    backgroundColor: grey[700],
    color: grey[100],
  },
}));

function ExpandableListItem({ icon, primary, className, disabled, children }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        className={className}
        button
        onClick={handleClick}
        disabled={disabled}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText disableTypography primary={primary} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}

const conditions = {
  $eq: '=',
  $ne: String.fromCharCode(8800), // so I can still debug in Chrome
  $gt: '>',
  $gte: String.fromCharCode(8805),
  $lt: '<',
  $lte: String.fromCharCode(8804),
};

export default function FilterSummary({ filters, hideEmpty = true }) {
  const classes = useStyles();

  // a filter definition may have mappings so true=>On or REG1234 doesn't become REG 1234
  function optionToString(definition, option) {
    return (
      definition?.mapOption?.(option) ||
      (definition?.options &&
        Object.values(definition.options).find((v) => v.value === option)
          ?.label) ||
      option
    );
  }

  let filterTypesToShow = Object.keys(filters);

  if (hideEmpty) {
    const filtersWithSomeDefined = Object.keys(filters).filter((type) =>
      filters[type].some(filterFullyDefined)
    );

    if (filtersWithSomeDefined.length === 0) {
      return (
        <List dense>
          <ListItem>
            <Typography>Empty</Typography>
          </ListItem>
        </List>
      );
    }

    filterTypesToShow = filtersWithSomeDefined;
  }

  return (
    <List dense>
      {filterTypesToShow.map((type) => {
        const dict = liveIconsByTypeAndSubtype;
        const icon = dict[pluralToSingularTypeMap[type] || type]?.default || (
          <DefaultIcon />
        );
        const fullyDefinedFilters = (filters[type] || []).filter(
          filterFullyDefined
        );
        const filterDefinitions = liveFilters[type];
        const count = fullyDefinedFilters.length;
        const badgedIcon = (
          <Badge
            badgeContent={`${count}`}
            color="primary"
            classes={{ badge: !count && classes.emptyBadge }}
          >
            {icon}
          </Badge>
        );

        return (
          <ExpandableListItem
            key={type}
            icon={badgedIcon}
            primary={_.startCase(type)}
            disabled={!count}
          >
            <List component="div" disablePadding>
              {fullyDefinedFilters.map((s, i) => {
                const filterValueIsArray = Array.isArray(s.value);
                let nameOverride = undefined;
                const definition =
                  filterDefinitions &&
                  Object.entries(filterDefinitions).find(([k, v]) => {
                    // some fields are in the form e.g. reducedAreas.bcu because they
                    // are a dynamic dictionary. In this case return the filter for
                    // the other properties but override the name
                    const [main, sub] = s.field.split('.');
                    if (sub && v.isDictionary && v.value.startsWith(main)) {
                      nameOverride =
                        overriddenFilterLabels[sub] || _.startCase(sub);
                      return true;
                    }

                    return s.field === k || s.field === v.value;
                  })?.[1]; // get the value not the [key, value]

                let humanOption = '';
                if (filterValueIsArray) {
                  humanOption = s.value
                    .map((option) => optionToString(definition, option))
                    .join(', ')
                    .replace(/, ([^,]*)$/, ' or $1');
                } else if (definition?.type === 'miles') {
                  humanOption = `${_.round(s.value * 0.62137119, 2)} mph`;
                } else if (definition?.type === 'date') {
                  humanOption = moment(s.value).format('YYYY/MM/DD HH:mm:ss');
                } else {
                  humanOption = optionToString(definition, s.value);
                }

                const name =
                  nameOverride ||
                  definition?.name ||
                  overriddenFilterLabels[s.field] ||
                  _.startCase(s.field);
                const key = `${name} ${i}`;
                return filterValueIsArray && s.value.length > 3 ? (
                  <ExpandableListItem
                    key={key}
                    primary={
                      <Badge
                        badgeContent={s.value.length}
                        color="secondary"
                        classes={{
                          badge: classes.badgeShift,
                        }}
                      >
                        <Typography variant="body2">{name}</Typography>
                      </Badge>
                    }
                    className={classes.nested}
                  >
                    <div className={classes.nestedMore}>{humanOption}</div>
                  </ExpandableListItem>
                ) : (
                  <ListItem key={key} className={classes.nested}>
                    <ListItemText
                      disableTypography
                      primary={`${name} ${
                        conditions[s.condition] || ':'
                      } ${humanOption}`}
                      title={humanOption}
                    />
                  </ListItem>
                );
              })}
            </List>
          </ExpandableListItem>
        );
      })}
    </List>
  );
}
