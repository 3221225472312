import rewind from '@mapbox/geojson-rewind';
import { format } from 'date-fns';
import humanizeDuration from 'humanize-duration';
import _ from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';
import api from '.';

const {
  dioStates,
  useReducedResourceInformation,
  useDallasKeys,
  useRestricted,
  options,
} = window.config;

export const urlInvalidCharactersRegex = /[\^%/\\]+/;

export function getHeaders() {
  return {
    'Cache-Control': 'No-Cache, No-Store, Must-Revalidate',
    Pragma: 'no-cache',
    Expires: 0,
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  };
}

export async function doesIdExist(type, id) {
  const response = await api.get(`/${type}`, {
    params: { query: { _id: id } },
    headers: getHeaders(),
  });

  if (response.data.length) {
    return true;
  } else {
    return false;
  }
}

export async function getNewIdentifier(type) {
  const number = (
    await api.get(`/autoNumber/${type.toUpperCase()}`, {
      headers: getHeaders(),
    })
  ).data;

  return `${type.toUpperCase()}${number}`;
}

export async function log(action, dataType, parameters) {
  await api.post(
    `/audits`,
    {
      action,
      dataType,
      parameters,
    },
    { headers: getHeaders() }
  );
}

export async function getQuery(id) {
  const response = await api.get(`/queries/${id}`, {
    params: {
      projection: {
        identifier: true,
        title: true,
        type: true,
        source: true,
        description: true,
        isRelativeTimePeriod: true,
        timePeriod: true,
        areas: true,
        briefs: true,
        boundary: true,
        parameters: true,
      },
    },
    headers: getHeaders(),
  });

  return response.data;
}

function getRelativeTimePeriod(timePeriod) {
  if (timePeriod.isRetrospective) {
    const endTime = timePeriod.includeCurrent
      ? moment().endOf(timePeriod.unit.slice(0, -1))
      : moment().startOf(timePeriod.unit.slice(0, -1));
    return {
      startTime: moment(endTime).subtract(timePeriod.amount, timePeriod.unit),
      endTime,
    };
  } else {
    const startTime = timePeriod.includeCurrent
      ? moment().startOf(timePeriod.unit.slice(0, -1))
      : moment().endOf(timePeriod.unit.slice(0, -1));
    return {
      startTime,
      endTime: moment(startTime).add(timePeriod.amount, timePeriod.unit),
    };
  }
}

function getQueryParams(query) {
  const timePeriod = query.isRelativeTimePeriod
    ? getRelativeTimePeriod(query.timePeriod)
    : {
        startTime:
          query.timePeriod.startTime || moment(new Date(-8640000000000000)),
        endTime: query.timePeriod.endTime || moment(new Date(8640000000000000)),
      };

  let params = { projection: {}, query: {} };

  switch (query.source) {
    case 'incidents':
      params.query.openedTimeDateRange = {
        $gte: timePeriod.startTime,
        $lt: timePeriod.endTime,
      };
      params.projection = {
        number: true,
        description: true,
        type: true,
        category: true,
        responseCategory: true,
        grade: true,
        point: true,
        openedTime: true,
        assignedTime: true,
        attendedTime: true,
        closedTime: true,
        closingCodes: true,
      };
      break;
    case 'crimes':
    case 'intelligence':
    case 'stopChecks':
      params.query.reportedTime = {
        $gte: timePeriod.startTime,
        $lt: timePeriod.endTime,
      };
      break;
    default:
  }

  if (query.source === 'incidents' && query.boundary) {
    params.query.point = {
      $geoIntersects: {
        $geometry: query.boundary,
      },
    };
  }

  const flatten = (arr) => arr.reduce((flat, next) => flat.concat(next), []);

  params.query.$and = flatten(
    Object.entries(query.parameters || {})
      .filter((parameter) => parameter[1].length > 0)
      .map((parameter) => {
        if (Array.isArray(parameter[1])) {
          const conditions = parameter[1].reduce(
            (accumulator, currentValue) => {
              return {
                ...accumulator,
                [currentValue.operator]: accumulator[currentValue.operator]
                  ? [...accumulator[currentValue.operator], currentValue.value]
                  : [currentValue.value],
              };
            },
            {}
          );

          return Object.entries(conditions).map((condition) => ({
            [parameter[0].split('^').join('.')]: {
              [condition[0]]: condition[1],
            },
          }));
        } else {
          return {
            [parameter[0].split('^').join('.')]: parameter[1],
          };
        }
      })
  );

  return params;
}

export async function getQueryEvents(id) {
  const query = await getQuery(id);

  let events = {
    incidents: {
      type: 'FeatureCollection',
      features: [],
    },
    crimes: {
      type: 'FeatureCollection',
      features: [],
    },
    intelligence: {
      type: 'FeatureCollection',
      features: [],
    },
    stopChecks: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  const response = await api.get(
    query.source,
    {
      params: getQueryParams(query),
    },
    {
      headers: getHeaders(),
    }
  );

  events[query.source] = {
    type: 'FeatureCollection',
    features: response.data.map(({ point: geometry, ...properties }, index) => {
      return {
        type: 'Feature',
        id: index,
        properties: {
          ...properties,
          typeId: query.source,
          collectionId: id,
          collectionTypeId: 'queries',
        },
        geometry,
      };
    }),
  };

  return events;
}

export async function getSelectionEvents(id) {
  const incidentsResponse = await api.get('/incidents', {
    params: {
      query: { collections: id },
      projection: {
        number: true,
        description: true,
        type: true,
        category: true,
        responseCategory: true,
        grade: true,
        point: true,
        openedTime: true,
        assignedTime: true,
        attendedTime: true,
        closedTime: true,
        closingCodes: true,
        collections: true,
      },
    },
    headers: getHeaders(),
  });

  const incidents = {
    type: 'FeatureCollection',
    features: incidentsResponse.data.map(
      ({ point: geometry, ...properties }, index) => {
        return {
          type: 'Feature',
          id: index,
          properties: {
            ...properties,
            typeId: 'incidents',
            collectionId: id,
            collectionTypeId: 'selections',
          },
          geometry,
        };
      }
    ),
  };

  const crimesResponse = await api.get('/crimes', {
    params: { query: { collections: id } },
    headers: getHeaders(),
  });

  const crimes = {
    type: 'FeatureCollection',
    features: crimesResponse.data.map(
      ({ point: geometry, ...properties }, index) => {
        return {
          type: 'Feature',
          id: index,
          properties: {
            ...properties,
            typeId: 'crimes',
            collectionId: id,
            collectionTypeId: 'selections',
          },
          geometry,
        };
      }
    ),
  };

  const intelligenceResponse = await api.get('/intelligence', {
    params: {
      query: { collections: id },
    },
    headers: getHeaders(),
  });

  const intelligence = {
    type: 'FeatureCollection',
    features: intelligenceResponse.data.map(
      ({ point: geometry, ...properties }, index) => {
        return {
          type: 'Feature',
          id: index,
          properties: {
            ...properties,
            typeId: 'intelligence',
            collectionId: id,
            collectionTypeId: 'selections',
          },
          geometry,
        };
      }
    ),
  };

  const events = {
    incidents,
    crimes,
    intelligence,
  };

  return events;
}

export async function getFeatures(id) {
  const response = await api.get('/features', {
    params: {
      query: { collections: id },
      projection: {
        identifier: true,
        type: true,
        subtype: true,
        title: true,
        description: true,
        areas: true,
        startTime: true,
        endTime: true,
        recurrence: true,
        complianceSeconds: true,
        collections: true,
        geometry: true,
        created: true,
        lastEdit: true,
      },
    },
    headers: getHeaders(),
  });

  return {
    type: 'FeatureCollection',
    features: response.data.map(({ geometry, ...properties }, index) => {
      return {
        type: 'Feature',
        id: index,
        properties: {
          ...properties,
          typeId: 'features',
          collectionId: id,
          collectionTypeId: 'plans',
        },
        geometry,
      };
    }),
  };
}

export function getPrimaryLocation(locations) {
  const locationTypeSequence = new Map([
    ['Police Station', 0],
    ['Base', 1],
    ['Workshop', 2],
    ['Ward', 3],
  ]);

  if ((locations || []).length === 0) {
    return {
      name: 'Elsewhere',
      type: 'None',
    };
  } else {
    return locations
      .map((location) => {
        return {
          name: location.name,
          type: location.type,
          sequence: locationTypeSequence.get(location.type) || 100,
        };
      })
      .sort((a, b) => {
        return a.sequence - b.sequence;
      })[0];
  }
}

export async function getFeatureBoundary(id) {
  const response = await api.get(`/features/${id}`, {
    params: {
      projection: { geometry: true },
    },
    headers: getHeaders(),
  });
  return response.data.geometry;
}

export async function getLocationBoundary(id) {
  const response = await api.get(`/locations/${id}`, {
    params: {
      projection: { boundary: true },
    },
    headers: getHeaders(),
  });
  return response.data.boundary;
}

export async function getObjectiveBoundary(objective) {
  const headers = getHeaders();

  switch (objective.boundaryType) {
    case 'Location':
      const location = await api.get(
        `/locations/${objective.boundaryIdentifier}`,
        {
          params: {
            projection: { boundary: true },
          },
          headers,
        }
      );
      return location.data.boundary;
    case 'Perimeter':
      const perimeter = await api.get(
        `/features/${objective.boundaryIdentifier}`,
        {
          params: {
            projection: { geometry: true },
          },
          headers,
        }
      );
      return perimeter.data.geometry;
    default:
      return objective.boundary;
  }
}

export async function getObjectiveWards(objective) {
  const boundary = await getObjectiveBoundary(objective);

  const response = await api.get('/locations', {
    params: {
      query: {
        type: 'Ward',
        boundary: {
          $geoIntersects: {
            $geometry: boundary,
          },
        },
      },
      projection: { code: true },
    },
    headers: getHeaders(),
  });

  return response.data.map((ward) => ward.code);
}

export const locationVisitHeaders = [
  { label: 'Location Type', key: 'locationType', type: 'text' },
  { label: 'Location Name', key: 'locationName', type: 'text' },
  { label: 'Start Time', key: 'startTime', type: 'date' },
  { label: 'End Time', key: 'endTime', type: 'date' },
  { label: 'Duration (minutes)', key: 'durationMinutes', type: 'number' },
];

export const startAndEndLocationHeaders = [
  { label: 'Start Location Type', key: 'startLocationType', type: 'text' },
  { label: 'Start Location Name', key: 'startLocationName', type: 'text' },
  { label: 'End Location Type', key: 'endLocationType', type: 'text' },
  { label: 'End Location Name', key: 'endLocationName', type: 'text' },
  { label: 'Start Time', key: 'startTime', type: 'date' },
  { label: 'End Time', key: 'endTime', type: 'date' },
  { label: 'Duration (minutes)', key: 'durationMinutes', type: 'number' },
];

export const eventsPersonTableHeaders = [
  ...(useReducedResourceInformation
    ? [
        {
          label: 'Staff ID',
          key: 'person.code',
          type: 'text',
          filter: true,
        },
      ]
    : [
        {
          label: 'Forenames',
          key: 'person.forenames',
          type: 'text',
          filter: true,
        },
        {
          label: 'Surname',
          key: 'person.surname',
          type: 'text',
          filter: true,
        },
        {
          label: 'Role',
          key: 'person.role',
          type: 'text',
          filter: true,
        },
        {
          label: 'Collar Number',
          key: 'person.collarNumber',
          type: 'text',
          filter: true,
        },
        {
          label: 'Rank',
          key: 'person.rank.code',
          type: 'text',
          filter: true,
        },
      ]),
];

export const shortPersonHeaders = [
  ...(useReducedResourceInformation
    ? [{ label: 'Staff Id', key: 'staffId', type: 'number' }]
    : [
        { label: 'Name', key: 'name', type: 'text' },
        { label: 'Role', key: 'personRole', type: 'text' },
        { label: 'Collar Number', key: 'collarNumber', type: 'number' },
      ]),
];

export const shortVehicleHeaders = [
  ...(useReducedResourceInformation
    ? [
        { label: 'Fleet Number', key: 'fleetNumber', type: 'number' },
        { label: 'Type', key: 'type', type: 'text' },
      ]
    : [
        { label: 'Registration', key: 'registrationNumber', type: 'number' },
        { label: 'Fleet Number', key: 'fleetNumber', type: 'number' },
        { label: 'Vehicle Role', key: 'vehicleRole', type: 'text' },
      ]),
];

export const longPersonVehicleHeaders = [
  ...(useReducedResourceInformation
    ? [
        { label: 'Fleet Number', key: 'fleetNumber', type: 'number' },
        { label: 'Type', key: 'type', type: 'text' },
        { label: 'Staff Id', key: 'staffId', type: 'number' },
      ]
    : [
        {
          label: 'Registration',
          key: 'registrationNumber',
          type: 'number',
        },
        { label: 'Fleet Number', key: 'fleetNumber', type: 'number' },
        { label: 'Vehicle Role', key: 'vehicleRole', type: 'text' },
        { label: 'Type', key: 'type', type: 'text' },
        { label: 'Name', key: 'name', type: 'text' },
        { label: 'Role', key: 'personRole', type: 'text' },
        { label: 'Collar Number', key: 'collarNumber', type: 'number' },
      ]),
  { label: 'RFID', key: 'rfidCards', type: 'number' },
];
// headers for vehicle/telematics boxes polls
export const telematicsBoxPollHeaders = [
  { label: 'VIN', key: 'identificationNumber', type: 'number' },
  { label: 'IMEI', key: 'imei', type: 'number' },
  { label: 'Time', key: 'time', type: 'date' },
  { label: 'Longitude', key: 'longitude', type: 'number' },
  { label: 'Latitude', key: 'latitude', type: 'number' },
  { label: 'Heading (degrees)', key: 'headingDegrees', type: 'number' },
  { label: 'Speed (mph)', key: 'speedMilesPerHour', type: 'number' },
  { label: 'Speed Limit (mph)', key: 'speedLimitMilesPerHour', type: 'number' },
  {
    label: 'Malfunction Indicator Light',
    key: 'malfunctionIndicatorLightOn',
    type: 'boolean',
  },
  { label: 'Accelerometer Alert', key: 'accelerometerAlert', type: 'boolean' },
  { label: 'Odometer (miles)', key: 'odometerMiles', type: 'number' },
  { label: 'Ignition On', key: 'ignitionOn', type: 'boolean' },
  { label: 'Diagnostic Code', key: 'diagnosticCode', type: 'number' },
  ...Object.entries(dioStates).map((entry) => ({
    key: entry[0],
    label: entry[1],
  })),
];

export const tripHeaders = [
  { label: 'Classification', key: 'classification', type: 'text' },
  { label: 'Start Location Type', key: 'startLocationType', type: 'text' },
  { label: 'Start Location Name', key: 'startLocationName', type: 'text' },
  { label: 'End Location Type', key: 'endLocationType', type: 'text' },
  { label: 'End Location Name', key: 'endLocationName', type: 'text' },
  { label: 'Start Time', key: 'startTime', type: 'date' },
  { label: 'End Time', key: 'endTime', type: 'date' },
  { label: 'Duration (minutes)', key: 'durationMinutes', type: 'number' },
  { label: 'Distance (miles)', key: 'distanceMiles', type: 'number' },
  { label: 'Maximum Speed (mph)', key: 'maxSpeedMilesPerHour', type: 'number' },
];

export const vehiclePollHeaders = [
  ...shortPersonHeaders,
  ...shortVehicleHeaders,
  ...telematicsBoxPollHeaders,
];

// headers for person/radio polls
export const radioPollHeaders = [
  { label: 'Radio SSI', key: 'radioSsi', type: 'number' },
  { label: 'Time', key: 'time', type: 'date' },
  { label: 'Longitude', key: 'longitude', type: 'number' },
  { label: 'Latitude', key: 'latitude', type: 'number' },
];

export async function getVehiclePolls(imei, startTime, endTime) {
  const response = await api.get('/telematicsBoxPolls', {
    params: {
      query: {
        imei,
        time: { $gte: startTime, $lte: endTime },
      },
      projection: {
        identifier: true,
        imei: true,
        driver: true,
        position: true,
        time: true,
        headingDegrees: true,
        speedKilometresPerHour: true,
        malfunctionIndicatorLightsOn: true,
        accelerometerAlert: true,
        distanceKilometres: true,
        ignitionOn: true,
        diagnosticCode: true,
        ...Object.keys(dioStates).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {}),
      },
    },
    headers: getHeaders(),
  });

  const polls = (response.data || []).map(
    ({
      position,
      time,
      speedKilometresPerHour,
      distanceKilometres,
      reverseGeocode,
      ...poll
    }) => ({
      longitude: position ? position.coordinates[0] : 0,
      latitude: position ? position.coordinates[1] : 0,
      time: new Date(time),
      speedMilesPerHour: _.round(speedKilometresPerHour * 0.62137119, 2),
      odometerMiles: _.round(distanceKilometres * 0.62137119, 2),
      speedLimitMilesPerHour:
        reverseGeocode && !reverseGeocode.unknownLimit
          ? _.round(reverseGeocode.speedLimitKilometresPerHour * 0.62137119, 2)
          : undefined,
      position,
      ...poll,
    })
  );

  return polls;
}

export async function getPersonForPoll(radioSsi, time) {
  const response = await api.get('/personTrails', {
    params: {
      query: {
        'person.radioSsi': radioSsi,
        startTime: { $lte: time },
        endTime: { $gte: time },
      },
      projection: {
        person: true,
      },
    },
    headers: getHeaders(),
  });

  return response.data?.[0]?.person || {};
}

export async function getPersonPolls(radioSsi, startTime, endTime) {
  const response = await api.get('/radioPolls', {
    params: {
      query: {
        ssi: radioSsi,
        time: { $gte: startTime, $lte: endTime },
      },
      projection: {
        ssi: true,
        time: true,
        headingDegrees: true,
        speedKilometresPerHour: true,
        deviceProperties: true,
        identifier: true,
        locations: true,
        gpsFix: true,
        position: true,
        features: true,
        objectives: true,
      },
    },
    headers: getHeaders(),
  });

  return response.data;
}

export const shortHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
  spacer: '',
});

function getSourceFromDataType(dataType) {
  switch (dataType) {
    case 'Vehicle':
    case 'Vehicle Trips':
    case 'Vehicle Location Visits':
      return 'vehicles';
    case 'Person':
    case 'Person Trails':
    case 'Person Location Visits':
    case 'Driver Trips':
      return 'people';
    case 'Location':
    case 'Person Visits':
    case 'Vehicle Visits':
      return 'locations';
    case 'Brief':
    case 'Brief Collections':
    case 'Brief Objectives':
      return 'briefs';
    case 'Objective':
    case 'Objective Attendances':
      return 'objectives';
    case 'Collection':
    case 'Collection Features':
    case 'Collection Events':
    case 'Plan':
    case 'Query':
    case 'Selection':
      return 'collections';
    case 'Feature':
      return 'features';
    // case 'Task':
    //   return 'tasks';
    case 'Retrospective':
      return 'retrospectives';
    case 'Incident':
      return 'incidents';
    default:
      return null;
  }
}

function getParamsFromSource(source) {
  switch (source) {
    case 'vehicles':
      return {
        projection: {
          identificationNumber: true,
          fleetNumber: true,
          registrationNumber: true,
        },
      };
    case 'people':
      return {
        projection: {
          code: true,
          forenames: true,
          surname: true,
          collarNumber: true,
        },
      };
    case 'locations':
      return { projection: { code: true, type: true, name: true } };
    case 'briefs':
    case 'objectives':
    case 'collections':
    case 'features':
      return { projection: { identifier: true, type: true, title: true } };
    // case 'tasks':
    //   return { projection: {] };
    case 'retrospectives':
      return { projection: { title: true } };
    case 'incidents':
      return { projection: { type: true, number: true } };
    default:
      return null;
  }
}

export async function getAuditItem(dataType, id) {
  const source = getSourceFromDataType(dataType);
  if (!source) {
    return null;
  }

  const params = getParamsFromSource(source);
  const response = await api.get(`/${source}/${id}`, {
    params,
    headers: getHeaders(),
  });

  return response.data;
}

export async function getPerson(id) {
  const response = await api.get(`/people/${id}`, {
    params: {
      projection: {
        code: true,
        collarNumber: true,
        forenames: true,
        surname: true,
        category: true,
        radioSsi: true,
        role: true,
        areas: true,
        wards: true,
        rank: true,
      },
    },
    headers: getHeaders(),
  });

  return response.data;
}

export const reduceByType = (arr) =>
  (arr || []).reduce((byType, item) => {
    if (item.name) {
      byType[item.type] = item.name;
    }
    return byType;
  }, {});

export function areasFilter(record, filter) {
  let areaMatch = true;

  Object.entries(filter.areas).forEach((keyValuePair) => {
    if (
      keyValuePair[1].length !== 0 &&
      !keyValuePair[1].includes(record.areas[keyValuePair[0]])
    ) {
      areaMatch = false;
    }
  });

  return areaMatch;
}

export function getGroupKey(groupBy, record) {
  if (groupBy in record) {
    return record[groupBy];
  }

  if (groupBy in record.areas) {
    return record.areas[groupBy];
  }

  if (groupBy === 'date') {
    return record.time;
  }

  if (groupBy === 'month') {
    return moment(record.time).startOf('month').toISOString();
  }

  if (groupBy === 'all') {
    return 'All';
  }

  return 'Others';
}

export async function imeiValid(imei, id) {
  if (!imei) {
    return true;
  }

  const response = await api.get('/vehicles', {
    params: {
      query: {
        telematicsBoxImei: imei,
      },
    },
    headers: getHeaders(),
  });

  if (response.data.length === 0) {
    return true;
  }

  return response.data
    .map((vehicle) => vehicle.identificationNumber)
    .includes(id);
}

export async function ssiValid(ssi, id) {
  if (!ssi) {
    return true;
  }

  const response = await api.get(`/people`, {
    params: {
      query: {
        radioSsi: ssi,
      },
      projection: { code: true },
    },
    headers: getHeaders(),
  });

  if (response.data.length === 0) {
    return true;
  }

  return response.data.map((person) => person.code).includes(id);
}

export function parseFilter(filters) {
  const units = {
    s: 1,
    m: 60,
    h: 3600,
    d: 86400,
  };

  if (filters === undefined) {
    return [];
  }

  const parsed = Object.entries(filters)
    .map((entry) =>
      entry[1]
        .filter((entry) => entry.value)
        .map(({ field, condition, value, unit }) => ({
          [entry[0] === 'event' ? field : `${entry[0]}.${field}`]: {
            [condition || '$eq']: isNaN(value)
              ? value
              : parseFloat(value) * (unit ? units[unit] : 1),
          },
        }))
    )
    .reduce((a, b) => [...a, ...b], []);

  return parsed;
}

export function parseSort(sort = []) {
  const orderBy = {
    fields: sort
      .filter((item) => !!item.field)
      .map((item) =>
        item.subject === 'event'
          ? `properties.${item.field}`
          : `properties.${item.subject}.${item.field}`
      ),
    directions: sort.map((item) => item.direction),
  };

  return orderBy;
}

export async function getRfidErrors(id, rfids) {
  if (!rfids || !rfids.length) {
    return false;
  }

  const counts = rfids.reduce(
    (accumulator, { reference }) => ({
      ...accumulator,
      [reference]: (accumulator[reference] || 0) + 1,
    }),
    {}
  );

  const duplicates = Object.keys(counts).filter((a) => counts[a] > 1);

  if (duplicates.length) {
    return `${duplicates.join(', ')} duplicated`;
  }

  const used = (
    await Promise.all(
      rfids
        .filter((rfid) => rfid.reference)
        .map(async (rfid) => {
          const response = await api.get(`/people`, {
            params: {
              query: {
                'rfidCards.reference': rfid.reference,
              },
              projection: { code: true },
            },
            headers: getHeaders(),
          });

          return {
            reference: rfid.reference,
            inUse:
              response.data.filter((person) => person.code !== id).length > 0,
          };
        })
    )
  )
    .filter((card) => card.inUse)
    .map((card) => card.reference);

  if (used.length) {
    return `${used.join(', ')} in use`;
  }

  return false;
}

export function checkLuhn(value) {
  return !(
    value
      .replace(/\D/g, '')
      .split('')
      .reverse()
      .reduce(function (a, d, i) {
        return (a + d * (i % 2 ? 2.2 : 1)) | 0;
      }, 0) % 10
  );
}

export function checkVin(value) {
  if (value.length !== 17) {
    return false;
  }

  return true;
}

export function formatDates(object, dateFormat) {
  let result = { ...object };
  for (const key in result) {
    if (result[key] instanceof Date) {
      result[key] = format(result[key], dateFormat);
    } else if (result[key] instanceof Object) {
      result[key] = formatDates(result[key], dateFormat);
    }
  }
  return result;
}

export function downloadCSV(data, filename, headers, extraInfo = {}) {
  log('Read', 'CSV', { filename, ...extraInfo });

  const header = headers
    ? Papa.unparse(
        { fields: headers.map((h) => h.label), data: [] },
        { header: true }
      )
    : '';

  const body = headers
    ? Papa.unparse(
        data.map((item) => formatDates(item, 'dd/MM/yyyy HH:mm:ss')),
        {
          header: false,
          columns: headers.map((h) => h.key),
        }
      )
    : Papa.unparse(
        data.map((item) => formatDates(item, 'dd/MM/yyyy HH:mm:ss'))
      );
  const csv = header + body;
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', filename);
    a.click();
  }
}

export function downloadJSON(data, filename) {
  log('Read', 'JSON', filename);

  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: 'application/json',
  });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', filename);
    a.click();
  }
}

export function downloadGeoJSON(data, filename) {
  log('Read', 'GeoJSON', filename);

  const geo = JSON.stringify(convertToGeoJSON(data));
  const blob = new Blob([geo], {
    type: 'application/json',
  });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', filename);
    a.click();
  }
}

function convertToGeoJSON(data) {
  const features = data.map((feature) => {
    const { boundary, path, point, position, ...props } = feature;
    const geometry = boundary || path || point || position || {};

    return {
      type: 'Feature',
      geometry,
      properties: props,
    };
  });

  let geojson = {
    type: 'FeatureCollection',
    features: features,
  };

  rewind(geojson, false);

  return geojson;
}

export async function getVehicles() {
  const response = await api.get('/vehicles', {
    params: {
      projection: {
        identificationNumber: true,
        registrationNumber: true,
        fleetNumber: true,
        role: true,
        type: true,
        make: true,
        model: true,
        colour: true,
        marked: true,
        homeStation: true,
        areas: true,
        equipment: true,
        telematicsBoxImei: true,
        lastPollTime: true,
        lastOdometerReading: true,
        fuelType: true,
        disposalDate: true,
        restricted: true,
      },
    },
    headers: getHeaders(),
  });

  return response.data;
}

export async function getPeople() {
  const response = await api.get('/people', {
    params: {
      projection: {
        code: true,
        forenames: true,
        surname: true,
        collarNumber: true,
        emailAddress: true,
        supervisorCode: true,
        postNumber: true,
        rank: true,
        role: true,
        homeStation: true,
        wards: true,
        areas: true,
        skills: true,
        radioSsi: true,
        lastPollTime: true,
        restricted: true,
        rfidCards: true,
      },
    },
    headers: getHeaders(),
  });

  return response.data;
}

export async function getLocations(type) {
  const response = await api.get('/locations', {
    params: {
      projection: {
        code: true,
        name: true,
        type: true,
        areas: true,
        subtype: true,
        boundary: true,
        startTime: true,
        endTime: true,
      },
      index: { name: 'type', values: [type] },
    },
    headers: getHeaders(),
  });

  return response.data;
}

const vehicleHeaders = [
  { label: 'VIN', key: 'identificationNumber', type: 'number' },
  ...(useReducedResourceInformation
    ? [{ label: 'Fleet Number', key: 'fleetNumber', type: 'number' }]
    : [
        { label: 'Registration', key: 'registrationNumber', type: 'number' },
        { label: 'Fleet Number', key: 'fleetNumber', type: 'number' },
        { label: 'Role', key: 'role', type: 'text' },
      ]),
  { label: 'Type', key: 'type', type: 'text' },
  { label: 'Make', key: 'make', type: 'text' },
  { label: 'Model', key: 'model', type: 'text' },
  { label: 'Colour', key: 'colour', type: 'text' },
  { label: 'Marked', key: 'marked', type: 'text' },
  { label: 'Home Station', key: 'homeStation', type: 'text' },
  { label: 'Equipment', key: 'equipment', type: 'text' },
  { label: 'IMEI', key: 'telematicsBoxImei', type: 'number' },
  { label: 'Last Poll Time', key: 'lastPollTime', type: 'date' },
  {
    label: 'Last Odometer Reading Date & Time',
    key: 'lastOdometerReadingTime',
    type: 'date',
  },
  {
    label: 'Last Odometer Reading Miles',
    key: 'lastOdometerReadingMiles',
    type: 'date',
  },
  { label: 'Fuel Type', key: 'fuelType', type: 'text' },
  { label: 'Disposed Date & Time', key: 'disposalDate', type: 'date' },
  ...(useRestricted
    ? [{ label: 'Restricted', key: 'restricted', type: 'boolean' }]
    : []),
];

export async function getVehiclesAndHeaders() {
  const response = await api.get('/vehicles', {
    params: {
      projection: {
        identificationNumber: true,
        registrationNumber: true,
        fleetNumber: true,
        role: true,
        type: true,
        make: true,
        model: true,
        colour: true,
        marked: true,
        homeStation: true,
        areas: true,
        equipment: true,
        telematicsBoxImei: true,
        lastPollTime: true,
        lastOdometerReading: true,
        fuelType: true,
        disposalDate: true,
        restricted: true,
      },
    },
    headers: getHeaders(),
  });

  const areas = Array.from(
    new Set(
      [].concat(
        ...response.data.map((record) =>
          (record.areas || []).map((area) => area.type)
        )
      )
    )
  );

  const vehicles = response.data.map(
    ({
      areas,
      lastPollTime,
      lastOdometerReading,
      disposalDate,
      equipment,
      ...vehicle
    }) => ({
      ...vehicle,
      equipment: (equipment || [])
        .map((equipment) => equipment.name)
        .toString(),
      lastPollTime: lastPollTime
        ? moment(lastPollTime).format('DD/MM/YYYY HH:mm:ss')
        : '',
      disposalDate: disposalDate
        ? moment(disposalDate).format('DD/MM/YYYY HH:mm:ss')
        : '',
      lastOdometerReadingTime:
        lastOdometerReading && lastOdometerReading.time
          ? moment(lastOdometerReading.time).format('DD/MM/YYYY HH:mm:ss')
          : '',
      lastOdometerReadingMiles:
        lastOdometerReading && lastOdometerReading.distanceKilometres
          ? _.round(lastOdometerReading.distanceKilometres * 0.62137119, 2)
          : '',
      ...(areas || []).reduce((accumulator, area) => {
        if (area.name !== undefined) {
          accumulator[area.type] = area.name;
        }

        return accumulator;
      }, {}),
    })
  );

  return {
    vehicles,
    headers: [
      ...vehicleHeaders,
      ...areas.map((area) => ({
        label: _.startCase(area),
        key: area,
        type: 'text',
      })),
    ],
  };
}

const peopleHeaders = [
  ...(useReducedResourceInformation
    ? [
        { label: 'Staff ID', key: 'code', type: 'number' },
        { label: 'AD Username', key: 'emailAddress', type: 'text' },
        { label: 'Supervisor', key: 'supervisorCode', type: 'number' },
        { label: 'Home Station', key: 'homeStation', type: 'text' },
        { label: 'RFID', key: 'rfidCards', type: 'number' },
      ]
    : [
        { label: 'Staff ID', key: 'code', type: 'number' },
        { label: 'Forenames', key: 'forenames', type: 'text' },
        { label: 'Surname', key: 'surname', type: 'text' },
        { label: 'Collar Number', key: 'collarNumber', type: 'number' },
        { label: 'Email Address', key: 'emailAddress', type: 'text' },
        { label: 'Supervisor', key: 'supervisorCode', type: 'number' },
        { label: 'Post Number', key: 'postNumber', type: 'number' },
        { label: 'Rank', key: 'rank', type: 'text' },
        { label: 'Role', key: 'role', type: 'text' },
        { label: 'Home Station', key: 'homeStation', type: 'text' },
        { label: 'Wards', key: 'wards', type: 'text' },
        { label: 'Skills', key: 'skills', type: 'text' },
        { label: 'Radio SSI', key: 'radioSsi', type: 'number' },
        { label: 'Last Poll Time', key: 'lastPollTime', type: 'date' },
        {
          label: useDallasKeys ? 'Dallas Keys' : 'RFID',
          key: 'rfidCards',
          type: 'number',
        },
      ]),
  ...(useRestricted
    ? [{ label: 'Restricted', key: 'restricted', type: 'boolean' }]
    : []),
];

export async function getPeopleAndHeaders() {
  const response = await api.get('/people', {
    params: {
      projection: {
        code: true,
        forenames: true,
        surname: true,
        collarNumber: true,
        emailAddress: true,
        supervisorCode: true,
        postNumber: true,
        rank: true,
        role: true,
        homeStation: true,
        wards: true,
        areas: true,
        skills: true,
        radioSsi: true,
        lastPollTime: true,
        restricted: true,
        rfidCards: true,
      },
    },
    headers: getHeaders(),
  });

  const areas = Array.from(
    new Set(
      [].concat(
        ...response.data.map((record) =>
          (record.areas || []).map((area) => area.type)
        )
      )
    )
  );

  const people = response.data.map(
    ({ areas, lastPollTime, wards, skills, rfidCards, rank, ...vehicle }) => ({
      ...vehicle,
      wards: (wards || []).toString(),
      skills: (skills || []).map((skill) => skill.name).toString(),
      lastPollTime: lastPollTime
        ? moment(lastPollTime).format('DD/MM/YYYY HH:mm:ss')
        : '',
      rfidCards: (rfidCards || []).map((card) => card.reference).toString(),
      rank: rank ? rank.code : '',
      ...(areas || []).reduce((accumulator, area) => {
        if (area.name !== undefined) {
          accumulator[area.type] = area.name;
        }

        return accumulator;
      }, {}),
    })
  );

  return {
    people,
    headers: [
      ...peopleHeaders,
      ...areas.map((area) => ({
        label: _.startCase(area),
        key: area,
        type: 'text',
      })),
    ],
  };
}

export async function fetchPersonRequest(id) {
  const response = await api.get(`/people/${id}`, {
    params: {
      projection: {
        code: true,
        picture: true,
        forenames: true,
        surname: true,
        collarNumber: true,
        emailAddress: true,
        supervisorCode: true,
        postNumber: true,
        rank: true,
        role: true,
        homeStation: true,
        wards: true,
        areas: true,
        skills: true,
        radioSsi: true,
        lastPollTime: true,
        rfidCards: true,
        ward: true,
        supervisorCodes: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'Person', { id });

  return response.data;
}

export async function fetchVehicleRequest(id) {
  const response = await api.get(`/vehicles/${id}`, {
    params: {
      projection: {
        identificationNumber: true,
        registrationNumber: true,
        fleetNumber: true,
        picture: true,
        role: true,
        type: true,
        make: true,
        model: true,
        colour: true,
        marked: true,
        homeStation: true,
        areas: true,
        equipment: true,
        telematicsBoxImei: true,
        lastPollTime: true,
        lastOdometerReading: true,
        fuelType: true,
        // disposedTime: true,
        disposalDate: true,
        restricted: true,
      },
    },
    headers: getHeaders(),
  });

  const vehicle = {
    ...response.data,
    lastOdometerReading: {
      distanceMiles:
        response.data.lastOdometerReading &&
        response.data.lastOdometerReading.distanceKilometres
          ? _.round(
              response.data.lastOdometerReading.distanceKilometres * 0.62137119,
              2
            )
          : null,
      time:
        response.data.lastOdometerReading &&
        response.data.lastOdometerReading.time
          ? response.data.lastOdometerReading.time
          : null,
    },
  };

  log('Read', 'Vehicle', { id });

  return vehicle;
}

export async function fetchLocationRequest(id) {
  const response = await api.get(`/locations/${id}`, {
    params: {
      projection: {
        code: true,
        name: true,
        type: true,
        areas: true,
        district: true,
        subtype: true,
        boundary: true,
        startTime: true,
        endTime: true,
        picture: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'Location', { id });

  return response.data;
}

export function getValues(object, path) {
  const segments = path.split('.');
  if (segments[0] in object) {
    const child = object[segments[0]];
    if (segments.length === 1) {
      if (child instanceof Array) {
        return child;
      } else {
        return [child];
      }
    } else if (child instanceof Array) {
      return [].concat(
        ...child.map((entry) => getValues(entry, segments.slice(1).join('.')))
      );
    } else {
      return getValues(child, segments.slice(1).join('.'));
    }
  } else {
    return [];
  }
}

export function getAllValues(array, path) {
  const rawValues = Array.from(
    new Set([].concat(...array.map((object) => getValues(object, path))))
  ).sort();

  return rawValues;
}

//TODO: Temporarily getting option values from resources, should be updated to fetch from config table once it's moved there
// https://github.com/lba/ir3/issues/543
export async function fetchOptions() {
  const vehicleResponse = await api.get('/vehicles', {
    params: {
      projection: options.vehicle.reduce(function (acc, field) {
        acc[field.name] = true;
        return acc;
      }, {}),
    },
    headers: getHeaders(),
  });

  const personResponse = await api.get('/people', {
    params: {
      projection: options.person.reduce(function (acc, field) {
        acc[field.name] = true;
        return acc;
      }, {}),
    },
    headers: getHeaders(),
  });

  const locationResponse = await api.get('/locations', {
    params: {
      projection: options.location.reduce(function (acc, field) {
        acc[field.name] = true;
        return acc;
      }, {}),
    },
    headers: getHeaders(),
  });

  const objectiveResponse = await api.get('/objectives', {
    params: {
      projection: options.objective.reduce(function (acc, field) {
        acc[field.name] = true;
        return acc;
      }, {}),
    },
    headers: getHeaders(),
  });

  const personOrDriverFields = options.person.map(({ name, label }) => ({
    name,
    label,
    values: getAllValues(personResponse.data, name).map((value) => ({
      label: value,
      value,
    })),
  }));

  return [
    {
      name: 'vehicle',
      label: 'Vehicle',
      fields: options.vehicle.map(({ name, label }) => ({
        name,
        label,
        values: getAllValues(vehicleResponse.data, name).map((value) => ({
          label: value,
          value,
        })),
      })),
    },
    {
      name: 'person',
      label: 'Person',
      fields: personOrDriverFields,
    },
    {
      name: 'driver',
      label: 'Driver',
      fields: personOrDriverFields,
    },
    {
      name: 'location',
      label: 'Location',
      fields: options.location.map(({ name, label }) => ({
        name,
        label,
        values: getAllValues(locationResponse.data, name).map((value) => ({
          label: value,
          value,
        })),
      })),
    },
    {
      name: 'objective',
      label: 'Objective',
      fields: options.objective.map(({ name, label }) => ({
        name,
        label,
        values: getAllValues(objectiveResponse.data, name).map((value) => ({
          label: value,
          value,
        })),
      })),
    },
  ];
}

export async function addBriefCollections(
  addedCollections,
  collectionName,
  brief
) {
  addedCollections.forEach(async (identifier) => {
    const collectionResponse = await api.get(
      `/${collectionName}/${identifier}`,
      {
        params: {
          projection: { identifier: true, briefs: true },
        },
        headers: getHeaders(),
      }
    );
    const briefs = (collectionResponse.data.briefs || []).concat(
      brief.identifier
    );

    await api.patch(
      `/${collectionName}/${identifier}`,
      { briefs },
      {
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/merge-patch+json',
        },
      }
    );
  });
}

export async function removeBriefCollections(
  removedCollections,
  collectionName,
  brief
) {
  await removedCollections.forEach(async (identifier) => {
    const collectionResponse = await api.get(
      `/${collectionName}/${identifier}`,
      {
        params: {
          query: { identifier: true, briefs: true },
        },
        headers: getHeaders(),
      }
    );
    const index = collectionResponse.data.briefs.indexOf(brief.identifier);
    const briefs = collectionResponse.data.briefs
      .slice(0, index)
      .concat(collectionResponse.data.briefs.slice(index + 1));

    await api.patch(
      `/${collectionName}/${identifier}`,
      { briefs },
      {
        headers: {
          ...getHeaders(),
          'Content-Type': 'application/merge-patch+json',
        },
      }
    );
  });
}
