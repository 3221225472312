import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  delete: {
    color: theme.palette.error.main,
  },
  remove: {
    color: theme.palette.error.main,
  },
  default: {},
}));

export default function ConfirmationDialog({
  itemId,
  open,
  onCancel,
  onOk,
  action,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`${action}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure you want to ${action.toLowerCase()} ${
            itemId ? `"${itemId}"` : 'this'
          }?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={onOk}
          color="primary"
          className={classes[action.toLowerCase() || 'default']}
        >
          {action}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
