// source organizer might remove this, here it is for reference
// import { CancelToken } from 'axios';
import { CancelToken } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import {
  FETCH_PERSON_DAILY_ACTIVITY,
  FETCH_PERSON_DAILY_ACTIVITY_CANCELLED,
  FETCH_PERSON_DAILY_ACTIVITY_FAILURE,
  FETCH_PERSON_DAILY_ACTIVITY_SUCCESS,
  FETCH_PERSON_HOURLY_ACTIVITY,
  FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED,
  FETCH_PERSON_HOURLY_ACTIVITY_FAILURE,
  FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS,
  LOAD_PERSON_DAILY_ACTIVITY,
  LOAD_PERSON_DAILY_ACTIVITY_FAILURE,
  LOAD_PERSON_DAILY_ACTIVITY_SUCCESS,
  LOAD_PERSON_HOURLY_ACTIVITY,
  LOAD_PERSON_HOURLY_ACTIVITY_FAILURE,
  LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS,
} from '../actions';
import api from '../apis';
import {
  getHeaders,
  log,
  reduceByType as reduceAreas,
  areasFilter,
  getGroupKey,
} from '../apis/utilities';
import db from '../data/db';

const { useReducedResourceInformation } = window.config;

let cancel;

function personActivityDataFilter(record, filter) {
  if (filter.code.length !== 0 && !filter.code.includes(record.code)) {
    return false;
  }

  if (filter.name.length !== 0 && !filter.name.includes(record.name)) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  return areasFilter(record, filter);
}

function getPersonActivityFilterAndGroupByValues(data, filter) {
  const { areas: _, ...fields } = filter;
  const result = { areas: {} };
  const areas = Array.from(
    new Set([].concat(...data.map((record) => Object.keys(record.areas))))
  );

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    result[key] = Array.from(
      new Set(
        data
          .filter((record) => personActivityDataFilter(record, keyFilter))
          .map((record) => record[key])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  for (const area of areas) {
    const keyFilter = { ...filter, areas: { ...filter.areas, [area]: [] } };
    result.areas[area] = Array.from(
      new Set(
        data
          .filter((record) => personActivityDataFilter(record, keyFilter))
          .map((record) => record.areas[area])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  return {
    filterValues: result,
    groupByValues: useReducedResourceInformation
      ? ['date', 'code', ...areas]
      : ['date', 'name', 'role', ...areas],
  };
}

function getPersonDailyActivity(rawData, groupBy) {
  const groupedData =
    rawData.length === 0
      ? new Map()
      : rawData.reduce((accumulator, record) => {
          const groupKey = getGroupKey(groupBy, record);
          let current = accumulator.get(groupKey);

          if (!current) {
            current = {
              group: groupKey,
              loggedInSeconds: [],
              inBaseSeconds: [],
              respondingToIncidentSeconds: [],
              attendingObjectiveSeconds: [],
              tripsDurationSeconds: [],
              inHomeWardSeconds: [],
              doubleCrewingSeconds: [],
              baseVisits: [],
              tripsDistanceKilometres: [],
              names: [],
            };
            accumulator.set(groupKey, current);
          }

          current.loggedInSeconds.push(record.loggedInSeconds);
          current.inBaseSeconds.push(record.inBaseSeconds);
          current.respondingToIncidentSeconds.push(
            record.respondingToIncidentSeconds
          );
          current.attendingObjectiveSeconds.push(
            record.attendingObjectiveSeconds
          );
          current.tripsDurationSeconds.push(record.tripsDurationSeconds);
          current.inHomeWardSeconds.push(record.inHomeWardSeconds);
          current.doubleCrewingSeconds.push(record.doubleCrewingSeconds);
          current.baseVisits.push(record.baseVisits);
          current.tripsDistanceKilometres.push(record.tripsDistanceKilometres);

          if (!current.names.includes(record.name)) {
            current.names.push(record.name);
          }

          return accumulator;
        }, new Map());

  const groupedArray = Array.from(groupedData.values());
  const singularGroups = groupedArray.every((v) => v.names.length === 1);
  const countText = (count) => (singularGroups ? '' : `(${count})`);
  const data = groupedArray.map((group) => ({
    group:
      groupBy === 'date'
        ? `${moment(group.group).format('DD/MM/YYYY')} ${countText(
            group.names.length
          )}`
        : `${group.group} ${countText(group.names.length)}`,
    // 'Group Size': group.names.length,
    onRadio: _.round(
      group.loggedInSeconds.reduce((a, b) => a + b, 0) /
        group.loggedInSeconds.length /
        3600,
      2
    ),
    inBase: _.round(
      group.inBaseSeconds.reduce((a, b) => a + b, 0) /
        group.inBaseSeconds.length /
        3600,
      2
    ),
    respondingToIncidents: _.round(
      group.respondingToIncidentSeconds.reduce((a, b) => a + b, 0) /
        group.respondingToIncidentSeconds.length /
        3600,
      2
    ),
    attendingObjectives: _.round(
      group.attendingObjectiveSeconds.reduce((a, b) => a + b, 0) /
        group.attendingObjectiveSeconds.length /
        3600,
      2
    ),
    driving: _.round(
      group.tripsDurationSeconds.reduce((a, b) => a + b, 0) /
        group.tripsDurationSeconds.length /
        3600,
      2
    ),
    inHomeWard: _.round(
      group.inHomeWardSeconds.reduce((a, b) => a + b, 0) /
        group.inHomeWardSeconds.length /
        3600,
      2
    ),
    doubleCrewing: _.round(
      group.doubleCrewingSeconds.reduce((a, b) => a + b, 0) /
        group.doubleCrewingSeconds.length /
        3600,
      2
    ),
    totalDrivingMileage: _.round(
      group.tripsDistanceKilometres.reduce((a, b) => a + b, 0) * 0.62137119,
      2
    ),
    averageDrivingMileage: _.round(
      (group.tripsDistanceKilometres.reduce((a, b) => a + b, 0) * 0.62137119) /
        group.names.length,
      2
    ),
    dailyDrivingMileage: _.round(
      (group.tripsDistanceKilometres.reduce((a, b) => a + b, 0) * 0.62137119) /
        group.tripsDistanceKilometres.length,
      2
    ),
    totalBaseVisits: _.round(
      group.baseVisits.reduce((a, b) => a + b, 0),
      2
    ),
    averageBaseVisits: _.round(
      group.baseVisits.reduce((a, b) => a + b, 0) / group.names.length,
      2
    ),
    dailyBaseVisits: _.round(
      group.baseVisits.reduce((a, b) => a + b, 0) / group.baseVisits.length,
      2
    ),
  }));
  data.sort((a, b) =>
    groupBy === 'date'
      ? moment(a.group, 'DD/MM/YYYY').diff(moment(b.group, 'DD/MM/YYYY'))
      : (a.group || '').localeCompare(b.group)
  );

  return data;
}

async function fetchPersonDailyActivityRequest(query, filter, groupBy) {
  const response = await api.get('/personDailySummaries', {
    params: {
      query,
      projection: {
        time: true,
        person: true,
        loggedInSeconds: true,
        inBaseSeconds: true,
        attendingObjectiveSeconds: true,
        inHomeWardSeconds: true,
        doubleCrewingSeconds: true,
        baseVisits: true,
        respondingToIncidentSeconds: true,
        trips: true,
        tripsDurationSeconds: true,
        tripsDistanceKilometres: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const data = response.data.map(
    ({
      person: { areas, collarNumber, forenames, surname } = {},
      ...record
    }) => ({
      ...record,
      name: `[${collarNumber}] ${forenames} ${surname}`,
      areas: reduceAreas(areas),
    })
  );

  await db.personDailyActivity.clear();
  await db.personDailyActivity.bulkAdd(data);
  await db.parameters.put({ store: 'personDailyActivity', query });

  const filteredData = data.filter((record) =>
    personActivityDataFilter(record, filter)
  );

  const results = {
    filter,
    groupBy,
    ...getPersonActivityFilterAndGroupByValues(data, filter),
    data: getPersonDailyActivity(filteredData, groupBy),
  };

  log('Read', 'Person Daily Activity', query);

  return results;
}

export function fetchPersonDailyActivityEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON_DAILY_ACTIVITY),
    mergeMap(({ payload: { query, filter, groupBy } }) =>
      from(fetchPersonDailyActivityRequest(query, filter, groupBy)).pipe(
        map((payload) => ({
          type: FETCH_PERSON_DAILY_ACTIVITY_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_PERSON_DAILY_ACTIVITY_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PERSON_DAILY_ACTIVITY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function loadPersonDailyActivityRequest(filter, groupBy) {
  const data = await db.personDailyActivity.toArray();
  const parameters = await db.parameters.get('personDailyActivity');

  const filteredData = data.filter((record) =>
    personActivityDataFilter(record, filter)
  );

  const results = {
    filter,
    groupBy,
    ...getPersonActivityFilterAndGroupByValues(data, filter),
    data: getPersonDailyActivity(filteredData, groupBy),
  };

  log('Load', 'Person Daily Activity', parameters);

  return results;
}

export function loadPersonDailyActivityEpic(action$) {
  return action$.pipe(
    ofType(LOAD_PERSON_DAILY_ACTIVITY),
    mergeMap(({ payload: { filter, groupBy } }) =>
      from(loadPersonDailyActivityRequest(filter, groupBy)).pipe(
        map((payload) => ({
          type: LOAD_PERSON_DAILY_ACTIVITY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_PERSON_DAILY_ACTIVITY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

function getPersonHourlyActivity(rawData) {
  const groupedData =
    rawData.length === 0
      ? new Map()
      : rawData.reduce((accumulator, record) => {
          const hour = moment(record.time).hour();
          let current = accumulator.get(hour);

          if (!current) {
            current = {
              hour,
              loggedInSeconds: [],
              inBaseSeconds: [],
              respondingToIncidentSeconds: [],
              attendingObjectiveSeconds: [],
              tripsDurationSeconds: [],
              inHomeWardSeconds: [],
              doubleCrewingSeconds: [],
            };
            accumulator.set(hour, current);
          }

          current.loggedInSeconds.push(record.loggedInSeconds);
          current.inBaseSeconds.push(record.inBaseSeconds);
          current.respondingToIncidentSeconds.push(
            record.respondingToIncidentSeconds
          );
          current.attendingObjectiveSeconds.push(
            record.attendingObjectiveSeconds
          );
          current.tripsDurationSeconds.push(record.tripsDurationSeconds);
          current.inHomeWardSeconds.push(record.inHomeWardSeconds);
          current.doubleCrewingSeconds.push(record.doubleCrewingSeconds);

          return accumulator;
        }, new Map());

  const data = Array.from(groupedData.values()).map((group) => ({
    Hour: moment({ hour: group.hour }).format('HH:mm'),
    onRadio: _.round(
      group.loggedInSeconds.reduce((a, b) => a + b, 0) /
        group.loggedInSeconds.length /
        60,
      2
    ),
    inBase: _.round(
      group.inBaseSeconds.reduce((a, b) => a + b, 0) /
        group.inBaseSeconds.length /
        60,
      2
    ),
    respondingToIncidents: _.round(
      group.respondingToIncidentSeconds.reduce((a, b) => a + b, 0) /
        group.respondingToIncidentSeconds.length /
        60,
      2
    ),
    attendingObjectives: _.round(
      group.attendingObjectiveSeconds.reduce((a, b) => a + b, 0) /
        group.attendingObjectiveSeconds.length /
        60,
      2
    ),
    driving: _.round(
      group.tripsDurationSeconds.reduce((a, b) => a + b, 0) /
        group.tripsDurationSeconds.length /
        60,
      2
    ),
    inHomeWard: _.round(
      group.inHomeWardSeconds.reduce((a, b) => a + b, 0) /
        group.inHomeWardSeconds.length /
        60,
      2
    ),
    doubleCrewing: _.round(
      group.doubleCrewingSeconds.reduce((a, b) => a + b, 0) /
        group.doubleCrewingSeconds.length /
        60,
      2
    ),
  }));
  data.sort((a, b) => a.Hour.localeCompare(b.Hour));

  return data;
}

async function fetchPersonHourlyActivityRequest(query, filter) {
  const response = await api.get('/personHourlySummaries', {
    params: {
      query,
      projection: {
        time: true,
        person: true,
        loggedInSeconds: true,
        inBaseSeconds: true,
        attendingObjectiveSeconds: true,
        inHomeWardSeconds: true,
        doubleCrewingSeconds: true,
        baseVisits: true,
        respondingToIncidentSeconds: true,
        trips: true,
        tripsDurationSeconds: true,
        tripsDistanceKilometres: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const data = response.data.map(
    ({ areas, collarNumber, name, ...record }) => ({
      ...record,
      name: `[${collarNumber}] ${name}`,
      areas: reduceAreas(areas),
    })
  );

  await db.personHourlyActivity.clear();
  await db.personHourlyActivity.bulkAdd(data);
  await db.parameters.put({
    store: 'personHourlyActivity',
    query,
  });

  const filteredData = data.filter((record) =>
    personActivityDataFilter(record, filter)
  );

  const results = {
    filter,
    ...getPersonActivityFilterAndGroupByValues(data, filter),
    data: getPersonHourlyActivity(filteredData),
  };

  log('Read', 'Person Hourly Activity', query);

  return results;
}

export function fetchPersonHourlyActivityEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON_HOURLY_ACTIVITY),
    mergeMap(({ payload: { query, filter } }) =>
      from(fetchPersonHourlyActivityRequest(query, filter)).pipe(
        map((payload) => ({
          type: FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED),
            tap((ev) => cancel('cancelled'))
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PERSON_HOURLY_ACTIVITY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function loadPersonHourlyActivityRequest(filter) {
  const data = await db.personHourlyActivity.toArray();
  const parameters = await db.parameters.get('personHourlyActivity');

  const filteredData = data.filter((record) =>
    personActivityDataFilter(record, filter)
  );

  const results = {
    filter,
    ...getPersonActivityFilterAndGroupByValues(data, filter),
    data: getPersonHourlyActivity(filteredData),
  };

  log('Load', 'Person Hourly Activity', parameters);

  return results;
}

export function loadPersonHourlyActivityEpic(action$) {
  return action$.pipe(
    ofType(LOAD_PERSON_HOURLY_ACTIVITY),
    mergeMap(({ payload: filter }) =>
      from(loadPersonHourlyActivityRequest(filter)).pipe(
        map((payload) => ({
          type: LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_PERSON_HOURLY_ACTIVITY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}
