import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import {
  Error as ErrorIcon,
  Input as InputIcon,
  LocationSearching as LocationSearchingIcon,
  Navigation as NavigationIcon,
  VolumeUp as VolumeUpIcon,
  VpnKey as VpnKeyIcon,
  Warning as WarningIcon,
  WbIncandescent as WbIncandescentIcon,
} from '@material-ui/icons';
import {
  AlarmLight as AlarmLightIcon,
  Engine as EngineIcon,
  CarSide as CarSideIcon,
  RadioHandheld as RadioHandheldIcon,
} from 'mdi-material-ui';
import React, { Fragment } from 'react';
import { replayItemTypeIcons } from '../../data/constants';

const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  hoverListItem: {
    backgroundColor: theme.palette.action.hover,
  },
  selectedListItem: {
    backgroundColor: theme.palette.action.selected,
  },
  driverIcon: {
    fontSize: 16,
  },
  statusIcon: {
    fontSize: 16,
  },
  dashboard: {
    display: 'flex',
  },
  speed: {
    flex: 1,
    textAlign: 'right',
    fontSize: 12,
    marginRight: theme.spacing(0.5),
  },
  speedAvatar: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: 8,
    fontWeight: 'bold',
    borderWidth: 2,
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    color: 'black',
  },
  assumedSpeedAvatar: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: 8,
    fontWeight: 'bold',
    borderWidth: 2,
    borderColor: theme.palette.grey[400],
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    color: 'black',
  },
}));

const groupHeaders = new Map([
  ['vehicles', 'Vehicles'],
  ['locations', 'Locations'],
  ['events', 'Events'],
  ['people', 'People'],
  ['incidents', 'Incidents'],
]);

export default function ReplayItemList({
  featureCollections,
  followedItemIndexes,
  selectedItemIndex,
  hoveredItemIndex,
  onHoverItem,
  onSelectItem,
  onFollowedItemsChange,
}) {
  const classes = useStyles();
  const theme = useTheme();

  function handleFollowToggle(id, type) {
    const index = followedItemIndexes.findIndex(
      (followedItem) => followedItem.type === type && followedItem.id === id
    );

    if (index === -1) {
      onFollowedItemsChange(followedItemIndexes.concat({ id, type }));
    } else {
      onFollowedItemsChange(
        followedItemIndexes
          .slice(0, index)
          .concat(followedItemIndexes.slice(index + 1))
      );
    }
  }

  function Item({ item }) {
    const {
      type,
      registrationNumber,
      fleetNumber,
      driver,
      speedKilometresPerHour,
      headingDegrees,
      ignitionOn,
      sirensOn,
      beaconsOn,
      malfunctionIndicatorLightOn,
      accelerometerAlert,
      rearBlueLightsOn,
      rearRedLightsOn,
      strikeButtonOn,
      frontPWEOn,
      rearPWEOn,
      airwaveOn,
      ancillaryEquipmentOn,
      forenames,
      surname,
      collarNumber,
      name,
      subtype,
      reverseGeocode,
    } = item.properties;

    switch (type) {
      case 'vehicle':
        return (
          <ListItem
            button
            key={item.id}
            className={
              item.id === selectedItemIndex.id &&
              type === selectedItemIndex.type
                ? classes.selectedListItem
                : item.id === hoveredItemIndex.id &&
                  type === hoveredItemIndex.type
                ? classes.hoverListItem
                : null
            }
            onMouseEnter={() => onHoverItem({ id: item.id, type })}
            onMouseLeave={() => onHoverItem({})}
            onClick={() => {
              onSelectItem({ id: item.id, type });
              onHoverItem({});
            }}
          >
            <ListItemAvatar>
              <Avatar>{replayItemTypeIcons[type]}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                useReducedResourceInformation ? fleetNumber : registrationNumber
              }
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <Fragment>
                  {driver &&
                    (useReducedResourceInformation
                      ? driver.code
                      : `${driver.forenames} ${driver.surname} [$
                      {driver.collarNumber}]`)}
                  <div className={classes.dashboard}>
                    <NavigationIcon
                      className={classes.statusIcon}
                      style={{
                        transform: `rotate(${headingDegrees}deg)`,
                      }}
                      color="action"
                    />
                    {ignitionOn !== undefined && (
                      <VpnKeyIcon
                        className={classes.statusIcon}
                        htmlColor={
                          ignitionOn
                            ? amber[700]
                            : theme.palette.action.disabled
                        }
                      />
                    )}
                    {sirensOn !== undefined && (
                      <VolumeUpIcon
                        className={classes.statusIcon}
                        htmlColor={
                          sirensOn ? amber[700] : theme.palette.action.disabled
                        }
                      />
                    )}
                    {beaconsOn !== undefined && (
                      <AlarmLightIcon
                        className={classes.statusIcon}
                        htmlColor={
                          beaconsOn ? amber[700] : theme.palette.action.disabled
                        }
                      />
                    )}
                    {malfunctionIndicatorLightOn !== undefined && (
                      <EngineIcon
                        className={classes.statusIcon}
                        htmlColor={
                          malfunctionIndicatorLightOn
                            ? amber[700]
                            : theme.palette.action.disabled
                        }
                      />
                    )}
                    {accelerometerAlert !== undefined && (
                      <WarningIcon
                        className={classes.statusIcon}
                        htmlColor={
                          accelerometerAlert
                            ? amber[700]
                            : theme.palette.action.disabled
                        }
                      />
                    )}
                    {rearBlueLightsOn !== undefined && (
                      <WbIncandescentIcon
                        className={classes.statusIcon}
                        color={rearBlueLightsOn ? 'primary' : 'disabled'}
                      />
                    )}
                    {rearRedLightsOn !== undefined && (
                      <WbIncandescentIcon
                        className={classes.statusIcon}
                        color={rearRedLightsOn ? 'error' : 'disabled'}
                      />
                    )}
                    {strikeButtonOn !== undefined && (
                      <ErrorIcon
                        className={classes.statusIcon}
                        htmlColor={
                          strikeButtonOn
                            ? amber[700]
                            : theme.palette.action.disabled
                        }
                      />
                    )}
                    {(frontPWEOn !== undefined || rearPWEOn !== undefined) && (
                      <CarSideIcon
                        className={classes.statusIcon}
                        component={(svgProps) => {
                          return (
                            <svg {...svgProps}>
                              <defs>
                                <linearGradient id="gradient1">
                                  <stop
                                    offset="50%"
                                    stopColor={
                                      rearPWEOn
                                        ? theme.palette.primary.dark
                                        : theme.palette.action.disabled
                                    }
                                  />
                                  <stop
                                    offset="50%"
                                    stopColor={
                                      frontPWEOn
                                        ? theme.palette.primary.dark
                                        : theme.palette.action.disabled
                                    }
                                  />
                                </linearGradient>
                              </defs>
                              {React.cloneElement(svgProps.children[0], {
                                fill: 'url(#gradient1)',
                              })}
                            </svg>
                          );
                        }}
                      />
                    )}
                    {airwaveOn !== undefined && (
                      <RadioHandheldIcon
                        className={classes.statusIcon}
                        htmlColor={
                          airwaveOn ? amber[700] : theme.palette.action.disabled
                        }
                      />
                    )}
                    {ancillaryEquipmentOn !== undefined && (
                      <InputIcon
                        className={classes.statusIcon}
                        htmlColor={
                          ancillaryEquipmentOn
                            ? amber[700]
                            : theme.palette.action.disabled
                        }
                      />
                    )}
                    <Typography className={classes.speed}>
                      {`${Math.round(speedKilometresPerHour * 0.62137119)} MPH`}
                    </Typography>
                    {reverseGeocode &&
                      reverseGeocode.speedLimitKilometresPerHour && (
                        <Avatar
                          className={
                            reverseGeocode.unknownLimit
                              ? classes.assumedSpeedAvatar
                              : classes.speedAvatar
                          }
                        >
                          {Math.round(
                            reverseGeocode.speedLimitKilometresPerHour *
                              0.62137119
                          )}
                        </Avatar>
                      )}{' '}
                  </div>
                </Fragment>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Follow"
                onClick={() => handleFollowToggle(item.id, type)}
              >
                <LocationSearchingIcon
                  fontSize="small"
                  color={
                    followedItemIndexes.some(
                      (followedItem) =>
                        followedItem.type === type &&
                        followedItem.id === item.id
                    )
                      ? 'primary'
                      : 'disabled'
                  }
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      case 'person':
        return (
          <ListItem
            button
            key={item.id}
            className={
              item.id === selectedItemIndex.id &&
              type === selectedItemIndex.type
                ? classes.selectedListItem
                : item.id === hoveredItemIndex.id &&
                  type === hoveredItemIndex.type
                ? classes.hoverListItem
                : null
            }
            onMouseEnter={() => onHoverItem({ id: item.id, type })}
            onMouseLeave={() => onHoverItem({})}
            onClick={() => {
              onSelectItem({ id: item.id, type });
              onHoverItem({});
            }}
          >
            <ListItemAvatar>
              <Avatar>{replayItemTypeIcons[type]}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                forenames &&
                `${forenames} ${surname} ${collarNumber && `[${collarNumber}]`}`
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Follow"
                onClick={() => handleFollowToggle(item.id, type)}
              >
                <LocationSearchingIcon
                  fontSize="small"
                  color={
                    followedItemIndexes.some(
                      (followedItem) =>
                        followedItem.type === type &&
                        followedItem.id === item.id
                    )
                      ? 'primary'
                      : 'disabled'
                  }
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      case 'location':
        return (
          <ListItem
            button
            key={item.id}
            className={
              item.id === selectedItemIndex.id &&
              type === selectedItemIndex.type
                ? classes.selectedListItem
                : item.id === hoveredItemIndex.id &&
                  type === hoveredItemIndex.type
                ? classes.hoverListItem
                : null
            }
            onMouseEnter={() => onHoverItem({ id: item.id, type })}
            onMouseLeave={() => onHoverItem({})}
            onClick={() => {
              onSelectItem({ id: item.id, type });
              onHoverItem({});
            }}
          >
            <ListItemAvatar>
              <Avatar>{replayItemTypeIcons[type]}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={name} secondary={subtype} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Follow"
                onClick={() => handleFollowToggle(item.id, type)}
              >
                <LocationSearchingIcon
                  fontSize="small"
                  color={
                    followedItemIndexes.some(
                      (followedItem) =>
                        followedItem.type === type &&
                        followedItem.id === item.id
                    )
                      ? 'primary'
                      : 'disabled'
                  }
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      default:
        return '';
    }
  }

  return (
    <List className={classes.list} dense disablePadding>
      {Object.entries(featureCollections || {}).map((group) => (
        <div key={group[0]}>
          <ListSubheader disableSticky>
            {groupHeaders.get(group[0])}
          </ListSubheader>
          {group[1].features.map((item) => (
            <Item item={item} key={item.id} />
          ))}
        </div>
      ))}
    </List>
  );
}
