import {
  FETCH_QUERY,
  FETCH_COLLECTION,
  FETCH_COLLECTION_SUCCESS,
  FETCH_COLLECTION_FAILURE,
  FETCH_COLLECTIONS,
  FETCH_COLLECTIONS_SUCCESS,
  FETCH_COLLECTIONS_FAILURE,
  UPDATE_COLLECTION,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAILURE,
  CREATE_COLLECTION,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_FAILURE,
  DELETE_COLLECTION,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAILURE,
  FETCH_SELECTION_EVENTS,
  FETCH_QUERY_EVENTS,
  FETCH_SELECTION_EVENTS_SUCCESS,
  FETCH_SELECTION_EVENTS_FAILURE,
  UPDATE_SELECTION_EVENT,
  UPDATE_SELECTION_EVENT_SUCCESS,
  UPDATE_SELECTION_EVENT_FAILURE,
  CLEAR_QUERY_EVENTS,
  CLEAR_SELECTION_EVENTS,
} from '../actions';

const INITIAL_STATE = {
  collectionNames: [],
  collection: null,
  isLoading: false,
  error: null,
  events: null,
  selectedEventIndex: null,
  hoverEventIndex: null,
};

export default function collectionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COLLECTIONS:
      return { ...state, isLoading: true, error: null };
    case FETCH_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collectionNames: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_COLLECTIONS_FAILURE:
      return {
        ...state,
        collectionNames: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_QUERY:
    case FETCH_COLLECTION:
      return { ...state, isLoading: true, error: null };
    case FETCH_COLLECTION_SUCCESS:
      return {
        ...state,
        collection: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_COLLECTION_FAILURE:
      return {
        ...state,
        collection: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_COLLECTION:
      return { ...state, isLoading: true, error: null };
    case UPDATE_COLLECTION_SUCCESS:
      const updatedCollections = state.collectionNames.filter(
        (collection) => collection.identifier !== action.payload.identifier
      );
      return {
        ...state,
        collection: action.payload,
        collectionNames: [...updatedCollections, action.payload],
        isLoading: false,
        error: null,
      };
    case UPDATE_COLLECTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_COLLECTION:
      return { ...state, isLoading: true, error: null };
    case CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        collection: action.payload,
        collectionNames: [...state.collectionNames, action.payload],
        isLoading: false,
        error: null,
      };
    case CREATE_COLLECTION_FAILURE:
      return {
        ...state,
        collection: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_COLLECTION:
      return { ...state, isLoading: true, error: null };
    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        collection: null,
        collectionNames: state.collectionNames.filter(
          (collection) => collection.identifier !== action.payload
        ),
        isLoading: false,
        error: null,
      };
    case DELETE_COLLECTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_SELECTION_EVENTS:
    case FETCH_QUERY_EVENTS:
      return { ...state, isLoading: true, error: null };
    case FETCH_SELECTION_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_SELECTION_EVENTS_FAILURE:
      return {
        ...state,
        events: null,
        isLoading: false,
        error: action.payload,
      };
    case CLEAR_QUERY_EVENTS:
    case CLEAR_SELECTION_EVENTS:
      return { ...state, events: null };
    case UPDATE_SELECTION_EVENT:
      return { ...state, isLoading: true, error: null };
    case UPDATE_SELECTION_EVENT_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          features: state.events.features
            .slice(0, action.payload.id)
            .concat(action.payload)
            .concat(state.events.features.slice(action.payload.id + 1)),
        },
        isLoading: false,
        error: null,
      };
    case UPDATE_SELECTION_EVENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
