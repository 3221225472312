import {
  Avatar,
  Button,
  Collapse,
  Fab,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import { format } from 'date-fns';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../Auth';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, Route, useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Helmet } from 'react-helmet-async';
import { FixedSizeList } from 'react-window';
import { GeoJSON, WKT } from 'ol/format';
import { FETCH_LOCATIONS } from '../../actions';
import { locationTypes } from '../../data/constants';
import Container from '../Container';
import Location from './Location';
import {
  downloadCSV,
  downloadGeoJSON,
  getLocations,
} from '../../apis/utilities';
import { SearchBox } from '../controls';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '100%',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  list: {
    overflow: 'none',
    flex: 1,
  },
  active: {
    backgroundColor: theme.palette.action.focus,
  },
  toolbar: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(1),
    },
  },
  searchBox: {
    width: '100%',
    padding: theme.spacing(1, 0, 1, 1),
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  settingsPanel: {
    padding: theme.spacing(0, 1, 1, 1),
  },
  sortAsc: {
    transform: 'rotate(0deg)',
    // marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  sortDesc: {
    transform: 'rotate(180deg)',
  },
  sortTextField: {
    paddingBottom: theme.spacing(1),
  },
  exportButton: {
    margin: theme.spacing(1, 1, 1, 0),
  },
}));

const locationHeaders = [
  { label: 'Code', key: 'code' },
  { label: 'Name', key: 'name' },
  { label: 'Type', key: 'type' },
  { label: 'Subtype', key: 'subtype' },
  { label: 'Start Time', key: 'startTime' },
  { label: 'End Time', key: 'endTime' },
  { label: 'Boundary WKT', key: 'boundaryWkt' },
];

async function getLocationsAndHeaders(type) {
  const data = await getLocations(type);

  const areas = Array.from(
    new Set(
      [].concat(
        ...data.map((record) => (record.areas || []).map((area) => area.type))
      )
    )
  );

  const locations = data.map(
    ({ areas, boundary, startTime, endTime, ...vehicle }) => {
      const geometry = new GeoJSON().readGeometry(boundary, {
        // featureProjection: 'EPSG:3857',
      });

      const boundaryWkt = new WKT().writeGeometry(geometry, {
        dataProjection: 'EPSG:4326',
        rightHanded: true,
      });

      return {
        ...vehicle,
        boundaryWkt,
        startTime: startTime
          ? format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss')
          : '',
        endTime: endTime
          ? format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss')
          : '',
        ...(areas || []).reduce((accumulator, area) => {
          if (area.name !== undefined) {
            accumulator[area.type] = area.name;
          }

          return accumulator;
        }, {}),
      };
    }
  );

  return {
    locations,
    headers: [
      ...locationHeaders,
      ...areas.map((area) => ({
        label: _.startCase(area),
        key: area,
        type: 'text',
      })),
    ],
  };
}

export default function LocationList() {
  const { id, locationType, report } = useParams();
  const dispatch = useDispatch();
  const locations = useSelector(
    (state) => state.locations.locationNames,
    _.isEqual
  );
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState('name');
  const [sortDesc, setSortDesc] = useState(false);
  const [filter, setFilter] = useState('all');
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const auth = useAuth();
  const editable = auth.isAuthorised('vehicles', true);

  const sortOptions = [
    { label: 'Name', value: 'name' },
    { label: 'Code', value: 'code' },
  ];

  const filters = [{ label: 'All', value: 'all' }];

  useEffect(() => {
    dispatch({
      type: FETCH_LOCATIONS,
      payload: locationTypes[locationType].name,
    });
  }, [dispatch, locationType]);

  const filteredList = locations
    .filter((item) => {
      switch (filter) {
        case 'all':
          return true;
        default:
          return true;
      }
    })
    .filter(
      (item) =>
        item.searchString.indexOf(searchText.toLowerCase()) > -1 ||
        searchText === ''
    )
    .sort(
      (a, b) =>
        (a[sortBy] || '').localeCompare(b[sortBy] || '') * (sortDesc ? -1 : 1)
    );

  function Row({ data, index, style }) {
    const item = data[index];

    return (
      <ListItem
        dense
        button
        key={index}
        style={style}
        component={NavLink}
        to={`/locations/${locationType}/${encodeURIComponent(item.code)}`}
        activeClassName={classes.active}
      >
        <ListItemAvatar>
          <Avatar src={item.picture}>{locationTypes[locationType].icon}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={item.name}
          secondary={item.code}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>
    );
  }

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }

  function handleFilterChange(event) {
    setFilter(event.target.value);
  }

  async function handleCsvClick() {
    const data = await getLocationsAndHeaders(locationTypes[locationType].name);
    downloadCSV(data.locations, 'locations.csv', data.headers);
  }

  async function handleGeoJsonClick() {
    const data = await getLocations(locationTypes[locationType].name);
    downloadGeoJSON(data, 'locations.json');
  }

  return (
    <Container
      title={`${locationTypes[locationType].name}s`}
      showBack={!!report}
    >
      <div className={classes.page}>
        <Helmet>
          <title>{`IR3 | ${locationTypes[locationType].name}s`}</title>
        </Helmet>
        {(!isXs || !id) && (
          <div className={classes.sideBar}>
            <div className={classes.searchBar}>
              <div className={classes.toolbar}>
                <SearchBox
                  value={searchText}
                  onChange={handleSearchChange}
                  className={classes.searchBox}
                  endAdornment={
                    <InputAdornment position="start">
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.count}
                      >
                        {`${filteredList.length}/${locations.length}`}
                      </Typography>
                    </InputAdornment>
                  }
                />
                <IconButton
                  title={showSettings ? 'Hide settings' : 'Show settings'}
                  onClick={handleSettingsToggle}
                >
                  <SettingsIcon color={showSettings ? 'primary' : 'inherit'} />
                </IconButton>
              </div>
            </div>
            <Collapse in={showSettings} unmountOnExit>
              <div className={classes.settingsPanel}>
                <TextField
                  className={classes.sortTextField}
                  select
                  fullWidth
                  label="Filter"
                  value={filter}
                  onChange={handleFilterChange}
                >
                  {filters.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className={classes.sortTextField}
                  select
                  fullWidth
                  label="Sort by"
                  value={sortBy}
                  onChange={handleSortByChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          title={sortDesc ? 'Descending' : 'Ascending'}
                          className={clsx(classes.sortAsc, {
                            [classes.sortDesc]: sortDesc,
                          })}
                          onClick={handleSortToggle}
                        >
                          <ArrowUpwardIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  {sortOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  Export
                </Typography>
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    className={classes.exportButton}
                    onClick={handleCsvClick}
                  >
                    CSV
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    disableElevation
                    className={classes.exportButton}
                    onClick={handleGeoJsonClick}
                  >
                    GEOJSON
                  </Button>
                </div>
              </div>
            </Collapse>
            {filteredList.length > 0 && (
              <div className={classes.list}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      width={width}
                      height={height}
                      overscanCount={10}
                      itemData={filteredList}
                      itemCount={filteredList.length}
                      itemSize={56}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            )}
            {!report && editable && (
              <Fab
                color="secondary"
                aria-label="add"
                className={classes.fab}
                component={Link}
                to={`/locations/${locationType}/new`}
              >
                <AddIcon />
              </Fab>
            )}
          </div>
        )}
        {(!isXs || id) && (
          <div className={classes.main}>
            <Route exact path="/locations/:locationType/:id">
              <Location />
            </Route>
          </div>
        )}
      </div>
    </Container>
  );
}
