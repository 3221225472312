import React, { Fragment, useState } from 'react';
import { Button, IconButton, Popover, makeStyles } from '@material-ui/core';
import {
  Autorenew as AutorenewIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';
import { downloadCSV, downloadGeoJSON } from '../../apis/utilities';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1, 0, 1, 1),
  },
  default: {
    marginRight: theme.spacing(1),
  },
  loading: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    color: theme.palette.common.white,
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  spinIcon: {
    animation: '$spin 2000ms linear infinite',
  },
}));

export default function ExportPicker({
  fetchDataToConvert,
  filename,
  headers,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFetchingCSV, setFetchingCSV] = useState(false);
  const [isFetchingGeoJSON, setFetchingGeoJSON] = useState(false);

  function handleClick(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event) {
    event.stopPropagation();
    setAnchorEl(null);
  }

  async function handleCSVClick(event) {
    event.stopPropagation();
    setFetchingCSV(true);
    const data = await fetchDataToConvert();
    downloadCSV(data, `${filename}.csv`, headers);
    setFetchingCSV(false);
  }

  async function handleGeoJSONClick(event) {
    event.stopPropagation();
    setFetchingGeoJSON(true);
    const data = await fetchDataToConvert();
    downloadGeoJSON(data, `${filename}.json`);
    setFetchingGeoJSON(false);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'type-popover' : undefined;

  return (
    <Fragment>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        title={'Download Polls'}
      >
        <GetAppIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.container}>
          <Button
            color="primary"
            title="CSV is a format you can use in spreadsheets"
            variant="contained"
            className={isFetchingCSV ? classes.loading : classes.default}
            onClick={handleCSVClick}
            endIcon={
              <AutorenewIcon
                className={isFetchingCSV ? classes.spinIcon : undefined}
              />
            }
          >
            {isFetchingCSV ? 'Cancel' : 'CSV'}
          </Button>
          <Button
            color="secondary"
            title="GeoJSON is a format you can use in GIS applications"
            variant="contained"
            className={isFetchingGeoJSON ? classes.loading : classes.default}
            onClick={handleGeoJSONClick}
            endIcon={
              <AutorenewIcon
                className={isFetchingGeoJSON ? classes.spinIcon : undefined}
              />
            }
          >
            {isFetchingGeoJSON ? 'Cancel' : 'GEOJSON'}
          </Button>
        </div>
      </Popover>
    </Fragment>
  );
}
