import {
  Paper,
  IconButton,
  TablePagination,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  GetApp as GetAppIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from '../Snackbar';
import {
  FETCH_PERSON_LOCATION_VISITS,
  FETCH_PERSON_LOCATION_VISITS_CANCELLED,
  UPDATE_PERSON_LOCATION_VISITS_FILTER,
} from '../../actions';
import Container from '../Container';
import { Parameters, Table, ExportPicker, FilterPicker } from '../controls';
import {
  downloadCSV,
  getPersonPolls,
  shortPersonHeaders,
  radioPollHeaders,
  eventsPersonTableHeaders,
} from '../../apis/utilities';
import { filterLocally } from '../../data/utilities';

const { rowsPerPageOptions, useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  filterField: {
    width: 144,
  },
  progress: {
    margin: theme.spacing(1),
  },
  placeholder: {
    color: theme.palette.grey[500],
  },
  popoverSheet: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
  linkButton: {
    padding: 0,
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const csvHeaders = [...shortPersonHeaders, ...radioPollHeaders];

const headers = [
  ...eventsPersonTableHeaders,
  { label: 'Location Type', key: 'locationType', type: 'text', filter: true },
  { label: 'Location Name', key: 'locationName', type: 'text', filter: true },
  { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
  { label: 'End Time', key: 'endTime', type: 'date', filter: false },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    type: 'number',
    filter: false,
  },
];

function ReplayLink({ entry }) {
  const history = useHistory();

  const handleViewClick = (identifier) => () => {
    history.push(`/replay/personLocationIntersections/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

function DownloadPersonPollsLink({ entry }) {
  async function fetchDataToConvert() {
    const polls = await getPersonPolls(
      entry.ssi,
      entry.startTime,
      entry.endTime
    );

    const data = polls.map((feature) => ({
      ...(useReducedResourceInformation
        ? { staffId: entry.code }
        : {
            name: entry.name,
            personRole: entry.role,
            collarNumber: entry.collarNumber,
          }),
      ...feature,
      time: new Date(feature.time),
      locationType: entry.locationType,
      locationName: entry.locationName,
      startTime: new Date(entry.startTime),
      endTime: new Date(entry.endTime),
      durationMinutes: entry.durationMinutes,
      radioSsi: feature.ssi,
      longitude: feature.position ? feature.position.coordinates[0] : 0,
      latitude: feature.position ? feature.position.coordinates[1] : 0,
    }));

    return data;
  }

  return (
    <ExportPicker
      title="download"
      fetchDataToConvert={fetchDataToConvert}
      filename="Person Location Visit"
      headers={csvHeaders}
    />
  );
}

const replayColumn = {
  label: '',
  key: 'replay',
  type: 'component',
  component: ReplayLink,
};

const downloadColumn = {
  label: '',
  key: 'download',
  type: 'component',
  component: DownloadPersonPollsLink,
};

export default function PersonLocationVisits() {
  const dispatch = useDispatch();
  const visits = useSelector(
    (state) => state.events.personLocationVisits.list,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.events.personLocationVisits.isLoading
  );
  const error = useSelector((state) => state.events.personLocationVisits.error);
  const filter = useSelector(
    (state) => state.events.personLocationVisits.filter,
    _.isEqual
  );
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_PERSON_LOCATION_VISITS,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_PERSON_LOCATION_VISITS_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_PERSON_LOCATION_VISITS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const filename = 'Person Location Visits.csv';
    const data = filteredVisits.map((visit) => ({
      'person.code': visit.person.code,
      'person.collarNumber': visit.person.collarNumber,
      'person.forenames': visit.person.forenames,
      'person.surname': visit.person.surname,
      'person.role': visit.person.role,
      locationType: visit.locationType,
      locationName: visit.locationName,
      startTime: new Date(visit.startTime),
      endTime: new Date(visit.endTime),
      durationMinutes: _.round(visit.durationMinutes || 0.0, 2),
    }));

    downloadCSV(data, filename, headers);
  }

  const filteredVisits = filterLocally(filter, visits);

  return (
    <Container title="Person Location Visits">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        className={classes.parameters}
        person
        location
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Person Location Visits</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Person Location Visits
          </Typography>
          <FilterPicker
            headers={headers}
            data={visits}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            disabled={filteredVisits.length === 0}
            title="Download"
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={filteredVisits}
            headers={headers.concat(replayColumn).concat(downloadColumn)}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredVisits.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}
