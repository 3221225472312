import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  ArrowDropDown as ArrowDropDownIcon,
  // FormatAlignLeft as FormatAlignLeftIcon,
  // FormatAlignCenter as FormatAlignCenterIcon,
  // FormatAlignRight as FormatAlignRightIcon,
  // FormatAlignJustify as FormatAlignJustifyIcon,
  FormatBold as FormatBoldIcon,
  FormatColorText as FormatColorTextIcon,
  FormatItalic as FormatItalicIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  Image as ImageIcon,
  Link as LinkIcon,
  Code as CodeIcon,
  FormatQuote as FormatQuoteIcon,
  FormatListNumbered as FormatListNumberedIcon,
  FormatListBulleted as FormatListBulletedIcon,
  Undo as UndoIcon,
  Redo as RedoIcon,
} from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {
  CodeNotEqualVariant as CodeNotEqualVariantIcon,
  Marker as MarkerIcon,
} from 'mdi-material-ui';
import {
  AtomicBlockUtils,
  CompositeDecorator,
  ContentState,
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  getDefaultKeyBinding,
} from 'draft-js';
import _ from 'lodash';
import ColorPicker from './ColorPicker';
import 'draft-js/dist/Draft.css';

const headerTypes = [
  { label: 'Normal', value: 'unstyled' },
  { label: 'Paragraph', value: 'paragraph' },
  { label: 'Heading 1', value: 'header-one' },
  { label: 'Heading 2', value: 'header-two' },
  { label: 'Heading 3', value: 'header-three' },
  { label: 'Heading 4', value: 'header-four' },
  { label: 'Heading 5', value: 'header-five' },
  { label: 'Heading 6', value: 'header-six' },
];

const blockTypes = [
  {
    title: 'Bulleted List',
    style: 'unordered-list-item',
    Icon: FormatListBulletedIcon,
  },
  {
    title: 'Numbered List',
    style: 'ordered-list-item',
    Icon: FormatListNumberedIcon,
  },
  { title: 'Quote Block', style: 'blockquote', Icon: FormatQuoteIcon },
  { title: 'Code Block', style: 'code-block', Icon: CodeNotEqualVariantIcon },
];

const inlineStyles = [
  { title: 'Bold', style: 'BOLD', Icon: FormatBoldIcon },
  { title: 'Italic', style: 'ITALIC', Icon: FormatItalicIcon },
  { title: 'Underline', style: 'UNDERLINE', Icon: FormatUnderlinedIcon },
  { title: 'Monospace', style: 'CODE', Icon: CodeIcon },
];

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderRadius: theme.spacing(0.5),
  },
  editor: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
  standalone: {
    border: 'none',
    margin: theme.spacing(0.5),
    textTransform: 'none',
  },
  headerlabel: {
    width: 66,
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

function Link({ contentState, entityKey, children }) {
  const { url } = contentState.getEntity(entityKey).getData();

  return <a href={url}>{children}</a>;
}

function findImageEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'IMAGE'
    );
  }, callback);
}

function Image({ contentState, entityKey }) {
  const { src } = contentState.getEntity(entityKey).getData();
  const style = {
    maxWidth: '100%',
    height: 'auto',
  };

  return <img src={src} style={style} alt="" />;
}

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
  {
    strategy: findImageEntities,
    component: Image,
  },
]);

const colorStyleMap = Object.assign(
  {},
  ...[].concat(
    ...Object.entries(colors)
      .slice(1)
      .map((hue) =>
        Object.entries(hue[1]).map((shade) => ({
          [`color-${shade[1]}`]: { color: shade[1] },
          [`backgroundColor-${shade[1]}`]: { backgroundColor: shade[1] },
        }))
      )
  )
);

const styleMap = {
  ...colorStyleMap,
};

export default function ContentEditor({
  placeholder,
  value,
  onChange,
  className,
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [headerAnchorEl, setHeaderAnchorEl] = useState(null);
  const [colorAnchorEl, setColorAnchorEl] = useState(null);
  const [colorType, setColorType] = useState('color');
  const [url, setUrl] = useState('');
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const editor = useRef(null);
  const classes = useStyles();
  const currentStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const focus = () => editor.current.focus();
  const reader = new FileReader();

  reader.onabort = () => console.log('file reading was aborted');
  reader.onerror = () => console.log('file reading has failed');
  reader.onload = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'IMAGE',
      'IMMUTABLE',
      { src: reader.result }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
    );

    setTimeout(() => focus(), 0);
  };

  // console.log(currentStyle.toJSON());
  const colorKey = currentStyle.find((styleKey) =>
    styleKey.startsWith('color-')
  );
  const backgroundColorKey = currentStyle.find((styleKey) =>
    styleKey.startsWith('backgroundColor-')
  );
  const currentColors = {
    color: colorKey ? colorStyleMap[colorKey].color : null,
    backgroundColor: backgroundColorKey
      ? colorStyleMap[backgroundColorKey].backgroundColor
      : null,
  };

  useEffect(() => {
    if (typeof value === 'string') {
      const blocksFromHTML = convertFromHTML(value);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      setEditorState(EditorState.createWithContent(contentState, decorator));
    } else {
      // this does not work but might be worth revisiting
      // const newState = EditorState.createWithContent(
      //   convertFromRaw(value),
      //   decorator
      // );
      // if (newState !== editorState) {
      //   setEditorState(newState);
      // }

      if (!_.isEqual(value, convertToRaw(editorState.getCurrentContent()))) {
        setEditorState(
          EditorState.createWithContent(convertFromRaw(value), decorator)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleChange(state) {
    setEditorState(state);

    onChange(convertToRaw(state.getCurrentContent()));
  }

  function preventDefault(event) {
    event.preventDefault();
  }

  // function handlePastedText(text, html, editorState) {
  //   console.log(text, html);
  // }

  function handlePastedFiles(files) {
    reader.readAsDataURL(files[0]);
  }

  function handleDroppedFiles(selection, files) {
    reader.readAsDataURL(files[0]);
  }

  function handleKeyCommand(command, state) {
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  function mapKeyToEditorCommand(event) {
    if (event.keyCode === 9 /* TAB */) {
      const newState = RichUtils.onTab(event, editorState, 4 /* maxDepth */);
      if (newState !== editorState) {
        setEditorState(newState);
      }
      return;
    }
    return getDefaultKeyBinding(event);
  }

  function handleBlockTypeClick(event) {
    setEditorState(
      RichUtils.toggleBlockType(editorState, event.currentTarget.value)
    );
  }

  function handleInlineStyleClick(event) {
    setEditorState(
      RichUtils.toggleInlineStyle(editorState, event.currentTarget.value)
    );

    // setTimeout(() => focus(), 0);
  }

  function handleUndoClick(event) {
    setEditorState(EditorState.undo(editorState));

    // setTimeout(() => focus(), 0);
  }

  function handleRedoClick(event) {
    setEditorState(EditorState.redo(editorState));

    // setTimeout(() => focus(), 0);
  }

  function handleHeaderClick(event) {
    setHeaderAnchorEl(event.currentTarget);
  }

  function handleHeaderClose() {
    setHeaderAnchorEl(null);
  }

  const handleHeaderMenuItemClick = (value) => () => {
    setEditorState(RichUtils.toggleBlockType(editorState, value));
    setHeaderAnchorEl(null);

    setTimeout(() => focus(), 0);
  };

  const handleColorClick = (colorType) => (event) => {
    setColorType(colorType);
    setColorAnchorEl(event.currentTarget);
  };

  //TODO: This still needs some work as it can get into a muddle with more than one colour being applied at a time thus making it hard to remove
  function handleColorChange(color) {
    // console.log(color);

    setEditorState(
      RichUtils.toggleInlineStyle(
        currentColors[colorType] !== null && currentColors[colorType] !== color
          ? RichUtils.toggleInlineStyle(
              editorState,
              `${colorType}-${currentColors[colorType]}`
            )
          : editorState,
        `${colorType}-${color}`
      )
    );
    setColorAnchorEl(null);
  }

  function handleColorClose(event, reason) {
    setColorAnchorEl(null);

    setTimeout(() => focus(), 0);
  }

  function handleLinkClick(event) {
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let linkUrl = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        linkUrl = linkInstance.getData().url;
      }

      setUrl(linkUrl);
      setLinkDialogOpen(true);
    }
  }

  const handleLinkDialogClose = (ok) => () => {
    if (ok) {
      let prefixedUrl = url;
      if (!/^https?:\/\//i.test(url)) {
        prefixedUrl = 'http://' + url;
      }
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url: prefixedUrl }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });
      setEditorState(
        RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey
        )
      );
    }

    setUrl('');
    setLinkDialogOpen(false);

    setTimeout(() => focus(), 0);
  };

  function handleUrlChange(event) {
    setUrl(event.target.value);
  }

  function handleFileChanged(event) {
    reader.readAsDataURL(event.target.files[0]);
  }

  return (
    <div className={className}>
      <div className={classes.container}>
        <div className={classes.toolbar}>
          <StyledToggleButtonGroup size="small">
            <ToggleButton
              value="undo"
              title="Undo"
              disabled={editorState.getUndoStack().size === 0}
              onMouseDown={preventDefault}
              onClick={handleUndoClick}
            >
              <UndoIcon />
            </ToggleButton>
            <ToggleButton
              value="redo"
              title="Redo"
              disabled={editorState.getRedoStack().size === 0}
              onMouseDown={preventDefault}
              onClick={handleRedoClick}
            >
              <RedoIcon />
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Divider
            flexItem
            orientation="vertical"
            className={classes.divider}
          />
          <ToggleButton
            title="Style"
            value="header"
            className={classes.standalone}
            size="small"
            onClick={handleHeaderClick}
          >
            <Typography variant="subtitle2" className={classes.headerlabel}>
              {
                (
                  headerTypes.find((type) => type.value === blockType) ||
                  headerTypes[0]
                ).label
              }
            </Typography>
            <ArrowDropDownIcon />
          </ToggleButton>
          <Menu
            anchorEl={headerAnchorEl}
            keepMounted
            open={Boolean(headerAnchorEl)}
            onClose={handleHeaderClose}
          >
            {headerTypes.map(({ label, value }) => (
              <MenuItem key={value} onClick={handleHeaderMenuItemClick(value)}>
                {label}
              </MenuItem>
            ))}
          </Menu>
          <Divider
            flexItem
            orientation="vertical"
            className={classes.divider}
          />
          <ToggleButton
            title="Colour"
            value="color"
            className={classes.standalone}
            size="small"
            onMouseDown={preventDefault}
            onClick={handleColorClick('color')}
          >
            <FormatColorTextIcon />
            <ArrowDropDownIcon />
          </ToggleButton>
          <ToggleButton
            title="Highlight"
            value="highlight"
            className={classes.standalone}
            size="small"
            onMouseDown={preventDefault}
            onClick={handleColorClick('backgroundColor')}
          >
            <MarkerIcon />
            <ArrowDropDownIcon />
          </ToggleButton>
          <ColorPicker
            value={currentColors[colorType]}
            anchorEl={colorAnchorEl}
            onChange={handleColorChange}
            onClose={handleColorClose}
          />
          {/* <Divider
            flexItem
            orientation="vertical"
            className={classes.divider}
          />
          <StyledToggleButtonGroup
            size="small"
            // value={alignment}
            exclusive
            // onChange={handleAlignment}
          >
            <ToggleButton value="left" title="Left Aligned">
              <FormatAlignLeftIcon />
            </ToggleButton>
            <ToggleButton value="center" title="Centered">
              <FormatAlignCenterIcon />
            </ToggleButton>
            <ToggleButton value="right" title="Right Aligned">
              <FormatAlignRightIcon />
            </ToggleButton>
            <ToggleButton value="justify" title="Justified">
              <FormatAlignJustifyIcon />
            </ToggleButton>
          </StyledToggleButtonGroup> */}
          <Divider
            flexItem
            orientation="vertical"
            className={classes.divider}
          />
          <StyledToggleButtonGroup size="small">
            {inlineStyles.map(({ style, title, Icon }) => (
              <ToggleButton
                key={style}
                value={style}
                title={title}
                selected={currentStyle.has(style)}
                onMouseDown={preventDefault}
                onClick={handleInlineStyleClick}
              >
                <Icon />
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
          <Divider
            flexItem
            orientation="vertical"
            className={classes.divider}
          />
          <StyledToggleButtonGroup size="small">
            {blockTypes.map(({ style, title, Icon }) => (
              <ToggleButton
                key={style}
                value={style}
                title={title}
                selected={blockType === style}
                onMouseDown={preventDefault}
                onClick={handleBlockTypeClick}
              >
                <Icon />
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
          <Divider
            flexItem
            orientation="vertical"
            className={classes.divider}
          />
          <ToggleButton
            title="Link"
            value="link"
            className={classes.standalone}
            size="small"
            onClick={handleLinkClick}
            disabled={selection.isCollapsed()}
          >
            <LinkIcon />
          </ToggleButton>
          <Dialog open={linkDialogOpen} onClose={handleLinkDialogClose(false)}>
            <DialogTitle>Insert Link</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the URL in the field below
              </DialogContentText>
              <TextField
                autoFocus
                value={url}
                margin="dense"
                id="url"
                label="URL"
                type="url"
                onChange={handleUrlChange}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleLinkDialogClose(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleLinkDialogClose(true)} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={handleFileChanged}
          />
          <label htmlFor="contained-button-file">
            <ToggleButton
              title="Image"
              value="image"
              className={classes.standalone}
              size="small"
              component="span"
              // onClick={handleImageClick}
            >
              <ImageIcon />
            </ToggleButton>
          </label>
        </div>
        <Divider />
        <div className={classes.editor} onClick={focus}>
          <Editor
            ref={editor}
            placeholder={placeholder}
            spellCheck={true}
            editorState={editorState}
            onChange={handleChange}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            handlePastedFiles={handlePastedFiles}
            handleDroppedFiles={handleDroppedFiles}
            // handlePastedText={handlePastedText}
            customStyleMap={styleMap}
          />
        </div>
      </div>
    </div>
  );
}

export function ContentViewer({ content = '' }) {
  let contentState;
  if (typeof content === 'string') {
    const blocksFromHTML = convertFromHTML(content);
    contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
  } else {
    contentState = convertFromRaw(content);
  }

  return (
    <Editor
      readOnly={true}
      editorState={EditorState.createWithContent(contentState, decorator)}
      customStyleMap={styleMap}
    />
  );
}
