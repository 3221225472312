import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Work as WorkIcon } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from '../Snackbar';
import {
  CREATE_BRIEF,
  DELETE_BRIEF,
  FETCH_BRIEF,
  FETCH_BRIEF_SUCCESS,
  FETCH_PLANS,
  FETCH_QUERIES,
  FETCH_TAGS,
  UPDATE_BRIEF,
} from '../../actions';
import { doesIdExist } from '../../apis/utilities';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  TextField,
  ContentField,
  TypesField,
  SelectMultipleField,
  required,
} from '../fields';
import { briefItemTypeIcons } from '../../data/constants';
import { RouteLeavingGuard } from '../controls';

const { maxUploadSize, personGroups } = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  collections: {
    width: '100%',
  },
  subHeader: {
    marginTop: 10,
  },
  editor: {
    width: '100%',
    marginBottom: 16,
  },
  delete: {
    color: theme.palette.error.main,
  },
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  edits: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const initialValues = {};

export default function Brief() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const brief = useSelector((state) => state.briefs.brief, _.isEqual);
  const error = useSelector((state) => state.briefs.error);
  const plans = useSelector((state) => state.plans.planNames, _.isEqual);
  const queries = useSelector((state) => state.queries.queryNames, _.isEqual);
  const tags = useSelector((state) => state.tags.tags, _.isEqual);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_BRIEF_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_BRIEF,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_PLANS,
    });
    dispatch({
      type: FETCH_QUERIES,
    });
    dispatch({
      type: FETCH_TAGS,
    });
  }, [id, dispatch]);

  function handleDelete() {
    if (brief) {
      dispatch({
        type: DELETE_BRIEF,
        payload: encodeURIComponent(brief.identifier),
      });
    }
  }

  function onSubmit(values) {
    if (brief) {
      dispatch({
        type: UPDATE_BRIEF,
        payload: values,
      });
    } else {
      dispatch({
        type: CREATE_BRIEF,
        payload: values,
      });
    }
  }

  async function validate(values) {
    const errors = {};

    if (!brief) {
      if (values.identifier) {
        if (values.identifier === 'new') {
          errors.identifier = 'Invalid';
        }
        const exists = await doesIdExist('briefs', values.identifier);
        if (exists) {
          errors.identifier = 'Exists';
        }
      }
    }

    const blob = new Blob([JSON.stringify(values)], {
      type: 'application/json',
    });

    if (blob.size > maxUploadSize) {
      snackbar.notify('warning', 'Unable to save, content greater than 5MB');
      errors.description = 'Content greater than 5MB';
    }

    return errors;
  }

  return (
    <Form
      initialValues={brief || initialValues}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form, submitting, dirty, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>IR3 | Brief{values.title ? ` | ${values.title}` : ''}</title>
          </Helmet>
          <Paper className={classes.card}>
            <CardHeader
              avatar={
                <Avatar aria-label="Brief" className={classes.avatar}>
                  <WorkIcon />
                </Avatar>
              }
              title={values.title}
              subheader={values.identifier}
            />
            <CardContent className={classes.cardContent}>
              {values.created && (
                <div className={classes.edits}>
                  <Typography variant="caption">
                    {`Created by ${values.created.userId} ${moment(
                      values.created.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </div>
              )}
              {values.lastEdit && (
                <div className={classes.edits}>
                  <Typography variant="caption">
                    {`Last edited by ${values.lastEdit.userId} ${moment(
                      values.lastEdit.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </div>
              )}
              <List>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="title"
                      component={TextField}
                      label="Title"
                      className={classes.textField}
                      validate={required}
                    />
                    <Field
                      name="identifier"
                      component={TextField}
                      label="Identifier"
                      className={classes.textField}
                      disabled={brief && 'identifier' in brief}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Description</ListSubheader>
                <ListItem>
                  <Field
                    name="description"
                    component={ContentField}
                    className={classes.editor}
                  />
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Groups & Areas</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="areas"
                      types={personGroups}
                      component={TypesField}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Plans</ListSubheader>
                <ListItem>
                  <div className={classes.collections}>
                    <Field
                      name="plans"
                      fullWidth
                      component={SelectMultipleField}
                      suggestions={plans.map((plan) => ({
                        label: plan.title,
                        value: plan.identifier,
                      }))}
                      typeIcons={briefItemTypeIcons}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Queries</ListSubheader>
                <ListItem>
                  <div className={classes.collections}>
                    <Field
                      name="queries"
                      fullWidth
                      component={SelectMultipleField}
                      suggestions={queries.map((query) => ({
                        label: query.title,
                        value: query.identifier,
                      }))}
                      typeIcons={briefItemTypeIcons}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Tags</ListSubheader>
                <ListItem>
                  <div className={classes.collections}>
                    <Field
                      name="tags"
                      fullWidth
                      component={SelectMultipleField}
                      suggestions={tags.map((tag) => ({
                        label: tag.identifier,
                        value: tag.identifier,
                      }))}
                      typeIcons={briefItemTypeIcons}
                    />
                  </div>
                </ListItem>
              </List>
            </CardContent>
            <CardActions disableSpacing>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={form.reset}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={dirty || submitting}
                onClick={() => history.push(`${id}/contents`)}
              >
                Contents
              </Button>
              <Button
                className={classes.delete}
                onClick={() => setDeleteOpen(true)}
                disabled={brief === null}
              >
                Delete
              </Button>
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.title || values.identifier}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
          <RouteLeavingGuard when={dirty} />
        </form>
      )}
    />
  );
}
