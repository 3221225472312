import {
  Avatar,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Work as WorkIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { ContentViewer } from '../../controls';

const { personGroups } = window.config;

const useStyles = makeStyles((theme) => ({
  description: {
    padding: theme.spacing(2),
  },
  areasSubheader: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: 12,
  },
  areasPaper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  areaType: {
    fontSize: 10,
  },
  areaName: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  edits: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function BriefContent(props) {
  const brief = useSelector((state) => state.briefs.brief, _.isEqual);
  const classes = useStyles();

  return (
    brief && (
      <Fragment>
        <CardHeader
          avatar={
            <Avatar aria-label="Collection" className={classes.avatar}>
              <WorkIcon />
            </Avatar>
          }
          title={brief.title}
          subheader={brief.identifier}
        />
        <CardContent>
          {brief.created && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`Created by ${brief.created.userId} ${moment(
                  brief.created.time
                ).format('DD/MM/YYYY, HH:mm')}`}
              </Typography>
            </div>
          )}
          {brief.lastEdit && (
            <div className={classes.edits}>
              <Typography variant="caption">
                {`Last edited by ${brief.lastEdit.userId} ${moment(
                  brief.lastEdit.time
                ).format('DD/MM/YYYY, HH:mm')}`}
              </Typography>
            </div>
          )}
          <div className={classes.description}>
            <ContentViewer content={brief.description} />
          </div>
          {brief.areas && (
            <Fragment>
              <Typography
                key="subheader"
                variant="subtitle1"
                className={classes.areasSubheader}
              >
                Assigned Groups/Areas
              </Typography>
              <Paper key="table" className={classes.areasPaper} elevation={0}>
                <Table>
                  <TableBody>
                    {brief.areas.map((area, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography
                            variant="caption"
                            className={classes.areaType}
                          >
                            {
                              (
                                personGroups[area.type] || {
                                  label: area.type,
                                }
                              ).label
                            }
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="caption"
                            className={classes.areaName}
                          >
                            {area.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Fragment>
          )}
        </CardContent>
      </Fragment>
    )
  );
}
