import _ from 'lodash';
import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  IconButton,
} from '@material-ui/core';
import { LocationSearching as FollowIcon } from '@material-ui/icons';
import avatarForItem from './avatarUtility';
import { idProperties } from '../../../data/utilities';

export function getIfNotAlreadyShown(item, path, shownPaths) {
  if (!path || shownPaths.includes(path)) {
    return undefined;
  }

  return _.get(item, path);
}

const { labelOverridePaths, showOverriddenLabelInListItems: showOverridden } =
  window.config.liveOptions || {};

function LiveListItem({
  item,
  type,
  style,
  classes,
  highlighted,
  onHoverItem,
  onClick,
  onFollowToggle,
  followedIdsByType,
  hideFollow,
  primaryPath,
  secondaryPath,
  tertiaryPath,
  secondaryComponent = undefined,
  shownPaths = [],
  divider = false,
}) {
  if (!item) {
    return <></>;
  }

  const idPath = idProperties[type];
  const id = item.id || (idPath && _.get(item, idPath));

  const idType = { id, type };
  const handleHoverEnter = onHoverItem ? () => onHoverItem(idType) : undefined;
  const handleHoverLeave = onHoverItem ? () => onHoverItem({}) : undefined;
  const handleClick = onClick
    ? () => onClick({ ...item, id, type })
    : undefined;
  const following = followedIdsByType?.[type]?.[id];

  const primaryText = _.get(item, primaryPath);
  const labelOverridePath = labelOverridePaths?.[type];
  const primaryOverride = _.get(item, labelOverridePath);

  // a third line in the list item will show the default label (e.g. fleet # or person
  // name) if there is a primary override (e.g. to show call sign) otherwise it will show
  // whatever's in the tertiaryPath (which is usually set when showSortFieldInListItems
  // is true meaning if you're sorting by X show X in the item unless it's already shown)
  const tertiaryValue =
    primaryOverride && showOverridden
      ? primaryText
      : getIfNotAlreadyShown(
          item,
          tertiaryPath,
          shownPaths.concat([primaryPath, secondaryPath])
        );

  return (
    <ListItem
      className={highlighted ? classes?.highlightListItem : null}
      style={style}
      button={!!onClick}
      onClick={handleClick}
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
      divider={divider}
    >
      <ListItemAvatar>{avatarForItem(item, type)}</ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{
          variant: 'body2',
          style: { lineHeight: 1.3, marginTop: 4 },
        }}
        primary={primaryOverride || primaryText}
        secondaryTypographyProps={{ component: 'span', noWrap: true }}
        secondary={
          secondaryComponent || (
            <React.Fragment>
              {_.get(item, secondaryPath)}
              <Typography
                title={tertiaryValue}
                className={classes?.tertiaryValue}
              >
                {tertiaryValue}
              </Typography>
            </React.Fragment>
          )
        }
      />
      {/* for whatever reason the ListItemSecondaryAction doesn't play well with 
          virtualized lists, positioning gets screwed up, so instead I'll
          use a normal button but stop propagation of the click to the select
          below
        */}
      {!hideFollow && (
        <IconButton
          title={following ? 'Stop following' : 'Follow'}
          onClick={(e) => {
            e.stopPropagation();
            onFollowToggle(type, id);
          }}
        >
          <FollowIcon
            fontSize="small"
            color={following ? 'primary' : 'disabled'}
          />
        </IconButton>
      )}
    </ListItem>
  );
}

export default LiveListItem;
