import { Typography, makeStyles } from '@material-ui/core';
import { Build as BuildIcon } from '@material-ui/icons';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const { maintenance } = window.config;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 96px)',
    flex: 1,
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
}));

export default function Maintenance() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>IR3 | Down for maintenance</title>
      </Helmet>
      <main className={classes.main}>
        <div className={classes.heroContent}>
          <Typography align="center" color="textSecondary">
            <BuildIcon style={{ fontSize: 200 }} />
          </Typography>
          <Typography variant="h6" align="center" component="p">
            {maintenance}
          </Typography>
        </div>
      </main>
    </div>
  );
}
