import React, { useState, useEffect } from 'react';
import {
  IconButton,
  MenuItem,
  TextField,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  LastPage as LastPageIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  option: {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
  },
  pageSizeSelect: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  page: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    width: 60,
  },
  pageCount: {
    paddingRight: theme.spacing(2),
  },
}));

export default function Pagination({
  page,
  pages,
  onPageChange,
  onPageSizeChange,
  pageSize,
  pageSizeOptions,
  hidePageSizeChange = false,
}) {
  const [internalPage, setInternalPage] = useState(0);
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    setInternalPage(page);
  }, [page]);

  function handleFirstPageButtonClick(event) {
    onPageChange(0);
  }

  function handleBackButtonClick(event) {
    onPageChange(page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(pages - 1);
  }

  function handlePageSizeChange(event) {
    onPageSizeChange(event.target.value);
  }

  function handlePageChange(event) {
    if (Number.isInteger(parseInt(event.target.value))) {
      setInternalPage(parseInt(event.target.value) - 1);
    } else {
      setInternalPage(event.target.value);
    }
  }

  function handlePageEnter(event) {
    if (event.key === 'Enter') {
      if (
        Number.isInteger(parseInt(internalPage)) &&
        internalPage > -1 &&
        internalPage < pages
      ) {
        onPageChange(internalPage);
      } else {
        onPageChange(0);
      }
    }
  }

  return (
    <div className={classes.root}>
      {!hidePageSizeChange && (
        <React.Fragment>
          <span>Rows per page:</span>
          <TextField
            className={classes.pageSizeSelect}
            select
            value={pageSize}
            onChange={handlePageSizeChange}
            InputProps={{
              style: {
                fontSize: '0.75rem',
              },
            }}
            margin="dense"
          >
            {pageSizeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <span className={classes.option}>{option}</span>
              </MenuItem>
            ))}
          </TextField>
        </React.Fragment>
      )}
      <span>Page:</span>
      <TextField
        type="number"
        inputProps={{ min: 1, max: pages }}
        margin="dense"
        id="page"
        className={classes.page}
        value={
          pages
            ? Number.isInteger(parseInt(internalPage))
              ? internalPage + 1
              : internalPage
            : 0
        }
        onChange={handlePageChange}
        onKeyPress={handlePageEnter}
        InputProps={{
          style: {
            fontSize: '0.75rem',
          },
        }}
      />
      <span className={classes.pageCount}> of {pages}</span>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRightIcon />
        ) : (
          <KeyboardArrowLeftIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page === pages - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page === pages - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
