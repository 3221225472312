import { IconButton, makeStyles } from '@material-ui/core';
import {
  AddCircle as AddIcon,
  RemoveCircle as RemoveIcon,
} from '@material-ui/icons';
import React from 'react';
import Field from './Field';
import SelectField from './SelectField';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
}));

export default function TypesField({ input, types, disabled, readOnly }) {
  const classes = useStyles();

  return (
    <div>
      <div>
        {(input.value || []).map((entry, index) => {
          const values =
            input.value[index].type in types
              ? types[input.value[index].type].values
              : [];

          return (
            <div className={classes.row} key={index}>
              <Field
                name={`${input.name}[${index}].type`}
                component={SelectField}
                label="Type"
                values={
                  Object.entries(types).map((type) => ({
                    label: type[1].label,
                    value: type[0],
                  })) || []
                }
                className={classes.textField}
                disabled={disabled}
                InputProps={{
                  readOnly,
                }}
              />
              <Field
                name={`${input.name}[${index}].name`}
                component={SelectField}
                label="Value"
                values={values}
                className={classes.textField}
                disabled={disabled}
                InputProps={{
                  readOnly,
                }}
              />
              {!readOnly && !disabled && (
                <IconButton
                  className={classes.button}
                  aria-label="Remove"
                  onClick={() => {
                    input.onChange(
                      input.value
                        .slice(0, index)
                        .concat(input.value.slice(index + 1))
                    );
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </div>
          );
        })}
      </div>
      <div className={classes.row}>
        {!readOnly && !disabled && (
          <IconButton
            className={classes.button}
            aria-label="Add"
            onClick={() => {
              input.onChange([...(input.value || []), {}]);
            }}
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}
