import {
  Avatar,
  Paper,
  Popover,
  Checkbox,
  Slider,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import React, { Fragment, useState } from 'react';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 24,
    height: 24,
  },
  paper: {
    width: 144,
    padding: theme.spacing(1),
  },
  radio: {
    padding: theme.spacing(0.5),
  },
}));

const hues = Object.keys(colors).slice(1);
const shades = [
  '900',
  '800',
  '700',
  '600',
  '500',
  '400',
  '300',
  '200',
  '100',
  '50',
  'A700',
  'A400',
  'A200',
  'A100',
];
const hueAndShade = Object.assign(
  {},
  ...[].concat(
    ...Object.entries(colors)
      .slice(1)
      .map((hue) =>
        Object.entries(hue[1]).map((shade) => ({
          [shade[1]]: { hue: hue[0], shade: shade[0] },
        }))
      )
  )
);
const grey = colors.grey[500];

export default function ColorPicker({ value, anchorEl, onClose, onChange }) {
  const [shade, setShade] = useState(hueAndShade[value || grey].shade);
  const classes = useStyles();
  const theme = useTheme();

  function handleChangeHue(event) {
    const {
      target: { value: hue },
    } = event;

    const color = colors[hue][shade];

    onChange(color);
  }

  function handleChangeShade(event, shadeIndex) {
    setShade(shades[shadeIndex]);
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <Paper className={classes.paper}>
        <div className={classes.sliderContainer}>
          <Slider
            title="Shade"
            className={classes.slider}
            value={shades.indexOf(shade)}
            min={0}
            max={13}
            step={1}
            onChange={handleChangeShade}
            onMouseDown={(e) => e.preventDefault()}
          />
        </div>
        <div>
          {hues.map((hue) => {
            const backgroundColor = colors[hue][shade];

            return (
              <Checkbox
                title={_.startCase(hue)}
                key={hue}
                className={classes.radio}
                checked={value === backgroundColor}
                onChange={handleChangeHue}
                onMouseDown={(e) => e.preventDefault()}
                value={hue}
                icon={
                  <Avatar
                    className={classes.avatar}
                    style={{ backgroundColor }}
                  >
                    <Fragment />
                  </Avatar>
                }
                checkedIcon={
                  <Avatar
                    className={classes.avatar}
                    style={{ backgroundColor }}
                  >
                    <CheckIcon
                      style={{
                        color: theme.palette.getContrastText(backgroundColor),
                        fontSize: 16,
                      }}
                    />
                  </Avatar>
                }
              />
            );
          })}
        </div>
      </Paper>
    </Popover>
  );
}
