import {
  CLEAR_BRIEF_COLLECTIONS,
  CLEAR_BRIEF_OBJECTIVES,
  FETCH_BRIEF,
  FETCH_BRIEF_SUCCESS,
  FETCH_BRIEF_FAILURE,
  FETCH_BRIEFS,
  FETCH_BRIEFS_SUCCESS,
  FETCH_BRIEFS_FAILURE,
  FETCH_BRIEF_COLLECTIONS,
  FETCH_BRIEF_COLLECTIONS_SUCCESS,
  FETCH_BRIEF_COLLECTIONS_FAILURE,
  FETCH_BRIEF_OBJECTIVES,
  FETCH_BRIEF_OBJECTIVES_SUCCESS,
  FETCH_BRIEF_OBJECTIVES_FAILURE,
  UPDATE_BRIEF,
  UPDATE_BRIEF_SUCCESS,
  UPDATE_BRIEF_FAILURE,
  CREATE_BRIEF,
  CREATE_BRIEF_SUCCESS,
  CREATE_BRIEF_FAILURE,
  DELETE_BRIEF,
  DELETE_BRIEF_SUCCESS,
  DELETE_BRIEF_FAILURE,
} from '../actions';

const INITIAL_STATE = {
  briefNames: [],
  brief: null,
  collections: null,
  objectives: null,
  isLoading: false,
  error: null,
};

export default function briefsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_BRIEFS:
      return { ...state, isLoading: true, error: null };
    case FETCH_BRIEFS_SUCCESS:
      return {
        ...state,
        briefNames: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_BRIEFS_FAILURE:
      return {
        ...state,
        briefNames: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_BRIEF:
      return { ...state, isLoading: true, error: null };
    case FETCH_BRIEF_SUCCESS:
      return { ...state, brief: action.payload, isLoading: false, error: null };
    case FETCH_BRIEF_FAILURE:
      return {
        ...state,
        brief: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_BRIEF:
      return { ...state, isLoading: true, error: null };
    case UPDATE_BRIEF_SUCCESS:
      return {
        ...state,
        brief: action.payload,
        briefNames: state.briefNames.map((brief) =>
          brief.identifier === action.payload.identifier
            ? {
                identifier: action.payload.identifier,
                title: action.payload.title,
              }
            : brief
        ),
        isLoading: false,
        error: null,
      };
    case UPDATE_BRIEF_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_BRIEF:
      return { ...state, isLoading: true, error: null };
    case CREATE_BRIEF_SUCCESS:
      return {
        ...state,
        brief: action.payload,
        briefNames: state.briefNames.concat({
          identifier: action.payload.identifier,
          title: action.payload.title,
        }),
        isLoading: false,
        error: null,
      };
    case CREATE_BRIEF_FAILURE:
      return {
        ...state,
        brief: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_BRIEF:
      return { ...state, isLoading: true, error: null };
    case DELETE_BRIEF_SUCCESS:
      const index = state.briefNames.findIndex(
        (brief) => brief.identifier === action.payload
      );

      return {
        ...state,
        brief: null,
        briefNames: state.briefNames
          .slice(0, index)
          .concat(state.briefNames.slice(index + 1)),
        isLoading: false,
        error: null,
      };
    case DELETE_BRIEF_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_BRIEF_COLLECTIONS:
      return { ...state, isLoading: true, error: null };
    case FETCH_BRIEF_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_BRIEF_COLLECTIONS_FAILURE:
      return {
        ...state,
        collections: null,
        isLoading: false,
        error: action.payload,
      };
    case CLEAR_BRIEF_COLLECTIONS:
      return { ...state, collections: null };
    case FETCH_BRIEF_OBJECTIVES:
      return { ...state, isLoading: true, error: null };
    case FETCH_BRIEF_OBJECTIVES_SUCCESS:
      return {
        ...state,
        objectives: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_BRIEF_OBJECTIVES_FAILURE:
      return {
        ...state,
        objectives: null,
        isLoading: false,
        error: action.payload,
      };
    case CLEAR_BRIEF_OBJECTIVES:
      return { ...state, objectives: null };
    default:
      return state;
  }
}
