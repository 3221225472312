import { Avatar, Badge, makeStyles } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';
import moment from 'moment';
import React from 'react';
import { objectiveTypeIcons } from '../../data/constants';

const useAvatarStyles = makeStyles((theme) => ({
  currentBadge: {
    backgroundColor: green[500],
  },
  pastBadge: {
    backgroundColor: red[500],
  },
  futureBadge: {
    backgroundColor: orange[500],
  },
  activeAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export function objectiveIsActive(objective) {
  const now = new moment();
  const hour = now.hour();
  const day = (now.day() + 6) % 7; // moment.js's S0 becomes S6, moment.js's M1 becomes M0

  return objective.hours?.includes(hour) && objective.days?.includes(day);
}

export default function ObjectiveAvatar({
  type,
  startTime,
  endTime,
  isActive,
  showBadge = true,
}) {
  const classes = useAvatarStyles();
  const now = moment(Date.now());
  const start = moment(startTime);
  const end = moment(endTime);

  let hours = 0.0;
  let tip = '';
  let className = null;

  if (now.isAfter(end)) {
    hours = now.diff(end, 'hours') / 24;
    tip = 'Days since end';
    className = classes.pastBadge;
  } else if (now.isBefore(start)) {
    hours = start.diff(now, 'hours') / 24;
    tip = 'Days until start';
    className = classes.futureBadge;
  } else {
    hours = end.diff(now, 'hours') / 24;
    tip = 'Days until end';
    className = classes.currentBadge;
  }

  return (
    <Badge
      classes={{ badge: className }}
      badgeContent={
        showBadge ? (
          <div title={tip}>
            {hours > 99.0 ? '99+' : Math.round(hours).toString()}
          </div>
        ) : undefined
      }
      color="primary"
    >
      <Avatar className={isActive ? classes.activeAvatar : undefined}>
        {objectiveTypeIcons[type]}
      </Avatar>
    </Badge>
  );
}
